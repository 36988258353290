import { observer } from 'mobx-react';
import * as React from 'react';

import ProductSearchStore from 'search/stores/product-search-store';
import { CustomRefinementListComponent } from './custom-parent-refinement-component';

interface ShowerFilterProps {
  store: ProductSearchStore;
}

const ShowerFilter = observer(
  class ShowerFilter extends React.Component<ShowerFilterProps> {
    private containerRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
      const { store } = this.props;
      const { showerFilterOpened: opened } = store;
      if (!opened && this.containerRef.current) {
        this.containerRef.current.style.display = 'none';
      }
    }

    render() {
      const { store } = this.props;
      const { showerFilterOpened: opened } = store;
      const classes = ['c-filters__group', opened ? 'is-open' : ''];
      return (
        <>
          <div className={classes.join(' ')}>
            <div
              className="c-filters__header c-accordion-toggle"
              onClick={() => {
                store.setShowerFilterOpened(!opened);
                if (this.containerRef.current) {
                  jQuery(this.containerRef.current).stop(true).slideToggle();
                }
              }}
            >
              <p className="c-filters__title">Showers</p>
            </div>
            <div className="c-filters__container c-accordion-container" ref={this.containerRef}>
              <CustomRefinementListComponent
                attribute="shower_type"
                limit={25}
                subrefinements={[
                  { attribute: 'shower_systems', key: 'Shower Systems' },
                  { attribute: 'showerheads', key: 'Showerheads' },
                  { attribute: 'hand_shower_wands', key: 'Shower Wands' },
                ]}
              />
            </div>
          </div>
        </>
      );
    }
  }
);
export default ShowerFilter;
