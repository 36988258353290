import { subject } from '@casl/ability';
import classNames from 'classnames';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { z } from 'zod';

import { FileModel, FileType, Order, RegisteredUser, Response, UserRole } from '__generated-api__';
import api from 'api';
import { AuthStatus, useAbility, useAuth } from 'auth';
import Form from 'components/Form/Form';
import InputField from 'components/Form/InputField';
import SubmitButton from 'components/Form/SubmitButton';
import Icon from 'components/icon';
import { useMutation, useQuery } from 'hooks/query';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { MainHero } from 'my-account/components/MainHero';
import MultilineMessage from 'my-account/components/MultilineMessage';
import ProductImage from 'my-account/components/ProductImage';
import { ProfileImage } from 'my-account/components/ProfileImage';
import { SelectOrder } from 'my-account/components/SelectOrder';
import { Block403Error } from 'my-account/pages/403page';
import { DisplayPrice } from 'my-account/utils/currency';
import { ErrorMessagesContent } from 'my-account/utils/error-handler';
import { getUserName } from 'my-account/utils/user';
import { OrderRow } from '../orders';

const RespondQuoteSchema = z.object({
  message: z.string().nonempty(),
  files: z.instanceof(window.FileList).optional(),
});

const OrderQuoteSchema = z.object({
  order: z
    .object({
      id: z.number(),
    })
    .nullable()
    .optional(),
});

const QuoteRespondForm: React.VFC<{ id: number }> = ({ id }) => {
  const queryClient = useQueryClient();
  const [respondQuote] = useMutation(api.quote.respondQuote);
  const [filesKey, setFilesKey] = React.useState(0);

  return (
    <Form
      schema={RespondQuoteSchema}
      initialValues={{}}
      onSubmit={async (data, ctx) => {
        let files: FileModel[] = [];

        if (data.files && data.files.length) {
          for (let i = 0; i < data.files.length; i += 1) {
            const file = data.files[i];
            const uploadRes = await api.file.upload({ image: file, type: FileType.File });
            files.push(uploadRes.data);
          }
        }

        await respondQuote([{ id, createQuoteResponseBody: { message: data.message, files } }]);

        await queryClient.refetchQueries({
          queryKey: api.quote.listQuote.getQueryKey({ id }),
        });

        ctx.reset();
        setFilesKey((key) => key + 1);
      }}
      className="u-mb-0"
      hasFloatingLabels
      disableFieldsOnSubmitting
      formProps={{ mode: 'onSubmit' }}
    >
      <div className="c-chat__row c-chat__row--footer">
        <div className="c-chat__column c-chat__column--large">
          <InputField type="textarea" name="message" label="Message" elStyle="fill" />
          <InputField name="files" type="file" elStyle="fill" key={`files-${filesKey}`} />
        </div>
        <div className="c-chat__column">
          <SubmitButton variant="secondary" isFull className="u-mb-spacer-base">
            Send
          </SubmitButton>
        </div>
      </div>
    </Form>
  );
};

const QuoteOrderForm: React.VFC<{
  id: number;
  company_id: number | undefined;
  order: Order | null | undefined;
  onSubmit: () => void;
}> = ({ id, company_id, order, onSubmit }) => {
  const queryClient = useQueryClient();
  const [orderQuote] = useMutation(api.quote.orderQuote);
  let queryParams = {};
  if (company_id !== undefined) {
    queryParams = { companyId: company_id };
  }
  return (
    <Form
      schema={OrderQuoteSchema}
      initialValues={{
        order: order,
      }}
      onSubmit={async (data, ctx) => {
        if (!data.order) {
          return;
        }
        await orderQuote([{ id, orderRequestBody: { order_id: data.order.id } }]);

        await queryClient.refetchQueries({
          queryKey: api.quote.listQuote.getQueryKey({ id }),
        });

        ctx.reset();
        onSubmit();
      }}
      className="u-mb-0"
      hasFloatingLabels
      disableFieldsOnSubmitting
      formProps={{ mode: 'onSubmit' }}
    >
      <div className="c-chat__column c-chat__column--large">
        <SelectOrder name="order" label="Order" elStyle="fill" queryParams={queryParams} />
      </div>
      <div className="c-chat__column">
        <SubmitButton variant="secondary" isFull className="u-mb-spacer-base">
          Link
        </SubmitButton>
      </div>
    </Form>
  );
};

const QuoteResponse: React.VFC<{ response: Response }> = ({ response }) => {
  return (
    <div className="c-chat-message-group">
      <div className="c-chat-message-group__column c-chat-message-group__column--avatar">
        <div className="c-chat-tooltip">
          <ProfileImage user={response.user} variant="chat" />
          <div className="c-chat-tooltip__main c-color--invert">
            <p className={classNames('c-chat-tooltip__title', { 'u-mb-0': !response.user.company })}>
              {getUserName(response.user).name}
            </p>
            {response.user.company ? (
              <Link to={`/companies/${response.user.company.id}`} className="c-tag c-tag--white">
                {response.user.company.name}
              </Link>
            ) : null}
          </div>
        </div>
      </div>

      <div className="c-chat-message-group__column">
        <div className="c-chat__meta">
          <p>{getUserName(response.user).name}</p>

          <p>
            <small>{format(parseISO(response.created_at), 'MMM d y')}</small>
          </p>
        </div>

        <div
          className={classNames('c-chat__message', {
            'c-chat__message--secondary': response.user.role === UserRole.Admin,
          })}
        >
          <MultilineMessage message={response.message} />
        </div>

        {response.files.length > 0 && (
          <div className="c-chat__tag-list">
            {response.files.map((file) => (
              <a href={file.private_url} className="c-tag" target="_blank" rel="noreferrer" key={file.id}>
                <Icon name="download" />
                <span>{file.title}</span>
              </a>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default function QuoteViewPage() {
  const auth = useAuth();
  let user: RegisteredUser | undefined;
  if (auth.status === AuthStatus.LoggedIn) {
    user = auth.currentUser;
  }
  const { id } = useParams<{ id?: string }>();
  const ability = useAbility();
  const [quoteRes, getQuoteState] = useQuery(api.quote.listQuote, { id: Number(id) });
  const [editOrderLink, setEditOrderLink] = React.useState(false);
  useDocumentTitle(
    quoteRes?.data.id
      ? quoteRes.data.user?.direct_buyer
        ? `Pending Order #${quoteRes.data.id}`
        : `Project Quote #${quoteRes.data.id}`
      : user?.direct_buyer
      ? `Pending Order`
      : ''
  );
  const [total, setTotal] = React.useState(0);
  React.useEffect(() => {
    if (quoteRes) {
      const total = quoteRes.data.products?.reduce(
        (result, product) => result + Number(product.pivot.quoted_price ?? product.price) * product.pivot.quantity,
        0
      );
      setTotal(total);
    }
  }, [quoteRes]);
  let directBuyer = false;
  if (quoteRes) {
    directBuyer = quoteRes.data.user?.direct_buyer ? true : false;
  }

  return (
    <>
      <MainHero />

      <section className="c-block c-block--hero">
        <div className="o-container-fluid">
          <div className="o-row u-flex-grow u-items-end">
            <div className="o-col-12">
              <Link
                to="/quotes"
                className="c-link-cta-basic c-link-cta--small c-block__get-back u-mt-spacer-base-large u-mb-spacer-base-small"
              >
                <Icon name="arrow" className="o-svg-icon o-svg-left" />
                {directBuyer ? <span>Back to pending orders</span> : <span>Back to quotes</span>}
              </Link>
              <h1 className="c-title--medium u-mb-0">
                {directBuyer ? `Pending Order` : `Project Quote`}
                {typeof quoteRes !== 'undefined' && getQuoteState.isSuccess && ` #${quoteRes.data.id}`}
              </h1>
            </div>
          </div>
        </div>
        <div className="c-block__pattern c-block__pattern--light3 c-block__pattern--top3 c-block__overlay--opacity-20">
          <Icon name="pattern" className="o-svg-icon" />
        </div>
      </section>

      {getQuoteState.isLoading && (
        <div className="c-listing__none">
          <div className="c-listing__none-spinner"></div>
          <p className="c-listing__none-title">Loading...</p>
        </div>
      )}
      {getQuoteState.isError && (
        <div className="o-container-fluid u-pb-spacer-section">
          <div className="o-row u-flex-grow u-items-center">
            <div className="o-col-12">
              <div className="c-listing__none">
                <div className="c-listing__none-figure">
                  <Icon name="error" className="o-svg-icon" />
                </div>
                <p className="c-listing__none-title">Error</p>
                <ErrorMessagesContent error={getQuoteState.error} />
              </div>
            </div>
          </div>
        </div>
      )}
      {typeof quoteRes !== 'undefined' && getQuoteState.isSuccess && (
        <>
          {ability.can('read', subject('Quote', quoteRes.data)) ? (
            <>
              {directBuyer ? (
                <>
                  <section className="c-block u-mb-spacer-base-large u-mb-spacer-section-small@md">
                    <div className="o-container-fluid">
                      <div className="o-row">
                        <div className="o-col-6@md">
                          <div className="c-admin-card">
                            <div className="c-admin-card__content">
                              <p className="c-admin-card__subtitle">Project Information</p>

                              <p className="u-mb-0">{quoteRes.data.project_name}</p>
                              <p className="u-font-medium u-mb-0">{quoteRes.data.message}</p>
                            </div>
                          </div>
                        </div>

                        <div className="o-col-6@md">
                          <div className="c-admin-card">
                            <div className="c-admin-card__content">
                              <p className="c-admin-card__subtitle">Shipping Address</p>

                              <p className="u-mb-0">{quoteRes.data.location_name}</p>
                              <p className="u-font-medium u-mb-0">
                                {quoteRes.data.address} <br />
                                {quoteRes.data.address2 && quoteRes.data.address2.length > 0 && (
                                  <>
                                    {quoteRes.data.address2} <br />
                                  </>
                                )}
                                {quoteRes.data.city}, {quoteRes.data.state} {quoteRes.data.postal_code}{' '}
                                {quoteRes.data.country} <br />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  </section>

                  <section className="c-block c-block--spacing-b-small">
                    {quoteRes.data.order && !editOrderLink ? (
                      <div className="o-container-fluid">
                        <div className="o-row">
                          <div className="o-col-3@md">
                            <p className="u-text-xs u-uppercase u-mb-spacer-base-small">Order Information</p>
                            <p className="c-note">
                              This order exists in our fulfillment system. You can track the order here. To link to a
                              different order, click{' '}
                              <a href="#link-order" onClick={() => setEditOrderLink(true)}>
                                here
                              </a>
                              .
                            </p>
                          </div>

                          <div className="o-col-9@md o-col-9@xl">
                            <OrderRow order={quoteRes.data.order} />
                          </div>
                        </div>

                        <div className="o-row">
                          <div className="o-col-12">
                            <hr className="u-mt-spacer-base u-mt-3@md u-mb-0 u-mb-spacer-base-small@md" />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="o-container-fluid">
                        <div className="o-row">
                          <div className="o-col-3@md">
                            <p className="u-text-xs u-uppercase u-mb-spacer-base-small">Link to Order</p>
                            <p className="c-note">Once the order is processed, the pending order can be linked here.</p>
                          </div>

                          <div className="o-col-9@md o-col-6@xl">
                            <QuoteOrderForm
                              id={Number(id)}
                              company_id={quoteRes.data.company?.id}
                              order={quoteRes.data.order}
                              onSubmit={() => setEditOrderLink(false)}
                            />
                          </div>
                        </div>

                        <div className="o-row">
                          <div className="o-col-12">
                            <hr className="u-mt-spacer-base u-mt-3@md u-mb-0 u-mb-spacer-base-small@md" />
                          </div>
                        </div>
                      </div>
                    )}
                  </section>
                </>
              ) : (
                <Form
                  initialValues={{ _readOnly: { ...quoteRes.data } }}
                  schema={z.object({})}
                  onSubmit={async () => {}}
                  hasFloatingLabels
                >
                  <section className="c-block c-block--spacing-b-small">
                    <div className="o-container-fluid">
                      <div className="o-row">
                        <div className="o-col-3@md">
                          <p className="u-text-xs u-uppercase u-mb-spacer-base-small">Project info</p>
                          <p className="c-note">
                            {directBuyer ? (
                              <>The following information is a summary of the order.</>
                            ) : (
                              <>The following information is a summary of the project quote.</>
                            )}
                          </p>
                        </div>

                        <div className="o-col-9@md o-col-6@xl">
                          <div className="o-row o-row--small-gutters">
                            <div className="o-col-12">
                              <InputField
                                name="_readOnly.project_name"
                                label="Project Name"
                                disabled
                                elStyle="fill"
                                inputProps={{ readOnly: true }}
                              />
                            </div>

                            <div className="o-col-6@sm">
                              <InputField
                                name="_readOnly.city"
                                label="City"
                                disabled
                                elStyle="fill"
                                inputProps={{ readOnly: true }}
                              />
                            </div>

                            <div className="o-col-6@sm">
                              <InputField
                                name="_readOnly.state"
                                label="State"
                                disabled
                                elStyle="fill"
                                inputProps={{ readOnly: true }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="o-row">
                        <div className="o-col-12">
                          <hr className="u-mt-spacer-base u-mt-3@md u-mb-0 u-mb-spacer-base-small@md" />
                        </div>
                      </div>
                    </div>
                  </section>
                </Form>
              )}
              <section className="c-block">
                <div className="o-container-fluid">
                  <div className="o-row">
                    <div className="o-col-12">
                      <div className="c-order-summary u-mb-spacer-section-small u-mb-spacer-section@md">
                        <header className="c-order-summary__header">
                          <ul className="c-order-summary-info">
                            <li className="c-order-summary-info__column">
                              <p className="c-order-summary-info__title">
                                {quoteRes.data.created_at ? (
                                  format(parseISO(quoteRes.data.created_at), 'MMM d y')
                                ) : (
                                  <>&mdash;</>
                                )}
                              </p>

                              <p className="c-order-summary-info__label">Submitted Date</p>
                            </li>

                            <li className="c-order-summary-info__column">
                              <p className="c-order-summary-info__title">
                                {quoteRes.data.company?.name ?? <>&mdash;</>}
                              </p>

                              <p className="c-order-summary-info__label">Company</p>
                            </li>

                            <li className="c-order-summary-info__column">
                              <p className="c-order-summary-info__title">
                                {quoteRes.data.user ? (
                                  [quoteRes.data.user.first_name, quoteRes.data.user.last_name]
                                    .filter(Boolean)
                                    .join(' ')
                                ) : (
                                  <>&mdash;</>
                                )}
                              </p>

                              <p className="c-order-summary-info__label">Created by</p>
                            </li>
                          </ul>
                        </header>

                        <table className="c-order-summary-table">
                          <thead>
                            <tr>
                              <th className="c-order-summary-table__column c-order-summary-table__column--first">
                                Item
                              </th>

                              <th className="c-order-summary-table__column c-order-summary-table__column--quantity">
                                Qty
                              </th>

                              <th className="c-order-summary-table__column">List Price</th>

                              <th className="c-order-summary-table__column">My Price</th>

                              <th className="c-order-summary-table__column">Total</th>
                            </tr>
                          </thead>

                          <tbody>
                            {quoteRes.data.products.length > 0 ? (
                              quoteRes.data.products.map((product) => (
                                <tr className="c-order-summary-table__row" key={product.id}>
                                  <td className="c-order-summary-table__column c-order-summary-table__column--first">
                                    <div className="c-order-summary-item">
                                      <ProductImage product={product} />

                                      <div>
                                        <p className="c-order-summary-item__title">{product.title}</p>

                                        <ul className="c-order-summary-item__list">
                                          {product.installation_guide && (
                                            <li>
                                              <a
                                                href={product.installation_guide.salsify_url}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                Installation
                                              </a>
                                            </li>
                                          )}

                                          {product.spec_sheet && (
                                            <li>
                                              <a href={product.spec_sheet.salsify_url} target="_blank" rel="noreferrer">
                                                Submittal
                                              </a>
                                            </li>
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </td>

                                  <td className="c-order-summary-table__column c-order-summary-table__column--quantity">
                                    <span className="c-order-summary-table__label">Qty</span> {product.pivot.quantity}
                                  </td>

                                  <td className="c-order-summary-table__column">
                                    <span className="c-order-summary-table__label">List Price</span>{' '}
                                    <DisplayPrice value={Number(product.price)} />
                                    &nbsp;USD
                                  </td>

                                  <td className="c-order-summary-table__column">
                                    <span className="c-order-summary-table__label">My Price</span>{' '}
                                    {typeof product.pivot.quoted_price !== 'undefined' ? (
                                      <>
                                        <DisplayPrice value={Number(product.pivot.quoted_price)} />
                                        &nbsp;USD
                                      </>
                                    ) : (
                                      <>&mdash;</>
                                    )}
                                  </td>

                                  <td className="c-order-summary-table__column">
                                    <span className="c-order-summary-table__label">Total</span>
                                    <strong>
                                      <DisplayPrice
                                        value={
                                          Number(product.pivot.quoted_price ?? product.price) * product.pivot.quantity
                                        }
                                      />
                                      &nbsp;USD
                                    </strong>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr className="c-order-summary-table__row">
                                <td colSpan={4}>
                                  <p>No products.</p>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>

                        <footer className="c-order-summary-footer">
                          <div className="c-order-summary-footer__row">
                            <div>
                              <p className="c-order-summary-footer__label">Subtotal</p>
                            </div>

                            <div>
                              <p className="c-order-summary-footer__value">
                                <DisplayPrice
                                  value={quoteRes.data.products?.reduce(
                                    (result, product) =>
                                      result +
                                      Number(product.pivot.quoted_price ?? product.price) * product.pivot.quantity,
                                    0
                                  )}
                                />
                                &nbsp;USD
                              </p>
                            </div>
                          </div>
                          {directBuyer && (
                            <div className="c-order-summary-footer__row">
                              <div>
                                <p className="c-order-summary-footer__label">Shipping</p>
                              </div>
                              <div>
                                <p className="c-order-summary-footer__value">
                                  {total > 999.99 ? (
                                    'Free Shipping'
                                  ) : (
                                    <>
                                      <DisplayPrice
                                        value={
                                          quoteRes.data.products?.reduce(
                                            (result, product) =>
                                              result + Number(product.shipping_weight) * product.pivot.quantity * 0.25,
                                            0
                                          ) + 11.05 ?? 0
                                        }
                                      />
                                      &nbsp;USD
                                    </>
                                  )}
                                </p>
                              </div>
                            </div>
                          )}
                          <div className="c-order-summary-footer__row">
                            <div>
                              <p className="c-order-summary-footer__label">Total price</p>
                            </div>

                            <div>
                              <p className="c-order-summary-footer__value">
                                <DisplayPrice
                                  value={
                                    total > 999.99
                                      ? total
                                      : total +
                                          quoteRes.data.products?.reduce(
                                            (result, product) =>
                                              result + Number(product.shipping_weight) * product.pivot.quantity * 0.25,
                                            0
                                          ) +
                                          11.05 ?? 0
                                  }
                                />
                                &nbsp;USD
                              </p>
                            </div>
                          </div>
                        </footer>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="c-block u-mb-spacer-base-large u-mb-spacer-section-large@md">
                <div className="o-container-fluid">
                  <div className="o-row">
                    <div className="o-col-12">
                      <div className="c-chat">
                        {quoteRes.data.responses && quoteRes.data.responses.length > 0 && (
                          <div className="c-chat__row">
                            <div className="c-chat__column c-chat__column--large">
                              {quoteRes.data.responses.map((response) => (
                                <QuoteResponse response={response} key={response.id} />
                              ))}
                            </div>
                          </div>
                        )}

                        <QuoteRespondForm id={Number(id)} />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          ) : (
            <Block403Error />
          )}
        </>
      )}
    </>
  );
}
