import * as React from 'react';
import { z } from 'zod';
import { Link, useHistory } from 'react-router-dom';
import { ForbiddenError, subject } from '@casl/ability';
import { useQueryClient } from 'react-query';
import merge from 'lodash/merge';

import { Agency } from '__generated-api__';
import api from 'api';
import { useAbility } from 'auth';
import { useMutation } from 'hooks/query';
import Form from 'components/Form/Form';
import InputField from 'components/Form/InputField';
import SubmitButton from 'components/Form/SubmitButton';
import Icon from 'components/icon';
import { useToast } from 'my-account/toast';
import { useController } from 'react-hook-form';
import { Accordion } from 'components/Accordion';
import { getUserName } from 'my-account/utils/user';

const AgencyUpdateSchema = z.object({
  name: z.string().nonempty(),
  users: z.array(
    z.object({
      id: z.number(),
      first_name: z.string().optional().nullable(),
      last_name: z.string().optional().nullable(),
      email: z.string().email().optional().nullable(),
      phone: z.string().nullable().optional(),
    })
  ),
});

const getAgencyInitialValues = (agency: Agency | undefined): z.infer<typeof AgencyUpdateSchema> => {
  if (typeof agency !== 'undefined') {
    return {
      name: agency.name,
      users: agency.users || [],
    };
  }

  return {
    name: '',
    users: [],
  };
};

const AgencyRepsField = () => {
  const arrayField = useController<z.infer<typeof AgencyUpdateSchema>, 'users'>({
    name: 'users',
  });

  return (
    <div className="o-row">
      <div className="o-col-3@md">
        <p className="u-text-xs u-uppercase u-mb-spacer-base-small">Agency representatives</p>
        <p className="c-note">
          This is the list of agency representatives that will be able to access the platform. They have the same
          permissions as Sales Representatives, able to access companies and locations, view orders, and create quote
          requests.
        </p>
      </div>

      <div className="o-col-9@md">
        <div className="o-row o-row--small-gutters">
          <div className="o-col-12">
            {arrayField.field.value.map((user) => (
              <Accordion
                key={user.id}
                title={getUserName(user).name}
                type="card"
                toggleActions={
                  <div className="u-hidden u-block@sm">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <Link to={`/users/${user.id}`} className="c-link-cta-basic c-link-cta--small">
                      <span>Edit representative</span>
                    </Link>
                  </div>
                }
              >
                <div className="o-row">
                  <div className="o-col-6@lg">
                    <div className="c-table-wrapper">
                      <table>
                        <tbody>
                          {Boolean(user.phone) && (
                            <tr>
                              <th>T:</th>
                              <td>{user.phone}</td>
                            </tr>
                          )}
                          <tr>
                            <th>E:</th>
                            <td>
                              <a href={`mailto:${user.email}`}>{user.email}</a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Accordion>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export const EditAgencyForm: React.VFC<{ agency?: Agency }> = ({ agency }) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();
  // const auth = useAuth();
  const ability = useAbility();
  const [createAgency] = useMutation(api.agency.createAgency);
  const [updateAgency] = useMutation(api.agency.updateAgency);
  const [deleteAgency] = useMutation(api.agency.deleteAgency);
  // const isAdmin = auth.status === AuthStatus.LoggedIn && auth.currentUser.role === AdminUserRoleEnum.Admin;

  const canSubmit = agency ? ability.can('update', subject('Agency', agency)) : ability.can('create', 'Agency');
  const canRemove = agency ? ability.can('delete', subject('Agency', agency)) : false;

  return (
    <>
      <Form
        schema={AgencyUpdateSchema}
        onSubmit={async (values, ctx) => {
          ForbiddenError.from(ability).throwUnlessCan(
            agency ? 'update' : 'create',
            subject('Agency', merge({}, agency, values))
          );

          const data = {
            ...values,
          };

          if (typeof agency === 'undefined') {
            const res = await createAgency([{ createAgencyBody: { ...data } }]);
            ctx.reset(getAgencyInitialValues(res.data));
            history.push(`/agencies/${res.data.id}`);

            toast.notify({
              type: 'success',
              title: 'Success',
              message: "You've successfully created a new Agency.",
            });
          } else {
            const res = await updateAgency([{ id: agency.id, updateAgencyBody: { ...data } }]);
            ctx.reset(getAgencyInitialValues(res.data));

            toast.notify({
              type: 'success',
              title: 'Success',
              message: "You've successfully updated the Agency.",
            });
          }
        }}
        initialValues={getAgencyInitialValues(agency)}
        className="u-mb-0"
        hasFloatingLabels
        formProps={{ mode: 'onSubmit' }}
      >
        <section className="c-block c-block--spacing-t-extra-small c-block--spacing-b c-block--spacing-b-large@md">
          <div className="o-container-fluid">
            <div className="o-row">
              <div className="o-col-3@md">
                <p className="u-text-xs u-uppercase u-mb-spacer-base-small">General info</p>
                <p className="c-note">Enter the agency name into the Agency name field.</p>
              </div>

              <div className="o-col-9@md o-col-6@lg">
                <div className="o-row o-row--small-gutters">
                  <div className="o-col-12">
                    <InputField name="name" label="Agency Name" elStyle="fill" />
                  </div>
                </div>
              </div>
            </div>

            <div className="o-row">
              <div className="o-col-12">
                <hr className="u-mt-spacer-base u-mt-spacer-base-large@sm u-mb-spacer-base-large u-mb-spacer-section-small@sm" />
              </div>
            </div>

            <AgencyRepsField />

            <div className="o-row">
              <div className="o-col-12">
                <hr className="u-mt-spacer-base u-mt-spacer-base-large@sm u-mb-spacer-base-large u-mb-spacer-section-small@sm" />
              </div>
            </div>

            <div className="o-row u-items-center">
              <div className="o-col-4@md o-offset-3@md">
                <div className="c-form-footer">
                  <SubmitButton variant="secondary" isFull disabled={!canSubmit}>
                    Save changes
                  </SubmitButton>
                </div>
              </div>

              {canRemove && agency && (
                <div className="o-col-5@md u-text-right@md">
                  {agency.status ? (
                    /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
                    <a
                      href="#"
                      className="c-link-cta-basic c-link-cta--small u-mt-spacer-base u-mt-0@md u-mb-0"
                      onClick={async (event) => {
                        event.preventDefault();

                        if (window.confirm(`Are you sure that you really want to archive "${agency.name}" agency?`)) {
                          await deleteAgency([{ id: agency.id }]);

                          await Promise.all([
                            queryClient
                              .cancelQueries(api.agency.listAgencies.getQueryKey()[0])
                              .then(() => queryClient.invalidateQueries(api.agency.listAgencies.getQueryKey()[0])),
                            queryClient
                              .cancelQueries(api.agency.getAgency.getQueryKey({ id: agency.id })[0])
                              .then(() =>
                                queryClient.invalidateQueries(api.agency.getAgency.getQueryKey({ id: agency.id })[0])
                              ),
                          ]);

                          history.push('/agencies');
                          toast.notify({
                            type: 'success',
                            title: 'Success',
                            message: `The "${agency.name}" agency was archived successfully.`,
                          });
                        }
                      }}
                    >
                      <Icon name="trash" className="o-svg-icon o-svg-larger" />
                      <span>Archive agency</span>
                    </a>
                  ) : (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                      href="#"
                      className="c-link-cta-basic c-link-cta--small u-mt-spacer-base u-mt-0@md u-mb-0"
                      onClick={async (event) => {
                        event.preventDefault();

                        if (window.confirm(`Are you sure that you really want to activate "${agency.name}" agency?`)) {
                          await updateAgency([
                            {
                              id: agency.id,
                              updateAgencyBody: {
                                name: agency.name,
                                status: 1,
                              },
                            },
                          ]);
                          await Promise.all([
                            queryClient
                              .cancelQueries(api.agency.listAgencies.getQueryKey()[0])
                              .then(() => queryClient.invalidateQueries(api.agency.listAgencies.getQueryKey()[0])),
                            queryClient
                              .cancelQueries(api.agency.getAgency.getQueryKey({ id: agency.id })[0])
                              .then(() =>
                                queryClient.invalidateQueries(api.agency.getAgency.getQueryKey({ id: agency.id })[0])
                              ),
                          ]);
                          toast.notify({
                            type: 'success',
                            title: 'Success',
                            message: `The "${agency.name}" agency was activated successfully.`,
                          });
                        }
                      }}
                    >
                      <Icon name="export" className="o-svg-icon o-svg-larger" />
                      <span>Activate agency</span>
                    </a>
                  )}
                </div>
              )}
            </div>
          </div>
        </section>
      </Form>
    </>
  );
};
