import * as React from 'react';
import { Text, View, Link } from '@react-pdf/renderer';
import ReactMarkdown from 'react-markdown';
import { visitParents } from 'unist-util-visit-parents';
import { Node } from 'unist';
import ImageBlock from 'spb-pdf/components/ImageBlock';
import { UseCroppedImageProps } from 'spb-pdf/utils/image';

export interface NotesProps {
  text: string;
  image?: UseCroppedImageProps['image'];
  image_settings?: UseCroppedImageProps['settings'];
  type: 'text' | 'image';
}

type BlockSize = 'small' | 'medium' | 'large';

function rehypeWrapText() {
  return function wrapTextTransform(tree: Node) {
    visitParents(tree, 'text', (node, ancestors) => {
      // @ts-ignore
      if (!['text', 'a', 'h1', 'em', 'strong', 'br'].includes(ancestors[ancestors.length - 1].tagName)) {
        // @ts-ignore
        node.type = 'element';
        // @ts-ignore
        node.tagName = 'text';
        // @ts-ignore
        node.children = [{ type: 'text', value: `${node.value}` }];
      }
    });
  };
}
/**
 * Render the notes page to pdf
 * @param blocks NotesProps[]
 * @returns React.VFC<{ blocks: NotesProps[] }>
 */
const Notes: React.VFC<{ blocks: NotesProps[]; layout?: 'alternate' | null }> = ({ blocks, layout }) => {
  return (
    <View style={{ width: '100%', height: '100%' }}>
      {blocks.map((block, index) => (
        <NotesImageOrText
          key={index}
          block={block}
          index={index}
          size={blocks.length > 2 && layout !== 'alternate' ? 'small' : 'medium'}
          layout={layout}
        />
      ))}
    </View>
  );
};

const NotesImageOrText: React.VFC<{
  block: NotesProps;
  index: number;
  size: BlockSize;
  layout?: 'alternate' | null;
}> = ({ block, index, layout, size }) => {
  if (block.type === 'image' && block.image) {
    return (
      <NotesImage key={index} image={block.image} image_settings={block.image_settings} layout={layout} size={size} />
    );
  }
  return <NotesText key={index}>{block.text}</NotesText>;
};

const NotesImage: React.VFC<{
  image: UseCroppedImageProps['image'];
  image_settings: UseCroppedImageProps['settings'];
  layout?: 'alternate' | null;
  size: BlockSize;
}> = ({ image, image_settings, layout, size }) => {
  const height = size === 'small' ? 150 : size === 'medium' ? 300 : 600;
  return (
    <View style={{ width: '100%', height: height }}>
      {image ? (
        <View
          style={{
            width: '100%',
            height: height,
            alignItems: 'center',
            justifyContent: 'center',
            flexGrow: 1,
            flexShrink: 1,
          }}
        >
          <ImageBlock
            image={{ content: image, settings: image_settings }}
            style={[
              { objectFit: 'cover' },
              { width: '100%', height: height, objectFit: layout === 'alternate' ? 'scale-down' : 'cover' },
            ]}
          />
        </View>
      ) : (
        <View style={{ width: '100%', flexGrow: 1, flexShrink: 1 }} />
      )}
    </View>
  );
};

const NotesText: React.VFC<{ children: string }> = ({ children }) => {
  return (
    <View style={{ paddingVertical: 20, paddingHorizontal: 45 }}>
      <ReactMarkdown
        allowedElements={['h1', 'em', 'strong', 'br', 'p', 'a', 'text']}
        rehypePlugins={[rehypeWrapText]}
        skipHtml={true}
        unwrapDisallowed={true}
        components={{
          h1(props) {
            return (
              <Text
                style={{
                  fontFamily: 'Gotham Rounded',
                  fontWeight: 200,
                  fontSize: 18,
                  color: '#000000',
                  opacity: 0.8,
                  letterSpacing: -0.39,
                  marginBottom: 24,
                  flexShrink: 0,
                }}
              >
                {props.children}
              </Text>
            );
          },
          p(props) {
            return (
              <Text
                style={{
                  fontFamily: 'Gotham Rounded',
                  fontSize: 8,
                  color: '#666666',
                  lineHeight: 1.5,
                  flexShrink: 0,
                }}
              >
                {props.children}
              </Text>
            );
          },
          em({ children }) {
            return (
              <Text
                style={{
                  fontStyle: 'italic',
                  fontFamily: 'Gotham Rounded',
                  fontSize: 8,
                  color: '#666666',
                  lineHeight: 1.5,
                }}
              >
                {children}
              </Text>
            );
          },
          strong({ children }) {
            return (
              <Text
                style={{
                  fontWeight: 600,
                  fontFamily: 'Gotham Rounded',
                  fontSize: 8,
                  color: '#666666',
                  lineHeight: 1.5,
                }}
              >
                {children}
              </Text>
            );
          },
          br() {
            return (
              <Text
                style={{
                  fontFamily: 'Gotham Rounded',
                  fontSize: 8,
                  color: '#666666',
                  lineHeight: 1.5,
                  flexShrink: 0,
                }}
              >
                {'\n'}
              </Text>
            );
          },
          a({ href, children }) {
            return (
              <Link
                src={href ?? '#'}
                style={{
                  fontFamily: 'Gotham Rounded',
                  fontSize: 8,
                  color: '#57AE55',
                  lineHeight: 1.5,
                  textDecoration: 'none',
                  flexShrink: 0,
                }}
              >
                <Text>{children}</Text>
              </Link>
            );
          },
          text(props) {
            return (
              <Text style={{ fontFamily: 'Gotham Rounded', fontSize: 9, color: '#666666', flexShrink: 0 }}>
                {props.children}
              </Text>
            );
          },
        }}
        children={children}
      />
    </View>
  );
};

export default Notes;
