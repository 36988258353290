import api from 'api';
import { SelectListingItemProps, SelectListingItem } from 'my-account/components/SelectListingItem';

export interface SelectSalesforceCustomerProps
  extends Omit<
    SelectListingItemProps<typeof api.salesforce.listSalesforceCustomers>,
    'queryFn' | 'getOptionLabel' | 'getOptionValue' | 'queryParams'
  > {
  queryParams?: SelectListingItemProps<typeof api.salesforce.listSalesforceCustomers>['queryParams'];
}

export const SelectSalesforceCustomer = ({ queryParams = {}, ...props }: SelectSalesforceCustomerProps) => {
  return (
    <SelectListingItem
      {...props}
      queryFn={api.salesforce.listSalesforceCustomers}
      queryParams={queryParams}
      getOptionLabel={(customer) => customer.name}
      getOptionValue={(customer) => String(customer.id)}
    />
  );
};
