import * as React from 'react';
import { z } from 'zod';
import { useController, useFormContext } from 'react-hook-form';
import axios from 'axios';
import classNames from 'classnames';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import pick from 'lodash/pick';

import { IntroductionBlockType, IntroductionStatus, Introduction } from '__generated-api__';
import api from 'api';

import Icon from 'components/icon';
import { Accordion, AccordionWithHideToggle } from 'components/Accordion';
import { Button } from 'components/Button';
import Form, { FormProvider, useForm } from 'components/Form/Form';
import { AutoSubmit } from 'components/Form/AutoSubmit';
import InputField from 'components/Form/InputField';
import SelectField from 'components/Form/SelectField';
import EditorField from 'components/Form/EditorField';
import SubmitButton from 'components/Form/SubmitButton';

import { Modal } from 'my-account/components/Modal';
import { DisplayFieldErrors, DisplayReactQueryError } from 'my-account/components/DisplayFieldError';
import { getLocalBlockId, IntroductionBlock } from 'my-account/validations/project';
import { DataTableClearFilterForm, DataTableListing } from 'my-account/components/DataTable/Listing';
import { getCounter } from 'my-account/utils/counter';
import { useImageUpload } from 'my-account/utils/image-upload';

import EditImageModalContent from './ImageBlockField/modal-contents/edit';
import { FileImageRelationUpdateSchema, ImageSettings } from 'my-account/validations/file';
import MediaLibraryModalContent from './ImageBlockField/modal-contents/library';
import { UploadImageProgress } from './ImageBlockField/modal-contents/upload-progress';
import BlockContentImage from './BlockContentImage';
import { ChooseImagePlaceholder } from './ImageBlockField/modal-contents/choose';

const getLocalIntroductionBlockFieldId = getCounter(0, 'introduction_block_field_');

const introductionsLibraryFiltersSchema = z.object({
  status: z.union([z.literal('1'), z.literal('0')]).optional(),
});

const IntroductionsLibraryFilters: React.VFC<{
  filters: z.infer<typeof introductionsLibraryFiltersSchema>;
  setFilters: React.Dispatch<React.SetStateAction<z.infer<typeof introductionsLibraryFiltersSchema>>>;
}> = ({ filters, setFilters }) => {
  return (
    <>
      <Form
        schema={introductionsLibraryFiltersSchema}
        initialValues={filters}
        onSubmit={async (values) => {
          setFilters(values);
        }}
        stopSubmitPropagation
      >
        <DataTableClearFilterForm />
        <AutoSubmit />
        <SelectField name="status" label="Status" elStyle="fill" small>
          <option value="1">Active</option>
          <option value="0">Archived</option>
        </SelectField>
      </Form>
    </>
  );
};

const IntroductionsLibrary: React.VFC<{
  title: string;
  onInsert: (introductions: [IntroductionValueType] | Introduction[]) => void;
}> = ({ title, onInsert }) => {
  const fieldId = React.useMemo(() => getLocalIntroductionBlockFieldId(), []);
  const [selectedItems, setSelectedItems] = React.useState<Introduction[]>([]);
  const [filters, setFilters] = React.useState<z.infer<typeof introductionsLibraryFiltersSchema>>({
    status: undefined,
  });

  return (
    <div className="c-block c-block--spacing-t-small c-block--spacing-b">
      <div className="o-container-fluid">
        <div className="o-row">
          <div className="o-col-12">
            <div className="c-block__header c-block__header--hor">
              <div className="c-block__header-content u-items-center u-block u-flex@sm">
                <div>
                  <h6 className="c-headline u-text-neutral-500">{title}</h6>
                  <h4 className="u-mr-spacer-base-small">Add Introduction</h4>
                </div>

                <div className="u-ml-auto@sm">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    href="#"
                    className="c-link-cta"
                    onClick={(event) => {
                      event.preventDefault();
                      onInsert([
                        {
                          id: null,
                          status: IntroductionStatus.Active,
                          content: '',
                          image: undefined,
                          image_settings: null,
                          name: '',
                          link: '',
                          show_link: false,
                        },
                      ]);
                    }}
                  >
                    <Icon name="add-thin" className="o-svg-icon" />
                    <span>Add custom introduction</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DataTableListing
          label="introductions"
          availableSortOptions={{
            created_at: 'Latest',
            name: 'Alphabetical',
            updated_at: 'Recently Updated',
          }}
          defaultSort="name"
          defaultDirection="desc"
          queryFn={api.introduction.listIntroductions}
          queryFnParams={(filters, searchParams) => {
            const status: IntroductionStatus[] | undefined = searchParams.has('status')
              ? searchParams
                  .getAll('status')
                  .filter((val): val is IntroductionStatus =>
                    [IntroductionStatus.Active, IntroductionStatus.Inactive].includes(
                      val as unknown as IntroductionStatus
                    )
                  )
              : undefined;

            return { ...filters, status };
          }}
          filters={() => <IntroductionsLibraryFilters filters={filters} setFilters={setFilters} />}
        >
          {(data) => (
            <ul className="o-stack-2@sm o-stack-3@md c-card__row--intros">
              {data.map((introduction) => (
                <li key={introduction.id}>
                  <article className="c-card c-card--intro c-card--link c-card--active">
                    <div className="c-card__body">
                      <div className="c-card__select-btn">
                        <div className="c-form-element c-form-element--style-line c-form-element--checkbox">
                          <div className="c-form-element__field">
                            <input
                              type="checkbox"
                              id={`${fieldId}_${introduction.id}`}
                              checked={typeof selectedItems.find((item) => item.id === introduction.id) !== 'undefined'}
                              onChange={(event) => {
                                setSelectedItems((items) => {
                                  if (!event.target.checked) {
                                    return items.filter((item) => item.id !== introduction.id);
                                  }

                                  return [...items, { ...introduction }];
                                });
                              }}
                            />
                            <label htmlFor={`${fieldId}_${introduction.id}`} />
                          </div>
                        </div>
                        <span
                          className="c-button c-button--full c-button--square c-button--primary u-mb-0"
                          onClick={(event) => {
                            event.preventDefault();
                            if (selectedItems.length === 0) {
                              onInsert([{ ...introduction }]);
                            } else if (
                              typeof selectedItems.find((item) => item.id === introduction.id) !== 'undefined'
                            ) {
                              onInsert(selectedItems);
                            } else {
                              setSelectedItems((items) => {
                                return [...items, { ...introduction }];
                              });
                            }
                          }}
                        >
                          <Icon name="add" className="o-svg-icon" />
                          Insert
                        </span>
                      </div>
                      <figure className="c-card__figure">
                        {introduction.image && <img src={introduction.image.thumbnail} alt={introduction.name} />}
                      </figure>
                      <header className="c-card__header">
                        <h4 className="c-card__title">{introduction.name}</h4>
                        <p className="c-card__subtitle">
                          {introduction.content.length > 56
                            ? introduction.content.substring(0, 53) + ' ...'
                            : introduction.content}
                        </p>
                      </header>
                    </div>
                  </article>
                </li>
              ))}
            </ul>
          )}
        </DataTableListing>
        <div className="u-text-right">
          <Button
            disabled={selectedItems.length === 0}
            onClick={(event) => {
              event.preventDefault();
              onInsert(selectedItems);
            }}
          >
            Insert {selectedItems.length > 1 ? `${selectedItems.length} items` : 'item'}
          </Button>
        </div>
      </div>
    </div>
  );
};

type IntroductionValueType = Exclude<IntroductionBlockFieldType['block']['content'], undefined>;

interface IntroductionDetailsModalContentProps {
  title: string;
  openIntroductionsLibrary: () => void;
  clearIntroduction: () => void;
  openImageEdit: () => void;
  openImageLibrary: () => void;
  formProps?: JSX.IntrinsicElements['form'];
}

const getRevertedKey = getCounter(0, 'reverted_');

const IntroductionDetailsModalContent: React.VFC<IntroductionDetailsModalContentProps> = ({
  title,
  openIntroductionsLibrary,
  clearIntroduction,
  openImageEdit,
  openImageLibrary,
  formProps,
}) => {
  const {
    formState: { errors },
  } = useFormContext<IntroductionBlockFieldType['block']>();
  const contentField = useController<IntroductionBlockFieldType['block'], 'content'>({ name: 'content' });
  const settingsField = useController<IntroductionBlockFieldType['block'], 'settings'>({ name: 'settings' });
  const { getRootProps, getInputProps, isDragActive, open, uploadImageState, uploadProgress, cancelUpload } =
    useImageUpload({
      onChange: (data) => {
        if (typeof contentField.field.value !== 'undefined' && typeof contentField.field.value.id === 'number') {
          settingsField.field.onChange({
            ...settingsField.field.value,
            image: data,
            image_settings: null,
          });
        } else {
          contentField.field.onChange({
            ...contentField.field.value,
            image: data,
            image_settings: null,
          });
        }
      },
    });

  const [revertedKey, setRevertedKey] = React.useState(getRevertedKey());

  const [isImageAccordionOpen, setIsImageAccordionOpen] = React.useState(false);
  const [isContentAccordionOpen, setIsContentAccordionOpen] = React.useState(false);
  const [isLinkAccordionOpen, setIsLinkAccordionOpen] = React.useState(false);

  return (
    <div className="c-block c-block--spacing-t-small c-block--spacing-b-small">
      <div className="o-container-fluid">
        <div className="o-row">
          <div className="o-col-12">
            <div className="c-block__header c-block__header--hor">
              <div className="c-block__header-content u-items-center u-block u-flex@sm">
                <div>
                  <h6 className="c-headline u-text-neutral-500">{title}</h6>
                  <h4>Introduction Details</h4>
                </div>
              </div>
            </div>
            <div className="u-mb-spacer-base">
              <button
                className="c-button c-button--light-grey c-button--option"
                type="button"
                onClick={(event) => {
                  event.preventDefault();
                  openIntroductionsLibrary();
                }}
              >
                <Icon name="refresh" className="o-svg-icon o-svg-large" />
                <span>Change Introduction</span>
              </button>{' '}
              <button
                className="c-button c-button--light-grey c-button--option"
                type="button"
                onClick={(event) => {
                  event.preventDefault();
                  clearIntroduction();
                }}
              >
                <Icon name="close" className="o-svg-icon" />
                <span>Clear Introduction</span>
              </button>
            </div>
            <form {...formProps}>
              <input {...getInputProps()} />
              <Accordion
                title="Image"
                type="framed"
                isRequired
                initialValue={isImageAccordionOpen}
                onChange={setIsImageAccordionOpen}
                isInvalid={
                  (uploadImageState.isError &&
                    Boolean(uploadImageState.error) &&
                    !axios.isCancel(uploadImageState.error)) ||
                  typeof errors.content?.image !== 'undefined' ||
                  typeof errors.content?.image_settings !== 'undefined' ||
                  typeof errors.settings?.image !== 'undefined' ||
                  typeof errors.settings?.image_settings !== 'undefined'
                }
              >
                <div {...getRootProps()}>
                  {isDragActive ? (
                    <div className="c-add-media c-add-media--active u-mb-spacer-base">
                      <div>
                        <Icon name="upload" className="o-svg-lg" />
                      </div>

                      <div>
                        <button className="c-link-cta-basic c-link-cta--small" type="button">
                          <span>Drop file here...</span>
                        </button>
                      </div>
                    </div>
                  ) : uploadImageState.isLoading ? (
                    <UploadImageProgress title="" uploadProgress={uploadProgress} cancelUpload={cancelUpload} />
                  ) : typeof settingsField.field.value?.image !== 'undefined' &&
                    settingsField.field.value?.image !== null ? (
                    <BlockContentImage
                      openImageLibrary={openImageLibrary}
                      openImageEdit={openImageEdit}
                      openFileUpload={open}
                      clearImage={() => {
                        settingsField.field.onChange({
                          ...settingsField.field.value,
                          image: undefined,
                          image_settings: undefined,
                        });
                      }}
                      image={settingsField.field.value?.image}
                      image_settings={settingsField.field.value?.image_settings}
                    />
                  ) : contentField.field.value?.image && settingsField.field.value?.image !== null ? (
                    <BlockContentImage
                      openImageLibrary={openImageLibrary}
                      openImageEdit={openImageEdit}
                      openFileUpload={open}
                      clearImage={() => {
                        if (
                          typeof contentField.field.value !== 'undefined' &&
                          typeof contentField.field.value.id === 'number'
                        ) {
                          settingsField.field.onChange({
                            ...settingsField.field.value,
                            image: null,
                            image_settings: null,
                          });
                        } else {
                          contentField.field.onChange({
                            ...contentField.field.value,
                            image: null,
                            image_settings: null,
                          });
                        }
                      }}
                      image={contentField.field.value.image}
                      image_settings={
                        typeof settingsField.field.value?.image_settings !== 'undefined'
                          ? settingsField.field.value.image_settings
                          : contentField.field.value.image_settings ?? undefined
                      }
                    />
                  ) : (
                    <ChooseImagePlaceholder openLibrary={openImageLibrary} open={open} />
                  )}
                  {uploadImageState.isError && uploadImageState.error && !axios.isCancel(uploadImageState.error) && (
                    <DisplayReactQueryError error={uploadImageState.error} />
                  )}

                  <DisplayFieldErrors
                    errors={pick(
                      errors,
                      'content.image',
                      'content.image_settings',
                      'settings.image',
                      'settings.image_settings'
                    )}
                    hideKeyLabels
                    style={{ marginTop: '-15px', marginBottom: '20px' }}
                  />
                </div>
              </Accordion>
              {!isImageAccordionOpen && (
                <DisplayFieldErrors
                  errors={pick(
                    errors,
                    'content.image',
                    'content.image_settings',
                    'settings.image',
                    'settings.image_settings'
                  )}
                  hideKeyLabels
                  style={{ marginTop: '-15px', marginBottom: '20px' }}
                />
              )}
              <Accordion
                title="Content"
                type="framed"
                isRequired
                initialValue={isContentAccordionOpen}
                onChange={setIsContentAccordionOpen}
                isInvalid={
                  (typeof contentField.field.value?.id === 'number' &&
                    (typeof errors.settings?.name !== 'undefined' ||
                      typeof errors.settings?.content !== 'undefined')) ||
                  typeof errors.content?.name !== 'undefined' ||
                  typeof errors.content?.content !== 'undefined'
                }
              >
                <React.Fragment key={revertedKey}>
                  <InputField
                    name={typeof contentField.field.value?.id === 'number' ? 'settings.name' : 'content.name'}
                    label="Name"
                    elStyle="fill"
                    defaultValue={(settingsField.field.value?.name || contentField.field.value?.name) ?? ''}
                  />
                  <EditorField
                    name={typeof contentField.field.value?.id === 'number' ? 'settings.content' : 'content.content'}
                    label="Content"
                    elStyle="fill"
                    defaultValue={(settingsField.field.value?.content || contentField.field.value?.content) ?? ''}
                    toolbar={['bold', 'italic']}
                  />
                </React.Fragment>
                {Boolean(
                  typeof contentField.field.value?.id === 'number' &&
                    (settingsField.field.value?.name !== contentField.field.value.name ||
                      settingsField.field.value?.content !== contentField.field.value.content)
                ) && (
                  <div className="u-text-right u-pb-spacer-base">
                    <button
                      className="c-button c-button--white c-button--option"
                      type="button"
                      onClick={(event) => {
                        event.preventDefault();
                        settingsField.field.onChange({
                          ...settingsField.field.value,
                          name: contentField.field.value?.name,
                          content: contentField.field.value?.content,
                        });
                        settingsField.field.onBlur();
                        setRevertedKey(getRevertedKey());
                      }}
                    >
                      <Icon name="back" className="o-svg-icon o-svg-large" />
                      <span>Revert to original</span>
                    </button>
                  </div>
                )}
              </Accordion>
              {!isContentAccordionOpen && (
                <DisplayFieldErrors
                  errors={pick(errors, 'content.name', 'content.content', 'settings.name', 'settings.content')}
                  hideKeyLabels
                  style={{ marginTop: '-15px', marginBottom: '20px' }}
                />
              )}
              <AccordionWithHideToggle
                title="Link"
                type="framed"
                name={typeof contentField.field.value?.id === 'number' ? 'settings.show_link' : 'content.show_link'}
                defaultValue={
                  typeof contentField.field.value?.id === 'number'
                    ? settingsField.field.value?.show_link || contentField.field.value?.show_link
                    : contentField.field.value?.show_link ?? false
                }
                initialValue={isLinkAccordionOpen}
                onChange={setIsLinkAccordionOpen}
                isInvalid={typeof errors.settings?.link !== 'undefined' || typeof errors.content?.link !== 'undefined'}
              >
                <InputField
                  name={typeof contentField.field.value?.id === 'number' ? 'settings.link' : 'content.link'}
                  placeholder="https://"
                  defaultValue={(settingsField.field.value?.link || contentField.field.value?.link) ?? ''}
                  elStyle="fill"
                />
              </AccordionWithHideToggle>
              {!isLinkAccordionOpen && (
                <DisplayFieldErrors
                  errors={pick(errors, 'content.link', 'settings.link')}
                  hideKeyLabels
                  style={{ marginTop: '-15px', marginBottom: '20px' }}
                />
              )}
              <SubmitButton>Save</SubmitButton>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

type ModalStateValue = 'closed' | 'choose' | 'details' | 'image-edit' | 'library' | 'image-library';

const IntroductionBlockModalFields: React.VFC<{
  title: string;
  modalState: ModalStateValue;
  setModalState: (value: ModalStateValue) => void;
  onInsert: (introductions: Introduction[]) => void;
  onClear: () => void;
  formProps?: JSX.IntrinsicElements['form'];
}> = ({ title, modalState, setModalState, onInsert, formProps, onClear }) => {
  const contentField = useController<IntroductionBlockFieldType['block'], 'content'>({ name: 'content' });
  const settingsField = useController<IntroductionBlockFieldType['block'], 'settings'>({ name: 'settings' });

  return (
    <div>
      {modalState === 'image-edit' &&
        (settingsField.field.value?.image ? (
          <EditImageModalContent
            title={title}
            image={settingsField.field.value.image}
            settings={settingsField.field.value.image_settings}
            onChange={(settings) => {
              settingsField.field.onChange({
                ...settingsField.field.value,
                image_settings: settings,
              });
              setModalState('details');
            }}
            onCancel={() => {
              setModalState('details');
            }}
          />
        ) : contentField.field.value?.image ? (
          <EditImageModalContent
            title={title}
            image={contentField.field.value.image}
            settings={
              settingsField.field.value?.image_settings
                ? settingsField.field.value.image_settings
                : contentField.field.value.image_settings ?? undefined
            }
            onChange={(settings) => {
              if (typeof contentField.field.value !== 'undefined' && typeof contentField.field.value.id === 'number') {
                settingsField.field.onChange({
                  ...settingsField.field.value,
                  image_settings: settings,
                });
              } else {
                contentField.field.onChange({
                  ...contentField.field.value,
                  image_settings: settings,
                });
              }

              setModalState('details');
            }}
            onCancel={() => {
              setModalState('details');
            }}
          />
        ) : null)}
      {modalState === 'details' && (
        <IntroductionDetailsModalContent
          title={title}
          formProps={formProps}
          clearIntroduction={() => {
            onClear();
          }}
          openImageEdit={() => {
            setModalState('image-edit');
          }}
          openImageLibrary={() => {
            setModalState('image-library');
          }}
          openIntroductionsLibrary={() => {
            setModalState('library');
          }}
        />
      )}
      {(modalState === 'library' || modalState === 'choose') && (
        <IntroductionsLibrary
          title={title}
          onInsert={(introductions) => {
            if (introductions.length === 1) {
              contentField.field.onChange({ ...introductions[0] });
              settingsField.field.onChange({
                name: introductions[0].name,
                content: introductions[0].content,
                image: undefined,
                image_settings: undefined,
                link: introductions[0].link,
                show_link: introductions[0].show_link,
              });

              setModalState('details');
            } else {
              const [updateIntroduction, ...otherIntroductions] = introductions as Introduction[];
              contentField.field.onChange({ ...updateIntroduction });
              settingsField.field.onChange({
                name: updateIntroduction.name,
                content: updateIntroduction.content,
                image: undefined,
                image_settings: undefined,
                link: updateIntroduction.link,
                show_link: updateIntroduction.show_link,
              });

              onInsert(otherIntroductions);
              setModalState('details');
            }
          }}
        />
      )}
      {modalState === 'image-library' && (
        <MediaLibraryModalContent
          title={title}
          onInsert={(image) => {
            if (typeof contentField.field.value !== 'undefined' && typeof contentField.field.value.id === 'number') {
              settingsField.field.onChange({
                ...settingsField.field.value,
                image: { ...image },
                image_settings: {
                  left: 0,
                  top: 0,
                  width: image.width,
                  height: image.height,
                  rotate: 0,
                  flip: 0,
                },
              });
            } else {
              contentField.field.onChange({
                ...contentField.field.value,
                image: { ...image },
                image_settings: {
                  left: 0,
                  top: 0,
                  width: image.width,
                  height: image.height,
                  rotate: 0,
                  flip: 0,
                },
              });
            }
            setModalState('details');
          }}
        />
      )}
    </div>
  );
};

const IntroductionBlockModalContent: React.VFC<{
  title: string;
  modalState: ModalStateValue;
  setModalState: (value: ModalStateValue) => void;
  value: z.infer<typeof IntroductionBlock>;
  onSubmit: (value: z.infer<typeof IntroductionBlock>) => void;
  onInsert: (introductions: Introduction[]) => void;
  onDirtyChange: (value: boolean) => void;
  onClear: () => void;
}> = ({ title, modalState, setModalState, value, onSubmit, onInsert, onDirtyChange, onClear }) => {
  const { context, formProps } = useForm({
    initialValues: value,
    schema: IntroductionBlock,
    onSubmit: async (values) => {
      onSubmit(values);
    },
    stopSubmitPropagation: true,
    disableFieldsOnSubmitting: true,
    hasFloatingLabels: true,
  });

  React.useEffect(() => {
    onDirtyChange(context.formState.isDirty);
  }, [context.formState.isDirty, onDirtyChange]);

  return (
    <FormProvider {...context}>
      <IntroductionBlockModalFields
        title={title}
        modalState={modalState}
        setModalState={setModalState}
        onInsert={onInsert}
        formProps={formProps}
        onClear={onClear}
      />
    </FormProvider>
  );
};

export interface IntroductionBlockFieldType {
  block: {
    id: number | string;
    type: typeof IntroductionBlockType.Introduction;
    content?: {
      id: number | null;
      name: string;
      content: string;
      status: IntroductionStatus;
      image?: z.infer<typeof FileImageRelationUpdateSchema> | null;
      image_settings?: z.infer<typeof ImageSettings> | null;
      link: string | null;
      show_link: boolean;
    };
    settings?: {
      name?: string;
      content?: string;
      image?: z.infer<typeof FileImageRelationUpdateSchema> | null;
      image_settings?: z.infer<typeof ImageSettings>;
      link?: string | null;
      show_link?: boolean;
    };
  };
}

interface IntroductionBlockFieldProps {
  name: string;
  title: string;
  buttonProps?: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
  onInsert: (introductions: Introduction[]) => void;
}

const IntroductionBlockField: React.VFC<IntroductionBlockFieldProps> = ({ name, title, buttonProps, onInsert }) => {
  const { field, fieldState } = useController<{ block: z.infer<typeof IntroductionBlock> }, 'block'>({
    name: name as 'block',
  });
  const onChange = (data: z.infer<typeof IntroductionBlock>) => {
    field.onChange(data);
  };
  const [isFormDirty, setIsFormDirty] = React.useState(false);
  const [modalState, setModalState] = React.useState<ModalStateValue>('closed');

  const onClose = () => {
    if (modalState !== 'details' && modalState !== 'choose') {
      setModalState('details');
    } else if (isFormDirty) {
      if (window.confirm('Do you really want to leave? You have unsaved changes!')) {
        setModalState('closed');
        field.onBlur();
      }
    } else {
      setModalState('closed');
      field.onBlur();
    }
  };

  return (
    <>
      <button
        {...buttonProps}
        className={classNames(
          'c-button c-button--white c-button--option c-button--full c-button--option-drag u-mb-spacer',
          {
            'is-invalid': typeof fieldState.error !== 'undefined',
          }
        )}
        onClick={(event) => {
          event.preventDefault();
          setModalState(field.value?.content ? 'details' : 'choose');
        }}
        type="button"
      >
        <span>
          <small>{title}</small>
          {field.value && field.value.content ? (
            typeof field.value.content.id === 'number' ? (
              field.value.settings?.name || field.value.content.name
            ) : (
              field.value.content.name
            )
          ) : (
            <>
              Add <Icon name="add-thin" className="o-svg-icon" />
            </>
          )}
        </span>
        <Icon name="pencil" className="o-svg-icon o-svg-large u-ml-auto" />
      </button>
      <DisplayFieldErrors errors={fieldState.error} />
      <Modal
        isOpen={modalState !== 'closed'}
        onRequestClose={() => {
          onClose();
        }}
        style={{
          content: {
            maxWidth:
              modalState === 'choose' || modalState === 'library' || modalState === 'image-library'
                ? '80rem'
                : '41.25rem',
          },
        }}
      >
        <div
          className={classNames('c-modal', { 'c-modal--dark c-color--invert': modalState === 'image-edit' })}
          style={{ width: '100%' }}
        >
          {/*eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */}
          <a
            href="#"
            className="c-modal-close"
            onClick={(event) => {
              event.preventDefault();
              onClose();
            }}
          />

          <div className="c-modal__main">
            {modalState !== 'closed' && (
              <IntroductionBlockModalContent
                title={title}
                modalState={modalState}
                setModalState={setModalState}
                value={field.value}
                onSubmit={(value) => {
                  onChange(value);
                  setModalState('closed');
                }}
                onInsert={onInsert}
                onDirtyChange={setIsFormDirty}
                onClear={() => {
                  field.onChange({
                    id: getLocalBlockId(),
                    type: IntroductionBlockType.Introduction,
                    content: undefined,
                    settings: undefined,
                  });
                  setModalState('closed');
                }}
              />
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export const SortableIntroductionBlockField: React.VFC<IntroductionBlockFieldProps & { id: string }> = ({
  id,
  ...props
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <IntroductionBlockField
      {...props}
      buttonProps={{ ref: setNodeRef, style, ...attributes, ...listeners, ...props.buttonProps }}
    />
  );
};

export default IntroductionBlockField;
