import * as React from 'react';
import { observer } from 'mobx-react';

import ProductSearchStore from 'search/stores/product-search-store';
import FinishRefinment from 'search/components/product-search/finish-refinment';

interface FinishHit {
  isRefined: boolean;
  label: string;
}

function compareFinishes(itemA: FinishHit, itemB: FinishHit): number {
  // if (itemA.isRefined && !itemB.isRefined) return -1;
  // if (!itemA.isRefined && itemB.isRefined) return 1;

  if (itemA.label !== itemB.label) {
    if (itemA.label === 'Polished Chrome') return -1;
    if (itemB.label === 'Polished Chrome') return 1;
    if (itemA.label < itemB.label) return -1;
    if (itemA.label > itemB.label) return 1;
  }

  return 0;
}

interface FinishFilterProps {
  store: ProductSearchStore;
}

const FinishFilter = observer(
  class FinishFilter extends React.Component<FinishFilterProps> {
    private containerRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
      const { store } = this.props;
      const { finishFilterOpened: opened } = store;
      if (!opened && this.containerRef.current) {
        this.containerRef.current.style.display = 'none';
      }
    }

    render() {
      const { store } = this.props;
      const { finishFilterOpened: opened } = store;
      const classes = ['c-filters__group', opened ? 'is-open' : ''];
      return (
        <>
          <div className={classes.join(' ')}>
            <div
              className="c-filters__header c-accordion-toggle"
              onClick={() => {
                store.setFinishFilterOpened(!opened);
                if (this.containerRef.current) {
                  jQuery(this.containerRef.current).stop(true).slideToggle();
                }
              }}
            >
              <p className="c-filters__title">Finishes</p>
            </div>
            <div className="c-filters__container c-accordion-container" ref={this.containerRef}>
              <FinishRefinment
                attribute="finishes"
                limit={5}
                showMore={true}
                showMoreLimit={100}
                transformItems={(items) => items.sort(compareFinishes)}
              />
            </div>
          </div>
        </>
      );
    }
  }
);

export default FinishFilter;
