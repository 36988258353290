import { Button } from 'components/Button';
import Form from 'components/Form/Form';
import InputField from 'components/Form/InputField';
import Icon from 'components/icon';
import { useToast } from 'my-account/toast';
import { z } from 'zod';
import { Project } from '__generated-api__';
import { Modal } from '../Modal';

const ShareModal: React.VFC<{ project: Project | undefined; open: boolean; close: () => void }> = ({
  project,
  open,
  close,
}) => {
  const toast = useToast();
  return (
    <Modal
      isOpen={open}
      onRequestClose={() => {
        close();
      }}
      style={{
        content: { maxWidth: '41.25rem', display: 'block', minHeight: 'auto', outline: 'none' },
        overlay: { zIndex: 1002 },
      }}
      id="modal-share"
      className="c-modal__wrapper c-modal__wrapper--sm"
    >
      <div className="c-modal c-modal--share">
        {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */}
        <a
          href="#"
          className="c-modal-close"
          onClick={(event) => {
            event.preventDefault();
            close();
          }}
        />

        <div className="c-modal__main">
          <div className="c-block c-block--spacing-t-small c-block--spacing-b-small">
            <div className="o-container-fluid">
              <div className="o-row">
                <div className="o-col-12">
                  <h4 className="u-mb-spacer-base-large">Share</h4>
                  <h6>Get public link</h6>
                  <Form
                    schema={z.object({
                      link: z.string().nonempty(),
                    })}
                    initialValues={{
                      link: `${window.location.protocol}//${window.location.host}/my-account/share/specification-packages/${project?.public_token}`,
                    }}
                    onSubmit={async (data, ctx) => {
                      close();
                    }}
                    hasFloatingLabels
                  >
                    <div className="o-row o-row--small-gutters">
                      <div className="o-col-9@md">
                        <div className="c-form-element c-form-element--style-fill">
                          <div className="c-form-element__field">
                            <div className="c-form-element__icon">
                              <Icon name="link" className="o-svg-icon" />
                            </div>
                            <div className="fl-wrap fl-wrap-input">
                              <InputField name="link" elStyle="fill" disabled={true} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="o-col-3@md">
                        <Button
                          className="c-button c-button--primary c-button--full u-mb-spacer-base"
                          onClick={(event) => {
                            event.preventDefault();
                            navigator.clipboard.writeText(
                              `${window.location.protocol}//${window.location.host}/my-account/share/specification-packages/${project?.public_token}`
                            );
                            toast.notify({
                              type: 'success',
                              title: 'Success',
                              message: 'The share link has been copied to your clipboard.',
                            });
                          }}
                        >
                          <Icon name="copy" className="o-svg-icon o-svg-large" />
                          <span>Copy</span>
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ShareModal;
