import * as React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { z } from 'zod';

import { FileVisibility } from '__generated-api__';
import api from 'api';
import { setLocationSearchParams } from 'utils/router';
import Form from 'components/Form/Form';
import { AutoSubmit } from 'components/Form/AutoSubmit';
import { FiltersAccordion } from 'components/Accordion';
import { CheckboxWithCountField } from 'components/Form/CheckboxField';
import Icon from 'components/icon';
import { MainHero } from 'my-account/components/MainHero';
import { DataTableHero } from 'my-account/components/DataTable/Hero';
import { DataTableClearFilterForm, DataTableListing } from 'my-account/components/DataTable/Listing';
import useDocumentTitle from 'hooks/useDocumentTitle';

const mediaLibraryFiltersSchema = z.object({
  visibility_hidden: z.boolean().optional(),
  visibility_private: z.boolean().optional(),
  visibility_location: z.boolean().optional(),
  visibility_company: z.boolean().optional(),
  visibility_public: z.boolean().optional(),
});

const MediaLibraryFilters: React.VFC<{
  counts?: { hidden: number; public: number; company: number; location: number; private: number };
}> = ({ counts }) => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = React.useMemo(() => new URLSearchParams(location.search), [location.search]);

  const initialValueParse = mediaLibraryFiltersSchema.safeParse({
    visibility_hidden: searchParams.getAll('visibility')?.includes('hidden') ?? false,
    visibility_private: searchParams.getAll('visibility')?.includes('private') ?? false,
    visibility_location: searchParams.getAll('visibility')?.includes('location') ?? false,
    visibility_company: searchParams.getAll('visibility')?.includes('company') ?? false,
    visibility_public: searchParams.getAll('visibility')?.includes('public') ?? false,
  });

  return (
    <Form
      schema={mediaLibraryFiltersSchema}
      initialValues={initialValueParse.success ? initialValueParse.data : {}}
      onSubmit={async (values) => {
        const visibility: Array<FileVisibility> = [];
        if (values.visibility_hidden) {
          visibility.push(FileVisibility.Hidden);
        }

        if (values.visibility_private) {
          visibility.push(FileVisibility.Private);
        }

        if (values.visibility_location) {
          visibility.push(FileVisibility.Location);
        }

        if (values.visibility_company) {
          visibility.push(FileVisibility.Company);
        }

        if (values.visibility_public) {
          visibility.push(FileVisibility.Public);
        }

        history.replace(
          setLocationSearchParams(location, {
            visibility,
            page: undefined,
            'list-page': undefined,
          })
        );
      }}
      isFiltersForm
    >
      <DataTableClearFilterForm />
      <AutoSubmit />

      <FiltersAccordion title="File Ownership">
        <div className="ais-RefinementList">
          <ul className="ais-RefinementList-list">
            {/* <CheckboxWithCountField label="Hidden" name="visibility_hidden" count={counts?.hidden} /> */}
            <CheckboxWithCountField label="Public" name="visibility_public" count={counts?.public} />
            {/* <CheckboxWithCountField label="Company" name="visibility_company" count={counts?.company} /> */}
            {/* <CheckboxWithCountField label="Location" name="visibility_location" count={counts?.location} /> */}
            <CheckboxWithCountField label="Private" name="visibility_private" count={counts?.private} />
          </ul>
        </div>
      </FiltersAccordion>
    </Form>
  );
};

export default function MediaLibraryPage() {
  useDocumentTitle('Media Library');

  return (
    <>
      <MainHero />
      <DataTableHero
        title="Media Library"
        buttons={
          <>
            <Link to="/media-library/add" className="c-button c-button--primary c-button--small u-my-spacer-base-small">
              <Icon name="add" className="o-svg-icon o-svg-right" />
              <span>Add new image</span>
            </Link>
          </>
        }
      />

      <section className="c-block c-block--spacing-b-extra-small c-block--spacing-b@md">
        <div className="o-container-fluid">
          <DataTableListing
            label="media library"
            availableSortOptions={{
              created_at: 'Latest',
              name: 'Alphabetical',
              updated_at: 'Recently Updated',
            }}
            defaultSort="created_at"
            defaultDirection="desc"
            defaultPerPage={48}
            queryFn={api.file.listFiles}
            queryFnParams={(filters, searchParams) => {
              const visibility: FileVisibility[] | undefined = searchParams.has('visibility')
                ? searchParams
                    .getAll('visibility')
                    .filter((val): val is FileVisibility =>
                      [
                        FileVisibility.Hidden,
                        FileVisibility.Private,
                        FileVisibility.Location,
                        FileVisibility.Company,
                        FileVisibility.Public,
                      ].includes(val as unknown as FileVisibility)
                    )
                : undefined;

              return { ...filters, visibility };
            }}
            filters={(query) => <MediaLibraryFilters counts={query[0]?.data?.filters?.visibility} />}
          >
            {(data) => (
              <ul className="o-stack-2 o-stack-3@sm o-stack-4@md c-card__row--media">
                {data.map((file) => (
                  <li key={file.id}>
                    <article className="c-card c-card--media c-card--link">
                      <div className="c-card__body">
                        <figure className="c-card__figure">
                          <Link to={`/media-library/${file.id}`}>
                            <img src={file.thumbnail} alt={file.name} />
                          </Link>
                        </figure>
                        <header className="c-card__header">
                          <h4 className="c-card__title">
                            <Link key={file.id} to={`/media-library/${file.id}`} className="c-card__link">
                              {file.title ?? file.name}
                            </Link>
                          </h4>
                        </header>
                      </div>
                    </article>
                  </li>
                ))}
              </ul>
            )}
          </DataTableListing>
        </div>
      </section>
    </>
  );
}
