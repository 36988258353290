import { AxiosResponse } from 'axios';
import classNames from 'classnames';
import { useMergeRefs } from 'hooks/useMergeRefs';
import * as React from 'react';
import { useController } from 'react-hook-form';
import Select, { GroupTypeBase, IndicatorProps, NamedProps, OptionTypeBase, components } from 'react-select';

import { Paginated } from '__generated-api__';
import FieldError from 'components/Form/FieldError';
import { useCustomFormState } from 'components/Form/Form';
import { BaseApiFn, GetPromiseReturnType, GetQueryFnParams, useInfiniteQuery } from 'hooks/query';

type BasePaginatedResponse = AxiosResponse<Paginated & { data: any[] }>;

interface ReactSelectProps<OptionType extends OptionTypeBase, isMulti extends boolean = false>
  extends Omit<
      React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>,
      keyof NamedProps<OptionType, isMulti>
    >,
    NamedProps<OptionType, isMulti> {
  ref: React.Ref<any>;
}

export interface SelectListingItemProps<
  QueryFn extends BaseApiFn<any[], BasePaginatedResponse>,
  isMulti extends boolean = false,
  // OptionType = GetPromiseReturnType<QueryFn>['data']['data'][number],
  QueryParams = GetQueryFnParams<QueryFn>
> {
  name: string;
  label?: string;
  placeholder?: string;
  helpText?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  isMulti?: isMulti;
  selectProps?: Omit<
    ReactSelectProps<OptionTypeBase, isMulti>,
    | 'ref'
    | 'name'
    | 'isDisabled'
    | 'className'
    | 'children'
    | 'onFocus'
    | 'onBlur'
    | 'onMenuScrollToBottom'
    | 'options'
    | 'placeholder'
    | 'getOptionLabel'
    | 'getOptionValue'
    | 'isMulti'
  >;
  queryFn: QueryFn;
  queryParams: QueryParams;
  getOptionLabel: Exclude<NamedProps<OptionTypeBase>['getOptionLabel'], undefined>;
  getOptionValue: Exclude<NamedProps<OptionTypeBase>['getOptionValue'], undefined>;
  elStyle?: 'fill';
  small?: boolean;
}

const DropdownIndicator = <
  OptionType extends OptionTypeBase,
  IsMulti extends boolean,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
>(
  props: IndicatorProps<OptionType, IsMulti, GroupType>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="10" viewBox="0 0 24 13.71">
        <path
          d="M12 9.58L21.07.5a1.74 1.74 0 012.43 0 1.72 1.72 0 010 2.43L13.21 13.22a1.73 1.73 0 01-2.42 0L.5 2.93A1.72 1.72 0 01.5.5a1.74 1.74 0 012.43 0z"
          fillRule="evenodd"
          fill="#4c4c4c"
        />
      </svg>
    </components.DropdownIndicator>
  );
};
export const SelectListingItem = <
  QueryFnParams extends { perPage?: number; search?: string; page?: number },
  QueryFn extends BaseApiFn<[QueryFnParams, ...any[]], BasePaginatedResponse>,
  isMulti extends boolean,
  OptionType = GetPromiseReturnType<QueryFn>['data']['data'][number]
>({
  name,
  label,
  placeholder,
  helpText,
  disabled,
  className,
  selectProps,
  queryFn,
  queryParams,
  getOptionLabel,
  getOptionValue,
  elStyle,
  small,
  ...props
}: SelectListingItemProps<QueryFn, isMulti, OptionType>) => {
  const { hasFloatingLabels, isFiltersForm } = useCustomFormState();
  const { field, fieldState } = useController({ name });
  const [inputValue, setInputValue] = React.useState('');
  const [queryRes, queryState] = useInfiniteQuery(
    queryFn,
    { ...queryParams, perPage: 16, search: inputValue.trim().length ? inputValue.trim() : undefined },
    {
      getNextPageParam: (lastPage) =>
        lastPage.data.current_page === lastPage.data.last_page ? undefined : lastPage.data.current_page + 1,
      onSuccess: (data) => {
        if (field.value) {
          // TODO: add support for multiple "loading" select items
          if (!Array.isArray(field.value)) {
            const id = getOptionValue(field.value);
            const defaultLabel = getOptionLabel(field.value);
            if (id && defaultLabel === 'Loading...') {
              // TODO: replace this with api call
              const items = data.pages.flatMap((queryPage) => queryPage.data.data);
              const item = items.find((item) => getOptionValue(item) === id);

              if (item) {
                field.onChange(item);
              }
            }
          }
        }
      },
    }
  );

  const wrapRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<Select<OptionTypeBase>>(null);
  const inputProps: ReactSelectProps<OptionTypeBase, isMulti> = {
    ...selectProps,
    value: field.value || null,
    inputValue,
    isDisabled: disabled,
    isLoading: queryState.isFetching,
    placeholder: placeholder || label,
    ref: useMergeRefs(field.ref, inputRef),
    getOptionLabel,
    getOptionValue,
    onChange: React.useCallback<Exclude<ReactSelectProps<OptionTypeBase, isMulti>['onChange'], undefined>>(
      (value, action) => {
        if (selectProps && selectProps.onChange) {
          selectProps.onChange(value, action);
        }

        field.onChange(value);
      },
      [selectProps, field]
    ),
    onBlur: React.useCallback<Exclude<NamedProps<OptionTypeBase, isMulti>['onBlur'], undefined>>(() => {
      field.onBlur();
      if (!wrapRef.current) return;

      wrapRef.current.classList.remove('fl-has-focus');
    }, [wrapRef, field]),
    onFocus: React.useCallback<Exclude<NamedProps<OptionTypeBase, isMulti>['onFocus'], undefined>>(() => {
      if (!wrapRef.current) return;

      wrapRef.current.classList.add('fl-has-focus');
    }, [wrapRef]),
    onInputChange: React.useCallback<Exclude<NamedProps<OptionTypeBase, isMulti>['onInputChange'], undefined>>(
      (value) => {
        setInputValue(value);
      },
      []
    ),
    onMenuScrollToBottom: () => {
      if (queryState.isFetching) {
        return;
      }

      queryState.fetchNextPage();
    },
    // @ts-ignore
    options: (typeof queryRes !== 'undefined'
      ? queryRes.pages.flatMap((queryPage) => queryPage.data.data)
      : []) as unknown as ReadonlyArray<OptionType>,
    styles: {
      control: (props) => ({
        ...props,
        border: '1px solid rgba( 0, 0, 0, .05 )',
        boxShadow: '0 1px .1875rem rgba(0, 0, 0, .05)',
        backgroundColor: '#fafafa',
        ':hover': {
          borderColor: 'rgba(41, 127, 203, .2)',
        },
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      indicatorsContainer: (props) => ({
        ...props,
        marginRight: '.5rem',
      }),
      valueContainer: (props) => ({
        ...props,
        paddingLeft: '.875rem',
        paddingTop: 0,
        paddingBottom: 0,
      }),
      singleValue: (props) => ({
        ...props,
        top: small ? '0.6rem' : '1.22917rem',
        transform: 'none',
        fontSize: small ? '.875rem' : '1rem',
        fontWeight: small ? '400' : '600',
        lineHeight: '1.25rem',
      }),
      multiValue: (props) => ({
        ...props,
        backgroundColor: '#333',
      }),
      multiValueLabel: (props) => ({
        ...props,
        color: '#fff',
      }),
      multiValueRemove: (props) => {
        return {
          ...props,
          color: '#999',
          ':hover': { ...props[':hover'], backgroundColor: undefined, color: '#e55446' },
        };
      },
      input: (props) => ({
        ...props,
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,
        marginBottom: 0,
        input: {
          minHeight: 'auto !important',
          paddingTop: small ? '0.6rem !important' : '1.22917rem !important',
          paddingBottom: small ? '0.65rem !important' : '.39583rem !important',
          boxShadow: 'none !important',
        },
      }),
      menu: (props) => ({
        ...props,
        zIndex: 6,
      }),
      menuList: (props) => ({
        ...props,
        paddingTop: 0,
        paddingBottom: 0,
        fontSize: small ? '.875rem' : '1rem',
      }),
      placeholder: (props) => ({
        ...props,
        fontSize: small ? '.875rem' : '1rem',
      }),
    },
    components: { DropdownIndicator },
    theme: (theme) => ({
      ...theme,
      colors: {
        ...theme.colors,
        primary: '#57ae55',
        primary25: 'rgba(0, 0, 0, .05)',
        primary50: 'rgba(0, 0, 0, .1)',
      },
    }),
    isMulti: props.isMulti,
  };
  const error = fieldState.error;

  React.useEffect(() => {
    if (!wrapRef.current) return;

    if (inputValue || field.value) {
      wrapRef.current.classList.add('fl-is-active');
    } else {
      wrapRef.current.classList.remove('fl-is-active');
    }
  }, [wrapRef, inputValue, field.value]);

  return (
    <div
      className={classNames(
        'c-form-element',
        'c-form-element--select',
        'c-form-element--react-select',
        {
          [`c-form-element--style-${elStyle}`]: elStyle,
          'c-form-element--small': small,
          'c-form-element--error': typeof error !== 'undefined',
        },
        className
      )}
    >
      {typeof label !== 'undefined' ? (
        hasFloatingLabels ? (
          <div className="c-form-element__field">
            <div className="fl-wrap fl-wrap-input" ref={wrapRef}>
              <label htmlFor={name} className={classNames('c-form-label', { 'c-filters__title': isFiltersForm })}>
                {label}
              </label>
              <Select {...inputProps} />
            </div>
          </div>
        ) : (
          <>
            <label htmlFor={name} className={classNames('c-form-label', { 'c-filters__title': isFiltersForm })}>
              {label}
            </label>
            <div className="c-form-element__field">
              <Select {...inputProps} />
            </div>
          </>
        )
      ) : (
        <div className="c-form-element__field">
          <Select {...inputProps} />
        </div>
      )}
      <FieldError error={error} />
      {typeof helpText !== 'undefined' && <p className="c-note">{helpText}</p>}
    </div>
  );
};
