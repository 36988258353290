import * as React from 'react';
import isEqual from 'lodash/isEqual';
import { useFormContext } from 'react-hook-form';

export const AutoSubmit: React.FC<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> = ({ children = 'Submit', ...props }) => {
  const { watch } = useFormContext();
  const values = watch();
  const previousValues = React.useRef<typeof values>();
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    if (!buttonRef.current || isEqual(previousValues.current, values)) {
      return;
    }

    if (typeof previousValues.current !== 'undefined') {
      buttonRef.current.click();
    }

    previousValues.current = values;
  }, [values]);

  return (
    <button {...props} type="submit" style={{ display: 'none' }} ref={buttonRef}>
      {children}
    </button>
  );
};
