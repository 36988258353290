import { AuthStatus, useAuth } from 'auth';
import { ExtractRouteParams, RouteProps, Redirect } from 'react-router';
import GuardedRoute from 'my-account/components/GuardedRoute';

export function PrivateRoute<
  Path extends string = string,
  Params extends { [K: string]: string | undefined } = ExtractRouteParams<Path, string>
>(props: RouteProps<Path, Params>) {
  const auth = useAuth();

  return (
    <GuardedRoute
      {...props}
      guard={({ location }, children) => {
        if (auth.status === AuthStatus.LoggedIn || auth.status === AuthStatus.LoggingOut) {
          return children();
        }

        return <Redirect to={{ pathname: '/auth/login', state: { from: location } }} />;
      }}
    />
  );
}

export default PrivateRoute;
