import * as React from 'react';
import ProductSelectContent, { SupplementalProductSelect } from 'configurator/components/product-select-content';
import RelatedProducts from 'configurator/components/related-product';
import ProductConfiguratorStore, { configuratorStoreData } from './stores/shower-configurator-store';
import SpriteIcon from 'search/components/sprite-icon';
import { ProductHit, ProductHitInterface } from 'search/components/product-search/product-hit';
import { Hit } from 'react-instantsearch-core';
import wordpressData from 'data';
import Portal from 'components/Portal';
import FiltersConfigurator from './filters-configurator';
import ShowerImageSrc from 'assets/shower.jpg';
import TubSpoutImageSrc from 'assets/tub-spout.jpg';

const ProductsConfigurator: React.VFC<{
  configurator: ProductConfiguratorStore;
  title?: string;
  introduction?: string;
  modal_title?: string;
  modal_content?: string;
}> = ({ configurator, title, introduction, modal_title, modal_content }) => {
  const [, forceRefresh] = React.useReducer((i) => i + 1, 0);
  const [openModal, setOpenModal] = React.useState(false);

  React.useEffect(() => {
    configurator.refresh = forceRefresh;
    configurator.load_configuration(new URLSearchParams(window.location.search).get('configuration'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sections = [];

  // Show this section if user can edit or is editing configuration
  if (configurator.is_loaded) {
    sections.push(
      <section key="starter-section" className="c-info-strip">
        <div className="o-container">
          <div className="o-row">
            <div className="o-col-12">
              <div className="c-info-strip__inner">
                <span className="c-info-strip__icon">
                  <SpriteIcon name="info" classes={['o-svg-icon']} />
                </span>
                <p className="c-info-strip__note">
                  The list is populated based on answers in the product configurator.{' '}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                  <a
                    onClick={() => {
                      setOpenModal(true);
                    }}
                  >
                    Edit your answers
                  </a>
                  {' or '}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                  <a
                    onClick={(event) => {
                      event.preventDefault();
                      configurator.delete_local_storage();
                      window.location.href = wordpressData.productsUrl;
                    }}
                  >
                    go back to all products
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  if (openModal) {
    sections.push(
      <Portal
        key="filters-section"
        onAppend={() => {
          document.documentElement.style.overflow = 'hidden';
        }}
        onRemove={() => {
          document.documentElement.style.overflow = 'visible';
        }}
      >
        <div className="mfp-bg mfp-ready"></div>
        <div className="mfp-wrap mfp-auto-cursor mfp-ready" tabIndex={-1} style={{ overflow: 'hidden auto' }}>
          <div className="mfp-container mfp-s-ready mfp-inline-holder">
            <div className="mfp-content">
              <div className="c-modal__wrapper c-modal__wrapper--lg">
                <div className="c-modal c-modal--has-sidebar">
                  <button onClick={() => setOpenModal(false)} className="c-modal-close" />
                  <div className="c-modal__main">
                    <div className="c-block c-block--spacing-t-small c-block--spacing-b-small u-bg-neutral-800">
                      <div className="o-container-fluid">
                        <div className="o-row">
                          <div className="o-col-12">
                            <div className="u-flex u-items-center u-mb-spacer-base-small">
                              <SpriteIcon
                                name="plans"
                                classes={['o-svg-icon', 'u-mr-spacer-base-small', 'u-h2', 'u-text-secondary']}
                              />
                              <h6 className="c-headline u-mb-0 u-text-secondary">Product configurator</h6>
                            </div>
                            <h2 className="u-text-white">{modal_title}</h2>
                            <p className="u-text-white">{modal_content}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="c-block c-block--spacing-t-small c-block--spacing-b-small">
                      <div className="o-container-fluid">
                        <div className="o-row">
                          <div className="o-col-12">
                            <FiltersConfigurator
                              onSubmit={(specifications) => {
                                if (configurator.can_edit) {
                                  configurator.specifications = specifications;
                                } else {
                                  // Create new configuration if user can't edit current configuration
                                  const url = new URL(window.location.href);
                                  if (url.searchParams.has('configuration')) {
                                    url.searchParams.delete('configuration');
                                    url.search = url.searchParams.toString();
                                    window.history.replaceState({}, 'Product configuration', url.toString());
                                    delete configurator.configuration_key;
                                    delete configurator.authorization_key;
                                    configurator.main_selection = true;
                                    delete configurator.error;
                                    configurator.is_loaded = true;
                                    configurator.data = {
                                      type: 'shower',
                                      meta: {},
                                      products: {},
                                    };
                                    configurator.specifications = specifications;
                                  }
                                }
                                setOpenModal(false);
                              }}
                              configurator={configurator}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mfp-bg mfp-ready"></div>
      </Portal>
    );
  }

  if (!configurator.is_loaded || configurator.error) {
    sections.push(
      <section
        key="info-section"
        className="c-block c-block--spacing-t-extra-small c-block--spacing-t-small@md c-block--spacing-b-extra-small c-block--spacing-b-small@md c-block--height-semifull@xs"
      >
        <div className="o-container-fluid">
          <div className="o-row u-flex-grow u-items-center">
            <div className="o-col-12">
              <div className="c-listing__none">
                <div className="c-listing__none-figure">
                  <SpriteIcon name={configurator.error ? 'search' : 'clock'} classes={['o-svg-icon']} />
                </div>
                <p className="c-listing__none-title">
                  {configurator.error ? configurator.error.title : 'Loading configuration.'}
                </p>
                <p>{configurator.error ? configurator.error.message : 'Loading configuration data with products...'}</p>
              </div>
            </div>
          </div>
          <div className="c-block__pattern c-block__pattern--light3 c-block__pattern--top3 c-block__overlay--opacity-20">
            <SpriteIcon name="pattern" classes={['o-svg-icon']} />
          </div>
        </div>
      </section>
    );
  } else if (
    configurator.can_edit &&
    !configurator.validateSpecifications() &&
    !configurator.getConfigurationProductSku()
  ) {
    // Force open modal configuration is empty and user landed directly on configurator page
    sections.push(
      <section
        key="cta-section"
        className="c-block c-block--spacing-t-extra-small c-block--spacing-t-small@md c-block--spacing-b-extra-small c-block--spacing-b-small@md c-block--height-semifull@xs"
      >
        <div className="o-container-fluid">
          <div className="o-row u-flex-grow u-items-center">
            <div className="o-col-12">
              <div className="c-listing__none">
                <div className="c-listing__none-figure">
                  <SpriteIcon name="plans" classes={['o-svg-icon']} />
                </div>
                <p className="c-listing__none-title">Answer few questions</p>
                <p>To create product configuration you need to answer few questions.</p>
                <button
                  className="c-button"
                  onClick={() => {
                    setOpenModal(true);
                  }}
                >
                  Edit your answers
                </button>
              </div>
            </div>
          </div>
          <div className="c-block__pattern c-block__pattern--light3 c-block__pattern--top3 c-block__overlay--opacity-20">
            <SpriteIcon name="pattern" classes={['o-svg-icon']} />
          </div>
        </div>
      </section>
    );
  } else if (configurator.main_selection) {
    // Main product selection screen
    sections.push(
      <section
        key="main-select-section"
        className="c-block c-block--bg-light2 c-block--spacing-t-small c-block--spacing-t@md c-block--spacing-b-small c-block--spacing-b@md"
      >
        <div className="o-container-fluid">
          <div className="o-row">
            <div className="o-col-12">
              <h3 className="u-mb-spacer-base-small">{title}</h3>
              {introduction && (
                <div className="u-mb-spacer-base-large" dangerouslySetInnerHTML={{ __html: introduction }}></div>
              )}
            </div>
          </div>

          <ProductSelectContent
            onSelect={(sku, hit) => {
              configurator.setConfigurationProduct(configurator.type, sku, hit);
              configurator.main_selection = false;
            }}
            showViewOptions={true}
            variantFilters={configurator.variantAffectingFilters}
            searchParameters={configurator.getSearchParameters()}
          />
        </div>
      </section>
    );
  } else {
    // Supplemental product selection screen
    const SelectSupplementalProducts = [];
    const SelectRelatedProducts = [];
    // Define type specific stuff
    if (configurator.type === 'shower') {
      SelectSupplementalProducts.push(
        <SupplementalProductSelect
          key="select-shower-head"
          image={ShowerImageSrc}
          productTypeLabel="shower head"
          showViewOptions={true}
          onSelect={
            configurator.can_edit
              ? (sku, hit) => {
                  configurator.setConfigurationProduct('shower_head', sku, hit);
                }
              : undefined
          }
          variantFilters={configurator.variantAffectingFilters}
          selectedProduct={configurator.getConfigurationProductHit('shower_head')}
          searchParameters={configurator.getSearchParameters('shower_head')}
        />
      );
      SelectSupplementalProducts.push(
        <SupplementalProductSelect
          key="select-tub-spout"
          image={TubSpoutImageSrc}
          productTypeLabel="tub spout"
          showViewOptions={true}
          onSelect={
            configurator.can_edit
              ? (sku, hit) => {
                  configurator.setConfigurationProduct('tub_spout', sku, hit);
                }
              : undefined
          }
          variantFilters={configurator.variantAffectingFilters}
          selectedProduct={configurator.getConfigurationProductHit('tub_spout')}
          searchParameters={configurator.getSearchParameters('tub_spout')}
        />
      );
      SelectRelatedProducts.push(
        <RelatedProducts
          key="recommended-valves"
          relation="recommended_valves"
          title="Recommended valve"
          sku={configurator.getConfigurationProductSku()}
          onHitLoad={configurator.cache_product_hit}
        />
      );
    }
    sections.push(
      <>
        <section
          key="final-section"
          className="c-block c-block--bg-light2 c-block--spacing-t-small c-block--spacing-t@md c-block--spacing-b-small c-block--spacing-b@md"
        >
          <div className="o-container-fluid">
            <div className="o-row">
              <div className="o-col-12 u-mb-spacer-base">
                {configurator.can_edit && (
                  <button
                    onClick={() => {
                      configurator.main_selection = true;
                      configurator.refresh();
                    }}
                    className="c-link-cta-basic c-link-cta--small c-block__get-back u-px-0 u-py-0"
                  >
                    <SpriteIcon name="arrow" classes={['o-svg-icon', 'o-svg-left']} />
                    <span>Back To All Results</span>
                  </button>
                )}
              </div>
            </div>
            <div className="o-row u-mb-spacer-base-large@md">
              <div className="o-col-12 u-mb-spacer-base-large">
                <article className="c-card c-card--product c-card--product-featured">
                  <ProductHit
                    hit={configurator.getConfigurationProductHit() as Hit<ProductHitInterface>}
                    variantAffectingFilters={configurator.variantAffectingFilters}
                    description={configurator.getProductDescriptionByHit(
                      configurator.getConfigurationProductHit() as Hit<ProductHitInterface>
                    )}
                  />
                </article>
              </div>
            </div>

            <div className="o-row u-mb-spacer-base-large@md">{SelectSupplementalProducts}</div>

            <div className="o-row">
              <div className="o-col-12">
                <div className="c-order-summary">
                  <header className="c-order-summary__header">
                    <h3 className="u-mb-0">Your selection</h3>
                  </header>
                  <div className="c-table-wrapper">
                    <table className="c-order-summary-table">
                      <thead>
                        <tr>
                          <th className="c-order-summary-table__column c-order-summary-table__column--left">#</th>
                          <th className="c-order-summary-table__column c-order-summary-table__column--left">
                            Model Number
                          </th>
                          <th className="c-order-summary-table__column c-order-summary-table__column--left">
                            Category
                          </th>
                          <th className="c-order-summary-table__column c-order-summary-table__column--left">Name</th>
                          <th className="c-order-summary-table__column">Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(
                          Object.keys(configurator.data.products) as Array<keyof configuratorStoreData['products']>
                        ).map((type, index) => {
                          const sku = configurator.getConfigurationProductSku(type);
                          const hit = configurator.getConfigurationProductHit(type);
                          if (typeof hit === 'undefined') {
                            // Hit data is not loaded so skip it for now
                            return null;
                          }
                          const price = configurator.getConfigurationProductPrice(type);

                          return (
                            <tr key={String(index + 1) + '-product-row'} className="c-order-summary-table__row">
                              <td className="c-order-summary-table__column c-order-summary-table__column--left">
                                <span className="c-order-summary-table__label">#</span> {index + 1}
                              </td>
                              <td className="c-order-summary-table__column c-order-summary-table__column--left">
                                <span className="c-order-summary-table__label">Model Number</span> {sku}
                              </td>
                              <td className="c-order-summary-table__column c-order-summary-table__column--left">
                                <span className="c-order-summary-table__label">Category</span>{' '}
                                {configurator.type_label(type, true)}
                              </td>
                              <td className="c-order-summary-table__column c-order-summary-table__column--left">
                                <span className="c-order-summary-table__label">Name</span> {hit.name}
                              </td>
                              <td className="c-order-summary-table__column">
                                <span className="c-order-summary-table__label">Price</span> {price?.sign}
                                {price?.formattedAmmount}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {SelectRelatedProducts}
      </>
    );
  }

  return <>{sections}</>;
};
export default ProductsConfigurator;
