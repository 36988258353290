import * as React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { MutationStatus } from 'react-query';

import { User, FileModel, FileType } from '__generated-api__';
import api from 'api';
import Icon from 'components/icon';
import { useMutation } from 'hooks/query';
import { isAPIError, isAPIErrorMessage, isAPIErrorsObject } from 'utils/errors';
import { getUserName } from 'my-account/utils/user';
import classNames from 'classnames';

const PreviewImageFile: React.VFC<{ file: File; type: FileType }> = ({ file, type }) => {
  const [fileImageDataUrl, setFileImageDataUrl] = React.useState<string | null>(null);

  React.useEffect(() => {
    const reader = new FileReader();

    reader.addEventListener('loadend', () => {
      setFileImageDataUrl(typeof reader.result === 'string' ? reader.result : null);
    });

    reader.readAsDataURL(file);

    return () => {
      reader.abort();
    };
  }, [file]);

  if (fileImageDataUrl) {
    return (
      <div className="c-profile-preview__main">
        <div className={classNames('u-mb-0', `c-profile-preview__${type}`)}>
          <img src={fileImageDataUrl} alt={file.name} />
        </div>
      </div>
    );
  }

  return (
    <div className="c-profile-preview__add">
      <div>
        <Icon name="camera" />
      </div>
    </div>
  );
};

type ProfileImageFieldFormValues = {
  __name__: Omit<FileModel, 'user'> | null;
};

export const ProfileImageField: React.VFC<{
  name: string;
  type: FileType;
  onUploadStatusChange?: (status: MutationStatus) => void;
  onFileUpload?: (File: FileModel) => void;
}> = ({ name, type = FileType.Avatar, onUploadStatusChange, onFileUpload }) => {
  const { setError, clearErrors } = useFormContext();
  const { field, fieldState } = useController<ProfileImageFieldFormValues, '__name__'>({ name: name as '__name__' });
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [uploadImage, uploadImageMutation] = useMutation(api.file.upload);

  React.useEffect(() => {
    if (!onUploadStatusChange) {
      return;
    }

    onUploadStatusChange(uploadImageMutation.status);
  }, [uploadImageMutation.status, onUploadStatusChange]);

  return (
    <div className="c-profile-preview c-profile-preview--out u-mb-spacer-base">
      <input
        type="file"
        style={{ position: 'fixed', top: '-100em' }}
        ref={fileInputRef}
        multiple={false}
        accept=".jpeg,.png,.gif,.jpg"
        onChange={async (event) => {
          if (!event.currentTarget.files || !event.currentTarget.files.length) {
            field.onChange(null);
            return;
          }

          clearErrors(name);

          return uploadImage([{ image: event.currentTarget.files[0], type }])
            .then((uploadRes) => {
              field.onChange(uploadRes.data);
              if (onFileUpload) onFileUpload(uploadRes.data);
            })
            .catch((e) => {
              const setFieldError = (errors: string[]) => {
                setError(name, {
                  types: Object.fromEntries(errors.map((msg, index) => [`api-error-${index}`, msg])),
                });
              };

              if (isAPIErrorsObject(e)) {
                const errorsKeys = Object.keys(e.response.data.errors);
                const apiErrors: string[] = [];

                if (e.response.data.message.trim()) {
                  apiErrors.push(e.response.data.message);
                }

                if (errorsKeys.includes(name)) {
                  const errorMsg = e.response.data.errors[name];

                  apiErrors.push(...errorMsg);
                }

                setFieldError(apiErrors);

                return;
              }

              if (isAPIErrorMessage(e)) {
                setFieldError([e.response.data.message]);

                return;
              }

              if (isAPIError(e)) {
                if (e.response) {
                  if (e.response.status === 403) {
                    setFieldError(['You do not have sufficient permissions to access this data.', e.message]);
                    return;
                  }
                  if (e.response.status === 404) {
                    setFieldError(['Requested data does not exist.', e.message]);

                    return;
                  }
                  if (e.response.status >= 400 && e.response.status <= 499) {
                    setFieldError([e.message]);
                    return;
                  }
                  if (e.response.status >= 500 && e.response.status <= 599) {
                    setFieldError([
                      'The server currently is not able to process your request. If this persists, please contact support.',
                      e.message,
                    ]);
                    return;
                  }
                }

                setFieldError([
                  'Establishing server connection failed. Please try refreshing the page or check again later.',
                  e.message,
                ]);

                return;
              }

              setFieldError(['Unexpected error has occurred. Please try refreshing the page or check again later.']);
            });
        }}
      />

      {uploadImageMutation.isLoading ? (
        <>
          {uploadImageMutation.variables && (
            <PreviewImageFile file={uploadImageMutation.variables[0].image} type={type} />
          )}

          <div className="c-profile-preview__footer">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href="#"
              className="c-button c-button--light-grey c-button--option is-disabled"
              onClick={(event) => {
                event.preventDefault();
              }}
            >
              <Icon name="avatar" className="o-svg-icon" />
              {type === FileType.Image ? <span>Change Image</span> : <span>Change profile picture</span>}
            </a>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href="#"
              className="c-button c-button--light-grey c-button--option is-disabled"
              onClick={(event) => {
                event.preventDefault();
              }}
            >
              <Icon name="close-thin" className="o-svg-icon" />
              {type === FileType.Image ? <span>Remove Image</span> : <span>Remove profile picture</span>}
            </a>
          </div>

          {typeof fieldState.error !== 'undefined' && (
            <ul className="c-form-element--error__list" style={{ display: 'block' }}>
              {typeof fieldState.error.message !== 'undefined' && <li key="message">{fieldState.error.message}</li>}
              {typeof fieldState.error.types !== 'undefined' &&
                Object.entries(fieldState.error.types).map(([key, msg]) => <li key={key}>{msg}</li>)}
            </ul>
          )}
        </>
      ) : typeof field.value === 'object' && field.value !== null && field.value.id ? (
        <>
          <div className="c-profile-preview__main">
            <div className={classNames('u-mb-0', `c-profile-preview__${type}`)}>
              <img src={field.value.thumbnail} alt={field.value.title} />
            </div>
          </div>

          <div className="c-profile-preview__footer">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href="#"
              className="c-button c-button--light-grey c-button--option"
              onClick={(event) => {
                event.preventDefault();

                fileInputRef.current?.click();
              }}
            >
              <Icon name="avatar" className="o-svg-icon" />
              {type === FileType.Image ? <span>Change Image</span> : <span>Change profile picture</span>}
            </a>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href="#"
              className="c-button c-button--light-grey c-button--option"
              onClick={(event) => {
                event.preventDefault();
                field.onChange(null);
              }}
            >
              <Icon name="close-thin" className="o-svg-icon" />
              {type === FileType.Image ? <span>Remove Image</span> : <span>Remove profile picture</span>}
            </a>
          </div>

          {fieldState.error && (
            <ul className="c-form-element--error__list" style={{ display: 'block' }}>
              {typeof fieldState.error.message !== 'undefined' && <li key="message">{fieldState.error.message}</li>}
              {typeof fieldState.error.types !== 'undefined' &&
                Object.entries(fieldState.error.types).map(([key, msg]) => <li key={key}>{msg}</li>)}
            </ul>
          )}
        </>
      ) : (
        <>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            href="#"
            className="c-profile-preview__add"
            onClick={(event) => {
              event.preventDefault();

              fileInputRef.current?.click();
            }}
          >
            <div>
              <Icon name="camera" />
            </div>

            <div>
              <button className="c-link-cta-basic c-link-cta--small" type="button">
                {type === FileType.Image ? <span>Add Image</span> : <span>Add profile picture</span>}
              </button>
            </div>
          </a>

          {fieldState.error && (
            <ul className="c-form-element--error__list" style={{ display: 'block' }}>
              {typeof fieldState.error.message !== 'undefined' && <li key="message">{fieldState.error.message}</li>}
              {typeof fieldState.error.types !== 'undefined' &&
                Object.entries(fieldState.error.types).map(([key, msg]) => <li key={key}>{msg}</li>)}
            </ul>
          )}

          <p className="c-note">JPG, JPEG, PNG, or GIF formats are accepted. 280x280 px is the minimum.</p>
        </>
      )}
    </div>
  );
};

const ProfileImageVariants = {
  'profile-preview': 'c-profile-preview',
  info: 'c-info',
  chat: 'c-chat',
} as const;

export const ProfileImage = <
  T extends { first_name: User['first_name']; last_name: User['last_name']; email: User['email']; image: User['image'] }
>({
  user,
  variant = 'profile-preview',
}: {
  user: T;
  variant?: keyof typeof ProfileImageVariants;
}) => {
  const { name, initials } = getUserName(user);
  const classNameBase = ProfileImageVariants[variant];

  if (user.image) {
    return (
      <div className={`${classNameBase}__avatar`}>
        <img src={user.image.thumbnail} alt={name} />
      </div>
    );
  }

  return (
    <div className={`${classNameBase}__avatar ${classNameBase}__avatar--initials`}>
      <span>{initials.toUpperCase()}</span>
    </div>
  );
};
