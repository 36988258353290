import * as React from 'react';
import omit from 'lodash/omit';
import { useFormContext } from 'react-hook-form';

import { IProjectOrderRequestSchema } from 'my-account/components/PendingQuote/schema';
import { IProjectQuoteRequestSchema } from 'my-account/components/PendingQuote/schema';

export const getPendingQuotePersistedValues = () => {
  const storageValue = window.localStorage.getItem('symmons-request-quote-data');
  if (storageValue) {
    try {
      const storedValues: Omit<IProjectQuoteRequestSchema, 'files'> = JSON.parse(storageValue);
      return storedValues;
    } catch (e) {}
  }

  return undefined;
};

export const cleanPendingQuotePersistedValues = () => {
  window.localStorage.removeItem('symmons-request-quote-data');
};

export const PersistQuotePersistValues = () => {
  const { watch, setValue, getValues } = useFormContext<IProjectQuoteRequestSchema>();
  watch();

  React.useEffect(() => {
    const storedValues = getPendingQuotePersistedValues();
    if (storedValues) {
      const storedValuesKeys = Object.keys(storedValues);

      for (let i = 0; i < storedValuesKeys.length; i += 1) {
        const key = storedValuesKeys[i] as keyof Omit<IProjectQuoteRequestSchema, 'files'>;

        setValue(key, storedValues[key], { shouldTouch: true, shouldDirty: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    window.localStorage.setItem('symmons-request-quote-data', JSON.stringify(omit(getValues(), 'files')));
  });

  return null;
};

export const getPendingOrderPersistedValues = () => {
  const storageValue = window.localStorage.getItem('symmons-request-order-data');
  if (storageValue) {
    try {
      const storedValues: Omit<IProjectOrderRequestSchema, 'files'> = JSON.parse(storageValue);
      return storedValues;
    } catch (e) {}
  }

  return undefined;
};

export const cleanPendingOrderPersistedValues = () => {
  window.localStorage.removeItem('symmons-request-order-data');
};

export const PersistOrderPersistValues = () => {
  const { watch, setValue, getValues } = useFormContext<IProjectOrderRequestSchema>();
  watch();

  React.useEffect(() => {
    const storedValues = getPendingOrderPersistedValues();
    if (storedValues) {
      const storedValuesKeys = Object.keys(storedValues);

      for (let i = 0; i < storedValuesKeys.length; i += 1) {
        const key = storedValuesKeys[i] as keyof Omit<IProjectOrderRequestSchema, 'files'>;

        setValue(key, storedValues[key], { shouldTouch: true, shouldDirty: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    window.localStorage.setItem('symmons-request-order-data', JSON.stringify(omit(getValues(), 'files')));
  });

  return null;
};
