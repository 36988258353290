import * as React from 'react';
import { observer } from 'mobx-react';
import { HitsProvided } from 'react-instantsearch-core';
import { connectHits } from 'react-instantsearch-dom';
import ProductSearchStore, { ViewMode } from 'search/stores/product-search-store';
import ProductHitWithInsights, { ProductHitInterface } from 'search/components/product-search/product-hit';

interface CustomProductHitsProps extends HitsProvided<ProductHitInterface> {
  store: ProductSearchStore;
}

const CustomProductHits = observer(
  class CustomProductHits extends React.Component<CustomProductHitsProps> {
    render() {
      const { store, hits } = this.props;
      return (
        <>
          <ul
            className={[
              'o-stack-2@sm',
              store.viewMode === ViewMode.Grid ? 'o-stack-3@md' : 'o-stack-1@md',
              'c-card__row--products',
            ].join(' ')}
          >
            {hits.map((hit) => (
              <li key={hit.objectID}>
                <article
                  className={[
                    'c-card',
                    'c-card--product',
                    'c-card--link',
                    store.viewMode === ViewMode.Grid ? '' : 'c-card--product-hor@md',
                  ].join(' ')}
                >
                  <ProductHitWithInsights hit={hit} variantAffectingFilters={store.variantAffectingFilters} />
                </article>
              </li>
            ))}
          </ul>
        </>
      );
    }
  }
);

const ProductHits = connectHits<CustomProductHitsProps, ProductHitInterface>(CustomProductHits);

export default ProductHits;
