import React from 'react';
import ReactDOM from 'react-dom';

const Portal: React.FC<{ className?: string; wrapper?: string; onAppend?: Function; onRemove?: Function }> = ({
  children,
  className = 'root-portal',
  wrapper = 'section',
  onAppend,
  onRemove,
}) => {
  const [container] = React.useState(() => {
    return document.createElement(wrapper);
  });

  React.useEffect(() => {
    container.classList.add(className);
    document.body.appendChild(container);
    onAppend && onAppend();
    return () => {
      document.body.removeChild(container);
      onRemove && onRemove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ReactDOM.createPortal(children, container);
};

export default Portal;
