import * as React from 'react';
import { Hit, WrappedInsightsClient } from 'react-instantsearch-core';
import _isEqual from 'lodash/isEqual';
import wordpressData from 'data';
import SpriteIcon from 'search/components/sprite-icon';
import { VariantAffectingFilters } from 'search/stores/product-search-store';
import { connectHitInsights } from 'react-instantsearch-dom';
import aa from 'search-insights';

export class Price {
  readonly sign = '$';
  readonly ammount;
  readonly currency;
  readonly formattedAmmount;

  constructor(ammount: number, currency: 'USD' | 'CAD') {
    this.ammount = ammount;
    this.currency = currency;

    const currencyFormatter = new Intl.NumberFormat(currency === 'CAD' ? 'en-CA' : 'en-US', {
      style: 'currency',
      currency: currency,
    });

    this.formattedAmmount = currencyFormatter.format(ammount).replace('$', '');
  }
}

interface Variant {
  sku: string;
  slug: string;
  finish: string;
  featured_image: string;
  special_attributes: string[];
  us_price?: number;
  ca_price?: number;
  is_obsolete: boolean;
  business_segments: string[];
  tier: 'RDS' | 'LTS' | 'SPO' | 'CMS' | '';
  availability: string;
}

export interface ProductHitInterface {
  name: string;
  sku: string;
  slug: string;
  categories: string[];
  collection: string[];
  featured_image: string;
  us_price?: number;
  ca_price?: number;
  is_obsolete: boolean;
  business_segments: string[];
  is_modified: boolean;
  tier: 'RDS' | 'LTS' | 'SPO' | 'CMS' | '';
  variants: Variant[];
}

export interface ProductHitProps {
  hit: Hit<ProductHitInterface>;
  insights?: WrappedInsightsClient;
  variantAffectingFilters: VariantAffectingFilters;
  selectProduct?: (sku: string, hit: Hit<ProductHitInterface>) => any;
  description?: string;
}

interface ProductHitState {
  selectedVariantIndex: number;
  variantAffectingFilters: VariantAffectingFilters;
}

function isVariantDisabled(variant: Variant, variantAffectingFilters: VariantAffectingFilters) {
  if (
    variantAffectingFilters.availabilities.length &&
    !variantAffectingFilters.availabilities.includes(variant.availability)
  ) {
    return true;
  }

  if (variantAffectingFilters.finishes.length && !variantAffectingFilters.finishes.includes(variant.finish)) {
    return true;
  }

  if (variantAffectingFilters.special_attributes.length) {
    const some = variant.special_attributes.some((specialAttribute) =>
      variantAffectingFilters.special_attributes.includes(specialAttribute)
    );
    if (!some) {
      return true;
    }
  }

  if (
    variantAffectingFilters.is_obsolete.length &&
    !variantAffectingFilters.is_obsolete.includes(variant.is_obsolete ? 'true' : 'false')
  ) {
    return true;
  }

  if (variantAffectingFilters.business_segments.length) {
    const some = variant.business_segments.some((business_segment) =>
      variantAffectingFilters.business_segments.includes(business_segment)
    );
    if (!some) {
      return true;
    }
  }

  return false;
}

function getDefaultVariantIndex(product: Hit<ProductHitInterface>, variantAffectingFilters: VariantAffectingFilters) {
  // console.log('getDefaultVariantIndex::product(index)', product);

  if (!product.variants.length) {
    // no variants, this is a solo product
    return -1;
  }

  let defaultVariantIndex;

  if (variantAffectingFilters.query) {
    // query string is present, start with the most relevant variant according to algolia
    defaultVariantIndex = product.variants.findIndex((variant) => variant.sku === product.sku);
  } else {
    // query string is not present, start with the first enabled variant
    defaultVariantIndex = product.variants.findIndex((variant) => !isVariantDisabled(variant, variantAffectingFilters));

    if (defaultVariantIndex === -1 || product.variants[defaultVariantIndex].tier !== 'RDS') {
      // variant not found (this should never happen) or variant is not RDS (this can happen)
      // we want to always display an enabled RDS variant if possible, try to find an enabled variant that is also RDS
      const rdsVariantIndex = product.variants.findIndex(
        (variant) => !isVariantDisabled(variant, variantAffectingFilters) && variant.tier === 'RDS'
      );
      defaultVariantIndex = rdsVariantIndex !== -1 ? rdsVariantIndex : defaultVariantIndex;
    }
  }

  // console.log('getDefaultVariantIndex::defaultVariantIndex', defaultVariantIndex);

  // fail safe, if we haven't managed to find a good default variant, return first variant
  defaultVariantIndex = defaultVariantIndex === -1 ? 0 : defaultVariantIndex;

  return defaultVariantIndex;
}

export class ProductHit extends React.Component<ProductHitProps, ProductHitState> {
  // state: ProductHitState = {
  //   selectedVariantIndex: -1,
  //   variantAffectingFilters: {
  //     availabilities: [],
  //     finishes: [],
  //     special_attributes: [],
  //     is_obsolete: [],
  //   },
  // };

  constructor(props: ProductHitProps) {
    super(props);

    const index = getDefaultVariantIndex(props.hit, props.variantAffectingFilters);

    // console.log('ProductHit::constructor::index', index);

    this.state = {
      selectedVariantIndex: index,
      variantAffectingFilters: {
        query: undefined,
        availabilities: [],
        finishes: [],
        special_attributes: [],
        is_obsolete: [],
        business_segments: [],
      },
    };

    this.sendAlgoliaClickEvent = this.sendAlgoliaClickEvent.bind(this);
  }

  static getDerivedStateFromProps(nextProps: ProductHitProps, prevState: ProductHitState) {
    // console.log('ProductHit::getDerivedStateFromProps::mark1');

    if (!_isEqual(nextProps.variantAffectingFilters, prevState.variantAffectingFilters)) {
      return {
        selectedVariantIndex: getDefaultVariantIndex(nextProps.hit, nextProps.variantAffectingFilters),
        variantAffectingFilters: nextProps.variantAffectingFilters,
      };
    }

    // console.log('ProductHit::getDerivedStateFromProps::mark2');

    return null;
  }

  componentDidMount() {
    // TODO (@ivo): Verify thumbnail preloadinng actually works
    // preload variation thumbnails
    this.props.hit.variants.forEach((variation) => {
      if (variation.featured_image) {
        new Image().src = variation.featured_image;
      }
    });
  }

  getPrice() {
    const product = this.props.hit;
    const selectedVariant = product.variants.length ? product.variants[this.state.selectedVariantIndex] : null;

    let price = null;

    if (wordpressData.visitorCountryCode === 'CA') {
      const priceAmmount = selectedVariant ? selectedVariant.ca_price : product.ca_price;
      if (priceAmmount) {
        price = new Price(priceAmmount, 'CAD');
      }
    } else {
      const priceAmmount = selectedVariant ? selectedVariant.us_price : product.us_price;
      if (priceAmmount) {
        price = new Price(priceAmmount, 'USD');
      }
    }

    return price;
  }

  sendAlgoliaClickEvent() {
    if (typeof this.props.insights !== 'undefined') {
      //console.log('Send "Product Search Result Click" Algolia event for ' + this.props.hit.name);
      return this.props.insights('clickedObjectIDsAfterSearch', { eventName: 'Product Search Result Click' });
    }
  }

  render() {
    const product = this.props.hit;

    const selectedVariantIndex = this.state.selectedVariantIndex;
    const selectedVariant = product.variants.length ? product.variants[selectedVariantIndex] : null;

    const sku = selectedVariant ? selectedVariant.sku : product.sku;
    const url = wordpressData.productsUrl + (selectedVariant ? selectedVariant.slug : product.slug);
    const price = this.getPrice();
    const isObsolete = selectedVariant ? selectedVariant.is_obsolete : product.is_obsolete;
    const isTopProduct = !!(selectedVariant
      ? selectedVariant.business_segments?.length
      : product?.business_segments?.length);
    const isModified = product.is_modified;
    const image = selectedVariant ? selectedVariant.featured_image : product.featured_image;
    const tier = selectedVariant ? selectedVariant.tier : product.tier;

    const selectProduct = this.props.selectProduct;
    const description = this.props.description;
    const hasDescription = Boolean(typeof this.props.description !== 'undefined');

    // console.log('ProductHit::render::variantAffectingFilters', this.props.variantAffectingFilters);
    // console.log('ProductHit::render::product', product);

    let card_content = [];

    card_content.push(
      <header key="card-head" className="c-card__header">
        <p className="c-headline">{product.categories.join(', ')}</p>
        <h4 className="c-card__title">
          <a href={url} className="c-card__link">
            {product.name}
          </a>
        </h4>
        <p className="c-card__subtitle">Model {sku}</p>
        {hasDescription && (
          <>
            <p className="c-card__desc u-my-spacer-base u-text-md">{description}</p>
            <a href={url} className="c-link-cta">
              <span>Learn more</span>
              <SpriteIcon name="arrow" classes={['o-svg-icon', 'o-svg-right']} />
            </a>
          </>
        )}
      </header>
    );

    card_content.push(
      <footer key="card-foot" className="c-card__footer c-card__footer-row">
        <div className="o-col">
          {!!product.variants.length && (
            <ul className="c-finishes c-finishes--small c-finishes--hor">
              {product.variants.map((variant, index) => {
                const finishIconUrl = wordpressData.finishIcons[variant.finish];
                return (
                  <li
                    key={index}
                    className={selectedVariantIndex === index ? 'is-selected' : ''}
                    style={{
                      opacity: isVariantDisabled(variant, this.props.variantAffectingFilters) ? 0.1 : undefined,
                    }}
                  >
                    <a
                      href={wordpressData.productsUrl + variant.slug}
                      title={variant.finish}
                      onClick={(event) => {
                        event.preventDefault();
                        this.setState({ selectedVariantIndex: index });
                      }}
                    >
                      <span
                        className="c-finishes__preview"
                        style={{
                          backgroundImage: finishIconUrl ? `url(${finishIconUrl})` : undefined,
                        }}
                      ></span>
                    </a>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        <div className="o-col c-card__col--end">
          <div className="c-price">
            {price && !isObsolete && (
              <p className="c-price__amount">
                <span className="c-price__small c-price__sup">{price.sign}</span>
                <span className="c-price__number">{price.formattedAmmount}</span>
                <span className="c-price__small">{price.currency}</span>
              </p>
            )}
            {tier === 'RDS' && (
              <div className="c-price__note">
                <p>
                  <SpriteIcon name="tier-1--multi" classes={['o-svg-icon', 'o-svg-larger']} />
                  <span>ReadyStock™</span>
                </p>
              </div>
            )}
            {tier === 'CMS' && (
              <div className="c-price__note">
                <p>
                  <SpriteIcon name="tier-4--multi" classes={['o-svg-icon', 'o-svg-larger']} />
                  <span>Coming Soon</span>
                </p>
              </div>
            )}
          </div>
        </div>
      </footer>
    );

    return (
      <>
        <div className="c-card__body" onClick={this.sendAlgoliaClickEvent}>
          {typeof selectProduct !== 'undefined' && (
            <div
              className="c-card__select-btn"
              onClick={() => {
                if (selectedVariant) {
                  selectProduct(selectedVariant.sku, product);
                } else {
                  selectProduct(product.sku, product);
                }
              }}
            >
              <span className="c-button c-button--full c-button--square c-button--primary">
                <SpriteIcon name="add" classes={['o-svg-icon']} />
                Select
              </span>
            </div>
          )}
          <figure className="c-card__figure">
            <a href={url}>
              <img src={image} alt={product.name} />
            </a>
            <div className="c-card__tags">
              {isObsolete && <span className="c-tag c-tag--small">RETIRED</span>}
              {isModified && (
                <a href="https://symmons.com?p=32563" className="c-tag c-tag--small c-tag--secondary">
                  Modified Shower Trim <SpriteIcon name="info" classes={['o-svg-icon']} />
                </a>
              )}
              {isTopProduct && <span className="c-tag c-tag--small c-tag--primary">Best Seller</span>}
            </div>
          </figure>

          {hasDescription ? <div className="c-card__content">{card_content}</div> : card_content}
        </div>
      </>
    );
  }
}

const ProductHitWithInsights = connectHitInsights(aa)(
  // @ts-ignore
  ProductHit
) as unknown as React.ComponentType<Omit<ProductHitProps, 'insights'>>;
export default ProductHitWithInsights;
