import * as React from 'react';
import { useQueryClient } from 'react-query';

import { RegisteredUser, SentQuoteStatusEnum } from '__generated-api__';
import api from 'api';
import { AuthStatus, useAuth } from 'auth';
import { Button } from 'components/Button';
import Form from 'components/Form/Form';
import InputField from 'components/Form/InputField';
import SelectField from 'components/Form/SelectField';
import SubmitButton from 'components/Form/SubmitButton';
import Icon from 'components/icon';
import { PendingQuoteModalStep } from 'my-account/components/PendingQuote/Modal';
import {
  PersistOrderPersistValues,
  cleanPendingOrderPersistedValues,
  getPendingOrderPersistedValues,
} from 'my-account/components/PendingQuote/PersistValues';
import { usePendingQuote } from 'my-account/components/PendingQuote/context';
import { IProjectOrderRequestSchema, ProjectOrderRequestSchema } from 'my-account/components/PendingQuote/schema';
import { useToast } from 'my-account/toast';
import { getObjectKeys } from 'my-account/utils/object';
import { Countries, caProvinces, usStates } from 'my-account/utils/states';
import { useFormContext } from 'react-hook-form';

export const RequestProjectOrderReviewProducts: React.VFC = () => {
  const { quote, openModal, canAddToQuote } = usePendingQuote();

  if (!canAddToQuote) {
    return null;
  }

  const productsCount =
    typeof quote !== 'undefined' && typeof quote.products !== 'undefined' && quote.products.length > 0
      ? quote.products.reduce((total, product) => (total += product.pivot.quantity), 0)
      : 0;

  if (
    typeof quote === 'undefined' ||
    quote.status === SentQuoteStatusEnum.Sent ||
    typeof quote.products === 'undefined' ||
    !productsCount
  ) {
    return null;
  }

  return (
    <p>
      You have {productsCount} product{productsCount !== 1 && 's'} pending in your order.
      <br />
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        href="#"
        onClick={(event) => {
          event.preventDefault();
          openModal({ hasConfirmation: false });
        }}
      >
        Review them
      </a>{' '}
      or continue by filling this form before sending.
    </p>
  );
};

export interface RequestProjectOrderFormProps {
  className?: string;
  onSubmit?: () => void;
  renderFooter: (submitButton: React.ReactNode) => React.ReactNode;
  products?: IProjectOrderRequestSchema['products'];
  step: PendingQuoteModalStep;
  setStep: (status: PendingQuoteModalStep) => void;
}

export const RequestProjectOrderForm: React.VFC<RequestProjectOrderFormProps> = ({
  className,
  onSubmit,
  renderFooter,
  products,
  step,
  setStep,
}) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const auth = useAuth();
  let user: RegisteredUser | undefined;
  let address:
    | {
        first_name: string;
        last_name: string;
        location_name: string;
        address: string;
        city: string;
        state: string;
        postal_code: string;
        country: string;
      }
    | undefined;
  if (auth.status === AuthStatus.LoggedIn) {
    user = auth.currentUser;
    if (user?.location) {
      address = {
        first_name: user.first_name ?? '',
        last_name: user.last_name ?? '',
        location_name: user.location.name ?? '',
        address: user.location.address ?? '',
        city: user.location.city ?? '',
        state: user.location.state ?? '',
        postal_code: user.location.zip_code ?? '',
        country: 'US',
      };
    } else if (user?.company && user?.company.addresses && user?.company.addresses.length > 0) {
      const companyAddress = user.company.addresses[0];
      address = {
        first_name: user.first_name ?? '',
        last_name: user.last_name ?? '',
        location_name: companyAddress.name ?? '',
        address: companyAddress.address ?? '',
        city: companyAddress.city ?? '',
        state: companyAddress.state ?? '',
        postal_code: companyAddress.zip_code ?? '',
        country: 'US',
      };
    }
  }
  const { requestOrder, isLoading, canCreateQuote } = usePendingQuote();
  const context = useFormContext<IProjectOrderRequestSchema>();
  if (!canCreateQuote) {
    return null;
  }

  return (
    <>
      <div className="c-block__header u-mb-spacer-base-small">
        <h5 className="c-title--xxs">
          <strong>{step === PendingQuoteModalStep.Shipping ? 'Shipping' : 'Checkout'}</strong>
        </h5>
      </div>
      <Form
        schema={ProjectOrderRequestSchema}
        initialValues={Object.assign(
          { state: '' },
          {
            ...address,
          },
          getPendingOrderPersistedValues()
        )}
        // onInvalidSubmit={(data, errors) => {
        // }}
        // onInvalid={(errors) => {
        //   console.log(errors);
        // }}
        onSubmit={async (data, ctx) => {
          console.log(data);
          await requestOrder(typeof products !== 'undefined' ? { ...data, products } : data);
          toast.notify({
            title: 'Order Sent',
            type: 'success',
            message:
              "We've received your order and we'll contact you via email soon! (Be sure to check your junk/spam folder just in case!)",
          });
          onSubmit && onSubmit();
          await Promise.all([
            queryClient
              .cancelQueries(api.quote.listQuotes.getQueryKey()[0])
              .then(() => queryClient.invalidateQueries(api.quote.listQuotes.getQueryKey()[0])),
            queryClient
              .cancelQueries(api.quote.getCurrentQuote.getQueryKey()[0])
              .then(() => queryClient.invalidateQueries(api.quote.getCurrentQuote.getQueryKey()[0])),
          ]);
          cleanPendingOrderPersistedValues();
          ctx.reset();
        }}
        className={className}
        hasFloatingLabels
      >
        <PersistOrderPersistValues />
        <div
          className="o-row o-row--small-gutters"
          style={{ display: step === PendingQuoteModalStep.Shipping ? 'flex' : 'none' }}
        >
          <div className="o-col-12@sm">
            <InputField name="location_name" label="Recipient Company" elStyle="fill" />
          </div>
          <div className="o-col-6@sm">
            <InputField name="first_name" label="First name" elStyle="fill" />
          </div>
          <div className="o-col-6@sm">
            <InputField name="last_name" label="Last Name" elStyle="fill" />
          </div>
          <div className="o-col-12@sm">
            <InputField name="address" label="Address" elStyle="fill" />
          </div>
          <div className="o-col-12@sm">
            <InputField name="address2" label="Additional" elStyle="fill" />
          </div>
          <div className="o-col-4@sm">
            <InputField name="city" label="City" elStyle="fill" />
          </div>
          <div className="o-col-4@sm">
            <SelectField name="state" label="State/Province" elStyle="fill">
              <optgroup label="United States">
                {getObjectKeys(usStates).map((state) => (
                  <option key={usStates[state]} value={usStates[state]}>
                    {state}
                  </option>
                ))}
              </optgroup>
              <optgroup label="Canada">
                {getObjectKeys(caProvinces).map((state) => (
                  <option key={caProvinces[state]} value={caProvinces[state]}>
                    {state}
                  </option>
                ))}
              </optgroup>
            </SelectField>
          </div>
          <div className="o-col-4@sm">
            <InputField name="postal_code" label="Postal Code" elStyle="fill" />
          </div>
          <div className="o-col-12@sm">
            <SelectField name="country" label="Country" elStyle="fill">
              {getObjectKeys(Countries).map((country) => (
                <option key={Countries[country]} value={Countries[country]}>
                  {country}
                </option>
              ))}
            </SelectField>
          </div>
          <div className="o-col-12@sm">
            <InputField name="phone" label="Phone" elStyle="fill" />
          </div>
          <div className="o-col-12">
            <div className="o-row u-items-center">
              <div className="o-col@sm u-text-right@sm">
                {renderFooter(
                  <Button
                    variant="secondary"
                    isLoading={isLoading}
                    isFull
                    onClick={(e) => {
                      e.preventDefault();
                      setStep(PendingQuoteModalStep.Confirmation);
                    }}
                  >
                    Checkout
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="o-row o-row--small-gutters"
          style={{ display: step === PendingQuoteModalStep.Confirmation ? 'flex' : 'none' }}
        >
          <div className="o-col-12">
            <InputField name="project_name" label="Project Name" elStyle="fill" />
          </div>
          <div className="o-col-12">
            <InputField name="message" label="Message" type="textarea" elStyle="fill" />
          </div>
          <div className="o-col-12 u-mb-spacer-base-small">
            <InputField name="files" type="file" elStyle="fill" inputProps={{ multiple: true }} />
          </div>
          <div className="o-col-12">
            <div className="o-row u-items-center">
              <div className="o-col@sm">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  className="c-link-cta-basic c-link-cta-basic--small u-mb-spacer-base u-mb-0@sm"
                  onClick={(event) => {
                    event.preventDefault();
                    setStep(PendingQuoteModalStep.Shipping);
                  }}
                >
                  <Icon name="arrow" className="o-svg-icon o-svg-left" /> <span>Shipping</span>
                </a>
              </div>
              <div className="o-col@sm u-text-right@sm">
                <SubmitButton
                  variant="secondary"
                  isLoading={isLoading}
                  isFull
                  onClick={(event) => {
                    console.log(context);
                  }}
                >
                  Submit Order
                </SubmitButton>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};
