import * as React from 'react';

import api from 'api';
import { useMutation } from 'hooks/query';

export const RequestPasswordResetButton: React.VFC<{ email: string; disabled?: boolean }> = ({ email, disabled }) => {
  const [requestPasswordReset, requestPasswordResetMutation] = useMutation(api.auth.requestReset);

  if (requestPasswordResetMutation.isSuccess) {
    return <p>A password reset email has been sent. Please click on the link to reset your email.</p>;
  }

  if (requestPasswordResetMutation.isError) {
    return <p>We were not able to request a password reset. Please, try again later.</p>;
  }

  if (requestPasswordResetMutation.isLoading) {
    return (
      <button
        type="button"
        className="c-link-cta"
        onClick={(event) => {
          event.preventDefault();
        }}
        disabled
      >
        <span>REQUESTING A PASSWORD RESET...</span>
      </button>
    );
  }

  return (
    <button
      className="c-link-cta"
      type="button"
      onClick={(event) => {
        event.preventDefault();

        if (window.confirm(`Do you really want to request a password reset for "${email}" account?`)) {
          requestPasswordReset([{ requestPasswordReset: { email } }]);
        }
      }}
      disabled={disabled}
    >
      <span>ASK FOR A PASSWORD RESET</span>
    </button>
  );
};
