import Header from './components/Header';
import Footer from './components/Footer';

import Cover from './pages/Cover';
import Introduction from './pages/Introduction';
import Product from './pages/Product';
import Toc from './pages/Toc';
import Summary from './pages/Summary';
import Notes from './pages/Notes';
import Divider from './pages/Divider';

const component = {
  Header,
  Footer,
};

const page = {
  Cover,
  Introduction,
  Product,
  Toc,
  Summary,
  Notes,
  Divider,
};

const spbPdf = {
  component,
  page,
};

export default spbPdf;
