import { useAbility } from 'auth';
import { ExtractRouteParams, RouteProps } from 'react-router';
import { TAppAbilityCanParams } from 'auth/authorization';
import GuardedRoute from 'my-account/components/GuardedRoute';
import Page403Error from 'my-account/pages/403page';

export interface AbilityGuardedRouteProps<
  Path extends string = string,
  Params extends { [K: string]: string | undefined } = ExtractRouteParams<Path, string>
> extends RouteProps<Path, Params> {
  subject: TAppAbilityCanParams[1];
  action: TAppAbilityCanParams[0];
}

export function AbilityGuardedRoute<
  Path extends string = string,
  Params extends { [K: string]: string | undefined } = ExtractRouteParams<Path, string>
>({ subject, action, ...rest }: AbilityGuardedRouteProps<Path, Params>) {
  const ability = useAbility();

  return (
    <GuardedRoute
      {...rest}
      guard={(params, children) => {
        if (ability.can(action, subject)) {
          return children();
        }

        return <Page403Error />;
      }}
    />
  );
}

export default AbilityGuardedRoute;
