import * as React from 'react';

export interface DisplayPriceProps {
  value: number;
  currency?: string;
  hideCurrencySign?: boolean;
}

export const DisplayPrice: React.VFC<DisplayPriceProps> = (props) => {
  return <>{getDisplayPrice(props)}</>;
};

export function getDisplayPrice({ value, currency = 'USD', hideCurrencySign }: DisplayPriceProps) {
  if (hideCurrencySign) {
    const options = new Intl.NumberFormat('en', { currency, style: 'currency' }).resolvedOptions();

    return value.toLocaleString('en', {
      ...options,
      style: 'decimal',
    });
  }

  return value.toLocaleString('en', {
    currency,
    style: 'currency',
  });
}
