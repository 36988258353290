export const truncate = (input: string, length: number) => {
  const value = input.trim();
  return value.length <= length ? value : value.substring(0, length).trim() + '...';
};

export const calcReadTime = (text: string) => {
  const wordsPerMinute = 200;
  const noOfWords = text.split(/\s/g).length;
  const minutes = noOfWords / wordsPerMinute;
  const readTime = Math.ceil(minutes);
  return readTime;
};
