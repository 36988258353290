import { fromImage } from 'imtool';

import { CroppedImageCancelled, GetCroppedImage } from './shared';

export const getCroppedImage: GetCroppedImage = ({ image, settings }) => {
  let cancelled = false;

  if (!image.image) {
    return Object.assign(Promise.reject<string>(new Error('Invalid image.')), {
      cancel: () => {
        cancelled = true;
      },
    });
  }

  if (
    !settings ||
    (settings.left === 0 &&
      settings.top === 0 &&
      Math.floor(settings.width) === Math.floor(image.width ?? 0) &&
      Math.floor(settings.height) === Math.floor(image.height ?? 0) &&
      settings.rotate === 0 &&
      settings.flip === 0)
  ) {
    return Object.assign(Promise.resolve(image.image), {
      cancel: () => {
        cancelled = true;
      },
    });
  }

  const promise = fetch(image.image).then((response) => {
    if (cancelled) {
      throw new CroppedImageCancelled();
    }

    if (response.status !== 200) {
      throw new Error(`Unable to fetch image ${image.image}.`);
    }

    return response
      .blob()
      .then((value) => {
        if (cancelled) {
          throw new CroppedImageCancelled();
        }

        return fromImage(value);
      })
      .then((obj) => {
        if (cancelled) {
          throw new CroppedImageCancelled();
        }

        // Make sure that image is the right size.
        return obj.scale(Math.floor(image.width ?? 0), Math.floor(image.height ?? 0));
      })
      .then((obj) => {
        if (cancelled) {
          throw new CroppedImageCancelled();
        }

        if (settings.flip) {
          return obj.flipH();
        }

        return obj;
      })
      .then((obj) => {
        if (cancelled) {
          throw new CroppedImageCancelled();
        }

        if (settings.rotate) {
          return obj.rotate((settings.rotate * Math.PI) / 180);
        }

        return obj;
      })
      .then((obj) => {
        if (cancelled) {
          throw new CroppedImageCancelled();
        }

        if (
          settings.left !== 0 ||
          settings.top !== 0 ||
          settings.width !== image.width ||
          settings.height !== image.height
        ) {
          return obj.crop(
            Math.ceil(settings.left),
            Math.ceil(settings.top),
            Math.floor(settings.width),
            Math.floor(settings.height)
          );
        }

        return obj;
      })
      .then(async (obj) => {
        if (cancelled) {
          throw new CroppedImageCancelled();
        }

        const url = await obj.quality(1).toDataURL();
        return url;
      })
      .then((url) => {
        if (cancelled) {
          throw new CroppedImageCancelled();
        }

        return url;
      });
  });

  return Object.assign(promise, {
    cancel: () => {
      cancelled = true;
    },
  });
};
