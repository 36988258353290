import * as React from 'react';
import { useFieldArray } from 'react-hook-form';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import chunk from 'lodash/chunk';
import { SetOptional } from 'type-fest';

import { IntroductionBlockType, IntroductionPageType } from '__generated-api__';
import { getCounter } from 'my-account/utils/counter';
import { IntroductionPageDefaultValue, ProjectDefaultValuesType } from 'my-account/validations/project';
import { SortableIntroductionBlockField } from 'my-account/components/SPB/IntroductionBlockField';

const getLocalBlockId = getCounter(0, 'introduction_page_block_');

const IntroductionPageFields: React.VFC<{
  currentPagePreview: number;
  addNewPage: (
    page?:
      | SetOptional<ProjectDefaultValuesType['latest']['pages'][0], 'id'>
      | Array<SetOptional<ProjectDefaultValuesType['latest']['pages'][0], 'id'>>,
    index?: number
  ) => void;
}> = ({ currentPagePreview, addNewPage }) => {
  const {
    fields: blocks,
    append,
    move,
    update,
  } = useFieldArray<IntroductionPageDefaultValue, 'blocks'>({
    name: `latest.pages.${currentPagePreview}.blocks` as unknown as 'blocks',
  });

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
      onActivation: ({ event }) => {
        event.preventDefault();
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const activeItemIndex = blocks.findIndex((block) => block.id === active.id);
      const overItemIndex = blocks.findIndex((block) => block.id === over.id);
      move(activeItemIndex, overItemIndex);
    }
  }

  React.useEffect(() => {
    if (blocks.length < 4) {
      for (let i = 0; i < 4 - blocks.length; i++) {
        append({
          id: getLocalBlockId(),
          type: IntroductionBlockType.Introduction,
          settings: {},
        });
      }
    }
  }, [append, blocks.length]);

  return (
    <div className="u-mb-spacer-base-large">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      >
        <SortableContext items={blocks} strategy={verticalListSortingStrategy}>
          {blocks.map((block, index) => (
            <SortableIntroductionBlockField
              key={block.id}
              id={block.id}
              name={`latest.pages.${currentPagePreview}.blocks.${index}`}
              title={`Introduction ${index + 1}`}
              onInsert={(introductions) => {
                const emptyBlocks = blocks.filter(
                  (emptyBlock) => emptyBlock.id !== block.id && typeof emptyBlock.content === 'undefined'
                );

                if (emptyBlocks.length) {
                  introductions.slice(0, emptyBlocks.length).forEach((introduction, index) => {
                    update(
                      blocks.findIndex((item) => item.id === emptyBlocks[index].id),
                      {
                        id: getLocalBlockId(),
                        type: IntroductionBlockType.Introduction,
                        content: { ...introduction },
                        settings: {
                          content: introduction.content,
                          name: introduction.name,
                          show_link: introduction.show_link,
                          link: introduction.link,
                        },
                      }
                    );
                  });
                }

                if (introductions.length > emptyBlocks.length) {
                  addNewPage(
                    chunk(introductions.slice(emptyBlocks.length)).map((pageIntroductions) => {
                      return {
                        type: IntroductionPageType.Introduction,
                        blocks: pageIntroductions.map((introduction) => {
                          return {
                            id: getLocalBlockId(),
                            type: IntroductionBlockType.Introduction,
                            content: { ...introduction },
                            settings: {
                              content: introduction.content,
                              name: introduction.name,
                              show_link: introduction.show_link,
                              link: introduction.link,
                            },
                          };
                        }),
                      };
                    }),
                    currentPagePreview + 1
                  );
                }
              }}
            />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default IntroductionPageFields;
