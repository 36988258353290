import { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { z } from 'zod';

import { GetRefreshTokenBodyGrantTypeEnum } from '__generated-api__';
import api from 'api';
import httpClient from 'api/httpClient';

export interface AuthData {
  accessToken: string;
  refreshToken: string;
  expires: number;
}

const authDataSchema = z.object({
  accessToken: z.string().nonempty(),
  refreshToken: z.string().nonempty(),
  expires: z.number().positive(),
});
const authCookieKey = '_account_auth';
export function hasAuthCookie() {
  return document.cookie.split(';').some((item) => item.trim().indexOf(`${authCookieKey}=`) === 0);
}

function validateAuthData(value: any): value is AuthData {
  return authDataSchema.safeParse(value).success;
}

export function getAuthData() {
  const cookie = Cookies.get(authCookieKey);

  if (!cookie) {
    return undefined;
  }

  let jsonData: AuthData | undefined;
  try {
    jsonData = JSON.parse(cookie);

    if (!validateAuthData(jsonData)) {
      throw new Error('Invalid data stored in cookie.');
    }
  } catch (error) {
    Cookies.remove(authCookieKey);
  }

  return jsonData;
}

export function prepareAuthData<T extends { access_token?: string; refresh_token?: string; expires_in?: number }>({
  access_token,
  refresh_token,
  expires_in,
}: T) {
  if (!access_token || !refresh_token || !expires_in) {
    throw new Error('Invalid auth data');
  }

  const authData: AuthData = {
    accessToken: access_token,
    refreshToken: refresh_token,
    expires: Math.floor(Date.now() / 1000) + expires_in,
  };

  return authData;
}

function setAxiosAccessToken(accessToken: string) {
  httpClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
}

export function setAuthData(authData: AuthData) {
  setAxiosAccessToken(authData.accessToken);
  Cookies.set(authCookieKey, JSON.stringify(authData), { expires: 30 });
}

export function removeAuthData() {
  removeRefreshTokenInterceptor();
  delete httpClient.defaults.headers.common.Authorization;
  Cookies.remove(authCookieKey);
}

let refreshTokenInterceptor: number | undefined;
let refreshPromise: ReturnType<typeof api.auth.getAuthToken> | undefined;

function refreshToken(refresh_token: string) {
  if (!refreshPromise) {
    refreshPromise = api.auth
      .getAuthToken({
        getTokenBody: {
          client_id: process.env.REACT_APP_API_CLIENT_ID,
          client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
          grant_type: GetRefreshTokenBodyGrantTypeEnum.RefreshToken,
          refresh_token: refresh_token,
        },
      })
      .then((res) => {
        refreshPromise = undefined;
        return res;
      })
      .catch((error) => {
        refreshPromise = undefined;
        throw error;
      });
  }

  return refreshPromise;
}

export function removeRefreshTokenInterceptor() {
  if (refreshTokenInterceptor) {
    httpClient.interceptors.response.eject(refreshTokenInterceptor);
    refreshTokenInterceptor = undefined;
  }
}

export function addRefreshTokenInterceptor(refresh_token: string) {
  removeRefreshTokenInterceptor();

  refreshTokenInterceptor = httpClient.interceptors.response.use(
    (res) => res,
    async (error: AxiosError) => {
      if (error.response?.status !== 401) {
        throw error;
      }

      // Remove it because of possible infinite loop
      removeRefreshTokenInterceptor();

      return refreshToken(refresh_token)
        .then(async (res) => {
          const authData = prepareAuthData(res.data);
          setAuthData(authData);
          // @ts-ignore
          if (!error.config.headers) {
            // @ts-ignore
            error.config.headers = {};
          }

          // @ts-ignore
          error.config.headers['Authorization'] = `Bearer ${authData.accessToken}`;

          // @ts-ignore
          const orgRes = await httpClient.request(error.config);
          addRefreshTokenInterceptor(authData.refreshToken);
          return orgRes;
        })
        .catch((refreshTokenError) => {
          console.error(refreshTokenError);

          removeAuthData();

          // eslint-disable-next-line no-self-assign
          window.location = window.location;
        });
    }
  );
}

const authData = getAuthData();

if (authData) {
  setAxiosAccessToken(authData.accessToken);
  addRefreshTokenInterceptor(authData.refreshToken);
}
