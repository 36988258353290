import * as React from 'react';
import ReactModal from 'react-modal';

export interface ModalProps extends ReactModal.Props {}

export const Modal: React.FC<ModalProps> = (props) => {
  return (
    <ReactModal
      {...props}
      style={{
        overlay: {
          zIndex: 1001,
          overscrollBehavior: 'contain',
          overflowY: 'auto',
          paddingLeft: '1rem',
          paddingRight: '1rem',
          ...props.style?.overlay,
        },
        content: {
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          margin: '2rem auto',
          minHeight: 'calc(100% - 4rem)',
          background: 'transparent',
          padding: 0,
          borderRadius: 0,
          border: 'none',
          maxWidth: '38.75rem',
          top: undefined,
          left: undefined,
          bottom: undefined,
          right: undefined,
          overflow: undefined,
          ...props.style?.content,
        },
      }}
    />
  );
};
