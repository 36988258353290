import * as React from 'react';
import ReactDOM from 'react-dom';
import { Toast } from 'my-account/toast/Toast';
import { ToastObject, ToastOptions } from 'my-account/toast/types';

const getNextToastId = (() => {
  let id = 0;

  return () => ++id;
})();

interface ToastContextValueType {
  notify: (options: ToastOptions) => number;
  close: (id: number) => void;
  closeAll: () => void;
}

const ToastContext = React.createContext<ToastContextValueType>({
  notify: (options: ToastOptions) => {
    return 0;
  },
  close: (id: number) => {},
  closeAll: () => {},
});

const Toasts: React.VFC<{ toasts: ToastObject[]; close: ToastContextValueType['close'] }> = ({ toasts, close }) => {
  return ReactDOM.createPortal(
    <div className="c-toast-container">
      {toasts.map((toast) => (
        <Toast key={toast.id} {...toast} onClose={() => close(toast.id)} />
      ))}
    </div>,
    document.body
  );
};

export const ToastProvider: React.FC = ({ children }) => {
  const [toasts, setToasts] = React.useState<ToastObject[]>([]);

  const notify = React.useCallback(
    (options: ToastOptions) => {
      const id = getNextToastId();
      setToasts((prevToasts) => [{ id, ...options }, ...prevToasts]);

      return id;
    },
    [setToasts]
  );

  const close = React.useCallback(
    (id: number) => {
      setToasts((prevToasts) => prevToasts.filter((item) => item.id !== id));
    },
    [setToasts]
  );

  const closeAll = React.useCallback(() => {
    setToasts([]);
  }, [setToasts]);

  const contextValue = React.useMemo<ToastContextValueType>(
    () => ({
      notify,
      close,
      closeAll,
    }),
    [notify, close, closeAll]
  );

  return (
    <ToastContext.Provider value={contextValue}>
      {children}
      <Toasts toasts={toasts} close={close} />
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  return React.useContext(ToastContext);
};
