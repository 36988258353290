import classNames from 'classnames';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import cloneDeep from 'lodash/cloneDeep';
import * as React from 'react';
import { useController, useWatch } from 'react-hook-form';
import { SetOptional } from 'type-fest';

import {
  CoverPageType,
  DividerPageType,
  IntroductionPageType,
  NotesPageType,
  // PdfPageType,
  ProductPageType,
  Project,
  ProjectVersion,
  ProjectVersionStatus,
  SummaryPageType,
  TocPageType,
} from '__generated-api__';
import InputField from 'components/Form/InputField';
import Icon from 'components/icon';
import DocumentSettingsField from 'my-account/components/SPB/DocumentSettingsField';
import PageContentFields from 'my-account/components/SPB/PageContentFields';
import PageHeaderField from 'my-account/components/SPB/PageHeaderField';
import PageTypeField from 'my-account/components/SPB/PageTypeField';
import { ProjectDefaultValuesType, getLocalPageId } from 'my-account/validations/project';
import DocumentPreviewCanvas from './DocumentPreviewCanvas';
import DownloadDocumentButton from './DownloadDocumentButton';
import PagesNav from './PagesNav';
import ShareButton from './ShareButton';
import ViewPreviewButton from './ViewPreviewButton';

const DocumentBuilder: React.VFC<{
  project: Project | undefined;
  projectVersion: ProjectVersion | undefined;
  isPublicView?: boolean;
}> = ({ project, projectVersion, isPublicView }) => {
  const [currentPagePreview, setCurrentPagePreview] = React.useState(0);
  const { field } = useController<ProjectDefaultValuesType, 'latest.pages'>({
    name: 'latest.pages',
  });
  const pages = useWatch<ProjectDefaultValuesType, 'latest.pages'>({ name: 'latest.pages' });
  const focusOnPageWhenAvailable = React.useRef<number | undefined>(undefined);

  const addNewPage = (
    input?:
      | SetOptional<ProjectDefaultValuesType['latest']['pages'][0], 'id'>
      | Array<SetOptional<ProjectDefaultValuesType['latest']['pages'][0], 'id'>>,
    index?: number
  ) => {
    const inputPages = Array.isArray(input) ? input : [input];
    let newValue: ProjectDefaultValuesType['latest']['pages'] = [...pages];
    if (pages.length > 84) {
      alert(
        'The spec pack builder only supports up to 85 pages. \n\nPlease contact support if you need longer Specification Packages.'
      );
      return;
    }
    // Focus on last newly added page
    focusOnPageWhenAvailable.current = Math.min(
      field.value.length - 1 + inputPages.length,
      index ? index - 1 + inputPages.length : Infinity
    );
    console.log('inputPages', inputPages);
    for (let i = 0; i < inputPages.length; i++) {
      const page = inputPages[i];
      const defaultNamePrefix =
        page?.type === CoverPageType.Cover
          ? 'Cover'
          : page?.type === IntroductionPageType.Introduction
          ? 'Introduction'
          : page?.type === ProductPageType.Product
          ? 'Products'
          : page?.type === TocPageType.Toc
          ? 'Table of Content'
          : page?.type === SummaryPageType.Summary
          ? 'Summary'
          : page?.type === NotesPageType.Notes
          ? 'Notes'
          : page?.type === DividerPageType.Divider
          ? 'Divider'
          : // page?.type === PdfPageType.Pdf
            // ? 'PDF' :
            'Page';

      const namePrefixRegex = new RegExp(`^${defaultNamePrefix} (\\d+)$`);
      const pageObj: ProjectDefaultValuesType['latest']['pages'][0] = {
        id: getLocalPageId(),
        type: '',
        name: `${defaultNamePrefix} ${
          Math.max(
            ...newValue.map(({ name }) => {
              if (name && name.match(namePrefixRegex)) {
                return parseInt(name.replace(namePrefixRegex, '$1'), 10);
              }

              return 0;
            })
          ) + 1
        }`,
        blocks: null,
        ...page,
      };

      if (typeof index !== 'undefined') {
        newValue = newValue.slice(0, index + i).concat(pageObj, newValue.slice(index + i));
      } else {
        newValue.push(pageObj);
      }
    }
    field.onChange(newValue);
  };

  const removePage = (index: number) => {
    if (currentPagePreview === field.value.length - 1) {
      setCurrentPagePreview(currentPagePreview - 1);
    }
    let newValue: ProjectDefaultValuesType['latest']['pages'] = [...pages];
    field.onChange([...newValue.slice(0, index), ...newValue.slice(index + 1)]);
  };

  const movePage = (from: number, to: number) => {
    let newValue: ProjectDefaultValuesType['latest']['pages'] = [...pages];
    field.onChange(
      from < to
        ? [...newValue.slice(0, from), ...newValue.slice(from + 1, to + 1), newValue[from], ...newValue.slice(to + 1)]
        : [...newValue.slice(0, to), newValue[from], ...newValue.slice(to, from), ...newValue.slice(from + 1)]
    );
  };

  // Update currentPagePreview to focusOnPageWhenAvailable.current once it's available in the form state
  React.useEffect(() => {
    if (
      typeof focusOnPageWhenAvailable.current !== 'undefined' &&
      typeof pages[focusOnPageWhenAvailable.current] !== 'undefined'
    ) {
      const focusOnPage = focusOnPageWhenAvailable.current;
      focusOnPageWhenAvailable.current = undefined;
      setCurrentPagePreview(focusOnPage);
    }
  }, [pages, currentPagePreview]);
  const currentPage = React.useMemo(
    () => (currentPagePreview <= pages.length - 1 ? cloneDeep(pages[currentPagePreview]) : undefined),
    [currentPagePreview, pages]
  );
  const isEditable =
    projectVersion?.status !== ProjectVersionStatus.Published &&
    projectVersion?.status !== ProjectVersionStatus.Publishing &&
    projectVersion?.status !== ProjectVersionStatus.Complete;

  return (
    <>
      <section className="c-block c-block--spacing-t-extra-small c-block--spacing-b-extra-small c-block--bg-light">
        <div className="o-container-fluid">
          <div className="o-row">
            <div className="o-col-6@md o-col-3@lg">
              <p className="u-mb-spacer-base-small u-text-xs u-font-medium u-uppercase u-text-neutral-800">
                {isPublicView ? 'View Project' : 'Document Builder'}
              </p>
              {!isPublicView && (
                <p className="c-note">
                  Use the building blocks below and in the right panel to design your own custom document.
                </p>
              )}
              <div className="u-mb-spacer-base-large">
                <ViewPreviewButton project={project} />

                {projectVersion?.status === ProjectVersionStatus.Published && typeof project !== 'undefined' && (
                  <>
                    <hr className="u-my-spacer-base"></hr>
                    <DownloadDocumentButton project={project} projectVersion={projectVersion} />
                    <ShareButton project={project} />
                  </>
                )}
                {isEditable && !isPublicView && (
                  <>
                    <PageHeaderField />
                    {/* <button className="c-button c-button--white c-button--option c-button--full" type="button">
                      <Icon name="edit" className="o-svg-icon o-svg-large" />
                      <span>Edit Footer</span>
                    </button> */}
                    <DocumentSettingsField />
                    <button
                      className="c-button c-button--white c-button--option c-button--full"
                      onClick={(event) => {
                        event.preventDefault();
                        addNewPage(undefined, currentPagePreview + 1);
                      }}
                      type="button"
                    >
                      <Icon name="add-thin" className="o-svg-icon o-svg-large" />
                      <span>Add Next Page</span>
                    </button>
                  </>
                )}
              </div>
            </div>
            {isEditable && (
              <div className="o-col-6@md o-col-3@lg u-order-3@lg">
                <p className="u-mb-spacer-base-small u-text-xs u-font-medium u-uppercase u-text-neutral-800">
                  Page Options
                </p>

                {typeof currentPage !== 'undefined' && (
                  <>
                    <InputField
                      key={`${currentPage.id}_name`}
                      name={`latest.pages.${currentPagePreview}.name`}
                      label="Page Name"
                      elStyle="fill"
                      small
                    />
                    <PageTypeField key={`${currentPage.id}_type`} currentPagePreview={currentPagePreview} />
                    <hr className="u-my-spacer-base" />
                    <PageContentFields
                      key={`${currentPage.id}_content`}
                      currentPagePreview={currentPagePreview}
                      addNewPage={addNewPage}
                    />
                  </>
                )}
              </div>
            )}
            <div
              className={classNames(
                'u-order-2@lg',
                'u-px-spacer-section-small@lg',
                isEditable ? 'o-col-6@lg' : 'o-col-9@lg'
              )}
            >
              <div className="c-document-preview-wrap">
                <div className="c-document-preview slick-initialized slick-slider">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    href="#"
                    className={classNames('c-slider__arrow c-slider__arrow--prev slick-arrow', {
                      'slick-disabled': currentPagePreview === 0,
                    })}
                    aria-disabled={currentPagePreview === 0}
                    style={{ display: 'block' }}
                    onClick={(event) => {
                      event.preventDefault();

                      setCurrentPagePreview((val) => {
                        return Math.max(0, val - 1);
                      });
                    }}
                  >
                    <Icon name="chevron" className="c-slider__arrow-thick o-icon o-svg-icon" />
                  </a>
                  <div className="c-document-preview__item">
                    {typeof currentPage !== 'undefined' && (
                      <DocumentPreviewCanvas
                        key={currentPage.id}
                        pageIndex={currentPagePreview}
                        pages={pages}
                        user={project?.user ?? undefined}
                        className="c-document-preview__page"
                        effectiveDate={format(
                          project && project.updated_at ? parseISO(project.updated_at) : new Date(),
                          'MMM d y'
                        )}
                      />
                    )}
                  </div>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    href="#"
                    className={classNames('c-slider__arrow c-slider__arrow--next slick-arrow', {
                      'slick-disabled': currentPagePreview === pages.length - 1,
                    })}
                    aria-disabled={currentPagePreview === pages.length - 1}
                    style={{ display: 'block' }}
                    onClick={(event) => {
                      event.preventDefault();

                      setCurrentPagePreview((val) => {
                        return Math.min(pages.length - 1, val + 1);
                      });
                    }}
                  >
                    <Icon name="chevron" className="c-slider__arrow-thick o-icon o-svg-icon" />
                  </a>
                </div>
                <div
                  className="c-document-add"
                  onClick={(event) => {
                    event.preventDefault();
                    addNewPage();
                  }}
                >
                  <Icon name="add-thin" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PagesNav
        addNewPage={addNewPage}
        removePage={removePage}
        movePage={movePage}
        currentPagePreview={currentPagePreview}
        setCurrentPagePreview={setCurrentPagePreview}
        user={project?.user}
        effectiveDate={format(project && project.updated_at ? parseISO(project.updated_at) : new Date(), 'MMM d y')}
        isEditable={isEditable}
      />
    </>
  );
};

export default DocumentBuilder;
