import * as React from 'react';

import { bufferToDataUrl } from 'my-account/utils/data-url';
import { useCroppedImage, UseCroppedImageProps } from 'spb-pdf/utils/image';

const CroppedImage: React.VFC<UseCroppedImageProps> = ({ image, settings }) => {
  const imageState = useCroppedImage({ image, settings });
  if (imageState.status === 'loading') {
    return (
      <img
        src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
        alt={image.name ?? ''}
        width={settings?.width ?? image.width ?? 0}
        height={settings?.height ?? image.height ?? 0}
      />
    );
  }

  if (imageState.status === 'error') {
    return (
      <img
        src="data:null"
        alt={imageState.error}
        width={settings?.width ?? image.width ?? 0}
        height={settings?.height ?? image.height ?? 0}
      />
    );
  }

  return (
    <img
      src={typeof imageState.src === 'string' ? imageState.src : bufferToDataUrl(imageState.src)}
      alt={image.name ?? ''}
      width={imageState.width}
      height={imageState.height}
    />
  );
};

export default CroppedImage;
