import * as React from 'react';
import { z } from 'zod';
import ReactCrop, { Crop } from 'react-image-crop';

import Icon from 'components/icon';
import { Button } from 'components/Button';
import { ImageBlock } from 'my-account/validations/project';
import { FileImageRelationUpdateSchema } from 'my-account/validations/file';
import { useCroppedImage } from 'spb-pdf/utils/image';
import { bufferToDataUrl } from 'my-account/utils/data-url';

const EditImageModalContent: React.VFC<{
  title: string;
  image: Pick<z.infer<typeof FileImageRelationUpdateSchema>, 'name' | 'image' | 'width' | 'height'>;
  settings: z.infer<typeof ImageBlock>['settings'];
  onCancel: () => void;
  onChange: (settings: z.infer<typeof ImageBlock>['settings']) => void;
}> = ({ title, image, settings, onCancel, onChange }) => {
  const [rotate, setRotate] = React.useState(settings?.rotate ?? 0);
  const [flip, setFlip] = React.useState(settings?.flip ? true : false);
  const cropped = useCroppedImage({
    image,
    settings: { flip: flip ? 1 : 0, rotate, top: 0, left: 0, width: image.width ?? 0, height: image.height ?? 0 },
  });
  const rotatedImageWidth = rotate === 0 || rotate === 180 ? image.width ?? 0 : image.height ?? 0;
  const rotatedImageHeight = rotate === 0 || rotate === 180 ? image.height ?? 0 : image.width ?? 0;
  const [crop, setCrop] = React.useState<Partial<Crop>>({
    x: (100 * (settings?.left ?? 0)) / rotatedImageWidth,
    y: (100 * (settings?.top ?? 0)) / rotatedImageHeight,
    width: (100 * (settings?.width ?? rotatedImageWidth)) / rotatedImageWidth,
    height: (100 * (settings?.height ?? rotatedImageHeight)) / rotatedImageHeight,
    unit: '%',
  });

  return (
    <div className="c-block c-block--spacing-t-small c-block--spacing-b-small">
      <div className="o-container-fluid">
        <div className="o-row">
          <div className="o-col-12">
            <div className="c-block__header c-block__header--hor">
              <div className="c-block__header-content u-items-center u-block u-flex@sm">
                <div>
                  <h6 className="c-headline u-text-neutral-500">{title} / Image details</h6>
                  <h4>Edit Image</h4>
                </div>
              </div>
            </div>
            <div className="u-mb-spacer-base">
              <button
                className="c-button c-button--light-grey c-button--option"
                type="button"
                onClick={(event) => {
                  event.preventDefault();
                  setRotate((value) => (value === 0 ? 270 : value - 90));
                }}
              >
                <Icon name="rotate" className="o-svg-icon o-svg-large" />
                <span>Rotate</span>
              </button>{' '}
              <button
                className="c-button c-button--light-grey c-button--option"
                type="button"
                onClick={(event) => {
                  event.preventDefault();
                  setFlip((value) => !value);
                }}
              >
                <Icon name="flip" className="o-svg-icon o-svg-large" />
                <span>Flip</span>
              </button>
            </div>
            <figure className="c-image-preview">
              <div style={{ width: '100%', textAlign: 'center' }}>
                <ReactCrop
                  src={
                    cropped.status === 'success'
                      ? typeof cropped.src === 'string'
                        ? cropped.src
                        : bufferToDataUrl(cropped.src)
                      : ''
                  }
                  imageAlt={image.name ?? ''}
                  crop={crop}
                  onChange={(crop, percentageCrop) => setCrop(percentageCrop)}
                />
              </div>
            </figure>
            <div className="c-image-preview__controls u-mb-spacer-base-large">
              <div className="c-image-preview__dimensions">
                <Icon name="dimensions-locked" className="o-svg-icon o-svg-large" />
                <input
                  type="number"
                  value={Math.ceil(
                    Math.min(Math.max(1, (rotatedImageWidth * (crop.width ?? 100)) / 100), rotatedImageWidth)
                  )}
                  min={1}
                  max={rotatedImageWidth}
                  step={1}
                  onChange={(event) => {
                    setCrop((value) => ({
                      ...value,
                      width:
                        (100 * Math.min(Math.max(1, event.target.valueAsNumber), rotatedImageWidth)) /
                        rotatedImageWidth,
                    }));
                  }}
                />
                <span>x</span>
                <input
                  type="number"
                  value={Math.ceil(
                    Math.min(Math.max(1, (rotatedImageHeight * (crop.height ?? 100)) / 100), rotatedImageHeight)
                  )}
                  min={1}
                  max={rotatedImageHeight}
                  step={1}
                  onChange={(event) => {
                    setCrop((value) => ({
                      ...value,
                      height:
                        (100 * Math.min(Math.max(1, event.target.valueAsNumber), rotatedImageHeight)) /
                        rotatedImageHeight,
                    }));
                  }}
                />
                <p>
                  Original:{' '}
                  <strong>
                    {Math.ceil(image.width ?? 0)} x {Math.ceil(image.height ?? 0)}
                  </strong>
                </p>
              </div>
              <div>
                <button
                  className="c-button c-button--light-grey c-button--option"
                  type="button"
                  onClick={(event) => {
                    event.preventDefault();
                    setCrop({
                      x: 0,
                      y: 0,
                      width: 100,
                      height: 100,
                      unit: '%',
                    });
                  }}
                >
                  <Icon name="back" className="o-svg-icon o-svg-large" />
                  <span>Revert to Original</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="o-row">
          <div className="o-col-12">
            <div>
              <Button
                className="c-button--md"
                onClick={(event) => {
                  event.preventDefault();
                  onChange({
                    left: Math.max(0, Math.min((rotatedImageWidth * (crop.x ?? 0)) / 100, rotatedImageWidth - 1)),
                    top: Math.max(0, Math.min((rotatedImageHeight * (crop.y ?? 0)) / 100, rotatedImageHeight - 1)),
                    width: Math.min(Math.max(1, (rotatedImageWidth * (crop.width ?? 100)) / 100), rotatedImageWidth),
                    height: Math.min(
                      Math.max(1, (rotatedImageHeight * (crop.height ?? 100)) / 100),
                      rotatedImageHeight
                    ),
                    rotate: rotate,
                    flip: flip ? 1 : 0,
                  });
                }}
              >
                Save
              </Button>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                className="c-link-cta-basic u-ml-spacer-base"
                onClick={(event) => {
                  event.preventDefault();
                  onCancel();
                }}
              >
                <span>Cancel</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditImageModalContent;
