import * as React from 'react';
import { connectCurrentRefinements } from 'react-instantsearch-dom';
import SpriteIcon from 'search/components/sprite-icon';

interface CustomClearRefinementsProps {
  items: any[];
  refine: (value: any) => void;
}

class CustomClearRefinements extends React.Component<CustomClearRefinementsProps> {
  render() {
    const { items, refine } = this.props;
    const classes = ['ais-ClearRefinements-button', !items.length ? 'ais-ClearRefinements-button--disabled' : ''];
    return (
      <>
        <div className="ais-ClearRefinements">
          <button onClick={() => refine(items)} type="reset" className={classes.join(' ')} disabled={!items.length}>
            <SpriteIcon name="close" classes={['o-svg-icon']} />
            <span>Clear all</span>
          </button>
        </div>
      </>
    );
  }
}

const ClearRefinements = connectCurrentRefinements(CustomClearRefinements);

export default ClearRefinements;
