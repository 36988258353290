import { SalsifyDigitalAsset } from '__generated-api__';

export function getTransformedSalsifyImage(
  digital_asset: Pick<SalsifyDigitalAsset, 'salsify_format' | 'salsify_url'>,
  transformations: string[]
) {
  const preview_image_url = digital_asset.salsify_format
    ? digital_asset.salsify_url.slice(0, -digital_asset.salsify_format.length) + 'jpg'
    : digital_asset.salsify_url;

  if (!transformations.length) {
    return preview_image_url;
  }

  const preview_image_url_object = new URL(preview_image_url);

  const [filename, ...reversedPathItems] = preview_image_url_object.pathname.split('/').reverse();

  return `${preview_image_url_object.origin}${reversedPathItems.slice().reverse().join('/')}/${transformations.join(
    ','
  )}/${filename}`;
}
