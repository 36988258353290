import * as React from 'react';
import { Text, View, Link } from '@react-pdf/renderer';

interface TableOfContentsProps {
  headline: string;
  links: Array<{
    label: string;
    page: number;
    anchor?: string;
  }>;
}

const TableOfContents: React.VFC<TableOfContentsProps> = ({ headline, links }) => {
  return (
    <View style={{ paddingVertical: 20, paddingHorizontal: 45 }}>
      <Text
        style={{
          fontFamily: 'Gotham Rounded',
          fontWeight: 200,
          fontSize: 18,
          color: '#000000',
          opacity: 0.8,
          letterSpacing: -0.39,
          marginBottom: 24,
        }}
      >
        {headline}
      </Text>
      {links.map((link, index) => (
        <Link
          key={index}
          src={'#' + (link.anchor ? link.anchor : `page-${link.page}`)}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontFamily: 'Gotham Rounded',
            fontSize: 8,
            color: '#666666',
            marginBottom: 8,
            textDecoration: 'none',
            lineHeight: 1.5,
          }}
        >
          <Text>{link.label}</Text>
          <Text>{link.page}</Text>
        </Link>
      ))}
    </View>
  );
};

export default TableOfContents;
