import * as React from 'react';
import { observer } from 'mobx-react';
import SearchStore from 'search/stores/search-store';

interface StatsProps {
  store: SearchStore;
}

const Stats = observer(
  class Stats extends React.Component<StatsProps> {
    render() {
      const { store } = this.props;
      if (store.hasResults) {
        const from = (store.page - 1) * store.hitsPerPage + 1;
        const to = store.page * store.hitsPerPage <= store.nbHits ? store.page * store.hitsPerPage : store.nbHits;
        return (
          <>
            <p>
              Showing{' '}
              <strong>
                {from}-{to}
              </strong>{' '}
              of <strong>{store.nbHits}</strong> results
            </p>
          </>
        );
      } else {
        return (
          <>
            <p>No results</p>
          </>
        );
      }
    }
  }
);

export default Stats;
