import * as React from 'react';
import { observer } from 'mobx-react';
import { HitsProvided } from 'react-instantsearch-core';
import { connectHits } from 'react-instantsearch-dom';
import DocumentHit, { DocumentHitInterface } from 'search/components/documents-search/document-hit';

interface CustomDocumentHitsProps extends HitsProvided<DocumentHitInterface> {}

// TODO: remove observer if store is not going to be used
const CustomDocumentHits = observer(
  class CustomDocumentHits extends React.Component<CustomDocumentHitsProps> {
    render() {
      const { hits } = this.props;
      return (
        <>
          <ul className="o-stack-2@sm o-stack-3@md c-card__row--documents">
            {hits.map((hit) => (
              <li key={hit.objectID}>
                <article className="c-card c-card--document-full">
                  <DocumentHit hit={hit} />
                </article>
              </li>
            ))}
          </ul>
        </>
      );
    }
  }
);

const DocumentHits = connectHits<CustomDocumentHitsProps, DocumentHitInterface>(CustomDocumentHits);

export default DocumentHits;
