import * as React from 'react';
import debounce from 'lodash/debounce';
import Fuse from 'fuse.js';

export const useFuse = <ListItem extends any>(list: ListItem[], options: Fuse.IFuseOptions<ListItem>) => {
  const [query, updateQuery] = React.useState('');
  const fuse = React.useMemo(() => new Fuse<ListItem>(list, options), [list, options]);
  const results = React.useMemo(
    () => (query.trim().length ? fuse.search(query) : list.map((item, index) => ({ item, refIndex: index }))),
    [fuse, list, query]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const search = React.useCallback(debounce(updateQuery, 100), [updateQuery]);

  return {
    results,
    search,
  };
};
