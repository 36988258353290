import * as React from 'react';
import { connectHitsPerPage } from 'react-instantsearch-dom';
import wordpressData from 'data';
import SpriteIcon from 'search/components/sprite-icon';

interface CustomHitsPerPageDropdownState {
  opened: boolean;
}

interface CustomHitsPerPageDropdownPropsItem {
  label: string;
  value: number;
  isRefined: boolean;
}

interface CustomHitsPerPageDropdownProps {
  items: CustomHitsPerPageDropdownPropsItem[];
  currentRefinement: number;
  refine: (value: number) => void;
  createURL: (value: number) => string;
}

class CustomHitsPerPageDropdown extends React.Component<
  CustomHitsPerPageDropdownProps,
  CustomHitsPerPageDropdownState
> {
  state: CustomHitsPerPageDropdownState = {
    opened: false,
  };

  private wrapperRef = React.createRef<HTMLDivElement>();

  constructor(props: CustomHitsPerPageDropdownProps) {
    super(props);

    // console.log('CustomHitsPerPageDropdown::constructor::props', props);

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickOutside(event: MouseEvent) {
    if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target as Node)) {
      if (this.state.opened) {
        this.setState({ opened: false });
      }
    }
  }

  componentDidMount() {
    // console.log('CustomHitsPerPageDropdown::componentDidMount');
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    // console.log('CustomHitsPerPageDropdown::componentWillUnmount');
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  render() {
    // console.log('CustomHitsPerPageDropdown::render');
    const { items, currentRefinement, refine, createURL } = this.props;
    const classes = ['c-dropdown-wrapper', this.state.opened ? 'has-dropdown-open' : ''];

    return (
      <>
        <div className={classes.join(' ')} ref={this.wrapperRef}>
          <a
            href={wordpressData.productsUrl}
            className="c-tag c-tag--light c-tag--large c-dropdown-toggle"
            onClick={(event) => {
              event.preventDefault();
              this.setState({ opened: !this.state.opened });
            }}
          >
            <span className="c-dropdown-desc">Results per page:</span>
            <span className="c-dropdown-value">{currentRefinement}</span>
          </a>
          <div className="c-dropdown c-dropdown--right">
            <div className="c-dropdown__header c-dropdown__header--sm">
              <span className="c-dropdown__title">Select number</span>
              <span className="c-dropdown__toggle js-dropdown-toggle">
                <SpriteIcon name="close-thin" classes={['o-svg-icon']} />
              </span>
            </div>
            <div className="c-dropdown__body">
              <ul className="c-dropdown__list">
                {items.map((item) => (
                  <li key={item.value} className={item.isRefined ? 'is-selected' : ''}>
                    <a
                      href={createURL(item.value)}
                      onClick={(event) => {
                        event.preventDefault();
                        this.setState({ opened: false });
                        if (currentRefinement !== item.value) {
                          refine(item.value);
                        }
                      }}
                    >
                      {item.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const HitsPerPageDropdown = connectHitsPerPage(CustomHitsPerPageDropdown);

export default HitsPerPageDropdown;
