import * as React from 'react';
import { RefinementListProvided } from 'react-instantsearch-core';
import { connectRefinementList } from 'react-instantsearch-dom';

interface CustomObsoleteRefinmentProps extends RefinementListProvided {}

class CustomObsoleteRefinment extends React.Component<CustomObsoleteRefinmentProps> {
  render() {
    // console.log('CustomObsoleteRefinment::render::props', this.props);

    const { items, refine } = this.props;

    return (
      <>
        <div className="ais-RefinementList">
          {!!items.length && (
            <ul className="ais-RefinementList-list">
              {items
                .filter((item) => item.label === 'true')
                .map((item) => (
                  <li
                    key={item.label}
                    className={[
                      'ais-RefinementList-item',
                      item.isRefined ? 'ais-RefinementList-item--selected' : '',
                    ].join(' ')}
                  >
                    <label className="ais-RefinementList-label">
                      <input
                        className="ais-RefinementList-checkbox"
                        type="checkbox"
                        checked={item.isRefined}
                        onChange={() => {
                          refine(item.value);
                        }}
                      />
                      <span className="ais-RefinementList-labelText">Show Retired Products</span>
                      <span className="ais-RefinementList-count">{item.count}</span>
                    </label>
                  </li>
                ))}
            </ul>
          )}
        </div>
      </>
    );
  }
}

const ObsoleteRefinment = connectRefinementList(CustomObsoleteRefinment);

export default ObsoleteRefinment;
