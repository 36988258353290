import { useAuthenticatedUser } from 'auth';
import { AdminUserRoleEnum, AgencyRepUserRoleEnum, SalesRepUserRoleEnum } from '__generated-api__';
import AdminDashboardPage from 'my-account/pages/dashboard/admin';
import UserDashboardPage from 'my-account/pages/dashboard/user';
import useDocumentTitle from 'hooks/useDocumentTitle';

export default function DashboardPage() {
  useDocumentTitle('Dashboard');
  const user = useAuthenticatedUser();

  if (
    user.role === AdminUserRoleEnum.Admin ||
    user.role === SalesRepUserRoleEnum.SalesRep ||
    user.role === AgencyRepUserRoleEnum.AgencyRep
  ) {
    return <AdminDashboardPage />;
  }

  return <UserDashboardPage />;
}
