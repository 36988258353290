import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { MultipleQueriesQuery } from '@algolia/client-search';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import { SearchState as AlgoliaSearchState } from 'react-instantsearch-core';
import qs from 'qs';
import wordpressData from 'data';
import ProductSearchStore from 'search/stores/product-search-store';
import StateResultsReporter from 'search/components/product-search/state-results-reporter';
import ProductSearchContent from 'search/components/product-search/product-search-content';
import _isEmpty from 'lodash/isEmpty';
import VirtualHitsPerPage from 'search/components/virtual-hits-per-page';
import VirtualPagination from 'search/components/virtual-pagination';
import { getAlgoliaClient } from 'utils/algolia';

// console.log('product-search.tsx::wordpressData', wordpressData);

export interface SearchState extends AlgoliaSearchState {
  /**
   * This is not zero based, it shows 1 both when there are no results or when on the first page.
   */
  // page?: number

  /**
   * WARNING: Algolia typings are wrong, properties can be undefined here, e.g. collection property can be undefined!
   */
  refinementList?: {
    availabilities: string[];
    categories: string[];
    collection: string[];
    finishes: string[];
    special_attributes: string[];
    is_obsolete: string[];
    business_segments: string[];
    shower_types: string[];
    showers: string[];
    shower_type: string[];
    faucets: string[];
    tub_faucets_type: string[];
    accessories: string[];
    shower_valves_type: string[];
    mixing_valves_type: string[];
    repair_parts: string[];
  };
}

/**
 * This is a proxy search client that we use to route empty searches to WordPress and all other searches to Algolia.
 * This way we prevent initial search results empty query which takes 2/3 of Algolia search requests thus reducing costs.
 * https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/js/#implementing-a-proxy
 */
const proxySearchClient = {
  search(requests: readonly MultipleQueriesQuery[]) {
    // console.log('product-search.tsx::proxySearchClient::requests', requests);

    // intercept empty search and pull it from WordPress cache to reduce algolia bill
    // if (
    // 	requests.length === 1
    // 	&& requests[0].params
    // 	&& !requests[0].params.page
    // 	&& !requests[0].params.query
    // 	&& !requests[0].params.tagFilters
    // ) {
    // 	console.log( 'proxySearchClient::mark3' );
    // 	console.log( wordpressData.ajaxUrl );
    // 	return axios
    // 		.get(wordpressData.ajaxUrl, {
    // 			params: {
    // 				action: 'symmons_algolia_product_empty_search',
    // 				facets: requests[0].params.facets,
    // 				hitsPerPage: requests[0].params.hitsPerPage,
    // 			},
    // 		})
    // 		.then((response) => {
    // 			// console.log('proxySearchClient::response=');
    // 			// console.log(response);
    // 			console.log( 'proxySearchClient::mark3.1' );
    // 			return { results: [ response.data ] };
    // 		});
    // }

    // console.log('product-search.tsx::proxySearchClient::mark2');

    // requests = requests.map((request) => {
    //   if (
    //     request.params &&
    //     typeof request.params.query === 'string' &&
    //     request.params.query.toLowerCase().includes('readystock')
    //   ) {
    //     request.params.facetFilters = request.params.facetFilters || [];
    //   }

    //   return request;
    // });

    console.log('proxySearchClient::search::requests', requests);

    return getAlgoliaClient().search(requests);
  },
};

const createURL: (state: any) => string = (state) => `?${qs.stringify(state)}`;

const searchStateToUrl: (props: ProductSearchProps, searchState: SearchState) => string = (props, searchState) => {
  // // clean up state before we create the URL

  // let searchStateCopy = {...searchState};

  // // use "s" instead of "query" in the URL
  // searchStateCopy.s = searchStateCopy.query;
  // delete searchStateCopy.query;

  // // remove page from the URL if page equals 1 (default)
  // if ( typeof searchStateCopy.page === 'number' && searchStateCopy.page === 1 ) {
  // 	delete searchStateCopy.page;
  // }

  // // remove hitsPerPage from the URL since it's always 21
  // if (
  // 	typeof searchStateCopy.configure === 'object'
  // 	&& searchStateCopy.configure
  // ) {
  // 	delete searchStateCopy.configure.hitsPerPage;
  // }

  if (typeof searchState.configure === 'object') {
    if (searchState.configure.hasOwnProperty('distinct')) {
      delete searchState.configure.distinct;
    }
    if (_isEmpty(searchState.configure)) {
      delete searchState.configure;
    }
  }

  // // remove menu type filters if blank
  // if (typeof searchStateCopy.menu === 'object' && searchStateCopy.menu) {
  // 	if (searchStateCopy.menu.category === '') {
  // 		delete searchStateCopy.menu.category;
  // 	}
  // 	if (searchStateCopy.menu.collection === '') {
  // 		delete searchStateCopy.menu.collection;
  // 	}
  // }

  console.log('searchStateToUrl::searchState', searchState);

  // return searchStateCopy ? `${props.location.pathname}${createURL(searchStateCopy)}` : '';
  return `${props.location.pathname}${createURL(searchState)}`;
};

export const urlToSearchState = (location: any, searchStore: ProductSearchStore): SearchState => {
  let searchState: SearchState = qs.parse(location.search.slice(1));
  // searchState.query = searchState.s;

  // hard coding distinct to 1
  (searchState.configure as any) = searchState.configure || {};
  if (searchState.configure) {
    searchState.configure.distinct = 1;
  }

  searchState.hitsPerPage = Number(searchState.hitsPerPage || 12);
  searchState.page = Number(searchState.page || 1);

  // make sure non-obsolete products are always displayed
  (searchState.refinementList as any) = searchState.refinementList || {};
  (searchState.refinementList as any).is_obsolete = (searchState.refinementList as any).is_obsolete || [];
  if (!Array.isArray((searchState.refinementList as any).is_obsolete)) {
    (searchState.refinementList as any).is_obsolete = [];
  }
  if (!(searchState.refinementList as any).is_obsolete.includes('false')) {
    (searchState.refinementList as any).is_obsolete.push('false');
  }

  if (searchState.refinementList && searchState.refinementList['categories']) {
    let selectedRefinement = searchState.refinementList['categories'] as unknown as string;

    switch (selectedRefinement) {
      case 'Showers':
        searchState.refinementList.shower_type = ['Shower Systems', 'Showerheads', 'Shower Wands', 'Tub Spouts'];
        searchStore.setShowerFilterOpened(true);
        break;
      case 'Faucets':
        searchState.refinementList.faucets = ['Lavatory Faucets', 'Kitchen Faucets', 'Bar Faucets', 'Other'];
        searchStore.setFaucetsFilterOpened(true);
        break;
      case 'Tub Faucets':
        searchState.refinementList.tub_faucets_type = ['Floor-Mounted', 'Deck-Mounted', 'Other'];
        searchStore.setTubFaucetsTypeFilterOpened(true);
        break;
      case 'Accessories':
        searchState.refinementList.accessories = [
          'Grab Bars',
          'Towel Bars',
          'Towel Rings / Hooks',
          'Toilet Paper Holders',
          'Robe Hooks',
          'Soap Dishes',
          'Shelves',
          'Other',
        ];
        searchStore.setAccessoriesFilterOpened(true);
        break;
      case 'Shower Valves':
        searchState.refinementList.shower_valves_type = ['Pressure Balancing (Temptrol)', 'Commercial', 'Other'];
        searchStore.setShowerValvesTypeFilterOpened(true);
        break;
      case 'Mixing Valves':
        searchState.refinementList.mixing_valves_type = ['Controllers', 'Point of Use'];
        searchStore.setMixingValvesTypeFilterOpened(true);
        break;
      case 'Repair Parts':
        searchState.refinementList.repair_parts = ['Showers', 'Faucets', 'Mixing Valves', 'Other'];
        searchStore.setRepairPartsFilterOpened(true);
        break;
      default:
        break;
    }
    searchState.refinementList.categories = [''];
  }

  console.log('urlToSearchState::searchState', searchState);

  return searchState;
};

interface ProductSearchProps extends RouteComponentProps<any> {
  store: ProductSearchStore;
  history: any;
  location: any;
}

interface ProductSearchState {
  searchState: SearchState;
  lastLocation: any;
}

class ProductSearch extends React.Component<ProductSearchProps, ProductSearchState> {
  state: ProductSearchState = {
    searchState: urlToSearchState(this.props.location, this.props.store),
    lastLocation: this.props.location,
  };

  // constructor(props: ProductSearchProps) {
  // 	super(props);
  // 	console.log('product-search.tsx::constructor');
  // }

  static getDerivedStateFromProps(props: ProductSearchProps, state: ProductSearchState) {
    // console.log('product-search.tsx::getDerivedStateFromProps');

    if (props.location !== state.lastLocation) {
      return {
        searchState: urlToSearchState(props.location, props.store),
        lastLocation: props.location,
      };
    }

    return null;
  }

  debouncedSetUrl?: NodeJS.Timeout = undefined;

  onSearchStateChange: (searchState: SearchState) => void = (searchState) => {
    const { store } = this.props;

    if (this.debouncedSetUrl) {
      clearTimeout(this.debouncedSetUrl);
    }

    console.log('onSearchStateChange::searchState.refinementList', searchState.refinementList);

    // make sure non-obsolete products are always displayed
    (searchState.refinementList as any) = searchState.refinementList || {};
    (searchState.refinementList as any).is_obsolete = (searchState.refinementList as any).is_obsolete || [];
    if (!Array.isArray((searchState.refinementList as any).is_obsolete)) {
      (searchState.refinementList as any).is_obsolete = [];
    }
    if (!(searchState.refinementList as any).is_obsolete.includes('false')) {
      (searchState.refinementList as any).is_obsolete.push('false');
    }

    // make sure availabilities:ReadyStock™ facet is active if search query contains ReadyStock
    // this functionality also depends on a rule in algolia that ignores "ReadyStock" in the search query
    if (typeof searchState.query === 'string' && searchState.query.toLowerCase().includes('readystock')) {
      (searchState.refinementList as any) = searchState.refinementList || {};
      if (!Array.isArray((searchState.refinementList as any).availabilities)) {
        (searchState.refinementList as any).availabilities = [];
      }
      if (!(searchState.refinementList as any).availabilities.includes('ReadyStock™')) {
        (searchState.refinementList as any).availabilities.push('ReadyStock™');
      }
    }

    console.log('onSearchStateChange::searchState.refinementList', searchState.refinementList);

    this.debouncedSetUrl = setTimeout(() => {
      this.props.history.push(searchStateToUrl(this.props, searchState), searchState);
    }, 500);
    store.setSearchState(searchState);
    this.setState({ searchState });
  };

  render() {
    // console.log('product-search.tsx::render::state', this.state);

    const { store } = this.props;
    return (
      <>
        <InstantSearch
          indexName={wordpressData.algolia.productsIndex}
          searchClient={proxySearchClient}
          searchState={this.state.searchState}
          onSearchStateChange={this.onSearchStateChange}
          createURL={createURL}
        >
          <StateResultsReporter store={store} />
          <ProductSearchContent store={store} defaultSortBy={store.sortBy} defaultHitsPerPage={store.hitsPerPage} />

          {/* Without these virtual widgets, hitsPerPage and page options in this.state.searchState have no effect during the initial render, so the initial query to algolia just sends the engine defaults, then only after the initial render our custom settings in searchState kick in and second query to algolia is performed. By using these virtual widgets we're able to set settings for the initial query, the second query is also still generated but the caching layer does not actually send it to Algolia. The widget props here are not important, we just want the engine not to ignore the keys we set in searchState when the engine is doing the intial query. */}
          <VirtualHitsPerPage defaultRefinement={12} items={[{ value: 12, label: '12' }]} />
          <VirtualPagination defaultRefinement={1} />

          <Configure distinct={1} clickAnalytics />
        </InstantSearch>
      </>
    );
  }
}

export default ProductSearch;
