import * as React from 'react';
import classNames from 'classnames';
import { useController } from 'react-hook-form';
import { useMergeRefs } from 'hooks/useMergeRefs';

import { useCustomFormState } from 'components/Form/Form';

interface SelectFieldProps {
  name: string;
  label?: string;
  emptyOptionLabel?: string;
  helpText?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  children?: React.ReactNode;
  selectProps?: Omit<JSX.IntrinsicElements['select'], 'name' | 'disabled' | 'className' | 'children'>;
  allowEmpty?: boolean;
  elStyle?: 'fill';
  small?: boolean;
  defaultValue?: string;
}

export const SelectField: React.VFC<SelectFieldProps> = ({
  name,
  label,
  emptyOptionLabel,
  helpText,
  disabled,
  className,
  children,
  selectProps,
  allowEmpty,
  elStyle,
  small,
  defaultValue,
}) => {
  const { hasFloatingLabels, isFiltersForm } = useCustomFormState();
  const wrapRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLSelectElement>(null);
  const fieldController = useController({ name, defaultValue });
  const { value, ...fieldProps } = fieldController.field;
  const inputProps: JSX.IntrinsicElements['select'] = {
    ...fieldProps,
    ...selectProps,
    value: value || '',
    disabled,
    ref: useMergeRefs(fieldProps.ref, inputRef),
    onChange: React.useCallback<React.ChangeEventHandler<HTMLSelectElement>>(
      (event) => {
        fieldProps.onChange(event.target.value);

        const isActive = allowEmpty || event.currentTarget.value.length > 0;

        if (wrapRef.current) {
          if (hasFloatingLabels && isActive) {
            wrapRef.current.classList.add('fl-is-active');
          } else {
            wrapRef.current.classList.remove('fl-is-active');
          }
        }

        if (inputRef.current) {
          if (isActive) {
            inputRef.current.classList.remove('c-placeholder');
          } else {
            inputRef.current.classList.add('c-placeholder');
          }
        }

        selectProps?.onChange?.(event);
      },
      [fieldProps, allowEmpty, selectProps, hasFloatingLabels]
    ),
    onBlur: React.useCallback<React.FocusEventHandler<HTMLSelectElement>>(
      (event) => {
        fieldProps.onBlur();
        if (wrapRef.current) {
          wrapRef.current.classList.remove('fl-has-focus');
        }

        selectProps?.onBlur?.(event);
      },
      [fieldProps, selectProps]
    ),
    onFocus: React.useCallback<React.FocusEventHandler<HTMLSelectElement>>(
      (event) => {
        if (hasFloatingLabels && wrapRef.current) {
          wrapRef.current.classList.add('fl-has-focus');
        }
        selectProps?.onFocus?.(event);
      },
      [hasFloatingLabels, selectProps]
    ),
  };
  const error = fieldController.fieldState.error;

  React.useEffect(() => {
    if (inputRef.current) {
      const isActive = allowEmpty || inputRef.current.value.length > 0;

      if (wrapRef.current) {
        if (hasFloatingLabels && isActive) {
          wrapRef.current.classList.add('fl-is-active');
        } else {
          wrapRef.current.classList.remove('fl-is-active');
        }
      }

      if (isActive) {
        inputRef.current.classList.remove('c-placeholder');
      } else {
        inputRef.current.classList.add('c-placeholder');
      }
    }
  }, [hasFloatingLabels, wrapRef, inputRef, allowEmpty]);

  return (
    <div
      className={classNames(
        'c-form-element',
        'c-form-element--select',
        {
          [`c-form-element--style-${elStyle}`]: elStyle,
          'c-form-element--small': small,
          'c-form-element--error': typeof error !== 'undefined',
        },
        className
      )}
    >
      {typeof label !== 'undefined' ? (
        hasFloatingLabels ? (
          <div className="c-form-element__field">
            <div className="fl-wrap fl-wrap-input" ref={wrapRef}>
              <label htmlFor={name} className={classNames('c-form-label', { 'c-filters__title': isFiltersForm })}>
                {label}
              </label>
              <select {...inputProps} className="fl-select c-placeholder">
                <option disabled={!allowEmpty} value="" key="disabled-empty-placeholder-value">
                  {emptyOptionLabel || label}
                </option>
                {children}
              </select>
            </div>
          </div>
        ) : (
          <>
            <label htmlFor={name} className={classNames('c-form-label', { 'c-filters__title': isFiltersForm })}>
              {label}
            </label>
            <div className="c-form-element__field">
              <select {...inputProps} className="c-placeholder">
                <option disabled={!allowEmpty} value="" key="disabled-empty-placeholder-value">
                  {emptyOptionLabel || label}
                </option>
                {children}
              </select>
            </div>
          </>
        )
      ) : (
        <div className="c-form-element__field">
          <select {...inputProps}>{children}</select>
        </div>
      )}
      {typeof error !== 'undefined' && (
        <ul className="c-form-element--error__list">
          <li>{error.message}</li>
        </ul>
      )}
      {typeof helpText !== 'undefined' && <p className="c-note">{helpText}</p>}
    </div>
  );
};

export default SelectField;
