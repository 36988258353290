import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import ProductSearchStore from 'search/stores/product-search-store';
import DocumentSearchStore from 'search/stores/document-search-store';
import ProductSearch from 'search/product-search';
import DocumentSearch from 'search/document-search';

/**
 * Product Search
 */

const productSearch = document.getElementById('product-search');
if (productSearch) {
  const productSearchStore = new ProductSearchStore();

  // console.log('productSearchStore=', productSearchStore);

  // autorun(() => {
  // 	console.log('store.hasResults=', productSearchStore.hasResults);
  // 	console.log('store.hitsPerPage=', productSearchStore.hitsPerPage);
  // 	console.log('store.nbHits=', productSearchStore.nbHits);
  // 	console.log('store.nbPages=', productSearchStore.nbPages);
  // 	console.log('store.page=', productSearchStore.page);
  // });

  ReactDOM.render(
    <BrowserRouter>
      <Route path="/" render={(props) => <ProductSearch {...props} store={productSearchStore} />} />
    </BrowserRouter>,
    productSearch
  );
}

/**
 * Document Search
 */

const documentSearch = document.getElementById('document-search');
if (documentSearch) {
  const documentSearchStore = new DocumentSearchStore();

  // console.log('documentSearchStore=', documentSearchStore);

  // autorun(() => {
  // 	console.log('store.hasResults=', documentSearchStore.hasResults);
  // 	console.log('store.hitsPerPage=', documentSearchStore.hitsPerPage);
  // 	console.log('store.nbHits=', documentSearchStore.nbHits);
  // 	console.log('store.nbPages=', documentSearchStore.nbPages);
  // 	console.log('store.page=', documentSearchStore.page);
  // });

  ReactDOM.render(
    <BrowserRouter>
      <Route path="/" render={(props) => <DocumentSearch {...props} store={documentSearchStore} />} />
    </BrowserRouter>,
    documentSearch
  );
}

// if ( wordpressData.isSearch ) {
// 	ReactDOM.render(
// 		<BrowserRouter>
// 			<Route path="/" component={ProductSearch} />
// 		</BrowserRouter>,
// 		document.getElementById('product-search')
// 	);

// 	//
// 	// TODO (@ivo): Look into why HMR works in index.html but doesn't work in WordPress
// 	//
// 	// if ((module as any).hot) {
// 	// 	(module as any).hot.accept('./product-search', () => {
// 	// 		const NextProductSearch = require('./product-search').default;
// 	// 		ReactDOM.render(
// 	// 			<React.StrictMode>
// 	// 				<NextProductSearch />
// 	// 			</React.StrictMode>,
// 	// 			document.getElementById('product-search')
// 	// 		);
// 	// 	});
// 	// }
// }

// ReactDOM.render(
// 	<ProductSearchModalForm />,
// 	document.getElementById('product-search-modal-form')
// );

if (module.hot) {
  module.hot.accept();
}
