import * as React from 'react';

import Icon from 'components/icon';

export interface DataTableHeroProps {
  title: React.ReactNode;
  actions?: React.ReactNode;
  buttons?: React.ReactNode;
}

export const DataTableHero: React.VFC<DataTableHeroProps> = ({ title, actions, buttons }) => {
  return (
    <section className="c-block c-block--hero">
      <div className="o-container-fluid">
        <div className="o-row u-flex-grow u-items-center">
          <div className="o-col-12">
            <div className="c-block__header c-block__header--hor u-mt-spacer-base-large">
              <div className="c-block__header-content u-items-center u-block u-flex@sm">
                <div>
                  <h1 className="c-title--extra-large u-mr-spacer-base-small">{title}</h1>
                </div>

                {typeof actions !== 'undefined' && <div>{actions}</div>}

                {typeof buttons !== 'undefined' && <div className="u-ml-auto@sm">{buttons}</div>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="c-block__pattern c-block__pattern--light3 c-block__pattern--top3 c-block__overlay--opacity-20">
        <Icon name="pattern" className="o-svg-icon" />
      </div>
    </section>
  );
};
