import * as React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { z } from 'zod';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import classNames from 'classnames';
import { useQueryClient } from 'react-query';
import { subject } from '@casl/ability';

import { IntroductionStatus, IntroductionVisibility } from '__generated-api__';
import api from 'api';
import { useAbility } from 'auth';
import { Can } from 'auth/components/Can';
import { setLocationSearchParams } from 'utils/router';
import { useMutation } from 'hooks/query';
import Icon from 'components/icon';
import Form from 'components/Form/Form';
import { AutoSubmit } from 'components/Form/AutoSubmit';
import { CheckboxWithCountField } from 'components/Form/CheckboxField';
import { FiltersAccordion } from 'components/Accordion';
import { useToast } from 'my-account/toast';
import { MainHero } from 'my-account/components/MainHero';
import { DataTableHero } from 'my-account/components/DataTable/Hero';
import { DataTableClearFilterForm, DataTableListing } from 'my-account/components/DataTable/Listing';
import useDocumentTitle from 'hooks/useDocumentTitle';

const introductionFilterSchema = z.object({
  status_active: z.boolean(),
  status_inactive: z.boolean(),
  visibility_public: z.boolean(),
  visibility_private: z.boolean(),
});

const IntroductionFilters: React.VFC<{ counts?: { active: number; inactive: number } }> = ({ counts }) => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = React.useMemo(() => new URLSearchParams(location.search), [location.search]);

  const initialValueParse = introductionFilterSchema.safeParse({
    status_active: searchParams.getAll('status')?.includes('active') ?? false,
    status_inactive: searchParams.getAll('status')?.includes('inactive') ?? false,
    visibility_public: searchParams.getAll('visibility')?.includes('public') ?? false,
    visibility_private: searchParams.getAll('visibility')?.includes('private') ?? false,
  });

  return (
    <>
      <Form
        schema={introductionFilterSchema}
        initialValues={initialValueParse.success ? initialValueParse.data : {}}
        onSubmit={async (values) => {
          const status: Array<'active' | 'inactive'> = [];
          const visibility: Array<'public' | 'private'> = [];
          if (values.status_active) {
            status.push('active');
          }

          if (values.status_inactive) {
            status.push('inactive');
          }

          if (values.visibility_public) {
            visibility.push('public');
          }

          if (values.visibility_private) {
            visibility.push('private');
          }
          history.replace(
            setLocationSearchParams(location, {
              status,
              visibility,
              page: undefined,
              'list-page': undefined,
            })
          );
        }}
        isFiltersForm
      >
        <DataTableClearFilterForm />
        <AutoSubmit />

        <FiltersAccordion title="Introduction Status">
          <div className="ais-RefinementList">
            <ul className="ais-RefinementList-list">
              <CheckboxWithCountField label="Active" name="status_active" count={counts?.active} />
              <CheckboxWithCountField label="Inactive" name="status_inactive" count={counts?.inactive} />
            </ul>
          </div>
        </FiltersAccordion>
        <FiltersAccordion title="Introduction Visibility">
          <div className="ais-RefinementList">
            <ul className="ais-RefinementList-list">
              <CheckboxWithCountField label="Public" name="visibility_public" />
              <CheckboxWithCountField label="Private" name="visibility_private" />
            </ul>
          </div>
        </FiltersAccordion>
      </Form>
    </>
  );
};

export default function IntroductionsPage() {
  useDocumentTitle('Introduction');
  const ability = useAbility();
  const toast = useToast();
  const queryClient = useQueryClient();
  const canCreateIntroduction = ability.can('create', 'Introduction');
  const [deleteIntroduction] = useMutation(api.introduction.deleteIntroduction, {
    onSettled: (data, error, variables) => {
      return Promise.all([
        queryClient
          .cancelQueries(api.introduction.listIntroductions.getQueryKey()[0])
          .then(() => queryClient.invalidateQueries(api.introduction.listIntroductions.getQueryKey()[0])),
        queryClient
          .cancelQueries(api.introduction.getIntroduction.getQueryKey({ id: variables[0].id })[0])
          .then(() =>
            queryClient.invalidateQueries(api.introduction.getIntroduction.getQueryKey({ id: variables[0].id })[0])
          ),
      ]);
    },
  });
  const [updateIntroduction] = useMutation(api.introduction.updateIntroduction, {
    onSettled: async (data, error, variables) => {
      const id = data ? data.data.id : variables[0].id;

      return Promise.all([
        queryClient
          .cancelQueries(api.introduction.listIntroductions.getQueryKey()[0])
          .then(() => queryClient.invalidateQueries(api.introduction.listIntroductions.getQueryKey()[0])),
        queryClient
          .cancelQueries(api.introduction.getIntroduction.getQueryKey({ id })[0])
          .then(() => queryClient.invalidateQueries(api.introduction.getIntroduction.getQueryKey({ id })[0])),
      ]);
    },
  });

  return (
    <>
      <MainHero />

      <DataTableHero
        title="Introductions"
        // actions={<SpecificationPackagesActions />}
        buttons={
          canCreateIntroduction ? (
            <>
              <Link
                to="/introductions/add"
                className="c-button c-button--primary c-button--small u-my-spacer-base-small"
              >
                <Icon name="add" className="o-svg-icon o-svg-right" />
                <span>New introduction</span>
              </Link>
            </>
          ) : undefined
        }
      />

      <section className="c-block c-block--spacing-b-extra-small c-block--spacing-b@md">
        <div className="o-container-fluid">
          <DataTableListing
            label="introductions"
            availableSortOptions={{
              created_at: 'Latest',
              updated_at: 'Recently Updated',
            }}
            defaultSort="created_at"
            defaultDirection="desc"
            queryFn={api.introduction.listIntroductions}
            queryFnParams={(filters, searchParams) => {
              const status: IntroductionStatus[] | undefined = searchParams.has('status')
                ? searchParams
                    .getAll('status')
                    .filter((val): val is IntroductionStatus =>
                      [IntroductionStatus.Active, IntroductionStatus.Inactive].includes(
                        val as unknown as IntroductionStatus
                      )
                    )
                : undefined;
              const visibility: IntroductionVisibility[] | undefined = searchParams.has('visibility')
                ? searchParams
                    .getAll('visibility')
                    .filter((val): val is IntroductionVisibility =>
                      [IntroductionVisibility.Public, IntroductionVisibility.Private].includes(
                        val as unknown as IntroductionVisibility
                      )
                    )
                : undefined;
              return {
                ...filters,
                status: Array.isArray(status) && status.length ? status : undefined,
                visibility: Array.isArray(visibility) && visibility.length ? visibility : undefined,
              };
            }}
            filters={(query) => <IntroductionFilters counts={query[0]?.data?.filters?.status} />}
          >
            {(data) => (
              <>
                {data.map((introduction) => (
                  <Link key={introduction.id} to={`/introductions/${introduction.id}`} className="c-data-card">
                    <div className="c-data-card__column c-data-card__column--first">
                      {introduction.status && (
                        <p
                          className={classNames('c-data-card__subtitle', 'c-data-card__subtitle--status', {
                            'c-data-card__subtitle--draft': introduction.status === IntroductionStatus.Active,
                            'c-data-card__subtitle--error': introduction.status === IntroductionStatus.Inactive,
                          })}
                        >
                          {introduction.status}
                        </p>
                      )}
                      <p className="c-data-card__title">{introduction.name}</p>
                      <p className="c-data-card__label">Introduction Name</p>
                    </div>

                    <div className="c-data-card__column">
                      <p>
                        {introduction.updated_at ? (
                          format(parseISO(introduction.updated_at), 'MMM d y')
                        ) : introduction.created_at ? (
                          format(parseISO(introduction.created_at), 'MMM d y')
                        ) : (
                          <>&mdash;</>
                        )}
                      </p>
                      <p className="c-data-card__label">Last update</p>
                    </div>

                    <div className="c-data-card__column c-data-card__column--last">
                      <button className="c-link-cta-basic c-link-cta--small" type="button">
                        <span>Introduction Details</span>
                        <Icon name="arrow" className="o-svg-icon o-svg-right" />
                      </button>
                    </div>

                    <div className="c-data-card-toolbar">
                      <Can I="delete" this={subject('Introduction', introduction)}>
                        {introduction.status !== IntroductionStatus.Inactive ? (
                          <button
                            onClick={async (event) => {
                              event.preventDefault();
                              if (
                                window.confirm(`Are you sure that you really want to disable "${introduction.name}".`)
                              ) {
                                await deleteIntroduction([{ id: introduction.id }]);
                                toast.notify({
                                  type: 'success',
                                  title: 'Success',
                                  message: `The "${introduction.name}" introduction was disabled successfully.`,
                                });
                              }
                            }}
                            type="button"
                            title="Disable Introduction"
                          >
                            <Icon name="trash" className="c-data-card-toolbar__icon" />
                          </button>
                        ) : (
                          <button
                            onClick={async (event) => {
                              event.preventDefault();
                              if (
                                window.confirm(
                                  `Are you sure that you really want to activate "${introduction.name}" introduction.`
                                )
                              ) {
                                await updateIntroduction([
                                  {
                                    id: introduction.id,
                                    updateIntroductionBody: {
                                      name: introduction.name,
                                      status: IntroductionStatus.Active,
                                    },
                                  },
                                ]);
                                toast.notify({
                                  type: 'success',
                                  title: 'Success',
                                  message: `The "${introduction.name}" introduction was activated successfully.`,
                                });
                              }
                            }}
                            type="button"
                            title="Activate Introduction"
                          >
                            <Icon name="export" className="c-data-card-toolbar__icon" />
                          </button>
                        )}
                      </Can>
                    </div>
                  </Link>
                ))}
              </>
            )}
          </DataTableListing>
        </div>
      </section>
    </>
  );
}
