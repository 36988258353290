import * as React from 'react';

import Icon from 'components/icon';

const DropPlaceholderModalContent: React.VFC<{ title: string }> = ({ title }) => {
  return (
    <div className="c-block c-block--spacing-t-extra-small c-block--spacing-b-small">
      <div className="o-container-fluid">
        <div className="o-row">
          <div className="o-col-12">
            <div className="c-block__header c-block__header--hor">
              <div className="c-block__header-content u-items-center u-block u-flex@sm">
                <div>
                  <h6 className="c-headline u-text-neutral-500">{title}</h6>
                  <h4 className="u-mb-spacer-base-large">Image Details</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="o-row">
          <div className="o-col-12">
            <div className="c-add-media c-add-media--active u-mb-spacer-base">
              <div>
                <Icon name="upload" className="o-svg-lg" />
              </div>

              <div>
                <button className="c-link-cta-basic c-link-cta--small" type="button">
                  <span>Drop file here...</span>
                </button>
              </div>
            </div>
            <p className="c-note">The accepted media file formats are JPG, JPEG, PNG, GIF, TIF, and TIFF.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropPlaceholderModalContent;
