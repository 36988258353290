import axios from 'axios';

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    // @ts-ignore
    common: {
      Authorization: undefined,
    },
  },
});

export default httpClient;
