import api from 'api';
import { SelectListingItemProps, SelectListingItem } from 'my-account/components/SelectListingItem';

export interface SelectLocationProps
  extends Omit<
    SelectListingItemProps<typeof api.location.listLocations>,
    'queryFn' | 'getOptionLabel' | 'getOptionValue' | 'queryParams'
  > {
  queryParams?: SelectListingItemProps<typeof api.location.listLocations>['queryParams'];
  canSelectAnyLocation?: boolean;
}

export const SelectLocation = ({ queryParams = {}, canSelectAnyLocation, ...props }: SelectLocationProps) => {
  return (
    <SelectListingItem
      {...props}
      queryFn={canSelectAnyLocation ? api.location.locationsFilter : api.location.listLocations}
      queryParams={{ sort: 'name', direction: 'asc', ...queryParams }}
      getOptionLabel={(location) =>
        location.name ? `${location.name} - ${location.city} ${location.state}` : `#${location.id}`
      }
      getOptionValue={(location) => String(location.id)}
    />
  );
};
