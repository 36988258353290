import axios, { CancelTokenSource } from 'axios';
import * as React from 'react';
import { useDropzone } from 'react-dropzone';

import { FileModelImage, FileModelImageTypeEnum, FileType } from '__generated-api__';
import api from 'api';
import { useMutation } from 'hooks/query';

export type UploadProgressValue = { loaded: number; total: number; startTime: Date; filename: string } | undefined;

export const useImageUpload = ({ onChange }: { onChange: (data: FileModelImage) => void }) => {
  const [uploadProgress, setUploadProgress] = React.useState<UploadProgressValue>(undefined);
  const cancelTokenRef = React.useRef<CancelTokenSource | undefined>(undefined);
  const [uploadImage, uploadImageState] = useMutation(api.file.upload, {
    axiosRequestConfig: {
      // @ts-ignore
      onUploadProgress: (progressEvent: ProgressEvent) =>
        setUploadProgress((val) => {
          return {
            loaded: progressEvent.loaded,
            total: progressEvent.total,
            startTime: val?.startTime ?? new Date(),
            filename: val?.filename ?? '',
          };
        }),
    },
  });
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    multiple: false,
    preventDropOnDocument: true,
    noClick: true,
    accept: 'image/*',
    onDropAccepted: ([image]) => {
      cancelTokenRef.current = axios.CancelToken.source();
      setUploadProgress({ loaded: 0, total: image.size, startTime: new Date(), filename: image.name });
      uploadImage([{ image, type: FileType.Image }, { cancelToken: cancelTokenRef.current.token }]).then((file) => {
        if (file.data.type !== FileModelImageTypeEnum.Image) {
          throw new Error('Invalid file type returned.');
        }

        onChange(file.data);
      });
    },
  });

  const cancelUpload = React.useCallback(() => {
    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel();
    }
  }, []);

  return { getRootProps, getInputProps, isDragActive, open, uploadImageState, uploadProgress, cancelUpload };
};
