import axios, { AxiosError, AxiosResponse } from 'axios';

export interface APIErrorResponse {
  message: string;
  errors: Record<string, string[]>;
}
export interface APIError<T> extends AxiosError<T> {
  response: AxiosResponse<T>;
}

export function isAPIError<R = any>(error: unknown): error is AxiosError<R> {
  if (axios.isAxiosError(error)) {
    return true;
  }

  return false;
}

export function isAPIErrorMessage<R extends { message: string }>(error: unknown): error is APIError<R> {
  if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.message) {
    return true;
  }

  return false;
}

export function isAPIErrorsObject<R extends APIErrorResponse>(error: unknown): error is APIError<R> {
  if (
    axios.isAxiosError(error) &&
    error.response &&
    error.response.data &&
    error.response.data.message &&
    error.response.data.errors
  ) {
    return true;
  }

  return false;
}

export function isClientErrorsObject(error: unknown): error is Record<string, string> {
  if (typeof error === 'object' && error !== null && Object.values(error).every((item) => typeof item === 'string')) {
    return true;
  }

  return false;
}
