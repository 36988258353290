import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import { Editor, EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import classNames from 'classnames';
import markdownit from 'markdown-it';
import * as React from 'react';
import { useController } from 'react-hook-form';
import TurndownService from 'turndown';
import { z } from 'zod';

import Form from 'components/Form/Form';
import InputField from 'components/Form/InputField';
import SubmitButton from 'components/Form/SubmitButton';
import Icon from 'components/icon';
import { Modal } from 'my-account/components/Modal';

type ToolbarButtons = 'heading' | 'bold' | 'italic' | 'link'; // | 'image';

const icons: Record<ToolbarButtons, React.ReactNode> = {
  // expand: (
  //   <svg className="o-icon o-svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
  //     <path
  //       fill="currentColor"
  //       d="M128 32H32C14.31 32 0 46.31 0 64v96c0 17.69 14.31 32 32 32s32-14.31 32-32V96h64c17.69 0 32-14.31 32-32S145.7 32 128 32zM416 32h-96c-17.69 0-32 14.31-32 32s14.31 32 32 32h64v64c0 17.69 14.31 32 32 32s32-14.31 32-32V64C448 46.31 433.7 32 416 32zM128 416H64v-64c0-17.69-14.31-32-32-32s-32 14.31-32 32v96c0 17.69 14.31 32 32 32h96c17.69 0 32-14.31 32-32S145.7 416 128 416zM416 320c-17.69 0-32 14.31-32 32v64h-64c-17.69 0-32 14.31-32 32s14.31 32 32 32h96c17.69 0 32-14.31 32-32v-96C448 334.3 433.7 320 416 320z"
  //     ></path>
  //   </svg>
  // ),
  heading: (
    <svg className="o-icon o-svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
      <path
        fill="currentColor"
        d="M448 448c0 17.69-14.33 32-32 32h-96c-17.67 0-32-14.31-32-32s14.33-32 32-32h16v-144h-224v144H128c17.67 0 32 14.31 32 32s-14.33 32-32 32H32c-17.67 0-32-14.31-32-32s14.33-32 32-32h16v-320H32c-17.67 0-32-14.31-32-32s14.33-32 32-32h96c17.67 0 32 14.31 32 32s-14.33 32-32 32H112v112h224v-112H320c-17.67 0-32-14.31-32-32s14.33-32 32-32h96c17.67 0 32 14.31 32 32s-14.33 32-32 32h-16v320H416C433.7 416 448 430.3 448 448z"
      ></path>
    </svg>
  ),
  bold: (
    <svg className="o-icon o-svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
      <path
        fill="currentColor"
        d="M321.1 242.4C340.1 220.1 352 191.6 352 160c0-70.59-57.42-128-128-128L32 32.01c-17.67 0-32 14.31-32 32s14.33 32 32 32h16v320H32c-17.67 0-32 14.31-32 32s14.33 32 32 32h224c70.58 0 128-57.41 128-128C384 305.3 358.6 264.8 321.1 242.4zM112 96.01H224c35.3 0 64 28.72 64 64s-28.7 64-64 64H112V96.01zM256 416H112v-128H256c35.3 0 64 28.71 64 63.1S291.3 416 256 416z"
      ></path>
    </svg>
  ),
  italic: (
    <svg className="o-icon o-svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
      <path
        fill="currentColor"
        d="M384 64.01c0 17.69-14.31 32-32 32h-58.67l-133.3 320H224c17.69 0 32 14.31 32 32s-14.31 32-32 32H32c-17.69 0-32-14.31-32-32s14.31-32 32-32h58.67l133.3-320H160c-17.69 0-32-14.31-32-32s14.31-32 32-32h192C369.7 32.01 384 46.33 384 64.01z"
      ></path>
    </svg>
  ),
  link: (
    <svg className="o-icon o-svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
      <path
        fill="currentColor"
        d="M598.6 41.41C570.1 13.8 534.8 0 498.6 0s-72.36 13.8-99.96 41.41l-43.36 43.36c15.11 8.012 29.47 17.58 41.91 30.02c3.146 3.146 5.898 6.518 8.742 9.838l37.96-37.96C458.5 72.05 477.1 64 498.6 64c20.67 0 40.1 8.047 54.71 22.66c14.61 14.61 22.66 34.04 22.66 54.71s-8.049 40.1-22.66 54.71l-133.3 133.3C405.5 343.1 386 352 365.4 352s-40.1-8.048-54.71-22.66C296 314.7 287.1 295.3 287.1 274.6s8.047-40.1 22.66-54.71L314.2 216.4C312.1 212.5 309.9 208.5 306.7 205.3C298.1 196.7 286.8 192 274.6 192c-11.93 0-23.1 4.664-31.61 12.97c-30.71 53.96-23.63 123.6 22.39 169.6C293 402.2 329.2 416 365.4 416c36.18 0 72.36-13.8 99.96-41.41L598.6 241.3c28.45-28.45 42.24-66.01 41.37-103.3C639.1 102.1 625.4 68.16 598.6 41.41zM234 387.4L196.1 425.3C181.5 439.1 162 448 141.4 448c-20.67 0-40.1-8.047-54.71-22.66c-14.61-14.61-22.66-34.04-22.66-54.71s8.049-40.1 22.66-54.71l133.3-133.3C234.5 168 253.1 160 274.6 160s40.1 8.048 54.71 22.66c14.62 14.61 22.66 34.04 22.66 54.71s-8.047 40.1-22.66 54.71L325.8 295.6c2.094 3.939 4.219 7.895 7.465 11.15C341.9 315.3 353.3 320 365.4 320c11.93 0 23.1-4.664 31.61-12.97c30.71-53.96 23.63-123.6-22.39-169.6C346.1 109.8 310.8 96 274.6 96C238.4 96 202.3 109.8 174.7 137.4L41.41 270.7c-27.6 27.6-41.41 63.78-41.41 99.96c-.0001 36.18 13.8 72.36 41.41 99.97C69.01 498.2 105.2 512 141.4 512c36.18 0 72.36-13.8 99.96-41.41l43.36-43.36c-15.11-8.012-29.47-17.58-41.91-30.02C239.6 394.1 236.9 390.7 234 387.4z"
      ></path>
    </svg>
  ),
  // image: (
  //   <svg className="o-icon o-svg-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  //     <path
  //       fill="currentColor"
  //       d="M5 8.5c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5c0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5zm9 .5l-2.519 4-2.481-1.96-4 5.96h14l-5-8zm8-4v14h-20v-14h20zm2-2h-24v18h24v-18z"
  //     />
  //   </svg>
  // ),
};

const LinkToolbarButton: React.VFC<{ editor: Editor }> = ({ editor }) => {
  const [isLinkModalOpen, setIsLinkModalOpen] = React.useState(false);

  return (
    <>
      <button
        onClick={() => setIsLinkModalOpen(true)}
        className={classNames('c-text-block-editor__toolbar-button', {
          'is-active': editor.isActive('link'),
        })}
        type="button"
      >
        {icons['link']}
      </button>
      <Modal
        isOpen={isLinkModalOpen}
        onRequestClose={() => {
          setIsLinkModalOpen(false);
        }}
      >
        <div className="c-modal" style={{ width: '100%' }}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */}
          <a
            href="#"
            className="c-modal-close"
            onClick={(event) => {
              event.preventDefault();
              setIsLinkModalOpen(false);
            }}
          />

          <div className="c-modal__main">
            <div className="c-block c-block--spacing-t-small c-block--spacing-b-small">
              <div className="o-container-fluid">
                <div className="o-row">
                  <div className="o-col-12">
                    <h4 className="u-mb-spacer-base-large">{editor.isActive('link') ? 'Update link' : 'Add link'}</h4>
                    {isLinkModalOpen && (
                      <Form
                        schema={z.object({ url: z.string().nonempty().url() })}
                        initialValues={{ url: editor.getAttributes('link').href }}
                        onSubmit={async (values) => {
                          editor.chain().focus().extendMarkRange('link').setLink({ href: values.url }).run();
                          setIsLinkModalOpen(false);
                        }}
                        hasFloatingLabels
                        stopSubmitPropagation
                      >
                        <InputField name="url" label="URL" elStyle="fill" />
                        <SubmitButton>{editor.isActive('link') ? 'Update' : 'Add'}</SubmitButton>
                        {editor.isActive('link') && (
                          <div className="ais-ClearRefinements">
                            <button
                              type="button"
                              className="ais-ClearRefinements-button"
                              onClick={(event) => {
                                event.preventDefault();
                                editor.chain().focus().extendMarkRange('link').unsetLink().run();
                                setIsLinkModalOpen(false);
                              }}
                            >
                              <Icon name="close" className="o-svg-icon" />
                              <span>Clear</span>
                            </button>
                          </div>
                        )}
                      </Form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

// const ImageToolbarButton: React.VFC<{ editor: Editor }> = ({ editor }) => {
//   const [isImageModalOpen, setIsImageModalOpen] = React.useState(false);

//   return (
//     <>
//       <button
//         onClick={() => setIsImageModalOpen(true)}
//         className={classNames('c-text-block-editor__toolbar-button', {
//           'is-active': editor.isActive('link'),
//         })}
//         type="button"
//       >
//         {icons['image']}
//       </button>
//       <Modal
//         isOpen={isImageModalOpen}
//         onRequestClose={() => {
//           setIsImageModalOpen(false);
//         }}
//         style={{ content: { maxWidth: '82.25rem' } }}
//       >
//         <div className="c-modal" style={{ width: '100%' }}>
//           {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */}
//           <a
//             href="#"
//             className="c-modal-close"
//             onClick={(event) => {
//               event.preventDefault();
//               setIsImageModalOpen(false);
//             }}
//           />

//           <div className="c-modal__main">
//             <div className="c-block c-block--spacing-t-small c-block--spacing-b-small">
//               {isImageModalOpen && (
//                 <MediaLibraryModalContent
//                   title={'Add Image'}
//                   onInsert={(image) => {
//                     console.log(image);
//                     editor.commands.setImage({ src: image.image });
//                     setIsImageModalOpen(false);
//                   }}
//                 />
//               )}
//             </div>
//           </div>
//         </div>
//       </Modal>
//     </>
//   );
// };

const toolbarButtons: Record<ToolbarButtons, (editor: Editor) => React.ReactNode> = {
  heading: (editor) => (
    <button
      onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
      className={classNames('c-text-block-editor__toolbar-button', {
        'is-active': editor.isActive('heading', { level: 2 }),
      })}
      type="button"
    >
      {icons['heading']}
    </button>
  ),
  bold: (editor) => (
    <button
      onClick={() => editor.chain().focus().toggleBold().run()}
      className={classNames('c-text-block-editor__toolbar-button', {
        'is-active': editor.isActive('bold'),
      })}
      type="button"
    >
      {icons['bold']}
    </button>
  ),
  italic: (editor) => (
    <button
      onClick={() => editor.chain().focus().toggleItalic().run()}
      className={classNames('c-text-block-editor__toolbar-button', {
        'is-active': editor.isActive('italic'),
      })}
      type="button"
    >
      {icons['italic']}
    </button>
  ),
  link: (editor) => <LinkToolbarButton editor={editor} />,
  // image: (editor) => <ImageToolbarButton editor={editor} />,
};

const Toolbar: React.VFC<{ editor: Editor | null; toolbar?: ToolbarButtons[] }> = ({
  editor,
  toolbar = Object.keys(icons) as unknown as ToolbarButtons[],
}) => {
  if (!editor || !toolbar.length) {
    return null;
  }

  return (
    <div className="c-text-block-editor__toolbar">
      {toolbar.map((toolbarKey) => (
        <React.Fragment key={toolbarKey}>{toolbarButtons[toolbarKey](editor)}</React.Fragment>
      ))}
    </div>
  );
};

const markdown2HtmlRenderer = markdownit({});
const markdownSerializer = new TurndownService();

export const useEditorFieldValue = (value: string | undefined) => {
  const htmlContent = markdown2HtmlRenderer.render(value ?? '');
  const textContent = React.useMemo(() => {
    const tempDivElement = document.createElement('div');
    tempDivElement.innerHTML = htmlContent;
    return tempDivElement.textContent || tempDivElement.innerText || '';
  }, [htmlContent]);

  return {
    htmlContent,
    textContent,
  };
};

interface EditorFieldProps {
  name: string;
  label?: string;
  placeholder?: string;
  helpText?: React.ReactNode;
  elStyle?: 'fill';
  small?: boolean;
  className?: string;
  defaultValue?: string;
  toolbar?: ToolbarButtons[];
}

const EditorField: React.VFC<EditorFieldProps> = ({
  name,
  label,
  placeholder,
  helpText,
  elStyle,
  small,
  className,
  defaultValue,
  toolbar,
}) => {
  const { field, fieldState } = useController<{ field?: string }, 'field'>({
    name: name as unknown as 'field',
    defaultValue,
  });

  const editorUpdateTimeoutIdRef = React.useRef<number | null>(null);
  const editor = useEditor({
    extensions: [
      StarterKit,
      Link.configure({
        openOnClick: false,
      }),
      // Image.configure({
      //   inline: true,
      // }),
      Placeholder.configure({ placeholder: placeholder || '' }),
    ],
    content: markdown2HtmlRenderer.render(field.value ?? ''),
    onUpdate: (data) => {
      if (editorUpdateTimeoutIdRef.current) {
        clearTimeout(editorUpdateTimeoutIdRef.current);
      }

      setTimeout(() => {
        field.onChange(markdownSerializer.turndown(data.editor.getHTML()));
      }, 200);
    },
  });

  return (
    <div
      className={classNames(
        'c-form-element',
        {
          [`c-form-element--style-${elStyle}`]: elStyle,
          'c-form-element--small': small,
          'c-form-element--error': typeof fieldState.error !== 'undefined',
        },
        className
      )}
    >
      {label && (
        <label htmlFor={name} className={classNames('c-form-label')}>
          {label}
        </label>
      )}
      <div className="c-text-block-editor">
        <Toolbar editor={editor} toolbar={toolbar} />
        <EditorContent editor={editor} className="c-text-block-editor__content" />
      </div>
      {typeof fieldState.error !== 'undefined' && (
        <ul className="c-form-element--error__list">
          <li>{fieldState.error.message}</li>
        </ul>
      )}
      {typeof helpText !== 'undefined' && <p className="c-note">{helpText}</p>}
    </div>
  );
};

export default EditorField;
