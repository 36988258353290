import * as React from 'react';
import { FieldError } from 'react-hook-form';

const DisplayFieldError: React.VFC<{ error: FieldError | undefined }> = ({ error }) => {
  if (typeof error === 'undefined' || typeof error.message === 'undefined') {
    return null;
  }

  return (
    <ul className="c-form-element--error__list">
      <li>{error.message}</li>
    </ul>
  );
};

const isFieldError = (value: any): value is FieldError =>
  typeof value === 'object' &&
  value !== null &&
  'message' in value &&
  typeof value.message === 'string' &&
  'type' in value &&
  typeof value.type === 'string' &&
  'ref' in value;

const getFieldErrors = (object: any, prefix?: string): [string, FieldError][] => {
  return Object.entries(object).reduce((result, [key, value]) => {
    if (isFieldError(value)) {
      return [...result, [(prefix ?? '') + key, value]];
    }

    if (typeof value === 'object' && value !== null) {
      return [...result, ...getFieldErrors(value, (prefix ?? '') + key + '_')];
    }

    return result;
  }, [] as [string, FieldError][]);
};

const DisplayFieldErrors = ({
  error,
  hideKeyLabels,
}: {
  error: FieldError | undefined | Record<string, FieldError | any>;
  hideKeyLabels?: boolean;
}) => {
  if (typeof error === 'undefined') {
    return null;
  }

  if (isFieldError(error)) {
    return <DisplayFieldError error={error} />;
  }

  let fieldErrors = getFieldErrors(error);

  if (fieldErrors.length === 0) {
    return null;
  }

  return (
    <ul className="c-form-element--error__list u-mb-0">
      {fieldErrors.map(([name, item], index) => (
        <li key={`${index}_${name}`} className="u-capitalize-first-letter">
          {!hideKeyLabels && <strong>{name.split('_').join(' ')}:</strong>}
          {!hideKeyLabels ? ' ' : ''}
          {item.message}
        </li>
      ))}
    </ul>
  );
};

export default DisplayFieldErrors;
