import { z } from 'zod';
import { UserRole } from '__generated-api__';
import { CompanyRelationUpdateSchema } from './company';
import { AnyFileImageRelationUpdateSchema } from './file';

export const UserRelationUpdateSchema = z.lazy(() =>
  z.object({
    id: z.number(),
    first_name: z.string().optional().nullable(),
    last_name: z.string().optional().nullable(),
    email: z.string().optional().nullable(),
    role: z.nativeEnum(UserRole).optional().nullable(),
    image: AnyFileImageRelationUpdateSchema.optional().nullable(),
    phone: z.string().optional().nullable(),
    company: CompanyRelationUpdateSchema.optional().nullable(),
  })
);
