import * as React from 'react';
import sortedUniq from 'lodash/sortedUniq';
import range from 'lodash/range';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { z } from 'zod';
import { useClickAway } from 'react-use';

import { setLocationSearchParam } from 'utils/router';
import Icon from 'components/icon';
import { FormProvider, useForm } from 'components/Form/Form';
import { InputField } from 'components/Form/InputField';
import { Button } from 'components/Button';

export interface PaginateOptions {
  current_page: number;
  last_page: number;
  range_size?: number;
  navigate?: string | ((page: number) => void);
}

export const paginate = ({ current_page, last_page, range_size = 2 }: PaginateOptions) => {
  const midPages = range(Math.max(1, current_page - range_size), Math.min(last_page, current_page + range_size + 1));

  return sortedUniq([1, ...midPages, last_page]);
};

const PaginationPageLink: React.FC<
  {
    page: number;
    className?: string;
    'aria-label'?: string;
  } & Pick<PaginateOptions, 'navigate'>
> = ({ page, children, className, navigate = 'list-page', ...linkProps }) => {
  if (typeof navigate === 'string') {
    return (
      <Link
        {...linkProps}
        to={(location) => {
          const searchParams = new URLSearchParams(location.search);
          searchParams.set('list-page', String(page));

          return { ...location, search: searchParams.toString() };
        }}
        className={classNames('ais-Pagination-link', className)}
      >
        {children}
      </Link>
    );
  }

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href="#"
      {...linkProps}
      onClick={(event) => {
        event.preventDefault();
        navigate(page);
      }}
      className={classNames('ais-Pagination-link', className)}
    >
      {children}
    </a>
  );
};

const PageSwitcher: React.VFC<Pick<PaginateOptions, 'navigate'>> = ({ navigate = 'list-page' }) => {
  const history = useHistory();
  const location = useLocation();
  const [isPageSwitcherOpen, setIsPageSwitcherOpen] = React.useState(false);
  const elRef = React.useRef<HTMLLIElement>(null);
  useClickAway(elRef, () => {
    setIsPageSwitcherOpen(false);
  });
  const { context, formProps } = useForm({
    schema: z.object({ page: z.number() }),
    hasFloatingLabels: true,
    stopSubmitPropagation: true,
    onSubmit: async (data) => {
      if (typeof navigate === 'string') {
        history.push(setLocationSearchParam(location, 'list-page', String(data.page)));
      } else {
        navigate(data.page);
      }
    },
    initialValues: {},
  });

  return (
    <li
      className={classNames({ 'c-pagination__item--active': isPageSwitcherOpen }, 'c-pagination__item--break')}
      ref={elRef}
    >
      <button
        type="button"
        onClick={(event) => {
          event.preventDefault();
          setIsPageSwitcherOpen((val) => !val);
        }}
      >
        ...
      </button>

      <FormProvider {...context}>
        <div className={classNames(formProps.className, 'c-pagination__search', { 'is-visible': isPageSwitcherOpen })}>
          <div>
            <InputField
              name="page"
              type="number"
              elStyle="fill"
              className="u-mb-0"
              small
              placeholder="Go to page ..."
              inputProps={{
                onKeyDown: (event) => {
                  if (event.key === 'Enter') {
                    formProps.onSubmit();
                  }
                },
              }}
            />
          </div>

          <div>
            <Button
              isIcon
              isSmall
              onClick={(event) => {
                event.preventDefault();
                formProps.onSubmit();
              }}
              isLoading={context.formState.isSubmitting}
            >
              <Icon name="arrow" className="o-svg-icon o-svg-right" />
            </Button>
          </div>
        </div>
      </FormProvider>
    </li>
  );
};

export const Pagination: React.VFC<PaginateOptions> = (props) => {
  let { current_page, last_page, range_size = 2 } = props;

  if (last_page < 2) {
    return null;
  }

  last_page = Math.max(1, last_page);
  current_page = Math.min(Math.max(1, current_page), last_page);
  range_size = Math.max(0, range_size);

  const pages = paginate({
    current_page,
    last_page,
    range_size,
  });

  return (
    <ul className="c-pagination">
      <li className="c-pagination__item--prev">
        {current_page < 2 ? (
          <span aria-label="Previous">
            <Icon name="chevron" className="o-svg-icon o-svg-left" />
            Previous
          </span>
        ) : (
          <PaginationPageLink page={current_page - 1} aria-label="Previous" navigate={props.navigate}>
            <Icon name="chevron" className="o-svg-icon o-svg-left" />
            Previous
          </PaginationPageLink>
        )}
      </li>
      {pages.flatMap((page, index) => {
        return [
          page > 1 && page !== pages[index - 1] + 1 ? <PageSwitcher key={`${page}_break`} /> : null,
          <li className={classNames({ 'c-pagination__item--active': current_page === page })} key={page}>
            <PaginationPageLink page={page} navigate={props.navigate}>
              {' '}
              {Number(page).toLocaleString('en')}{' '}
            </PaginationPageLink>
          </li>,
        ];
      })}
      <li className="c-pagination__item--next">
        {current_page >= last_page ? (
          <span aria-label="Next">
            Next
            <Icon name="chevron" className="o-svg-icon o-svg-right" />
          </span>
        ) : (
          <PaginationPageLink page={current_page + 1} aria-label="Next" navigate={props.navigate}>
            Next
            <Icon name="chevron" className="o-svg-icon o-svg-right" />
          </PaginationPageLink>
        )}
      </li>
    </ul>
  );
};
