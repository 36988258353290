import { ForbiddenError } from '@casl/ability';
import * as React from 'react';
import { FieldError } from 'react-hook-form';
import { isAPIError, isAPIErrorMessage, isAPIErrorsObject } from 'utils/errors';

const DisplayFieldError: React.VFC<{ error: FieldError | undefined; style?: React.CSSProperties }> = ({
  error,
  style,
}) => {
  if (typeof error === 'undefined' || typeof error.message === 'undefined') {
    return null;
  }

  return (
    <div className="c-form-element c-form-element--error" style={style}>
      <ul className="c-form-element--error__list">
        <li>{error.message}</li>
      </ul>
    </div>
  );
};

const isFieldError = (value: any): value is FieldError =>
  typeof value === 'object' &&
  value !== null &&
  'message' in value &&
  typeof value.message === 'string' &&
  'type' in value &&
  typeof value.type === 'string' &&
  'ref' in value;

const getFieldErrors = (object: any, prefix?: string): [string, FieldError][] => {
  return Object.entries(object).reduce((result, [key, value]) => {
    if (isFieldError(value)) {
      return [...result, [(prefix ?? '') + key, value]];
    }

    if (typeof value === 'object' && value !== null) {
      return [...result, ...getFieldErrors(value, (prefix ?? '') + key + '_')];
    }

    return result;
  }, [] as [string, FieldError][]);
};

export const DisplayFieldErrors = ({
  errors,
  hideKeyLabels,
  style,
}: {
  errors: FieldError | undefined | Record<string, FieldError | any>;
  hideKeyLabels?: boolean;
  style?: React.CSSProperties;
}) => {
  if (typeof errors === 'undefined') {
    return null;
  }

  if (isFieldError(errors)) {
    return <DisplayFieldError error={errors} />;
  }

  let fieldErrors = getFieldErrors(errors);

  if (fieldErrors.length === 0) {
    return null;
  }

  return (
    <div
      className="c-form-element c-form-element--error"
      style={{ marginTop: '0.25rem', marginBottom: '0.75rem', ...style }}
    >
      <ul className="c-form-element--error__list u-mb-0">
        {fieldErrors.map(([name, error], index) => (
          <li key={`${index}_${name}`} className="u-capitalize-first-letter">
            {!hideKeyLabels && <strong>{name.split('_').join(' ')}:</strong>}
            {!hideKeyLabels ? ' ' : ''}
            {error.message}
          </li>
        ))}
      </ul>
    </div>
  );
};

export const DisplayReactQueryError: React.VFC<{ error: unknown }> = ({ error }) => {
  if (typeof error === 'undefined') {
    return null;
  }

  if (error instanceof ForbiddenError) {
    return (
      <div className="c-form-element c-form-element--error">
        <ul className="c-form-element--error__list">
          <li>
            You don't have right permissions to {error.action} {error.subjectType}.
          </li>
        </ul>
      </div>
    );
  }

  if (isAPIErrorsObject(error)) {
    return (
      <div className="c-form-element c-form-element--error">
        <ul className="c-form-element--error__list">
          <li key="rootErrorMessage">{error.response.data.message}</li>
          {Object.entries(error.response.data.errors).flatMap(([key, value]) =>
            value.map((item, index) => <li key={`${key}_${index}`}>{item}</li>)
          )}
        </ul>
      </div>
    );
  }

  if (isAPIErrorMessage(error)) {
    return (
      <div className="c-form-element c-form-element--error">
        <ul className="c-form-element--error__list">
          <li>There was a problem saving your data. If this persists, please contact support.</li>
          <li>{error.response.data.message}</li>
        </ul>
      </div>
    );
  }

  if (isAPIError(error)) {
    let mainMessage = 'Establishing server connection failed. Please try refreshing the page or check again later.';
    if (error.response) {
      if (error.response.status === 403) {
        mainMessage = 'You do not have sufficient permissions to access this data.';
      }

      if (error.response.status === 404) {
        mainMessage = 'Requested data does not exist.';
      }

      if (error.response.status >= 400 && error.response.status <= 499) {
        mainMessage = 'There was a problem saving your data. If this persists, please contact support.';
      }

      if (error.response.status >= 500 && error.response.status <= 599) {
        mainMessage =
          'The server currently is not able to process your request. If this persists, please contact support.';
      }
    }

    return (
      <div className="c-form-element c-form-element--error">
        <ul className="c-form-element--error__list">
          <li>{mainMessage}</li>
          <li>{error.message}</li>
        </ul>
      </div>
    );
  }

  return (
    <div className="c-form-element c-form-element--error">
      <ul className="c-form-element--error__list">
        <li>Unexpected error has occurred. Please try refreshing the page or check again later.</li>
      </ul>
    </div>
  );
};

export default DisplayFieldError;
