import classNames from 'classnames';
import React from 'react';
import { RefinementListProvided } from 'react-instantsearch-core';
import { connectRefinementList, RefinementList } from 'react-instantsearch-dom';

export interface CustomParentRefinementProps extends RefinementListProvided {
  subrefinements?: { attribute: string; key: string }[];
}
class CustomParentRefinementListComponent extends React.Component<CustomParentRefinementProps> {
  render() {
    const { items, refine, currentRefinement, subrefinements } = this.props;
    return (
      <>
        <div className="ais-RefinementList">
          <ul className="ais-RefinementList-list">
            {items.map((item, index) => {
              return (
                <li className="ais-RefinementList-item" key={'item_' + index}>
                  <label className="ais-RefinementList-label">
                    <input
                      className="ais-RefinementList-checkbox"
                      type="checkbox"
                      value={item.label}
                      checked={item.isRefined}
                      onChange={(event) => {
                        const next = currentRefinement.includes(item.label)
                          ? currentRefinement.filter((current) => current !== item.label)
                          : currentRefinement.concat(item.label);
                        refine(next);
                      }}
                    />
                    <span className="ais-RefinementList-labelText">{item.label}</span>
                    <span className="ais-RefinementList-count">{item.count}</span>
                  </label>
                  {subrefinements &&
                    subrefinements.length > 0 &&
                    subrefinements.map((subrefinement, index) => {
                      return (
                        item.label === subrefinement.key && (
                          <div
                            key={subrefinement.attribute + index}
                            className={classNames([{ 'u-hidden': !item.isRefined }])}
                            style={{ marginLeft: 8, marginRight: -8 }}
                          >
                            <RefinementList
                              attribute={subrefinement.attribute}
                              limit={5}
                              showMore={true}
                              showMoreLimit={100}
                            />
                          </div>
                        )
                      );
                    })}
                </li>
              );
            })}
          </ul>
        </div>
      </>
    );
  }
}
export const CustomRefinementListComponent = connectRefinementList(CustomParentRefinementListComponent);
