import * as React from 'react';
import { z } from 'zod';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import pick from 'lodash/pick';
import values from 'lodash/values';

import { CurrencyCode } from '__generated-api__';
import Icon from 'components/icon';
import { FormProvider, useForm } from 'components/Form/Form';
import InputField from 'components/Form/InputField';
import CheckboxField from 'components/Form/CheckboxField';
import SelectField from 'components/Form/SelectField';
import SubmitButton from 'components/Form/SubmitButton';
import { Modal } from 'my-account/components/Modal';
import { ProjectVersionSchema } from 'my-account/validations/project';
import { DisplayFieldErrors } from '../DisplayFieldError';
import useDocumentSettings from 'my-account/hooks/useDocumentSettings';

const documentSettingsSchema = ProjectVersionSchema.pick({
  show_address: true,
  show_rep_info: true,
  currency_type: true,
  show_default_number_units: true,
  default_number_units: true,
  show_list_price: true,
  show_discount_price_label: true,
  discount_price_label: true,
  default_multiplier: true,
  show_extended_price: true,
  pricing_footer_note: true,
});

type DocumentSettingsType = z.infer<typeof documentSettingsSchema>;

interface ProjectVersionType {
  latest: DocumentSettingsType;
}

const DocumentSettingsField: React.VFC = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const context = useFormContext<ProjectVersionType>();
  const settings = useDocumentSettings();

  const form = useForm({
    initialValues: settings,
    schema: documentSettingsSchema,
    onSubmit: async (values) => {
      context.setValue('latest.show_address', values.show_address);
      context.setValue('latest.show_rep_info', values.show_rep_info);
      context.setValue('latest.currency_type', values.currency_type);
      context.setValue('latest.show_default_number_units', values.show_default_number_units);
      context.setValue('latest.default_number_units', values.default_number_units);
      context.setValue('latest.show_list_price', values.show_list_price);
      context.setValue('latest.show_discount_price_label', values.show_discount_price_label);
      context.setValue('latest.discount_price_label', values.discount_price_label);
      context.setValue('latest.default_multiplier', values.default_multiplier);
      context.setValue('latest.show_extended_price', values.show_extended_price);
      context.setValue('latest.pricing_footer_note', values.pricing_footer_note);

      setIsModalOpen(false);
      form.context.reset(values);
    },
    stopSubmitPropagation: true,
    hasFloatingLabels: true,
  });

  const onClose = () => {
    if (form.context.formState.isDirty) {
      if (window.confirm('Do you really want to leave? You have unsaved changes!')) {
        setIsModalOpen(false);
        form.context.reset(settings);
      }
    } else {
      setIsModalOpen(false);
      form.context.reset(settings);
    }
  };

  const errors = pick(
    context.formState.errors,
    'latest.show_address',
    'latest.show_rep_info',
    'latest.currency_type',
    'latest.show_default_number_units',
    'latest.default_number_units',
    'latest.show_list_price',
    'latest.show_discount_price_label',
    'latest.discount_price_label',
    'latest.default_multiplier',
    'latest.show_extended_price',
    'latest.pricing_footer_note'
  ).latest;

  return (
    <>
      <button
        className={classNames('c-button c-button--white c-button--option c-button--full u-mb-spacer', {
          'is-invalid': typeof values(errors).find((item) => typeof item !== 'undefined') !== 'undefined',
        })}
        onClick={(event) => {
          event.preventDefault();
          setIsModalOpen(true);
        }}
        type="button"
      >
        <Icon name="controls-alt" className="o-svg-icon o-svg-large" />
        <span>Document Settings</span>
      </button>
      <DisplayFieldErrors errors={errors} hideKeyLabels />
      <Modal isOpen={isModalOpen} onRequestClose={onClose} style={{ content: { maxWidth: '41.25rem' } }}>
        <div className="c-modal" style={{ width: '100%' }}>
          {/*eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */}
          <a
            href="#"
            className="c-modal-close"
            onClick={(event) => {
              event.preventDefault();
              onClose();
            }}
          />

          <div className="c-modal__main">
            <div className="c-block c-block--spacing-t-small c-block--spacing-b-small">
              <div className="o-container-fluid">
                <div className="o-row">
                  <div className="o-col-12">
                    <div className="c-block__header c-block__header--hor">
                      <div className="c-block__header-content u-items-center u-block u-flex@sm">
                        <div>
                          <h4 className="u-mb-spacer-base-large">Document Settings</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="o-row">
                  <div className="o-col-12">
                    <FormProvider {...form.context}>
                      <form {...form.formProps}>
                        <CheckboxField name="show_address" label="Show Opportunity Address" checkboxStyle="toggle" />
                        <CheckboxField
                          name="show_rep_info"
                          label="Display My Contact Information"
                          checkboxStyle="toggle"
                        />
                        <SelectField name="currency_type" label="Currency" elStyle="fill">
                          <option value={CurrencyCode.Us}>US</option>
                          <option value={CurrencyCode.Ca}>CAN</option>
                        </SelectField>
                        <CheckboxField
                          name="show_default_number_units"
                          label="Default Number of Units"
                          checkboxStyle="toggle"
                          className="u-mb-0"
                        />
                        <InputField type="number" name="default_number_units" elStyle="fill" />
                        <CheckboxField name="show_list_price" label="Show List Price" checkboxStyle="toggle" />
                        <CheckboxField
                          name="show_discount_price_label"
                          label="Discount Price Label"
                          checkboxStyle="toggle"
                          className="u-mb-0"
                        />
                        <SelectField name="discount_price_label" elStyle="fill">
                          <option value="Distributor Net Price">Distributor Net Price</option>
                          <option value="Budget Price">Budget Price</option>
                        </SelectField>
                        <InputField
                          type="number"
                          name="default_multiplier"
                          label="Default Multiplier"
                          elStyle="fill"
                          inputProps={{
                            step: 0.01,
                          }}
                        />
                        <CheckboxField name="show_extended_price" label="Show Extended Price" checkboxStyle="toggle" />
                        <InputField name="pricing_footer_note" label="Pricing Footer Note" elStyle="fill" />
                        <SubmitButton>Save</SubmitButton>
                      </form>
                    </FormProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DocumentSettingsField;
