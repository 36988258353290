import * as React from 'react';
import classNames from 'classnames';
import { Link, LinkProps, NavLink, NavLinkProps } from 'react-router-dom';
import { useClickAway } from 'react-use';

import { LinkAnchorProps, useLinkAnchorProps } from 'utils/router';
import Icon, { IconProps } from 'components/icon';
import { runIfFn } from 'my-account/utils/functions';

interface DropdownProps<Options extends Record<string, React.ReactNode>> {
  label?: string;
  description: string;
  options: Options;
  value: keyof Options;
  onChange: (value: keyof Options) => void;
  className?: string;
  hideEmptyKey?: boolean;
  dropdownPosition?: 'left' | 'right';
}

export function Dropdown<
  Options extends Record<string, React.ReactNode | ((props: { isToggle: boolean }) => React.ReactNode)>
>({
  label,
  description,
  options,
  value,
  onChange,
  className,
  hideEmptyKey,
  dropdownPosition = 'right',
}: DropdownProps<Options>) {
  const [isOpen, setIsOpen] = React.useState(false);
  const elRef = React.useRef<HTMLDivElement>(null);
  const toggle = React.useCallback<React.MouseEventHandler<HTMLAnchorElement | HTMLSpanElement>>((event) => {
    event.preventDefault();
    setIsOpen((val) => !val);
  }, []);
  useClickAway(elRef, () => {
    setIsOpen(false);
  });

  return (
    <div className={classNames('c-dropdown-wrapper', { 'has-dropdown-open': isOpen }, className)} ref={elRef}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href="#" className="c-tag c-tag--light c-tag--large c-dropdown-toggle" onClick={toggle}>
        {typeof label !== 'undefined' && <span className="c-dropdown-desc">{label}</span>}
        <span className="c-dropdown-value">{runIfFn(options[value], { isToggle: true })}</span>
      </a>
      <div className={classNames('c-dropdown', `c-dropdown--${dropdownPosition}`)}>
        <div className="c-dropdown__header c-dropdown__header--sm">
          <span className="c-dropdown__title">{description}</span>
          <span className="c-dropdown__toggle" onClick={toggle}>
            <Icon name="close-thin" className="o-svg-icon" />
          </span>
        </div>
        <div className="c-dropdown__body">
          <ul className="c-dropdown__list">
            {Object.keys(options)
              .filter((optionKey) => {
                if (!optionKey && hideEmptyKey) {
                  return false;
                }

                return true;
              })
              .map((optionKey) => (
                <li key={optionKey} className={value === optionKey ? 'is-selected' : undefined}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      setIsOpen(false);
                      onChange(optionKey);
                    }}
                  >
                    {runIfFn(options[optionKey], { isToggle: false })}
                  </a>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export interface DropdownLinksProps<
  Options extends Record<
    string,
    LinkProps & { children: React.ReactNode | ((props: { isToggle?: boolean }) => React.ReactNode) }
  > = Record<string, LinkProps & { children: React.ReactNode | ((props: { isToggle?: boolean }) => React.ReactNode) }>
> {
  label: string;
  description: string;
  options: Options;
  value: keyof Options;
}

export function DropdownLinks<
  Options extends Record<
    string,
    LinkProps & { children: React.ReactNode | ((props: { isToggle?: boolean }) => React.ReactNode) }
  >
>({ label, description, options, value }: DropdownLinksProps<Options>) {
  const [isOpen, setIsOpen] = React.useState(false);
  const elRef = React.useRef<HTMLDivElement>(null);
  const toggle = React.useCallback<React.MouseEventHandler<HTMLAnchorElement | HTMLSpanElement>>((event) => {
    event.preventDefault();
    setIsOpen((val) => !val);
  }, []);
  useClickAway(elRef, () => {
    setIsOpen(false);
  });

  return (
    <div className={classNames('c-dropdown-wrapper', { 'has-dropdown-open': isOpen })} ref={elRef}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href="#" className="c-tag c-tag--light c-tag--large c-dropdown-toggle" onClick={toggle}>
        <span className="c-dropdown-desc">{label}</span>
        <span className="c-dropdown-value">{runIfFn(options[value].children, { isToggle: true })}</span>
      </a>
      <div className="c-dropdown c-dropdown--right">
        <div className="c-dropdown__header c-dropdown__header--sm">
          <span className="c-dropdown__title">{description}</span>
          <span className="c-dropdown__toggle" onClick={toggle}>
            <Icon name="close-thin" className="o-svg-icon" />
          </span>
        </div>
        <div className="c-dropdown__body">
          <ul className="c-dropdown__list">
            {Object.keys(options).map((optionKey) => (
              <li key={optionKey} className={value === optionKey ? 'is-selected' : undefined}>
                <Link
                  {...options[optionKey]}
                  onClick={(event) => {
                    setIsOpen(false);
                    options[optionKey].onClick?.(event);
                  }}
                >
                  {runIfFn(options[optionKey].children, { isToggle: false })}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

interface MenuDropdownOption {
  href: LinkProps['to'];
  icon: IconProps['name'];
  label: React.ReactNode;
  isActive?: NavLinkProps['isActive'];
}

export interface MenuDropdownProps {
  label: string;
  options: MenuDropdownOption[];
  isOut?: boolean;
  className?: string;
}

const MenuLinkComponent = React.forwardRef<HTMLAnchorElement, LinkAnchorProps>((props, forwardedRef) => {
  const [anchorProps, { isActive }] = useLinkAnchorProps(props, forwardedRef);

  return (
    <li className={classNames('c-menu-dropdown-nav__item', { 'is-current': isActive })}>
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
      <a {...anchorProps} />
    </li>
  );
});

export function MenuDropdown({ label, options, isOut, className }: MenuDropdownProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const elRef = React.useRef<HTMLDivElement>(null);
  const toggle = React.useCallback<React.MouseEventHandler<HTMLAnchorElement | HTMLSpanElement>>((event) => {
    event.preventDefault();
    setIsOpen((val) => !val);
  }, []);
  useClickAway(elRef, () => {
    setIsOpen(false);
  });

  return (
    <div
      className={classNames(
        'c-menu-dropdown',
        { 'has-dropdown-open': isOpen, 'c-menu-dropdown--out': isOut },
        className
      )}
      ref={elRef}
    >
      <button className="c-menu-dropdown__toggle" type="button" onClick={toggle}>
        <div className="c-menu-dropdown__icon">
          <Icon name="hamburger" />
        </div>

        {!isOut && <p className="c-menu-dropdown__label">{label}</p>}
      </button>

      <div className="c-menu-dropdown__dropdown">
        <button className="c-menu-dropdown__toggle" type="button" onClick={toggle}>
          <div className="c-menu-dropdown__icon">
            <Icon name="close-thin" />
          </div>

          <p className="c-menu-dropdown__label">{label}</p>
        </button>

        <nav className="c-menu-dropdown-nav">
          <ul className="c-menu-dropdown-nav__list">
            {options.map((option, index) => (
              <NavLink
                key={index}
                className="c-menu-dropdown-nav__link"
                activeClassName="is-current"
                component={MenuLinkComponent}
                to={option.href}
                exact
                onClick={() => {
                  setIsOpen(false);
                }}
                isActive={option['isActive']}
              >
                <Icon name={option.icon} />
                {option.label}
              </NavLink>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
}

interface IconDropdownProps<Options extends Record<string, React.ReactNode>> {
  buttonLabel?: string;
  menuLabel: string;
  value?: keyof Options;
  options: Options;
  onChange: (value: keyof Options) => void;
  hasBodyToggleIcon?: boolean;
}

export function IconDropdown<Options extends Record<string, React.ReactNode>>({
  menuLabel,
  options,
  ...props
}: IconDropdownProps<Options>) {
  const [isOpen, setIsOpen] = React.useState(false);
  const elRef = React.useRef<HTMLDivElement>(null);
  const toggle = React.useCallback<React.MouseEventHandler<HTMLAnchorElement | HTMLSpanElement>>((event) => {
    event.preventDefault();
    setIsOpen((val) => !val);
  }, []);
  useClickAway(elRef, () => {
    setIsOpen(false);
  });

  return (
    <div className={classNames('c-dropdown-wrapper', { 'has-dropdown-open': isOpen })} ref={elRef}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href="#" className="c-tag c-tag--light c-tag--large c-dropdown-toggle" onClick={toggle}>
        {typeof props.buttonLabel !== 'undefined' && props.buttonLabel.length > 0 && `${props.buttonLabel} `}
        <span className="c-dropdown-icon">
          <Icon name="actions" />
        </span>
      </a>
      <div className="c-dropdown c-dropdown--right">
        <div className="c-dropdown__header c-dropdown__header--sm">
          <span className="c-dropdown__title">{menuLabel}</span>
          {props.hasBodyToggleIcon !== false && (
            <span className="c-dropdown__toggle" onClick={toggle}>
              <Icon name="close-thin" className="o-svg-icon" />
            </span>
          )}
        </div>
        <div className="c-dropdown__body">
          <ul className="c-dropdown__list">
            {Object.keys(options).map((optionKey) => (
              <li key={optionKey} className={props.value === optionKey ? 'is-selected' : undefined}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    setIsOpen(false);
                    props.onChange(optionKey);
                  }}
                >
                  {options[optionKey]}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

interface MultiIconDropdownProps<Options extends [string, Record<string, React.ReactNode>][]> {
  buttonLabel?: string;
  options: Options;
  hasBodyToggleIcon?: boolean;
  value?: keyof Options[number][1];
  onChange: (value: keyof Options[number][1]) => void;
}

export function MultiIconDropdown<Options extends [string, Record<string, React.ReactNode>][]>(
  props: MultiIconDropdownProps<Options>
) {
  const [isOpen, setIsOpen] = React.useState(false);
  const elRef = React.useRef<HTMLDivElement>(null);
  const toggle = React.useCallback<React.MouseEventHandler<HTMLAnchorElement | HTMLSpanElement>>((event) => {
    event.preventDefault();
    setIsOpen((val) => !val);
  }, []);
  useClickAway(elRef, () => {
    setIsOpen(false);
  });

  return (
    <div className={classNames('c-dropdown-wrapper', { 'has-dropdown-open': isOpen })} ref={elRef}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href="#" className="c-tag c-tag--light c-tag--large c-dropdown-toggle" onClick={toggle}>
        {typeof props.buttonLabel !== 'undefined' && props.buttonLabel.length > 0 && `${props.buttonLabel} `}
        <span className="c-dropdown-icon">
          <Icon name="actions" />
        </span>
      </a>
      <div className="c-dropdown c-dropdown--right">
        {props.options.map(([menuLabel, options], index) => (
          <React.Fragment key={index}>
            <div className="c-dropdown__header c-dropdown__header--sm">
              <span className="c-dropdown__title">{menuLabel}</span>
              {props.hasBodyToggleIcon !== false && (
                <span className="c-dropdown__toggle" onClick={toggle}>
                  <Icon name="close-thin" className="o-svg-icon" />
                </span>
              )}
            </div>
            <div className="c-dropdown__body">
              <ul className="c-dropdown__list">
                {Object.keys(options).map((optionKey) => (
                  <li key={optionKey} className={props.value === optionKey ? 'is-selected' : undefined}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      href="#"
                      onClick={(event) => {
                        event.preventDefault();
                        setIsOpen(false);
                        props.onChange(optionKey);
                      }}
                    >
                      {options[optionKey]}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export interface LinksListDropdownProps {
  label: string;
  description: string;
  openInNewTab?: boolean;
  options: { label: string; URL: string }[];
}

export function LinksListDropdown({ label, description, openInNewTab, options }: LinksListDropdownProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const elRef = React.useRef<HTMLDivElement>(null);
  const toggle = React.useCallback<React.MouseEventHandler<HTMLAnchorElement | HTMLSpanElement>>((event) => {
    event.preventDefault();
    setIsOpen((val) => !val);
  }, []);
  useClickAway(elRef, () => {
    setIsOpen(false);
  });

  return (
    <div className={classNames('c-dropdown-wrapper', 'js-dropdown', { 'has-dropdown-open': isOpen })} ref={elRef}>
      <a href="/#" className="c-dropdown-link js-dropdown-toggle" onClick={toggle}>
        <span className="c-dropdown-label">{label}</span>
        <span className="c-dropdown-icon">
          <Icon name="chevron" className={classNames([{ 'o-svg-up': isOpen, 'o-svg-down': !isOpen }])} />
        </span>
      </a>
      <div className="c-dropdown c-dropdown--left">
        <div className="c-dropdown__header c-dropdown__header--sm">
          <span className="c-dropdown__title">{description}</span>
          <span className="c-dropdown__toggle js-dropdown-toggle" onClick={toggle}>
            <Icon name="close-thin" />
          </span>
        </div>
        <div className="c-dropdown__body">
          <ul className="c-dropdown__list">
            {options.map((option, index) => (
              <li key={'item' + index}>
                <a href={option.URL} target={openInNewTab ? '_blank' : '_self'} rel="noreferrer">
                  {option.label}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
