import * as React from 'react';
import { connectCurrentRefinements } from 'react-instantsearch-dom';
import SpriteIcon from 'search/components/sprite-icon';

interface CustomClearRefinementsProps {
  items: any[];
  refine: (value: any) => void;
}

class CustomClearRefinements extends React.Component<CustomClearRefinementsProps> {
  render() {
    // console.log('CustomClearRefinements::render::props', this.props);

    const { items, refine } = this.props;

    // is_obsolete:false is permanently turned on and we don't want to count it as an active refinement
    const disabled = !items.reduce((refinementCount, item) => {
      if (item.attribute === 'is_obsolete' && Array.isArray(item.currentRefinement)) {
        return (
          refinementCount +
          item.currentRefinement.reduce((refinementCount: number, item: any) => {
            return item === 'false' ? refinementCount : refinementCount + 1;
          }, 0)
        );
      }
      return refinementCount + 1;
    }, 0);

    const classes = ['ais-ClearRefinements-button', disabled ? 'ais-ClearRefinements-button--disabled' : ''];

    return (
      <>
        <div className="ais-ClearRefinements">
          <button onClick={() => refine(items)} type="reset" className={classes.join(' ')} disabled={disabled}>
            <SpriteIcon name="close" classes={['o-svg-icon']} />
            <span>Clear all</span>
          </button>
        </div>
      </>
    );
  }
}

const ClearRefinements = connectCurrentRefinements(CustomClearRefinements);

export default ClearRefinements;
