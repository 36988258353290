import * as React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import classNames from 'classnames';
import { z } from 'zod';
import { subject } from '@casl/ability';

import { Location, ReportType, SalesRepUserRoleEnum, UserRole } from '__generated-api__';
import api from 'api';
import { useAbility } from 'auth';
import { Can } from 'auth/components/Can';
import wordpressData from 'data';
import { setLocationSearchParams } from 'utils/router';
import Icon from 'components/icon';
import Form from 'components/Form/Form';
import { AutoSubmit } from 'components/Form/AutoSubmit';
import SelectField from 'components/Form/SelectField';
import { MainHero } from 'my-account/components/MainHero';
import { DataTableHero } from 'my-account/components/DataTable/Hero';
import { DataTableClearFilterForm, DataTableListing } from 'my-account/components/DataTable/Listing';
import { AddressText } from 'my-account/components/AddressText';
import { caProvinces, usStates } from 'my-account/utils/states';
import { SelectCompany } from 'my-account/components/SelectCompany';
import { SelectUser } from 'my-account/components/SelectUser';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { getObjectKeys } from 'my-account/utils/object';

enum LocationsListingLayout {
  List = 'list',
  Map = 'map',
}

const LocationMarker: React.VFC<{
  location: Location;
  showInfo?: boolean;
  onInfoClose?: () => void;
  onMarkerClick?: (e: google.maps.MapMouseEvent) => void;
}> = ({ location, showInfo, onInfoClose, onMarkerClick }) => {
  const [isMounted, setIsMounted] = React.useState(false);

  React.useEffect(() => {
    setIsMounted(true);
  }, []);

  if (location.latitude === null || location.longitude === null) {
    return null;
  }

  return (
    <>
      <Marker
        position={{ lat: location.latitude, lng: location.longitude }}
        onClick={onMarkerClick}
        clickable={typeof onMarkerClick !== 'undefined'}
      >
        {showInfo && isMounted && (
          <InfoWindow onCloseClick={onInfoClose}>
            <div style={{ background: 'white', padding: '.5rem' }}>
              <h4 className="c-card__title">{location.name}</h4>
              <p className="c-card__desc">
                <AddressText address={location} />
              </p>
              <div className="u-flex u-justify-end">
                <Link to={`/locations/${location.id}`}>
                  <button className="c-link-cta-basic c-link-cta--small" type="button">
                    <span>Location Details</span>
                    <Icon name="arrow" className="o-svg-icon o-svg-right" />
                  </button>
                </Link>
              </div>
            </div>
          </InfoWindow>
        )}
      </Marker>
    </>
  );
};

const LocationsMap: React.VFC<{ data: Location[] }> = ({ data }) => {
  const [selectedLocationMarker, selectLocationMarker] = React.useState<number | undefined>(undefined);
  const [isMapLoaded, setIsMapLoaded] = React.useState(false);
  const mapRef = React.useRef<google.maps.Map>();

  const bounds = React.useMemo(() => {
    const bounds = new window.google.maps.LatLngBounds();

    data.forEach((location) => {
      if (location.latitude === null || location.longitude === null) {
        return;
      }

      bounds.extend({ lat: location.latitude, lng: location.longitude });
    });

    return bounds;
  }, [data]);

  React.useEffect(() => {
    if (!mapRef.current || !isMapLoaded) {
      return;
    }

    mapRef.current.fitBounds(bounds);
  }, [mapRef, bounds, isMapLoaded]);

  return (
    <GoogleMap
      mapContainerStyle={{ width: '100%', height: '800px' }}
      zoom={6}
      onLoad={(map) => {
        mapRef.current = map;
        setIsMapLoaded(true);
      }}
      center={bounds.getCenter().toJSON()}
    >
      {data.map((location) => (
        <LocationMarker
          key={location.id}
          location={location}
          showInfo={selectedLocationMarker === location.id}
          onInfoClose={() => {
            selectLocationMarker(undefined);
          }}
          onMarkerClick={() => {
            selectLocationMarker(location.id);
          }}
        />
      ))}
    </GoogleMap>
  );
};

const locationsFiltersSchema = z.object({
  company: z
    .object({
      id: z.number().positive(),
      name: z.string().nullable(),
    })
    .nullable()
    .optional(),
  state: z.nativeEnum(usStates).or(z.nativeEnum(caProvinces)).or(z.literal('')),
  rep: z
    .object({
      id: z.number().positive(),
      first_name: z.string(),
      last_name: z.string(),
      email: z.string(),
    })
    .nullable()
    .optional(),
});

const LocationsFilters: React.VFC = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = React.useMemo(() => new URLSearchParams(location.search), [location.search]);
  const ability = useAbility();
  const canListRepresentatives = ability.can('list', subject('User', { role: SalesRepUserRoleEnum.SalesRep }));

  const initialValueParse = locationsFiltersSchema.safeParse(
    Object.assign(
      {
        state: searchParams.get('state') ?? '',
      },
      searchParams.has('company')
        ? { company: { id: Number(searchParams.get('company')!), name: 'Loading...' } }
        : undefined,
      searchParams.has('rep') && canListRepresentatives
        ? { rep: { id: Number(searchParams.get('rep')!), first_name: 'Loading...', last_name: '', email: '' } }
        : undefined
    )
  );

  return (
    <>
      <Form
        schema={locationsFiltersSchema}
        initialValues={initialValueParse.success ? initialValueParse.data : {}}
        onSubmit={async (values) => {
          history.replace(
            setLocationSearchParams(location, {
              company: values.company ? String(values.company.id) : undefined,
              state: values.state ? values.state : undefined,
              rep: values.rep ? String(values.rep.id) : undefined,
              page: undefined,
              'list-page': undefined,
            })
          );
        }}
        isFiltersForm
      >
        <DataTableClearFilterForm />
        <AutoSubmit />
        <Can I="list" this={subject('Location', { company_id: 0 })}>
          <SelectCompany
            elStyle="fill"
            name="company"
            label="Company"
            selectProps={{ autoComplete: 'off', isClearable: true }}
            small
          />
        </Can>
        <SelectField
          name="state"
          label="State/Province"
          elStyle="fill"
          allowEmpty
          emptyOptionLabel="Any State/Province"
          small
        >
          <optgroup label="United States">
            {getObjectKeys(usStates).map((state) => (
              <option key={usStates[state]} value={usStates[state]}>
                {state}
              </option>
            ))}
          </optgroup>
          <optgroup label="Canada">
            {getObjectKeys(caProvinces).map((state) => (
              <option key={caProvinces[state]} value={caProvinces[state]}>
                {state}
              </option>
            ))}
          </optgroup>
        </SelectField>
        {canListRepresentatives && (
          <SelectUser
            name="rep"
            label="Representative"
            elStyle="fill"
            small
            queryParams={{ role: UserRole.SalesRep }}
            selectProps={{ isClearable: true }}
          />
        )}
      </Form>
    </>
  );
};

export default function LocationsPage() {
  useDocumentTitle('Locations');
  const ability = useAbility();
  const [layout, setLayout] = React.useState(LocationsListingLayout.List);
  const canListRepresentatives = ability.can('list', subject('User', { role: SalesRepUserRoleEnum.SalesRep }));

  return (
    <>
      <MainHero />

      <DataTableHero title="Locations" />

      <section className="c-block c-block--spacing-b-extra-small c-block--spacing-b@md">
        <div className="o-container-fluid">
          <DataTableListing
            label="locations"
            availableSortOptions={{
              created_at: 'Latest',
              name: 'Alphabetical',
            }}
            defaultSort="name"
            defaultDirection="asc"
            headerControls={
              <div className="c-listing__layout has-selected-grid u-hidden u-flex@md">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  className={classNames({ 'is-selected': layout === LocationsListingLayout.List })}
                  onClick={(event) => {
                    event.preventDefault();

                    setLayout(LocationsListingLayout.List);
                  }}
                >
                  <Icon name="list" className="o-svg-icon" />
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  className={classNames({ 'is-selected': layout === LocationsListingLayout.Map })}
                  onClick={(event) => {
                    event.preventDefault();

                    setLayout(LocationsListingLayout.Map);
                  }}
                >
                  <Icon name="map" className="o-svg-icon" />
                </a>
              </div>
            }
            queryFn={api.location.listLocations}
            queryFnParams={(filters, searchParams) => {
              let companyId: number | undefined = Number(searchParams.get('company'));
              if (Number.isNaN(companyId) || companyId < 1) {
                companyId = undefined;
              }

              let state: string | undefined = searchParams.get('state') ?? '';
              if (!state.trim()) {
                state = undefined;
              }

              let salesRepId: number | undefined =
                searchParams.has('rep') && canListRepresentatives ? Number(searchParams.get('rep')!) : undefined;
              if (!salesRepId || Number.isNaN(salesRepId) || salesRepId < 1) {
                salesRepId = undefined;
              }

              return { ...filters, companyId, state, salesRepId };
            }}
            filters={() => <LocationsFilters />}
            reportType={ReportType.Location}
          >
            {(data) =>
              layout === LocationsListingLayout.List ? (
                <>
                  {data.map((location) => (
                    <Link key={location.id} to={`/locations/${location.id}`} className="c-data-card">
                      <div className="c-data-card__column c-data-card__column--first">
                        {location.company && <p className="c-data-card__subtitle">{location.company.name}</p>}
                        <p className="c-data-card__title">{location.name}</p>
                        <p className="c-data-card__label">Location Name</p>
                      </div>

                      <div className="c-data-card__column c-data-card__column--large">
                        <p>{location.state}</p>
                        <p className="c-data-card__label">State</p>
                      </div>

                      <div className="c-data-card__column c-data-card__column--last">
                        <button className="c-link-cta-basic c-link-cta--small" type="button">
                          <span>Location Details</span>
                          <Icon name="arrow" className="o-svg-icon o-svg-right" />
                        </button>
                      </div>
                    </Link>
                  ))}
                </>
              ) : (
                <LoadScript googleMapsApiKey={wordpressData.googleMapsApiKey}>
                  <LocationsMap data={data} />
                </LoadScript>
              )
            }
          </DataTableListing>
        </div>
      </section>
    </>
  );
}
