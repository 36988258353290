import * as React from 'react';

import { useAuth } from 'auth';
import Icon from 'components/icon';
import { NavigationDropdown } from 'my-account/components/NavigationDropdown';
import { useSupportModal } from 'my-account/components/SupportModal';

export const DashboardHeader: React.VFC = () => {
  const auth = useAuth();
  const supportModal = useSupportModal();

  return (
    <section className="c-block c-block--higher c-block--bg-light2 c-block--hero c-block--height-xs@xs">
      <div className="o-container-fluid">
        <div className="o-row u-flex-grow u-items-end">
          <div className="o-col-6@md u-justify-around">
            <div className="u-flex u-items-center">
              <NavigationDropdown className="u-mt-spacer-base u-mb-spacer-base" isOut />

              <h1 className="c-title--extra-large u-mt-spacer-base">My Account</h1>
            </div>
          </div>
          <div className="o-col-6@md u-text-right@sm">
            <ul className="c-divider-list">
              <li className="c-divider-list__item">
                <a
                  href="#modal-contact"
                  className="c-link-cta-basic c-link-cta--small u-mb-0"
                  onClick={(event) => {
                    event.preventDefault();
                    supportModal.open();
                  }}
                >
                  <Icon name="chat" className="o-svg-icon o-svg-larger" />
                  <span>Contact support</span>
                </a>
              </li>
              <li className="c-divider-list__item">
                <a
                  href="#logout"
                  className="c-link-cta-basic c-link-cta--small u-mb-0"
                  onClick={(event) => {
                    event.preventDefault();
                    auth.logout();
                  }}
                >
                  <span>Log out</span>
                  <Icon name="out" className="o-svg-icon" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="c-block__pattern c-block__pattern--light3 c-block__overlay--opacity-40">
        <Icon name="pattern" className="o-svg-icon" />
      </div>
    </section>
  );
};
