import * as React from 'react';
import { z } from 'zod';

import api from 'api';
import { FileModel, FileType } from '__generated-api__';
import { useMutation } from 'hooks/query';
import Icon from 'components/icon';
import Form from 'components/Form/Form';
import InputField from 'components/Form/InputField';
import SelectField from 'components/Form/SelectField';
import SubmitButton from 'components/Form/SubmitButton';
import { useToast } from 'my-account/toast';
import { Modal } from 'my-account/components/Modal';

interface SupportModalContextValue {
  open: () => void;
  close: () => void;
}

const supportModalContext = React.createContext<SupportModalContextValue>({
  open: () => {},
  close: () => {},
});

const supportTypesEnum = z.enum([
  'Sales',
  'Troubleshooting',
  'Order Inquiries',
  'Pricing',
  'Product Information',
  'Other',
]);

const supportFormSchema = z.object({
  type: supportTypesEnum,
  message: z.string().nonempty(),
  file: z.instanceof(window.FileList).nullable().optional(),
});

export const SupportModalProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toast = useToast();
  const [supportRequest, supportRequestMutation] = useMutation(api.support.supportRequest);

  const open = React.useCallback(() => {
    setIsOpen(true);
  }, []);

  const close = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <supportModalContext.Provider value={{ open, close }}>
      {children}

      <Modal
        isOpen={isOpen}
        onRequestClose={() => {
          close();
        }}
        id="support-modal"
      >
        <div className="c-modal c-modal--contact">
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */}
          <a
            href="#"
            className="c-modal-close c-modal-close--invert"
            onClick={(event) => {
              event.preventDefault();
              close();
            }}
          />
          <div className="c-modal__main">
            <div className="c-block c-block--spacing-t-small c-block--spacing-b-small c-color--white">
              <div className="o-container-fluid">
                <div className="c-block__pattern-circle c-block__pattern--left c-block__pattern--light3 c-block__overlay--opacity-20">
                  <Icon name="pattern-circle" className="o-svg-icon" />
                </div>
                <div className="c-block__header">
                  <h5 className="c-title--large">
                    <strong>Symmons</strong> Support
                  </h5>
                  <p>
                    Select a category from the drop-down list, then let us know how we can help with your issue or
                    question.
                  </p>
                </div>

                <Form
                  schema={supportFormSchema}
                  initialValues={{}}
                  onSubmit={async (data, ctx) => {
                    let files: FileModel[] = [];

                    if (data.file) {
                      for (let i = 0; i < data.file.length; i += 1) {
                        const file = data.file[i];
                        const uploadRes = await api.file.upload({ image: file, type: FileType.File });
                        files.push(uploadRes.data);
                      }
                    }

                    await supportRequest([
                      {
                        supportBody: {
                          type: data.type,
                          body: data.message,
                          files: files.map((item) => ({ id: item.id })),
                        },
                      },
                    ]);

                    toast.notify({
                      type: 'success',
                      title: 'Success',
                      message: "Thanks for your submission! We'll review your request and be in touch soon.",
                    });

                    supportRequestMutation.reset();
                    ctx.reset();
                    close();
                  }}
                  hasFloatingLabels
                >
                  <div className="o-row o-row--small-gutters">
                    <div className="o-col-12">
                      <h6>How can we help?</h6>
                    </div>
                    <div className="o-col-12">
                      <SelectField name="type" label="What can we help you with today?" elStyle="fill">
                        {supportTypesEnum.options.map((item) => (
                          <option value={item} key={item}>
                            {item}
                          </option>
                        ))}
                      </SelectField>
                    </div>
                    <div className="o-col-12">
                      <InputField name="message" label="Message" type="textarea" elStyle="fill" />
                    </div>
                    <div className="o-col-12">
                      <InputField name="file" type="file" elStyle="fill" inputProps={{ multiple: true }} />
                    </div>
                    <div className="o-col-12@sm">
                      <div className="c-form-footer">
                        <SubmitButton variant="secondary">Get in touch</SubmitButton>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </supportModalContext.Provider>
  );
};

export const useSupportModal = () => {
  return React.useContext(supportModalContext);
};
