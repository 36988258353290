import * as React from 'react';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';

const EstimatedTimeLeft: React.VFC<{ startTime: Date; progress: number }> = ({ startTime, progress }) => {
  const [, forceUpdate] = React.useReducer((x) => x + 1, 0);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      forceUpdate();
    }, 500);

    return () => {
      clearInterval(intervalId);
    };
  }, [startTime, progress]);

  const timeElapsed = (new Date().getTime() - startTime.getTime()) / 1000;
  const timeLeft = timeElapsed / Math.max(0.05, progress) - timeElapsed;

  return <>{formatDistanceStrict(0, timeLeft * 1000, { unit: 'second', roundingMethod: 'round' })} remaining</>;
};

export default EstimatedTimeLeft;
