import { Link, useParams } from 'react-router-dom';
import { subject } from '@casl/ability';

import api from 'api';
import { useAbility } from 'auth';
import { useQuery } from 'hooks/query';
import Icon from 'components/icon';
import { MainHero } from 'my-account/components/MainHero';
import { EditResourceForm } from 'my-account/forms/Resource';
import { Block403Error } from 'my-account/pages/403page';
import { ErrorMessagesContent } from 'my-account/utils/error-handler';
import useDocumentTitle from 'hooks/useDocumentTitle';

export default function ResourceEditPage() {
  const { id } = useParams<{ id?: string }>();
  const ability = useAbility();
  const [resourceRes, getResourceState] = useQuery(api.resource.getResource, { id: Number(id) });
  useDocumentTitle(resourceRes?.data.name ? `Edit ${resourceRes?.data.name} Resource` : 'Edit Resource');

  const canViewResourcesList = ability.can('list', 'Resource');

  return (
    <>
      <MainHero />

      <section className="c-block c-block--hero">
        <div className="o-container-fluid">
          <div className="o-row u-flex-grow u-items-end">
            <div className="o-col-12">
              {canViewResourcesList && (
                <Link
                  to="/resources"
                  className="c-link-cta-basic c-link-cta--small c-block__get-back u-mt-spacer-base-large u-mb-spacer-base-small"
                >
                  <Icon name="arrow" className="o-svg-icon o-svg-left" />
                  <span>Back to resources</span>
                </Link>
              )}
              <h1 className="c-title--medium u-mb-0">Edit Resource</h1>
            </div>
          </div>
        </div>
        <div className="c-block__pattern c-block__pattern--light3 c-block__pattern--top3 c-block__overlay--opacity-20">
          <Icon name="pattern" className="o-svg-icon" />
        </div>
      </section>

      {getResourceState.isLoading && (
        <div className="c-listing__none">
          <div className="c-listing__none-spinner"></div>
          <p className="c-listing__none-title">Loading...</p>
        </div>
      )}
      {getResourceState.isError && (
        <div className="o-container-fluid u-pb-spacer-section">
          <div className="o-row u-flex-grow u-items-center">
            <div className="o-col-12">
              <div className="c-listing__none">
                <div className="c-listing__none-figure">
                  <Icon name="error" className="o-svg-icon" />
                </div>
                <p className="c-listing__none-title">Error</p>
                <ErrorMessagesContent error={getResourceState.error} />
              </div>
            </div>
          </div>
        </div>
      )}
      {typeof resourceRes !== 'undefined' && getResourceState.isSuccess && (
        <>
          {ability.can('read', subject('Resource', resourceRes.data)) ? (
            <EditResourceForm resource={resourceRes.data} />
          ) : (
            <Block403Error />
          )}
        </>
      )}
    </>
  );
}
