import {
  AdminUserRoleEnum,
  SalesRepUserRoleEnum,
  CompanyManagerUserRoleEnum,
  LocationManagerUserRoleEnum,
  RegularUserRoleEnum,
  AgencyRepUserRoleEnum,
} from '__generated-api__';

export const UserRoleLabels = {
  [AdminUserRoleEnum.Admin]: 'Administrator',
  [SalesRepUserRoleEnum.SalesRep]: 'Sales Representative',
  [AgencyRepUserRoleEnum.AgencyRep]: 'Agency Representative',
  [CompanyManagerUserRoleEnum.CompanyManager]: 'Company Manager',
  [LocationManagerUserRoleEnum.LocationManager]: 'Location Manager',
  [RegularUserRoleEnum.User]: 'User',
} as const;
