import * as React from 'react';

import { bufferToDataUrl } from 'my-account/utils/data-url';
import { useCroppedImage, UseCroppedImageProps } from 'spb-pdf/utils/image';

const CroppedImageBackground: React.VFC<UseCroppedImageProps> = ({ image, settings }) => {
  const imageState = useCroppedImage({ image, settings });
  if (imageState.status === 'loading') {
    return (
      <div
        className="c-hero__figure"
        style={{
          backgroundImage: `url(data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==)`,
          width: settings?.width ?? image.width ?? 0,
          height: settings?.height ?? image.height ?? 0,
        }}
      ></div>
    );
  }

  if (imageState.status === 'error') {
    return (
      <div
        className="c-hero__figure"
        style={{
          backgroundImage: `url(data:null)`,
          width: settings?.width ?? image.width ?? 0,
          height: settings?.height ?? image.height ?? 0,
        }}
      ></div>
    );
  }

  return (
    <div
      className="c-hero__figure"
      style={{
        backgroundImage: `url(${
          typeof imageState.src === 'string' ? imageState.src : bufferToDataUrl(imageState.src)
        })`,
        //width: imageState.width,
        //height: imageState.height,
      }}
    ></div>
  );
};

export default CroppedImageBackground;
