import * as React from 'react';

import { Project, ProjectVersion } from '__generated-api__';
import Icon from 'components/icon';
import { Button } from 'components/Button';
import { useDownloadDocumentButton, useDownloadPublicDocumentButton } from 'my-account/hooks/project';

const DownloadDocumentButton: React.VFC<{
  project: Project;
  projectVersion: ProjectVersion;
  isPublicView?: boolean;
}> = ({ project, projectVersion, isPublicView }) => {
  const [publicDownload, { isPublicDownloading }] = useDownloadPublicDocumentButton();
  const [download, { isDownloading }] = useDownloadDocumentButton();

  return (
    <Button
      variant="dark"
      isFull
      className="c-button--option u-mb-spacer"
      type="button"
      disabled={isPublicView ? isPublicDownloading : isDownloading}
      isLoading={isPublicView ? isPublicDownloading : isDownloading}
      onClick={(event) => {
        event.preventDefault();
        if (isPublicView) {
          publicDownload({ public_token: project.public_token, name: project.name });
        } else {
          download({ id: project.id, name: project.name, versionId: projectVersion.id });
        }
      }}
      leftIcon={<Icon name="download" className="o-svg-icon o-svg-large" />}
    >
      Download Document
    </Button>
  );
};

export default DownloadDocumentButton;
