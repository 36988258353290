import * as React from 'react';
import PhoneFormatter from 'awesome-phonenumber';

interface PhoneNumberLinkProps {
  className?: string;
  countryCode?: string;
  children: string;
}

export const PhoneNumberLink: React.VFC<PhoneNumberLinkProps> = ({ children, countryCode = 'US', className }) => {
  const phone = new PhoneFormatter(children, countryCode);
  return (
    <a href={phone.getNumber('rfc3966')} className={className}>
      {phone.getNumber('national')}
    </a>
  );
};
