import * as React from 'react';
import { z } from 'zod';
import { DefaultValues } from 'react-hook-form';
import reactPdf from '@react-pdf/renderer';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { SetRequired } from 'type-fest';

import {
  CoverPageType,
  NotesPageType,
  IntroductionPageType,
  DividerPageType,
  ImageBlockType,
  Project,
  ProductPageType,
  SummaryPageType,
  TocPageType,
  SummaryBlockType,
  IntroductionBlockType,
  ProductBlockType,
  TextBlockType,
  CurrencyCode,
} from '__generated-api__';
import spbPdf from 'spb-pdf';
import { registerFonts } from 'spb-pdf/utils/fonts';
import { ProductProps } from 'spb-pdf/utils/product';
import {
  projectToFormValue,
  filterEmptyClient,
  ProjectDefaultValuesType,
  ProductDocumentType,
  EmptyBlock,
  ProductBlockDefaultValues,
  ProductBlockFieldType,
  ImageBlockFieldType,
  ImageBlockDefaultValues,
  IntroductionBlockDefaultValues,
  IntroductionBlockFieldType,
  SummaryBlockDefaultValues,
  SummaryBlockFieldType,
  TitleBlockDefaultValues,
  ProjectVersionPageDefaultValue,
  TextBlockDefaultValues,
} from 'my-account/validations/project';
import { getUserName } from 'my-account/utils/user';
import { customFinishIcon, finishIcons } from 'my-account/utils/finishes';
import { getTransformedSalsifyImage } from 'my-account/utils/salsify';
import { runIfFn } from 'my-account/utils/functions';
import { NotesProps } from './pages/Notes';

registerFonts();

export const filterEmptyImageBlock = (
  block?: ImageBlockDefaultValues | DefaultValues<z.infer<typeof EmptyBlock>>
): block is Omit<ImageBlockFieldType, 'content'> & { content: Exclude<ImageBlockFieldType['content'], undefined> } => {
  return (
    typeof block !== 'undefined' &&
    block.type === ImageBlockType.Image &&
    typeof (block as ImageBlockFieldType).content !== 'undefined'
  );
};

export const filterEmptyImageOrTextBlock = (
  block?: ImageBlockDefaultValues | TextBlockDefaultValues | DefaultValues<z.infer<typeof EmptyBlock>>
): block is Omit<ImageBlockFieldType, 'content'> & { content: Exclude<ImageBlockFieldType['content'], undefined> } => {
  return (
    (typeof block !== 'undefined' &&
      block.type === ImageBlockType.Image &&
      typeof (block as ImageBlockFieldType).content !== 'undefined') ||
    (typeof block !== 'undefined' &&
      block.type === TextBlockType.Text &&
      typeof (block as ImageBlockFieldType).content !== 'undefined')
  );
};

export const filterEmptyIntroductionBlock = (
  block?: IntroductionBlockDefaultValues | DefaultValues<z.infer<typeof EmptyBlock>>
): block is SetRequired<IntroductionBlockFieldType, 'content'> => {
  return (
    typeof block !== 'undefined' &&
    block.type === IntroductionBlockType.Introduction &&
    typeof (block as IntroductionBlockFieldType).content !== 'undefined'
  );
};

export const filterEmptyProductBlock = (
  block?: ProductBlockDefaultValues | DefaultValues<z.infer<typeof EmptyBlock>>
): block is ProductBlockFieldType => {
  return (
    typeof block !== 'undefined' &&
    block.type === ProductBlockType.Product &&
    typeof (block as ProductBlockFieldType).content !== 'undefined'
  );
};

export const filterEmptySummaryBlock = (
  block?: SummaryBlockDefaultValues | DefaultValues<z.infer<typeof EmptyBlock>>
): block is SummaryBlockFieldType => {
  return typeof block !== 'undefined' && block.type === SummaryBlockType.Summary;
};

const productBlockToPdfProps =
  (currency_type: CurrencyCode | undefined) =>
  (block: ProductBlockFieldType): ProductProps => {
    const installation_guide = block.settings.documents.find(
      (document) => document.type === ProductDocumentType.InstallationGuide
    );
    const customer_service = block.settings.documents.find(
      (document) => document.type === ProductDocumentType.CustomerService
    );
    const warranty = block.settings.documents.find((document) => document.type === ProductDocumentType.Warranty);
    const product_sheet = block.settings.documents.find(
      (document) => document.type === ProductDocumentType.ProductSheet
    );
    const spec_sheet = block.settings.documents.find((document) => document.type === ProductDocumentType.SpecSheet);
    const parts_diagram = block.settings.documents.find(
      (document) => document.type === ProductDocumentType.PartsDiagram
    );

    return {
      title: block.settings.title || block.content?.title || '',
      subtitle: `Model ${block.settings.sku || block.content?.sku || ''}`,
      show_features: block.settings.show_features,
      features:
        typeof block.settings.features !== 'undefined'
          ? block.settings.features.map((feature) => feature.content).filter((item) => item.trim().length)
          : block.content?.features ?? [],
      image:
        typeof block.settings.main_image !== 'undefined'
          ? block.settings.main_image ?? undefined
          : block.content?.main_image
          ? {
              name: block.content.main_image.salsify_name,
              // image: block.content.main_image.salsify_url.replace(/^http:/, 'https:'),
              image: getTransformedSalsifyImage(
                {
                  salsify_url: block.content.main_image.salsify_url.replace(/^http:/, 'https:'),
                  salsify_format: block.content.main_image.salsify_format,
                },
                ['w_512', 'h_512', 'c_fit']
              ),
              width:
                block.content.main_image.salsify_asset_width! >= block.content.main_image.salsify_asset_height!
                  ? 512
                  : (512 * block.content.main_image.salsify_asset_width!) /
                    block.content.main_image.salsify_asset_height!,
              height:
                block.content.main_image.salsify_asset_height! >= block.content.main_image.salsify_asset_width!
                  ? 512
                  : (512 * block.content.main_image.salsify_asset_height!) /
                    block.content.main_image.salsify_asset_width!,
            }
          : undefined,
      image_settings: block.settings.image_settings,
      show_pricing: block.settings.show_pricing,
      show_no_of_units: block.settings.show_no_of_units,
      no_of_units: block.settings.no_of_units,
      show_list_price: block.settings.show_list_price,
      list_price: block.settings.list_price,
      show_net_price: block.settings.show_net_price,
      net_price: block.settings.net_price,
      net_price_label: block.settings.net_price_label,
      show_ext_price: block.settings.show_ext_price,
      extended_price: block.settings.ext_price,
      show_finishes: block.settings.show_finishes,
      finishes: block.settings.finishes
        .map((finish) => ({
          image: finishIcons[finish.term] || customFinishIcon,
          is_selected: block.settings.selected_finish === finish.term,
        }))
        .filter((finish): finish is Required<ProductProps['finishes'][0]> => typeof finish.image !== 'undefined')
        .filter((finish, index, finishes) => {
          // Filter out duplicate custom finish icons, but keep the first one.
          if (
            finish.image === customFinishIcon &&
            index !== finishes.findIndex((item) => item.image === customFinishIcon)
          ) {
            return false;
          }
          return true;
        }),
      show_info: block.settings.show_info,
      heading: block.settings.heading,
      notes: block.settings.notes,
      show_documents: block.settings.show_documents,
      installation_guide: installation_guide
        ? installation_guide.source === 'product'
          ? installation_guide.file.salsify_url
          : installation_guide.file.private_url ?? undefined
        : undefined,
      customer_service: customer_service
        ? customer_service.source === 'product'
          ? customer_service.file.salsify_url
          : customer_service.file.private_url ?? undefined
        : undefined,
      warranty: warranty
        ? warranty.source === 'product'
          ? warranty.file.salsify_url
          : warranty.file.private_url ?? undefined
        : undefined,
      product_sheet: product_sheet
        ? product_sheet.source === 'product'
          ? product_sheet.file.salsify_url
          : product_sheet.file.private_url ?? undefined
        : undefined,
      spec_sheet: spec_sheet
        ? spec_sheet.source === 'product'
          ? spec_sheet.file.salsify_url
          : spec_sheet.file.private_url ?? undefined
        : undefined,
      parts_diagram: parts_diagram
        ? parts_diagram.source === 'product'
          ? parts_diagram.file.salsify_url
          : parts_diagram.file.private_url ?? undefined
        : undefined,
      currency_type,
      show_special_attributes: block.settings.show_special_attributes,
      ada_compliant: block.content?.ada_compliant ?? undefined,
      buy_american_act: block.content?.buy_american_act ?? undefined,
      watersense_certified: block.content?.watersense_certified ?? undefined,
      lead_free: block.content?.lead_free ?? undefined,
      csa_cert: block.content?.csa_cert ?? undefined,
      upc_cert: block.content?.upc_cert ?? undefined,
    };
  };

export interface GenerateSPBPdfPageProps {
  pages: ProjectDefaultValuesType['latest']['pages'];
  page_index: number;
  header?: React.ReactNode;
  footer?: React.ReactNode | ((page: ProjectDefaultValuesType['latest']['pages'][0]) => React.ReactNode);
  wrapWithPage?: boolean;
  currency_type: CurrencyCode | undefined;
}

export const GenerateSPBPdfPage: React.VFC<GenerateSPBPdfPageProps> = ({
  header,
  pages,
  page_index,
  footer,
  wrapWithPage = true,
  currency_type,
}) => {
  const page = pages[page_index];

  if (typeof page === 'undefined' || !('type' in page)) {
    return null;
  }

  let $pageContent: React.ReactNode = null;

  if (page.type === CoverPageType.Cover) {
    const filteredBlocks = (page.blocks ?? []).filter(filterEmptyImageBlock);

    $pageContent = (
      <spbPdf.page.Cover
        images={
          filteredBlocks.length > 2
            ? [filteredBlocks[0], filteredBlocks[1], filteredBlocks[2]]
            : filteredBlocks.length > 1
            ? [filteredBlocks[0], filteredBlocks[1]]
            : filteredBlocks.length > 0
            ? [filteredBlocks[0]]
            : undefined
        }
        layout={page.layout}
      />
    );
  } else if (page.type === IntroductionPageType.Introduction) {
    const filteredBlocks = (page.blocks ?? []).filter(filterEmptyIntroductionBlock).map((block) => {
      const show_link =
        typeof block.content.id !== 'number'
          ? block.content.show_link
          : typeof block.settings.show_link === 'boolean'
          ? block.settings.show_link
          : block.content.show_link;
      const link =
        typeof block.content.id !== 'number' ? block.content.link : block.settings.link || block.content.link;

      return {
        title: block.settings.name || block.content.name,
        content:
          (block.settings.content || block.content.content) + (show_link && link ? `\n\n[Learn More](${link})` : ''),
        image: block.settings.image || block.content.image,
        image_settings: (block.settings.image_settings || block.content.image_settings) ?? undefined,
      };
    });

    if (filteredBlocks.length) {
      $pageContent = (
        <spbPdf.page.Introduction
          introductions={
            filteredBlocks.length === 1
              ? [filteredBlocks[0]]
              : filteredBlocks.length === 2
              ? [filteredBlocks[0], filteredBlocks[1]]
              : filteredBlocks.length === 3
              ? [filteredBlocks[0], filteredBlocks[1], filteredBlocks[2]]
              : [filteredBlocks[0], filteredBlocks[1], filteredBlocks[2], filteredBlocks[3]]
          }
        />
      );
    }
  } else if (page.type === NotesPageType.Notes) {
    if (page.blocks) {
      const filteredBlocks: NotesProps[] = (page.blocks ?? [])
        .filter((block) => {
          if (block.type === 'image' && !block.content) {
            return false;
          }
          if (
            block.type === 'text' &&
            (!block.content || block.content?.content === '' || block.content?.content === null)
          ) {
            return false;
          }
          return block;
        })
        .map((block) => {
          if (block.type === 'image') {
            return {
              image: block.content ?? undefined,
              image_settings: block.settings ?? undefined,
              text: '',
              type: block.type,
            };
          } else {
            return {
              text: block.content?.content ?? '',
              image: undefined,
              image_settings: undefined,
              type: block.type,
            };
          }
        });
      $pageContent = <spbPdf.page.Notes blocks={filteredBlocks} layout={page.layout} />;
    }
  } else if (page.type === DividerPageType.Divider) {
    const imageBlock = page.blocks
      ? page.blocks.find((block): block is ImageBlockDefaultValues => block.type === ImageBlockType.Image)
      : undefined;
    const textBlock = page.blocks
      ? page.blocks.find((block): block is TitleBlockDefaultValues => block.type === TextBlockType.Text)
      : undefined;

    $pageContent = (
      <spbPdf.page.Divider
        image={
          typeof imageBlock !== 'undefined' && typeof imageBlock.content !== 'undefined'
            ? {
                content: imageBlock.content,
                settings: imageBlock.settings,
              }
            : undefined
        }
        title={textBlock?.content?.content || undefined}
        layout={page.layout}
      />
    );
  } else if (page.type === ProductPageType.Product) {
    const blocks = (page.blocks ?? []).filter(filterEmptyProductBlock);

    if (blocks.length) {
      $pageContent = <spbPdf.page.Product products={blocks.map(productBlockToPdfProps(currency_type))} />;
    }
  } else if (page.type === SummaryPageType.Summary) {
    $pageContent = (
      <spbPdf.page.Summary
        title={
          page.blocks && page.blocks[0] && page.blocks[0].type === SummaryBlockType.Summary && page.blocks[0].content
            ? page.blocks[0].content.content ?? ''
            : ''
        }
        products={pages.flatMap((page) => {
          if (page.type !== ProductPageType.Product) {
            return [];
          }
          return (page.blocks ?? []).filter(filterEmptyProductBlock).map(productBlockToPdfProps(currency_type));
        })}
        settings={
          page.blocks && page.blocks[0] && page.blocks[0].type === SummaryBlockType.Summary && page.blocks[0].settings
            ? page.blocks[0].settings
            : {
                show_totals: true,
                show_no_of_units: true,
                show_list_price: true,
                show_net_price: true,
                show_ext_price: true,
              }
        }
        currency_type={currency_type}
      />
    );
  } else if (page.type === TocPageType.Toc) {
    $pageContent = (
      <spbPdf.page.Toc
        headline={(page.blocks ?? [])[0]?.content?.content ?? ''}
        links={pages
          .map((page, index) => {
            if (index === 0 && (page.type === CoverPageType.Cover || page.type === DividerPageType.Divider)) {
              return undefined;
            }

            return {
              label: page.name,
              page: index + 1,
            };
          })
          .filter(
            (link): link is { label: string; page: number } => typeof link !== 'undefined' && Boolean(link.label)
          )}
      />
    );
  }

  if (!wrapWithPage) {
    return (
      <>
        {header}
        {$pageContent}
        {footer}
      </>
    );
  }

  return (
    <reactPdf.Page
      size="A4"
      id={`page-${page_index + 1}`}
      style={{ paddingVertical: page.type === SummaryPageType.Summary ? 110 : 90 }}
    >
      {header}
      {$pageContent}
      {runIfFn(footer, page)}
    </reactPdf.Page>
  );
};

export const PreviewSPBPdfDocument: React.VFC<{
  project: ProjectDefaultValuesType;
  effectiveDate: string;
  user?: Project['user'];
}> = ({ project, effectiveDate, user }) => {
  console.log('logo', project.latest);
  const header: GenerateSPBPdfPageProps['header'] = (
    <spbPdf.component.Header
      label={project.latest?.logo_text ?? ''}
      logo={project.latest?.logo ?? undefined}
      logo_settings={project.latest?.logo_settings ?? undefined}
      clients={
        typeof project.latest?.clients !== 'undefined'
          ? project.latest.clients.filter(filterEmptyClient).map((client) => ({
              name: client.name ?? '',
              image:
                client.image && client.image.image
                  ? client.image.width && client.image.height
                    ? { src: client.image.image, width: client.image.width, height: client.image.height }
                    : { src: client.image.image }
                  : undefined,
            }))
          : []
      }
    />
  );

  const footer: GenerateSPBPdfPageProps['footer'] = (page) => (
    <spbPdf.component.Footer
      client={{
        name: project.latest.opportunity?.name ?? '',
        address: project.latest.show_address
          ? [
              project.latest.opportunity?.address,
              [
                project.latest.opportunity?.city,
                [project.latest.opportunity?.state, project.latest.opportunity?.postal_code].filter(Boolean).join(' '),
              ]
                .filter(Boolean)
                .join(', '),
            ]
              .filter(Boolean)
              .join('\n')
          : undefined,
      }}
      effective_date={effectiveDate}
      prepared_by={
        project.latest.show_rep_info
          ? {
              name: user ? getUserName(user).name : '',
              email: user?.email ?? '',
              phone: user?.phone ?? undefined,
            }
          : undefined
      }
      quote_number={project.latest.number}
      pricing_footer_note={
        page.type === ProductPageType.Product || page.type === SummaryPageType.Summary
          ? project.latest.pricing_footer_note
          : undefined
      }
    />
  );

  return (
    <reactPdf.Document>
      {(project.latest?.pages ?? [])
        .filter((page): page is ProjectVersionPageDefaultValue => typeof page.type !== 'undefined')
        .map((page, index, pages) => (
          <GenerateSPBPdfPage
            key={page?.id ? page.id : `page_index_${index}`}
            pages={pages}
            page_index={index}
            header={header}
            footer={footer}
            currency_type={project.latest.currency_type}
          />
        ))}
    </reactPdf.Document>
  );
};

export const SPBPdfDocument: React.VFC<{ project: Project; version: 'latest' | number }> = (props) => {
  const project = projectToFormValue(props.project, props.version);
  return (
    <PreviewSPBPdfDocument
      project={project}
      effectiveDate={format(parseISO(props.project.updated_at), 'MMM d y')}
      user={props.project.user}
    />
  );
};
