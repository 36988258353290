import * as React from 'react';
import { z } from 'zod';
import { useController } from 'react-hook-form';
import classNames from 'classnames';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { ImageBlockType } from '__generated-api__';
import Icon from 'components/icon';
import { FormProvider, useForm } from 'components/Form/Form';
import { Modal } from 'my-account/components/Modal';
import { DisplayFieldErrors } from 'my-account/components/DisplayFieldError';
import { getLocalBlockId, ImageBlock } from 'my-account/validations/project';
import { useImageUpload } from 'my-account/utils/image-upload';
import { truncate } from 'my-account/utils/text';

import MediaLibraryModalContent from './modal-contents/library';
import ImageDetailsModalContent from './modal-contents/details';
import EditImageModalContent from './modal-contents/edit';
import DropPlaceholderModalContent from './modal-contents/drop-placeholder';
import UploadProgressModalContent from './modal-contents/upload-progress';
import ChooseImageModalContent from './modal-contents/choose';
import UploadImageModalContent from './modal-contents/upload';

interface ImageBlockFieldType {
  block: z.infer<typeof ImageBlock>;
}

type ImageBlockFieldDefaultValueType = Pick<z.infer<typeof ImageBlock>, 'id' | 'type'> &
  Partial<Pick<z.infer<typeof ImageBlock>, 'content' | 'settings'>>;

export interface ImageBlockFieldProps {
  name: string;
  title: string;
  buttonProps?: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
}

const ImageBlockField: React.VFC<ImageBlockFieldProps> = ({ name, title, buttonProps }) => {
  const { field, fieldState } = useController<ImageBlockFieldType, 'block'>({
    name: name as 'block',
  });
  const [modalState, setModalState] = React.useState<
    'closed' | 'details' | 'edit' | 'choose' | 'choose-library' | 'upload' | 'library'
  >('closed');

  const onChange = (value: ImageBlockFieldDefaultValueType) => {
    return field.onChange(value);
  };

  const { context, formProps } = useForm({
    initialValues: field.value,
    onSubmit: async (values, ctx) => {
      onChange(values);
      setModalState('closed');
      ctx.reset(values);
    },
    schema: ImageBlock,
    disableFieldsOnSubmitting: true,
    hasFloatingLabels: true,
    stopSubmitPropagation: true,
  });

  const contentField = useController({ control: context.control, name: 'content', defaultValue: field.value.content });
  const settingsField = useController({
    control: context.control,
    name: 'settings',
    defaultValue: field.value.settings,
  });

  const { getRootProps, getInputProps, isDragActive, open, uploadImageState, uploadProgress, cancelUpload } =
    useImageUpload({
      onChange: (data) => {
        contentField.field.onChange({ ...data });
        settingsField.field.onChange({
          top: 0,
          left: 0,
          width: data.width,
          height: data.height,
          rotate: 0,
          flip: 0,
        });

        setModalState('details');
      },
    });

  const onClose = () => {
    if (modalState !== 'details' && modalState !== 'choose' && modalState !== 'choose-library') {
      setModalState('details');
    } else if (context.formState.isDirty) {
      if (window.confirm('Do you really want to leave? You have unsaved changes!')) {
        setModalState('closed');
        field.onBlur();
        context.reset(field.value);
      }
    } else {
      setModalState('closed');
      field.onBlur();
      context.reset(field.value);
    }
  };

  return (
    <>
      <button
        {...buttonProps}
        className={classNames(
          'c-button c-button--white c-button--option c-button--full c-button--option-drag u-mb-spacer',
          {
            'is-invalid': typeof fieldState.error !== 'undefined',
          }
        )}
        onClick={(event) => {
          event.preventDefault();
          setModalState(field.value?.content ? 'details' : 'choose');
        }}
        type="button"
      >
        <span>
          <small>{title}</small>
          {field.value && field.value.content ? (
            truncate(field.value.content.title ?? '', 20)
          ) : (
            <>
              Add <Icon name="add-thin" className="o-svg-icon" />
            </>
          )}
        </span>
        <Icon name="pencil" className="o-svg-icon o-svg-large u-ml-auto" />
      </button>
      <input {...getInputProps()} />
      <DisplayFieldErrors errors={fieldState.error} />
      <FormProvider {...context}>
        <Modal
          isOpen={modalState !== 'closed'}
          onRequestClose={onClose}
          style={{
            content: { maxWidth: modalState === 'library' || modalState === 'choose-library' ? '80rem' : '41.25rem' },
          }}
          overlayElement={(props, contentEl) => (
            <div {...getRootProps()}>
              <div {...props}>{contentEl}</div>
            </div>
          )}
        >
          <div
            className={classNames(
              'c-modal',
              { 'c-modal--upload': isDragActive },
              { 'c-modal--dark c-color--invert': modalState === 'edit' }
            )}
            style={{ width: '100%' }}
          >
            {/*eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */}
            <a
              href="#"
              className="c-modal-close"
              onClick={(event) => {
                event.preventDefault();
                onClose();
              }}
            />

            <div className="c-modal__main">
              {modalState === 'details' || modalState === 'edit' ? (
                modalState === 'details' ? (
                  typeof contentField.field.value === 'undefined' ? (
                    <p>Invalid image!</p>
                  ) : (
                    <ImageDetailsModalContent
                      value={contentField.field.value}
                      settings={settingsField.field.value}
                      isEditable
                      clearImage={() => {
                        const newValue = {
                          id: getLocalBlockId(),
                          type: ImageBlockType.Image,
                          content: undefined,
                          settings: undefined,
                        };
                        onChange(newValue);
                        setModalState('closed');
                        context.reset(newValue);
                      }}
                      openEdit={() => {
                        setModalState('edit');
                      }}
                      openLibrary={() => {
                        setModalState('library');
                      }}
                      openUpload={() => {
                        setModalState('upload');
                      }}
                      formProps={formProps}
                      errors={context.formState.errors}
                    />
                  )
                ) : typeof contentField.field.value === 'undefined' ? (
                  <p>Invalid image!</p>
                ) : (
                  <EditImageModalContent
                    title={title}
                    image={contentField.field.value}
                    settings={settingsField.field.value}
                    onChange={(settings) => {
                      settingsField.field.onChange(settings);
                      setModalState('details');
                    }}
                    onCancel={() => {
                      setModalState('details');
                    }}
                  />
                )
              ) : uploadImageState.isLoading ? (
                <UploadProgressModalContent title={title} uploadProgress={uploadProgress} cancelUpload={cancelUpload} />
              ) : isDragActive ? (
                <DropPlaceholderModalContent title={title} />
              ) : modalState === 'library' || modalState === 'choose-library' ? (
                <MediaLibraryModalContent
                  title={title}
                  onInsert={(file) => {
                    contentField.field.onChange({ ...file });
                    settingsField.field.onChange({
                      top: 0,
                      left: 0,
                      width: file.width,
                      height: file.height,
                      rotate: 0,
                      flip: 0,
                    });
                    setModalState('details');
                  }}
                />
              ) : modalState === 'choose' ? (
                <ChooseImageModalContent
                  title={title}
                  open={open}
                  error={uploadImageState.isError ? uploadImageState.error : undefined}
                  openLibrary={() => {
                    setModalState('choose-library');
                  }}
                />
              ) : (
                <UploadImageModalContent
                  open={open}
                  error={uploadImageState.isError ? uploadImageState.error : undefined}
                />
              )}
            </div>
          </div>
        </Modal>
      </FormProvider>
    </>
  );
};

export const SortableImageBlockField: React.VFC<ImageBlockFieldProps & { id: string }> = ({ id, ...props }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <ImageBlockField
      {...props}
      buttonProps={{ ref: setNodeRef, style, ...attributes, ...listeners, ...props.buttonProps }}
    />
  );
};

export const ImageFieldWithPreview: React.VFC<{ name: string }> = ({ name }) => {
  const { field, fieldState } = useController<ImageBlockFieldType, 'block.content'>({
    name: name as 'block.content',
  });

  const onChange = (value: ImageBlockFieldDefaultValueType['content']) => {
    return field.onChange(value);
  };

  const { context, formProps } = useForm({
    initialValues: { content: field.value },
    onSubmit: async (values, ctx) => {
      onChange(values.content);
      setModalState('closed');
      ctx.reset(values);
    },
    schema: ImageBlock.pick({ content: true }),
    disableFieldsOnSubmitting: true,
    hasFloatingLabels: true,
    stopSubmitPropagation: true,
  });

  const contentField = useController({ control: context.control, name: 'content', defaultValue: field.value });

  const [modalState, setModalState] = React.useState<
    'closed' | 'details' | 'choose' | 'choose-library' | 'upload' | 'library'
  >('closed');
  const { getRootProps, getInputProps, isDragActive, open, uploadImageState, uploadProgress, cancelUpload } =
    useImageUpload({
      onChange: (data) => {
        contentField.field.onChange({
          ...data,
        });
        setModalState('details');
      },
    });

  const onClose = () => {
    if (modalState !== 'details' && modalState !== 'choose' && modalState !== 'choose-library') {
      setModalState('details');
    } else if (context.formState.isDirty) {
      if (window.confirm('Do you really want to leave? You have unsaved changes!')) {
        setModalState('closed');
        field.onBlur();
        context.reset({ content: field.value });
      }
    } else {
      setModalState('closed');
      field.onBlur();
      context.reset({ content: field.value });
    }
  };

  return (
    <>
      {field.value ? (
        <div className="o-row o-row--small-gutters u-mb-spacer-base">
          {typeof field.value.image === 'string' && (
            <div className="o-col-6@sm">
              <div className="c-form__logo-preview">
                <label>Logo</label>
                <img src={field.value.image} alt="Logo" />
              </div>
            </div>
          )}
          <div className="o-col-6@sm">
            <div>
              <button
                className="c-button c-button--light-grey c-button--option c-button--full"
                onClick={(event) => {
                  event.preventDefault();
                  setModalState('choose-library');
                }}
                type="button"
              >
                <Icon name="refresh" className="o-svg-icon o-svg-large" />
                <span>Change from Library</span>
              </button>
              <button
                className="c-button c-button--light-grey c-button--option c-button--full"
                onClick={(event) => {
                  event.preventDefault();
                  setModalState('upload');
                  open();
                }}
                type="button"
              >
                <Icon name="upload" className="o-svg-icon o-svg-large" />
                <span>Upload New Image</span>
              </button>
              <button
                className="c-button c-button--light-grey c-button--option c-button--full"
                onClick={(event) => {
                  event.preventDefault();
                  field.onChange(null);
                }}
                type="button"
              >
                <Icon name="close" className="o-svg-icon" />
                <span>Clear Image</span>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="u-text-right u-mb-spacer-base">
          {/*eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            href="#"
            className="c-button c-button--dark c-button--md"
            onClick={(event) => {
              event.preventDefault();
              setModalState('choose');
            }}
          >
            <Icon name="add-thin" className="o-svg-icon" />
            <span>Display Logo</span>
          </a>
        </div>
      )}
      <input {...getInputProps()} />
      <DisplayFieldErrors errors={fieldState.error} />
      <Modal
        isOpen={modalState !== 'closed'}
        onRequestClose={onClose}
        style={{
          content: { maxWidth: modalState === 'library' || modalState === 'choose-library' ? '80rem' : '41.25rem' },
        }}
        overlayElement={(props, contentEl) => (
          <div {...getRootProps()}>
            <div {...props}>{contentEl}</div>
          </div>
        )}
      >
        <div className={classNames('c-modal', { 'c-modal--upload': isDragActive })} style={{ width: '100%' }}>
          {/*eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */}
          <a
            href="#"
            className="c-modal-close"
            onClick={(event) => {
              event.preventDefault();
              onClose();
            }}
          />

          <div className="c-modal__main">
            {modalState === 'details' ? (
              typeof contentField.field.value === 'undefined' ? (
                <p>Invalid image!</p>
              ) : (
                <ImageDetailsModalContent value={contentField.field.value} formProps={formProps} />
              )
            ) : uploadImageState.isLoading ? (
              <UploadProgressModalContent title="Logo" uploadProgress={uploadProgress} cancelUpload={cancelUpload} />
            ) : isDragActive ? (
              <DropPlaceholderModalContent title="Logo" />
            ) : modalState === 'library' || modalState === 'choose-library' ? (
              <MediaLibraryModalContent
                title="Logo"
                onInsert={(file) => {
                  contentField.field.onChange({
                    ...file,
                  });
                  setModalState('details');
                }}
              />
            ) : modalState === 'choose' ? (
              <ChooseImageModalContent
                title="Logo"
                open={open}
                error={uploadImageState.isError ? uploadImageState.error : undefined}
                openLibrary={() => {
                  setModalState('choose-library');
                }}
              />
            ) : (
              <UploadImageModalContent
                open={open}
                error={uploadImageState.isError ? uploadImageState.error : undefined}
              />
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ImageBlockField;
