import * as React from 'react';
import { z } from 'zod';
import { useFormContext } from 'react-hook-form';
import { MutationStatus } from 'react-query';

import {
  AdminUserRoleEnum,
  UserRole,
  CompanyManagerUserRoleEnum,
  RegisteredUser,
  SalesRepUserRoleEnum,
  FileType,
  AgencyRepUserRoleEnum,
} from '__generated-api__';
import api from 'api';
import { useAuth, useAuthenticatedUser } from 'auth';
import { useMutation } from 'hooks/query';
import Form from 'components/Form/Form';
import InputField from 'components/Form/InputField';
import SubmitButton from 'components/Form/SubmitButton';
import Icon from 'components/icon';
import SelectField from 'components/Form/SelectField';
import UnsavedChangesPrompt from 'components/Form/UnsavedChangesPrompt';
import { MainHero } from 'my-account/components/MainHero';
import { ProfileImageField } from 'my-account/components/ProfileImage';
import { AddressText } from 'my-account/components/AddressText';
import { RequestPasswordResetButton } from 'my-account/components/RequestPasswordResetButton';
import { useToast } from 'my-account/toast';
import useDocumentTitle from 'hooks/useDocumentTitle';

const ProfileUpdateSchema = z.object({
  first_name: z.string().nonempty(),
  last_name: z.string().nonempty(),
  email: z.string().nonempty().email(),
  image: z
    .object({
      id: z.number().positive(),
      title: z.string(),
      thumbnail: z.string(),
    })
    .nullable()
    .optional(),
});

interface IProfileReadOnlyFields {
  role: UserRole;
  company_name?: string;
  location_name?: string;
}

const SelectedRoleGuard: React.FC<{ roles: UserRole[] }> = ({ roles, children }) => {
  const { watch } = useFormContext<{ _readOnly: IProfileReadOnlyFields }>();
  const { _readOnly } = watch();

  if (!roles.includes(_readOnly.role)) {
    return null;
  }

  return <>{children}</>;
};

const getUserInitialValues = (user: RegisteredUser) => ({
  first_name: user.first_name ?? '',
  last_name: user.last_name ?? '',
  email: user.email,
  image: user.image
    ? {
        id: user.image.id,
        title: user.image.title,
        thumbnail: user.image.thumbnail,
      }
    : null,
  _readOnly: {
    role: user.role as unknown as UserRole,
    company_name:
      user.role !== AdminUserRoleEnum.Admin &&
      user.role !== SalesRepUserRoleEnum.SalesRep &&
      user.role !== AgencyRepUserRoleEnum.AgencyRep
        ? user.company.name
        : undefined,
    location_name:
      user.role !== AdminUserRoleEnum.Admin &&
      user.role !== CompanyManagerUserRoleEnum.CompanyManager &&
      user.role !== SalesRepUserRoleEnum.SalesRep &&
      user.role !== AgencyRepUserRoleEnum.AgencyRep &&
      user.location
        ? user.location.name
        : undefined,
  },
});

export default function ProfilePage() {
  useDocumentTitle('My Profile');
  const toast = useToast();
  const auth = useAuth();
  const user = useAuthenticatedUser();
  const [updateProfile] = useMutation(api.user.updateProfile);
  const [uploadImageStatus, setUploadImageStatus] = React.useState<MutationStatus>('idle');

  return (
    <>
      <MainHero />

      <section className="c-block c-block--hero">
        <div className="o-container-fluid">
          <div className="o-row u-flex-grow u-items-end">
            <div className="o-col-12">
              <div className="c-block__header">
                <h1 className="c-title--extra-large u-mt-spacer-base-large u-mb-0">Profile</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="c-block__pattern c-block__pattern--light3 c-block__pattern--top3 c-block__overlay--opacity-20">
          <Icon name="pattern" className="o-svg-icon" />
        </div>
      </section>

      <Form
        schema={ProfileUpdateSchema}
        onSubmit={async (values, ctx) => {
          const res = await updateProfile([
            {
              userUpdateUserUpdatePasswordUpdate: { ...values, image: values.image ?? null },
            },
          ]);

          ctx.reset(getUserInitialValues(res.data));
          auth.updateUserData(res.data);

          toast.notify({
            type: 'success',
            title: 'Success',
            message: "You've successfully updated your profile.",
          });
        }}
        initialValues={getUserInitialValues(user)}
        className="u-mb-0"
        hasFloatingLabels
      >
        <UnsavedChangesPrompt />
        <section className="c-block c-block--spacing-b c-block--spacing-b-large@md">
          <div className="o-container-fluid">
            <div className="o-row">
              <div className="o-col-3@md">
                <p className="u-text-xs u-uppercase u-mb-spacer-base-small">About Me</p>
                <p className="c-note">Enter your profile information and click Save Changes when you’re done.</p>
              </div>

              <div className="o-col-9@md o-col-6@lg">
                <div className="o-row o-row--small-gutters">
                  <div className="o-col-6@sm">
                    <InputField name="first_name" label="First Name" elStyle="fill" />
                  </div>
                  <div className="o-col-6@sm">
                    <InputField name="last_name" label="Last Name" elStyle="fill" />
                  </div>
                  <div className="o-col-12">
                    <InputField name="email" label="Email address" type="email" elStyle="fill" />
                  </div>
                  <div className="o-col-12">
                    <fieldset disabled>
                      <SelectField name="_readOnly[role]" label="User Role" elStyle="fill">
                        <option value={UserRole.Admin}>Administrator</option>
                        <option value={UserRole.AgencyRep}>Agency Representative</option>
                        <option value={UserRole.SalesRep}>Sales Representative</option>
                        <option value={UserRole.CompanyManager}>Company Manager</option>
                        <option value={UserRole.LocationManager}>Location Manager</option>
                        <option value={UserRole.User}>User</option>
                      </SelectField>
                    </fieldset>
                  </div>
                  <SelectedRoleGuard roles={[UserRole.CompanyManager, UserRole.LocationManager, UserRole.User]}>
                    <div className="o-col-12">
                      <fieldset disabled>
                        <InputField name="_readOnly[company_name]" label="Company" type="text" elStyle="fill" />
                      </fieldset>
                    </div>
                  </SelectedRoleGuard>
                  <SelectedRoleGuard roles={[UserRole.LocationManager, UserRole.User]}>
                    <div className="o-col-12">
                      <fieldset disabled>
                        <InputField name="_readOnly[location_name]" label="Location" type="text" elStyle="fill" />
                      </fieldset>
                    </div>
                  </SelectedRoleGuard>
                </div>
              </div>

              <div className="o-col-9 o-col-5@sm o-col-3@lg o-offset-3@md u-ml-0@lg">
                <ProfileImageField
                  name="image"
                  type={FileType.Avatar}
                  onUploadStatusChange={(status) => {
                    setUploadImageStatus(status);
                  }}
                />
              </div>
            </div>

            {user.role !== AdminUserRoleEnum.Admin &&
              user.role !== SalesRepUserRoleEnum.SalesRep &&
              user.role !== AgencyRepUserRoleEnum.AgencyRep && (
                <div className="o-row">
                  <div className="o-col-9@md u-ml-auto">
                    <div className="o-row o-row--small-gutters">
                      <div className="o-col-6@lg">
                        <label className="c-form-label u-text-neutral-600">BILLING ADDRESS:</label>
                        <div className="c-admin-card c-admin-card--spacing-sm u-mb-spacer-base">
                          <p className="u-text-neutral-600 u-font-medium u-mb-0">
                            <AddressText address={user.company.addresses[0]} />
                          </p>
                        </div>
                      </div>

                      {user.role !== CompanyManagerUserRoleEnum.CompanyManager && user.location && (
                        <div className="o-col-6@lg">
                          <label className="c-form-label u-text-neutral-600">SHIPPING ADDRESS:</label>

                          <div className="c-admin-card c-admin-card--spacing-sm u-mb-spacer-base">
                            <p className="u-text-neutral-600 u-font-medium u-mb-0">
                              <AddressText address={user.location} />
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

            <div className="o-row">
              <div className="o-col-12">
                <hr className="u-mt-spacer-base u-mt-spacer-base-large@sm u-mb-spacer-base-large u-mb-spacer-section-small@sm" />
              </div>
            </div>

            <div className="o-row">
              <div className="o-col-3@md">
                <p className="u-text-xs u-uppercase u-mb-spacer-base-small">Password</p>
                <p className="c-note">
                  Your password must be at least 8 characters long, contain at least one number, one uppercase or
                  lowercase letter, and at least one special character.
                </p>
              </div>

              <div className="o-col-9@md">
                <RequestPasswordResetButton email={user.email} />
              </div>
            </div>

            <div className="o-row">
              <div className="o-col-12">
                <hr className="u-mt-spacer-base u-mt-spacer-base-large@sm u-mb-spacer-base-large u-mb-spacer-section-small@sm" />
              </div>
            </div>

            <div className="o-row">
              <div className="o-col-6@md o-offset-3@md">
                <div className="c-form-footer">
                  <SubmitButton variant="secondary" isFull isLoading={uploadImageStatus === 'loading'}>
                    Save changes
                  </SubmitButton>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Form>
    </>
  );
}
