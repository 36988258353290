import algoliasearch, { SearchClient } from 'algoliasearch/lite';
import aa from 'search-insights';
import wordpressData from 'data';

let algoliaClient: SearchClient | undefined;

export function getAlgoliaClient() {
  if (algoliaClient) {
    return algoliaClient;
  }

  if (
    !wordpressData ||
    typeof wordpressData.algolia !== 'object' ||
    !wordpressData.algolia ||
    !wordpressData.algolia.appId ||
    !wordpressData.algolia.productsIndex ||
    !wordpressData.algolia.documentsIndex ||
    !wordpressData.algolia.searchOnlyApiKey
  ) {
    throw new Error('Algolia configuration is missing.');
  }

  // Initialize algolia search client
  algoliaClient = algoliasearch(wordpressData.algolia.appId, wordpressData.algolia.searchOnlyApiKey);

  /**
   * Setup InsightsClient for Algolia analytics.
   * Anonymous user token will not be set if "useCookie" is false. If user is logged in use "userToken" instead.
   */
  aa('init', {
    appId: wordpressData.algolia.appId,
    apiKey: wordpressData.algolia.searchOnlyApiKey,
    useCookie: true,
    userToken: undefined,
  });

  return algoliaClient;
}
