import { z } from 'zod';

import { IntroductionStatus } from '__generated-api__';
import { FileImageRelationUpdateSchema, ImageSettings } from './file';

export const IntroductionModelBase = z.object({
  id: z.number().nullable(),
  name: z.string().min(3).max(60),
  content: z.string().min(3).max(960),
  status: z.nativeEnum(IntroductionStatus),
  image: FileImageRelationUpdateSchema,
  image_settings: ImageSettings.optional().nullable(),
});

export const IntroductionModelLink = z
  .object({
    link: z.literal('').or(z.string().url()).nullable(),
    show_link: z.boolean(),
  })
  .refine(
    (val) => {
      if (val.show_link === true && !val.link) {
        return false;
      }

      return true;
    },
    {
      message: 'Required',
      path: ['link'],
    }
  );

export const IntroductionModelUpdate = IntroductionModelBase.and(IntroductionModelLink);
