import * as React from 'react';
import { z } from 'zod';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import get from 'lodash/get';
import classNames from 'classnames';

import Icon from 'components/icon';
import { Accordion } from 'components/Accordion';
import { FormProvider, useForm } from 'components/Form/Form';
import SubmitButton from 'components/Form/SubmitButton';
import InputField from 'components/Form/InputField';
import { ProjectVersionSchema } from 'my-account/validations/project';
import { ImageFieldWithPreview } from 'my-account/components/SPB/ImageBlockField';
import { Modal } from 'my-account/components/Modal';
import { DisplayFieldErrors, DisplayReactQueryError } from '../DisplayFieldError';
import { ChooseImagePlaceholder } from './ImageBlockField/modal-contents/choose';
import BlockContentImage from './BlockContentImage';
import { UploadImageProgress } from './ImageBlockField/modal-contents/upload-progress';
import { useImageUpload } from 'my-account/utils/image-upload';
import axios from 'axios';
import MediaLibraryModalContent from './ImageBlockField/modal-contents/library';
import EditImageModalContent from './ImageBlockField/modal-contents/edit';

const headerSchema = ProjectVersionSchema.pick({ clients: true, logo_text: true, logo: true, logo_settings: true });
type HeaderUploadStateValue = 'default' | 'image-library' | 'image-edit';
type HeaderSchema = z.infer<typeof headerSchema>;

interface ProjectHeaderType {
  latest: HeaderSchema;
}

const ClientField: React.VFC<{ index: number; errors: any; remove: (index: number) => void }> = ({
  index,
  errors,
  remove,
}) => {
  const clientName = useWatch<HeaderSchema, 'clients.0.name'>({ name: `clients.${index}.name` as 'clients.0.name' });

  return (
    <Accordion
      title={clientName ? `Client: ${clientName}` : `Client ${index + 1}`}
      toggleActions={
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          href="#"
          className="c-link-cta-basic c-link-cta--small"
          onClick={(event) => {
            event.preventDefault();
            remove(index);
          }}
        >
          <span>Remove client</span>
        </a>
      }
      type="framed"
      isInvalid={typeof errors !== 'undefined'}
    >
      <p className="u-hidden@sm">
        {/*eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          href="#"
          className="c-link-cta-basic c-link-cta--small"
          onClick={(event) => {
            event.preventDefault();
            remove(index);
          }}
        >
          <span>Remove client</span>
        </a>
      </p>
      <InputField name={`clients.${index}.name`} label="Client Name" elStyle="fill" />
      <ImageFieldWithPreview name={`clients.${index}.image`} />
    </Accordion>
  );
};

const ClientsField: React.VFC = () => {
  const context = useFormContext<HeaderSchema>();
  const {
    fields: clients,
    append,
    remove,
  } = useFieldArray<HeaderSchema, 'clients'>({
    name: 'clients',
  });

  return (
    <div>
      {clients.map((client, index) => {
        const errors = get(context.formState.errors, `clients.${index}`);

        return <ClientField key={client.id ?? `client_${index}`} index={index} errors={errors} remove={remove} />;
      })}

      <div className="u-mb-spacer-base">
        {/*eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          href="#"
          className="c-link-cta"
          onClick={(event) => {
            event.preventDefault();
            append({
              id: null,
              name: '',
              image: null,
            });
          }}
        >
          <span>Add client</span>
        </a>
      </div>
    </div>
  );
};

const HeaderLogoUploadField: React.VFC<{
  openImageLibrary: () => void;
  openImageEdit: () => void;
}> = ({ openImageLibrary, openImageEdit }) => {
  const {
    setValue,
    formState: { errors },
  } = useFormContext<HeaderSchema>();

  const logo = useWatch<HeaderSchema, 'logo'>({ name: 'logo' });
  const logoSettings = useWatch<HeaderSchema, 'logo_settings'>({ name: 'logo_settings' });
  console.log(errors);
  const { getRootProps, getInputProps, isDragActive, open, uploadImageState, uploadProgress, cancelUpload } =
    useImageUpload({
      onChange: (data) => {
        setValue('logo', data);
        setValue('logo_settings', {
          left: 0,
          top: 0,
          rotate: 0,
          flip: 0,
          width: data.width,
          height: data.height,
        });
      },
    });

  return (
    <>
      <Accordion title={`Document Logo`} type="framed">
        <label className="c-label" htmlFor="logo">
          Upload if you wish to replace the green Symmons logo
        </label>
        <input {...getInputProps()} />
        {uploadImageState.isLoading ? (
          <UploadImageProgress title="" uploadProgress={uploadProgress} cancelUpload={cancelUpload} />
        ) : (
          <div {...getRootProps()}>
            {isDragActive ? (
              <div className="c-add-media c-add-media--active u-mb-spacer-base">
                <div>
                  <Icon name="upload" className="o-svg-lg" />
                </div>

                <div>
                  <button className="c-link-cta-basic c-link-cta--small" type="button">
                    <span>Drop file here...</span>
                  </button>
                </div>
              </div>
            ) : typeof logo !== 'undefined' ? (
              <BlockContentImage
                image={logo}
                image_settings={logoSettings}
                openFileUpload={open}
                clearImage={() => {
                  setValue('logo', undefined);
                  setValue('logo_settings', undefined);
                }}
                openImageLibrary={openImageLibrary}
                openImageEdit={openImageEdit}
                hideImageDetails
              />
            ) : (
              <ChooseImagePlaceholder openLibrary={openImageLibrary} open={open} />
            )}
            {uploadImageState.isError && uploadImageState.error && !axios.isCancel(uploadImageState.error) && (
              <DisplayReactQueryError error={uploadImageState.error} />
            )}
          </div>
        )}
      </Accordion>
    </>
  );
};

const HeaderLogoLibraryModalStateContent: React.VFC<{
  title: string;
  setModalState: (value: HeaderUploadStateValue) => void;
}> = ({ title, setModalState }) => {
  const { setValue } = useFormContext<HeaderSchema>();

  return (
    <MediaLibraryModalContent
      title={title}
      onInsert={(logo) => {
        setValue('logo', logo);
        setValue('logo_settings', {
          top: 0,
          left: 0,
          rotate: 0,
          flip: 0,
          width: logo.width,
          height: logo.height,
        });
        setModalState('default');
      }}
    />
  );
};

const HeaderLogoEditModalStateContent: React.VFC<{
  title: string;
  setModalState: (value: HeaderUploadStateValue) => void;
}> = ({ title, setModalState }) => {
  const { setValue } = useFormContext<HeaderSchema>();

  const image = useWatch<HeaderSchema, 'logo'>({ name: 'logo' });
  const imageSettings = useWatch<HeaderSchema, 'logo_settings'>({ name: 'logo_settings' });

  React.useEffect(() => {
    // It should not happen that we are in the image-edit modal state without image in the content
    // or in the settings, but anyway adding this just in case
    if (!image) {
      setModalState('default');
    }
  }, [setModalState, image]);

  if (image) {
    return (
      <EditImageModalContent
        title={title}
        image={image}
        settings={imageSettings ?? undefined}
        onChange={(settings) => {
          setValue('logo_settings', settings);
          setModalState('default');
        }}
        onCancel={() => {
          setModalState('default');
        }}
      />
    );
  }

  return null;
};

const PageHeaderField: React.VFC = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [uploadState, setUploadState] = React.useState('default');
  const mainContext = useFormContext<ProjectHeaderType>();
  const clientsErrors = get(mainContext.formState.errors, 'latest.clients');
  const logoTextErrors = get(mainContext.formState.errors, 'latest.logo_text');
  const logoErrors = get(mainContext.formState.errors, 'latest.logo');
  const logoSettingsErrors = get(mainContext.formState.errors, 'latest.logo_settings');
  const clients = useWatch<ProjectHeaderType, 'latest.clients'>({ name: 'latest.clients' });
  const logo_text = useWatch<ProjectHeaderType, 'latest.logo_text'>({ name: 'latest.logo_text' });
  const logo_settings = useWatch<ProjectHeaderType, 'latest.logo_settings'>({ name: 'latest.logo_settings' });
  const logo = useWatch<ProjectHeaderType, 'latest.logo'>({ name: 'latest.logo' });
  const form = useForm({
    schema: headerSchema,
    initialValues: { clients, logo_text, logo, logo_settings },
    stopSubmitPropagation: true,
    onSubmit: async (values) => {
      mainContext.setValue('latest.logo', values.logo);
      mainContext.setValue('latest.logo_settings', values.logo_settings);
      mainContext.setValue('latest.logo_text', values.logo_text);
      mainContext.setValue('latest.clients', values.clients);
      setIsModalOpen(false);
      form.context.reset(values);
    },
  });

  const onClose = () => {
    if (form.context.formState.isDirty) {
      if (window.confirm('Do you really want to leave? You have unsaved changes!')) {
        setIsModalOpen(false);
        setUploadState('default');
        form.context.reset({ clients, logo_text, logo, logo_settings });
      }
    } else {
      setIsModalOpen(false);
      setUploadState('default');
      form.context.reset({ clients, logo_text, logo, logo_settings });
    }
  };

  return (
    <>
      <button
        className={classNames('c-button c-button--white c-button--option c-button--full u-mb-spacer', {
          'is-invalid': typeof clientsErrors !== 'undefined' || typeof logoTextErrors !== 'undefined',
        })}
        onClick={(event) => {
          event.preventDefault();
          setIsModalOpen(true);
        }}
        type="button"
      >
        <Icon name="edit" className="o-svg-icon o-svg-large" />
        <span>Edit Header</span>
      </button>
      <DisplayFieldErrors
        errors={
          typeof clientsErrors !== 'undefined' ||
          typeof logoTextErrors !== 'undefined' ||
          typeof logoErrors !== 'undefined' ||
          typeof logoSettingsErrors !== 'undefined'
            ? { clients: clientsErrors, logo_text: logoTextErrors, logo: logoErrors, logo_settings: logoSettingsErrors }
            : {}
        }
      />
      <Modal
        isOpen={isModalOpen}
        onRequestClose={onClose}
        style={{ content: { maxWidth: uploadState === 'image-library' ? '80rem' : '41.25rem' } }}
      >
        <div className="c-modal" style={{ width: '100%' }}>
          {/*eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */}
          <a
            href="#"
            className="c-modal-close"
            onClick={(event) => {
              event.preventDefault();
              onClose();
            }}
          />

          <div className="c-modal__main">
            <div className="c-block c-block--spacing-t-small c-block--spacing-b-small">
              <div className="o-container-fluid">
                <div className="o-row">
                  <div className="o-col-12">
                    <div className="c-block__header c-block__header--hor">
                      <div className="c-block__header-content u-items-center u-block u-flex@sm">
                        <div>
                          <h4 className="u-mb-spacer-base-large">Edit Document Header</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="o-row">
                  <div className="o-col-12">
                    <FormProvider {...form.context}>
                      {uploadState === 'image-library' && (
                        <HeaderLogoLibraryModalStateContent title="" setModalState={setUploadState} />
                      )}
                      {uploadState === 'image-edit' && (
                        <HeaderLogoEditModalStateContent title="" setModalState={setUploadState} />
                      )}
                      <form {...form.formProps}>
                        {uploadState === 'default' && (
                          <>
                            <div className="u-mb-spacer-base-large">
                              <InputField name="logo_text" label="Label" elStyle="fill" />
                            </div>
                            <HeaderLogoUploadField
                              openImageEdit={() => setUploadState('image-edit')}
                              openImageLibrary={() => setUploadState('image-library')}
                            />
                            <ClientsField />
                            <SubmitButton>Save</SubmitButton>
                          </>
                        )}
                      </form>
                    </FormProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PageHeaderField;
