import { Link, useHistory } from 'react-router-dom';
import { z } from 'zod';

import api from 'api';
import { useMutation } from 'hooks/query';
import Icon from 'components/icon';
import Form from 'components/Form/Form';
import InputField from 'components/Form/InputField';
import SubmitButton from 'components/Form/SubmitButton';
import ProductImage from 'my-account/images/product-01.jpg';
import { useToast } from 'my-account/toast';
import useDocumentTitle from 'hooks/useDocumentTitle';

const ForgotPasswordSchema = z.object({
  email: z.string().email().nonempty(),
});

export function ForgotPasswordForm() {
  const history = useHistory();
  const toast = useToast();
  const [requestReset, { isLoading }] = useMutation(api.auth.requestReset);

  return (
    <Form
      schema={ForgotPasswordSchema}
      initialValues={{ email: '' }}
      onSubmit={async (data) => {
        await requestReset([{ requestPasswordReset: { email: data.email } }]);
        toast.notify({
          type: 'success',
          title: 'Success',
          message: 'A password reset email has been sent. Please click on the link to reset your email.',
          autoClose: false,
        });
        history.push('/auth/login');
      }}
      className="c-form-group c-form-group--sm c-form-group--ver u-ml-auto u-mr-auto u-mb-spacer-base-large"
      hasFloatingLabels
    >
      <div className="o-row o-row--no-gutters c-form-group__dividers u-flex-grow">
        <div className="o-col-12">
          <InputField
            name="email"
            type="email"
            label="Email address"
            icon={<Icon name="envelope" className="o-svg-icon" />}
            className="c-form-element--high c-form-element--style-line"
            disabled={isLoading}
          />
        </div>
      </div>
      <div className="c-form-footer">
        <SubmitButton isLoading={isLoading} isFull>
          Request password
        </SubmitButton>
      </div>
    </Form>
  );
}

export default function ForgotPasswordPage() {
  useDocumentTitle('Forgot Password');
  return (
    <section
      className="c-block c-block--spacing-t-extra-small c-block--spacing-t-small@md c-block--spacing-b-extra-small c-block--spacing-b-small@md c-block--bg-light2 c-block--hero c-block--height-semifull@xs c-block--bg-image"
      style={{ backgroundImage: `url(${ProductImage})` }}
    >
      <div className="o-container-fluid">
        <div className="o-row u-flex-grow u-items-center">
          <div className="o-col-12">
            <div className="c-block__header c-block__header--md c-block__header--large u-items-center u-text-center">
              <h1 className="c-title--extra-large">Forgot Your Password?</h1>
              <div className="c-hero__desc">
                <p>No problem! Just enter your email address and we’ll send you a link to reset your password.</p>
              </div>
            </div>
            <ForgotPasswordForm />
            <div className="c-info c-info--sm u-mx-auto">
              <div className="c-info__header">
                <div className="c-info__icon u-text-secondary">
                  <Icon name="info" className="o-svg-icon" />
                </div>
                <p>
                  Need help?{' '}
                  <Link
                    to="/auth/login"
                    className="c-link-cta-basic c-link-cta--small u-mb-0 u-ml-spacer-base-small@sm"
                  >
                    <span>Back to login</span>
                    <Icon name="arrow" className="o-svg-icon o-svg-right" />
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="c-block__pattern c-block__pattern--light3 c-block__overlay--opacity-40">
        <Icon name="pattern" className="o-svg-icon" />
      </div>
      <div className="c-block__overlay c-block__overlay--light2 c-block__overlay--opacity-85"></div>
    </section>
  );
}
