import * as React from 'react';
import { observer } from 'mobx-react';
import { RefinementList } from 'react-instantsearch-dom';
import Tippy from '@tippyjs/react';

import wordpressData from 'data';
import ProductSearchStore from 'search/stores/product-search-store';
import SpriteIcon from 'search/components/sprite-icon';

interface BusinessSegmentFilterProps {
  store: ProductSearchStore;
}

const BusinessSegmentFilter = observer(
  class BusinessSegmentFilter extends React.Component<BusinessSegmentFilterProps> {
    private containerRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
      const { store } = this.props;
      const { businessSegmentFilterOpened: opened } = store;
      if (!opened && this.containerRef.current) {
        this.containerRef.current.style.display = 'none';
      }
    }

    render() {
      const { store } = this.props;
      const { businessSegmentFilterOpened: opened } = store;
      const classes = ['c-filters__group', opened ? 'is-open' : ''];
      return (
        <>
          <div className={classes.join(' ')}>
            <div
              className="c-filters__header c-accordion-toggle"
              onClick={() => {
                store.setBusinessSegmentFilterOpened(!opened);
                if (this.containerRef.current) {
                  jQuery(this.containerRef.current).stop(true).slideToggle();
                }
              }}
            >
              <p className="c-filters__title">
                Business segments
                {wordpressData.productSearch.businessSegmentsTooltip && (
                  <Tippy content={wordpressData.productSearch.businessSegmentsTooltip}>
                    <a
                      href={wordpressData.productsUrl}
                      className="c-tooltip"
                      onClick={(event) => event.preventDefault()}
                    >
                      <SpriteIcon name="help" classes={['o-svg-icon']} />
                    </a>
                  </Tippy>
                )}
              </p>
            </div>
            <div className="c-filters__container c-accordion-container" ref={this.containerRef}>
              <RefinementList
                attribute="business_segments"
                limit={5}
                showMore={true}
                showMoreLimit={100}
                translations={{
                  showMore(expanded: boolean) {
                    if (expanded) {
                      return (
                        <>
                          <SpriteIcon name="chevron" classes={['o-svg-icon', 'o-svg-up']} />
                          Show less
                        </>
                      );
                    } else {
                      return (
                        <>
                          <SpriteIcon name="chevron" classes={['o-svg-icon', 'o-svg-down']} />
                          Show more
                        </>
                      );
                    }
                  },
                }}
              />
            </div>
          </div>
        </>
      );
    }
  }
);

export default BusinessSegmentFilter;
