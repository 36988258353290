import * as React from 'react';
import { z } from 'zod';
import { ForbiddenError, subject } from '@casl/ability';
import merge from 'lodash/merge';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { useController } from 'react-hook-form';
import uniqBy from 'lodash/uniqBy';

import { AdminUserRoleEnum, Location, UserRelationUpdate, UserRole } from '__generated-api__';
import api from 'api';
import wordpressData from 'data';
import { AuthStatus, useAbility, useAuth } from 'auth';
import { useMutation } from 'hooks/query';
import Form, { FormProvider, useForm } from 'components/Form/Form';
import InputField from 'components/Form/InputField';
import CheckboxField from 'components/Form/CheckboxField';
import SubmitButton from 'components/Form/SubmitButton';
import { useToast } from 'my-account/toast';
import { getUserName } from 'my-account/utils/user';
import { AddressText } from 'my-account/components/AddressText';
import { SelectUser } from 'my-account/components/SelectUser';
import Icon from 'components/icon';
import { UserRelationUpdateSchema } from 'my-account/validations/user';
import { Accordion } from 'components/Accordion';

const LocationUpdateSchema = z.object({
  use_company_sales_rep: z.boolean().optional(),
  sales_rep_id: z.number().nullable().optional(),
  sales_reps: z.array(
    z.object({
      id: z.number(),
      first_name: z.string().optional().nullable(),
      last_name: z.string().optional().nullable(),
      email: z.string().email().optional().nullable(),
      phone: z.string().nullable().optional(),
    })
  ),
});

const getLocationInitialValues = (location: Location) => {
  const companySalesRep = location.company?.sales_rep ?? undefined;

  return {
    sales_rep_id: location.sales_rep_id,
    sales_reps: location.sales_reps || [],
    use_company_sales_rep: !location.sales_rep_id && typeof companySalesRep !== 'undefined',
    _readOnly: {
      name: location.name,
      companySalesRepName: companySalesRep ? getUserName(companySalesRep).name : '',
    },
  };
};

interface MapAddress {
  name: string | null;
  latitude: number | null;
  longitude: number | null;
  address: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
}

const MapAddressMarker: React.VFC<{
  address: MapAddress;
  showInfo?: boolean;
  onInfoClose?: () => void;
  onMarkerClick?: (e: google.maps.MapMouseEvent) => void;
}> = ({ address, showInfo, onInfoClose, onMarkerClick }) => {
  const [isMounted, setIsMounted] = React.useState(false);

  React.useEffect(() => {
    setIsMounted(true);
  }, []);

  if (address.latitude === null || address.longitude === null) {
    return null;
  }

  return (
    <>
      <Marker
        position={{ lat: address.latitude, lng: address.longitude }}
        onClick={onMarkerClick}
        clickable={typeof onMarkerClick !== 'undefined'}
      >
        {showInfo && isMounted && (
          <InfoWindow onCloseClick={onInfoClose}>
            <div style={{ background: 'white', padding: '.5rem' }}>
              <p>
                <strong>{address.name}</strong>
              </p>
              <p>
                <AddressText address={address} />
              </p>
            </div>
          </InfoWindow>
        )}
      </Marker>
    </>
  );
};

const MapAddresses: React.VFC<{ addresses: MapAddress[] }> = ({ addresses }) => {
  const [selectedCompanyAddressMarker, selectCompanyAddressMarker] = React.useState<number | undefined>(undefined);
  const [isMapLoaded, setIsMapLoaded] = React.useState(false);
  const mapRef = React.useRef<google.maps.Map>();

  const bounds = React.useMemo(() => {
    const bounds = new window.google.maps.LatLngBounds();

    addresses.forEach((address) => {
      if (address.latitude === null || address.longitude === null) {
        return;
      }

      bounds.extend({ lat: address.latitude, lng: address.longitude });
    });

    return bounds;
  }, [addresses]);

  React.useEffect(() => {
    if (!mapRef.current || !isMapLoaded) {
      return;
    }

    google.maps.event.addListenerOnce(mapRef.current, 'zoom_changed', () => {
      google.maps.event.addListenerOnce(mapRef.current!, 'bounds_changed', () => {
        mapRef.current!.setZoom(Math.min(6, mapRef.current!.getZoom() ?? 6));
      });
    });
    mapRef.current.fitBounds(bounds);
  }, [mapRef, bounds, isMapLoaded]);

  return (
    <GoogleMap
      mapContainerStyle={{ width: '100%', height: '280px' }}
      zoom={6}
      onLoad={(map) => {
        mapRef.current = map;
        setIsMapLoaded(true);
      }}
      center={bounds.getCenter().toJSON()}
      options={{ streetViewControl: false, fullscreenControl: false }}
    >
      {addresses.map((address, index) => (
        <MapAddressMarker
          key={index}
          address={address}
          showInfo={selectedCompanyAddressMarker === index}
          onInfoClose={() => {
            selectCompanyAddressMarker(undefined);
          }}
          onMarkerClick={() => {
            selectCompanyAddressMarker(index);
          }}
        />
      ))}
    </GoogleMap>
  );
};

const addRepresentativeSchema = z.object({
  users: z.array(UserRelationUpdateSchema),
});

const AddRepresentative: React.VFC<{
  salesRepsIds: string[];
  onAdd: (users: UserRelationUpdate[]) => void;
  onClose: () => void;
}> = ({ salesRepsIds, onAdd, onClose }) => {
  const auth = useAuth();
  const isAdmin = auth.status === AuthStatus.LoggedIn && auth.currentUser.role === AdminUserRoleEnum.Admin;
  const { context, formProps } = useForm({
    schema: addRepresentativeSchema,
    initialValues: {
      users: undefined,
    },
    onSubmit(values, ctx, event) {
      event?.stopPropagation();
      onAdd(values.users);
      onClose();

      return Promise.resolve();
    },
  });

  if (!isAdmin) {
    return null;
  }

  return (
    <FormProvider {...context}>
      <div className="c-fetch-card c-fetch-card--colappsable">
        <div className="c-fetch-card__inner">
          <div className="c-fetch-card__input">
            <SelectUser
              name="users"
              elStyle="fill"
              placeholder="Representative"
              queryParams={{ role: UserRole.SalesRep }}
              isMulti
              selectProps={{
                filterOption: (option) => {
                  return salesRepsIds.indexOf(option.value) === -1;
                },
                onKeyDown: (event) => {
                  if (event.key === 'Enter') {
                    formProps.onSubmit();
                  }
                },
              }}
            />
          </div>

          <div>
            <button
              className="c-button c-button--white"
              type="button"
              onClick={(event) => {
                event.preventDefault();
                formProps.onSubmit();
              }}
            >
              <span>Add</span>
              <Icon name="arrow" className="o-svg-icon o-svg-right" />
            </button>
          </div>
        </div>
        <div className="c-fetch-card__close">
          <button
            type="button"
            onClick={(event) => {
              event.preventDefault();
              onClose();
            }}
          >
            <Icon name="close-thin" className="o-svg-icon o-svg-right" />
          </button>
        </div>
      </div>
    </FormProvider>
  );
};

const LocationSalesReps: React.VFC<{ hasCompanySalesRep?: boolean }> = ({ hasCompanySalesRep }) => {
  const use_company_sales_rep = useController<z.infer<typeof LocationUpdateSchema>, 'use_company_sales_rep'>({
    name: 'use_company_sales_rep',
  });
  const salesRepIdField = useController<z.infer<typeof LocationUpdateSchema>, 'sales_rep_id'>({ name: 'sales_rep_id' });
  const arrayField = useController<z.infer<typeof LocationUpdateSchema>, 'sales_reps'>({
    name: 'sales_reps',
  });
  const [isAddRepresentativeOpen, setIsAddRepresentativeOpen] = React.useState(arrayField.field.value.length === 0);

  const onChange = (value: z.infer<typeof LocationUpdateSchema>['sales_reps']) => {
    arrayField.field.onChange(value);
  };

  const addRepresentatives = (users: z.infer<typeof LocationUpdateSchema>['sales_reps']) => {
    if (!arrayField.field.value.length) {
      salesRepIdField.field.onChange(users[0].id);
    }

    onChange(uniqBy([...arrayField.field.value, ...users], 'id'));
  };

  const removeSalesRepWithId = (id: number) => {
    onChange(arrayField.field.value.filter((sales_rep) => sales_rep.id !== id));
    if (salesRepIdField.field.value === id) {
      salesRepIdField.field.onChange(null);
    }
  };

  return (
    <div className="o-row">
      <div className="o-col-3@md">
        <p className="u-text-xs u-uppercase u-mb-spacer-base-small">Symmons representative</p>
        <p className="c-note">
          If this field is empty, select the Location’s Symmons Representative from the dropdown field. If the Company’s
          Symmons Representative is already populated, you may override it for the Location by unchecking “Use Company
          Representative”, and selecting the Representative desired from the dropdown.
        </p>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          href="#"
          className="c-link-cta-basic u-mt-spacer-base-small u-mb-spacer-base u-text-xs"
          onClick={(event) => {
            event.preventDefault();
            setIsAddRepresentativeOpen(true);
            use_company_sales_rep.field.onChange(false);
          }}
        >
          <span>Add new representative</span>
          <Icon name="add" className="o-svg-icon u-text-neutral-300" />
        </a>
        {!use_company_sales_rep.field.value && hasCompanySalesRep && (
          /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
          <a
            href="#"
            className="c-link-cta-basic u-mt-spacer-base-small u-mb-spacer u-text-xs"
            onClick={(event) => {
              event.preventDefault();
              use_company_sales_rep.field.onChange(true);
              setIsAddRepresentativeOpen(false);
              onChange([]);
            }}
          >
            <span>Revert to comp. representatives</span>
            <Icon name="back" className="o-svg-icon u-text-neutral-600" />
          </a>
        )}
      </div>

      <div className="o-col-9@md">
        <div className="o-row o-row--small-gutters">
          <div className="o-col-12">
            {use_company_sales_rep.field.value ? (
              <InputField
                label="Company Sales Representative"
                name="_readOnly[companySalesRepName]"
                disabled
                elStyle="fill"
                inputProps={{ readOnly: true }}
              />
            ) : (
              <>
                {arrayField.field.value.map((sales_rep) => (
                  <Accordion
                    key={sales_rep.id}
                    title={getUserName(sales_rep).name}
                    type="card"
                    isPrimary={salesRepIdField.field.value === sales_rep.id}
                    toggleActions={
                      <div className="u-hidden u-block@sm">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a
                          href="#"
                          className="c-link-cta-basic c-link-cta--small"
                          onClick={(event) => {
                            event.preventDefault();
                            removeSalesRepWithId(sales_rep.id);
                          }}
                        >
                          <span>Remove representative</span>
                        </a>
                      </div>
                    }
                  >
                    <div className="o-row">
                      <div className="o-col-6@lg">
                        <div className="c-table-wrapper">
                          <table>
                            <tbody>
                              {Boolean(sales_rep.phone) && (
                                <tr>
                                  <th>T:</th>
                                  <td>{sales_rep.phone}</td>
                                </tr>
                              )}
                              <tr>
                                <th>E:</th>
                                <td>
                                  <a href={`mailto:${sales_rep.email}`}>{sales_rep.email}</a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <hr className="u-mb-spacer-base" />

                    <div>
                      <CheckboxField
                        checkboxStyle="toggle"
                        name={`sales_reps_is_primary.${sales_rep.id}`}
                        label="This user is a primary representative for the location"
                        inputProps={{
                          onChange: (event) => {
                            salesRepIdField.field.onChange(
                              salesRepIdField.field.value === sales_rep.id ? null : sales_rep.id
                            );
                          },
                          checked: salesRepIdField.field.value === sales_rep.id,
                        }}
                      />
                    </div>
                  </Accordion>
                ))}
                {isAddRepresentativeOpen && (
                  <AddRepresentative
                    salesRepsIds={arrayField.field.value.map((sales_rep) => String(sales_rep.id))}
                    onAdd={(users) => {
                      addRepresentatives(users);
                      use_company_sales_rep.field.onChange(false);
                    }}
                    onClose={() => {
                      setIsAddRepresentativeOpen(false);
                    }}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const EditLocationForm: React.VFC<{ location: Location }> = ({ location }) => {
  const toast = useToast();
  const ability = useAbility();
  const [updateLocation] = useMutation(api.location.updateLocation);
  const canUpdate = ability.can('update', subject('Location', location));

  return (
    <Form
      schema={LocationUpdateSchema}
      onSubmit={async ({ use_company_sales_rep, sales_rep_id, sales_reps }, ctx) => {
        const values = { sales_rep_id: use_company_sales_rep || !sales_rep_id ? null : sales_rep_id, sales_reps };
        ForbiddenError.from(ability).throwUnlessCan('update', subject('Location', merge({}, location, values)));

        const res = await updateLocation([
          {
            locationId: location.id,
            updateLocationBody: values,
          },
        ]);
        ctx.reset(getLocationInitialValues(res.data));

        toast.notify({
          type: 'success',
          title: 'Success',
          message: "You've successfully updated the Location.",
        });
      }}
      initialValues={getLocationInitialValues(location)}
      className="u-mb-0"
      hasFloatingLabels
    >
      <section className="c-block c-block--spacing-t-extra-small c-block--spacing-b c-block--spacing-b-large@md">
        <div className="o-container-fluid">
          <div className="o-row">
            <div className="o-col-3@md">
              <p className="u-text-xs u-uppercase u-mb-spacer-base-small">General info</p>
              <p className="c-note">Edit the company location, then click Save Changes when you’re done.</p>
            </div>

            <div className="o-col-9@md">
              <div className="o-row o-row--small-gutters">
                <div className="o-col-8@lg">
                  <InputField name="_readOnly[name]" label="Location Name" elStyle="fill" disabled />
                </div>
                <div className="o-col-12">
                  <div className="c-admin-card c-admin-card--hor u-mb-spacer-base">
                    <div className="c-admin-card__column c-admin-card__column--large c-admin-card__column--first">
                      <p className="c-admin-card__label">Company:</p>
                      {typeof location.company !== 'undefined' ? (
                        <p className="u-text-neutral-600 u-font-medium">{location.company.name}</p>
                      ) : (
                        <p className="u-text-neutral-600 u-font-medium">&mdash;</p>
                      )}

                      <p className="c-admin-card__label">Address:</p>
                      <p className="u-text-neutral-600 u-font-medium u-mb-0">
                        <AddressText address={location} />
                      </p>
                    </div>

                    <div className="c-admin-card__column c-admin-card__column--small c-admin-card__column--last">
                      <LoadScript googleMapsApiKey={wordpressData.googleMapsApiKey}>
                        <MapAddresses addresses={[location]} />
                      </LoadScript>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="o-row">
            <div className="o-col-12">
              <hr className="u-mt-spacer-base u-mt-spacer-base-large@sm u-mb-spacer-base-large u-mb-spacer-section-small@sm" />
            </div>
          </div>

          <LocationSalesReps hasCompanySalesRep={Boolean(location.company?.sales_rep)} />

          <div className="o-row">
            <div className="o-col-12">
              <hr className="u-mt-spacer-base u-mt-spacer-base-large@sm u-mb-spacer-base-large u-mb-spacer-section-small@sm" />
            </div>
          </div>

          <div className="o-row">
            <div className="o-col-6@md o-offset-3@md">
              <div className="c-form-footer">
                <SubmitButton variant="secondary" isFull disabled={!canUpdate}>
                  Save changes
                </SubmitButton>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Form>
  );
};
