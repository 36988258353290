import * as React from 'react';

export const MultilineMessage: React.VFC<{ message: string }> = ({ message }) => {
  return (
    <>
      {message.split('\n\n').map((paragraph, paragraphIndex) => (
        <p key={`paragraph-${paragraphIndex}`}>
          {paragraph.split('\n').reduce<null | React.ReactNode[]>((lines, line, lineIndex) => {
            return lines === null ? [line] : [...lines, <br key={`line-break-${lineIndex}`} />, line];
          }, null)}
        </p>
      ))}
    </>
  );
};

export default MultilineMessage;
