import * as React from 'react';
import { Link } from 'react-router-dom';

import api from 'api';
import { useAbility } from 'auth';
import Icon from 'components/icon';
import { useQuery } from 'hooks/query';
import { CountPlaceholder } from 'my-account/components/CountPlaceholder';

interface AdminCardProps {
  count: number | undefined;
  label: [string, string];
  icon: string;
  listingUrl: string;
  addNewUrl?: string;
}

const AdminCardCountPlaceholder: React.VFC = () => {
  return <CountPlaceholder size={26} shade="e" />;
};

const _AdminCard: React.VFC<AdminCardProps> = ({ count, label, icon, listingUrl, addNewUrl }) => {
  const [singular, plural] = label;

  return (
    <div className="c-admin-card c-admin-card--hor">
      <div className="c-admin-card__icon">
        <Icon name={icon} />
      </div>
      <div className="c-admin-card__content">
        <p className="c-admin-card__title">
          {typeof count !== 'undefined' ? (
            <>
              <strong>{count}</strong>
            </>
          ) : (
            <AdminCardCountPlaceholder />
          )}{' '}
          {typeof count !== 'undefined' && count === 1 ? singular : plural}
        </p>
        {typeof addNewUrl !== 'undefined' && (
          <Link className="c-link-cta-basic c-link-cta--small" to={addNewUrl}>
            <Icon name="add" className="o-svg-icon" />
            <span>Add new</span>
          </Link>
        )}
      </div>
      <div className="c-admin-card__cta">
        <Link className="c-link-cta" to={listingUrl}>
          <span>View all</span>
          <Icon name="arrow" className="o-svg-icon o-svg-right" />
        </Link>
      </div>
    </div>
  );
};

const Users: React.VFC = () => {
  const ability = useAbility();
  const canListUsers = ability.can('list', 'User');
  const [users, usersQuery] = useQuery(
    api.user.listUsers,
    {
      page: 1,
      perPage: 6,
      sort: undefined,
      direction: 'asc',
    },
    { enabled: canListUsers }
  );

  if (!canListUsers) {
    return null;
  }

  return (
    <AdminCard
      count={users && usersQuery.isSuccess ? users.data.total : undefined}
      label={['User', 'Users']}
      icon="user"
      listingUrl="/users"
      addNewUrl={ability.can('create', 'User') ? '/users/add' : undefined}
    />
  );
};

const Locations: React.VFC = () => {
  const [locations, locationsQuery] = useQuery(api.location.listLocations, {
    page: 1,
    perPage: 6,
    sort: undefined,
    direction: 'asc',
    search: undefined,
  });

  return (
    <AdminCard
      count={locations && locationsQuery.isSuccess ? locations.data.total : undefined}
      label={['Location', 'Locations']}
      icon="location"
      listingUrl="/locations"
    />
  );
};

export const AdminCard = Object.assign(_AdminCard, {
  Users,
  Locations,
});
