import * as React from 'react';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import classNames from 'classnames';
import { useClickAway } from 'react-use';
import get from 'lodash/get';

import {
  TocPageType,
  SummaryPageType,
  // PdfPageType,
  CoverPageType,
  IntroductionPageType,
  DividerPageType,
  ProductPageType,
  NotesPageType,
  PageLayouts,
  ImageBlockType,
} from '__generated-api__';
import Icon from 'components/icon';
import { DisplayFieldErrors } from 'my-account/components/DisplayFieldError';
import {
  CoverPageDefaultValue,
  ProjectDefaultValuesType,
  getLocalPageId,
  ProjectVersionPageDefaultValue,
  ProjectVersionPageEmptyValue,
  ImageBlockDefaultValues,
} from 'my-account/validations/project';
import { getObjectKeys } from 'my-account/utils/object';
import { filterEmptyImageBlock } from 'spb-pdf/generator';

const pageTypeData: Record<
  Exclude<ProjectVersionPageDefaultValue['type'], '' | null | undefined>,
  { label: string; icon: string }
> = {
  [CoverPageType.Cover]: {
    label: 'Cover',
    icon: 'page-cover',
  },
  [IntroductionPageType.Introduction]: {
    label: 'Introduction',
    icon: 'page-introduction',
  },
  [ProductPageType.Product]: {
    label: 'Products',
    icon: 'page-products',
  },
  [TocPageType.Toc]: {
    label: 'Table of Content',
    icon: 'page-toc',
  },
  [SummaryPageType.Summary]: {
    label: 'Summary',
    icon: 'page-summary',
  },
  [NotesPageType.Notes]: {
    label: 'Notes',
    icon: 'page-notes',
  },
  [DividerPageType.Divider]: {
    label: 'Divider',
    icon: 'remove',
  },
  // [PdfPageType.Pdf]: {
  //   label: 'PDF',
  //   icon: 'item-pdf',
  // },
};

const SingleImageLayoutField: React.VFC<{ currentPagePreview: number }> = ({ currentPagePreview }) => {
  const { field } = useController<CoverPageDefaultValue, 'layout'>({
    name: `latest.pages.${currentPagePreview}.layout` as unknown as 'layout',
  });

  return (
    <div className="c-switch">
      <div className="c-switch__label">Layout</div>
      <div className="c-switch__ctas">
        <button
          type="button"
          className={classNames('c-switch__cta', { 'is-active': !field.value })}
          onClick={(event) => {
            event.preventDefault();
            field.onChange(null);
            field.onBlur();
          }}
        >
          <Icon name="layout-box-l" className="o-svg-icon" />
        </button>
        <button
          type="button"
          className={classNames('c-switch__cta', { 'is-active': field.value === PageLayouts.Alternate })}
          onClick={(event) => {
            event.preventDefault();
            field.onChange(PageLayouts.Alternate);
            field.onBlur();
          }}
        >
          <Icon name="layout-box-s" className="o-svg-icon" />
        </button>
      </div>
    </div>
  );
};

const CoverPageTwoImagesLayoutField: React.VFC<{ currentPagePreview: number }> = ({ currentPagePreview }) => {
  const { field } = useController<CoverPageDefaultValue, 'layout'>({
    name: `latest.pages.${currentPagePreview}.layout` as unknown as 'layout',
  });

  return (
    <div className="c-switch">
      <div className="c-switch__label">Layout</div>
      <div className="c-switch__ctas">
        <button
          type="button"
          className={classNames('c-switch__cta', { 'is-active': !field.value })}
          onClick={(event) => {
            event.preventDefault();
            field.onChange(null);
            field.onBlur();
          }}
        >
          <Icon name="layout-ver" className="o-svg-icon" />
        </button>
        <button
          type="button"
          className={classNames('c-switch__cta', { 'is-active': field.value === PageLayouts.Alternate })}
          onClick={(event) => {
            event.preventDefault();
            field.onChange(PageLayouts.Alternate);
            field.onBlur();
          }}
        >
          <Icon name="layout-hor" className="o-svg-icon" />
        </button>
      </div>
    </div>
  );
};

const PageLayoutOptions: React.VFC<{ currentPagePreview: number }> = ({ currentPagePreview }) => {
  const pages = useWatch<ProjectDefaultValuesType, 'latest.pages'>({ name: 'latest.pages' });
  const page = pages[currentPagePreview] ?? pages[0];
  if (!page.type) {
    return null;
  }

  if (page.type === CoverPageType.Cover && page.blocks) {
    if (page.blocks.filter(filterEmptyImageBlock).length < 2) {
      return <SingleImageLayoutField currentPagePreview={currentPagePreview} />;
    }

    if (page.blocks.filter(filterEmptyImageBlock).length === 2) {
      return <CoverPageTwoImagesLayoutField currentPagePreview={currentPagePreview} />;
    }
  }

  if (
    page.type === DividerPageType.Divider &&
    page.blocks &&
    page.blocks
      .filter((block): block is ImageBlockDefaultValues => block.type === ImageBlockType.Image)
      .filter(filterEmptyImageBlock).length
  ) {
    return <SingleImageLayoutField currentPagePreview={currentPagePreview} />;
  }

  if (page.type === NotesPageType.Notes && page.blocks) {
    return <SingleImageLayoutField currentPagePreview={currentPagePreview} />;
  }

  return null;
};

const PageTypeField: React.VFC<{ currentPagePreview: number }> = ({ currentPagePreview }) => {
  const pageName = useWatch<ProjectDefaultValuesType['latest']['pages'][0], 'name'>({
    name: `latest.pages.${currentPagePreview}.name` as unknown as 'name',
  });
  const pageType = useWatch<ProjectDefaultValuesType['latest']['pages'][0], 'type'>({
    name: `latest.pages.${currentPagePreview}.type` as unknown as 'type',
  });
  const { setValue, formState } = useFormContext<{
    page:
      | (Omit<ProjectVersionPageDefaultValue, 'blocks'> & { blocks?: null | undefined })
      | ProjectVersionPageEmptyValue;
  }>();
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  const onChange = (value: Exclude<ProjectDefaultValuesType['latest']['pages'][0]['type'], null | undefined>) => {
    if (pageType && pageType !== value) {
      if (window.confirm('Are you sure you want to switch page type?')) {
        setValue(`latest.pages.${currentPagePreview}` as 'page', {
          id: getLocalPageId(),
          name: pageName ?? '',
          type: value,
          blocks: null,
        });
      }
    } else {
      setValue(`latest.pages.${currentPagePreview}.type` as 'page.type', value);
    }
    setIsDropdownOpen(false);
  };

  useClickAway(wrapperRef, () => {
    setIsDropdownOpen(false);
  });

  return (
    <div ref={wrapperRef} className="u-relative">
      {/* @ts-ignore */}
      <DisplayFieldErrors errors={get(formState.errors, `latest.pages.${currentPagePreview}.type` as 'page.type')} />
      {!!pageType && (
        <button
          type="button"
          className={classNames('c-button c-button--white c-button--full c-button--select u-mb-spacer', {
            'is-active': isDropdownOpen,
          })}
          onClick={(event) => {
            event.preventDefault();
            setIsDropdownOpen((val) => !val);
          }}
        >
          <span>
            <small>Page template:</small> {pageTypeData[pageType].label}
          </span>
          <Icon name={pageTypeData[pageType].icon} className="o-svg-icon o-svg-large u-ml-auto" />
          <Icon name="chevron" className="o-svg-icon o-svg-smaller u-ml-spacer-base u-mr-0" />
        </button>
      )}
      <PageLayoutOptions currentPagePreview={currentPagePreview} />
      {(!pageType || isDropdownOpen) && (
        <div
          className={classNames('c-option-group c-option-group--split u-mb-spacer-base', {
            'c-option-group--boxed': pageType,
            'u-absolute': pageType,
          })}
          style={{ zIndex: 1 }}
        >
          <div className="c-option-group__heading">
            <span className="u-uppercase u-text-xxs">Select a template:</span>
            <hr className="u-my-spacer" />
          </div>
          {getObjectKeys(pageTypeData).map((pageTypeOption) => (
            <div key={pageTypeOption}>
              <button
                className={classNames(
                  'c-button c-button--white c-button--option c-button--full c-button--option c-button--option-square',
                  { 'c-button--option-selected': pageType === pageTypeOption }
                )}
                onClick={(event) => {
                  event.preventDefault();
                  onChange(pageTypeOption);
                }}
                type="button"
              >
                <Icon name={pageTypeData[pageTypeOption].icon} className="o-svg-icon o-svg-large" />
                <span>{pageTypeData[pageTypeOption].label}</span>
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PageTypeField;
