import * as React from 'react';
import { observer } from 'mobx-react';
import { SearchBox } from 'react-instantsearch-dom';
import wordpressData from 'data';
import ProductSearchStore from 'search/stores/product-search-store';
import SpriteIcon from 'search/components/sprite-icon';
import HitsPerPageDropdown from 'search/components/hits-per-page-dropdown';
import ToggleFiltersBtn from 'search/components/toggle-filters-btn';
import Pagination from 'search/components/pagination';
import Stats from 'search/components/stats';
import ProductHits from 'search/components/product-search/product-hits';
import SortByDropdown from 'search/components/product-search/sort-by-dropdown';
import AvailabilityFilter from 'search/components/product-search/availability-filter';
import BusinessSegmentFilter from 'search/components/product-search/business-segment-filter';
import CollectionFilter from 'search/components/product-search/collection-filter';
import ClearRefinements from 'search/components/product-search/clear-refinements';
import ToggleViewBtn from 'search/components/product-search/toggle-view-btn';
import FinishFilter from 'search/components/product-search/finish-filter';
import SpecialAttributeFilter from 'search/components/product-search/special-attribute-filter';
import ObsoleteFilter from 'search/components/product-search/obsolete-filter';
import FlowRateGpmFilter from 'search/components/product-search/flow-rate-gpm-filter';
import ShowerFilter from './shower-filter';
import AccessoriesFilter from './accessories-filter';
import FaucetsFilter from './faucets-filter';
import TubFaucetsTypeFilter from './tub-faucets-type-filter';
import ShowerValvesTypeFilter from './shower-valves-type-filter';
import MixingValvesTypeFilter from './mixing-valves-type-filter';
import RepairPartsFilter from './repair-parts-filter';
import EvolutionDevicesFilter from './evolution-devices-filter';

interface ProductSearchContentProps {
  store: ProductSearchStore;
  defaultSortBy: string;
  defaultHitsPerPage: number;
}

const ProductSearchContent = observer(
  class ProductSearch extends React.Component<ProductSearchContentProps> {
    render() {
      const { store, defaultSortBy, defaultHitsPerPage } = this.props;

      // console.log('ProductSearchContent::render::state', this.state);

      return (
        <>
          <section className="c-block c-block--spacing-t-extra-small c-block--spacing-t-small@md c-block--spacing-b-extra-small c-block--spacing-b@md c-block--higher">
            <div className="o-container-fluid">
              <div className="c-listing">
                <div className="c-listing__header u-justify-between@sm u-justify-start@lg">
                  <div className="c-listing__header-search u-hidden@lg">
                    <SearchBox
                      showLoadingIndicator={true}
                      onChange={(event) => {
                        console.log('onchange');
                      }}
                    />
                  </div>
                  <div className="c-listing__header-filter">
                    <ToggleFiltersBtn store={store} />
                    <ClearRefinements clearsQuery={true} />
                  </div>
                  <div className="c-listing__header-views">
                    <div className="c-listing__header-results">{store.hasResults && <Stats store={store} />}</div>
                    <div className="c-listing__header-controls">
                      {store.hasResults && (
                        <>
                          <SortByDropdown
                            defaultRefinement={defaultSortBy}
                            items={[
                              { value: `${wordpressData.algolia.productsIndex}`, label: 'Most relevant' },
                              { value: `${wordpressData.algolia.productsIndex}_price_asc`, label: 'Lowest price' },
                              { value: `${wordpressData.algolia.productsIndex}_price_desc`, label: 'Highest Price' },
                            ]}
                          />
                          <HitsPerPageDropdown
                            defaultRefinement={defaultHitsPerPage}
                            items={[
                              { value: 6, label: '6' },
                              { value: 12, label: '12' },
                              { value: 24, label: '24' },
                              { value: 48, label: '48' },
                            ]}
                          />
                          <ToggleViewBtn store={store} />
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="c-listing__filter-wrapper u-hidden@lg">
                  <div
                    className={[
                      'c-offcanvas',
                      'c-offcanvas--popup',
                      'c-offcanvas--site-nav',
                      store.showFilters ? 'is-active' : '',
                    ].join(' ')}
                  >
                    <div className="c-offcanvas__inner">
                      <span
                        className="c-offcanvas__toggle"
                        onClick={() => {
                          store.toggleShowFilters();
                        }}
                      >
                        <SpriteIcon name="close-thin" classes={['o-svg-icon']} />
                      </span>
                      <div className="c-offcanvas__header c-offcanvas__header--sm">
                        <span className="c-offcanvas__title">Filter products</span>
                      </div>
                      <div className="c-offcanvas__body">
                        <div className="c-filters">
                          <AvailabilityFilter store={store} />
                          {/* <CategoryFilter store={store} /> */}
                          <ShowerFilter store={store} />
                          <FaucetsFilter store={store} />
                          <TubFaucetsTypeFilter store={store} />
                          <AccessoriesFilter store={store} />
                          <FinishFilter store={store} />
                          <ShowerValvesTypeFilter store={store} />
                          <MixingValvesTypeFilter store={store} />
                          <CollectionFilter store={store} />
                          <RepairPartsFilter store={store} />
                          <FlowRateGpmFilter store={store} />
                          <SpecialAttributeFilter store={store} />
                          <BusinessSegmentFilter store={store} />
                          <EvolutionDevicesFilter store={store} />
                          {/* <NumberOfHandlesFilter store={store} />
                          <InstallationMountTypeFilter store={store} /> */}
                          <ObsoleteFilter store={store} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={['c-offcanvas__overlay', 'is-transparent', store.showFilters ? 'is-active' : ''].join(
                      ' '
                    )}
                    onClick={() => {
                      store.toggleShowFilters();
                    }}
                  ></div>
                </div>
                <div className="c-listing__row">
                  <div className="c-listing__sidebar">
                    <div className="c-listing__sidebar-container">
                      <div className="c-filters">
                        <div className="c-filters__group">
                          <div className="c-filters__container">
                            <SearchBox
                              showLoadingIndicator={true}
                              onChange={(event) => {
                                console.log('desktop onchange');
                                // if (event.currentTarget.value.toLowerCase().includes('readystock')) {
                                //   console.log('includes readystock!');
                                //   store.refineAvailabilities && store.refineAvailabilities(['ReadyStock™']);
                                // }
                              }}
                            />
                          </div>
                        </div>
                        <AvailabilityFilter store={store} />
                        {/* <CategoryFilter store={store} /> */}
                        <ShowerFilter store={store} />
                        <FaucetsFilter store={store} />
                        <TubFaucetsTypeFilter store={store} />
                        <AccessoriesFilter store={store} />
                        <FinishFilter store={store} />
                        <ShowerValvesTypeFilter store={store} />
                        <MixingValvesTypeFilter store={store} />
                        <CollectionFilter store={store} />
                        <RepairPartsFilter store={store} />
                        <FlowRateGpmFilter store={store} />
                        <SpecialAttributeFilter store={store} />
                        <BusinessSegmentFilter store={store} />
                        <EvolutionDevicesFilter store={store} />
                        {/* <NumberOfHandlesFilter store={store} />
                          <InstallationMountTypeFilter store={store} /> */}
                        <ObsoleteFilter store={store} />
                      </div>
                    </div>
                  </div>
                  <div className={['c-listing__content', store.hasResults ? '' : 'c-listing__none-wrapper'].join(' ')}>
                    {store.hasResults ? (
                      <>
                        <ProductHits store={store} />
                        <Pagination scrollToSelector="#product-search" />
                      </>
                    ) : (
                      <div className="c-listing__none">
                        <div className="c-listing__none-figure">
                          <SpriteIcon name="search-results" classes={['o-svg-icon']} />
                        </div>
                        <p className="c-listing__none-title">No results to show</p>
                        <p>Try tweaking the filters or searching for something more general.</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      );
    }
  }
);

export default ProductSearchContent;
