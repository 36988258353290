import * as React from 'react';
import classNames from 'classnames';
import { usePrevious } from 'react-use';
import { Controller } from 'react-hook-form';
import Tippy from '@tippyjs/react';

import Icon from './icon';

export const useAccordion = <ContainerElement extends HTMLElement = HTMLElement>(initialValue = false) => {
  const [isOpen, setIsOpen] = React.useState(initialValue);
  const contentRef = React.useRef<ContainerElement>(null);
  const prevValue = usePrevious(isOpen);

  const toggle = React.useCallback(() => {
    setIsOpen((val) => !val);
  }, []);

  React.useEffect(() => {
    if (contentRef.current) {
      if (!prevValue && isOpen) {
        jQuery(contentRef.current).slideDown();
      } else if (prevValue && !isOpen) {
        jQuery(contentRef.current).slideUp();
      }
    }
  }, [isOpen, contentRef, prevValue]);

  return {
    isOpen,
    toggle,
    contentRef,
  };
};

export interface AccordionProps {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  toggleActions?: React.ReactNode;
  type: 'card' | 'framed';
  isInvalid?: boolean;
  isRequired?: boolean;
  isPrimary?: boolean;
  initialValue?: boolean;
  onChange?: (value: boolean) => void;
  subtitlePosition?: 'above-title' | 'below-title';
}

export const Accordion: React.FC<AccordionProps> = ({
  title,
  subtitle,
  toggleActions,
  children,
  type,
  isInvalid,
  isRequired,
  isPrimary,
  initialValue,
  onChange,
  subtitlePosition = 'above-title',
}) => {
  const initialValueRef = React.useRef(initialValue);
  const { isOpen, toggle, contentRef } = useAccordion<HTMLDivElement>(initialValue);

  React.useEffect(() => {
    onChange?.(isOpen);
  }, [isOpen, onChange]);

  return (
    <div className={classNames('c-accordion', `c-accordion--${type}`, { 'is-invalid': isInvalid })}>
      <div
        className={classNames('c-accordion__container', {
          'is-open': isOpen,
          'is-empty': !children,
          'is-primary': isPrimary,
        })}
      >
        <div
          className="c-accordion-toggle-caret"
          onClick={(event) => {
            event.preventDefault();
            toggle();
          }}
        >
          <div>
            {typeof subtitle !== 'undefined' && subtitlePosition === 'above-title' && (
              <p className="c-accordion__subtitle">{subtitle}</p>
            )}
            <p className="c-accordion__title">
              {title}
              {isRequired && ' '}
              {isRequired && (
                <Tippy content="This section is required">
                  <span />
                </Tippy>
              )}
            </p>
            {typeof subtitle !== 'undefined' && subtitlePosition === 'below-title' && (
              <p className="c-accordion__subtitle">{subtitle}</p>
            )}
          </div>

          {toggleActions}
        </div>

        {Boolean(children) && (
          <div
            className="c-accordion__content"
            ref={contentRef}
            style={!initialValueRef.current ? { display: 'none' } : undefined}
          >
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

export const FiltersAccordion: React.FC<{ title: React.ReactNode }> = ({ title, children }) => {
  const { isOpen, toggle, contentRef: containerRef } = useAccordion<HTMLDivElement>();

  return (
    <div className={classNames('c-filters__group', { 'is-open': isOpen })}>
      <div
        className="c-filters__header c-accordion-toggle"
        onClick={(event) => {
          event.preventDefault();
          toggle();
        }}
      >
        <p className="c-filters__title">{title}</p>
      </div>
      <div className="c-filters__container c-accordion-container" ref={containerRef} style={{ display: 'none' }}>
        {children}
      </div>
    </div>
  );
};

export interface AccordionWithHideToggleProps extends Omit<AccordionProps, 'toggleActions'> {
  name: string;
  defaultValue?: boolean;
}

export const AccordionWithHideToggle: React.FC<AccordionWithHideToggleProps> = ({
  children,
  name,
  defaultValue,
  ...props
}) => {
  return (
    <Accordion
      {...props}
      toggleActions={
        <Controller
          name={name}
          defaultValue={defaultValue}
          render={({ field }) => {
            return (
              <div className="u-hidden u-block@sm">
                {/*eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  className={classNames('c-button c-button--option', {
                    'c-button--white': field.value,
                    'c-button--dark': !field.value,
                  })}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    field.onChange(!field.value);
                  }}
                >
                  <Icon name="eye" className="o-svg-icon o-svg-large" />
                  <span>{field.value ? 'Hide' : 'Hidden'}</span>
                </a>
              </div>
            );
          }}
        />
      }
      children={
        Boolean(children) ? (
          <>
            <Controller
              name={name}
              render={({ field }) => {
                return (
                  <div className="u-hidden@sm">
                    {/*eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      href="#"
                      className={classNames('c-button c-button--option', {
                        'c-button--white': field.value,
                        'c-button--dark': !field.value,
                      })}
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        field.onChange(!field.value);
                      }}
                    >
                      <Icon name="eye" className="o-svg-icon o-svg-large" />
                      <span>{field.value ? 'Hide' : 'Hidden'}</span>
                    </a>
                  </div>
                );
              }}
            />
            {children}
          </>
        ) : undefined
      }
    />
  );
};
