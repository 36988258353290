import * as React from 'react';
import { RefinementListProvided } from 'react-instantsearch-core';
import { connectRefinementList } from 'react-instantsearch-dom';
import Tippy from '@tippyjs/react';
import wordpressData from 'data';

interface CustomFinishRefinmentProps extends RefinementListProvided {}

class CustomFinishRefinment extends React.Component<CustomFinishRefinmentProps> {
  render() {
    // console.log('CustomFinishRefinment::render::props', this.props);

    const { items, createURL, refine } = this.props;
    return (
      <>
        <ul className="c-finishes c-finishes--hor">
          {items.map((item) => {
            const finishIconUrl = wordpressData.finishIcons[item.label];
            return (
              <li key={item.label} className={item.isRefined ? 'is-selected' : ''}>
                <Tippy content={item.label}>
                  <a
                    href={createURL(item.value)}
                    title={item.label}
                    onClick={(event) => {
                      event.preventDefault();
                      refine(item.value);
                    }}
                  >
                    <span
                      className="c-finishes__preview"
                      style={{
                        backgroundImage: finishIconUrl ? `url(${finishIconUrl})` : undefined,
                      }}
                    ></span>
                  </a>
                </Tippy>
              </li>
            );
          })}
        </ul>
      </>
    );
  }
}

const FinishRefinment = connectRefinementList(CustomFinishRefinment);

export default FinishRefinment;
