import { subject } from '@casl/ability';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { z } from 'zod';

import {
  AdminUserRoleEnum,
  AgencyRepUserRoleEnum,
  CompanyManagerUserRoleEnum,
  LineItemShippedStatus,
  LocationManagerUserRoleEnum,
  Order,
  OrderEventType,
  OrderStatus,
  RegularUserRoleEnum,
  RequestOrderChangeEventType,
  SalesRepUserRoleEnum,
} from '__generated-api__';
import api from 'api';
import { useAbility, useAuthenticatedUser } from 'auth';
import { IconDropdown, LinksListDropdown } from 'components/Dropdown';
import Form from 'components/Form/Form';
import InputField from 'components/Form/InputField';
import SubmitButton from 'components/Form/SubmitButton';
import Icon from 'components/icon';
import { useMutation, useQuery } from 'hooks/query';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { AddressText } from 'my-account/components/AddressText';
import { MainHero } from 'my-account/components/MainHero';
import { Modal } from 'my-account/components/Modal';
import MultilineMessage from 'my-account/components/MultilineMessage';
import { Block403Error } from 'my-account/pages/403page';
import { useToast } from 'my-account/toast';
import { DisplayPrice } from 'my-account/utils/currency';
import { ErrorMessagesContent } from 'my-account/utils/error-handler';
import { getUserName } from 'my-account/utils/user';
import { QuoteRow } from '../quotes';

const OrderViewStatus: React.VFC<{ order: Order }> = ({ order }) => {
  if (order.status === OrderStatus.Open) {
    return <p className="c-order-summary-info__title">Open</p>;
  }

  if (order.status === OrderStatus.Canceled) {
    return <p className="c-order-summary-info__title">Canceled</p>;
  }

  if (order.status === OrderStatus.Closed) {
    return (
      <p className="c-order-summary-info__title">
        <Icon name="checkmark" className="c-order-summary-info__icon" />
        Delivered
      </p>
    );
  }

  if (order.status === OrderStatus.Shipped) {
    return <p className="c-order-summary-info__title">Shipped</p>;
  }

  if (order.status === OrderStatus.ChangeRequested) {
    return <p className="c-order-summary-info__title c-order-summary-info__title--warning">Change requested</p>;
  }

  if (order.status === OrderStatus.ReturnRequested) {
    return <p className="c-order-summary-info__title c-order-summary-info__title--error">Return requested</p>;
  }

  if (order.status === OrderStatus.Changed) {
    return <p className="c-order-summary-info__title">Changed</p>;
  }

  if (order.status === OrderStatus.Returned) {
    return <p className="c-order-summary-info__title">Returned</p>;
  }

  return null;
};

const OrderEventTitles = {
  [OrderEventType.Created]: 'Order Submitted',
  [OrderEventType.Shipped]: 'Shipped',
  [OrderEventType.Delivered]: 'Delivered',
  [OrderEventType.Closed]: 'Closed',
  [OrderEventType.Canceled]: 'Canceled',
  [OrderEventType.Change]: 'Change Requested',
  [OrderEventType.Changed]: 'Changed',
  [OrderEventType.Return]: 'Return Requested',
  [OrderEventType.Returned]: 'Returned',
} as const;

const OrderViewStatusLog: React.VFC<{ order: Order }> = ({ order }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [orderEvents] = useQuery(api.order.listOrderEvents, { id: order.id });

  return (
    <>
      <button
        onClick={(event) => {
          event.preventDefault();
          setIsOpen(true);
        }}
        className="c-tag c-tag--stroke u-ml-spacer-base@sm u-text-black"
        type="button"
      >
        <Icon name="clock" /> Status Log
      </button>

      <Modal
        isOpen={isOpen}
        onRequestClose={() => {
          setIsOpen(false);
        }}
      >
        <div className="c-modal">
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */}
          <a
            href="#"
            onClick={(event) => {
              event.preventDefault();
              setIsOpen(false);
            }}
            className="c-modal-close"
          ></a>

          <div className="c-modal__main">
            <div className="c-block c-block--spacing-t-extra-small c-block--spacing-b-extra-small">
              <div className="o-container-fluid">
                <div className="o-row">
                  <div className="o-col-12">
                    <div className="c-block__header">
                      <h5 className="c-title">Order Status Log</h5>

                      <p>
                        Track the status of your order, and review the order details, quantity, pricing, and totals.
                      </p>

                      <h5 className="c-title c-title--xxs u-mt-spacer-base">
                        <strong>Order Activity</strong>
                      </h5>

                      {typeof orderEvents !== 'undefined' ? (
                        <ul className="c-log-timeline">
                          {orderEvents.data.data.map((orderEvent, index) => (
                            <li className="c-log-timeline__item" key={index}>
                              <div className="c-log-timeline__icon"></div>

                              <div className="c-log-timeline__main">
                                <p className="c-log-timeline__date">
                                  {format(parseISO(orderEvent.created_at), 'MMM d y')}
                                </p>

                                <p className="c-log-timeline__title">{OrderEventTitles[orderEvent.type]}</p>
                                {orderEvent.message && orderEvent.user && (
                                  <div className="c-log-timeline__message">
                                    <p className="c-log-timeline__meta">
                                      Author: <strong>{getUserName(orderEvent.user).name}</strong>
                                    </p>

                                    <MultilineMessage message={orderEvent.message} />
                                  </div>
                                )}
                              </div>
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const requestOrderChangeFormSchema = z.object({
  message: z.string().nonempty(),
});

const OrderView: React.VFC<{ order: Order }> = ({ order }) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const [requestOrderChange] = useMutation(api.order.changeOrder);
  const [openedModal, setOpenedModal] = React.useState<undefined | 'request_change' | 'request_return'>(undefined);
  const user = useAuthenticatedUser();

  const userHidePricing =
    user.direct_buyer &&
    (user.role === LocationManagerUserRoleEnum.LocationManager || user.role === RegularUserRoleEnum.User);

  return (
    <>
      <section className="c-block c-block--higher">
        <div className="o-container-fluid">
          <div className="o-row">
            <div className="o-col-12">
              <div className="c-order-summary u-mb-spacer-section-small u-mb-spacer-section@md">
                <header className="c-order-summary__header">
                  <ul className="c-order-summary-info">
                    <li className="c-order-summary-info__column">
                      <p className="c-order-summary-info__title">
                        {order.order_date ? (
                          format(utcToZonedTime(parseISO(order.order_date), 'America/New_York'), 'MMM d y')
                        ) : (
                          <>&mdash;</>
                        )}
                      </p>

                      <p className="c-order-summary-info__label">Order Date</p>
                    </li>

                    <li className="c-order-summary-info__column">
                      <p className="c-order-summary-info__title">{order.po_number}</p>

                      <p className="c-order-summary-info__label">Purchase Order #</p>
                    </li>

                    <li className="c-order-summary-info__column">
                      <div className="u-flex@sm u-items-center">
                        <OrderViewStatus order={order} />
                        <OrderViewStatusLog order={order} />
                      </div>

                      <p className="c-order-summary-info__label">Order Status</p>
                    </li>
                  </ul>

                  <div className="c-listing__header">
                    {(order.invoice_url !== null || order.packing_slip_url !== null) && (
                      <IconDropdown
                        buttonLabel="Documents"
                        menuLabel="Download"
                        options={Object.assign(
                          {},
                          order.invoice_url !== null &&
                            (!order.company?.direct_buyer ||
                              // If the company is a direct buyer, don't show the invoices option for location manager and below
                              user.role === CompanyManagerUserRoleEnum.CompanyManager ||
                              user.role === AdminUserRoleEnum.Admin ||
                              user.role === SalesRepUserRoleEnum.SalesRep ||
                              user.role === AgencyRepUserRoleEnum.AgencyRep)
                            ? {
                                invoices: (
                                  <>
                                    <Icon name="download" />
                                    Invoices
                                  </>
                                ),
                              }
                            : undefined,
                          order.packing_slip_url !== null
                            ? {
                                packing_slip: (
                                  <>
                                    <Icon name="download" />
                                    Packing slips
                                  </>
                                ),
                              }
                            : undefined
                        )}
                        onChange={(value) => {
                          if (value === 'invoices' && order.invoice_url !== null) {
                            window.open(order.invoice_url, '_blank');
                          }

                          if (value === 'packing_slip' && order.packing_slip_url !== null) {
                            window.open(order.packing_slip_url, '_blank');
                          }
                        }}
                      />
                    )}

                    <IconDropdown
                      buttonLabel="Actions"
                      menuLabel="Actions"
                      options={Object.assign(
                        {},
                        order.status !== OrderStatus.Closed &&
                          (!order.lines ||
                            !order.lines.length ||
                            order.lines.find((orderLine) => orderLine.shipped_status !== LineItemShippedStatus.Shipped))
                          ? {
                              request_change: 'Request Change',
                            }
                          : undefined,
                        (order.lines ?? []).find(
                          (orderLine) => orderLine.shipped_status === LineItemShippedStatus.Shipped
                        )
                          ? {
                              request_return: 'Request Return',
                            }
                          : undefined
                      )}
                      onChange={(value) => {
                        setOpenedModal(value);
                      }}
                    />
                  </div>
                </header>

                {order.quote && order.quote.id && (
                  <>
                    <section className="c-block c-block--spacing-b-small">
                      <div className="o-row">
                        <div className="o-col-3@md">
                          <p className="u-text-xs u-uppercase u-mb-spacer-base-small">Order</p>
                          <p className="c-note">
                            This order was created using the direct buyer order system. Here's a link to the original
                            order.
                          </p>
                        </div>

                        <div className="o-col-9@md o-col-9@xl">
                          <QuoteRow quote={order.quote} user={user} />
                        </div>
                      </div>
                    </section>
                  </>
                )}

                <table className="c-order-summary-table">
                  <thead>
                    <tr>
                      <th className="c-order-summary-table__column c-order-summary-table__column--first">Item</th>

                      <th className="c-order-summary-table__column c-order-summary-table__column--quantity">Qty</th>

                      {!userHidePricing && <th className="c-order-summary-table__column">Price</th>}

                      {!userHidePricing && <th className="c-order-summary-table__column">Total</th>}
                    </tr>
                  </thead>

                  <tbody>
                    {order.lines?.map((lineItem) => (
                      <tr className="c-order-summary-table__row" key={lineItem.id}>
                        <td className="c-order-summary-table__column c-order-summary-table__column--first">
                          <div className="c-order-summary-item">
                            <div>
                              {lineItem.main_image && (
                                <img
                                  src={lineItem.main_image}
                                  alt={lineItem.sku_description || lineItem.sku || String(lineItem.id)}
                                  className="c-order-summary-item__image"
                                />
                              )}
                            </div>

                            <div>
                              <p className="c-order-summary-item__title">{lineItem.sku_description}</p>
                              <p className="c-order-summary-item__subtitle">{lineItem.sku || lineItem.id}</p>

                              <ul className="c-order-summary-item__list">
                                {lineItem.shipped_status && <li>{lineItem.shipped_status}</li>}
                                {lineItem.related_assets.length > 0 && (
                                  <li>
                                    <LinksListDropdown
                                      label={'Documents'}
                                      description={'Download'}
                                      openInNewTab={true}
                                      options={lineItem.related_assets.map((asset) => {
                                        return { label: asset.type, URL: asset.url };
                                      })}
                                    />
                                  </li>
                                )}
                                {lineItem.shipped_status === LineItemShippedStatus.NotShipped &&
                                  lineItem.estimated_ship_date && (
                                    <li>
                                      Estimated Ship Date:{' '}
                                      {format(
                                        utcToZonedTime(parseISO(lineItem.estimated_ship_date), 'America/New_York'),
                                        'MMM d y'
                                      )}
                                    </li>
                                  )}
                                {lineItem.tracking_url && (
                                  <li>
                                    <a href={lineItem.tracking_url} target="_blank" rel="noreferrer">
                                      Track package <Icon name="export" />
                                    </a>
                                  </li>
                                )}
                              </ul>
                            </div>
                          </div>
                        </td>

                        <td className="c-order-summary-table__column c-order-summary-table__column--quantity">
                          <span className="c-order-summary-table__label">Qty</span>{' '}
                          {Number(lineItem.quantity).toLocaleString('en')}
                        </td>
                        {!userHidePricing && (
                          <td className="c-order-summary-table__column">
                            <span className="c-order-summary-table__label">Price</span>{' '}
                            <DisplayPrice
                              value={
                                Number(lineItem.extended_price) /
                                (Number(lineItem.quantity) > 0 ? Number(lineItem.quantity) : 1)
                              }
                            />
                          </td>
                        )}
                        {!userHidePricing && (
                          <td className="c-order-summary-table__column">
                            <span className="c-order-summary-table__label">Total</span>
                            <strong>
                              <DisplayPrice value={Number(lineItem.extended_price)} />
                            </strong>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
                {!userHidePricing && (
                  <footer className="c-order-summary-footer">
                    {order.charges?.map((charge) => (
                      <div className="c-order-summary-footer__row" key={charge.id}>
                        <div>
                          <p className="c-order-summary-footer__label">{charge.sku_description}</p>
                        </div>

                        <div>
                          <p className="c-order-summary-footer__value">
                            <DisplayPrice value={Number(charge.extended_price ?? 0)} />
                          </p>
                        </div>
                      </div>
                    ))}
                    {Number(order.sub_total) > 0 && (
                      <div className="c-order-summary-footer__row">
                        <div>
                          <p className="c-order-summary-footer__label">Subtotal</p>
                        </div>

                        <div>
                          <p className="c-order-summary-footer__value">
                            <DisplayPrice value={Number(order.sub_total ?? 0)} />
                          </p>
                        </div>
                      </div>
                    )}
                    {Number(order.sales_tax) > 0 && (
                      <div className="c-order-summary-footer__row">
                        <div>
                          <p className="c-order-summary-footer__label">Sales tax</p>
                        </div>

                        <div>
                          <p className="c-order-summary-footer__value">
                            <DisplayPrice value={Number(order.sales_tax ?? 0)} />
                          </p>
                        </div>
                      </div>
                    )}
                    <div className="c-order-summary-footer__row">
                      <div>
                        <p className="c-order-summary-footer__label">Order total</p>
                      </div>

                      <div>
                        <p className="c-order-summary-footer__value c-order-summary-footer__value--total">
                          <DisplayPrice value={Number(order.total ?? 0)} />
                        </p>
                      </div>
                    </div>
                  </footer>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="c-block u-mb-spacer-base-large u-mb-spacer-section-small@md">
        <div className="o-container-fluid">
          <div className="o-row">
            {order.company && (
              <div className="o-col-6@md">
                <div className="c-admin-card">
                  <div className="c-admin-card__content">
                    <p className="c-admin-card__subtitle">Billing Address</p>

                    <div className="u-text-xs">
                      <p>The following address will be used for billing purposes.</p>
                    </div>

                    <p className="u-mb-0">{order.company.name}</p>
                    <p className="u-font-medium u-mb-0">
                      <AddressText address={order.company.addresses[0]} />
                    </p>
                  </div>
                </div>
              </div>
            )}

            {order.location && (
              <div className="o-col-6@md">
                <div className="c-admin-card">
                  <div className="c-admin-card__content">
                    <p className="c-admin-card__subtitle">Shipping Address</p>

                    <div className="u-text-xs">
                      <p>The following address will be used for shipping purposes.</p>
                    </div>

                    <p className="u-mb-0">{order.location.name}</p>
                    <p className="u-font-medium u-mb-0">
                      <AddressText address={order.location} />
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>

      <Modal
        isOpen={openedModal === 'request_change'}
        onRequestClose={() => {
          setOpenedModal(undefined);
        }}
        id={`request_change_order_${order.id}`}
      >
        <div className="c-modal c-modal--contact">
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */}
          <a
            href="#"
            className="c-modal-close c-modal-close--invert"
            onClick={(event) => {
              event.preventDefault();
              setOpenedModal(undefined);
            }}
          />
          <div className="c-modal__main">
            <div className="c-block c-block--spacing-t-small c-block--spacing-b-small c-color--white">
              <div className="o-container-fluid">
                <div className="c-block__pattern-circle c-block__pattern--left c-block__pattern--light3 c-block__overlay--opacity-20">
                  <Icon name="pattern-circle" className="o-svg-icon" />
                </div>
                <div className="c-block__header">
                  <h5 className="c-title--large">
                    Request <strong>Change</strong>
                  </h5>
                  <p>
                    Enter your requested change into the text field below. Be sure to provide as much detail as
                    possible, such as quantity, finish, technical info, etc. When you’re done, click Send Request and
                    we’ll review it as soon as possible.
                  </p>
                </div>

                <Form
                  schema={requestOrderChangeFormSchema}
                  initialValues={{}}
                  onSubmit={async (data) => {
                    const res = await requestOrderChange([
                      {
                        id: order.id,
                        requestOrderChange: {
                          message: data.message,
                          type: RequestOrderChangeEventType.Change,
                        },
                      },
                    ]);

                    queryClient.setQueryData(api.order.getOrder.getQueryKey({ id: order.id }), res);

                    toast.notify({
                      type: 'success',
                      title: 'Success',
                      message: 'Order change request successfully sent.',
                    });

                    setOpenedModal(undefined);
                  }}
                  hasFloatingLabels
                >
                  <div className="o-row o-row--small-gutters">
                    <div className="o-col-12">
                      <h6>Submit a Request</h6>
                    </div>
                    <div className="o-col-12">
                      <InputField name="message" label="Message" type="textarea" elStyle="fill" />
                    </div>
                    <div className="o-col-12@sm">
                      <div className="c-form-footer">
                        <SubmitButton variant="secondary">Send request</SubmitButton>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={openedModal === 'request_return'}
        onRequestClose={() => {
          setOpenedModal(undefined);
        }}
        id={`request_return_order_${order.id}`}
      >
        <div className="c-modal c-modal--contact">
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */}
          <a
            href="#"
            className="c-modal-close c-modal-close--invert"
            onClick={(event) => {
              event.preventDefault();
              setOpenedModal(undefined);
            }}
          />
          <div className="c-modal__main">
            <div className="c-block c-block--spacing-t-small c-block--spacing-b-small c-color--white">
              <div className="o-container-fluid">
                <div className="c-block__pattern-circle c-block__pattern--left c-block__pattern--light3 c-block__overlay--opacity-20">
                  <Icon name="pattern-circle" className="o-svg-icon" />
                </div>
                <div className="c-block__header">
                  <h5 className="c-title--large">
                    Request <strong>Return</strong>
                  </h5>
                  <p>
                    Enter your requested return details into the text field below. Be sure to provide as much detail as
                    possible, such as quantity, finish, technical info, etc. When you’re done, click Send Request and
                    we’ll review it as soon as possible.
                  </p>
                </div>

                <Form
                  schema={requestOrderChangeFormSchema}
                  initialValues={{}}
                  onSubmit={async (data) => {
                    const res = await requestOrderChange([
                      {
                        id: order.id,
                        requestOrderChange: {
                          message: data.message,
                          type: RequestOrderChangeEventType.Return,
                        },
                      },
                    ]);

                    queryClient.setQueryData(api.order.getOrder.getQueryKey({ id: order.id }), res);

                    toast.notify({
                      type: 'success',
                      title: 'Success',
                      message: 'Order return request successfully sent.',
                    });

                    setOpenedModal(undefined);
                  }}
                  hasFloatingLabels
                >
                  <div className="o-row o-row--small-gutters">
                    <div className="o-col-12">
                      <h6>Submit a Request</h6>
                    </div>
                    <div className="o-col-12">
                      <InputField name="message" label="Message" type="textarea" elStyle="fill" />
                    </div>
                    <div className="o-col-12@sm">
                      <div className="c-form-footer">
                        <SubmitButton variant="secondary">Send request</SubmitButton>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default function OrderViewPage() {
  const { id } = useParams<{ id?: string }>();
  const ability = useAbility();
  const [orderRes, getOrderState] = useQuery(api.order.getOrder, { id: Number(id) });
  useDocumentTitle(
    typeof orderRes !== 'undefined' && getOrderState.isSuccess
      ? `${orderRes.data.type} #${orderRes.data.number}`
      : 'Order'
  );

  return (
    <>
      <MainHero />

      <section className="c-block c-block--hero">
        <div className="o-container-fluid">
          <div className="o-row u-flex-grow u-items-end">
            <div className="o-col-12">
              <Link
                to="/orders"
                className="c-link-cta-basic c-link-cta--small c-block__get-back u-mt-spacer-base-large u-mb-spacer-base-small"
              >
                <Icon name="arrow" className="o-svg-icon o-svg-left" />
                <span>Back to orders</span>
              </Link>
              <h1 className="c-title--medium u-mb-0">
                {typeof orderRes !== 'undefined' && getOrderState.isSuccess
                  ? `${orderRes.data.type} #${orderRes.data.number}`
                  : 'Order'}
              </h1>
            </div>
          </div>
        </div>
        <div className="c-block__pattern c-block__pattern--light3 c-block__pattern--top3 c-block__overlay--opacity-20">
          <Icon name="pattern" className="o-svg-icon" />
        </div>
      </section>

      {getOrderState.isLoading && (
        <div className="c-listing__none">
          <div className="c-listing__none-spinner"></div>
          <p className="c-listing__none-title">Loading...</p>
        </div>
      )}
      {getOrderState.isError && (
        <div className="o-container-fluid u-pb-spacer-section">
          <div className="o-row u-flex-grow u-items-center">
            <div className="o-col-12">
              <div className="c-listing__none">
                <div className="c-listing__none-figure">
                  <Icon name="error" className="o-svg-icon" />
                </div>
                <p className="c-listing__none-title">Error</p>
                <ErrorMessagesContent error={getOrderState.error} />
              </div>
            </div>
          </div>
        </div>
      )}
      {typeof orderRes !== 'undefined' && getOrderState.isSuccess && (
        <>
          {ability.can('read', subject('Order', orderRes.data)) ? (
            <OrderView order={orderRes.data} />
          ) : (
            <Block403Error />
          )}
        </>
      )}
    </>
  );
}
