import * as React from 'react';

import { ProductRelationWithQuotePivotData, Quote } from '__generated-api__';
import { IProjectOrderRequestSchema, IProjectQuoteRequestSchema } from 'my-account/components/PendingQuote/schema';

export type AddProductData = Pick<
  ProductRelationWithQuotePivotData,
  'sku' | 'title' | 'price' | 'main_image' | 'installation_guide'
> & { quantity: number };

export interface PendingQuoteContextValue {
  canCreateQuote: boolean;
  canAddToQuote: boolean;
  quote?: Quote;
  addProduct: (data: AddProductData) => Promise<void>;
  updateProducts: (products: ProductRelationWithQuotePivotData[]) => Promise<void>;
  requestQuote: (data: IProjectQuoteRequestSchema) => Promise<void>;
  requestOrder: (data: IProjectOrderRequestSchema) => Promise<void>;
  isLoading: boolean;
  openModal: (options?: { hasConfirmation?: boolean }) => void;
  closeModal: () => void;
  addCustomPrice: (sku: string, price: number) => void;
}

const pendingQuoteContext = React.createContext<PendingQuoteContextValue>({
  canCreateQuote: false,
  canAddToQuote: false,
  addProduct: () => {
    return Promise.reject(new Error('Invalid!'));
  },
  updateProducts: () => {
    return Promise.reject(new Error('Invalid!'));
  },
  requestQuote: () => {
    return Promise.reject(new Error('Invalid!'));
  },
  requestOrder: () => {
    return Promise.reject(new Error('Invalid!'));
  },
  isLoading: false,
  openModal: () => {
    throw new Error('Invalid!');
  },
  closeModal: () => {
    throw new Error('Invalid!');
  },
  addCustomPrice: () => {
    throw new Error('Invalid!');
  },
});

export const PendingQuoteContextProvider = pendingQuoteContext.Provider;
export const usePendingQuote = () => {
  return React.useContext(pendingQuoteContext);
};
