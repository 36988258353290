import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Prompt } from 'react-router';

export const UnsavedChangesPrompt = () => {
  const {
    formState: { isDirty },
  } = useFormContext();

  const message = 'Do you really want to leave? You have unsaved changes!';

  useEffect(() => {
    if (isDirty) {
      const handler = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        return (event.returnValue = message);
      };

      window.addEventListener('beforeunload', handler, { capture: true });

      return () => {
        window.removeEventListener('beforeunload', handler, { capture: true });
      };
    }
  }, [isDirty]);

  return <Prompt when={isDirty} message={message} />;
};

export default UnsavedChangesPrompt;
