import * as React from 'react';
import { LinkProps } from 'react-router-dom';
import { Location } from 'history';
import { useMergeRefs } from 'hooks/useMergeRefs';
import wordpressData from 'data';

function isModifiedEvent(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

export interface LinkAnchorProps extends Omit<LinkProps, 'component' | 'replace' | 'to' | 'innerRef'> {
  innerRef?: React.Ref<HTMLAnchorElement | null>;
  href: string;
  navigate: () => void;
}

export const useLinkAnchorProps = (
  { navigate, onClick, innerRef, ...rest }: LinkAnchorProps,
  forwardedRef: React.ForwardedRef<HTMLAnchorElement>
) => {
  const anchorRef = useMergeRefs(forwardedRef, innerRef);
  const { target } = rest;

  const anchorProps: JSX.IntrinsicElements['a'] = {
    ...rest,
    onClick: (event) => {
      try {
        if (onClick) onClick(event);
      } catch (ex) {
        event.preventDefault();
        throw ex;
      }

      if (
        !event.defaultPrevented && // onClick prevented default
        event.button === 0 && // ignore everything but left clicks
        (!target || target === '_self') && // let browser handle "target=_blank" etc.
        !isModifiedEvent(event) // ignore clicks with modifier keys
      ) {
        event.preventDefault();
        navigate();
      }
    },
    ref: anchorRef,
  };

  const anchorClassNames = anchorProps.className?.split(' ') ?? [];
  const isActive = anchorClassNames.includes('is-current');
  anchorProps.className = anchorClassNames.filter((item) => item !== 'is-current').join(' ');

  const out: [JSX.IntrinsicElements['a'], { isActive: boolean }] = [anchorProps, { isActive }];

  return out;
};

export const setLocationSearchParam = (location: Location, key: string, value: string | undefined) => {
  const newSearchParams = new URLSearchParams(location.search);
  if (typeof value !== 'undefined') {
    newSearchParams.set(key, value);
  } else {
    newSearchParams.delete(key);
  }

  return { ...location, search: newSearchParams.toString() };
};

export const setLocationSearchParams = (location: Location, params: Record<string, string | string[] | undefined>) => {
  const newSearchParams = new URLSearchParams(location.search);

  for (const [key, value] of Object.entries(params)) {
    if (typeof value !== 'undefined') {
      if (Array.isArray(value)) {
        newSearchParams.delete(key);
        value.forEach((val) => newSearchParams.append(key, val));
      } else {
        newSearchParams.set(key, value);
      }
    } else {
      newSearchParams.delete(key);
    }
  }

  return { ...location, search: newSearchParams.toString() };
};

export const routerBasename = wordpressData
  ? wordpressData.myAccountUrl.replace(wordpressData.siteUrl, '').replace(/\/$/, '')
  : '';
