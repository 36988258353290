import * as React from 'react';
import { observer } from 'mobx-react';
import { SearchBox } from 'react-instantsearch-dom';
import DocumentSearchStore from 'search/stores/document-search-store';
import SpriteIcon from 'search/components/sprite-icon';
import HitsPerPageDropdown from 'search/components/hits-per-page-dropdown';
import ToggleFiltersBtn from 'search/components/toggle-filters-btn';
import Pagination from 'search/components/pagination';
import Stats from 'search/components/stats';
import DocumentHits from 'search/components/documents-search/document-hits';
import ClearRefinements from 'search/components/documents-search/clear-refinements';
import DocumentTypeFilter from 'search/components/documents-search/document-type-filter';

interface DocumentSearchContentProps {
  store: DocumentSearchStore;
  defaultHitsPerPage: number;
}

const DocumentSearchContent = observer(
  class DocumentSearchContent extends React.Component<DocumentSearchContentProps> {
    render() {
      const { store, defaultHitsPerPage } = this.props;

      // console.log('DocumentSearchContent::render::state', this.state);

      return (
        <>
          <section className="c-block c-block--spacing-t-extra-small c-block--spacing-t-small@md c-block--spacing-b-extra-small c-block--spacing-b@md c-block--higher">
            <div className="o-container-fluid">
              <div className="c-listing">
                <div className="c-listing__header">
                  <div className="c-listing__header-filter">
                    <ToggleFiltersBtn store={store} />
                    <ClearRefinements clearsQuery={true} />
                  </div>
                  <div className="c-listing__header-views">
                    <div className="c-listing__header-results">{store.hasResults && <Stats store={store} />}</div>
                    <div className="c-listing__header-controls">
                      {store.hasResults && (
                        <>
                          <HitsPerPageDropdown
                            defaultRefinement={defaultHitsPerPage}
                            items={[
                              { value: 6, label: '6' },
                              { value: 12, label: '12' },
                              { value: 24, label: '24' },
                              { value: 48, label: '48' },
                            ]}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="c-listing__filter-wrapper u-hidden@lg">
                  <div
                    className={[
                      'c-offcanvas',
                      'c-offcanvas--popup',
                      'c-offcanvas--site-nav',
                      store.showFilters ? 'is-active' : '',
                    ].join(' ')}
                  >
                    <div className="c-offcanvas__inner">
                      <span
                        className="c-offcanvas__toggle"
                        onClick={() => {
                          store.toggleShowFilters();
                        }}
                      >
                        <SpriteIcon name="close-thin" classes={['o-svg-icon']} />
                      </span>
                      <div className="c-offcanvas__header c-offcanvas__header--sm">
                        <span className="c-offcanvas__title">Filter documents</span>
                      </div>
                      <div className="c-offcanvas__body">
                        <div className="c-filters">
                          <div className="c-filters__group">
                            <div className="c-filters__container">
                              <SearchBox showLoadingIndicator={true} />
                            </div>
                          </div>
                          <DocumentTypeFilter store={store} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={['c-offcanvas__overlay', 'is-transparent', store.showFilters ? 'is-active' : ''].join(
                      ' '
                    )}
                    onClick={() => {
                      store.toggleShowFilters();
                    }}
                  ></div>
                </div>
                <div className="c-listing__row">
                  <div className="c-listing__sidebar">
                    <div className="c-listing__sidebar-container">
                      <div className="c-filters">
                        <div className="c-filters__group">
                          <div className="c-filters__container">
                            <SearchBox showLoadingIndicator={true} />
                          </div>
                        </div>
                        <DocumentTypeFilter store={store} />
                      </div>
                    </div>
                  </div>
                  <div className={['c-listing__content', store.hasResults ? '' : 'c-listing__none-wrapper'].join(' ')}>
                    {store.hasResults ? (
                      <>
                        <DocumentHits />
                        <Pagination scrollToSelector="#document-search" />
                      </>
                    ) : (
                      <div className="c-listing__none">
                        <div className="c-listing__none-figure">
                          <SpriteIcon name="search-results" classes={['o-svg-icon']} />
                        </div>
                        <p className="c-listing__none-title">No results to show</p>
                        <p>Try tweaking the filters or searching for something more general.</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      );
    }
  }
);

export default DocumentSearchContent;
