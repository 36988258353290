import * as React from 'react';
import axios from 'axios';

import Icon from 'components/icon';
import { DisplayReactQueryError } from 'my-account/components/DisplayFieldError';

const UploadImageModalContent: React.VFC<{ open: () => void; error: unknown }> = ({ open, error }) => {
  return (
    <div className="c-block c-block--spacing-t-extra-small c-block--spacing-b-small">
      <div className="o-container-fluid">
        <div className="o-row">
          <div className="o-col-12">
            <div className="c-block__header c-block__header--hor">
              <div className="c-block__header-content u-items-center u-block u-flex@sm">
                <div>
                  <h4 className="u-mb-spacer-base-large">Add Media File</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="o-row">
          <div className="o-col-12">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href="#"
              className="c-add-media u-mb-spacer-base"
              onClick={(event) => {
                event.preventDefault();
                open();
              }}
            >
              <div>
                <Icon name="upload" />
              </div>

              <div>
                <button className="c-link-cta-basic c-link-cta--small" type="button">
                  <span>Drop file here or click to upload</span>
                </button>
              </div>
            </a>
            <p className="c-note">The accepted media file formats are JPG, JPEG, PNG, GIF, TIF, and TIFF.</p>
            {error && !axios.isCancel(error) && <DisplayReactQueryError error={error} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadImageModalContent;
