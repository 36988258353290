import api from 'api';
import { SelectListingItemProps, SelectListingItem } from 'my-account/components/SelectListingItem';
import { getUserName } from 'my-account/utils/user';

export interface SelectUserProps<IsMulti extends boolean = false>
  extends Omit<
    SelectListingItemProps<typeof api.user.listUsers, IsMulti>,
    'queryFn' | 'getOptionLabel' | 'getOptionValue' | 'queryParams'
  > {
  queryParams?: SelectListingItemProps<typeof api.user.listUsers, IsMulti>['queryParams'];
}

export const SelectUser = <IsMulti extends boolean = false>({
  queryParams = {},
  ...props
}: SelectUserProps<IsMulti>) => {
  return (
    <SelectListingItem
      {...props}
      queryFn={api.user.listUsers}
      queryParams={{ sort: 'first_name', direction: 'asc', ...queryParams }}
      getOptionLabel={(user) => getUserName(user).name}
      getOptionValue={(user) => String(user.id)}
    />
  );
};
