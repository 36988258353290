import { Font } from '@react-pdf/renderer';
import GothamRounded_Light from 'assets/fonts/gotham-rounded/GothamRounded-Light.ttf';
import GothamRounded_LightItalic from 'assets/fonts/gotham-rounded/GothamRounded-LightItalic.ttf';
import GothamRounded_Book from 'assets/fonts/gotham-rounded/GothamRounded-Book.ttf';
import GothamRounded_BookItalic from 'assets/fonts/gotham-rounded/GothamRounded-BookItalic.ttf';
import GothamRounded_Medium from 'assets/fonts/gotham-rounded/GothamRounded-Medium.ttf';
import GothamRounded_MediumItalic from 'assets/fonts/gotham-rounded/GothamRounded-MediumItalic.ttf';
import GothamRounded_Bold from 'assets/fonts/gotham-rounded/GothamRounded-Bold.ttf';
import GothamRounded_BoldItalic from 'assets/fonts/gotham-rounded/GothamRounded-BoldItalic.ttf';

export function registerFonts() {
  Font.register({
    family: 'Gotham Rounded',
    fontWeight: 200,
    fontStyle: 'normal',
    src: GothamRounded_Light,
  });

  Font.register({
    family: 'Gotham Rounded',
    fontWeight: 200,
    fontStyle: 'italic',
    src: GothamRounded_LightItalic,
  });

  Font.register({
    family: 'Gotham Rounded',
    fontWeight: 400,
    fontStyle: 'normal',
    src: GothamRounded_Book,
  });

  Font.register({
    family: 'Gotham Rounded',
    fontWeight: 400,
    fontStyle: 'italic',
    src: GothamRounded_BookItalic,
  });

  Font.register({
    family: 'Gotham Rounded',
    fontWeight: 600,
    fontStyle: 'normal',
    src: GothamRounded_Medium,
  });

  Font.register({
    family: 'Gotham Rounded',
    fontWeight: 600,
    fontStyle: 'italic',
    src: GothamRounded_MediumItalic,
  });

  Font.register({
    family: 'Gotham Rounded',
    fontWeight: 700,
    fontStyle: 'normal',
    src: GothamRounded_Bold,
  });

  Font.register({
    family: 'Gotham Rounded',
    fontWeight: 700,
    fontStyle: 'italic',
    src: GothamRounded_BoldItalic,
  });
}
