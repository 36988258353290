import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import reactPdf from '@react-pdf/renderer';
import debounce from 'lodash/debounce';
import { AxiosResponse } from 'axios';

import { Project, ProjectVersion } from '__generated-api__';
import api from 'api';
import { useMutation } from 'hooks/query';
import Icon from 'components/icon';
import { Button } from 'components/Button';
import { PreviewSPBPdfDocument } from 'spb-pdf/generator';
import { ProjectDefaultValuesType } from 'my-account/validations/project';
import { click } from 'my-account/utils/dom';
import { useErrorHandler } from 'my-account/utils/error-handler';

const ViewPreviewButton: React.VFC<{ project: Project | undefined }> = ({ project }) => {
  const { getValues } = useFormContext<ProjectDefaultValuesType>();
  const [isGeneratingPreview, setIsGeneratingPreview] = React.useState(false);

  return (
    <Button
      variant="white"
      isFull
      className="c-button--option u-mb-spacer"
      type="button"
      onClick={async (event) => {
        event.preventDefault();
        setIsGeneratingPreview(true);
        const previewDocument = (
          <PreviewSPBPdfDocument
            project={getValues()}
            effectiveDate={format(project && project.updated_at ? parseISO(project.updated_at) : new Date(), 'MMM d y')}
            user={project?.user}
          />
        );
        const pdfDocument = reactPdf.pdf(previewDocument);
        const openPreview = debounce(async () => {
          pdfDocument.removeListener('change', openPreview);
          const documentBlob = await pdfDocument.toBlob();
          const file = new Blob([documentBlob], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.rel = 'noopener';
          link.href = URL.createObjectURL(file);
          link.target = '_blank';
          setTimeout(() => {
            URL.revokeObjectURL(link.href);
          }, 360000);
          setTimeout(() => {
            click(link);
          }, 0);
          setIsGeneratingPreview(false);
        }, 2000);

        pdfDocument.on('change', openPreview);
        openPreview();
      }}
      leftIcon={<Icon name="glasses" className="o-svg-icon o-svg-large" />}
      isLoading={isGeneratingPreview}
    >
      View Preview
    </Button>
  );
};

export const ViewPublishedDocumentButton: React.VFC<{ project: Project; projectVersion: ProjectVersion }> = ({
  project,
  projectVersion,
}) => {
  const errorHandler = useErrorHandler();
  const [isGeneratingPreview, setIsGeneratingPreview] = React.useState(false);
  const [downloadPublicProjectVersion] = useMutation(api.project.downloadPublicProjectVersion, {
    axiosRequestConfig: { responseType: 'blob', timeout: 30000 },
  });

  return (
    <Button
      variant="white"
      isFull
      className="c-button--option u-mb-spacer"
      type="button"
      onClick={async (event) => {
        event.preventDefault();
        setIsGeneratingPreview(true);
        errorHandler.clearErrors();
        try {
          const res = (await downloadPublicProjectVersion([
            { publicToken: project.public_token ?? '', versionId: projectVersion.id.toString() },
          ])) as unknown as AxiosResponse<Blob>;
          const file = new Blob([res.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.rel = 'noopener';
          link.href = URL.createObjectURL(file);
          link.target = '_blank';
          setTimeout(() => {
            URL.revokeObjectURL(link.href);
          }, 360000);
          setTimeout(() => {
            click(link);
          }, 0);
        } catch (error) {
          errorHandler.handleError(error);
        }
        setIsGeneratingPreview(false);
      }}
      leftIcon={<Icon name="glasses" className="o-svg-icon o-svg-large" />}
      isLoading={isGeneratingPreview}
    >
      View Preview
    </Button>
  );
};

export default ViewPreviewButton;
