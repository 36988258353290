/**
 * link.click() does not work in all browsers
 * @link https://github.com/eligrey/FileSaver.js/blob/b5e61ec88969461ce0504658af07c2b56650ee8c/src/FileSaver.js#L58
 */
export const click = (node: HTMLElement) => {
  try {
    node.dispatchEvent(new MouseEvent('click'));
  } catch (e) {
    var evt = document.createEvent('MouseEvents');
    evt.initMouseEvent('click', true, true, window, 0, 0, 0, 80, 20, false, false, false, false, 0, null);
    node.dispatchEvent(evt);
  }
};
