import * as React from 'react';
import { z } from 'zod';
import { useController } from 'react-hook-form';
import classNames from 'classnames';

import Icon from 'components/icon';
import { FormProvider, useForm } from 'components/Form/Form';
import EditorField, { useEditorFieldValue } from 'components/Form/EditorField';
import SubmitButton from 'components/Form/SubmitButton';
import { Modal } from 'my-account/components/Modal';
import { DisplayFieldErrors } from 'my-account/components/DisplayFieldError';
import { TextBlock } from 'my-account/validations/project';
import { truncate } from 'my-account/utils/text';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

type TextBlockFieldType = z.infer<typeof TextBlock>;
export interface TextBlockFieldProps {
  name: string;
  title: string;
  buttonProps?: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
}

type TextBlockDefaultValues = Pick<TextBlockFieldType, 'id' | 'type'> & Partial<Pick<TextBlockFieldType, 'content'>>;

const TextBlockFieldModalForm: React.VFC<{
  value: TextBlockDefaultValues;
  onChange: (value: TextBlockDefaultValues) => void;
  onDirtyChange: (value: boolean) => void;
}> = ({ value, onChange, onDirtyChange }) => {
  const { context, formProps } = useForm({
    initialValues: { ...value, content: value.content && value.content.content ? value.content : { content: '' } },
    schema: TextBlock,
    stopSubmitPropagation: true,
    onSubmit: async (values) => {
      onChange(values);
    },
  });

  React.useEffect(() => {
    onDirtyChange(context.formState.isDirty);
  }, [context.formState.isDirty, onDirtyChange]);

  return (
    <FormProvider {...context}>
      <form {...formProps}>
        <EditorField name="content.content" label="Content" placeholder="Write something here..." />
        <SubmitButton>Save</SubmitButton>
      </form>
    </FormProvider>
  );
};

export const SortableTextBlockField: React.VFC<TextBlockFieldProps & { id: string }> = ({ id, ...props }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <TextBlockField
      {...props}
      buttonProps={{ ref: setNodeRef, style, ...attributes, ...listeners, ...props.buttonProps }}
    />
  );
};

const TextBlockField: React.VFC<TextBlockFieldProps> = ({ title, name, buttonProps }) => {
  const { field, fieldState } = useController<{ block: TextBlockDefaultValues }, 'block'>({
    name: name as 'block',
  });
  const [isFormDirty, setIsFormDirty] = React.useState(false);
  const [isEditorModalOpen, setIsEditorModalOpen] = React.useState(false);
  const { textContent } = useEditorFieldValue(field.value.content?.content ?? '');

  const onClose = () => {
    if (isFormDirty) {
      if (window.confirm('Do you really want to leave? You have unsaved changes!')) {
        setIsEditorModalOpen(false);
        field.onBlur();
      }
    } else {
      setIsEditorModalOpen(false);
      field.onBlur();
    }
  };

  return (
    <>
      <button
        {...buttonProps}
        className={classNames(
          'c-button c-button--white c-button--option c-button--full c-button--option c-button--option-drag u-mb-spacer',
          {
            'is-invalid': typeof fieldState.error !== 'undefined',
          }
        )}
        type="button"
        onClick={(event) => {
          event.preventDefault();
          setIsEditorModalOpen(true);
        }}
      >
        <span>
          <small>{title}:</small>
          {typeof field.value.content !== 'undefined' &&
          typeof field.value.content.content !== 'undefined' &&
          field.value.content.content !== null &&
          field.value.content.content !== '' ? (
            truncate(
              textContent
                .trim()
                .split('\n')
                .filter((val) => val.length)[0] ?? '',
              24
            )
          ) : (
            <>
              Add <Icon name="add-thin" className="o-svg-icon" />
            </>
          )}
        </span>
        <Icon name="pencil" className="o-svg-icon o-svg-large u-ml-auto" />
      </button>
      <DisplayFieldErrors errors={fieldState.error} />
      <Modal isOpen={isEditorModalOpen} onRequestClose={onClose}>
        <div className="c-modal" style={{ width: '100%' }}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */}
          <a
            href="#"
            className="c-modal-close"
            onClick={(event) => {
              event.preventDefault();
              onClose();
            }}
          />

          <div className="c-modal__main">
            <div className="c-block c-block--spacing-t-small c-block--spacing-b-small">
              <div className="o-container-fluid">
                <div className="o-row">
                  <div className="o-col-12">
                    <div className="c-block__header c-block__header--hor">
                      <div className="c-block__header-content u-items-center u-block u-flex@sm">
                        <div>
                          <h6 className="c-headline u-text-neutral-500">{title}</h6>
                          <h4 className="u-mb-spacer-base-large">Edit Content</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="o-col-12">
                    {isEditorModalOpen && (
                      <TextBlockFieldModalForm
                        value={field.value}
                        onChange={(value) => {
                          field.onChange(value);
                          setIsEditorModalOpen(false);
                          setIsFormDirty(false);
                        }}
                        onDirtyChange={setIsFormDirty}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TextBlockField;
