import { Link, useParams } from 'react-router-dom';
import { subject } from '@casl/ability';

import { ProjectVersionStatus } from '__generated-api__';
import api from 'api';
import { useAbility } from 'auth';
import { useQuery } from 'hooks/query';
import Icon from 'components/icon';
import { MainHero } from 'my-account/components/MainHero';
import { Block403Error } from 'my-account/pages/403page';
import { ErrorMessagesContent } from 'my-account/utils/error-handler';
import { EditProjectForm } from 'my-account/forms/Project';
import { getProjectVersion } from 'my-account/utils/project';
import useDocumentTitle from 'hooks/useDocumentTitle';

export default function SpecificationPackageEditPage() {
  const { id, version } = useParams<{ id?: string; version?: string }>();
  const ability = useAbility();
  const [projectRes, getProjectState] = useQuery(
    api.project.getProject,
    { id: Number(id) },
    {
      refetchInterval: (data, query) => {
        const { projectVersion } = getProjectVersion(data?.data, version);

        if (projectVersion?.status === ProjectVersionStatus.Publishing) {
          return 2000;
        }

        return false;
      },
    }
  );

  const { projectVersion } = getProjectVersion(projectRes?.data, version);

  useDocumentTitle(projectVersion?.name ? `Edit ${projectVersion.name} Project` : 'Edit Project');

  const canViewProjectsList = ability.can('list', 'Project');

  return (
    <>
      <MainHero />

      <section className="c-block c-block--hero">
        <div className="o-container-fluid">
          <div className="o-row u-flex-grow u-items-end">
            <div className="o-col-12">
              {canViewProjectsList && (
                <Link
                  to="/specification-packages"
                  className="c-link-cta-basic c-link-cta--small c-block__get-back u-mt-spacer-base-large u-mb-spacer-base-small"
                >
                  <Icon name="arrow" className="o-svg-icon o-svg-left" />
                  <span>Back to specification packages</span>
                </Link>
              )}
              <h1 className="c-title--medium u-mb-0">Edit Project</h1>
            </div>
          </div>
        </div>
        <div className="c-block__pattern c-block__pattern--light3 c-block__pattern--top3 c-block__overlay--opacity-20">
          <Icon name="pattern" className="o-svg-icon" />
        </div>
      </section>

      {getProjectState.isLoading && (
        <div className="c-listing__none">
          <div className="c-listing__none-spinner"></div>
          <p className="c-listing__none-title">Loading...</p>
        </div>
      )}
      {getProjectState.isError && (
        <div className="o-container-fluid u-pb-spacer-section">
          <div className="o-row u-flex-grow u-items-center">
            <div className="o-col-12">
              <div className="c-listing__none">
                <div className="c-listing__none-figure">
                  <Icon name="error" className="o-svg-icon" />
                </div>
                <p className="c-listing__none-title">Error</p>
                <ErrorMessagesContent error={getProjectState.error} />
              </div>
            </div>
          </div>
        </div>
      )}
      {typeof projectRes !== 'undefined' && typeof projectVersion !== 'undefined' && getProjectState.isSuccess && (
        <>
          {ability.can('read', subject('Project', projectRes.data)) ? (
            <EditProjectForm project={projectRes.data} projectVersion={projectVersion} />
          ) : (
            <Block403Error />
          )}
        </>
      )}
    </>
  );
}
