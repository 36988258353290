import * as React from 'react';

import { RegisteredUser } from '__generated-api__';
import { AuthStatus, useAbility, useAuth } from 'auth';
import Icon from 'components/icon';
import { Modal } from 'my-account/components/Modal';
import { RequestProjectQuoteForm, RequestProjectQuoteReviewProducts } from 'my-account/forms/RequestProjectQuote';

export const NewProjectQuoteModal: React.VFC<{
  isOpen: boolean;
  onRequestClose: () => void;
}> = ({ isOpen, onRequestClose }) => {
  const ability = useAbility();
  const canCreateQuote = ability.can('create', 'Quote');
  const auth = useAuth();
  let user: RegisteredUser | undefined;
  if (auth.status === AuthStatus.LoggedIn) {
    user = auth.currentUser;
  }
  if (!canCreateQuote) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} id="new-project-quote">
      <div className="c-modal c-modal--contact">
        {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */}
        <a
          href="#"
          className="c-modal-close c-modal-close--invert"
          onClick={(event) => {
            event.preventDefault();
            onRequestClose();
          }}
        />
        <div className="c-modal__main">
          <div className="c-block c-block--spacing-t-small c-block--spacing-b-small c-color--white">
            <div className="o-container-fluid">
              <div className="c-block__pattern-circle c-block__pattern--left c-block__pattern--light3 c-block__overlay--opacity-20">
                <Icon name="pattern-circle" className="o-svg-icon" />
              </div>
              <div className="c-block__header">
                <h5 className="c-title--large">
                  {user?.direct_buyer ? (
                    <>
                      Create a <strong>New Order</strong>
                    </>
                  ) : (
                    <>
                      Request <strong>Project Quote</strong>
                    </>
                  )}
                </h5>
                <p>
                  {user?.direct_buyer ? (
                    <>
                      To create a new order, complete the form below. Be as specific as possible in regard to models,
                      sizes, finishes, and other details.
                    </>
                  ) : (
                    <>
                      To request a quote for your project, complete the form below. Be as specific as possible in regard
                      to models, sizes, finishes, and other details.
                    </>
                  )}
                </p>
              </div>
              <h6>{user?.direct_buyer ? 'Order Details' : 'Project Details'}</h6>

              <RequestProjectQuoteReviewProducts />
              <RequestProjectQuoteForm
                onSubmit={() => {
                  onRequestClose();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
