import * as React from 'react';

import { RegisteredUser } from '__generated-api__';
import { AuthStatus, useAuth } from 'auth';
import { Button } from 'components/Button';
import Icon from 'components/icon';
import { NewProjectQuoteModal } from 'my-account/components/NewProjectQuoteModal';
import { usePendingQuote } from 'my-account/components/PendingQuote/context';

export const AddProductToQuote: React.VFC<{ product: Symmons.Product }> = ({ product }) => {
  const { addProduct, canAddToQuote, canCreateQuote } = usePendingQuote();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const auth = useAuth();
  let user: RegisteredUser | undefined;
  if (auth.status === AuthStatus.LoggedIn) {
    user = auth.currentUser;
  }

  const addToQuoteHandler = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      event.preventDefault();

      if (!inputRef.current) {
        return null;
      }

      const quantity = Number.isNaN(inputRef.current.valueAsNumber) ? 1 : Math.max(inputRef.current.valueAsNumber, 1);

      setIsLoading(true);

      return addProduct({
        sku: product.sku,
        quantity,
        title: product.title,
        main_image: null,
        installation_guide: null,
        price: product.price?.ammount ?? 0,
      }).then(() => {
        setIsLoading(false);
        if (inputRef.current) {
          inputRef.current.valueAsNumber = 1;
        }
      });
    },
    [addProduct, product.price?.ammount, product.sku, product.title]
  );
  const increase = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>((event) => {
    event.preventDefault();

    if (!inputRef.current) {
      return;
    }

    inputRef.current.valueAsNumber = Number.isNaN(inputRef.current.valueAsNumber)
      ? 1
      : inputRef.current.valueAsNumber + 1;
  }, []);
  const decrease = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>((event) => {
    event.preventDefault();

    if (!inputRef.current) {
      return;
    }

    inputRef.current.valueAsNumber = Math.max(
      Number.isNaN(inputRef.current.valueAsNumber) ? 1 : inputRef.current.valueAsNumber - 1,
      1
    );
  }, []);
  const guardValue = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>((event) => {
    if (Number.isNaN(event.target.valueAsNumber) || event.target.valueAsNumber < 1) {
      event.target.valueAsNumber = 1;
    }
  }, []);

  if (!canCreateQuote) {
    return null;
  }

  if (!canAddToQuote) {
    return (
      <>
        <div className="c-list-grid">
          <div className="u-ml-auto">
            <div className="c-list-grid">
              <div>
                <Button
                  onClick={(event) => {
                    event.preventDefault();
                    setIsModalOpen(true);
                  }}
                  rightIcon="add"
                >
                  {user?.direct_buyer ? 'New order' : 'New project quote'}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <NewProjectQuoteModal
          isOpen={isModalOpen}
          onRequestClose={() => {
            setIsModalOpen(false);
          }}
        />
      </>
    );
  }

  return (
    <>
      <div className="c-number-input u-ml-auto">
        <div className="c-number-input__input">
          <button className="c-number-input__button c-number-input__button--remove" type="button" onClick={decrease}>
            <Icon name="remove" />
          </button>
          <input type="number" defaultValue="1" min="1" ref={inputRef} onChange={guardValue} onBlur={guardValue} />
          <button className="c-number-input__button c-number-input__button--add" type="button" onClick={increase}>
            <Icon name="add" />
          </button>
        </div>
        <div className="c-number-input__cta">
          <Button
            onClick={addToQuoteHandler}
            isFull
            rightIcon={<Icon name="package" className="o-svg-icon o-svg-large" />}
            className="u-mb-0"
            isLoading={isLoading}
          >
            {user?.direct_buyer ? 'Add to cart' : 'Add to quote'}
          </Button>
        </div>
      </div>
    </>
  );
};
