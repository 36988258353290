import api from 'api';
import { SelectListingItemProps, SelectListingItem } from 'my-account/components/SelectListingItem';

export interface SelectAgencyProps<IsMulti extends boolean = false>
  extends Omit<
    SelectListingItemProps<typeof api.agency.listAgencies, IsMulti>,
    'queryFn' | 'getOptionLabel' | 'getOptionValue' | 'queryParams'
  > {
  queryParams?: SelectListingItemProps<typeof api.agency.listAgencies, IsMulti>['queryParams'];
}

export const SelectAgency = <IsMulti extends boolean = false>({
  queryParams = {},
  ...props
}: SelectAgencyProps<IsMulti>) => {
  return (
    <SelectListingItem
      {...props}
      queryFn={api.agency.listAgencies}
      queryParams={{ sort: 'name', direction: 'asc', ...queryParams }}
      getOptionLabel={(agency) => agency.name}
      getOptionValue={(agency) => String(agency.id)}
    />
  );
};
