import { useAuth } from 'auth';
import Icon from 'components/icon';
import { NavigationDropdown } from 'my-account/components/NavigationDropdown';
import { useSupportModal } from 'my-account/components/SupportModal';

export const MainHero = () => {
  const auth = useAuth();
  const supportModal = useSupportModal();

  return (
    <section className="c-block c-block--higher c-block--bg-light2 c-block--hero c-block--height-xs@xs">
      <div className="o-container-fluid u-pb-0">
        <div className="o-row u-flex-grow u-items-end">
          <div className="o-col-5@sm">
            <NavigationDropdown />
          </div>
          <div className="o-col-7@sm u-text-right@sm">
            <ul className="c-divider-list u-mb-spacer-base-small">
              <li className="c-divider-list__item">
                <a
                  href="#modal-contact"
                  className="c-link-cta-basic c-link-cta--small u-mb-0"
                  onClick={(event) => {
                    event.preventDefault();
                    supportModal.open();
                  }}
                >
                  <Icon name="chat" className="o-svg-icon o-svg-larger" />
                  <span>Contact support</span>
                </a>
              </li>
              <li className="c-divider-list__item">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  className="c-link-cta-basic c-link-cta--small u-mb-0"
                  onClick={(event) => {
                    event.preventDefault();
                    auth.logout();
                  }}
                >
                  <span>Log out</span>
                  <Icon name="out" className="o-svg-icon" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
