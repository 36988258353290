import classNames from 'classnames';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { useUnmount } from 'react-use';

import { RegisteredUser } from '__generated-api__';
import { AuthStatus, useAuth } from 'auth';
import Icon from 'components/icon';
import { usePendingQuote } from 'my-account/components/PendingQuote/context';

export const PendingQuoteMenu: React.VFC = () => {
  const auth = useAuth();
  let user: RegisteredUser | undefined;
  if (auth.status === AuthStatus.LoggedIn) {
    user = auth.currentUser;
  }
  const element = React.useMemo(() => {
    const ul = jQuery('.c-site-nav__secondary > ul');

    if (!ul.length) {
      return null;
    }

    const el = document.createElement('li');
    el.className = 'has-divider';
    ul.prepend(el);

    return el;
  }, []);
  const { openModal, quote } = usePendingQuote();

  useUnmount(() => {
    if (element) {
      jQuery(element).remove();
    }
  });

  if (!element) return null;

  const isActiveQuote = quote && typeof quote.products !== 'undefined' && quote.products.length > 0;

  return ReactDOM.createPortal(
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href="#"
      onClick={(event) => {
        event.preventDefault();
        openModal();
      }}
      className={classNames({
        'c-site-utility-quote': isActiveQuote,
      })}
    >
      <Icon name={isActiveQuote ? 'package-fill' : 'package'} className="o-svg-icon o-svg-large" />
      {user?.direct_buyer ? <span>Shopping Cart</span> : <span>Project Quote</span>}
      {isActiveQuote && (
        <>
          {' '}
          <span className="c-site-utility-quote__qty">
            {quote!.products!.reduce((total, product) => (total += product.pivot.quantity), 0)}
          </span>
        </>
      )}
    </a>,
    element
  );
};
