import * as React from 'react';
import { AxiosResponse } from 'axios';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { Project, ProjectVersionStatusUpdate, PageUpdate, ProjectVersion, EmptyPageType } from '__generated-api__';
import api from 'api';
import { useMutation } from 'hooks/query';
import { click } from 'my-account/utils/dom';
import { useErrorHandler } from 'my-account/utils/error-handler';
import { getProjectVersion } from 'my-account/utils/project';

export const useCopyProject = () => {
  const queryClient = useQueryClient();
  const [createProject, createProjectState] = useMutation(api.project.createProject, {
    onSettled: () =>
      queryClient
        .cancelQueries(api.project.listProjects.getQueryKey()[0])
        .then(() => queryClient.invalidateQueries(api.project.listProjects.getQueryKey()[0])),
  });

  const fn = React.useCallback(
    (project: Project, projectVersion: ProjectVersion) => {
      let copyName = project.name;
      const match = copyName.match(/\[COPY( (\d+))?\]$/);

      if (match) {
        copyName = copyName.replace(
          /\[COPY( \d+)?\]$/,
          `[COPY ${match[2] && !Number.isNaN(Number(match[2])) ? Number(match[2]) + 1 : '2'}]`
        );
      } else {
        copyName = `${copyName} [COPY]`;
      }

      if (copyName.length > 60) {
        const match = copyName.match(/\[COPY( (\d+))?\]$/);

        if (match && match.index && match[0].length < 60) {
          const suffix = match[0];
          copyName = copyName.substring(0, match.index).substring(0, 60 - suffix.length) + suffix;
        } else {
          copyName = copyName.substring(0, 60);
        }
      }

      return createProject([
        {
          projectUpdate: {
            name: copyName,
            latest: {
              ...projectVersion,
              id: null,
              name: '',
              description: projectVersion.description || undefined,
              number: projectVersion.number || undefined,
              customer_id: projectVersion.customer?.id ?? null,
              opportunity_id: projectVersion.opportunity?.id ?? null,
              status: ProjectVersionStatusUpdate.Draft,
              pages: projectVersion.pages.map((page) => {
                return {
                  ...page,
                  id: null,
                  blocks:
                    page.type !== EmptyPageType.Empty
                      ? page.blocks.map((block) => {
                          return { ...block, id: null };
                        })
                      : undefined,
                };
              }) as PageUpdate[],
            },
          },
        },
      ]);
    },
    [createProject]
  );

  return [fn, createProjectState] as const;
};

export const useProjectVersion = (project?: Project) => {
  const params = useParams<{ version?: string }>();

  return getProjectVersion(project, params.version);
};

export const useDownloadDocumentButton = () => {
  const [downloadProject, downloadProjectState] = useMutation(api.project.downloadProjectVersion, {
    axiosRequestConfig: { responseType: 'blob', timeout: 30000 },
  });
  const errorHandler = useErrorHandler();

  const download = React.useCallback(
    async ({ id, name, versionId }: { id: Project['id']; name: Project['name']; versionId: ProjectVersion['id'] }) => {
      errorHandler.clearErrors();

      const URL = window.URL || window.webkitURL;

      try {
        const res = (await downloadProject([{ id, versionId }])) as unknown as AxiosResponse<Blob>;
        const link = document.createElement('a');
        link.rel = 'noopener';
        link.href = URL.createObjectURL(res.data);
        link.download = `${name}.pdf`;

        setTimeout(() => {
          URL.revokeObjectURL(link.href);
        }, 40000);

        setTimeout(() => {
          click(link);
        }, 0);
      } catch (error) {
        errorHandler.handleError(error);
      }
    },
    [downloadProject, errorHandler]
  );

  return [download, { isDownloading: downloadProjectState.isLoading }] as const;
};

export const useDownloadPublicDocumentButton = () => {
  const [downloadProject, downloadProjectState] = useMutation(api.project.downloadPublicProject);
  const errorHandler = useErrorHandler();

  const publicDownload = React.useCallback(
    async ({ public_token, name }: Pick<Project, 'public_token' | 'name'>) => {
      errorHandler.clearErrors();

      const URL = window.URL || window.webkitURL;

      try {
        const res = (await downloadProject([
          { publicToken: public_token ?? '' },
          { responseType: 'blob', timeout: 30000 },
        ])) as unknown as AxiosResponse<Blob>;
        const link = document.createElement('a');
        link.rel = 'noopener';
        link.href = URL.createObjectURL(res.data);
        link.download = `${name}.pdf`;

        setTimeout(() => {
          URL.revokeObjectURL(link.href);
        }, 40000);

        setTimeout(() => {
          click(link);
        }, 0);
      } catch (error) {
        errorHandler.handleError(error);
      }
    },
    [downloadProject, errorHandler]
  );

  return [publicDownload, { isPublicDownloading: downloadProjectState.isLoading }] as const;
};
