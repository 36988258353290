import { Link } from 'react-router-dom';
import { z } from 'zod';

import api from 'api';
import { useMutation } from 'hooks/query';
import Icon from 'components/icon';
import Form from 'components/Form/Form';
import InputField from 'components/Form/InputField';
import SubmitButton from 'components/Form/SubmitButton';
import SelectField from 'components/Form/SelectField';
import { usStates, caProvinces } from 'my-account/utils/states';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { getObjectKeys } from 'my-account/utils/object';

const RequestAccountSchema = z.object({
  first_name: z.string().nonempty(),
  last_name: z.string().nonempty(),
  email: z.string().email().nonempty(),
  company_name: z.string().nonempty(),
  phone: z.string().nonempty(),
  city: z.string().nonempty(),
  state: z
    .nativeEnum(usStates)
    .or(z.nativeEnum(caProvinces))
    .or(z.literal(''))
    .refine((value) => value !== '', 'State is required.'),
});

export function RequestAccountForm() {
  const [register, { isLoading, isSuccess }] = useMutation(api.user.register);

  if (isSuccess) {
    return (
      <>
        <h6>Account Requested!</h6>
        <p>
          We've received your request and we'll contact you via email soon! (Be sure to check your junk/spam folder just
          in case!)
        </p>
      </>
    );
  }

  return (
    <>
      <h6>Contact Information</h6>
      <Form
        schema={RequestAccountSchema}
        initialValues={{ first_name: '', last_name: '', email: '', company_name: '', phone: '', city: '', state: '' }}
        onSubmit={async (data) => {
          await register([{ requestAccountBody: { ...data } }]);
        }}
        className="u-mb-spacer-section-small"
        hasFloatingLabels
      >
        <div className="o-row o-row--small-gutters">
          <div className="o-col-6@sm">
            <InputField
              name="first_name"
              type="text"
              label="First Name"
              disabled={isLoading}
              elStyle="fill"
              inputProps={{
                autoComplete: 'given-name',
              }}
            />
          </div>
          <div className="o-col-6@sm">
            <InputField
              name="last_name"
              type="text"
              label="Last Name"
              disabled={isLoading}
              elStyle="fill"
              inputProps={{
                autoComplete: 'family-name',
              }}
            />
          </div>
          <div className="o-col-12@sm">
            <InputField
              name="email"
              type="email"
              label="Email address"
              disabled={isLoading}
              elStyle="fill"
              inputProps={{
                autoComplete: 'email',
              }}
            />
          </div>
          <div className="o-col-12@sm">
            <InputField
              name="company_name"
              type="text"
              label="Company name"
              disabled={isLoading}
              elStyle="fill"
              inputProps={{
                autoComplete: 'organization',
              }}
            />
          </div>
          <div className="o-col-12@sm">
            <InputField
              name="phone"
              type="text"
              label="Phone number"
              disabled={isLoading}
              elStyle="fill"
              inputProps={{
                autoComplete: 'tel',
              }}
            />
          </div>
          <div className="o-col-8@sm">
            <InputField
              name="city"
              type="text"
              label="City"
              disabled={isLoading}
              elStyle="fill"
              inputProps={{
                autoComplete: 'address-level2',
              }}
            />
          </div>
          <div className="o-col-4@sm">
            <SelectField
              name="state"
              label="State/Province"
              disabled={isLoading}
              elStyle="fill"
              selectProps={{ autoComplete: 'address-level1' }}
            >
              <optgroup label="United States">
                {getObjectKeys(usStates).map((state) => (
                  <option key={usStates[state]} value={usStates[state]}>
                    {state}
                  </option>
                ))}
              </optgroup>
              <optgroup label="Canada">
                {getObjectKeys(caProvinces).map((state) => (
                  <option key={caProvinces[state]} value={caProvinces[state]}>
                    {state}
                  </option>
                ))}
              </optgroup>
            </SelectField>
          </div>
          <div className="o-col-12@sm">
            <div className="c-form-footer">
              <SubmitButton variant="secondary" isLoading={isLoading} isFull>
                Sign up
              </SubmitButton>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
}

export default function RequestAccountPage() {
  useDocumentTitle('Request Account');
  return (
    <>
      <section className="c-block c-block--bg-light2 c-block--hero c-block--height-xs@xs">
        <div className="o-container-fluid">
          <div className="o-row u-flex-grow u-items-center">
            <div className="o-col-12@md u-justify-around">
              <div className="c-block__header u-items-center u-mt-spacer-base">
                <h1 className="c-title--extra-large">Need an Account?</h1>
                <div className="c-hero__desc">
                  <p>Just enter your details and we’ll set you up!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="c-block__pattern c-block__pattern--light3 c-block__overlay--opacity-40">
          <Icon name="pattern" className="o-svg-icon" />
        </div>
      </section>

      <section className="c-block c-block--spacing-t-small c-block--spacing-t@md c-block--spacing-b-small c-block--spacing-b@md">
        <div className="o-container-fluid">
          <div className="o-row">
            <div className="o-col-8@md o-col-6@lg u-mx-auto">
              <RequestAccountForm />
              <div className="c-info c-info--sm u-mb-spacer-base u-mx-auto">
                <div className="c-info__header">
                  <div className="c-info__icon u-text-secondary">
                    <Icon name="info" className="o-svg-icon" />
                  </div>
                  <p>
                    Already have an account?{' '}
                    <Link
                      to="/auth/login"
                      className="c-link-cta-basic c-link-cta--small u-mb-0 u-ml-spacer-base-small@sm"
                    >
                      <span>Login</span>
                      <Icon name="arrow" className="o-svg-icon o-svg-right" />
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
