import * as React from 'react';
import { useDeepCompareEffect } from 'react-use';

import { isCroppedImageCancelled, UseCroppedImageProps, getCroppedImageSize } from 'spb-pdf/utils/image/shared';
import { getCroppedImage } from 'spb-pdf/utils/image/getCroppedImage';
export { getCroppedImage } from 'spb-pdf/utils/image/getCroppedImage';
export { getCroppedImageSize } from 'spb-pdf/utils/image/shared';
export type { UseCroppedImageProps } from 'spb-pdf/utils/image/shared';

export const useCroppedImage = ({ image, settings }: UseCroppedImageProps) => {
  const [imageState, setImageState] = React.useState<
    | { status: 'loading' }
    | { status: 'error'; error: string }
    | { status: 'success'; src: string | { data: Buffer; format: 'jpg' | 'png' }; width: number; height: number }
  >({ status: 'loading' });
  useDeepCompareEffect(() => {
    const promise = getCroppedImage({ image, settings });

    promise
      .then((src) => {
        setImageState({ status: 'success', src, ...getCroppedImageSize({ image, settings }) });
      })
      .catch((error) => {
        if (isCroppedImageCancelled(error)) {
          return;
        }

        console.error(error);
        setImageState({
          status: 'error',
          error: error instanceof Error ? error.message : 'Unable to apply image manipulations.',
        });
      });

    return () => {
      promise.cancel();
    };
  }, [image.height, image.image, image.width, settings ?? {}]);

  return imageState;
};
