import * as React from 'react';
import { useWatch } from 'react-hook-form';
import { SetOptional } from 'type-fest';

import {
  CoverPageType,
  ProductPageType,
  NotesPageType,
  IntroductionPageType,
  DividerPageType,
  SummaryPageType,
  TocPageType,
} from '__generated-api__';
import { ProjectDefaultValuesType } from 'my-account/validations/project';
import CoverPageFields from './cover';
import IntroductionPageFields from './introduction';
import ProductPageFields from './product';
import DividerPageFields from './divider';
import NotesPageFields from './notes';
import SummaryPageFields from './summary';
import TocPageFields from './toc';

const PageContentFields: React.VFC<{
  currentPagePreview: number;
  addNewPage: (
    page?:
      | SetOptional<ProjectDefaultValuesType['latest']['pages'][0], 'id'>
      | Array<SetOptional<ProjectDefaultValuesType['latest']['pages'][0], 'id'>>,
    index?: number
  ) => void;
}> = ({ currentPagePreview, addNewPage }) => {
  const pageType = useWatch<ProjectDefaultValuesType['latest']['pages'][0], 'type'>({
    name: `latest.pages.${currentPagePreview}.type` as unknown as 'type',
  });

  if (!pageType) {
    return null;
  }

  if (pageType === CoverPageType.Cover) {
    return <CoverPageFields currentPagePreview={currentPagePreview} />;
  }

  if (pageType === IntroductionPageType.Introduction) {
    return <IntroductionPageFields currentPagePreview={currentPagePreview} addNewPage={addNewPage} />;
  }

  if (pageType === NotesPageType.Notes) {
    return <NotesPageFields currentPagePreview={currentPagePreview} />;
  }

  if (pageType === DividerPageType.Divider) {
    return <DividerPageFields currentPagePreview={currentPagePreview} />;
  }

  if (pageType === ProductPageType.Product) {
    return <ProductPageFields currentPagePreview={currentPagePreview} addNewPage={addNewPage} />;
  }

  if (pageType === SummaryPageType.Summary) {
    return <SummaryPageFields currentPagePreview={currentPagePreview} />;
  }

  if (pageType === TocPageType.Toc) {
    return <TocPageFields currentPagePreview={currentPagePreview} />;
  }

  return null;
};

export default PageContentFields;
