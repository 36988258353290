import * as React from 'react';
import { useQueryClient } from 'react-query';
import promiseDebounce from 'p-debounce';
import { useLocation } from 'react-router';

import {
  CompanyManagerUserRoleEnum,
  LocationManagerUserRoleEnum,
  PendingQuoteStatusEnum,
  ProductRelationWithQuotePivotData,
  Quote,
  RegularUserRoleEnum,
  UpdatePendingQuoteBodyStatusEnum,
  UpdateSubmittedQuoteBodyStatusEnum,
  FileType,
} from '__generated-api__';
import api from 'api';
import { AuthStatus, useAbility, useAuth } from 'auth';
import { useAxiosResponseQuery, useMutation } from 'hooks/query';
import { Button } from 'components/Button';
import {
  PendingQuoteContextProvider,
  AddProductData,
  PendingQuoteContextValue,
} from 'my-account/components/PendingQuote/context';
import { PendingQuoteMenu } from 'my-account/components/PendingQuote/Menu';
import { PendingQuoteModal } from 'my-account/components/PendingQuote/Modal';
import { IProjectOrderRequestSchema, IProjectQuoteRequestSchema } from 'my-account/components/PendingQuote/schema';
import { useErrorHandler } from 'my-account/utils/error-handler';

const updateCurrentQuoteDebounced: typeof api.quote.updateCurrentQuote = Object.assign(
  promiseDebounce(api.quote.updateCurrentQuote, 200),
  {
    getQueryKey: api.quote.updateCurrentQuote.getQueryKey,
  }
);

export const PendingQuoteProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const queryClient = useQueryClient();
  const errorHandler = useErrorHandler();
  const auth = useAuth();
  const ability = useAbility();
  const canCreateQuoteAbility = ability.can('create', 'Quote');
  const canCreateQuote =
    canCreateQuoteAbility &&
    (auth.status === AuthStatus.LoggedIn || auth.status === AuthStatus.LoggingOut) &&
    !location.pathname.startsWith('/auth/');
  const [quote, quoteQuery] = useAxiosResponseQuery(api.quote.getCurrentQuote, undefined, {
    enabled: canCreateQuote,
    initialData:
      canCreateQuote && auth.status === AuthStatus.LoggedIn && auth.currentUser.pending_quote
        ? auth.currentUser.pending_quote
        : undefined,
  });
  const [updateQuote, updateQuoteQuery] = useMutation(updateCurrentQuoteDebounced);
  const isLoading = React.useMemo(() => {
    return quoteQuery.isLoading || updateQuoteQuery.isLoading;
  }, [quoteQuery.isLoading, updateQuoteQuery.isLoading]);
  const [modalHasConfirmation, setModalHasConfirmation] = React.useState(true);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const customPrices = React.useRef<Map<string, number>>(new Map());

  const openModal = React.useCallback((options: { hasConfirmation?: boolean } = {}) => {
    const { hasConfirmation = true } = options;
    setModalHasConfirmation(hasConfirmation);
    setIsModalOpen(true);
  }, []);

  const closeModal = React.useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const requestQuote = React.useCallback(
    async (data: IProjectQuoteRequestSchema) => {
      if (!canCreateQuote) {
        return;
      }

      let files = [];

      if (data.files && data.files.length) {
        for (let i = 0; i < data.files.length; i += 1) {
          const file = data.files[i];
          const uploadRes = await api.file.upload({ image: file, type: FileType.File });
          files.push(uploadRes.data);
        }
      }

      const res = await updateQuote([
        {
          updateQuoteBody: Object.assign(
            {
              status: UpdateSubmittedQuoteBodyStatusEnum.Sent,
              project_name: data.project_name,
              city: data.city,
              state: data.state,
              message: data.message,
              files,
            },
            typeof data.products !== 'undefined' ? { products: data.products } : undefined
          ),
        },
      ]);

      queryClient.setQueryData<Quote>(api.quote.getCurrentQuote.getQueryKey(), () => ({ ...res.data }));
    },
    [canCreateQuote, queryClient, updateQuote]
  );

  const requestOrder = React.useCallback(
    async (data: IProjectOrderRequestSchema) => {
      if (!canCreateQuote) {
        return;
      }

      let files = [];

      if (data.files && data.files.length) {
        for (let i = 0; i < data.files.length; i += 1) {
          const file = data.files[i];
          const uploadRes = await api.file.upload({ image: file, type: FileType.File });
          files.push(uploadRes.data);
        }
      }

      const res = await updateQuote([
        {
          updateQuoteBody: Object.assign(
            {
              status: UpdateSubmittedQuoteBodyStatusEnum.Sent,
              project_name: data.project_name,
              city: data.city,
              state: data.state,
              message: data.message,
              address: data.address,
              address2: data.address2,
              postal_code: data.postal_code,
              phone: data.phone,
              country: data.country,
              location_name: data.location_name,
              first_name: data.first_name,
              last_name: data.last_name,
              files,
            },
            typeof data.products !== 'undefined' ? { products: data.products } : undefined
          ),
        },
      ]);

      queryClient.setQueryData<Quote>(api.quote.getCurrentQuote.getQueryKey(), () => ({ ...res.data }));
    },
    [canCreateQuote, queryClient, updateQuote]
  );

  const updateProducts = React.useCallback(
    async (products: ProductRelationWithQuotePivotData[]) => {
      if (!canCreateQuote) {
        return;
      }
      errorHandler.clearErrors();

      const currentQuoteQueryKey = api.quote.getCurrentQuote.getQueryKey();

      await queryClient.cancelQueries(currentQuoteQueryKey);

      const currentQuoteData = queryClient.getQueryData<Quote>(currentQuoteQueryKey);

      if (currentQuoteData) {
        queryClient.setQueryData<Quote>(currentQuoteQueryKey, () => {
          return {
            ...currentQuoteData,
            status: PendingQuoteStatusEnum.Pending,
            products,
          };
        });
      }

      try {
        await updateQuote([
          {
            updateQuoteBody: {
              status: UpdatePendingQuoteBodyStatusEnum.Pending,
              products: products.map((product) => ({
                sku: product.sku,
                quantity: product.pivot.quantity,
              })),
            },
          },
        ]);
        await queryClient.cancelQueries(api.quote.getCurrentQuote.getQueryKey()[0]);
        await queryClient.invalidateQueries(api.quote.getCurrentQuote.getQueryKey()[0]);
      } catch (e) {
        errorHandler.handleError(e, {
          messageFooter: (
            <Button
              variant="white"
              onClick={(event) => {
                event.preventDefault();
                updateProducts(products).catch(() => {});
              }}
            >
              Retry
            </Button>
          ),
        });
        if (currentQuoteData) {
          queryClient.setQueryData<Quote>(currentQuoteQueryKey, () => ({ ...currentQuoteData }));
        }
        await queryClient.cancelQueries(api.quote.getCurrentQuote.getQueryKey()[0]);
        await queryClient.invalidateQueries(api.quote.getCurrentQuote.getQueryKey()[0]);
        throw e;
      }
    },
    [canCreateQuote, errorHandler, queryClient, updateQuote]
  );

  const addProduct = React.useCallback(
    async (data: AddProductData) => {
      if (!canCreateQuote) {
        return;
      }
      const currentQuoteData = queryClient.getQueryData<Quote>(api.quote.getCurrentQuote.getQueryKey());

      const products =
        currentQuoteData &&
        currentQuoteData.products &&
        currentQuoteData.products.length &&
        currentQuoteData.products.find(({ sku }) => sku === data.sku)
          ? currentQuoteData.products.map((item) => ({
              ...item,
              pivot: { ...item.pivot, quantity: item.pivot.quantity + data.quantity },
            }))
          : [
              ...(currentQuoteData?.products ?? []),
              {
                id: 0,
                created_at: '',
                updated_at: '',
                sku: data.sku,
                title: data.title,
                price: customPrices.current.get(data.sku) ?? data.price,
                main_image: data.main_image,
                installation_guide: data.installation_guide,
                pivot: {
                  product_id: 0,
                  created_at: '',
                  updated_at: '',
                  quote_id: currentQuoteData?.id ?? 0,
                  quantity: data.quantity,
                },
              },
            ];

      return updateProducts(products);
    },
    [canCreateQuote, queryClient, updateProducts]
  );

  const addCustomPrice = React.useCallback((sku: string, price: number) => {
    customPrices.current.set(sku, price);
  }, []);

  let value: PendingQuoteContextValue = {
    quote: undefined,
    canCreateQuote: false,
    canAddToQuote: false,
    addProduct: () => {
      throw new Error('You are not allowed to access this.');
    },
    updateProducts: () => {
      throw new Error('You are not allowed to access this.');
    },
    requestQuote: () => {
      throw new Error('You are not allowed to access this.');
    },
    requestOrder: () => {
      throw new Error('You are not allowed to access this.');
    },
    isLoading: false,
    openModal: () => {
      throw new Error('You are not allowed to access this.');
    },
    closeModal: () => {
      throw new Error('You are not allowed to access this.');
    },
    addCustomPrice: () => {},
  };

  if (canCreateQuote) {
    let canAddToQuote = true;

    if (
      auth.currentUser.role === CompanyManagerUserRoleEnum.CompanyManager ||
      auth.currentUser.role === LocationManagerUserRoleEnum.LocationManager ||
      auth.currentUser.role === RegularUserRoleEnum.User
    ) {
      canAddToQuote = !auth.currentUser.rep_managed;
    }

    value = {
      canCreateQuote: true,
      canAddToQuote,
      quote,
      addProduct,
      updateProducts,
      requestQuote,
      requestOrder,
      isLoading,
      openModal,
      closeModal,
      addCustomPrice,
    };
  }

  return (
    <PendingQuoteContextProvider value={value}>
      {children}
      {canCreateQuote && (
        <>
          <PendingQuoteMenu />
          <PendingQuoteModal
            isOpen={isModalOpen}
            onRequestClose={() => {
              closeModal();
            }}
            hasConfirmation={modalHasConfirmation}
          />
        </>
      )}
    </PendingQuoteContextProvider>
  );
};
