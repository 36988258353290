import * as React from 'react';
import classNames from 'classnames';
import Icon from 'components/icon';

export interface ButtonProps extends Omit<JSX.IntrinsicElements['button'], 'className'> {
  isLoading?: boolean;
  variant?: 'primary' | 'secondary' | 'white' | 'dark';
  isFull?: boolean;
  isSmall?: boolean;
  isIcon?: boolean;
  leftIcon?: string | React.ReactNode;
  rightIcon?: string | React.ReactNode;
  className?: string;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  isLoading,
  disabled,
  variant = 'primary',
  isFull,
  isSmall,
  isIcon,
  type = 'button',
  leftIcon,
  rightIcon,
  className,
  ...buttonProps
}) => {
  return (
    <button
      {...buttonProps}
      type={type}
      className={classNames(
        'c-button',
        `c-button--${variant}`,
        {
          'c-button--pre-loading': isLoading,
          'c-button--loading': isLoading,
          'c-button--full': isFull,
          'c-button--small': isSmall,
          'c-button--icon': isIcon,
        },
        className
      )}
      disabled={isLoading || disabled}
    >
      {typeof leftIcon !== 'undefined' ? (
        typeof leftIcon === 'string' ? (
          <Icon name={leftIcon} className="o-svg-icon o-svg-left" />
        ) : (
          leftIcon
        )
      ) : null}
      {isLoading || typeof rightIcon !== 'undefined' || typeof leftIcon !== 'undefined' ? (
        <span>{children}</span>
      ) : (
        children
      )}
      {typeof rightIcon !== 'undefined' ? (
        typeof rightIcon === 'string' ? (
          <Icon name={rightIcon} className="o-svg-icon o-svg-right" />
        ) : (
          rightIcon
        )
      ) : null}
    </button>
  );
};
