import { useWatch } from 'react-hook-form';
import { ProjectDefaultValuesType } from 'my-account/validations/project';

const useDocumentSettings = () => {
  const show_address = useWatch<ProjectDefaultValuesType, 'latest.show_address'>({ name: 'latest.show_address' });
  const show_rep_info = useWatch<ProjectDefaultValuesType, 'latest.show_rep_info'>({ name: 'latest.show_rep_info' });
  const show_default_number_units = useWatch<ProjectDefaultValuesType, 'latest.show_default_number_units'>({
    name: 'latest.show_default_number_units',
  });
  const default_number_units = useWatch<ProjectDefaultValuesType, 'latest.default_number_units'>({
    name: 'latest.default_number_units',
  });
  const show_list_price = useWatch<ProjectDefaultValuesType, 'latest.show_list_price'>({
    name: 'latest.show_list_price',
  });
  const show_discount_price_label = useWatch<ProjectDefaultValuesType, 'latest.show_discount_price_label'>({
    name: 'latest.show_discount_price_label',
  });
  const discount_price_label = useWatch<ProjectDefaultValuesType, 'latest.discount_price_label'>({
    name: 'latest.discount_price_label',
  });
  const default_multiplier = useWatch<ProjectDefaultValuesType, 'latest.default_multiplier'>({
    name: 'latest.default_multiplier',
  });
  const currency_type = useWatch<ProjectDefaultValuesType, 'latest.currency_type'>({
    name: 'latest.currency_type',
  });

  const show_extended_price = useWatch<ProjectDefaultValuesType, 'latest.show_extended_price'>({
    name: 'latest.show_extended_price',
  });

  const pricing_footer_note = useWatch<ProjectDefaultValuesType, 'latest.pricing_footer_note'>({
    name: 'latest.pricing_footer_note',
  });

  return {
    show_address,
    show_rep_info,
    show_default_number_units,
    default_number_units,
    show_list_price,
    show_discount_price_label,
    discount_price_label,
    default_multiplier,
    currency_type,
    show_extended_price,
    pricing_footer_note,
  };
};

export default useDocumentSettings;
