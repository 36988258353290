import * as React from 'react';
import { z } from 'zod';
import { useController } from 'react-hook-form';

import { SummaryBlockType } from '__generated-api__';
import { EmptyBlock, SummaryBlock, SummaryBlockDefaultValues } from 'my-account/validations/project';
import SummaryBlockField from 'my-account/components/SPB/SummaryBlockField';
import useDocumentSettings from 'my-account/hooks/useDocumentSettings';

const SummaryPageFields: React.VFC<{ currentPagePreview: number }> = ({ currentPagePreview }) => {
  const document_settings = useDocumentSettings();
  const summary = useController<
    { block: z.infer<typeof SummaryBlock> | z.infer<typeof EmptyBlock> | undefined },
    'block'
  >({
    name: `latest.pages.${currentPagePreview}.blocks.0` as unknown as 'block',
  });

  React.useEffect(() => {
    if (!summary.field.value) {
      const block_value: SummaryBlockDefaultValues = {
        id: null,
        type: SummaryBlockType.Summary,
        content: {
          content: '',
        },
        settings: {
          show_totals: true,
          show_no_of_units: document_settings.show_default_number_units ?? true,
          show_list_price: document_settings.show_list_price ?? true,
          show_net_price: document_settings.show_discount_price_label ?? true,
          show_ext_price: document_settings.show_extended_price ?? true,
        },
      };

      summary.field.onChange(block_value);
    }
  }, [
    document_settings.show_default_number_units,
    document_settings.show_discount_price_label,
    document_settings.show_extended_price,
    document_settings.show_list_price,
    summary.field,
  ]);

  if (!summary.field.value) {
    return null;
  }

  return (
    <div className="u-mb-spacer-base-large">
      <SummaryBlockField name={`latest.pages.${currentPagePreview}.blocks.0`} title="Summary" />
    </div>
  );
};

export default SummaryPageFields;
