import * as React from 'react';
import * as RHF from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { ForbiddenError } from '@casl/ability';
import { useUnmount } from 'react-use';
import classNames from 'classnames';

import { isAPIError, isAPIErrorMessage, isAPIErrorsObject, isClientErrorsObject } from 'utils/errors';
import { useToast } from 'my-account/toast';

interface ReadOnlyFields {
  _readOnly?: Record<string, any>;
}

export interface UseFormProps<S extends z.ZodSchema<any, any>> {
  schema: S;
  onSubmit: (
    values: z.infer<S>,
    ctx: RHF.UseFormReturn<z.infer<S>>,
    event?: React.FormEvent<HTMLFormElement>
  ) => Promise<void>;
  onInvalidSubmit?: (
    values: any,
    errors: RHF.FieldErrors<z.infer<S>>,
    event?: React.BaseSyntheticEvent
  ) => void | Promise<void>;
  initialValues: RHF.UseFormProps<z.infer<S>>['defaultValues'] & ReadOnlyFields;
  hasFloatingLabels?: boolean;
  disableFieldsOnSubmitting?: boolean;
  isFiltersForm?: boolean;
  formProps?: Omit<RHF.UseFormProps<z.infer<S>>, 'resolver' | 'defaultValues'>;
  stopSubmitPropagation?: boolean;
}

export interface FormProps<S extends z.ZodSchema<any, any>>
  extends UseFormProps<S>,
    Omit<React.PropsWithoutRef<JSX.IntrinsicElements['form']>, 'onSubmit'> {
  children?: React.ReactNode;
}

interface CustomFormStateContextValue {
  hasFloatingLabels?: boolean;
  disableFieldsOnSubmitting?: boolean;
  isFiltersForm?: boolean;
}

const customFormState = React.createContext<CustomFormStateContextValue>({
  hasFloatingLabels: false,
  disableFieldsOnSubmitting: false,
  isFiltersForm: false,
});

export const FormProvider: React.FC<RHF.UseFormReturn<any> & CustomFormStateContextValue> = ({
  hasFloatingLabels,
  disableFieldsOnSubmitting,
  isFiltersForm,
  children,
  ...ctx
}) => {
  return (
    <RHF.FormProvider {...ctx}>
      <customFormState.Provider value={{ hasFloatingLabels, disableFieldsOnSubmitting, isFiltersForm }}>
        {children}
      </customFormState.Provider>
    </RHF.FormProvider>
  );
};

export function Form<S extends z.ZodSchema<any, any>>({
  children,
  schema,
  initialValues,
  onSubmit,
  onInvalidSubmit,
  hasFloatingLabels,
  className,
  formProps,
  disableFieldsOnSubmitting,
  stopSubmitPropagation,
  isFiltersForm,
  ...props
}: FormProps<S>) {
  const {
    context,
    formProps: { className: htmlFormClassName, ...htmlFormProps },
  } = useForm({
    schema,
    initialValues,
    onSubmit,
    onInvalidSubmit,
    hasFloatingLabels,
    formProps,
    disableFieldsOnSubmitting,
    stopSubmitPropagation,
    isFiltersForm,
  });

  return (
    <FormProvider {...context}>
      <form className={classNames(htmlFormClassName, className)} {...htmlFormProps} {...props}>
        {children}
      </form>
    </FormProvider>
  );
}

export function useForm<S extends z.ZodSchema<any, any>>({
  schema,
  initialValues,
  onSubmit,
  onInvalidSubmit,
  hasFloatingLabels,
  formProps,
  disableFieldsOnSubmitting,
  stopSubmitPropagation,
  isFiltersForm,
}: UseFormProps<S>) {
  const ctx = RHF.useForm<z.infer<S> & ReadOnlyFields>({
    mode: 'onBlur',
    ...formProps,
    resolver: zodResolver(schema),
    defaultValues: initialValues,
  });
  const toast = useToast();
  const formToasts = React.useRef<number[]>([]);

  // Clear all form toasts on unmount.
  useUnmount(() => {
    for (let i = 0; i <= formToasts.current.length; i += 1) {
      toast.close(formToasts.current[i]);
    }
    formToasts.current = [];
  });

  const rhfSubmitHandler = React.useCallback<RHF.SubmitHandler<z.infer<S> & ReadOnlyFields>>(
    async (rawValues, event) => {
      ctx.clearErrors();

      // Clear all form toasts.
      for (let i = 0; i <= formToasts.current.length; i += 1) {
        toast.close(formToasts.current[i]);
      }
      formToasts.current = [];

      const { _readOnly, ...values } = rawValues;

      try {
        await onSubmit(values, ctx, event as unknown as React.FormEvent<HTMLFormElement> | undefined);
      } catch (e) {
        if (e instanceof ForbiddenError) {
          formToasts.current.push(
            toast.notify({
              type: 'error',
              title: 'Error',
              message: `You don't have right permissions to ${e.action} ${e.subjectType}.`,
              autoClose: false,
            })
          );
          return;
        }

        if (isAPIErrorsObject(e)) {
          const errorsKeys = Object.keys(e.response.data.errors);
          const unregisteredErrors: string[] = [];

          if (e.response.data.message.trim()) {
            unregisteredErrors.push(e.response.data.message);
          }

          for (let i = 0; i < errorsKeys.length; i += 1) {
            const errorKey = errorsKeys[i];
            const errorMsg = e.response.data.errors[errorKey];

            if (ctx.control._fields[errorKey]) {
              ctx.setError(errorKey as any, { message: errorMsg[0] });
            } else {
              unregisteredErrors.push(`${errorKey} - ${errorMsg[0]}`);
            }
          }

          formToasts.current.push(
            toast.notify({
              type: 'error',
              title: 'Error',
              message: !unregisteredErrors.length
                ? 'There was a problem saving your data. If this persists, please contact support.'
                : () => (
                    <>
                      <p>There was a problem saving your data. If this persists, please contact support.</p>
                      <ul>
                        {unregisteredErrors.map((errorMsg, index) => (
                          <li key={index}>{errorMsg}</li>
                        ))}
                      </ul>
                    </>
                  ),
              autoClose: false,
            })
          );

          return;
        }

        if (isAPIErrorMessage(e)) {
          formToasts.current.push(
            toast.notify({
              type: 'error',
              title: 'Error',
              message: (
                <>
                  <p>There was a problem saving your data. If this persists, please contact support.</p>
                  <p>{e.response.data.message}</p>
                </>
              ),
              autoClose: false,
            })
          );

          return;
        }

        if (isAPIError(e)) {
          if (e.response) {
            if (e.response.status === 403) {
              formToasts.current.push(
                toast.notify({
                  type: 'error',
                  title: 'Error',
                  message: (
                    <>
                      <p>You do not have sufficient permissions to access this data.</p>
                      <p>{e.message}</p>
                    </>
                  ),
                  autoClose: false,
                })
              );
              return;
            }

            if (e.response.status === 404) {
              formToasts.current.push(
                toast.notify({
                  type: 'error',
                  title: 'Error',
                  message: (
                    <>
                      <p>Requested data does not exist.</p>
                      <p>{e.message}</p>
                    </>
                  ),
                  autoClose: false,
                })
              );

              return;
            }

            if (e.response.status >= 400 && e.response.status <= 499) {
              formToasts.current.push(
                toast.notify({
                  type: 'error',
                  title: 'Error',
                  message: (
                    <>
                      <p>There was a problem saving your data. If this persists, please contact support.</p>
                      <p>{e.message}</p>
                    </>
                  ),
                  autoClose: false,
                })
              );
              return;
            }

            if (e.response.status >= 500 && e.response.status <= 599) {
              formToasts.current.push(
                toast.notify({
                  type: 'error',
                  title: 'Error',
                  message: (
                    <>
                      <p>
                        The server currently is not able to process your request. If this persists, please contact
                        support.
                      </p>
                      <p>{e.message}</p>
                    </>
                  ),
                  autoClose: false,
                })
              );
              return;
            }
          }

          formToasts.current.push(
            toast.notify({
              type: 'error',
              title: 'Error',
              message: (
                <>
                  <p>Establishing server connection failed. Please try refreshing the page or check again later.</p>
                  <p>{e.message}</p>
                </>
              ),
              autoClose: false,
            })
          );

          return;
        }

        if (isClientErrorsObject(e)) {
          const errorsKeys = Object.keys(e);
          const unregisteredErrors: string[] = [];

          for (let i = 0; i < errorsKeys.length; i += 1) {
            const errorKey = errorsKeys[i];
            const errorMsg = e[errorKey];

            if (ctx.control._fields[errorKey]) {
              ctx.setError(errorKey as any, { message: errorMsg });
            } else {
              unregisteredErrors.push(`${errorKey} - ${errorMsg}`);
            }
          }

          formToasts.current.push(
            toast.notify({
              type: 'error',
              title: 'Error',
              message: !unregisteredErrors.length
                ? 'There was a problem validating your data. Please check error messages bellow input fields.'
                : () => (
                    <>
                      <p>
                        There was a problem validating your data. Please check error messages bellow input fields and
                        this message.
                      </p>
                      <ul>
                        {unregisteredErrors.map((errorMsg, index) => (
                          <li key={index}>{errorMsg}</li>
                        ))}
                      </ul>
                    </>
                  ),
              autoClose: false,
            })
          );

          return;
        }

        formToasts.current.push(
          toast.notify({
            type: 'error',
            title: 'Error',
            message: 'Unexpected error has occurred. Please try refreshing the page or check again later.',
            autoClose: false,
          })
        );
      }
    },
    [onSubmit, ctx, toast]
  );

  const rhfInvalidSubmitHandler = React.useCallback<RHF.SubmitErrorHandler<z.infer<S>>>(
    (errors, event) => {
      if (stopSubmitPropagation && event) {
        event.stopPropagation();
      }

      onInvalidSubmit?.(ctx.getValues(), errors, event);
    },
    [ctx, onInvalidSubmit, stopSubmitPropagation]
  );

  const submitHandler = React.useCallback<(event?: React.FormEvent<HTMLFormElement>) => void>(
    (event) => {
      if (stopSubmitPropagation && event) {
        event.stopPropagation();
      }
      ctx.handleSubmit(rhfSubmitHandler, rhfInvalidSubmitHandler)(event);
    },
    [ctx, rhfInvalidSubmitHandler, rhfSubmitHandler, stopSubmitPropagation]
  );

  return {
    context: { ...ctx, hasFloatingLabels, disableFieldsOnSubmitting, isFiltersForm },
    formProps: {
      onSubmit: submitHandler,
      className: classNames({ 'c-form--float-labels': hasFloatingLabels, 'fl-form': hasFloatingLabels }),
    },
  };
}

export const useCustomFormState = () => {
  return React.useContext(customFormState);
};

export default Form;
