import * as React from 'react';
import { ForbiddenError } from '@casl/ability';

import { useToast } from 'my-account/toast';
import { isAPIError, isAPIErrorMessage, isAPIErrorsObject, isClientErrorsObject } from 'utils/errors';

export const ErrorMessagesContent: React.VFC<{ error: unknown }> = ({ error }) => {
  if (error instanceof ForbiddenError) {
    return (
      <p>
        You don't have right permissions to {error.action} {error.subjectType}.
      </p>
    );
  }

  if (isAPIErrorsObject(error)) {
    const errorsKeys = Object.keys(error.response.data.errors);
    const unregisteredErrors: string[] = [];

    if (error.response.data.message.trim()) {
      unregisteredErrors.push(error.response.data.message);
    }

    for (let i = 0; i < errorsKeys.length; i += 1) {
      const errorKey = errorsKeys[i];
      const errorMsg = error.response.data.errors[errorKey];

      unregisteredErrors.push(`${errorKey} - ${errorMsg[0]}`);
    }

    return (
      <>
        <p>There was a problem processing your request. If this persists, please contact support.</p>
        {unregisteredErrors.length && (
          <ul>
            {unregisteredErrors.map((errorMsg, index) => (
              <li key={index}>{errorMsg}</li>
            ))}
          </ul>
        )}
      </>
    );
  }

  if (isAPIErrorMessage(error)) {
    return (
      <>
        <p>There was a problem processing your request. If this persists, please contact support.</p>
        <p>{error.response.data.message}</p>
      </>
    );
  }

  if (isAPIError(error)) {
    if (error.response) {
      if (error.response.status === 403) {
        return (
          <>
            <p>You do not have sufficient permissions to access this data.</p>
            <p>{error.message}</p>
          </>
        );
      }

      if (error.response.status === 404) {
        return (
          <>
            <p>Requested data does not exist.</p>
            <p>{error.message}</p>
          </>
        );
      }

      if (error.response.status >= 400 && error.response.status <= 499) {
        return (
          <>
            <p>There was a problem processing your request. If this persists, please contact support.</p>
            <p>{error.message}</p>
          </>
        );
      }

      if (error.response.status >= 500 && error.response.status <= 599) {
        return (
          <>
            <p>The server currently is not able to process your request. If this persists, please contact support.</p>
            <p>{error.message}</p>
          </>
        );
      }
    }

    return (
      <>
        <p>Establishing server connection failed. Please try refreshing the page or check again later.</p>
        <p>{error.message}</p>
      </>
    );
  }

  if (isClientErrorsObject(error)) {
    const errorsKeys = Object.keys(error);
    const unregisteredErrors: string[] = [];

    for (let i = 0; i < errorsKeys.length; i += 1) {
      const errorKey = errorsKeys[i];
      const errorMsg = error[errorKey];

      unregisteredErrors.push(`${errorKey} - ${errorMsg}`);
    }

    return !unregisteredErrors.length ? (
      <p>There was a problem validating your data. Please check error messages bellow input fields.</p>
    ) : (
      <>
        <p>
          There was a problem validating your data. Please check error messages bellow input fields and this message.
        </p>
        <ul>
          {unregisteredErrors.map((errorMsg, index) => (
            <li key={index}>{errorMsg}</li>
          ))}
        </ul>
      </>
    );
  }

  return <p>Unexpected error has occurred. Please try refreshing the page or check again later.</p>;
};

export const useErrorHandler = () => {
  const toast = useToast();
  const errorToasts = React.useRef<number[]>([]);

  const clearErrors = React.useCallback(() => {
    for (let i = 0; i <= errorToasts.current.length; i += 1) {
      toast.close(errorToasts.current[i]);
    }
    errorToasts.current = [];
  }, [toast]);

  const handleError = React.useCallback(
    (e: unknown, options: { title?: string; autoClose?: boolean; messageFooter?: React.ReactNode } = {}) => {
      const { title = 'Error', autoClose = false, messageFooter } = options;
      errorToasts.current.push(
        toast.notify({
          type: 'error',
          title,
          message: () => (
            <>
              <ErrorMessagesContent error={e} />
              {messageFooter}
            </>
          ),
          autoClose,
        })
      );
    },
    [toast]
  );

  return {
    clearErrors,
    handleError,
  };
};
