import * as React from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useMount } from 'react-use';
import { isError } from 'react-query';
import { AxiosResponse } from 'axios';
import { useFormContext } from 'react-hook-form';
import omit from 'lodash/omit';
import { subject } from '@casl/ability';

import { Paginated, ReportType } from '__generated-api__';
import api from 'api';
import { useAbility } from 'auth';
import { useQuery, BaseApiFn, GetQueryFnParams, GetPromiseReturnType, useMutation, UseQueryResult } from 'hooks/query';
import { setLocationSearchParam, setLocationSearchParams } from 'utils/router';
import Icon from 'components/icon';
import { Pagination } from 'components/Pagination';
import { DropdownLinks, DropdownLinksProps, IconDropdown } from 'components/Dropdown';
import { Offcanvas, OffcanvasStaticKeys, useOffcanvas } from 'components/Offcanvas';
import { useToast } from 'my-account/toast';
import { useErrorHandler } from 'my-account/utils/error-handler';
import { SearchFilter } from 'my-account/components/SearchFilter';
import { ClearFilters } from 'my-account/components/ClearFilters';

type BasePaginatedResponse = AxiosResponse<Paginated & { data: any[] }>;

export interface DataTableListingProps<
  QueryFn extends BaseApiFn<any[], BasePaginatedResponse>,
  AvailableSortOptions extends Record<string, string>
> {
  label: string;
  reportType?: ReportType;
  availableSortOptions: AvailableSortOptions;
  defaultSort: keyof AvailableSortOptions;
  defaultDirection?: 'asc' | 'desc';
  defaultPerPage?: 6 | 12 | 24 | 48;
  queryFn: QueryFn;
  queryFnParams: (
    filters: {
      page: number;
      perPage: number;
      sort?: keyof AvailableSortOptions;
      direction: 'asc' | 'desc';
      search?: string;
    },
    searchParams: URLSearchParams
  ) => GetQueryFnParams<QueryFn>;
  filters?: (query: UseQueryResult<QueryFn>) => React.ReactNode;
  headerControls?: React.ReactNode;
  children: (data: GetPromiseReturnType<QueryFn>['data']['data']) => React.ReactNode;
}

interface DataTableContextValue {
  addClearListener: (fn: () => void) => void;
  removeClearListener: (fn: () => void) => void;
  emitClear: () => void;
}

const dataTableContext = React.createContext<DataTableContextValue>({
  addClearListener: () => {
    throw new Error('Invalid!');
  },
  removeClearListener: () => {
    throw new Error('Invalid!');
  },
  emitClear: () => {
    throw new Error('Invalid!');
  },
});

const DataTableContextProvider: React.FC = ({ children }) => {
  const listeners = React.useRef<Array<() => void>>([]);

  const addClearListener = React.useCallback((fn: () => void) => {
    listeners.current.push(fn);
  }, []);
  const removeClearListener = React.useCallback((fn: () => void) => {
    listeners.current = listeners.current.filter((item) => item !== fn);
  }, []);
  const emitClear = React.useCallback(() => {
    for (let i = 0; i < listeners.current.length; i += 1) {
      listeners.current[i]();
    }
  }, []);

  return (
    <dataTableContext.Provider value={{ addClearListener, removeClearListener, emitClear }}>
      {children}
    </dataTableContext.Provider>
  );
};

export const useDataTableContext = () => {
  return React.useContext(dataTableContext);
};

export const DataTableClearFilterForm: React.VFC = () => {
  const { reset } = useFormContext();
  const { addClearListener, removeClearListener } = useDataTableContext();

  React.useEffect(() => {
    const fn = () => {
      reset();
    };

    addClearListener(fn);

    return () => {
      removeClearListener(fn);
    };
  }, [addClearListener, removeClearListener, reset]);

  return null;
};

const ClearFiltersWithEmitter = () => {
  const { emitClear } = useDataTableContext();

  return <ClearFilters onClear={emitClear} />;
};

// TODO: add ability to provide custom state instead of using url params
export const DataTableListing = <
  QueryFn extends BaseApiFn<any[], BasePaginatedResponse>,
  AvailableSortOptions extends Record<string, string>
>({
  label,
  reportType,
  availableSortOptions,
  defaultSort,
  defaultDirection = 'desc',
  defaultPerPage = 6,
  queryFn,
  queryFnParams,
  filters,
  headerControls,
  children,
}: DataTableListingProps<QueryFn, AvailableSortOptions>) => {
  const ability = useAbility();
  const toast = useToast();
  const { clearErrors, handleError } = useErrorHandler();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const offcanvas = useOffcanvas();
  const [createReport, createReportMutation] = useMutation(api.report.createReport, {
    onMutate() {
      clearErrors();
    },
    onError(e) {
      handleError(e);
    },
    onSuccess() {
      toast.notify({
        title: 'Success',
        type: 'success',
        message: 'Your request for report generation has been successfully sent.',
      });
    },
  });
  const canGenerateReport =
    typeof reportType !== 'undefined' && ability.can('create', subject('Report', { type: reportType }));
  const labelSlug = label.toLowerCase().replace(/\s+/g, '_');

  useMount(() => {
    if (localStorage.getItem(`is-${labelSlug}-filters-open`) === 'yes') {
      offcanvas.open(OffcanvasStaticKeys.Filters);
    }
  });

  React.useEffect(() => {
    if (offcanvas.activeOffcanvas === OffcanvasStaticKeys.Filters) {
      localStorage.setItem(`is-${labelSlug}-filters-open`, 'yes');
    } else {
      localStorage.removeItem(`is-${labelSlug}-filters-open`);
    }
  }, [labelSlug, offcanvas.activeOffcanvas]);

  let page = 1;
  if (searchParams.has('list-page')) {
    page = Number(searchParams.get('list-page'));

    if (Number.isNaN(page) || page < 1) {
      page = 1;
    }
  }

  const availablePerPage = [6, 12, 24, 48];
  let perPage: number = defaultPerPage;
  if (searchParams.has('perPage')) {
    const searchPerPage = Number(searchParams.get('perPage'));

    if (!Number.isNaN(searchPerPage) && availablePerPage.includes(searchPerPage)) {
      perPage = searchPerPage;
    }
  }

  let sort: keyof AvailableSortOptions = defaultSort;
  if (searchParams.has('sort')) {
    sort = searchParams.get('sort') ?? '';

    if (!Object.keys(availableSortOptions).includes(sort as string)) {
      sort = defaultSort;
    }
  }

  let direction: 'asc' | 'desc' = defaultDirection;
  if (searchParams.has('direction')) {
    const searchDirection = searchParams.get('direction');
    if (searchDirection === 'asc' || searchDirection === 'desc') {
      direction = searchDirection;
    }
  }

  let search: string | undefined = undefined;
  if (searchParams.has('search')) {
    search = searchParams.get('search') ?? undefined;
  }

  const queryParams = queryFnParams(
    {
      page,
      perPage,
      sort,
      direction,
      search,
    },
    searchParams
  );

  const listQuery = useQuery(queryFn, queryParams);
  const [queryResponse, queryState] = listQuery;

  const isFiltersOffcanvasOpen = offcanvas.activeOffcanvas === OffcanvasStaticKeys.Filters;

  return (
    <DataTableContextProvider>
      <div className="c-listing">
        <div className="c-listing__header u-justify-between@sm u-justify-start@lg">
          <div className="c-listing__header-search u-hidden@lg">
            <SearchFilter placeholder={`Search for ${label}`} />
          </div>
          <div
            className={classNames('c-listing__header-filter', {
              'is-active': isFiltersOffcanvasOpen,
            })}
          >
            <span
              className={classNames('c-listing__filter-toggle', {
                'is-active': isFiltersOffcanvasOpen,
              })}
            >
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                className={classNames('c-tag', 'c-tag--large', {
                  'c-tag--light': !isFiltersOffcanvasOpen,
                  'c-tag--dark': isFiltersOffcanvasOpen,
                })}
                onClick={(event) => {
                  event.preventDefault();

                  offcanvas.toggle(OffcanvasStaticKeys.Filters);
                }}
              >
                <Icon name="controls" className="o-svg-icon" />
                <span>{isFiltersOffcanvasOpen ? 'Hide' : 'Show'} filters</span>
              </a>
            </span>
            <ClearFiltersWithEmitter />
          </div>
          <div className="c-listing__header-views">
            <div className="c-listing__header-results">
              {queryResponse && queryResponse.data.from && queryResponse.data.to && (
                <p>
                  Showing{' '}
                  <strong>
                    {queryResponse.data.from}-{queryResponse.data.to}
                  </strong>{' '}
                  of <strong>{queryResponse.data.total}</strong> results
                </p>
              )}
            </div>
            <div className="c-listing__header-controls">
              <DropdownLinks
                label="Results per page:"
                description="Select number"
                value={String(perPage)}
                options={availablePerPage.reduce((res, value) => {
                  const out = { ...res };
                  out[`${value}`] = {
                    to: (loc) => setLocationSearchParam(loc, 'perPage', String(value)),
                    children: value,
                  };

                  return out;
                }, {} as DropdownLinksProps['options'])}
              />
              <DropdownLinks
                label="Sort:"
                description="Sort items by:"
                value={sort as string}
                options={Object.keys(availableSortOptions).reduce((res, sortKey) => {
                  const out = { ...res };
                  out[sortKey] = {
                    to: (loc) =>
                      setLocationSearchParams(loc, {
                        sort: sortKey,
                        direction:
                          (sort || defaultSort) === sortKey && direction === defaultDirection
                            ? defaultDirection === 'asc'
                              ? 'desc'
                              : 'asc'
                            : undefined,
                      }),
                    children: ({ isToggle }) => (
                      <>
                        {availableSortOptions[sortKey as keyof typeof availableSortOptions]}
                        {!isToggle && (sort || defaultSort) === sortKey && (
                          <span>
                            {direction === 'asc' ? (
                              <svg width="17" height="13" xmlns="http://www.w3.org/2000/svg">
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="M12.186.836c.057.025.11.06.154.104l4.32 4.32a.481.481 0 01-.68.679l-3.5-3.5v9.88a.48.48 0 01-.96 0V2.44l-3.5 3.5a.48.48 0 01-.68-.68L11.66.943a.488.488 0 01.527-.106z"
                                    fill="#FFF"
                                  />
                                  <path
                                    d="M3.876 12.175a.326.326 0 01-.102-.068l-2.88-2.88a.32.32 0 01.452-.453l2.334 2.334V4.52a.32.32 0 01.64 0v6.588l2.334-2.334a.32.32 0 01.452.452l-2.878 2.879a.325.325 0 01-.351.07z"
                                    fill="#000"
                                    opacity=".3"
                                  />
                                </g>
                              </svg>
                            ) : (
                              <svg width="17" height="13" xmlns="http://www.w3.org/2000/svg">
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="M5.414 12.763a.488.488 0 01-.154-.103L.94 8.34a.481.481 0 01.68-.68l3.5 3.501V1.28a.48.48 0 01.96 0v9.881l3.5-3.5a.48.48 0 01.68.679L5.94 12.657a.488.488 0 01-.527.106z"
                                    fill="#FFF"
                                  />
                                  <path
                                    d="M13.724 4.424c.038.016.073.04.102.069l2.88 2.88a.32.32 0 01-.452.453L13.92 5.492v6.588a.32.32 0 01-.64 0V5.492l-2.334 2.334a.32.32 0 01-.452-.453l2.878-2.878a.325.325 0 01.351-.07z"
                                    fill="#000"
                                    opacity=".3"
                                  />
                                </g>
                              </svg>
                            )}
                          </span>
                        )}
                      </>
                    ),
                  };

                  return out;
                }, {} as DropdownLinksProps['options'])}
              />
              {headerControls}
              {typeof reportType !== 'undefined' && canGenerateReport && (
                <IconDropdown
                  menuLabel="Actions"
                  options={{ report: 'Generate Report' }}
                  onChange={(value) => {
                    if (value === 'report') {
                      if (createReportMutation.isLoading) {
                        return;
                      }

                      createReport([{ type: reportType, ...omit(queryParams, 'type') }]);
                    }
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div className="c-listing__filter-wrapper u-hidden@lg">
          <Offcanvas offcanvasKey={OffcanvasStaticKeys.Filters} title={`Filter ${label}`}>
            <div className="c-filters">
              <div className="c-filters__group">
                <div className="c-filters__container">
                  <SearchFilter placeholder={`Search for ${label}`} />
                  {filters?.(listQuery)}
                </div>
              </div>
            </div>
          </Offcanvas>
        </div>
        <div className="c-listing__row">
          <div
            className={classNames('c-listing__sidebar', {
              'is-active': isFiltersOffcanvasOpen,
            })}
          >
            <div className="c-listing__sidebar-container">
              <div className="c-filters">
                <div className="c-filters__group u-hidden u-block@lg">
                  <div className="c-filters__container">
                    <SearchFilter placeholder={`Search for ${label}`} />
                    {filters?.(listQuery)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="c-listing__content">
            {queryState.isError && (
              <div className="c-listing__none">
                <div className="c-listing__none-figure">
                  <Icon name="error" className="o-svg-icon" />
                </div>
                <p className="c-listing__none-title">Error</p>
                {isError(queryState.error) ? (
                  <p>{queryState.error.message}</p>
                ) : (
                  <p>Unexpected error has occurred. Please try refreshing the page or check again later.</p>
                )}
              </div>
            )}
            {queryState.isLoading && (
              <div className="c-listing__none">
                <div className="c-listing__none-spinner"></div>
                <p className="c-listing__none-title">Loading...</p>
              </div>
            )}
            {queryState.isSuccess && (
              <>
                {queryResponse && queryResponse.data.data.length ? (
                  <>
                    {children(queryResponse.data.data)}

                    <Pagination current_page={page} last_page={queryResponse.data.last_page} />
                  </>
                ) : (
                  <div className="c-listing__none">
                    <div className="c-listing__none-figure">
                      <Icon name="search-results" className="o-svg-icon" />
                    </div>
                    <p className="c-listing__none-title">No results to show</p>
                    <p>Try tweaking the filters or searching for something more general.</p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </DataTableContextProvider>
  );
};
