import * as React from 'react';

import api from 'api';
import { SelectListingItemProps, SelectListingItem } from 'my-account/components/SelectListingItem';

export interface SelectTopicProps<IsMulti extends boolean = false>
  extends Omit<
    SelectListingItemProps<typeof api.resourceCategories.listResourceCategories, IsMulti>,
    'queryFn' | 'getOptionLabel' | 'getOptionValue' | 'queryParams'
  > {
  queryParams?: SelectListingItemProps<typeof api.resourceCategories.listResourceCategories, IsMulti>['queryParams'];
}

export const SelectTopic = <IsMulti extends boolean = false>({
  queryParams = {},
  ...props
}: SelectTopicProps<IsMulti>) => {
  return (
    <SelectListingItem
      {...props}
      queryFn={api.resourceCategories.listResourceCategories}
      queryParams={{ sort: 'name', direction: 'asc', ...queryParams }}
      getOptionLabel={(topic) => topic.term}
      getOptionValue={(topic) => String(topic.id)}
    />
  );
};
