import { subject } from '@casl/ability';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { z } from 'zod';

import { LocationManagerUserRoleEnum, Order, OrderStatus, RegularUserRoleEnum, ReportType } from '__generated-api__';
import api from 'api';
import { useAbility, useAuthenticatedUser } from 'auth';
import { AutoSubmit } from 'components/Form/AutoSubmit';
import Form from 'components/Form/Form';
import SelectField from 'components/Form/SelectField';
import Icon from 'components/icon';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { DataTableHero } from 'my-account/components/DataTable/Hero';
import { DataTableClearFilterForm, DataTableListing } from 'my-account/components/DataTable/Listing';
import { MainHero } from 'my-account/components/MainHero';
import { SelectCompany } from 'my-account/components/SelectCompany';
import { SelectLocation } from 'my-account/components/SelectLocation';
import { DisplayPrice } from 'my-account/utils/currency';
import { setLocationSearchParams } from 'utils/router';

const OrderStatusZodEnum = z.nativeEnum(OrderStatus).or(z.literal(''));
const ordersFiltersSchema = z.object({
  company: z
    .object({
      id: z.number().positive(),
      name: z.string().nullable(),
    })
    .nullable()
    .optional(),
  location: z
    .object({
      id: z.number().positive(),
      name: z.string().nullable(),
    })
    .nullable()
    .optional(),
  status: OrderStatusZodEnum,
  type: z.nativeEnum({ evolution: 'evolution', wholesaler: 'wholesaler' }).or(z.literal('')).optional(),
});

const LocationFilter: React.VFC<{ companyId?: number }> = ({ companyId }) => {
  return (
    <SelectLocation
      elStyle="fill"
      name="location"
      label="Location"
      queryParams={companyId ? { companyId } : undefined}
      selectProps={{ autoComplete: 'off', isClearable: true }}
      small
      canSelectAnyLocation={true}
    />
  );
};

const CompanyDependentLocationFilter: React.VFC = () => {
  const { watch } = useFormContext<z.infer<typeof ordersFiltersSchema>>();
  const company = watch('company');

  return <LocationFilter companyId={company?.id} />;
};

const OrdersFilters: React.VFC = () => {
  const user = useAuthenticatedUser();
  const ability = useAbility();
  const history = useHistory();
  const location = useLocation();
  const searchParams = React.useMemo(() => new URLSearchParams(location.search), [location.search]);
  const canFilterByCompany = ability.can('read', subject('Order', { company_id: 0 }));
  const canFilterByLocation = ability.can('read', subject('Order', { company_id: user.company_id, location_id: 0 }));

  const initialValueParse = ordersFiltersSchema.safeParse({
    company:
      searchParams.has('company') && canFilterByCompany
        ? { id: Number(searchParams.get('company')!), name: 'Loading...' }
        : undefined,
    location:
      searchParams.has('location') && canFilterByLocation
        ? { id: Number(searchParams.get('location')!), name: 'Loading...' }
        : undefined,
    status: searchParams.get('status') ?? '',
  });

  return (
    <>
      <Form
        schema={ordersFiltersSchema}
        initialValues={initialValueParse.success ? initialValueParse.data : {}}
        onSubmit={async (values) => {
          history.replace(
            setLocationSearchParams(location, {
              company: values.company && canFilterByCompany ? String(values.company.id) : undefined,
              location: values.location && canFilterByLocation ? String(values.location.id) : undefined,
              evolution: values.type === 'evolution' ? 'true' : undefined,
              wholesaler: values.type === 'wholesaler' ? 'true' : undefined,
              status: values.status ? values.status : undefined,
              page: undefined,
              'list-page': undefined,
            })
          );
        }}
        isFiltersForm
      >
        <DataTableClearFilterForm />
        <AutoSubmit />
        {canFilterByCompany && (
          <>
            <SelectCompany
              elStyle="fill"
              name="company"
              label="Company"
              selectProps={{ autoComplete: 'off', isClearable: true }}
              small
            />
            <CompanyDependentLocationFilter />
            <SelectField name="type" label="Company Type" elStyle="fill" emptyOptionLabel="Any Type" small>
              <option value="wholesaler">Wholesaler</option>
              <option value="evolution">Evolution</option>
            </SelectField>
          </>
        )}
        {!canFilterByCompany && canFilterByLocation && <LocationFilter companyId={user.company_id} />}
        <SelectField name="status" label="Status" elStyle="fill" allowEmpty emptyOptionLabel="Any Status" small>
          <option value={OrderStatus.Open}>Open</option>
          <option value={OrderStatus.Closed}>Closed</option>
          <option value={OrderStatus.Shipped}>Shipped</option>
          <option value={OrderStatus.Canceled}>Canceled</option>
          <option value={OrderStatus.ChangeRequested}>Change Requested</option>
          <option value={OrderStatus.Changed}>Changed</option>
          <option value={OrderStatus.ReturnRequested}>Return Requested</option>
          <option value={OrderStatus.Returned}>Returned</option>
        </SelectField>
      </Form>
    </>
  );
};

const ListOrderStatus: React.VFC<{ order: Order }> = ({ order }) => {
  if (order.status === OrderStatus.Open) {
    return <p className="c-data-card__subtitle">Open</p>;
  }

  if (order.status === OrderStatus.Canceled) {
    return <p className="c-data-card__subtitle">Canceled</p>;
  }

  if (order.status === OrderStatus.Closed) {
    return <p className="c-data-card__subtitle">Delivered</p>;
  }

  if (order.status === OrderStatus.Shipped) {
    if (order.ship_date) {
      return (
        <p className="c-data-card__subtitle c-data-card__subtitle--status c-data-card__subtitle--info">
          Expected by {format(utcToZonedTime(parseISO(order.ship_date), 'America/New_York'), 'MMM d')}
        </p>
      );
    }

    return <p className="c-data-card__subtitle">Shipped</p>;
  }

  if (order.status === OrderStatus.ChangeRequested) {
    return (
      <p className="c-data-card__subtitle c-data-card__subtitle--status c-data-card__subtitle--warning">
        Change requested
      </p>
    );
  }

  if (order.status === OrderStatus.ReturnRequested) {
    return (
      <p className="c-data-card__subtitle c-data-card__subtitle--status c-data-card__subtitle--error">
        Return requested
      </p>
    );
  }

  if (order.status === OrderStatus.Changed) {
    return <p className="c-data-card__subtitle">Changed</p>;
  }

  if (order.status === OrderStatus.Returned) {
    return <p className="c-data-card__subtitle">Returned</p>;
  }

  return null;
};

export default function OrdersPage() {
  useDocumentTitle('Orders');

  return (
    <>
      <MainHero />

      <DataTableHero title="Orders" />

      <section className="c-block c-block--spacing-b-extra-small c-block--spacing-b@md">
        <div className="o-container-fluid">
          <DataTableListing
            label="orders"
            availableSortOptions={{
              order_date: 'Order Date',
              updated_at: 'Latest Update',
              po_number: 'PO Number',
              number: 'Order Number',
            }}
            defaultSort="order_date"
            queryFn={api.order.listOrders}
            queryFnParams={(filters, searchParams) => {
              let status: z.infer<typeof OrderStatusZodEnum> = '';
              if (searchParams.has('status')) {
                const stateParamValue = OrderStatusZodEnum.safeParse(searchParams.get('status')!);

                if (stateParamValue.success) {
                  status = stateParamValue.data;
                }
              }

              let companyId: number | undefined;
              let locationId: number | undefined;

              if (searchParams.has('company')) {
                const company = Number(searchParams.get('company')!);

                if (!Number.isNaN(company) && company > 0) {
                  companyId = company;
                }
              }

              if (searchParams.has('location')) {
                const location = Number(searchParams.get('location')!);

                if (!Number.isNaN(location) && location > 0) {
                  locationId = location;
                }
              }

              let evolution: boolean | undefined = Boolean(searchParams.get('evolution'));
              let wholesaler: boolean | undefined = Boolean(searchParams.get('wholesaler'));
              if (!evolution) evolution = undefined;
              if (!wholesaler) wholesaler = undefined;

              return { ...filters, status: status ? status : undefined, companyId, locationId, evolution, wholesaler };
            }}
            filters={() => <OrdersFilters />}
            reportType={ReportType.Order}
          >
            {(data) => (
              <>
                {data.map((order) => (
                  <OrderRow key={order.id} order={order} />
                ))}
              </>
            )}
          </DataTableListing>
        </div>
      </section>
    </>
  );
}

export const OrderRow: React.VFC<{ order: Order }> = ({ order }) => {
  const user = useAuthenticatedUser();
  const userHidePricing =
    user.direct_buyer &&
    (user.role === LocationManagerUserRoleEnum.LocationManager || user.role === RegularUserRoleEnum.User);
  return (
    <Link key={order.id} to={`/orders/${order.id}`} className="c-data-card c-data-card--orders">
      <div className="c-data-card__column c-data-card__column--first">
        <ListOrderStatus order={order} />
        <p className="c-data-card__title">{order.number}</p>
        <p className="c-data-card__label">Order #</p>
      </div>

      <div className="c-data-card__column">
        <p>
          {order.order_date ? (
            format(utcToZonedTime(parseISO(order.order_date), 'America/New_York'), 'MMM d y')
          ) : (
            <>&mdash;</>
          )}
        </p>
        <p className="c-data-card__label">Order Date</p>
      </div>

      <div className="c-data-card__column">
        <p>{order.po_number}</p>
        <p className="c-data-card__label">PO #</p>
      </div>

      {!userHidePricing && (
        <div className="c-data-card__column">
          <p>
            <DisplayPrice value={Number(order.total ?? '0')} /> USD
          </p>
          <p className="c-data-card__label">Total</p>
        </div>
      )}

      <div className="c-data-card__column c-data-card__column--last">
        <button className="c-link-cta-basic c-link-cta--small" type="button">
          <span>Order Details</span>
          <Icon name="arrow" className="o-svg-icon o-svg-right" />
        </button>
      </div>
      <div className="c-data-card-toolbar">
        <span className="c-data-card-toolbar__label">Download:</span>
        {order.packing_slip_url !== null && (
          <button
            onClick={(event) => {
              event.preventDefault();
              window.open(order.packing_slip_url!, '__blank');
            }}
            type="button"
          >
            <Icon name="item-install" className="o-icon c-data-card-toolbar__icon c-data-card-toolbar__icon--large" />
          </button>
        )}
        {order.invoice_url !== null && (
          <button
            onClick={(event) => {
              event.preventDefault();
              window.open(order.invoice_url!, '__blank');
            }}
            type="button"
          >
            <Icon name="invoice" className="o-icon c-data-card-toolbar__icon c-data-card-toolbar__icon--large" />
          </button>
        )}
      </div>
    </Link>
  );
};
