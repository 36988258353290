import * as React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { z } from 'zod';
import { subject } from '@casl/ability';

import {
  CompanyManagerUserRoleEnum,
  LocationManagerUserRoleEnum,
  ReportType,
  SalesRepUserRoleEnum,
  UserRole,
} from '__generated-api__';
import api from 'api';
import { useAbility } from 'auth';
import Icon from 'components/icon';
import Form from 'components/Form/Form';
import { AutoSubmit } from 'components/Form/AutoSubmit';
import SelectField from 'components/Form/SelectField';
import { setLocationSearchParams } from 'utils/router';
import { MainHero } from 'my-account/components/MainHero';
import { DataTableHero } from 'my-account/components/DataTable/Hero';
import { DataTableClearFilterForm, DataTableListing } from 'my-account/components/DataTable/Listing';
import { caProvinces, usStates } from 'my-account/utils/states';
import { SelectUser } from 'my-account/components/SelectUser';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { getObjectKeys } from 'my-account/utils/object';

const companiesFiltersSchema = z.object({
  state: z.nativeEnum(usStates).or(z.nativeEnum(caProvinces)).or(z.literal('')),
  type: z.nativeEnum({ evolution: 'evolution', wholesaler: 'wholesaler' }).or(z.literal('')).optional(),
  status: z
    .union([z.literal('1'), z.literal('0')])
    .optional()
    .default('1'),
  rep: z
    .object({
      id: z.number().positive(),
      first_name: z.string(),
      last_name: z.string(),
      email: z.string(),
    })
    .nullable()
    .optional(),
});

const CompaniesFilters: React.VFC = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = React.useMemo(() => new URLSearchParams(location.search), [location.search]);
  const ability = useAbility();
  const canListRepresentatives = ability.can('list', subject('User', { role: SalesRepUserRoleEnum.SalesRep }));

  const initialValueParse = companiesFiltersSchema.safeParse(
    Object.assign(
      {
        state: searchParams.get('state') ?? '',
        status: searchParams.get('status') ?? undefined,
      },
      searchParams.has('rep') && canListRepresentatives
        ? { rep: { id: Number(searchParams.get('rep')!), first_name: 'Loading...', last_name: '', email: '' } }
        : undefined
    )
  );

  return (
    <>
      <Form
        schema={companiesFiltersSchema}
        initialValues={initialValueParse.success ? initialValueParse.data : {}}
        onSubmit={async (values) => {
          history.replace(
            setLocationSearchParams(location, {
              state: values.state ? values.state : undefined,
              evolution: values.type === 'evolution' ? 'true' : undefined,
              wholesaler: values.type === 'wholesaler' ? 'true' : undefined,
              status: values.status === '0' ? '0' : undefined,
              rep: values.rep ? String(values.rep.id) : undefined,
              page: undefined,
              'list-page': undefined,
            })
          );
        }}
        isFiltersForm
      >
        <DataTableClearFilterForm />
        <AutoSubmit />
        <SelectField
          name="state"
          label="State/Province"
          allowEmpty
          emptyOptionLabel="Any State/Province"
          elStyle="fill"
          small
        >
          <optgroup label="United States">
            {getObjectKeys(usStates).map((state) => (
              <option key={usStates[state]} value={usStates[state]}>
                {state}
              </option>
            ))}
          </optgroup>
          <optgroup label="Canada">
            {getObjectKeys(caProvinces).map((state) => (
              <option key={caProvinces[state]} value={caProvinces[state]}>
                {state}
              </option>
            ))}
          </optgroup>
        </SelectField>
        <SelectField name="status" label="Status" elStyle="fill" allowEmpty small>
          <option value="1">Active</option>
          <option value="0">Archived</option>
        </SelectField>
        <SelectField name="type" label="Type" elStyle="fill" emptyOptionLabel="Any Type" small>
          <option value="wholesaler">Wholesaler</option>
          <option value="evolution">Evolution</option>
        </SelectField>
        {canListRepresentatives && (
          <SelectUser
            name="rep"
            label="Representative"
            elStyle="fill"
            small
            queryParams={{ role: UserRole.SalesRep }}
            selectProps={{ isClearable: true }}
          />
        )}
      </Form>
    </>
  );
};

export default function CompaniesPage() {
  useDocumentTitle('Companies');
  const ability = useAbility();
  const canCreateCompany = ability.can('create', 'Company');
  const canListRepresentatives = ability.can('list', subject('User', { role: SalesRepUserRoleEnum.SalesRep }));

  return (
    <>
      <MainHero />

      <DataTableHero
        title="Companies"
        buttons={
          canCreateCompany ? (
            <>
              <Link to="/companies/add" className="c-button c-button--primary c-button--small u-my-spacer-base-small">
                <Icon name="add" className="o-svg-icon o-svg-right" />
                <span>Add new company</span>
              </Link>
            </>
          ) : undefined
        }
      />

      <section className="c-block c-block--spacing-b-extra-small c-block--spacing-b@md">
        <div className="o-container-fluid">
          <DataTableListing
            label="companies"
            availableSortOptions={{
              created_at: 'Latest',
              name: 'Alphabetical',
              updated_at: 'Recently Updated',
            }}
            defaultSort="name"
            defaultDirection="asc"
            queryFn={api.company.listCompanies}
            queryFnParams={(filters, searchParams) => {
              let state: string | undefined = searchParams.get('state') ?? '';
              if (!state.trim()) {
                state = undefined;
              }

              let evolution: boolean | undefined = Boolean(searchParams.get('evolution'));
              let wholesaler: boolean | undefined = Boolean(searchParams.get('wholesaler'));
              if (!evolution) evolution = undefined;
              if (!wholesaler) wholesaler = undefined;
              const status: 1 | 0 | undefined =
                searchParams.get('status') === '1' ? 1 : searchParams.get('status') === '0' ? 0 : undefined;

              let salesRepId: number | undefined =
                searchParams.has('rep') && canListRepresentatives ? Number(searchParams.get('rep')!) : undefined;
              if (!salesRepId || Number.isNaN(salesRepId) || salesRepId < 1) {
                salesRepId = undefined;
              }

              return { ...filters, evolution, wholesaler, state, status, salesRepId };
            }}
            filters={() => <CompaniesFilters />}
            reportType={ReportType.Company}
          >
            {(data) => (
              <>
                {data.map((company) => (
                  <Link key={company.id} to={`/companies/${company.id}`} className="c-data-card c-data-card--companies">
                    <div className="c-data-card__column c-data-card__column--first">
                      <p
                        className="c-data-card__subtitle"
                        title={company.addresses.map(({ bill_to_id }) => bill_to_id).join(' ')}
                      >
                        {company.evolution && 'Evolution - '}
                        {company.addresses.length > 2
                          ? 'Multiple IDs'
                          : company.addresses.length > 0
                          ? `${company.addresses.map(({ bill_to_id }) => bill_to_id).join(' ')}`
                          : ''}
                      </p>
                      <p className="c-data-card__title">{company.name}</p>
                      <p className="c-data-card__label">Company Name</p>
                    </div>

                    <div className="c-data-card__column">
                      <p>
                        {company.count_locations} Place{company.count_locations !== 1 && 's'}
                      </p>
                      <p className="c-data-card__label">Location</p>
                    </div>

                    <div className="c-data-card__column">
                      <p>
                        {company.users.filter((user) => user.role === CompanyManagerUserRoleEnum.CompanyManager).length}{' '}
                        CMs
                      </p>
                      <p className="c-data-card__label">Company Managers</p>
                    </div>

                    <div className="c-data-card__column">
                      <p>
                        {
                          company.users.filter((user) => user.role === LocationManagerUserRoleEnum.LocationManager)
                            .length
                        }{' '}
                        LMs
                      </p>
                      <p className="c-data-card__label">Location Managers</p>
                    </div>

                    <div className="c-data-card__column c-data-card__column--last">
                      <button className="c-link-cta-basic c-link-cta--small" type="button">
                        <span>Company Details</span>
                        <Icon name="arrow" className="o-svg-icon o-svg-right" />
                      </button>
                    </div>
                  </Link>
                ))}
              </>
            )}
          </DataTableListing>
        </div>
      </section>
    </>
  );
}
