import * as React from 'react';
import classNames from 'classnames';

import { Project, ProjectVersion, ProjectVersionStatus } from '__generated-api__';
import Icon from 'components/icon';
import { PreviewDocumentPdf } from './DocumentPreviewCanvas';
import { ViewPublishedDocumentButton } from './ViewPreviewButton';
import DownloadDocumentButton from './DownloadDocumentButton';
import ShareButton from './ShareButton';

const PublishedDocumentBuilder: React.VFC<{
  project: Project;
  projectVersion: ProjectVersion & {
    status:
      | typeof ProjectVersionStatus.Published
      | typeof ProjectVersionStatus.Publishing
      | typeof ProjectVersionStatus.Complete;
  };
  isPublicView?: boolean;
}> = ({ project, projectVersion, isPublicView }) => {
  const [currentPagePreview, setCurrentPagePreview] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);

  return (
    <>
      <section className="c-block c-block--spacing-t-extra-small c-block--spacing-b-extra-small c-block--bg-light">
        <div className="o-container-fluid">
          <div className="o-row">
            <div className="o-col-6@md o-col-3@lg">
              <p className="u-mb-spacer-base-small u-text-xs u-font-medium u-uppercase u-text-neutral-800">
                {isPublicView ? 'View Project' : 'Document Builder'}
              </p>
              {!isPublicView && (
                <p className="c-note">
                  Use the building blocks below and in the right panel to design your own custom document.
                </p>
              )}
              <div className="u-mb-spacer-base-large">
                <ViewPublishedDocumentButton project={project} projectVersion={projectVersion} />

                {projectVersion.status === ProjectVersionStatus.Published && (
                  <>
                    <hr className="u-my-spacer-base"></hr>
                    <DownloadDocumentButton project={project} projectVersion={projectVersion} isPublicView />
                    <ShareButton project={project} />
                  </>
                )}
              </div>
            </div>
            <div className="o-col-9@lg u-order-2@lg u-px-spacer-section-small@lg">
              <div className="c-document-preview-wrap">
                <div className="c-document-preview slick-initialized slick-slider">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    href="#"
                    className={classNames('c-slider__arrow c-slider__arrow--prev slick-arrow', {
                      'slick-disabled': currentPagePreview === 0,
                    })}
                    aria-disabled={currentPagePreview === 0}
                    style={{ display: 'block' }}
                    onClick={(event) => {
                      event.preventDefault();

                      setCurrentPagePreview((val) => {
                        return Math.max(0, val - 1);
                      });
                    }}
                  >
                    <Icon name="chevron" className="c-slider__arrow-thick o-icon o-svg-icon" />
                  </a>
                  <div className="c-document-preview__item">
                    {isPublicView && project.public_token ? (
                      <PreviewDocumentPdf
                        className="c-document-preview__page"
                        pageNumber={currentPagePreview + 1}
                        publicToken={project.public_token}
                        setTotalPages={setTotalPages}
                      />
                    ) : (
                      <PreviewDocumentPdf
                        className="c-document-preview__page"
                        pageNumber={currentPagePreview + 1}
                        project={{ id: project.id, versionId: projectVersion.id }}
                        setTotalPages={setTotalPages}
                      />
                    )}
                  </div>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    href="#"
                    className={classNames('c-slider__arrow c-slider__arrow--next slick-arrow', {
                      'slick-disabled': currentPagePreview >= totalPages - 1,
                    })}
                    aria-disabled={currentPagePreview >= totalPages - 1}
                    style={{ display: 'block' }}
                    onClick={(event) => {
                      event.preventDefault();

                      setCurrentPagePreview((val) => {
                        return Math.min(totalPages - 1, val + 1);
                      });
                    }}
                  >
                    <Icon name="chevron" className="c-slider__arrow-thick o-icon o-svg-icon" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PublishedDocumentBuilder;
