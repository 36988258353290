import * as React from 'react';
import { z } from 'zod';

import { FileModelImageTypeEnum, FileModelImage, FileVisibility } from '__generated-api__';
import api from 'api';
import Icon from 'components/icon';
import Form from 'components/Form/Form';
import { AutoSubmit } from 'components/Form/AutoSubmit';
import {
  DataTableClearFilterForm,
  DataTableListing,
  DataTableListingProps,
} from 'my-account/components/DataTable/Listing';
import { CheckboxWithCountField } from 'components/Form/CheckboxField';
import { FiltersAccordion } from 'components/Accordion';

const mediaLibraryFiltersSchema = z.object({
  visibility_hidden: z.boolean().optional(),
  visibility_public: z.boolean().optional(),
  visibility_company: z.boolean().optional(),
  visibility_location: z.boolean().optional(),
  visibility_private: z.boolean().optional(),
});

const MediaLibraryFilters: React.VFC<{
  counts?: { hidden: number; public: number; company: number; location: number; private: number };
  filters: z.infer<typeof mediaLibraryFiltersSchema>;
  setFilters: React.Dispatch<React.SetStateAction<z.infer<typeof mediaLibraryFiltersSchema>>>;
}> = ({ counts, filters, setFilters }) => {
  return (
    <>
      <Form
        schema={mediaLibraryFiltersSchema}
        initialValues={filters}
        onSubmit={async (values) => {
          setFilters(values);
        }}
        stopSubmitPropagation
        isFiltersForm
      >
        <DataTableClearFilterForm />
        <AutoSubmit />

        <FiltersAccordion title="File Ownership">
          <div className="ais-RefinementList">
            <ul className="ais-RefinementList-list">
              {/* <CheckboxWithCountField label="Hidden" name="visibility_hidden" count={counts?.hidden} /> */}
              <CheckboxWithCountField label="Public" name="visibility_public" count={counts?.public} />
              {/* <CheckboxWithCountField label="Company" name="visibility_company" count={counts?.company} /> */}
              {/* <CheckboxWithCountField label="Location" name="visibility_location" count={counts?.location} /> */}
              <CheckboxWithCountField label="Private" name="visibility_private" count={counts?.private} />
            </ul>
          </div>
        </FiltersAccordion>
      </Form>
    </>
  );
};

const MediaLibraryModalContent: React.VFC<{ title: string; onInsert: (file: FileModelImage) => void }> = ({
  title,
  onInsert,
}) => {
  const [filters, setFilters] = React.useState<z.infer<typeof mediaLibraryFiltersSchema>>({
    visibility_hidden: undefined,
    visibility_public: undefined,
    visibility_company: undefined,
    visibility_location: undefined,
    visibility_private: undefined,
  });

  const availableSortOptions = {
    created_at: 'Latest',
    name: 'Alphabetical',
    updated_at: 'Recently Updated',
  } as const;

  const dataTableQueryFnParams = React.useCallback<
    DataTableListingProps<typeof api.file.listFiles, typeof availableSortOptions>['queryFnParams']
  >(
    (listingFilters) => {
      const visibility: FileVisibility[] = [];

      if (filters.visibility_hidden) {
        visibility.push(FileVisibility.Hidden);
      }

      if (filters.visibility_public) {
        visibility.push(FileVisibility.Public);
      }

      if (filters.visibility_company) {
        visibility.push(FileVisibility.Company);
      }

      if (filters.visibility_location) {
        visibility.push(FileVisibility.Location);
      }

      if (filters.visibility_private) {
        visibility.push(FileVisibility.Private);
      }

      return {
        ...listingFilters,
        visibility: visibility.length ? visibility : undefined,
      };
    },
    [filters]
  );

  return (
    <div className="c-block c-block--spacing-t-small c-block--spacing-b">
      <div className="o-container-fluid">
        <div className="o-row">
          <div className="o-col-12">
            <div className="c-block__header c-block__header--hor">
              <div className="c-block__header-content u-items-center u-block u-flex@sm">
                <div>
                  <h6 className="c-headline u-text-neutral-500">{title}</h6>
                  <h4 className="u-mr-spacer-base-small">Add File</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DataTableListing
          label="media library"
          availableSortOptions={availableSortOptions}
          defaultSort="created_at"
          defaultDirection="desc"
          defaultPerPage={48}
          queryFn={api.file.listFiles}
          queryFnParams={dataTableQueryFnParams}
          filters={(query) => (
            <MediaLibraryFilters
              filters={filters}
              setFilters={setFilters}
              counts={query[0]?.data?.filters?.visibility}
            />
          )}
        >
          {(data) => (
            <ul className="o-stack-2 o-stack-3@sm o-stack-4@md c-card__row--media">
              {data
                .filter((file): file is FileModelImage => file.type === FileModelImageTypeEnum.Image)
                .map((file) => (
                  <li key={file.id}>
                    <article
                      className="c-card c-card--media c-card--link"
                      onClick={(event) => {
                        event.preventDefault();
                        onInsert(file);
                      }}
                    >
                      <div className="c-card__body">
                        <div className="c-card__select-btn">
                          <span className="c-button c-button--full c-button--square c-button--primary">
                            <Icon name="add" className="o-svg-icon" />
                            Insert
                          </span>
                        </div>
                        <figure className="c-card__figure">
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <a
                            href="#"
                            onClick={(event) => {
                              event.preventDefault();
                            }}
                          >
                            <img src={file.thumbnail} alt={file.name} />
                          </a>
                        </figure>
                        <header className="c-card__header">
                          <h4 className="c-card__title">
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a
                              href="#"
                              className="c-card__link"
                              onClick={(event) => {
                                event.preventDefault();
                              }}
                            >
                              {file.name}
                            </a>
                          </h4>
                        </header>
                      </div>
                    </article>
                  </li>
                ))}
            </ul>
          )}
        </DataTableListing>
      </div>
    </div>
  );
};

export default MediaLibraryModalContent;
