import * as React from 'react';
import { z } from 'zod';
import { useHistory } from 'react-router-dom';
import { ForbiddenError, subject } from '@casl/ability';
import { useQueryClient } from 'react-query';
import merge from 'lodash/merge';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { useFormContext, useWatch } from 'react-hook-form';
import classNames from 'classnames';

import {
  Project,
  ProjectStatus,
  ProjectVersionStatus,
  ProjectVersionStatusUpdate,
  PageUpdate,
  ProjectVersion,
  EmptyPageType,
} from '__generated-api__';
import api from 'api';
import { AuthStatus, useAbility, useAuth } from 'auth';
import { useMutation } from 'hooks/query';
import Form from 'components/Form/Form';
import SubmitButton from 'components/Form/SubmitButton';
import Icon from 'components/icon';
import { Button } from 'components/Button';
import { MultiIconDropdown } from 'components/Dropdown';
import InputField from 'components/Form/InputField';
import { useToast } from 'my-account/toast';
import { getUserName } from 'my-account/utils/user';
import { useCopyProject, useProjectVersion } from 'my-account/hooks/project';
import { SelectSalesforceCustomer } from 'my-account/components/SelectSalesforceCustomer';
import { SelectSalesforceOpportunity } from 'my-account/components/SelectSalesforceOpportunity';
import {
  projectToFormValue,
  SalesforceCustomer,
  ProjectDefaultValuesType,
  ProjectUpdateSchema,
  formValueToProjectUpdate,
} from 'my-account/validations/project';
import DocumentBuilder from 'my-account/components/SPB/DocumentBuilder';
import { Modal } from 'my-account/components/Modal';
import PublishedDocumentBuilder from 'my-account/components/SPB/PublishedDocumentBuilder';
import { isPublishedVersion } from 'my-account/utils/project';

interface SalesforceCustomerType {
  field: z.infer<typeof SalesforceCustomer>;
}

const SelectSalesforceOpportunityWithCustomer: React.VFC = () => {
  const { setValue } = useFormContext<SalesforceCustomerType>();
  const customer = useWatch<SalesforceCustomerType, 'field'>({ name: 'latest.customer' as 'field' });

  return (
    <SelectSalesforceOpportunity
      name="latest.opportunity"
      label="Opportunity"
      elStyle="fill"
      queryParams={customer ? { salesforceCustomerId: customer.id } : undefined}
      selectProps={{
        isClearable: true,
        onChange: (value) => {
          if (value && value.customer) {
            setValue('latest.customer' as 'field', value.customer);
          }
        },
      }}
    />
  );
};

export function EditProjectForm(
  props:
    | {
        project: Project;
        projectVersion: ProjectVersion & {
          status:
            | typeof ProjectVersionStatus.Published
            | typeof ProjectVersionStatus.Publishing
            | typeof ProjectVersionStatus.Complete;
        };
        isPublicView: true;
        defaultValues?: undefined;
      }
    | { project: Project; projectVersion: ProjectVersion; isPublicView?: false; defaultValues?: undefined }
    | {
        project: undefined;
        projectVersion: undefined;
        isPublicView?: false;
        defaultValues?: {
          customer?: ProjectDefaultValuesType['latest']['customer'];
          opportunity?: ProjectDefaultValuesType['latest']['opportunity'];
        };
      }
) {
  const { versionNumber } = useProjectVersion(props.project);
  const auth = useAuth();
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();
  const ability = useAbility();
  const [createProject] = useMutation(api.project.createProject, {
    onSettled: () => queryClient.invalidateQueries(api.project.listProjects.getQueryKey()[0]),
  });
  const [updateProject, updateProjectState] = useMutation(api.project.updateProject, {
    onSettled: (data, error, variables) => {
      return Promise.all([
        queryClient
          .cancelQueries(api.project.listProjects.getQueryKey()[0])
          .then(() => queryClient.invalidateQueries(api.project.listProjects.getQueryKey()[0])),
        queryClient
          .cancelQueries(api.project.getProject.getQueryKey({ id: data ? data.data.id : variables[0].id })[0])
          .then(() =>
            queryClient.invalidateQueries(
              api.project.getProject.getQueryKey({ id: data ? data.data.id : variables[0].id })[0]
            )
          ),
      ]);
    },
  });
  const [deleteProject] = useMutation(api.project.deleteProject, {
    onSettled: async (data, error, variables) => {
      return Promise.all([
        queryClient
          .cancelQueries(api.project.listProjects.getQueryKey()[0])
          .then(() => queryClient.invalidateQueries(api.project.listProjects.getQueryKey()[0])),
        queryClient
          .cancelQueries(api.project.getProject.getQueryKey({ id: variables[0].id })[0])
          .then(() => queryClient.invalidateQueries(api.project.getProject.getQueryKey({ id: variables[0].id })[0])),
      ]);
    },
  });
  const [copyProject, copyProjectState] = useCopyProject();
  const [isRestoreModalOpen, setIsRestoreModalOpen] = React.useState(false);

  const canCreate = ability.can('create', 'Project');
  const canSubmit = props.project ? ability.can('update', subject('Project', props.project)) : canCreate;
  const canRemove = props.project ? ability.can('delete', subject('Project', props.project)) : false;

  const projectUser = props.project
    ? props.project.user
    : auth.status === AuthStatus.LoggedIn || auth.status === AuthStatus.LoggingOut
    ? auth.currentUser
    : undefined;

  return (
    <Form
      key={`${props.project?.id ?? 'add'}_${versionNumber}_${props.projectVersion?.id}`}
      schema={ProjectUpdateSchema}
      onInvalidSubmit={(values, errors, event) => {
        const formAction = (event?.target as HTMLFormElement)?.action ?? '';
        if (window.Sentry) {
          window.Sentry.addBreadcrumb({
            category: 'spb',
            level: 'error',
            message: 'SPB form validation errors data',
            data: {
              projectId: props.project?.id ?? 'add',
              projectVersion: props.projectVersion,
              versionNumber,
              formAction,
              errors: JSON.stringify(errors),
              values: JSON.stringify(values),
            },
          });
          window.Sentry.captureException(new Error('SPB form validation failed'));
        } else {
          console.error({
            category: 'spb',
            level: 'error',
            message: 'SPB form validation errors data.',
            data: {
              projectId: props.project?.id ?? 'add',
              projectVersion: props.projectVersion,
              versionNumber,
              formAction,
              errors: JSON.stringify(errors),
              values: JSON.stringify(values),
            },
          });
        }
      }}
      onSubmit={async ({ submit_button, ...input }, ctx, event) => {
        const action = (event?.target as HTMLFormElement)?.action ?? '';

        const data = formValueToProjectUpdate(input, action.endsWith('/draft'));
        console.log('project data', data);
        ForbiddenError.from(ability).throwUnlessCan(
          props.project ? 'update' : 'create',
          subject('Project', merge({}, props.project, data))
        );

        if (typeof props.project === 'undefined') {
          const res = await createProject([{ projectUpdate: data }]);
          ctx.reset(projectToFormValue(res.data, versionNumber));
          history.push(`/specification-packages/${res.data.id}`);

          toast.notify({
            type: 'success',
            title: 'Success',
            message: "You've successfully created a new Specification Package.",
          });
        } else {
          const res = await updateProject([{ id: props.project.id, projectUpdate: data }]);
          ctx.reset(projectToFormValue(res.data, versionNumber));

          toast.notify({
            type: 'success',
            title: 'Success',
            message: "You've successfully updated the Specification Package.",
          });
        }
      }}
      initialValues={projectToFormValue(props.project, versionNumber, props.defaultValues)}
      className="u-mb-0"
      hasFloatingLabels
      formProps={{ mode: 'onSubmit' }}
      style={{
        position: 'relative',
        zIndex: 5,
      }}
    >
      <section className="c-block c-block--spacing-b-extra-small c-block--higher">
        <div className="o-container-fluid">
          <div className="o-row">
            <div className="o-col-12">
              <div className="c-order-summary">
                <header className="c-order-summary__header u-items-start u-mb-spacer-base-large">
                  <ul className="c-order-summary-info">
                    <li className="c-order-summary-info__column">
                      <p className="c-order-summary-info__title">
                        {format(
                          props.projectVersion ? parseISO(props.projectVersion.created_at) : new Date(),
                          'MMM d y'
                        )}
                      </p>

                      <p className="c-order-summary-info__label">Created on</p>
                    </li>

                    <li className="c-order-summary-info__column">
                      <p className="c-order-summary-info__title">{projectUser ? getUserName(projectUser).name : ''}</p>

                      <p className="c-order-summary-info__label">Created by</p>
                    </li>

                    <li className="c-order-summary-info__column">
                      {props.project?.status === ProjectStatus.Disabled ? (
                        <p className="c-order-summary-info__title c-order-summary-info__title--error">Deleted</p>
                      ) : typeof props.projectVersion === 'undefined' ||
                        props.projectVersion.status === ProjectVersionStatus.Draft ? (
                        <p className="c-order-summary-info__title c-order-summary-info__title--draft">Draft</p>
                      ) : props.projectVersion?.status === ProjectVersionStatus.Publishing ? (
                        <p className="c-order-summary-info__title c-order-summary-info__title--warning">Publishing</p>
                      ) : props.projectVersion?.status === ProjectVersionStatus.PublishFailed ? (
                        <p className="c-order-summary-info__title c-order-summary-info__title--error">
                          Publishing Error
                        </p>
                      ) : typeof props.project !== 'undefined' &&
                        typeof props.projectVersion !== 'undefined' &&
                        versionNumber !== 'latest' ? (
                        <div className="u-flex@sm">
                          <p className="c-order-summary-info__title">Archived</p>

                          {!props.isPublicView && (
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <a
                              href="#"
                              className="c-tag c-tag--stroke u-ml-spacer-base@sm"
                              onClick={(event) => {
                                event.preventDefault();
                                history.push(`/specification-packages/${props.project!.id}`);
                              }}
                            >
                              <Icon name="back" className="o-svg-icon" />
                              Back to Current
                            </a>
                          )}
                        </div>
                      ) : props.projectVersion?.status === ProjectVersionStatus.Published ? (
                        <p className="c-order-summary-info__title c-order-summary-info__title--published">Published</p>
                      ) : null}

                      <p className="c-order-summary-info__label">Project Status</p>
                    </li>
                  </ul>
                  {!props.isPublicView &&
                    typeof props.project !== 'undefined' &&
                    props.project.versions.filter((item) => item.id !== props.project!.latest_version_id).length >
                      0 && (
                      <div className="c-listing__header">
                        <MultiIconDropdown
                          buttonLabel="Version History"
                          value={String(versionNumber)}
                          options={[
                            [
                              'Current Version',
                              {
                                latest: (
                                  <>
                                    {props.project.latest.name}{' '}
                                    <small>{format(parseISO(props.project.latest.created_at), 'MMM d y')}</small>
                                  </>
                                ),
                              },
                            ],
                            [
                              'Older Versions',
                              Object.fromEntries(
                                props.project.versions
                                  .filter((item) => item.id !== props.project!.latest_version_id)
                                  .map((item) => [
                                    String(item.id),
                                    <>
                                      {item.name} <small>{format(parseISO(item.created_at), 'MMM d y')}</small>
                                    </>,
                                  ])
                              ),
                            ] as [string, Record<string, React.ReactNode>],
                          ]}
                          onChange={(version) => {
                            if (version === 'latest') {
                              history.push(`/specification-packages/${props.project!.id}`);
                            } else {
                              history.push(`/specification-packages/${props.project!.id}/${version}`);
                            }
                          }}
                        />
                      </div>
                    )}
                </header>
              </div>
            </div>
          </div>

          <fieldset
            disabled={
              props.isPublicView ||
              props.projectVersion?.status === ProjectVersionStatus.Published ||
              props.projectVersion?.status === ProjectVersionStatus.Publishing ||
              props.projectVersion?.status === ProjectVersionStatus.Complete
            }
          >
            <div className="o-row">
              <div className="o-col-3@md">
                <p className="u-mb-spacer-base-small u-text-xs u-font-medium u-uppercase u-text-neutral-800">
                  {props.isPublicView ? 'Project Info' : 'Project Settings'}
                </p>
                {!props.isPublicView && (
                  <p className="c-note">
                    To create a new project, enter the project name and details. Be sure to include a version name for
                    version control purposes.
                  </p>
                )}
              </div>

              <div
                className={classNames(
                  'o-col-9@md',
                  'u-px-spacer-section-small@lg',
                  props.isPublicView ? 'o-col-9@lg' : 'o-col-6@lg'
                )}
              >
                <div className="o-row o-row--small-gutters">
                  <div className="o-col-12">
                    <InputField name="name" label="Project Name" elStyle="fill" />
                  </div>
                </div>
                <div className="o-row o-row--small-gutters">
                  <div className="o-col-12">
                    {props.isPublicView ||
                    isPublishedVersion(props.projectVersion) ||
                    props.projectVersion?.status === ProjectVersionStatus.Publishing ? (
                      <InputField name="latest.customer.name" label="Account" elStyle="fill" />
                    ) : (
                      <SelectSalesforceCustomer
                        name="latest.customer"
                        label="Account"
                        elStyle="fill"
                        selectProps={{ isClearable: true }}
                      />
                    )}
                  </div>
                </div>
                <div className="o-row o-row--small-gutters">
                  <div className="o-col-12">
                    {props.isPublicView ||
                    isPublishedVersion(props.projectVersion) ||
                    props.projectVersion?.status === ProjectVersionStatus.Publishing ? (
                      <InputField name="latest.opportunity.name" label="Opportunity" elStyle="fill" />
                    ) : (
                      <SelectSalesforceOpportunityWithCustomer />
                    )}
                  </div>
                </div>
                <div className="o-row o-row--small-gutters">
                  <div className="o-col-6@sm">
                    <InputField name="latest.name" label="Project Version Name" elStyle="fill" />
                  </div>
                  <div className="o-col-6@sm">
                    <InputField name="latest.number" label="Quote Number" elStyle="fill" />
                  </div>
                </div>
              </div>

              {!props.isPublicView && (
                <div className="o-col-3@lg o-offset-3@md u-ml-0@lg">
                  <div className="c-info u-mb-spacer-base">
                    <div className="c-info__header">
                      <div className="c-info__icon u-text-secondary">
                        <Icon name="info" className="o-svg-icon" />
                      </div>
                      <p>Helpful Hints</p>
                    </div>
                    <div className="c-info__body">
                      <div className="c-info__content">
                        <p>
                          Be sure the information entered here matches the information on file in Salesforce. Also, a
                          unique Project Version Name is important when creating or saving multiple versions of a
                          project.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </fieldset>
        </div>
      </section>

      {props.isPublicView ? (
        <PublishedDocumentBuilder project={props.project} projectVersion={props.projectVersion} isPublicView={true} />
      ) : typeof props.project !== 'undefined' &&
        typeof props.projectVersion !== 'undefined' &&
        isPublishedVersion(props.projectVersion) ? (
        <PublishedDocumentBuilder project={props.project} projectVersion={props.projectVersion} />
      ) : (
        <DocumentBuilder project={props.project} projectVersion={props.projectVersion} />
      )}

      {!props.isPublicView && (
        <section className="c-block c-block--spacing-t-extra-small c-block--spacing-b-extra-small">
          <div className="o-container-fluid">
            <div className="o-row">
              <div className="o-col-9@lg o-offset-3@lg u-pl-spacer-section-small@lg">
                <div className="o-row u-items-center">
                  <div className="o-col-6@md">
                    <div className="c-form-footer u-mb-spacer-base-small">
                      {typeof props.project !== 'undefined' &&
                      typeof props.projectVersion !== 'undefined' &&
                      (isPublishedVersion(props.projectVersion) ||
                        props.projectVersion.status === ProjectVersionStatus.Publishing) ? (
                        <>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <Button
                            className="c-button c-button--dark u-mb-spacer-base-small"
                            disabled={!canSubmit}
                            onClick={(event) => {
                              event.preventDefault();
                              setIsRestoreModalOpen(true);
                            }}
                          >
                            <span>Restore as new version</span>
                          </Button>
                          <Modal
                            isOpen={isRestoreModalOpen}
                            onRequestClose={() => {
                              setIsRestoreModalOpen(false);
                            }}
                          >
                            <div className="c-modal">
                              {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */}
                              <a
                                href="#"
                                className="c-modal-close"
                                onClick={(event) => {
                                  event.preventDefault();
                                  setIsRestoreModalOpen(false);
                                }}
                              />

                              <div className="c-modal__main">
                                <div className="c-block c-block--spacing-t-small c-block--spacing-b">
                                  <div className="o-container-fluid">
                                    <div className="o-row">
                                      <div className="o-col-12">
                                        <h4>Restore as a New Version</h4>
                                        <p>
                                          By clicking Restore, you’ll restore this version as the most recent version of
                                          this project.
                                        </p>
                                        <Button
                                          className="c-button--md"
                                          disabled={updateProjectState.isLoading}
                                          isLoading={updateProjectState.isLoading}
                                          onClick={async (event) => {
                                            event.preventDefault();
                                            const projectVersionNumber = `v${props.projectVersion!.serial}`;
                                            await updateProject([
                                              {
                                                id: props.project!.id,
                                                projectUpdate: {
                                                  name: props.project!.name,
                                                  latest: {
                                                    ...props.projectVersion!,
                                                    id: null,
                                                    description: props.projectVersion!.description || undefined,
                                                    number: props.projectVersion!.number || undefined,
                                                    customer_id: props.projectVersion!.customer?.id ?? null,
                                                    opportunity_id: props.projectVersion!.opportunity?.id ?? null,
                                                    status: ProjectVersionStatusUpdate.Draft,
                                                    pages: props.projectVersion!.pages.map((page) => {
                                                      return {
                                                        ...page,
                                                        id: null,
                                                        blocks:
                                                          page.type !== EmptyPageType.Empty
                                                            ? page.blocks.map((block) => {
                                                                return { ...block, id: null };
                                                              })
                                                            : undefined,
                                                      };
                                                    }) as PageUpdate[],
                                                  },
                                                },
                                              },
                                            ]);
                                            history.push(`/specification-packages/${props.project!.id}`);
                                            toast.notify({
                                              type: 'success',
                                              title: 'Success',
                                              message: `The "${
                                                props.project!.name
                                              }" ${projectVersionNumber} specification package version was cloned successfully.`,
                                            });
                                            setIsRestoreModalOpen(false);
                                          }}
                                        >
                                          <span>Restore</span>
                                        </Button>{' '}
                                        <Button
                                          className="c-button--md"
                                          variant="white"
                                          disabled={updateProjectState.isLoading}
                                          onClick={(event) => {
                                            event.preventDefault();
                                            setIsRestoreModalOpen(false);
                                          }}
                                        >
                                          <span>Cancel</span>
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Modal>
                        </>
                      ) : (
                        <>
                          <SubmitButton
                            variant="dark"
                            disabled={!canSubmit}
                            className="u-mb-spacer-base-small"
                            onClick={(event) => {
                              if ((event.target as HTMLButtonElement).form) {
                                (event.target as HTMLButtonElement).form!.action = '/draft';
                              }
                            }}
                          >
                            Save Draft
                          </SubmitButton>{' '}
                          <SubmitButton
                            variant="secondary"
                            disabled={!canSubmit}
                            className="u-mb-spacer-base-small"
                            onClick={(event) => {
                              if ((event.target as HTMLButtonElement).form) {
                                (event.target as HTMLButtonElement).form!.action = '/publish';
                              }
                            }}
                          >
                            Publish
                          </SubmitButton>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="o-col-6@md u-text-right@md">
                    <ul className="c-divider-list">
                      {canCreate &&
                        typeof props.project !== 'undefined' &&
                        typeof props.projectVersion !== 'undefined' && (
                          <li className="c-divider-list__item">
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a
                              href="#"
                              className="c-link-cta-basic c-link-cta--small"
                              onClick={async (event) => {
                                event.preventDefault();
                                const res = await copyProject(props.project!, props.projectVersion!);
                                history.push(`/specification-packages/${res.data.id}`);
                                toast.notify({
                                  type: 'success',
                                  title: 'Success',
                                  message: (
                                    <p>The "{props.project!.name}" specification package was copied successfully.</p>
                                  ),
                                });
                              }}
                            >
                              <Icon name="copy" className="o-svg-icon o-svg-larger" />
                              {copyProjectState.isLoading ? <span>Cloning...</span> : <span>Clone project</span>}
                            </a>
                          </li>
                        )}
                      {canRemove &&
                        typeof props.project !== 'undefined' &&
                        typeof props.projectVersion !== 'undefined' && (
                          <li className="c-divider-list__item">
                            {props.project.status !== ProjectStatus.Disabled ? (
                              /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
                              <a
                                href="#"
                                className="c-link-cta-basic c-link-cta--small u-mt-spacer-base u-mt-0@md u-mb-0"
                                onClick={async (event) => {
                                  event.preventDefault();

                                  if (
                                    window.confirm(
                                      `Are you sure that you really want to delete "${
                                        props.project!.name
                                      }" Specification Package?`
                                    )
                                  ) {
                                    await deleteProject([{ id: props.project!.id }]);
                                    history.push('/specification-packages');
                                    toast.notify({
                                      type: 'success',
                                      title: 'Success',
                                      message: `The "${
                                        props.project!.name
                                      }" specification package was deleted successfully.`,
                                    });
                                  }
                                }}
                              >
                                <Icon name="trash" className="o-svg-icon o-svg-larger" />
                                <span>Delete Project</span>
                              </a>
                            ) : (
                              // eslint-disable-next-line jsx-a11y/anchor-is-valid
                              <a
                                href="#"
                                className="c-link-cta-basic c-link-cta--small u-mt-spacer-base u-mt-0@md u-mb-0"
                                onClick={async (event) => {
                                  event.preventDefault();

                                  if (
                                    window.confirm(
                                      `Are you sure that you really want to restore "${
                                        props.project!.name
                                      }" specification package?`
                                    )
                                  ) {
                                    await updateProject([
                                      {
                                        id: props.project!.id,
                                        projectUpdate: {
                                          name: props.project!.name,
                                          latest: {
                                            ...props.projectVersion!,
                                            id: null,
                                            description: props.projectVersion!.description || undefined,
                                            number: props.projectVersion!.number || undefined,
                                            customer_id: props.projectVersion!.customer?.id ?? null,
                                            opportunity_id: props.projectVersion!.opportunity?.id ?? null,
                                            status: ProjectVersionStatusUpdate.Draft,
                                            pages: props.projectVersion!.pages.map((page) => {
                                              return {
                                                ...page,
                                                id: null,
                                                blocks:
                                                  page.type !== EmptyPageType.Empty
                                                    ? page.blocks.map((block) => {
                                                        return { ...block, id: null };
                                                      })
                                                    : undefined,
                                              };
                                            }) as PageUpdate[],
                                          },
                                        },
                                      },
                                    ]);
                                    toast.notify({
                                      type: 'success',
                                      title: 'Success',
                                      message: `The "${
                                        props.project!.name
                                      }" specification package was restored successfully.`,
                                    });
                                  }
                                }}
                              >
                                <Icon name="export" className="o-svg-icon o-svg-larger" />
                                <span>Reactivate Project</span>
                              </a>
                            )}
                          </li>
                        )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </Form>
  );
}
