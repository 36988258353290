import Icon from 'components/icon';
import { MainHero } from 'my-account/components/MainHero';
import useDocumentTitle from 'hooks/useDocumentTitle';

export default function Page404Error() {
  useDocumentTitle('Not Found');

  return (
    <>
      <MainHero />

      <section className="c-block c-block--spacing-t-extra-small c-block--spacing-t-small@md c-block--spacing-b-extra-small c-block--spacing-b-small@md c-block--height-semifull@xs">
        <div className="o-container-fluid">
          <div className="o-row u-flex-grow u-items-center">
            <div className="o-col-12">
              <div className="c-listing__none">
                <div className="c-listing__none-figure">
                  <Icon name="handyman" className="o-svg-icon" />
                </div>
                <p className="c-listing__none-title">
                  Hmm... it seems the page you are looking for is missing. We'll look into it.
                </p>
                <p>Our experienced team is on it! In the meantime, try visiting our homepage or searching our site.</p>
              </div>
            </div>
          </div>

          <div className="c-block__pattern c-block__pattern--light3 c-block__pattern--top3 c-block__overlay--opacity-20">
            <Icon name="pattern" className="o-svg-icon" />
          </div>
        </div>
      </section>
    </>
  );
}
