import * as React from 'react';

import { ProductRelation } from '__generated-api__';
import { getTransformedSalsifyImage } from 'my-account/utils/salsify';

const ProductImage: React.VFC<{ product: Pick<ProductRelation, 'main_image'> }> = ({ product }) => {
  const image = product.main_image;

  if (!image) {
    return null;
  }

  return (
    <div>
      <img
        src={getTransformedSalsifyImage(image, ['w_160', 'h_160', 'c_pad'])}
        alt={image.salsify_name}
        width="160"
        height="160"
        className="c-order-summary-item__image"
      />
    </div>
  );
};

export default ProductImage;
