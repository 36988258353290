import * as React from 'react';
import { connectStateResults } from 'react-instantsearch-dom';
import { StateResultsProvided } from 'react-instantsearch-core';
import { SearchState } from 'search/product-search';
import ProductSearchStore from 'search/stores/product-search-store';
import { ProductHitInterface } from 'search/components/product-search/product-hit';

interface CustomStateResultsReporterProps extends StateResultsProvided<ProductHitInterface> {
  store: ProductSearchStore;
  searchState: SearchState;

  /**
   * This can be null although the interface says it can't.
   */
  // searchResults: SearchResults<ProductHitInterface>;

  /**
   * This is zero based, it shows 0 both when there are no results or when on the first page.
   */
  // searchResults.page: number

  /**
   * This is 0 when there are no results and 1 when there's only one result.
   */
  // searchResults.nbPages
}

class CustomStateResultsReporter extends React.Component<CustomStateResultsReporterProps> {
  state = {};

  static getDerivedStateFromProps(props: CustomStateResultsReporterProps) {
    // console.log('CustomStateResultsReporter::getDerivedStateFromProps::props', props);
    const { store, searchResults, searchState } = props;
    // const { finishes = [] } = searchState.refinementList || {};

    store.setSearchState(searchState);

    if (searchResults) {
      store.setPage(searchResults.page + 1);
      store.setNbPages(searchResults.nbPages);
      store.setNbHits(searchResults.nbHits);
      // store.setHitsPerPage(searchResults.hitsPerPage);
    }

    // store.setRefinmentListFinishes( finishes );

    return null;
  }

  render() {
    // console.log('CustomStateResultsReporter::render::props', this.props);
    return <></>;
  }
}

const StateResultsReporter = connectStateResults(CustomStateResultsReporter);

export default StateResultsReporter;
