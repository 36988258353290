import * as React from 'react';
import wordpressData from 'data';
import SpriteIcon from 'search/components/sprite-icon';

const SelectDropdown: React.VFC<{
  currentValue?: string | number;
  items:
    | [{ label: string; value: string }, ...Array<{ label: string; value: string }>]
    | [{ label: string; value: number }, ...Array<{ label: string; value: number }>];
  label: string;
  subtitle: string;
  onChange: (value: string | number) => void;
}> = ({ currentValue, items, label, subtitle, onChange }) => {
  const [state, setState] = React.useState({
    isOpen: false,
    selected:
      typeof currentValue !== 'undefined' && items.findIndex((item) => currentValue === item.value) > 0
        ? items.findIndex((item) => currentValue === item.value)
        : 0,
  });

  const wrapperRef = React.createRef<HTMLDivElement>();

  const handleClickOutside = (event: MouseEvent) => {
    if (wrapperRef && wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      if (state.isOpen) {
        setState({ ...state, isOpen: false });
      }
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    // returned function will be called on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <div className={'c-dropdown-wrapper js-dropdown ' + (state.isOpen ? 'has-dropdown-open' : '')} ref={wrapperRef}>
      <a
        href={wordpressData.productsUrl}
        className="c-tag c-tag--light c-tag--large c-dropdown-toggle"
        onClick={(event) => {
          event.preventDefault();
          setState({ ...state, isOpen: !state.isOpen });
        }}
      >
        <span className="c-dropdown-desc">{label}</span>
        <span className="c-dropdown-value">{items[state.selected].label}</span>
      </a>
      <div className="c-dropdown c-dropdown--right">
        <div className="c-dropdown__header c-dropdown__header--sm">
          <span className="c-dropdown__title">{subtitle}</span>
          <span className="c-dropdown__toggle">
            <SpriteIcon name="close-thin" classes={['o-svg-icon']} />
          </span>
        </div>
        <div className="c-dropdown__body">
          <ul className="c-dropdown__list">
            {items.map((item, index) => (
              <li key={item.value} className={items[state.selected].value === item.value ? 'is-selected' : ''}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href={wordpressData.productsUrl}
                  onClick={(event) => {
                    event.preventDefault();
                    setState({ selected: index, isOpen: false });
                    onChange(item.value);
                  }}
                >
                  {item.label}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SelectDropdown;
