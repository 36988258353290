import * as React from 'react';
import classNames from 'classnames';
import { useController } from 'react-hook-form';

interface CheckboxFieldProps {
  name: string;
  label: string;
  helpText?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  inputProps?: Omit<JSX.IntrinsicElements['input'], 'name' | 'type' | 'disabled' | 'className' | 'placeholder'>;
  small?: boolean;
  checkboxStyle?: 'checkbox' | 'toggle';
}

export const CheckboxField: React.VFC<CheckboxFieldProps> = ({
  name,
  label,
  helpText,
  disabled,
  className,
  inputProps,
  small,
  checkboxStyle,
}) => {
  const fieldController = useController({ name });
  const { value, ...fieldProps } = fieldController.field;
  const inputElProps: JSX.IntrinsicElements['input'] = {
    ...inputProps,
    ...fieldProps,
    checked: typeof inputProps?.checked !== 'undefined' ? inputProps.checked : value || false,
    id: fieldProps.name,
    disabled,
    onChange: (event) => {
      inputProps?.onChange?.(event);
      fieldProps.onChange(event);
    },
    onBlur: (event) => {
      inputProps?.onBlur?.(event);
      fieldProps.onBlur();
    },
  };
  const error = fieldController.fieldState.error;

  return (
    <div
      className={classNames(
        'c-form-element',
        {
          'c-form-element--checkbox': checkboxStyle === 'checkbox',
          'c-form-element--style-line': checkboxStyle === 'toggle',
          'c-form-element--toggle': checkboxStyle === 'toggle',
          'c-form-element--small': small,
          'c-form-element--error': typeof error !== 'undefined',
        },
        className
      )}
    >
      <div className="c-form-element__field">
        <input {...inputElProps} type="checkbox" />
        <label htmlFor={name} className={classNames({ 'u-mb-0': checkboxStyle === 'toggle' })}>
          {label}
        </label>
      </div>
      {typeof error !== 'undefined' && (
        <ul className="c-form-element--error__list">
          <li>{error.message}</li>
        </ul>
      )}
      {typeof helpText !== 'undefined' && <p className="c-note">{helpText}</p>}
    </div>
  );
};

interface CheckboxWithCountFieldProps {
  name: string;
  label: string;
  disabled?: boolean;
  inputProps?: Omit<JSX.IntrinsicElements['input'], 'name' | 'type' | 'disabled' | 'className' | 'placeholder'>;
  count?: number;
}

export const CheckboxWithCountField: React.VFC<CheckboxWithCountFieldProps> = ({
  name,
  label,
  disabled,
  inputProps,
  count,
}) => {
  const fieldController = useController({ name });
  const { value, ...fieldProps } = fieldController.field;
  const inputElProps: JSX.IntrinsicElements['input'] = {
    ...inputProps,
    ...fieldProps,
    checked: value || false,
    id: fieldProps.name,
    disabled,
    onChange: (event) => {
      inputProps?.onChange?.(event);
      fieldProps.onChange(event);
    },
    onBlur: (event) => {
      inputProps?.onBlur?.(event);
      fieldProps.onBlur();
    },
  };
  const error = fieldController.fieldState.error;

  return (
    <>
      <li
        className={classNames('ais-RefinementList-item', {
          'ais-RefinementList-item--selected': value,
          // 'c-form-element--error': typeof error !== 'undefined',
        })}
      >
        <label className="ais-RefinementList-label">
          <input {...inputElProps} type="checkbox" className="ais-RefinementList-checkbox" />
          <span className="ais-RefinementList-labelText">{label}</span>
          {typeof count !== 'undefined' && <span className="ais-RefinementList-count">{count}</span>}
        </label>
        {typeof error !== 'undefined' && (
          <div className="c-form-element">
            <ul className="c-form-element--error__list">
              <li>{error.message}</li>
            </ul>
          </div>
        )}
      </li>
    </>
  );
};

export default CheckboxField;
