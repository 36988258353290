import { makeObservable, observable, action, autorun, computed } from 'mobx';
import _pick from 'lodash/pick';

import wordpressData from 'data';
import { SearchState, urlToSearchState } from 'search/product-search';
import SearchStore from 'search/stores/search-store';

export enum ViewMode {
  Grid = 'grid',
  List = 'list',
}

export const DEFAULT_HITS_PER_PAGE = 6;

export interface VariantAffectingFilters {
  query: string | undefined;
  availabilities: string[];
  finishes: string[];
  special_attributes: string[];
  is_obsolete: string[];
  business_segments: string[];
}

interface SavedProductSearchStore {
  showFilters: boolean;
  viewMode: ViewMode;
  availabilityFilterOpened: boolean;
  categoryFilterOpened: boolean;
  showerFilterOpened: boolean;
  businessSegmentFilterOpened: boolean;
  collectionFilterOpened: boolean;
  finishFilterOpened: boolean;
  specialAttributeFilterOpened: boolean;
  numberOfHandlesFilterOpened: boolean;
  installationMountTypeFilterOpened: boolean;
  flowRateGpmFilterOpened: boolean;
  obsoleteFilterOpened: boolean;
  searchState: SearchState;

  accessoriesFilterOpened: boolean;
  faucetsFilterOpened: boolean;
  tubFaucetsTypeFilterOpened: boolean;
  showerValvesTypeFilterOpened: boolean;
  mixingValvesTypeFilterOpened: boolean;
  repairPartsFilterOpened: boolean;
  evolutionDevicesFilterOpened: boolean;
}

export default class ProductSearchStore implements SearchStore {
  showFilters: boolean = true;
  viewMode: ViewMode = ViewMode.Grid;
  nbHits: number = 0;
  hasResults: boolean = false;
  /**
   * one based, will be one both when no results or when on first page
   */
  page: number = 1;
  nbPages: number = 0;

  searchState: SearchState = {};

  availabilityFilterOpened: boolean = true;
  categoryFilterOpened: boolean = true;
  showerFilterOpened: boolean = true;
  businessSegmentFilterOpened: boolean = true;
  collectionFilterOpened: boolean = false;
  finishFilterOpened: boolean = true;
  specialAttributeFilterOpened: boolean = false;
  numberOfHandlesFilterOpened: boolean = false;
  installationMountTypeFilterOpened: boolean = false;
  flowRateGpmFilterOpened: boolean = false;
  obsoleteFilterOpened: boolean = true;

  accessoriesFilterOpened: boolean = true;
  faucetsFilterOpened: boolean = true;
  tubFaucetsTypeFilterOpened: boolean = true;
  showerValvesTypeFilterOpened: boolean = true;
  mixingValvesTypeFilterOpened: boolean = true;
  repairPartsFilterOpened: boolean = true;
  evolutionDevicesFilterOpened: boolean = true;

  constructor() {
    this.load();
    makeObservable(this, {
      showFilters: observable,
      viewMode: observable,
      availabilityFilterOpened: observable,
      categoryFilterOpened: observable,
      showerFilterOpened: observable,
      businessSegmentFilterOpened: observable,
      collectionFilterOpened: observable,
      finishFilterOpened: observable,
      specialAttributeFilterOpened: observable,
      numberOfHandlesFilterOpened: observable,
      installationMountTypeFilterOpened: observable,
      flowRateGpmFilterOpened: observable,
      obsoleteFilterOpened: observable,
      searchState: observable,
      nbHits: observable,
      hasResults: observable,
      page: observable,
      nbPages: observable,
      load: action,
      setShowFilters: action,
      toggleShowFilters: action,
      setViewMode: action,
      setAvailabilityFilterOpened: action,
      setCategoryFilterOpened: action,
      setBusinessSegmentFilterOpened: action,
      setCollectionFilterOpened: action,
      setFinishFilterOpened: action,
      setSpecialAttributeFilterOpened: action,
      setNumberOfHandlesFilterOpened: action,
      setInstallationMountTypeFilterOpened: action,
      setFlowRateGpmFilterOpened: action,
      setObsoleteFilterOpened: action,
      setSearchState: action,
      setNbHits: action,
      setPage: action,
      setNbPages: action,
      hitsPerPage: computed,
      sortBy: computed,
      refinementListFinishes: computed,
      variantAffectingFilters: computed,

      accessoriesFilterOpened: observable,
      setAccessoriesFilterOpened: action,

      faucetsFilterOpened: observable,
      setFaucetsFilterOpened: action,

      tubFaucetsTypeFilterOpened: observable,
      setTubFaucetsTypeFilterOpened: action,

      showerValvesTypeFilterOpened: observable,
      setShowerValvesTypeFilterOpened: action,

      mixingValvesTypeFilterOpened: observable,
      setMixingValvesTypeFilterOpened: action,

      repairPartsFilterOpened: observable,
      setRepairPartsFilterOpened: action,

      evolutionDevicesFilterOpened: observable,
      setEvolutionDevicesFilterOpened: action,
    });
    this.save = this.save.bind(this);
    autorun(this.save);
  }

  private save() {
    // console.log('ProductSearchStore::save', this);
    // save certain properties every time they change
    const store: SavedProductSearchStore = {
      showFilters: this.showFilters,
      viewMode: this.viewMode,
      availabilityFilterOpened: this.availabilityFilterOpened,
      categoryFilterOpened: this.categoryFilterOpened,
      showerFilterOpened: this.showerFilterOpened,
      businessSegmentFilterOpened: this.businessSegmentFilterOpened,
      collectionFilterOpened: this.collectionFilterOpened,
      finishFilterOpened: this.finishFilterOpened,
      specialAttributeFilterOpened: this.specialAttributeFilterOpened,
      numberOfHandlesFilterOpened: this.numberOfHandlesFilterOpened,
      installationMountTypeFilterOpened: this.installationMountTypeFilterOpened,
      flowRateGpmFilterOpened: this.flowRateGpmFilterOpened,
      obsoleteFilterOpened: this.obsoleteFilterOpened,
      searchState: {
        hitsPerPage: this.searchState.hitsPerPage,
        sortBy: this.searchState.sortBy,
      },

      accessoriesFilterOpened: this.accessoriesFilterOpened,
      faucetsFilterOpened: this.faucetsFilterOpened,
      tubFaucetsTypeFilterOpened: this.tubFaucetsTypeFilterOpened,
      showerValvesTypeFilterOpened: this.showerValvesTypeFilterOpened,
      mixingValvesTypeFilterOpened: this.mixingValvesTypeFilterOpened,
      repairPartsFilterOpened: this.repairPartsFilterOpened,
      evolutionDevicesFilterOpened: this.evolutionDevicesFilterOpened,
    };
    window.localStorage.setItem('productSearchStore', JSON.stringify(store));
  }

  load() {
    Object.assign(
      this,
      _pick(JSON.parse(window.localStorage.getItem('productSearchStore') || '{}'), [
        'showFilters',
        'viewMode',
        'availabilityFilterOpened',
        'categoryFilterOpened',
        'showerFilterOpened',
        'businessSegmentFilterOpened',
        'collectionFilterOpened',
        'finishFilterOpened',
        'specialAttributeFilterOpened',
        'numberOfHandlesFilterOpened',
        'installationMountTypeFilterOpened',
        'flowRateGpmFilterOpened',
        'obsoleteFilterOpened',
        'searchState',
        'accessoriesFilterOpened',
        'faucetsFilterOpened',
        'tubFaucetsTypeFilterOpened',
        'showerValvesTypeFilterOpened',
        'mixingValvesTypeFilterOpened',
        'repairPartsFilterOpened',
        'evolutionDevicesFilterOpened',
      ])
    );
    this.searchState = Object.assign(this.searchState, urlToSearchState(window.location, this));

    if (window.innerWidth >= 992) {
      // desktop
    } else {
      // mobile
      this.setShowFilters(false);
    }

    // trigger body class toggle
    this.setShowFilters(this.showFilters);
  }

  setShowFilters(showFilters: boolean) {
    this.showFilters = showFilters;
    jQuery('body').toggleClass('is-active--listing-filters', this.showFilters);
  }

  toggleShowFilters() {
    this.setShowFilters(!this.showFilters);
  }

  setViewMode(viewMode: ViewMode) {
    this.viewMode = viewMode;
  }

  setAvailabilityFilterOpened(availabilityFilterOpened: boolean) {
    this.availabilityFilterOpened = availabilityFilterOpened;
  }

  setCategoryFilterOpened(categoryFilterOpened: boolean) {
    this.categoryFilterOpened = categoryFilterOpened;
  }

  setShowerFilterOpened(showerFilterOpened: boolean) {
    this.showerFilterOpened = showerFilterOpened;
  }

  setBusinessSegmentFilterOpened(businessSegmentFilterOpened: boolean) {
    this.businessSegmentFilterOpened = businessSegmentFilterOpened;
  }

  setCollectionFilterOpened(collectionFilterOpened: boolean) {
    this.collectionFilterOpened = collectionFilterOpened;
  }

  setFinishFilterOpened(finishFilterOpened: boolean) {
    this.finishFilterOpened = finishFilterOpened;
  }

  setSpecialAttributeFilterOpened(specialAttributeFilterOpened: boolean) {
    this.specialAttributeFilterOpened = specialAttributeFilterOpened;
  }

  setNumberOfHandlesFilterOpened(numberOfHandlesFilterOpened: boolean) {
    this.numberOfHandlesFilterOpened = numberOfHandlesFilterOpened;
  }

  setInstallationMountTypeFilterOpened(installationMountTypeFilterOpened: boolean) {
    this.installationMountTypeFilterOpened = installationMountTypeFilterOpened;
  }

  setFlowRateGpmFilterOpened(flowRateGpmFilterOpened: boolean) {
    this.flowRateGpmFilterOpened = flowRateGpmFilterOpened;
  }

  setObsoleteFilterOpened(obsoleteFilterOpened: boolean) {
    this.obsoleteFilterOpened = obsoleteFilterOpened;
  }

  setSearchState(searchState: SearchState) {
    this.searchState = searchState;
  }

  setAccessoriesFilterOpened(accessorieFilterOpened: boolean) {
    this.accessoriesFilterOpened = accessorieFilterOpened;
  }

  setFaucetsFilterOpened(faucetsFilterOpened: boolean) {
    this.faucetsFilterOpened = faucetsFilterOpened;
  }

  setTubFaucetsTypeFilterOpened(tubFaucetsTypeFilterOpened: boolean) {
    this.tubFaucetsTypeFilterOpened = tubFaucetsTypeFilterOpened;
  }

  setShowerValvesTypeFilterOpened(showerValvesTypeFilterOpened: boolean) {
    this.showerValvesTypeFilterOpened = showerValvesTypeFilterOpened;
  }

  setMixingValvesTypeFilterOpened(mixingValvesTypeFilterOpened: boolean) {
    this.mixingValvesTypeFilterOpened = mixingValvesTypeFilterOpened;
  }

  setRepairPartsFilterOpened(repairPartsFilterOpened: boolean) {
    this.repairPartsFilterOpened = repairPartsFilterOpened;
  }

  setEvolutionDevicesFilterOpened(evolutionDevicesFilterOpened: boolean) {
    this.evolutionDevicesFilterOpened = evolutionDevicesFilterOpened;
  }

  get hitsPerPage() {
    return this.searchState.hitsPerPage || DEFAULT_HITS_PER_PAGE;
  }

  get sortBy() {
    return this.searchState.sortBy || wordpressData.algolia.productsIndex;
  }

  get refinementListFinishes() {
    if (this.searchState.refinementList && this.searchState.refinementList.finishes) {
      return this.searchState.refinementList.finishes;
    }
    return [];
  }

  get variantAffectingFilters() {
    const filters: VariantAffectingFilters = {
      query: undefined,
      availabilities: [],
      finishes: [],
      special_attributes: [],
      is_obsolete: [],
      business_segments: [],
    };

    if (typeof this.searchState.query === 'string') {
      filters.query = this.searchState.query;
    }

    if (this.searchState.refinementList) {
      if (this.searchState.refinementList.availabilities) {
        filters.availabilities = this.searchState.refinementList.availabilities;
      }
      if (this.searchState.refinementList.finishes) {
        filters.finishes = this.searchState.refinementList.finishes;
      }
      if (this.searchState.refinementList.special_attributes) {
        filters.special_attributes = this.searchState.refinementList.special_attributes;
      }
      if (this.searchState.refinementList.is_obsolete) {
        filters.is_obsolete = this.searchState.refinementList.is_obsolete;
      }
      if (this.searchState.refinementList.business_segments) {
        filters.business_segments = this.searchState.refinementList.business_segments;
      }
    }

    return filters;
  }

  setNbHits(nbHits: number) {
    this.nbHits = nbHits;
    this.hasResults = nbHits !== 0;
  }

  setPage(page: number) {
    this.page = page;
  }

  setNbPages(nbPages: number) {
    this.nbPages = nbPages;
  }
}
