import { AuthStatus, useAuth } from 'auth';
import { ExtractRouteParams, RouteProps, Redirect } from 'react-router';
import GuardedRoute from 'my-account/components/GuardedRoute';

export function AuthRoute<
  Path extends string = string,
  Params extends { [K: string]: string | undefined } = ExtractRouteParams<Path, string>
>(props: RouteProps<Path, Params>) {
  const auth = useAuth();

  return (
    <GuardedRoute
      {...props}
      guard={({ location }, children) => {
        if (auth.status === AuthStatus.LoggedOut || auth.status === AuthStatus.LoggingIn) {
          return children();
        }

        const from = (location.state as any)?.from ?? { pathname: '/' };

        return <Redirect to={from} />;
      }}
    />
  );
}

export default AuthRoute;
