import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { parseISO, format } from 'date-fns/esm';
import { utcToZonedTime } from 'date-fns-tz';

import { AdminUserRoleEnum, FileStatus, FileVisibility } from '__generated-api__';
import api from 'api';
import { AuthStatus, useAuth } from 'auth';
import { useQuery, useMutation } from 'hooks/query';
import Icon from 'components/icon';
import { MainHero } from 'my-account/components/MainHero';
import { IconDropdown } from 'components/Dropdown';
import { useToast } from 'my-account/toast';
import useDocumentTitle from 'hooks/useDocumentTitle';

export default function MediaLibraryViewPage() {
  const { id } = useParams<{ id?: string }>();
  const [file, getFileStatus] = useQuery(api.file.getFile, { id: Number(id) });
  useDocumentTitle(file?.data.name ? `View ${file.data.name} Media` : 'View Media');
  const [updateFile] = useMutation(api.file.updateFile);
  const [deleteFile] = useMutation(api.file.deleteFile);
  const queryClient = useQueryClient();
  const toast = useToast();
  const history = useHistory();
  const auth = useAuth();
  if (auth.status !== AuthStatus.LoggedIn && auth.status !== AuthStatus.LoggingOut) {
    return null;
  }
  const user = auth.currentUser;
  return (
    <>
      <MainHero />
      {typeof file !== 'undefined' && getFileStatus.isSuccess && (
        <>
          <section className="c-block c-block--hero">
            <div className="o-container-fluid">
              <div className="o-row u-flex-grow u-items-end">
                <div className="o-col-12">
                  <Link
                    to="/media-library"
                    className="c-link-cta-basic c-link-cta--small c-block__get-back u-mt-spacer-base-large u-mb-spacer-base-small"
                  >
                    <Icon name="arrow" className="o-svg-icon o-svg-left" />
                    <span>Back to media library</span>
                  </Link>
                  <h1 className="c-title--medium u-mb-0">{file.data.title ?? file.data.name}</h1>
                </div>
              </div>
            </div>
            <div className="c-block__pattern c-block__pattern--light3 c-block__pattern--top3 c-block__overlay--opacity-20">
              <Icon name="pattern" className="o-svg-icon" />
            </div>
          </section>
          <section className="c-block">
            <div className="o-container-fluid">
              <div className="o-row">
                <div className="o-col-12">
                  <div className="c-order-summary">
                    <header className="c-order-summary__header">
                      <ul className="c-order-summary-info">
                        <li className="c-order-summary-info__column">
                          <p className="c-order-summary-info__title">
                            {file.data.created_at ? (
                              format(utcToZonedTime(parseISO(file.data.created_at), 'America/New_York'), 'MMM d y')
                            ) : (
                              <>&mdash;</>
                            )}
                          </p>

                          <p className="c-order-summary-info__label">Created Date</p>
                        </li>

                        <li className="c-order-summary-info__column">
                          <p className="c-order-summary-info__title u-uppercase">{file.data.visibility}</p>
                          <p className="c-order-summary-info__label">Visibility</p>
                        </li>

                        <li className="c-order-summary-info__column">
                          <p className="c-order-summary-info__title">
                            <Link to={`/user/${file.data.user?.id}`}></Link>
                            {file.data.user?.first_name} {file.data.user?.last_name}
                          </p>
                          <p className="c-order-summary-info__label">Owner</p>
                        </li>

                        <li className="c-order-summary-info__column">
                          <p className="c-order-summary-info__title u-uppercase">{file.data.status}</p>
                          <p className="c-order-summary-info__label">Status</p>
                        </li>
                      </ul>

                      <div className="c-listing__header">
                        <IconDropdown
                          buttonLabel="Actions"
                          menuLabel="Actions"
                          options={Object.assign(
                            {},
                            {
                              edit: 'Edit File',
                            },
                            file.data.visibility === FileVisibility.Public &&
                              user.role === AdminUserRoleEnum.Admin && { private: 'Make Private' },
                            file.data.visibility === FileVisibility.Private &&
                              user.role === AdminUserRoleEnum.Admin && { public: 'Make Public' },
                            file.data.status === FileStatus.Active && { delete: 'Delete File' },
                            file.data.status === FileStatus.Inactive && { restore: 'Restore File' }
                          )}
                          onChange={async (value) => {
                            if (value === 'edit') {
                              history.push(`/media-library/${file.data.id}/edit`);
                            }
                            if (value === 'private') {
                              await updateFile([
                                {
                                  id: file.data.id,
                                  fileModelMetadataRequestBody: {
                                    visibility: FileVisibility.Private,
                                  },
                                },
                              ]);
                              toast.notify({
                                type: 'success',
                                title: 'Success',
                                message: `"${file.data.name}" was made private.`,
                              });
                            }
                            if (value === 'public') {
                              await updateFile([
                                {
                                  id: file.data.id,
                                  fileModelMetadataRequestBody: {
                                    visibility: FileVisibility.Public,
                                  },
                                },
                              ]);
                              toast.notify({
                                type: 'success',
                                title: 'Success',
                                message: `"${file.data.name}" was made public.`,
                              });
                            }
                            if (value === 'delete') {
                              if (window.confirm(`Are you sure that you really want to archive "${file.data.name}"?`)) {
                                await deleteFile([{ id: file.data.id }]);
                                toast.notify({
                                  type: 'success',
                                  title: 'Success',
                                  message: `"${file.data.name}" was archived successfully.`,
                                });
                              }
                            }
                            if (value === 'restore') {
                              if (window.confirm(`Are you sure that you really want to restore "${file.data.name}"?`)) {
                                await updateFile([
                                  {
                                    id: file.data.id,
                                    fileModelMetadataRequestBody: {
                                      status: FileStatus.Active,
                                    },
                                  },
                                ]);
                                toast.notify({
                                  type: 'success',
                                  title: 'Success',
                                  message: `"${file.data.name}" was archived successfully.`,
                                });
                              }
                            }

                            await Promise.all([
                              queryClient
                                .cancelQueries(api.file.listFiles.getQueryKey()[0])
                                .then(() => queryClient.invalidateQueries(api.file.listFiles.getQueryKey()[0])),
                              queryClient
                                .cancelQueries(api.file.getFile.getQueryKey({ id: file.data.id })[0])
                                .then(() =>
                                  queryClient.invalidateQueries(api.file.getFile.getQueryKey({ id: file.data.id })[0])
                                ),
                            ]);
                          }}
                        />
                      </div>
                    </header>
                  </div>
                </div>
              </div>
              <div className="o-row">
                <div className="o-col-7">
                  <article className="c-card c-card--media c-card--link">
                    <div className="c-card__body">
                      <figure className="c-card__figure">
                        <Link to={`/media-library/${file.data.id}`}>
                          <img src={file.data.image} alt={file.data.name} />
                        </Link>
                      </figure>
                      <header className="c-card__header">
                        <h4 className="c-card__title">
                          <Link
                            key={file.data.id}
                            to={`/media-library/${file.data.id}`}
                            className="c-card__link"
                          ></Link>
                        </h4>
                      </header>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
      )
    </>
  );
}
