import api from 'api';
import { SelectListingItemProps, SelectListingItem } from 'my-account/components/SelectListingItem';

export interface SelectSalesforceOpportunityProps
  extends Omit<
    SelectListingItemProps<typeof api.salesforce.listSalesforceOpportunities>,
    'queryFn' | 'getOptionLabel' | 'getOptionValue' | 'queryParams'
  > {
  queryParams?: SelectListingItemProps<typeof api.salesforce.listSalesforceOpportunities>['queryParams'];
}

export const SelectSalesforceOpportunity = ({ queryParams = {}, ...props }: SelectSalesforceOpportunityProps) => {
  return (
    <SelectListingItem
      {...props}
      queryFn={api.salesforce.listSalesforceOpportunities}
      queryParams={queryParams}
      getOptionLabel={(opportunity) => opportunity.name}
      getOptionValue={(opportunity) => String(opportunity.id)}
    />
  );
};
