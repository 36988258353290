import brushedBronzeIcon from 'assets/finish-icons/brushed-bronze-20x56.jpg';
import matteBlackIcon from 'assets/finish-icons/matte-black-20x56.jpg';
import polishedChromeIcon from 'assets/finish-icons/polished-chrome-20x56.jpg';
import polishedGraphiteIcon from 'assets/finish-icons/polished-graphite-20x56.jpg';
import polishedNickelIcon from 'assets/finish-icons/polished-nickel-20x56.jpg';
import satinNickelIcon from 'assets/finish-icons/satin-nickel-20x56.jpg';
import seasonedBronzeIcon from 'assets/finish-icons/seasoned-bronze-20x56.jpg';
import stainlessSteelIcon from 'assets/finish-icons/stainless-steel-20x56.jpg';
import customFinishIcon from 'assets/finish-icons/customfinish_20x56.png';

export const finishIcons: Record<string, string | undefined> = {
  BBZ: brushedBronzeIcon,
  MTB: matteBlackIcon,
  PCP: polishedChromeIcon,
  PLG: polishedGraphiteIcon,
  PNL: polishedNickelIcon,
  SNA: satinNickelIcon,
  SBZ: seasonedBronzeIcon,
  STS: stainlessSteelIcon,
};

export { customFinishIcon };
