import classNames from 'classnames';
import uniq from 'lodash/uniq';
import * as React from 'react';
import { useMount } from 'react-use';

import { AdminUserRoleEnum, SalesRepUserRoleEnum } from '__generated-api__';
import { AuthStatus, useAuth } from 'auth';
import { Dropdown } from 'components/Dropdown';
import Icon from 'components/icon';
import wordpressData from 'data';
import { ProductHeroGallery } from 'my-account/components/ProductHero/Gallery';
import { ProductHeroPrice } from 'my-account/components/ProductHero/Price';
import { AddProductToQuote } from 'my-account/components/ProductHero/Quote';

function getMultivariant(data: Symmons.ProductData, flowRateId: string | null, finish: string | null) {
  // find the best sellable product to switch to

  const products = data.multivariants.filter((product) => product.flowRateId === flowRateId);

  if (!products.length) {
    return null;
  }

  return products.find((product) => product.finish === finish) ?? products[0];
}

function getCurrentFlowRateVariants(data: Symmons.ProductData, currentProduct: Symmons.Product) {
  const flowRateVariants = uniq(
    data.multivariants
      .filter((product): product is Symmons.Product & { flowRateId: string } => product.flowRateId !== null)
      .map((product) => product.flowRateId)
  )
    // get a variant for each flow rate ID
    .reduce((accumulator, flowRateId) => {
      const flowRateVariant = getMultivariant(data, flowRateId, currentProduct.finish);
      flowRateVariant && accumulator.push(flowRateVariant);
      return accumulator;
    }, [] as Symmons.Product[]);

  // this function returns all flow rate variants incuding the current product
  // if there's only one flow rate variant we only have the current product inside
  // we don't want to display flow rate variant picker in this case
  return flowRateVariants.length > 1 ? flowRateVariants : [];
}

const ProductHeroGetInTouchButton: React.VFC<{ html: string }> = ({ html }) => {
  const elRef = React.useRef<HTMLDivElement>(null);

  useMount(() => {
    if (!elRef.current) {
      return;
    }

    jQuery(elRef.current)
      .find('.js-modal-base')
      // @ts-ignore
      .magnificPopup({
        closeOnContentClick: false,
        showCloseBtn: false,
      });
  });

  return (
    <div className="c-list-grid" ref={elRef}>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  );
};

const DivOrFragment: React.VFC<React.HTMLAttributes<HTMLDivElement> & { isFragment?: boolean }> = ({
  isFragment,
  ...props
}) => {
  if (isFragment) {
    return <>{props.children}</>;
  }

  return <div {...props} />;
};

export const ProductHero: React.VFC = () => {
  const auth = useAuth();
  const productData = React.useMemo<Symmons.ProductData>(() => jQuery('body').data('product-single-data'), []);
  const sectionData = React.useMemo<{
    enable_documentation_anchor: boolean;
    enable_faq_anchor: boolean;
    get_in_touch_modal_cta_html: string;
  }>(() => jQuery('#product-hero-app').data('section-args'), []);
  const [currentProduct, setCurrentProduct] = React.useState(productData.currentProduct);
  const { title, isObsolete, isModified, sku, availabilityHtml, categories, business_segments } = currentProduct;
  const finishVariants = productData.multivariants
    .filter((product) => product.type === 'variant')
    .filter((product) => product.flowRateId === currentProduct.flowRateId)
    .filter((product): product is Symmons.Product & { finish: string } => typeof product.finish === 'string');
  const flowRateVariants = getCurrentFlowRateVariants(productData, currentProduct);
  const productMediaItems = productData.mediaItems[currentProduct.sku] || [];
  const changeMultivariant = React.useCallback(
    (flowRateId: string | null, finish: string | null) => {
      const product = getMultivariant(productData, flowRateId, finish);
      if (!product) {
        return;
      }
      window.history.pushState(null, '', product.permalink);
      setCurrentProduct(product);

      // where to buy

      const $whereToBuyBtns = jQuery('.js-product-single__where-to-buy-btn');
      $whereToBuyBtns.removeClass('is-active');
      $whereToBuyBtns.filter(`[ps-sku="${jQuery.escapeSelector(product.sku)}"]`).addClass('is-active');

      // primary preset content blocks

      const $primaryPresetContentBlocks = jQuery('.js-product-single__primary-preset-content-block');
      $primaryPresetContentBlocks.hide();
      $primaryPresetContentBlocks.filter(`[data-skus*="|${jQuery.escapeSelector(product.sku)}|"]`).show();

      // features

      const $featureBulletsBlock = jQuery('.js-product-single__feature-bullets-block');
      // const $featureBulletsList = jQuery('.js-product-single__feature-bullets-list');

      if (product.featureBullets.length) {
        // $featureBulletsList.empty();
        // product.featureBullets.forEach((featureBullet) => {
        //   $featureBulletsList.append(`
        //     <li>
        //       ${_escape(featureBullet)}
        //     </li>
        //   `);
        // });
        $featureBulletsBlock.show();
      } else {
        $featureBulletsBlock.hide();
      }

      // documentation

      const $documentationBlocks = jQuery('.js-product-single__documentation-block');
      $documentationBlocks.hide();
      $documentationBlocks.filter(`[data-skus*="|${jQuery.escapeSelector(product.sku)}|"]`).show();

      // replacement parts blocks

      const $replacementPartsBlocks = jQuery('.js-product-single__replacement-parts-block');
      $replacementPartsBlocks.hide();
      $replacementPartsBlocks.filter(`[data-skus*="|${jQuery.escapeSelector(product.sku)}|"]`).show();

      // secondary preset content blocks

      const $secondaryPresetContentBlocks = jQuery('.js-product-single__secondary-preset-content-block');
      $secondaryPresetContentBlocks.hide();
      $secondaryPresetContentBlocks.filter(`[data-skus*="|${jQuery.escapeSelector(product.sku)}|"]`).show();
    },
    [productData]
  );

  return (
    <>
      <div className="o-container-fluid">
        <div className="o-row u-flex-grow">
          <div className="o-col-6@lg u-pr-gutter@lg">
            <div className="c-hero__content u-justify-around">
              <div className="c-block__header c-block__header--large">
                {categories.length > 0 && (
                  <p className="c-headline">{categories.map((category) => category.name).join(', ')}</p>
                )}
                <h1 className="c-title--extra-large">{title}</h1>
                <ul className="c-list-grid c-list-grid--small u-mt-spacer-base--small">
                  {(isObsolete || isModified || !!business_segments.length) && (
                    <li>
                      <div className="c-tag__group">
                        {isObsolete && <span className="c-tag c-tag--small">RETIRED</span>}
                        {isModified && (
                          <a href="https://symmons.com?p=32563" className="c-tag c-tag--small c-tag--secondary">
                            Modified Shower Trim <Icon name="info" className="o-svg-icon" />
                          </a>
                        )}
                        {!!business_segments.length && (
                          <span className="c-tag c-tag--small c-tag--primary">Best Seller</span>
                        )}
                      </div>
                    </li>
                  )}
                  <li>
                    <p className="c-subtitle">Model {sku}</p>
                  </li>
                </ul>
              </div>
              <div className="c-product__features js-product-single__feature-bullets-block">
                <ul className="c-list-features js-product-single__feature-bullets-list">
                  {currentProduct.featureBullets.map((featureBullet) => (
                    <li key={featureBullet}>{featureBullet}</li>
                  ))}
                </ul>
              </div>

              <div className="c-product__info">
                <div className="c-list-grid u-items-end">
                  {availabilityHtml.length > 0 && (
                    <div>
                      <p className="c-subtitle" dangerouslySetInnerHTML={{ __html: availabilityHtml }} />
                    </div>
                  )}
                  <ProductHeroPrice
                    product={currentProduct}
                    small={!finishVariants.length && !flowRateVariants.length}
                  />
                </div>

                <div
                  className="c-product__options"
                  style={!finishVariants.length && !flowRateVariants.length ? { display: 'none' } : undefined}
                >
                  {finishVariants.length > 0 && (
                    <DivOrFragment
                      className={
                        finishVariants.length && flowRateVariants.length ? 'c-product__options-col' : undefined
                      }
                      isFragment={finishVariants.length && flowRateVariants.length ? false : true}
                    >
                      <div className="c-list-grid c-list-grid--small">
                        <div>
                          <p className="c-product__label">Finishes:</p>
                        </div>
                        <div>
                          <ul className="c-finishes c-finishes--hor">
                            {finishVariants.map((finish) => {
                              const finishIconUrl = wordpressData.finishIcons[finish.finish];

                              return (
                                <li
                                  className={classNames({ 'is-selected': finish.post_id === currentProduct.post_id })}
                                  key={finish.post_id}
                                >
                                  <a
                                    href={finish.permalink}
                                    onClick={(event) => {
                                      event.preventDefault();
                                      changeMultivariant(currentProduct.flowRateId, finish.finish);
                                    }}
                                  >
                                    <span
                                      className="c-finishes__preview"
                                      style={{ backgroundImage: `url(${finishIconUrl})` }}
                                    ></span>
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </DivOrFragment>
                  )}

                  {flowRateVariants.length > 0 && (
                    <DivOrFragment
                      className={
                        finishVariants.length && flowRateVariants.length ? 'c-product__options-col' : undefined
                      }
                      isFragment={finishVariants.length && flowRateVariants.length ? false : true}
                    >
                      <div className="c-list-grid c-list-grid--small">
                        <div>
                          <p className="c-product__label">Flow Rate:</p>
                        </div>
                        <div>
                          <Dropdown
                            description="Flow Rate:"
                            options={
                              Object.fromEntries(
                                flowRateVariants.map((variant) => [variant.flowRateId!, variant.flowRateName!])
                              ) as unknown as Record<string, string>
                            }
                            value={currentProduct.flowRateId ?? flowRateVariants[0].flowRateId!}
                            onChange={(val) => {
                              changeMultivariant(val, currentProduct.finish);
                            }}
                          />
                        </div>
                      </div>
                    </DivOrFragment>
                  )}
                </div>

                {auth.status === AuthStatus.LoggedOut ? (
                  <div className="c-list-grid">
                    <div>
                      <ProductHeroGetInTouchButton html={sectionData.get_in_touch_modal_cta_html} />
                    </div>
                    <div className="u-ml-auto">
                      <div className="c-list-grid">
                        <div>
                          {productData.multivariants
                            .filter(
                              (
                                multivariant
                              ): multivariant is Symmons.Product & { price: Symmons.Price; isObsolete: false } =>
                                multivariant.price !== null && !multivariant.isObsolete
                            )
                            .map((multivariant) => (
                              <div
                                key={multivariant.post_id}
                                className={classNames('ps-widget', 'c-button', 'c-button--primary', {
                                  'is-active': multivariant.sku === currentProduct.sku,
                                })}
                                ps-sku={multivariant.sku}
                              >
                                <span>Where to buy</span>
                                <Icon name="location" className="o-svg-icon o-svg-large" />
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : auth.status === AuthStatus.LoggedIn &&
                  (auth.currentUser.role === AdminUserRoleEnum.Admin ||
                    auth.currentUser.role === SalesRepUserRoleEnum.SalesRep) ? (
                  <div className="c-list-grid">
                    <div></div>
                    <div className="u-ml-auto">
                      <div className="c-list-grid">
                        <div>
                          {productData.multivariants
                            .filter(
                              (
                                multivariant
                              ): multivariant is Symmons.Product & { price: Symmons.Price; isObsolete: false } =>
                                multivariant.price !== null && !multivariant.isObsolete
                            )
                            .map((multivariant) => (
                              <div
                                key={multivariant.post_id}
                                className={classNames('ps-widget', 'c-button', 'c-button--primary', {
                                  'is-active': multivariant.sku === currentProduct.sku,
                                })}
                                ps-sku={multivariant.sku}
                              >
                                <span>Where to buy</span>
                                <Icon name="location" className="o-svg-icon o-svg-large" />
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {!currentProduct.isObsolete && <AddProductToQuote product={currentProduct} />}
              </div>
            </div>
            <div className="c-hero__nav u-hidden u-flex@sm">
              <p>Skip to:</p>
              <ul>
                {sectionData.enable_documentation_anchor && (
                  <li>
                    <a href="#documentation">
                      <span>Documentation</span>
                    </a>
                  </li>
                )}
                {sectionData.enable_faq_anchor && (
                  <li>
                    <a href="#faq">
                      <span>FAQ</span>
                    </a>
                  </li>
                )}
                <li>
                  <a href="#request-more-information">
                    <span>Contact Us</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="c-block__pattern-circle c-block__pattern--light3 c-block__overlay--opacity-20 u-ml-gutter-@xl">
              <Icon name="pattern-circle" className="o-svg-icon" />
            </div>
          </div>
          <div className="o-col-6@lg u-justify-around u-pt-0 u-pb-0">
            <ProductHeroGallery productMediaItems={productMediaItems} currentProduct={currentProduct} />
          </div>
        </div>
      </div>
      <div className="c-block__overlay c-block__overlay--hero c-block__overlay--base-half c-block__overlay--opacity-100 u-ml-gutter u-hidden u-block@lg"></div>
    </>
  );
};
