import * as React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import Modal from 'react-modal';

import { hasAuthCookie } from 'api/auth';
import { AuthProvider } from 'auth';
import { OffcanvasProvider } from 'components/Offcanvas';
import { routerBasename } from 'utils/router';
import { SupportModalProvider } from 'my-account/components/SupportModal';
import { PendingQuoteProvider } from 'my-account/components/PendingQuote';
import { ToastProvider } from 'my-account/toast';
import { MyAccountPortal } from 'my-account/portal';
import { ProductHero } from 'my-account/product-hero';

const myAccountAppRoot = document.getElementById('my-account-app-root');
const productHeroRoot = document.getElementById('product-hero-app');
const shouldRenderApp = myAccountAppRoot !== null || productHeroRoot !== null || hasAuthCookie();

if (shouldRenderApp) {
  const queryClient = new QueryClient();

  const MyAccountProviders: React.FC = ({ children }) => {
    return (
      <QueryClientProvider client={queryClient}>
        <BrowserRouter basename={routerBasename}>
          <ToastProvider>
            <AuthProvider>
              <OffcanvasProvider>
                <SupportModalProvider>
                  <PendingQuoteProvider>{children}</PendingQuoteProvider>
                </SupportModalProvider>
              </OffcanvasProvider>
            </AuthProvider>
          </ToastProvider>
        </BrowserRouter>
        {process.env.NODE_ENV === 'development' && (
          <style
            type="text/css"
            dangerouslySetInnerHTML={{
              __html: 'footer.ReactQueryDevtools, footer.ReactQueryDevtools *:not(svg, g, path) { all: revert; }',
            }}
          />
        )}
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    );
  };

  if (myAccountAppRoot) {
    Modal.setAppElement(myAccountAppRoot);

    ReactDOM.render(
      <MyAccountProviders>
        <MyAccountPortal />
      </MyAccountProviders>,
      myAccountAppRoot
    );
  } else if (productHeroRoot) {
    Modal.setAppElement(productHeroRoot);

    ReactDOM.render(
      <MyAccountProviders>
        <ProductHero />
      </MyAccountProviders>,
      productHeroRoot
    );
  } else {
    const appEl = document.createElement('div');
    document.body.appendChild(appEl);

    Modal.setAppElement(appEl);
    ReactDOM.render(<MyAccountProviders></MyAccountProviders>, appEl);
  }
}

if (module.hot) {
  module.hot.accept();
}
