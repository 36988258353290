import * as React from 'react';
import { Hit } from 'react-instantsearch-core';
import SpriteIcon from 'search/components/sprite-icon';

export interface DocumentHitInterface {
  name: string;
  salsify_id: string;
  types: string[];
  thumbnail: string;
  salsify_format: string;
}

interface DocumentHitProps {
  hit: Hit<DocumentHitInterface>;
}

class DocumentHit extends React.Component<DocumentHitProps> {
  render() {
    const { name, salsify_id, types, thumbnail, salsify_format } = this.props.hit;

    const download_url = `/download_asset/?id=${salsify_id}`;

    return (
      <>
        <figure
          className="c-card__figure c-card__figure--fixed-height"
          style={{ backgroundImage: `url(${thumbnail})` }}
        >
          <a href={download_url} target="_blank" rel="noreferrer">
            <img src={thumbnail} alt={name} />
          </a>
          <div className="c-card__tags">
            <span className="c-tag c-tag--small">{types.join(', ')}</span>
          </div>
        </figure>
        <div className="c-card__body">
          <header className="c-card__header">
            <h4 className="c-card__title">
              <a href={download_url} target="_blank" rel="noreferrer" className="c-card__link">
                {name}
              </a>
            </h4>
          </header>
          <div className="c-card__desc">
            <p className="c-note">{salsify_format.toUpperCase()}</p>
          </div>
          <footer className="c-card__footer">
            <p>
              <a href={download_url} target="_blank" rel="noreferrer" className="c-link-cta-basic c-link-cta--small">
                <SpriteIcon name="download" classes={['o-svg-icon']} />
                <span>Download</span>
              </a>
            </p>
          </footer>
        </div>
      </>
    );
  }
}

export default DocumentHit;
