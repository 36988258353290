import * as React from 'react';
import { useMount } from 'react-use';
import axios, { AxiosRequestConfig } from 'axios';
import { useQueryClient } from 'react-query';
import { Location } from 'history';
import { useLocation } from 'react-router';

import {
  GetPasswordTokenBodyGrantTypeEnum,
  GetTokenWithPasswordResetBodyGrantTypeEnum,
  GetTokenWithSalesforceSSOBodyGrantTypeEnum,
  RegisteredUser,
} from '__generated-api__';
import {
  getAuthData,
  removeAuthData,
  setAuthData,
  prepareAuthData,
  addRefreshTokenInterceptor,
  removeRefreshTokenInterceptor,
} from 'api/auth';
import api, { API_CLIENT_ID, API_CLIENT_SECRET } from 'api';
import { useMutation, UseMutationResult } from 'hooks/query';
import { defineAbilityFor, AuthorizationContext } from 'auth/authorization';

export enum AuthStatus {
  LoggedOut = 'logged-out',
  LoggingIn = 'logging-in',
  LoggedIn = 'logged-in',
  LoggingOut = 'logging-out',
}

const initialAuthData = getAuthData();

type AuthenticationContextState =
  | {
      status: AuthStatus.LoggedIn;
      currentUser: RegisteredUser;
    }
  | {
      status: AuthStatus.LoggingOut;
      currentUser: RegisteredUser;
    }
  | {
      status: AuthStatus.LoggedOut;
    }
  | {
      status: AuthStatus.LoggingIn;
    };

type AuthenticationContextValue = AuthenticationContextState & {
  login: (username: string, password: string) => Promise<void>;
  loginSalesforce: (token: string) => Promise<void>;
  logout: () => Promise<void>;
  verifyReset: (token: string, email: string) => Promise<void>;
  loginMutationState: UseMutationResult<typeof api.auth.getAuthToken>[1];
  updateUserData: (data: Partial<Pick<RegisteredUser, 'first_name' | 'last_name' | 'email' | 'image'>>) => void;
};

const AuthenticationContext = React.createContext<AuthenticationContextValue>({
  status: AuthStatus.LoggingIn,
  login: () => Promise.resolve(),
  loginSalesforce: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  verifyReset: () => Promise.resolve(),
  loginMutationState: {
    context: undefined,
    data: undefined,
    error: undefined,
    failureCount: 0,
    isError: false,
    isIdle: true,
    isLoading: false,
    isPaused: false,
    isSuccess: false,
    reset: () => {},
    status: 'idle',
    variables: undefined,
  },
  updateUserData: () => {},
});

export const AuthProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const [state, setState] = React.useState<AuthenticationContextState>({
    status:
      typeof initialAuthData !== 'undefined' && !location.pathname.startsWith('/auth/verify-reset')
        ? AuthStatus.LoggingIn
        : AuthStatus.LoggedOut,
  });
  const [getAuthToken, loginMutationState] = useMutation(api.auth.getAuthToken);
  const queryClient = useQueryClient();
  const prevLocation = React.useRef<Location<unknown> | undefined>(undefined);
  const initialLoginPromise = React.useRef<{ promise: Promise<void>; cancel: () => void } | undefined>(undefined);

  const initialLogin = React.useCallback(() => {
    if (typeof initialLoginPromise.current === 'undefined') {
      if (typeof initialAuthData === 'undefined' || location.pathname.startsWith('/auth/verify-reset')) {
        return;
      }

      setState({ status: AuthStatus.LoggingIn });

      const cancelTokenSource = axios.CancelToken.source();
      const reqOptions: AxiosRequestConfig = { cancelToken: cancelTokenSource.token };
      const promise = api.user
        .getCurrentUser(undefined, reqOptions)
        .then((res) => {
          setState({ status: AuthStatus.LoggedIn, currentUser: res.data });
          initialLoginPromise.current = undefined;
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            removeAuthData();
            setState({ status: AuthStatus.LoggedOut });
          }

          initialLoginPromise.current = undefined;
        });

      const cancel = () => cancelTokenSource.cancel();

      initialLoginPromise.current = { promise, cancel };
    }

    return initialLoginPromise.current;
  }, [location]);

  useMount(() => {
    const promiseData = initialLogin();

    return () => {
      promiseData?.cancel();
    };
  });

  React.useEffect(() => {
    if (
      prevLocation.current &&
      prevLocation.current.pathname.startsWith('/auth/verify-reset') &&
      location.pathname.startsWith('/auth/login')
    ) {
      initialLogin();
    }

    prevLocation.current = location;
  }, [location, initialLogin]);

  const login = React.useCallback(
    (username: string, password: string) => {
      // TODO: add cancel token
      setState({ status: AuthStatus.LoggingIn });

      return getAuthToken([
        {
          getTokenBody: {
            client_id: API_CLIENT_ID,
            client_secret: API_CLIENT_SECRET,
            grant_type: GetPasswordTokenBodyGrantTypeEnum.Password,
            username,
            password,
          },
        },
      ])
        .then((res) => {
          const authData = prepareAuthData(res.data);
          setAuthData(authData);
          addRefreshTokenInterceptor(authData.refreshToken);
          setState({ status: AuthStatus.LoggedIn, currentUser: res.data.user });
        })
        .catch((error) => {
          removeRefreshTokenInterceptor();
          removeAuthData();
          setState({ status: AuthStatus.LoggedOut });
          throw error;
        });
    },
    [getAuthToken]
  );

  const loginSalesforce = React.useCallback(
    (token: string) => {
      // TODO: add cancel token
      setState({ status: AuthStatus.LoggingIn });

      return getAuthToken([
        {
          getTokenBody: {
            client_id: API_CLIENT_ID,
            client_secret: API_CLIENT_SECRET,
            grant_type: GetTokenWithSalesforceSSOBodyGrantTypeEnum.SalesforceSso,
            token,
          },
        },
      ])
        .then((res) => {
          const authData = prepareAuthData(res.data);
          setAuthData(authData);
          addRefreshTokenInterceptor(authData.refreshToken);
          setState({ status: AuthStatus.LoggedIn, currentUser: res.data.user });
        })
        .catch((error) => {
          removeRefreshTokenInterceptor();
          removeAuthData();
          setState({ status: AuthStatus.LoggedOut });
          throw error;
        });
    },
    [getAuthToken]
  );

  const verifyReset = React.useCallback(
    (token: string, email: string) => {
      // TODO: add cancel token
      return getAuthToken([
        {
          getTokenBody: {
            client_id: API_CLIENT_ID,
            client_secret: API_CLIENT_SECRET,
            grant_type: GetTokenWithPasswordResetBodyGrantTypeEnum.ResetPasswordGrant,
            token,
            email,
          },
        },
      ])
        .then((res) => {
          const authData = prepareAuthData(res.data);
          setAuthData(authData);
          addRefreshTokenInterceptor(authData.refreshToken);
          setState({ status: AuthStatus.LoggedIn, currentUser: res.data.user });
        })
        .catch((error) => {
          removeRefreshTokenInterceptor();
          removeAuthData();
          setState({ status: AuthStatus.LoggedOut });
          throw error;
        });
    },
    [getAuthToken]
  );

  const logout = React.useCallback(async () => {
    if (state.status !== AuthStatus.LoggedIn) {
      return;
    }

    setState({ status: AuthStatus.LoggingOut, currentUser: state.currentUser });
    removeRefreshTokenInterceptor();
    removeAuthData();
    await queryClient.invalidateQueries();
    setState({ status: AuthStatus.LoggedOut });
  }, [queryClient, state]);

  const updateUserData = React.useCallback(
    (data: Partial<Pick<RegisteredUser, 'first_name' | 'last_name' | 'email' | 'image'>>) => {
      if (state.status !== AuthStatus.LoggedIn) {
        return;
      }

      setState((prevValue) => {
        if (prevValue.status !== AuthStatus.LoggedIn) {
          return prevValue;
        }

        return { ...prevValue, currentUser: { ...prevValue.currentUser, ...data } };
      });
    },
    [state]
  );

  const authenticationState: AuthenticationContextValue = {
    ...state,
    login,
    loginSalesforce,
    loginMutationState,
    logout,
    verifyReset,
    updateUserData,
  };

  const ability = React.useMemo(
    () =>
      defineAbilityFor(
        state.status === AuthStatus.LoggedIn || state.status === AuthStatus.LoggingOut ? state.currentUser : undefined
      ),
    [state]
  );

  return (
    <AuthenticationContext.Provider value={authenticationState}>
      <AuthorizationContext.Provider value={ability}>{children}</AuthorizationContext.Provider>
    </AuthenticationContext.Provider>
  );
};

export function useAuth() {
  return React.useContext(AuthenticationContext);
}

export function useAuthenticatedUser() {
  const auth = useAuth();

  if (auth.status === AuthStatus.LoggedIn || auth.status === AuthStatus.LoggingOut) {
    return auth.currentUser;
  }

  throw new Error('Invalid use of "useAuthenticatedUser" hook.');
}

export function useAbility() {
  return React.useContext(AuthorizationContext);
}
