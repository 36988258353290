import * as React from 'react';
import { observer } from 'mobx-react';
import { RefinementList } from 'react-instantsearch-dom';

import ProductSearchStore from 'search/stores/product-search-store';

interface SpecialAttributeFilterProps {
  store: ProductSearchStore;
}

const SpecialAttributeFilter = observer(
  class SpecialAttributeFilter extends React.Component<SpecialAttributeFilterProps> {
    private containerRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
      const { store } = this.props;
      const { specialAttributeFilterOpened: opened } = store;
      if (!opened && this.containerRef.current) {
        this.containerRef.current.style['display'] = 'none';
      }
    }

    render() {
      const { store } = this.props;
      const { specialAttributeFilterOpened: opened } = store;
      const classes = ['c-filters__group', opened ? 'is-open' : ''];
      return (
        <>
          <div className={classes.join(' ')}>
            <div
              className="c-filters__header c-accordion-toggle"
              onClick={() => {
                store.setSpecialAttributeFilterOpened(!opened);
                if (this.containerRef.current) {
                  jQuery(this.containerRef.current).stop(true).slideToggle();
                }
              }}
            >
              <p className="c-filters__title">Special Attributes</p>
            </div>
            <div className="c-filters__container c-accordion-container" ref={this.containerRef}>
              <RefinementList attribute="special_attributes" limit={100} />
            </div>
          </div>
        </>
      );
    }
  }
);

export default SpecialAttributeFilter;
