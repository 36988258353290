import * as React from 'react';
import classNames from 'classnames';

import api from 'api';
import { useAbility } from 'auth';
import { useQuery } from 'hooks/query';
import { DisplayPrice } from 'my-account/utils/currency';
import { CountPlaceholder } from 'my-account/components/CountPlaceholder';
import { usePendingQuote } from 'my-account/components/PendingQuote/context';

export const ProductHeroPrice: React.VFC<{ product: Symmons.Product; small?: boolean }> = ({ product, small }) => {
  const ability = useAbility();
  const { addCustomPrice } = usePendingQuote();
  const loadCustomPrice = !product.isObsolete && ability.can('read', 'ProductPrice');

  const [myPriceData, myPriceDataQueryState] = useQuery(
    api.pricing.getPricing,
    {
      modelNumber: product.sku,
    },
    {
      retry: false,
      enabled: loadCustomPrice,
      onSuccess(data) {
        addCustomPrice(data.data.model_number, data.data.price);
      },
    }
  );

  if (product.isObsolete) {
    return null;
  }

  if (loadCustomPrice) {
    return (
      <div className="u-ml-auto">
        <div className={classNames('c-price-comparison', { 'c-price-comparison--small': small }, 'c-list-grid')}>
          {product.price && (
            <div>
              <p className="c-note">List price:</p>
              <div className="c-price">
                <p className="c-price__amount">
                  <span className="c-price__small c-price__sup">{product.price.sign}</span>
                  <span className="c-price__number">{product.price.formattedAmmount}</span>
                  <span className="c-price__small">{product.price.currency}</span>
                </p>
              </div>
            </div>
          )}
          <div
            className={classNames(
              'c-price-comparison__bold',
              { 'c-price-comparison__bold--small': small },
              'c-color--invert'
            )}
          >
            <p className="c-note">My price:</p>
            <div className="c-price c-price--large">
              {myPriceDataQueryState.isSuccess && (
                <p className="c-price__amount">
                  <span className="c-price__small c-price__sup">$</span>
                  <span className="c-price__number">
                    <DisplayPrice
                      value={Number(myPriceData!.data.price)}
                      currency={myPriceData!.data.currency}
                      hideCurrencySign
                    />
                  </span>
                  <span className="c-price__small">{myPriceData!.data.currency}</span>
                </p>
              )}
              {myPriceDataQueryState.isLoading && (
                <p className="c-price__amount">
                  <span className="c-price__number">
                    <CountPlaceholder size={{ width: 90, height: 22 }} shade="3" />
                  </span>
                </p>
              )}
              {myPriceDataQueryState.isError && (
                <p className="c-price__amount">
                  <span className="c-price__small">Not Available</span>
                  <span className="c-price__number">&nbsp;</span>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!product.price) {
    return null;
  }

  return (
    <div className="u-ml-auto">
      <div className="c-list-grid c-list-grid--small">
        <div>
          <p className="c-note">Price:</p>
        </div>
        <div>
          <div className="c-price">
            <p className="c-price__amount">
              <span className="c-price__small c-price__sup">{product.price.sign}</span>
              <span className="c-price__number">{product.price.formattedAmmount}</span>
              <span className="c-price__small">{product.price.currency}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
