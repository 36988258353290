import { ForbiddenError, subject } from '@casl/ability';
import classNames from 'classnames';
import merge from 'lodash/merge';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import * as React from 'react';
import { ErrorOption, FieldError, useController, useFieldArray, useFormContext, UseFormReturn } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import { useUnmount } from 'react-use';
import { z } from 'zod';

import { AdminUserRoleEnum, Company, UserRelationUpdate, UserRole } from '__generated-api__';
import api from 'api';
import { AuthStatus, useAbility, useAuth } from 'auth';
import { Accordion } from 'components/Accordion';
import { Button } from 'components/Button';
import CheckboxField from 'components/Form/CheckboxField';
import Form, { FormProvider, useForm } from 'components/Form/Form';
import InputField from 'components/Form/InputField';
import SubmitButton from 'components/Form/SubmitButton';
import Icon from 'components/icon';
import { Pagination } from 'components/Pagination';
import { useFuse } from 'hooks/fuse';
import { useMutation } from 'hooks/query';
import { getAddressText } from 'my-account/components/AddressText';
import { Modal } from 'my-account/components/Modal';
import { SearchFilter } from 'my-account/components/SearchFilter';
import { SelectCompany } from 'my-account/components/SelectCompany';
import { SelectUser } from 'my-account/components/SelectUser';
import { useToast } from 'my-account/toast';
import { getCounter } from 'my-account/utils/counter';
import { useErrorHandler } from 'my-account/utils/error-handler';
import { getUserName } from 'my-account/utils/user';
import { UserRelationUpdateSchema } from 'my-account/validations/user';

const getUniqueAddressId = getCounter(0, 'address');

const AddCompanyAddress: React.VFC<{
  getValues: UseFormReturn<z.infer<typeof CompanyUpdateSchema>>['getValues'];
  setValue: (value: z.infer<typeof CompanyUpdateSchema>['addresses'][number]) => void;
  setError: (option: ErrorOption) => void;
  index: number;
  inputRef?: React.RefObject<HTMLInputElement>;
}> = ({ getValues, setError, index, setValue, inputRef }) => {
  const auth = useAuth();
  const {
    formState: { errors },
  } = useFormContext<z.infer<typeof CompanyUpdateSchema>>();
  const isAdmin = auth.status === AuthStatus.LoggedIn && auth.currentUser.role === AdminUserRoleEnum.Admin;
  const [fetchCompanyAddresses, fetchCompanyAddressesState] = useMutation(api.company.fetchCompanyAddresses);
  const { clearErrors, handleError } = useErrorHandler();
  const error = typeof errors.addresses !== 'undefined' ? errors.addresses[index]?.bill_to_id : undefined;

  useUnmount(() => {
    clearErrors();
  });

  if (!isAdmin) {
    return null;
  }

  return (
    <div className={classNames('c-fetch-card', { 'c-fetch-card--error': typeof error !== 'undefined' })}>
      <div className="c-fetch-card__inner">
        <div className="c-fetch-card__input">
          <InputField
            name={`addresses.${index}.bill_to_id`}
            label="Company ID"
            disabled={fetchCompanyAddressesState.isLoading}
            elStyle="fill"
            ref={inputRef}
          />
        </div>

        <div>
          <Button
            variant="white"
            disabled={fetchCompanyAddressesState.isLoading}
            onClick={async (event) => {
              event.preventDefault();
              const { addresses } = getValues();
              const bill_to_id = Number(addresses[index]['bill_to_id']);

              clearErrors();

              if (!bill_to_id || Number.isNaN(bill_to_id) || bill_to_id < 1) {
                setError({
                  message: 'Please fill in Company ID before fetching details.',
                });
                return;
              }

              const ids = addresses.map((address) => address.bill_to_id);
              if (ids.includes(bill_to_id)) {
                setError({
                  message: `Company with ${bill_to_id} has already been added.`,
                });
                return;
              }

              try {
                const companyDetailsRes = await fetchCompanyAddresses([{ billTo: String(bill_to_id) }]);

                setValue({
                  id: addresses[index].id,
                  bill_to_id,
                  name: companyDetailsRes.data.company.name,
                  address: companyDetailsRes.data.company.address,
                  city: companyDetailsRes.data.company.city,
                  state: companyDetailsRes.data.company.state,
                  zip_code: companyDetailsRes.data.company.zip_code,
                  locations: companyDetailsRes.data.locations,
                });
              } catch (e) {
                handleError(e);
              }
            }}
          >
            <span>Fetch</span>
            <Icon name="arrow" className="o-svg-icon o-svg-right" />
          </Button>
        </div>
      </div>
    </div>
  );
};

const addRepresentativeSchema = z.object({
  users: z.array(UserRelationUpdateSchema),
});

const AddRepresentative: React.VFC<{
  salesRepsIds: string[];
  onAdd: (users: UserRelationUpdate[]) => void;
  onClose: () => void;
}> = ({ salesRepsIds, onAdd, onClose }) => {
  const auth = useAuth();
  const isAdmin = auth.status === AuthStatus.LoggedIn && auth.currentUser.role === AdminUserRoleEnum.Admin;
  const { context, formProps } = useForm({
    schema: addRepresentativeSchema,
    initialValues: {
      users: undefined,
    },
    onSubmit(values, ctx, event) {
      event?.stopPropagation();
      onAdd(values.users);
      onClose();

      return Promise.resolve();
    },
  });

  if (!isAdmin) {
    return null;
  }

  return (
    <FormProvider {...context}>
      <div className="c-fetch-card c-fetch-card--colappsable">
        <div className="c-fetch-card__inner">
          <div className="c-fetch-card__input">
            <SelectUser
              name="users"
              elStyle="fill"
              placeholder="Representative"
              queryParams={{ role: UserRole.SalesRep }}
              isMulti
              selectProps={{
                filterOption: (option) => {
                  return salesRepsIds.indexOf(option.value) === -1;
                },
                onKeyDown: (event) => {
                  if (event.key === 'Enter') {
                    formProps.onSubmit();
                  }
                },
              }}
            />
          </div>

          <div>
            <button
              className="c-button c-button--white"
              type="button"
              onClick={(event) => {
                event.preventDefault();
                formProps.onSubmit();
              }}
            >
              <span>Add</span>
              <Icon name="arrow" className="o-svg-icon o-svg-right" />
            </button>
          </div>
        </div>
        <div className="c-fetch-card__close">
          <button
            type="button"
            onClick={(event) => {
              event.preventDefault();
              onClose();
            }}
          >
            <Icon name="close-thin" className="o-svg-icon o-svg-right" />
          </button>
        </div>
      </div>
    </FormProvider>
  );
};
type CompanyModalStateValue =
  | {
      bill_to_id: string | number | undefined;
      name: string | null | undefined;
    }
  | undefined;
const CompanyAddressesField: React.VFC<{
  setModalState: React.Dispatch<React.SetStateAction<CompanyModalStateValue>>;
}> = ({ setModalState }) => {
  const auth = useAuth();
  const [searchValue, setSearchValue] = React.useState<string | undefined>(undefined);
  const isAdmin = auth.status === AuthStatus.LoggedIn && auth.currentUser.role === AdminUserRoleEnum.Admin;
  const formCtx = useFormContext<z.infer<typeof CompanyUpdateSchema>>();
  const arrayField = useFieldArray<z.infer<typeof CompanyUpdateSchema>, 'addresses'>({
    control: formCtx.control,
    name: 'addresses',
  });
  const addressesError = formCtx.formState.errors.addresses;
  const canAddAddress = isAdmin && arrayField.fields.filter((item) => typeof item.bill_to_id === 'string').length === 0;
  const addAddressInputRef = React.useRef<HTMLInputElement>(null);
  const addAddressInputIndex = arrayField.fields.findIndex((value) => typeof value.bill_to_id !== 'number');
  const [current_page, setCurrentPage] = React.useState(1);
  const ITEMS_PER_PAGE = 6;
  const allData = arrayField.fields.filter((value) => typeof value.bill_to_id === 'number');
  const { results, search } = useFuse(allData, {
    findAllMatches: true,
    keys: [
      { name: 'bill_to_id', weight: 1 },
      { name: 'name', weight: 3 },
    ],
  });
  const data = results.slice((current_page - 1) * ITEMS_PER_PAGE, current_page * ITEMS_PER_PAGE);
  const last_page = Math.ceil(results.length / ITEMS_PER_PAGE);

  React.useEffect(() => {
    search(searchValue || '');
  }, [search, searchValue]);

  return (
    <div className="o-row">
      <div className="o-col-3@md">
        <p className="u-text-xs u-uppercase u-mb-spacer-base-small">Company IDs</p>
        <p className="c-note">Use the drop-down list to associate the company a specific company ID and location.</p>
        <SearchFilter
          placeholder="Search by name"
          defaultValue={searchValue || ''}
          navigateSearch={setSearchValue}
          navigatePagination={(page) => setCurrentPage(page)}
        />
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          href="#"
          className="c-link-cta-basic u-mt-spacer-base-small u-mb-spacer-base"
          onClick={(event) => {
            event.preventDefault();
            if (canAddAddress) {
              arrayField.append({ id: getUniqueAddressId(), bill_to_id: '' });
            } else if (addAddressInputRef.current) {
              addAddressInputRef.current.focus();
            }
          }}
        >
          <span>Add New Company ID</span>
          <Icon name="add" className="o-svg-icon u-text-neutral-300" />
        </a>
      </div>

      <div className="o-col-9@md">
        {
          /* This will display addresses bill_to_id errors if address value is empty */
          typeof addressesError !== 'undefined' && (
            <ul className="c-form-element--error__list" style={{ display: 'block' }}>
              {Array.isArray(addressesError) ? (
                addressesError.map((e, index) => {
                  if (typeof e !== 'undefined') {
                    return Object.entries(e)
                      .filter(
                        ([key, error]) =>
                          typeof error !== 'undefined' && typeof (error! as FieldError).message !== 'undefined'
                      )
                      .map(([key, error]) => {
                        return <li key={`${index}_${key}`}>{(error! as FieldError).message!}</li>;
                      });
                  }

                  return null;
                })
              ) : typeof (addressesError as FieldError).message === 'string' ? (
                <li>{(addressesError as FieldError).message}</li>
              ) : null}
            </ul>
          )
        }

        {data.map(({ item }) => {
          const index = arrayField.fields.findIndex(({ id }) => id === item.id);

          if (typeof item.bill_to_id === 'number') {
            const companyAddress = getAddressText({ address: item });

            return (
              <Accordion
                key={item.bill_to_id}
                title={item.name}
                subtitle={item.bill_to_id}
                toggleActions={
                  isAdmin ? (
                    <div className="u-hidden u-block@sm">
                      <button
                        className="c-link-cta-basic c-link-cta--small c-link-cta--multiple"
                        onClick={(event) => {
                          event.preventDefault();
                          arrayField.remove(index);
                        }}
                        type="button"
                      >
                        <span>Remove company ID</span>
                      </button>
                      <button
                        className="c-link-cta-basic c-link-cta--small c-link-cta--multiple"
                        onClick={(event) => {
                          event.preventDefault();
                          setModalState({
                            bill_to_id: item.bill_to_id,
                            name: item.name,
                          });
                        }}
                        type="button"
                      >
                        <span>Move company ID</span>
                      </button>
                    </div>
                  ) : undefined
                }
                type="card"
              >
                <div className="o-row">
                  <div className="o-col-6@lg">
                    {isAdmin && (
                      <p className="u-hidden@sm">
                        <button
                          className="c-link-cta-basic c-link-cta--small"
                          onClick={(event) => {
                            event.preventDefault();
                            arrayField.remove(index);
                          }}
                          type="button"
                        >
                          <span>Remove company ID</span>
                        </button>
                      </p>
                    )}

                    {companyAddress !== null && (
                      <>
                        <p className="c-accordion__subtitle">Billing Address:</p>
                        <p>{companyAddress}</p>
                      </>
                    )}
                  </div>
                </div>

                {item.locations && item.locations.length > 0 && (
                  <>
                    <hr />

                    <p className="c-accordion__subtitle">Locations</p>

                    <div className="c-tag__group">
                      {sortBy(item.locations, 'name').map((location, index) => {
                        if (typeof location.id === 'undefined') {
                          return (
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <a href="#" key={`_${index}`} className="c-tag c-tag--white c-tag--stroke">
                              {location.name}
                            </a>
                          );
                        }

                        return (
                          <Link
                            to={`/locations/${location.id}`}
                            key={location.id}
                            className="c-tag c-tag--white c-tag--stroke"
                          >
                            {location.name}
                          </Link>
                        );
                      })}
                    </div>
                  </>
                )}
              </Accordion>
            );
          }

          return null;
        })}

        {addAddressInputIndex > -1 && (
          <AddCompanyAddress
            getValues={formCtx.getValues}
            index={addAddressInputIndex}
            setError={(option: ErrorOption) => {
              formCtx.setError(`addresses.${addAddressInputIndex}.bill_to_id` as 'addresses.0.bill_to_id', option);
            }}
            setValue={(value: z.infer<typeof CompanyUpdateSchema>['addresses'][number]) => {
              arrayField.update(addAddressInputIndex, value);
            }}
            inputRef={addAddressInputRef}
          />
        )}

        {canAddAddress && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            href="#"
            className="c-link-cta-basic u-mt-spacer-base-small u-mb-spacer-base"
            onClick={(event) => {
              event.preventDefault();
              arrayField.append({ id: getUniqueAddressId(), bill_to_id: '' });
            }}
          >
            <span>Add New</span>
            <Icon name="add" className="o-svg-icon u-text-neutral-300" />
          </a>
        )}

        <Pagination current_page={current_page} last_page={last_page} navigate={(page) => setCurrentPage(page)} />
      </div>
    </div>
  );
};

const CompanySalesRepsField = () => {
  const salesRepIdField = useController<z.infer<typeof CompanyUpdateSchema>, 'sales_rep_id'>({ name: 'sales_rep_id' });
  const arrayField = useController<z.infer<typeof CompanyUpdateSchema>, 'sales_reps'>({
    name: 'sales_reps',
  });
  const [isAddRepresentativeOpen, setIsAddRepresentativeOpen] = React.useState(arrayField.field.value.length === 0);

  const onChange = (value: z.infer<typeof CompanyUpdateSchema>['sales_reps']) => {
    arrayField.field.onChange(value);
  };

  const addRepresentatives = (users: z.infer<typeof CompanyUpdateSchema>['sales_reps']) => {
    if (!arrayField.field.value.length) {
      salesRepIdField.field.onChange(users[0].id);
    }

    onChange(uniqBy([...arrayField.field.value, ...users], 'id'));
  };

  const removeSalesRepWithId = (id: number) => {
    onChange(arrayField.field.value.filter((sales_rep) => sales_rep.id !== id));
    if (salesRepIdField.field.value === id) {
      salesRepIdField.field.onChange(null);
    }
  };

  return (
    <div className="o-row">
      <div className="o-col-3@md">
        <p className="u-text-xs u-uppercase u-mb-spacer-base-small">Symmons representative</p>
        <p className="c-note">
          Select the Company's Symmons Representative from the dropdown field. This Representative will apply to all
          Locations. Should you want to change a Location's Representative, you may edit it on the Location edit screen.
        </p>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          href="#"
          className="c-link-cta-basic u-mt-spacer-base-small u-mb-spacer-base u-text-xs"
          onClick={(event) => {
            event.preventDefault();
            setIsAddRepresentativeOpen(true);
          }}
        >
          <span>Add new representative</span>
          <Icon name="add" className="o-svg-icon u-text-neutral-300" />
        </a>
      </div>

      <div className="o-col-9@md">
        <div className="o-row o-row--small-gutters">
          <div className="o-col-12">
            {arrayField.field.value.map((sales_rep) => (
              <Accordion
                key={sales_rep.id}
                title={getUserName(sales_rep).name}
                type="card"
                isPrimary={salesRepIdField.field.value === sales_rep.id}
                toggleActions={
                  <div className="u-hidden u-block@sm">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      href="#"
                      className="c-link-cta-basic c-link-cta--small"
                      onClick={(event) => {
                        event.preventDefault();
                        removeSalesRepWithId(sales_rep.id);
                      }}
                    >
                      <span>Remove representative</span>
                    </a>
                  </div>
                }
              >
                <div className="o-row">
                  <div className="o-col-6@lg">
                    <div className="c-table-wrapper">
                      <table>
                        <tbody>
                          {Boolean(sales_rep.phone) && (
                            <tr>
                              <th>T:</th>
                              <td>{sales_rep.phone}</td>
                            </tr>
                          )}
                          <tr>
                            <th>E:</th>
                            <td>
                              <a href={`mailto:${sales_rep.email}`}>{sales_rep.email}</a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <hr className="u-mb-spacer-base" />

                <div>
                  <CheckboxField
                    checkboxStyle="toggle"
                    name={`sales_reps_is_primary.${sales_rep.id}`}
                    label="This user is a primary representative for the company"
                    inputProps={{
                      onChange: () => {
                        salesRepIdField.field.onChange(
                          salesRepIdField.field.value === sales_rep.id ? null : sales_rep.id
                        );
                      },
                      checked: salesRepIdField.field.value === sales_rep.id,
                    }}
                  />
                </div>
              </Accordion>
            ))}
            {isAddRepresentativeOpen && (
              <AddRepresentative
                salesRepsIds={arrayField.field.value.map((sales_rep) => String(sales_rep.id))}
                onAdd={(users) => {
                  addRepresentatives(users);
                }}
                onClose={() => {
                  setIsAddRepresentativeOpen(false);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const CompanyUpdateSchema = z.object({
  name: z.string().nonempty(),
  wholesaler: z.boolean().optional(),
  evolution: z.boolean().optional(),
  direct_buyer: z.boolean().optional(),
  master_id: z.string().optional(),
  master_name: z.string().optional(),
  sales_rep_id: z.number().positive().nullable().optional(),
  sales_reps: z.array(
    z.object({
      id: z.number(),
      first_name: z.string().optional().nullable(),
      last_name: z.string().optional().nullable(),
      email: z.string().email().optional().nullable(),
      phone: z.string().nullable().optional(),
    })
  ),
  addresses: z.array(
    z.object({
      id: z.string().optional(),
      bill_to_id: z.union([z.number().positive(), z.string().optional()]),
      name: z.string().nullable().optional(),
      address: z.string().nullable().optional(),
      city: z.string().nullable().optional(),
      state: z.string().nullable().optional(),
      zip_code: z.string().nullable().optional(),
      locations: z
        .array(
          z.object({
            id: z.number().positive().optional(),
            bill_to_id: z.number().positive().optional(),
            name: z.string().nullable().optional(),
            address: z.string().nullable().optional(),
            city: z.string().nullable().optional(),
            state: z.string().nullable().optional(),
            zip_code: z.string().nullable().optional(),
          })
        )
        .optional(),
    })
  ),
});

const CompanySchema = CompanyUpdateSchema.extend({
  id: z.number().positive(),
});

const getCompanyInitialValues = (company: Company | undefined): z.infer<typeof CompanyUpdateSchema> => {
  if (typeof company !== 'undefined') {
    const addresses = company.addresses.map((item) => ({ id: getUniqueAddressId(), ...item }));
    return {
      name: company.name,
      wholesaler: company.wholesaler,
      evolution: company.evolution,
      direct_buyer: company.direct_buyer,
      master_name: company.master_name,
      master_id: company.master_id,
      sales_rep_id: company.sales_rep_id,
      sales_reps: company.sales_reps || [],
      addresses: addresses.length
        ? addresses
        : [
            {
              id: getUniqueAddressId(),
              bill_to_id: '',
              address: null,
              city: null,
              locations: [],
              name: null,
              state: null,
              zip_code: null,
            },
          ],
    };
  }

  return {
    name: '',
    wholesaler: true,
    evolution: false,
    direct_buyer: false,
    sales_rep_id: null,
    sales_reps: [],
    addresses: [
      {
        id: getUniqueAddressId(),
        bill_to_id: '',
        address: null,
        city: null,
        locations: [],
        name: null,
        state: null,
        zip_code: null,
      },
    ],
  };
};

export const MoveBillToModal: React.VFC<{
  company: Company;
  modalState: CompanyModalStateValue;
  setModalState: React.Dispatch<React.SetStateAction<CompanyModalStateValue>>;
  onSuccess: (company: Company) => void;
}> = ({ company, modalState, setModalState, onSuccess }) => {
  const ability = useAbility();
  const toast = useToast();
  const [moveBillToCompany] = useMutation(api.company.moveBillToCompany);
  return (
    <Modal
      isOpen={modalState !== undefined}
      onRequestClose={() => setModalState(undefined)}
      style={{
        content: {
          maxWidth: '41.25rem',
        },
      }}
    >
      <div className={classNames('c-modal')} style={{ width: '100%' }}>
        {/*eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */}
        <a
          href="#"
          className="c-modal-close"
          onClick={(event) => {
            event.preventDefault();
            setModalState(undefined);
          }}
        />

        <div className="c-modal__main">
          {modalState !== undefined && (
            <Form
              initialValues={{
                bill_to_id: String(modalState.bill_to_id),
                company: undefined,
              }}
              schema={z.object({
                bill_to_id: z.string().nonempty(),
                company: CompanySchema.optional(),
              })}
              onSubmit={async (values, ctx) => {
                console.log(values);
                ForbiddenError.from(ability).throwUnlessCan(
                  company ? 'update' : 'create',
                  subject('Company', merge({}, company, values))
                );
                const id = values.company ? values.company?.id : undefined;
                if (typeof id !== 'undefined') {
                  const res = await moveBillToCompany([{ id, moveBillToBody: { bill_to_id: values.bill_to_id } }]);
                  toast.notify({
                    type: 'success',
                    title: 'Success',
                    message: `You've moved ${modalState.name} to ${values.company?.name}.`,
                  });
                  setModalState(undefined);
                  onSuccess(res.data);
                }
              }}
            >
              <div className="c-block c-block--spacing-t-extra-small c-block--spacing-b-small">
                <div className="o-container-fluid">
                  <div className="o-row">
                    <div className="o-col-12">
                      <div className="c-block__header c-block__header--hor">
                        <div className="c-block__header-content u-items-center u-block u-flex@sm">
                          <div>
                            <h4 className="u-mb-spacer-base-large">Move {modalState.name}</h4>
                            <p className="c-note">You can use this to move {modalState.name} to a different company</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="o-row">
                    <div className="o-col-12">
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <SelectCompany
                        elStyle="fill"
                        name="company"
                        label="Company"
                        selectProps={{ autoComplete: 'off', isClearable: true }}
                        small
                      />
                      <div className="c-form-footer">
                        <SubmitButton variant="secondary" isFull>
                          Save changes
                        </SubmitButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </div>
      </div>
    </Modal>
  );
};

export const EditCompanyForm: React.VFC<{ company?: Company }> = ({ company }) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();
  const auth = useAuth();
  const ability = useAbility();
  const [createCompany] = useMutation(api.company.createCompany);
  const [updateCompany] = useMutation(api.company.updateCompany);
  const [deleteCompany] = useMutation(api.company.deleteCompany);
  const isAdmin = auth.status === AuthStatus.LoggedIn && auth.currentUser.role === AdminUserRoleEnum.Admin;
  const [modalState, setModalState] = React.useState<CompanyModalStateValue>(undefined);
  const canSubmit = company ? ability.can('update', subject('Company', company)) : ability.can('create', 'Company');
  const canRemove = company ? ability.can('delete', subject('Company', company)) : false;
  const form = useForm({
    schema: CompanyUpdateSchema,
    onSubmit: async (values, ctx) => {
      ForbiddenError.from(ability).throwUnlessCan(
        company ? 'update' : 'create',
        subject('Company', merge({}, company, values))
      );

      const data = {
        ...values,
        addresses: values.addresses.filter((item) => typeof item.bill_to_id === 'number') as Array<
          Omit<z.infer<typeof CompanyUpdateSchema>['addresses'][0], 'bill_to_id'> & { bill_to_id: number }
        >,
      };

      if (typeof company === 'undefined') {
        const res = await createCompany([{ createCompanyBody: { ...data, sales_rep_id: data.sales_rep_id ?? null } }]);
        ctx.reset(getCompanyInitialValues(res.data));
        history.push(`/companies/${res.data.id}`);

        toast.notify({
          type: 'success',
          title: 'Success',
          message: "You've successfully created a new Company.",
        });
      } else {
        const res = await updateCompany([
          { id: company.id, updateCompanyBody: { ...data, sales_rep_id: data.sales_rep_id ?? null } },
        ]);
        ctx.reset(getCompanyInitialValues(res.data));

        toast.notify({
          type: 'success',
          title: 'Success',
          message: "You've successfully updated the Company.",
        });
      }
    },
    initialValues: getCompanyInitialValues(company),
    formProps: { mode: 'onSubmit' },
    hasFloatingLabels: true,
  });
  return (
    <>
      {company && (
        <MoveBillToModal
          company={company}
          modalState={modalState}
          setModalState={setModalState}
          onSuccess={(data) => {
            const company = getCompanyInitialValues(data);
            form.context.reset(company);
          }}
        />
      )}
      <FormProvider {...form.context}>
        <form {...form.formProps}>
          <section className="c-block c-block--spacing-t-extra-small c-block--spacing-b c-block--spacing-b-large@md">
            <div className="o-container-fluid">
              <div className="o-row">
                <div className="o-col-3@md">
                  <p className="u-text-xs u-uppercase u-mb-spacer-base-small">General info</p>
                  <p className="c-note">Enter the company name into the Company name field.</p>
                </div>

                <div className="o-col-9@md o-col-6@lg">
                  <div className="o-row o-row--small-gutters">
                    <div className="o-col-12">
                      <InputField name="name" label="Company Name" elStyle="fill" />
                    </div>
                  </div>
                  <div className="o-row o-row--small-gutters">
                    <div className="o-col-12">
                      <CheckboxField name="wholesaler" label="Wholesaler" checkboxStyle="toggle" />
                      <CheckboxField name="evolution" label="Evolution Customer" checkboxStyle="toggle" />
                      <CheckboxField name="direct_buyer" label="Direct Buyer" checkboxStyle="toggle" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="o-row">
                <div className="o-col-12">
                  <hr className="u-mt-spacer-base u-mt-spacer-base-large@sm u-mb-spacer-base-large u-mb-spacer-section-small@sm" />
                </div>
              </div>

              <CompanySalesRepsField />

              <div className="o-row">
                <div className="o-col-12">
                  <hr className="u-mt-spacer-base u-mt-spacer-base-large@sm u-mb-spacer-base-large u-mb-spacer-section-small@sm" />
                </div>
              </div>

              <CompanyAddressesField setModalState={setModalState} />

              <div className="o-row">
                <div className="o-col-12">
                  <hr className="u-mt-spacer-base u-mt-spacer-base-large@sm u-mb-spacer-base-large u-mb-spacer-section-small@sm" />
                </div>
              </div>
              {isAdmin && (
                <div className="o-row">
                  <div className="o-col-3@md">
                    <p className="u-text-xs u-uppercase u-mb-spacer-base-small">General info</p>
                    <p className="c-note">Enter the company name into the Company name field.</p>
                  </div>

                  <div className="o-col-9@md o-col-6@lg">
                    <div className="o-row o-row--small-gutters">
                      <div className="o-col-12">
                        <InputField name="master_name" label="Master Name" elStyle="fill" />
                      </div>
                    </div>
                    <div className="o-row o-row--small-gutters">
                      <div className="o-col-12">
                        <InputField name="master_id" label="Master Id" elStyle="fill" />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="o-row">
                <div className="o-col-12">
                  <hr className="u-mt-spacer-base u-mt-spacer-base-large@sm u-mb-spacer-base-large u-mb-spacer-section-small@sm" />
                </div>
              </div>

              <div className="o-row u-items-center">
                <div className="o-col-4@md o-offset-3@md">
                  <div className="c-form-footer">
                    <SubmitButton variant="secondary" isFull disabled={!canSubmit}>
                      Save changes
                    </SubmitButton>
                  </div>
                </div>

                {canRemove && company && (
                  <div className="o-col-5@md u-text-right@md">
                    {company.status ? (
                      /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
                      <a
                        href="#"
                        className="c-link-cta-basic c-link-cta--small u-mt-spacer-base u-mt-0@md u-mb-0"
                        onClick={async (event) => {
                          event.preventDefault();

                          if (
                            window.confirm(`Are you sure that you really want to archive "${company.name}" company?`)
                          ) {
                            await deleteCompany([{ id: company.id }]);

                            await Promise.all([
                              queryClient
                                .cancelQueries(api.company.listCompanies.getQueryKey()[0])
                                .then(() => queryClient.invalidateQueries(api.company.listCompanies.getQueryKey()[0])),
                              queryClient
                                .cancelQueries(api.company.getCompany.getQueryKey({ id: company.id })[0])
                                .then(() =>
                                  queryClient.invalidateQueries(
                                    api.company.getCompany.getQueryKey({ id: company.id })[0]
                                  )
                                ),
                            ]);

                            history.push('/companies');
                            toast.notify({
                              type: 'success',
                              title: 'Success',
                              message: `The "${company.name}" company was archived successfully.`,
                            });
                          }
                        }}
                      >
                        <Icon name="trash" className="o-svg-icon o-svg-larger" />
                        <span>Archive company</span>
                      </a>
                    ) : (
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      <a
                        href="#"
                        className="c-link-cta-basic c-link-cta--small u-mt-spacer-base u-mt-0@md u-mb-0"
                        onClick={async (event) => {
                          event.preventDefault();

                          if (
                            window.confirm(`Are you sure that you really want to activate "${company.name}" company?`)
                          ) {
                            await updateCompany([
                              {
                                id: company.id,
                                updateCompanyBody: {
                                  name: company.name,
                                  addresses: company.addresses,
                                  status: 1,
                                  sales_rep_id: company.sales_rep_id,
                                  sales_reps: company.sales_reps,
                                },
                              },
                            ]);
                            await Promise.all([
                              queryClient
                                .cancelQueries(api.company.listCompanies.getQueryKey()[0])
                                .then(() => queryClient.invalidateQueries(api.company.listCompanies.getQueryKey()[0])),
                              queryClient
                                .cancelQueries(api.company.getCompany.getQueryKey({ id: company.id })[0])
                                .then(() =>
                                  queryClient.invalidateQueries(
                                    api.company.getCompany.getQueryKey({ id: company.id })[0]
                                  )
                                ),
                            ]);
                            toast.notify({
                              type: 'success',
                              title: 'Success',
                              message: `The "${company.name}" company was activated successfully.`,
                            });
                          }
                        }}
                      >
                        <Icon name="export" className="o-svg-icon o-svg-larger" />
                        <span>Activate company</span>
                      </a>
                    )}
                  </div>
                )}
              </div>
            </div>
          </section>
        </form>
      </FormProvider>
    </>
  );
};
