import * as React from 'react';
import { useController } from 'react-hook-form';
import classNames from 'classnames';

import Icon from 'components/icon';
import { FormProvider, useForm } from 'components/Form/Form';
import SubmitButton from 'components/Form/SubmitButton';
import InputField from 'components/Form/InputField';
import CheckboxField from 'components/Form/CheckboxField';
import { Modal } from 'my-account/components/Modal';
import { SummaryBlock, SummaryBlockDefaultValues } from 'my-account/validations/project';
import useDocumentSettings from 'my-account/hooks/useDocumentSettings';
import { truncate } from 'my-account/utils/text';

const SummaryBlockFieldModalForm: React.VFC<{
  value: SummaryBlockDefaultValues;
  onChange: (value: SummaryBlockDefaultValues) => void;
  onDirtyChange: (value: boolean) => void;
  document_settings: ReturnType<typeof useDocumentSettings>;
}> = ({ value, onChange, onDirtyChange, document_settings }) => {
  const { context, formProps } = useForm({
    initialValues: {
      ...value,
      content: value.content && value.content.content ? value.content : { content: '' },
      settings: {
        ...value.settings,
        show_totals: value.settings?.show_totals ?? true,
        show_no_of_units: value.settings?.show_no_of_units ?? document_settings.show_default_number_units ?? true,
        show_list_price: value.settings?.show_list_price ?? document_settings.show_list_price ?? true,
        show_net_price: value.settings?.show_net_price ?? document_settings.show_discount_price_label ?? true,
        show_ext_price: value.settings?.show_ext_price ?? document_settings.show_extended_price ?? true,
      },
    },
    schema: SummaryBlock,
    stopSubmitPropagation: true,
    onSubmit: async (values) => {
      onChange(values);
    },
  });

  React.useEffect(() => {
    onDirtyChange(context.formState.isDirty);
  }, [context.formState.isDirty, onDirtyChange]);

  return (
    <FormProvider {...context}>
      <form {...formProps}>
        <InputField label="Title" name="content.content" elStyle="fill" />
        <CheckboxField checkboxStyle="toggle" name="settings.show_totals" label="Show Totals" />
        <CheckboxField checkboxStyle="toggle" name="settings.show_no_of_units" label="Show Number of Units" />
        <CheckboxField checkboxStyle="toggle" name="settings.show_list_price" label="Show List Price" />
        <CheckboxField checkboxStyle="toggle" name="settings.show_net_price" label="Show Net Price" />
        <CheckboxField checkboxStyle="toggle" name="settings.show_ext_price" label="Show Extended Price" />
        <SubmitButton>Save</SubmitButton>
      </form>
    </FormProvider>
  );
};

const SummaryBlockField: React.VFC<{ title: string; name: string }> = ({ title, name }) => {
  const document_settings = useDocumentSettings();

  const { field, fieldState } = useController<{ block: SummaryBlockDefaultValues }, 'block'>({
    name: name as 'block',
  });
  const [isFormDirty, setIsFormDirty] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const onClose = () => {
    if (isFormDirty) {
      if (window.confirm('Do you really want to leave? You have unsaved changes!')) {
        setIsModalOpen(false);
        field.onBlur();
      }
    } else {
      setIsModalOpen(false);
      field.onBlur();
    }
  };

  return (
    <div>
      <button
        className={classNames(
          'c-button c-button--white c-button--option c-button--full c-button--option c-button--full-drag u-mb-spacer',
          {
            'is-invalid': typeof fieldState.error !== 'undefined',
          }
        )}
        type="button"
        onClick={(event) => {
          event.preventDefault();
          setIsModalOpen(true);
        }}
      >
        <span>
          <small>{title}:</small>
          {truncate(
            (field.value.content?.content ?? '')
              .trim()
              .split('\n')
              .filter((val) => val.length)[0] ?? '',
            20
          ) || (
            <>
              Add <Icon name="add-thin" className="o-svg-icon" />
            </>
          )}
        </span>
        <Icon name="pencil" className="o-svg-icon o-svg-large u-ml-auto" />
      </button>
      {typeof fieldState.error !== 'undefined' && (
        <div className="c-form-element c-form-element--error" style={{ marginTop: '0.25rem', marginBottom: '0.75rem' }}>
          <ul className="c-form-element--error__list u-mb-0">
            <li>
              <strong>Summary Title</strong> is required
            </li>
          </ul>
        </div>
      )}
      <Modal isOpen={isModalOpen} onRequestClose={onClose}>
        <div className="c-modal" style={{ width: '100%' }}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */}
          <a
            href="#"
            className="c-modal-close"
            onClick={(event) => {
              event.preventDefault();
              onClose();
            }}
          />

          <div className="c-modal__main">
            <div className="c-block c-block--spacing-t-small c-block--spacing-b-small">
              <div className="o-container-fluid">
                <div className="o-row">
                  <div className="o-col-12">
                    <div className="c-block__header c-block__header--hor">
                      <div className="c-block__header-content u-items-center u-block u-flex@sm">
                        <div>
                          <h6 className="c-headline u-text-neutral-500">{title}</h6>
                          <h4 className="u-mb-spacer-base-large">Edit Content</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="o-col-12">
                    {isModalOpen && (
                      <SummaryBlockFieldModalForm
                        value={field.value}
                        onChange={(value) => {
                          field.onChange(value);
                          setIsModalOpen(false);
                          setIsFormDirty(false);
                        }}
                        onDirtyChange={setIsFormDirty}
                        document_settings={document_settings}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SummaryBlockField;
