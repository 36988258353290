import * as React from 'react';
import { useLocation } from 'react-router';
import { routerBasename } from 'utils/router';

export const usePageTracking = () => {
  const location = useLocation();

  React.useEffect(() => {
    try {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'pageview',
        page_path: routerBasename + location.pathname + location.search,
        page_title: document.title,
      });
    } catch (e) {
      console.log('Page tracking error:');
      console.error(e);
    }
  }, [location.pathname, location.search]);
};
