import { observer } from 'mobx-react';
import * as React from 'react';

import ProductSearchStore from 'search/stores/product-search-store';
import { CustomRefinementListComponent } from './custom-parent-refinement-component';

interface MixingValvesTypeFilterProps {
  store: ProductSearchStore;
}

const MixingValvesTypeFilter = observer(
  class MixingValvesTypeFilter extends React.Component<MixingValvesTypeFilterProps> {
    private containerRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
      const { store } = this.props;
      const { mixingValvesTypeFilterOpened: opened } = store;
      if (!opened && this.containerRef.current) {
        this.containerRef.current.style.display = 'none';
      }
    }

    render() {
      const { store } = this.props;
      const { mixingValvesTypeFilterOpened: opened } = store;
      const classes = ['c-filters__group', opened ? 'is-open' : ''];
      return (
        <>
          <div className={classes.join(' ')}>
            <div
              className="c-filters__header c-accordion-toggle"
              onClick={() => {
                store.setMixingValvesTypeFilterOpened(!opened);
                if (this.containerRef.current) {
                  jQuery(this.containerRef.current).stop(true).slideToggle();
                }
              }}
            >
              <p className="c-filters__title">Mixing Valves</p>
            </div>
            <div className="c-filters__container c-accordion-container" ref={this.containerRef}>
              <CustomRefinementListComponent
                attribute="mixing_valves_type"
                limit={25}
                subrefinements={[{ attribute: 'valve_controllers_type', key: 'Controllers' }]}
              />
            </div>
          </div>
        </>
      );
    }
  }
);
export default MixingValvesTypeFilter;
