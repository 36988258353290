import * as React from 'react';
import { useFieldArray } from 'react-hook-form';

import { ImageBlockType, TextBlockType } from '__generated-api__';
import { NotesPageDefaultValue } from 'my-account/validations/project';
import TextBlockField, { TextBlockFieldProps } from 'my-account/components/SPB/TextBlockField';
import ImageBlockField, { ImageBlockFieldProps } from 'my-account/components/SPB/ImageBlockField';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import { getCounter } from 'my-account/utils/counter';

export type TextOrImageBlockFieldProps = TextBlockFieldProps | ImageBlockFieldProps;
const getLocalBlockId = getCounter(0, 'notes_page_block_');
const NotesPageFields: React.VFC<{ currentPagePreview: number }> = ({ currentPagePreview }) => {
  const {
    fields: blocks,
    append,
    move,
  } = useFieldArray<NotesPageDefaultValue, 'blocks'>({
    name: `latest.pages.${currentPagePreview}.blocks` as unknown as 'blocks',
  });

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
      onActivation: ({ event }) => {
        event.preventDefault();
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const activeItemIndex = blocks.findIndex((block) => block.id === active.id);
      const overItemIndex = blocks.findIndex((block) => block.id === over.id);
      move(activeItemIndex, overItemIndex);
    }
  }

  React.useEffect(() => {
    const countImageBlocks = blocks.filter((block) => block.type === ImageBlockType.Image).length;
    const countTextBlocks = blocks.filter((block) => block.type === TextBlockType.Text).length;
    if (countImageBlocks < 2) {
      for (let i = 0; i < 2 - countImageBlocks; i++) {
        append({
          id: getLocalBlockId(),
          type: ImageBlockType.Image,
        });
      }
    }
    if (countTextBlocks < 3) {
      for (let i = 0; i < 3 - countTextBlocks; i++) {
        append({
          id: i + 1,
          type: TextBlockType.Text,
          content: {
            content: '',
          },
        });
      }
    }
  }, [append, blocks]);

  return (
    <div className="u-mb-spacer-base-large">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      >
        <SortableContext items={blocks} strategy={verticalListSortingStrategy}>
          {blocks.map((block, index) => (
            <SortableTextOrImageBlockField
              key={block.id}
              name={`latest.pages.${currentPagePreview}.blocks.${index}`}
              title={`${block.type === 'image' ? 'Image' : 'Text'} ${index + 1}`}
              type={block.type}
              id={block.id}
            />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
};

/**
 * Create a generic sortable text or image block field
 * @param param0 TextOrImageBlockFieldProps
 * @returns React.VFC<TextOrImageBlockFieldProps & { id: string; type: 'image' | 'text' }>
 */
export const SortableTextOrImageBlockField: React.VFC<
  TextOrImageBlockFieldProps & { id: string; type: 'image' | 'text' }
> = ({ id, type, ...props }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  if (type === ImageBlockType.Image) {
    return (
      <ImageBlockField
        {...props}
        buttonProps={{ ref: setNodeRef, style, ...attributes, ...listeners, ...props.buttonProps }}
      />
    );
  } else {
    return (
      <TextBlockField
        {...props}
        buttonProps={{ ref: setNodeRef, style, ...attributes, ...listeners, ...props.buttonProps }}
      />
    );
  }
};

export default NotesPageFields;
