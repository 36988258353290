import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import FiltersConfigurator from 'configurator/filters-configurator';
import ProductsConfigurator from 'configurator/products-configurator';
import ProductConfiguratorStore, {
  ConfiguratorSpecifications,
  configuratorTypes,
} from './stores/shower-configurator-store';
import wordpressData from 'data';

const configurator_types: Array<configuratorTypes> = ['shower'];

/**
 * Product configurator filters
 */

const productConfiguratorFilters = document.getElementById('product-configurator-filters');
if (productConfiguratorFilters) {
  const type = productConfiguratorFilters.getAttribute('data-type') as configuratorTypes;
  if (type && configurator_types.includes(type)) {
    const configurator = new ProductConfiguratorStore(type);
    const onSubmit = (specifications: ConfiguratorSpecifications) => {
      configurator.specifications = specifications;
      if (configurator.type === 'shower') {
        window.location.href = wordpressData.productConfigurator.showerPage;
      }
    };

    ReactDOM.render(
      <BrowserRouter>
        <Route path="/" render={(props) => <FiltersConfigurator configurator={configurator} onSubmit={onSubmit} />} />
      </BrowserRouter>,
      productConfiguratorFilters
    );
  }
}

/**
 * Product selection for configurator
 */

const productConfigurator = document.getElementById('product-configurator');
if (productConfigurator) {
  const type = productConfigurator.getAttribute('data-type') as configuratorTypes;
  if (type && configurator_types.includes(type)) {
    const title = productConfigurator.getAttribute('data-title');
    const introduction = productConfigurator.getAttribute('data-introduction');
    const modal_title = productConfigurator.getAttribute('data-modal-title');
    const modal_content = productConfigurator.getAttribute('data-modal-content');

    const configurator = new ProductConfiguratorStore(type);
    ReactDOM.render(
      <BrowserRouter>
        <Route
          path="/"
          render={(props) => (
            <ProductsConfigurator
              configurator={configurator}
              title={title ?? undefined}
              introduction={introduction ?? undefined}
              modal_title={modal_title ?? undefined}
              modal_content={modal_content ?? undefined}
            />
          )}
        />
      </BrowserRouter>,
      productConfigurator
    );
  }
}
if (module.hot) {
  module.hot.accept();
}
