import * as React from 'react';
import * as rq from 'react-query';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

/**
 * If type has a Promise, unwrap it. Otherwise return the original type
 */
type Await<T> = T extends PromiseLike<infer U> ? U : T;

/**
 * Get the return type of a function which returns a Promise.
 */
export type GetPromiseReturnType<T extends (...args: any) => Promise<any>> = Await<ReturnType<T>>;

export type GetQueryFnParams<T> = T extends (...args: [infer Params, any | undefined]) => any ? Params : never;

export type MutationParams<Params> = [Params, AxiosRequestConfig | undefined] | [Params];

export type BaseApiFn<Params extends any[], Response extends any> = {
  (...args: Params): Promise<Response>;
  getQueryKey(...args: Params): string[];
};

export type UseQueryResult<
  TQueryFn extends BaseApiFn<any[], any>,
  TError = unknown,
  TQueryFnData = GetPromiseReturnType<TQueryFn>
> = [rq.UseQueryResult<TQueryFnData, TError>['data'], Omit<rq.UseQueryResult<TQueryFnData, TError>, 'data'>];

export const useQuery = <
  TQueryFn extends BaseApiFn<any[], any>,
  TError = unknown,
  TQueryFnData = GetPromiseReturnType<TQueryFn>
>(
  queryFn: TQueryFn,
  params: GetQueryFnParams<TQueryFn>,
  options?: rq.UseQueryOptions<TQueryFnData, TError, TQueryFnData, string[]> & {
    axiosRequestConfig?: AxiosRequestConfig;
  }
): UseQueryResult<TQueryFn, TError, TQueryFnData> => {
  const { axiosRequestConfig, ...queryOptions } = options ?? {};
  const queryKey = queryFn.getQueryKey(params, axiosRequestConfig);

  const { data, ...rest } = rq.useQuery<TQueryFnData, TError, TQueryFnData, string[]>(
    queryOptions.queryKey ? queryOptions.queryKey : queryKey,
    () => {
      const source = axios.CancelToken.source();

      const promise: Promise<any> & { cancel?: () => void } = queryFn(params, {
        ...axiosRequestConfig,
        cancelToken: source.token,
      });

      promise.cancel = () => {
        source.cancel('Query was cancelled by React Query.');
      };

      return promise;
    },
    queryOptions
  );

  return [data, rest];
};

export const useAxiosResponseQuery = <
  TQueryFn extends BaseApiFn<any[], AxiosResponse<any>>,
  TError = unknown,
  TQueryFnData = GetPromiseReturnType<TQueryFn>['data']
>(
  queryFn: TQueryFn,
  params: GetQueryFnParams<TQueryFn>,
  options?: rq.UseQueryOptions<TQueryFnData, TError, TQueryFnData, string[]> & {
    axiosRequestConfig?: AxiosRequestConfig;
  }
): [rq.UseQueryResult<TQueryFnData, TError>['data'], Omit<rq.UseQueryResult<TQueryFnData, TError>, 'data'>] => {
  const queryDataFn = React.useMemo(() => {
    const fn = (...args: any[]): Promise<TQueryFnData> => {
      return queryFn(...args).then(({ data }) => data);
    };

    fn.getQueryKey = queryFn.getQueryKey;

    return fn;
  }, [queryFn]);
  return useQuery(queryDataFn, params, options);
};

export type UseMutationResult<
  TQueryFn extends BaseApiFn<any[], any>,
  TError = unknown,
  TContext = unknown,
  TQueryFnData = GetPromiseReturnType<TQueryFn>
> = [
  rq.UseMutationResult<TQueryFnData, TError, MutationParams<GetQueryFnParams<TQueryFn>>, TContext>['mutateAsync'],
  Omit<
    rq.UseMutationResult<TQueryFnData, TError, MutationParams<GetQueryFnParams<TQueryFn>>, TContext>,
    'mutate' | 'mutateAsync'
  >
];

export const useMutation = <
  TQueryFn extends BaseApiFn<any[], any>,
  TError = unknown,
  TContext = unknown,
  TQueryFnData = GetPromiseReturnType<TQueryFn>
>(
  queryFn: TQueryFn,
  options?: rq.UseMutationOptions<TQueryFnData, TError, MutationParams<GetQueryFnParams<TQueryFn>>, TContext> & {
    axiosRequestConfig?: AxiosRequestConfig;
  }
): UseMutationResult<TQueryFn, TError, TContext, TQueryFnData> => {
  const { axiosRequestConfig, ...mutateOptions } = options ?? {};
  const { mutate, mutateAsync, ...rest } = rq.useMutation<
    TQueryFnData,
    TError,
    MutationParams<GetQueryFnParams<TQueryFn>>,
    TContext
  >(([params, options]) => {
    return queryFn(params, { ...axiosRequestConfig, ...options });
  }, mutateOptions);

  return [mutateAsync, rest];
};

export const useInfiniteQuery = <
  TQueryFn extends BaseApiFn<any[], any>,
  TQueryFnParams = GetQueryFnParams<TQueryFn>,
  TError = unknown,
  TQueryFnData = GetPromiseReturnType<TQueryFn>
>(
  queryFn: TQueryFn,
  params: TQueryFnParams,
  options?: rq.UseInfiniteQueryOptions<TQueryFnData, TError, TQueryFnData, TQueryFnData, string[]> & {
    axiosRequestConfig?: AxiosRequestConfig;
  }
): [
  rq.UseInfiniteQueryResult<TQueryFnData, TError>['data'],
  Omit<rq.UseInfiniteQueryResult<TQueryFnData, TError>, 'data'>
] => {
  const { axiosRequestConfig, ...queryOptions } = options ?? {};
  const queryKey = queryFn.getQueryKey(params, axiosRequestConfig);

  const { data, ...rest } = rq.useInfiniteQuery<TQueryFnData, TError, TQueryFnData, string[]>(
    queryOptions.queryKey ? queryOptions.queryKey : queryKey,
    ({ pageParam }) => {
      const source = axios.CancelToken.source();

      const promise: Promise<any> & { cancel?: () => void } = queryFn(
        { ...params, page: pageParam },
        {
          ...axiosRequestConfig,
          cancelToken: source.token,
        }
      );

      promise.cancel = () => {
        source.cancel('Query was cancelled by React Query.');
      };

      return promise;
    },
    queryOptions
  );

  return [data, rest];
};

export const prefetchQuery = <
  TQueryFn extends BaseApiFn<any[], any>,
  TError = unknown,
  TQueryFnData = GetPromiseReturnType<TQueryFn>
>(
  queryClient: rq.QueryClient,
  queryFn: TQueryFn,
  params: GetQueryFnParams<TQueryFn>,
  options?: rq.FetchQueryOptions<TQueryFnData, TError, TQueryFnData, string[]> & {
    axiosRequestConfig?: AxiosRequestConfig;
  }
): [string[], Promise<void>] => {
  const { axiosRequestConfig, ...queryOptions } = options ?? {};
  const queryKey = queryFn.getQueryKey(params, axiosRequestConfig);

  return [
    queryOptions.queryKey ? queryOptions.queryKey : queryKey,
    queryClient.prefetchQuery(
      queryOptions.queryKey ? queryOptions.queryKey : queryKey,
      () => {
        const source = axios.CancelToken.source();

        const promise: Promise<any> & { cancel?: () => void } = queryFn(params, {
          ...axiosRequestConfig,
          cancelToken: source.token,
        });

        promise.cancel = () => {
          source.cancel('Query was cancelled by React Query.');
        };

        return promise;
      },
      queryOptions
    ),
  ];
};
