import * as React from 'react';
import { z } from 'zod';
import prettyBytes from 'pretty-bytes';

import Icon from 'components/icon';
import CroppedImage from 'my-account/components/CroppedImage';
import { FileImageRelationUpdateSchema, ImageSettings } from 'my-account/validations/file';

const BlockContentImage: React.VFC<{
  openImageLibrary: () => void;
  openImageEdit: () => void;
  openFileUpload: () => void;
  clearImage?: () => void;
  revertToOriginal?: () => void;
  image: Pick<z.infer<typeof FileImageRelationUpdateSchema>, 'name' | 'image' | 'width' | 'height' | 'size'>;
  image_settings: z.infer<typeof ImageSettings> | undefined;
  hideImageDetails?: boolean;
}> = (props) => {
  return (
    <>
      <div className="u-mb-spacer-base">
        <button
          className="c-button c-button--light-grey c-button--option u-mb-spacer"
          type="button"
          onClick={(event) => {
            event.preventDefault();
            props.openImageLibrary();
          }}
        >
          <Icon name="refresh" className="o-svg-icon o-svg-large" />
          <span>Change from Library</span>
        </button>{' '}
        <button
          className="c-button c-button--light-grey c-button--option u-mb-spacer"
          type="button"
          onClick={(event) => {
            event.preventDefault();
            props.openFileUpload();
          }}
        >
          <Icon name="upload" className="o-svg-icon o-svg-large" />
          <span>Upload New Image</span>
        </button>{' '}
        {typeof props.clearImage !== 'undefined' && (
          <>
            <button
              className="c-button c-button--light-grey c-button--option u-mb-spacer"
              type="button"
              onClick={(event) => {
                event.preventDefault();
                props.clearImage?.();
              }}
            >
              <Icon name="close" className="o-svg-icon" />
              <span>Clear Image</span>
            </button>{' '}
          </>
        )}
        {typeof props.revertToOriginal !== 'undefined' && (
          <>
            <button
              className="c-button c-button--light-grey c-button--option u-mb-spacer"
              type="button"
              onClick={(event) => {
                event.preventDefault();
                props.revertToOriginal?.();
              }}
            >
              <Icon name="back" className="o-svg-icon o-svg-large" />
              <span>Revert to original</span>
            </button>{' '}
          </>
        )}
      </div>
      <figure className="c-image-preview" style={{ minHeight: '2rem' }}>
        <CroppedImage image={props.image} settings={props.image_settings} />
        <button
          className="c-button c-button--white c-button--option"
          type="button"
          onClick={(event) => {
            event.preventDefault();
            props.openImageEdit();
          }}
        >
          <Icon name="pencil" className="o-svg-icon o-svg-large" />
          <span>Edit image</span>
        </button>
      </figure>
      {!props.hideImageDetails && (
        <div className="c-table-wrapper">
          <table>
            <tbody>
              <tr>
                <th>
                  <Icon name="link" />
                </th>
                <td>
                  <strong>{props.image.image}</strong> {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    href="#"
                    className="c-link-cta-basic c-link-cta--small"
                    onClick={(event) => {
                      event.preventDefault();
                      navigator.clipboard.writeText(props.image.image ?? '');
                    }}
                  >
                    <Icon name="copy" className="o-svg-icon o-svg-larger" />
                    <span>Copy link</span>
                  </a>
                </td>
              </tr>
              <tr>
                <th>
                  <Icon name="id" />
                </th>
                <td>
                  <strong>{props.image.name}</strong>
                </td>
              </tr>
              <tr>
                <th>
                  <Icon name="dimensions" />
                </th>
                <td>
                  <strong>
                    {Math.ceil(props.image.width ?? 0)} x {Math.ceil(props.image.height ?? 0)}
                  </strong>
                </td>
              </tr>
              <tr>
                <th>
                  <Icon name="invoice" />
                </th>
                <td>
                  <strong>{prettyBytes(props.image.size ?? 0)}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default BlockContentImage;
