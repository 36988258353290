import * as React from 'react';
import { useMount } from 'react-use';
import videojs from 'video.js';

import wordpressData from 'data';

// const projectSettings = {
//   breakpointSM: 576,
//   breakpointMD: 769,
//   breakpointLG: 992,
//   breakpointXL: 1300,
// };

interface JQuerySlickFixed extends JQuerySlick {
  _oldReinit: (this: JQuerySlickFixed) => void;
  reinit: (this: JQuerySlickFixed) => void;
}

export const ProductHeroGallery: React.VFC<{
  productMediaItems: (Symmons.ProductImage | Symmons.ProductVideo)[];
  currentProduct: Symmons.Product;
}> = ({ productMediaItems, currentProduct }) => {
  const primaryEl = React.useRef<HTMLDivElement>(null);
  const secondaryEl = React.useRef<HTMLDivElement>(null);
  const renderProps = React.useRef({ productMediaItems, currentProduct });

  useMount(() => {
    if (!primaryEl.current || !secondaryEl.current) {
      return;
    }

    const $slider = jQuery(primaryEl.current);
    const $navSlider = jQuery(secondaryEl.current);

    function newReinit(this: JQuerySlickFixed) {
      // fix issue where navSlider isn't clickable and can't navigate the main slider after you change the variation
      // https://github.com/kenwheeler/slick/issues/1043#issuecomment-256917237
      this._oldReinit();
      if (this.$slides) {
        this.$slides.each((index, element) => {
          jQuery(element).attr('data-slick-index', index);
        });
        this.$slides.find('.js-video video').each(function () {
          videojs(this, {
            controls: true,
            nativeControlsForTouch: false,
            html5: false,
            fluid: true,
            controlBar: {
              remainingTimeDisplay: true,
              progressControl: true,
              pictureInPictureToggle: false,
              currentTimeDisplay: true,
              timeDivider: true,
              durationDisplay: true,
              liveDisplay: true,
              children: [
                'playToggle',
                'remainingTimeDisplay',
                'progressControl',
                'volumePanel',
                // "qualitySelector",
                'fullscreenToggle',
              ],
            },
          });
        });
      }
    }

    $slider.slick({
      mobileFirst: false,
      infinite: false,
      autoplay: false,
      adaptiveHeight: true,
      draggable: true,
      swipe: true,
      fade: true,
      variableWidth: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      dotsClass: 'c-slider__dots',
      arrows: false,
      nextArrow:
        '<a href="" class="c-slider__arrow c-slider__arrow--next"><svg class="c-slider__arrow-thick o-icon o-svg-icon"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="' +
        wordpressData.themeAssetsUrl +
        '/sprite/icons.svg#icon-chevron"></use></svg></a>',
      prevArrow:
        '<a href="" class="c-slider__arrow c-slider__arrow--prev"><svg class="c-slider__arrow-thick o-icon o-svg-icon"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="' +
        wordpressData.themeAssetsUrl +
        '/sprite/icons.svg#icon-chevron"></use></svg></a>',
      //appendArrows: '.slick-list',
      asNavFor: $navSlider,
    });
    ($slider.slick('getSlick') as JQuerySlickFixed)._oldReinit = ($slider.slick('getSlick') as JQuerySlickFixed).reinit;
    ($slider.slick('getSlick') as JQuerySlickFixed).reinit = newReinit;

    $slider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      const videoEl = $slider.find('.slick-current .js-video video').get(0);
      if (videoEl) {
        const video = videojs.getPlayer(videoEl as any);
        if (video) {
          video.pause();
        }
      }
    });

    $navSlider.slick({
      mobileFirst: false,
      infinite: false,
      autoplay: false,
      adaptiveHeight: true,
      draggable: true,
      swipe: true,
      fade: false,
      variableWidth: true,
      slidesToShow: 6,
      slidesToScroll: 6,
      swipeToSlide: true,
      //centerMode: true,
      //centerPadding: '50px',
      focusOnSelect: true,
      dots: false,
      dotsClass: 'c-slider__dots',
      arrows: true,
      nextArrow:
        '<a href="" class="c-slider__arrow c-slider__arrow--base c-slider__arrow--next"><svg class="c-slider__arrow-thick o-icon o-svg-icon"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="' +
        wordpressData.themeAssetsUrl +
        '/sprite/icons.svg#icon-chevron"></use></svg></a>',
      prevArrow:
        '<a href="" class="c-slider__arrow c-slider__arrow--base c-slider__arrow--prev"><svg class="c-slider__arrow-thick o-icon o-svg-icon"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="' +
        wordpressData.themeAssetsUrl +
        '/sprite/icons.svg#icon-chevron"></use></svg></a>',
      asNavFor: $slider,
    });
    ($navSlider.slick('getSlick') as JQuerySlickFixed)._oldReinit = (
      $navSlider.slick('getSlick') as JQuerySlickFixed
    ).reinit;
    ($navSlider.slick('getSlick') as JQuerySlickFixed).reinit = newReinit;
  });

  React.useEffect(() => {
    if (!primaryEl.current || !secondaryEl.current) {
      return;
    }

    const $slider = jQuery(primaryEl.current);
    const $navSlider = jQuery(secondaryEl.current);

    $slider.slick('slickRemove', null, null, true);
    $navSlider.slick('slickRemove', null, null, true);

    productMediaItems.forEach((mediaItem) => {
      if (mediaItem.type === 'video') {
        $slider.slick(
          'slickAdd',
          `
          <div class="c-hero__gallery-item">
            <div class="c-video js-video">
              <video class="video-js" poster="${mediaItem.poster_url}">
                <source src="${mediaItem.video_url}" type="video/mp4" />
              </video>
            </div>
          </div>
          `
        );
      } else {
        $slider.slick(
          'slickAdd',
          `
          <div class="c-hero__gallery-item">
            <a href="${mediaItem.original_url}">
              <img src="${mediaItem.large_url}" />
            </a>
          </div>
          `
        );
      }

      $navSlider.slick(
        'slickAdd',
        `
        <div class="c-hero__gallery-item ${mediaItem.type === 'video' ? 'c-hero__gallery-item--video' : ''}">
          <span>
            <img src="${mediaItem.thumbnail_url}" />
          </span>
        </div>
      `
      );
    });

    // position is apparently important, setting this up before the image loop doesn't work
    $slider.imagesLoaded(() => $slider.slick('setPosition'));
    $navSlider.imagesLoaded(() => $navSlider.slick('setPosition'));
  }, [productMediaItems, currentProduct]);

  return (
    <div className="c-hero__gallery">
      <div className="c-hero__gallery-primary" ref={primaryEl}>
        {renderProps.current.productMediaItems.map((mediaItem, index) => (
          <div className="c-hero__gallery-item" key={`${renderProps.current.currentProduct.sku}-image-${index}`}>
            {mediaItem.type === 'video' ? (
              <div className="c-video js-video">
                <video className="video-js" poster={mediaItem.poster_url}>
                  <source src={mediaItem.video_url} type="video/mp4" />
                </video>
              </div>
            ) : (
              <a href={mediaItem.original_url}>
                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                <img src={mediaItem.large_url} />
              </a>
            )}
          </div>
        ))}
      </div>
      <div
        className="c-hero__gallery-secondary"
        style={productMediaItems.length <= 1 ? { display: 'none' } : undefined}
        ref={secondaryEl}
      >
        {renderProps.current.productMediaItems.map((mediaItem, index) => (
          <div
            className={['c-hero__gallery-item', mediaItem.type === 'video' ? 'c-hero__gallery-item--video' : ''].join(
              ' '
            )}
            key={`${renderProps.current.currentProduct.sku}-image-${index}`}
          >
            <span>
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <img src={mediaItem.thumbnail_url} />
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
