import { Link } from 'react-router-dom';

import Icon from 'components/icon';
import { MainHero } from 'my-account/components/MainHero';
import { EditIntroductionForm } from 'my-account/forms/Introduction';
import useDocumentTitle from 'hooks/useDocumentTitle';

export default function IntroductionCreatePage() {
  useDocumentTitle('Add Introduction');

  return (
    <>
      <MainHero />

      <section className="c-block c-block--hero">
        <div className="o-container-fluid">
          <div className="o-row u-flex-grow u-items-end">
            <div className="o-col-12">
              <Link
                to="/introductions"
                className="c-link-cta-basic c-link-cta--small c-block__get-back u-mt-spacer-base-large u-mb-spacer-base-small"
              >
                <Icon name="arrow" className="o-svg-icon o-svg-left" />
                <span>Back to introductions</span>
              </Link>
              <h1 className="c-title--medium u-mb-0">Add Introduction</h1>
            </div>
          </div>
        </div>
        <div className="c-block__pattern c-block__pattern--light3 c-block__pattern--top3 c-block__overlay--opacity-20">
          <Icon name="pattern" className="o-svg-icon" />
        </div>
      </section>

      <EditIntroductionForm introduction={undefined} />
    </>
  );
}
