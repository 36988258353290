import * as React from 'react';
import { Text, View } from '@react-pdf/renderer';

import ImageBlockImage, { ImageBlockImageProps } from 'spb-pdf/components/ImageBlock';

interface DividerProps {
  title?: string;
  image?: ImageBlockImageProps['image'];
  layout?: 'alternate' | null;
}

const Divider: React.VFC<DividerProps> = ({ title, image, layout }) => {
  return (
    <View style={{ width: '100%', height: '100%' }}>
      {image ? (
        <View
          style={{
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            flexGrow: 1,
            flexShrink: 1,
          }}
        >
          <ImageBlockImage
            image={image}
            style={{ maxWidth: '100%', maxHeight: '100%', objectFit: layout === 'alternate' ? 'scale-down' : 'cover' }}
          />
        </View>
      ) : (
        <View style={{ width: '100%', flexGrow: 1, flexShrink: 1 }} />
      )}

      {title && (
        <View style={{ paddingVertical: 20, paddingHorizontal: 45, flexShrink: 0 }}>
          <Text
            style={{
              fontFamily: 'Gotham Rounded',
              fontWeight: 200,
              fontSize: 24,
              color: '#000000',
              opacity: 0.8,
              letterSpacing: -0.39,
            }}
          >
            {title}
          </Text>
        </View>
      )}
    </View>
  );
};

export default Divider;
