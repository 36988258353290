import * as React from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import Icon from 'components/icon';

export const ClearFilters: React.VFC<{ onClear: () => void }> = ({ onClear }) => {
  const history = useHistory();
  const location = useLocation();

  const clearHandler = React.useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (event) => {
      event.preventDefault();

      history.replace({ ...location, search: undefined });
      onClear();
    },
    [history, location, onClear]
  );

  if (!location.search) {
    return null;
  }

  return (
    <div className="ais-ClearRefinements" onClick={clearHandler}>
      <button type="reset" className="ais-ClearRefinements-button">
        <Icon name="close" className="o-svg-icon" />
        <span>Clear all</span>
      </button>
    </div>
  );
};
