import * as React from 'react';
import { observer } from 'mobx-react';
import wordpressData from 'data';
import SpriteIcon from 'search/components/sprite-icon';
import SearchStore from 'search/stores/search-store';

interface ToggleFiltersBtnProps {
  store: SearchStore;
}

const ToggleFiltersBtn = observer(
  class ToggleFiltersBtn extends React.Component<ToggleFiltersBtnProps> {
    render() {
      const { store } = this.props;
      const { showFilters } = store;
      const classes = ['c-listing__filter-toggle', showFilters ? 'is-active' : ''];
      return (
        <>
          <span
            className={classes.join(' ')}
            data-offcanvas="filters"
            onClick={() => {
              store.toggleShowFilters();
            }}
          >
            <a
              href={wordpressData.productsUrl}
              className="c-tag c-tag--light c-tag--large c-listing__inactive"
              onClick={(event) => {
                event.preventDefault();
              }}
            >
              <SpriteIcon name="controls" classes={['o-svg-icon']} />
              <span>Show filters</span>
            </a>
            <a
              href={wordpressData.productsUrl}
              className="c-tag c-tag--dark c-tag--large c-listing__active"
              onClick={(event) => {
                event.preventDefault();
              }}
            >
              <SpriteIcon name="controls" classes={['o-svg-icon']} />
              <span>Hide filters</span>
            </a>
          </span>
        </>
      );
    }
  }
);

export default ToggleFiltersBtn;
