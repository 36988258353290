import * as React from 'react';

import { Project } from '__generated-api__';
import Icon from 'components/icon';
import { Button } from 'components/Button';
import ShareModal from './ShareModal';

const ShareButton: React.VFC<{ project: Project | undefined }> = ({ project }) => {
  const [isShareOpen, setShareOpen] = React.useState(false);
  return (
    <>
      <ShareModal
        project={project}
        open={isShareOpen}
        close={() => {
          setShareOpen(false);
        }}
      />
      <Button
        variant="primary"
        isFull
        className="c-button--option u-mb-spacer"
        type="button"
        onClick={async (event) => {
          event.preventDefault();
          setShareOpen(true);
        }}
        leftIcon={<Icon name="share" className="o-svg-icon o-svg-large" />}
      >
        Share
      </Button>
    </>
  );
};

export default ShareButton;
