import api from 'api';
import { SelectListingItem, SelectListingItemProps } from 'my-account/components/SelectListingItem';

export interface SelectOrderProps<IsMulti extends boolean = false>
  extends Omit<
    SelectListingItemProps<typeof api.order.listOrders, IsMulti>,
    'queryFn' | 'getOptionLabel' | 'getOptionValue' | 'queryParams'
  > {
  queryParams?: SelectListingItemProps<typeof api.order.listOrders, IsMulti>['queryParams'];
}

export const SelectOrder = <IsMulti extends boolean = false>({
  queryParams = {},
  ...props
}: SelectOrderProps<IsMulti>) => {
  return (
    <SelectListingItem
      {...props}
      queryFn={api.order.listOrders}
      queryParams={{ sort: 'name', direction: 'asc', ...queryParams }}
      getOptionLabel={(order) => order.number}
      getOptionValue={(order) => String(order.id)}
    />
  );
};
