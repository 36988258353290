import * as React from 'react';
import { Route, ExtractRouteParams, RouteProps, RouteComponentProps, RouteChildrenProps } from 'react-router';
import isFunction from 'lodash/isFunction';

export interface GuardedRouteProps<
  Path extends string = string,
  Params extends { [K: string]: string | undefined } = ExtractRouteParams<Path, string>
> extends RouteProps<Path, Params> {
  guard: (params: RouteChildrenProps<Params>, children: () => React.ReactNode) => React.ReactNode;
}

export function GuardedRoute<
  Path extends string = string,
  Params extends { [K: string]: string | undefined } = ExtractRouteParams<Path, string>
>({ children, render, component: Component, guard, ...rest }: GuardedRouteProps<Path, Params>) {
  if (Component) {
    return (
      <Route
        {...rest}
        render={(params) => (
          <>
            {guard(params as unknown as RouteChildrenProps<Params>, () => (
              <Component {...params} />
            ))}
          </>
        )}
      />
    );
  }

  if (render) {
    return (
      <Route
        {...rest}
        render={(params) => (
          <>
            {guard(params as unknown as RouteComponentProps<Params>, () =>
              render(params as unknown as RouteComponentProps<Params>)
            )}
          </>
        )}
      />
    );
  }

  return (
    <Route {...rest}>
      {(params) => (
        <>
          {guard(params as unknown as RouteChildrenProps<Params>, () =>
            isFunction(children) ? children(params as unknown as RouteChildrenProps<Params>) : children
          )}
        </>
      )}
    </Route>
  );
}

export default GuardedRoute;
