import * as React from 'react';
import { View } from '@react-pdf/renderer';

import ImageBlockImage, { ImageBlockImageProps } from 'spb-pdf/components/ImageBlock';

type CoverImage = ImageBlockImageProps['image'];

const Cover: React.VFC<{
  images?: [CoverImage] | [CoverImage, CoverImage] | [CoverImage, CoverImage, CoverImage];
  layout?: 'alternate' | null;
}> = ({ images, layout }) => {
  if (!images || !images.length) {
    return null;
  }

  return (
    <View style={{ width: '100%', height: '100%' }}>
      {images.length === 1 && (
        <View style={{ width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
          <ImageBlockImage
            image={images[0]}
            style={{ maxWidth: '100%', maxHeight: '100%', objectFit: layout === 'alternate' ? 'scale-down' : 'cover' }}
          />
        </View>
      )}
      {images.length === 2 &&
        (layout === 'alternate' ? (
          <View style={{ flexGrow: 1 }}>
            <View style={{ flexBasis: '60%' }}>
              <ImageBlockImage image={images[0]} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </View>
            <View style={{ flexBasis: '40%', paddingTop: 10 }}>
              <ImageBlockImage image={images[1]} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </View>
          </View>
        ) : (
          <View style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
            <View style={{ flexBasis: '60%' }}>
              <ImageBlockImage image={images[0]} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </View>
            <View style={{ flexBasis: '40%', paddingLeft: 10 }}>
              <ImageBlockImage image={images[1]} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </View>
          </View>
        ))}
      {images.length === 3 && (
        <View style={{ width: '100%', height: '100%', paddingHorizontal: 10 }}>
          <View style={{ width: '100%', height: '50%', paddingBottom: 5 }}>
            <ImageBlockImage image={images[0]} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </View>
          <View style={{ width: '100%', height: '50%', paddingTop: 5 }}>
            <View style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%' }}>
              <View style={{ width: '60%', height: '100%' }}>
                <ImageBlockImage image={images[1]} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
              </View>
              <View style={{ width: '40%', height: '100%', paddingLeft: 10 }}>
                <ImageBlockImage image={images[2]} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
              </View>
            </View>
          </View>
        </View>
      )}
    </View>
  );
};

export default Cover;
