export function getCounter(): () => number;
export function getCounter(start: number): () => number;
export function getCounter(start: number, prefix: undefined): () => number;
export function getCounter(start: number, prefix: string): () => string;
export function getCounter(start = 0, prefix?: string) {
  let count = start;

  return () => {
    count++;
    return typeof prefix === 'undefined' ? count : `${prefix}${count}`;
  };
}
