import * as React from 'react';
import { observer } from 'mobx-react';
import { RefinementList } from 'react-instantsearch-dom';
import Tippy from '@tippyjs/react';

import wordpressData from 'data';
import ProductSearchStore from 'search/stores/product-search-store';
import SpriteIcon from 'search/components/sprite-icon';

interface AvailabilityFilterProps {
  store: ProductSearchStore;
}

function compareAvailabilities(itemA: any, itemB: any): number {
  const order = ['ReadyStock™', 'Standard Product', 'Special Order', 'Coming Soon'];

  if (itemA.label === itemB.label) {
    return 0;
  }

  const indexA = order.indexOf(itemA.label) !== -1 ? order.indexOf(itemA.label) : order.length;
  const indexB = order.indexOf(itemB.label) !== -1 ? order.indexOf(itemB.label) : order.length;

  if (indexA === order.length && indexB === order.length) {
    return itemA.label.localeCompare(itemB.label);
  } else {
    return indexA < indexB ? -1 : 1;
  }
}

const AvailabilityFilter = observer(
  class AvailabilityFilter extends React.Component<AvailabilityFilterProps> {
    private containerRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
      const { store } = this.props;
      const { availabilityFilterOpened: opened } = store;
      if (!opened && this.containerRef.current) {
        this.containerRef.current.style.display = 'none';
      }
    }

    render() {
      const { store } = this.props;
      const { availabilityFilterOpened: opened } = store;
      const classes = ['c-filters__group', opened ? 'is-open' : ''];
      return (
        <>
          <div className={classes.join(' ')}>
            <div
              className="c-filters__header c-accordion-toggle"
              onClick={() => {
                store.setAvailabilityFilterOpened(!opened);
                if (this.containerRef.current) {
                  jQuery(this.containerRef.current).stop(true).slideToggle();
                }
              }}
            >
              <p className="c-filters__title">
                Availability
                {wordpressData.productSearch.availabilityTooltip && (
                  <Tippy content={wordpressData.productSearch.availabilityTooltip}>
                    <a
                      href={wordpressData.productsUrl}
                      className="c-tooltip"
                      onClick={(event) => event.preventDefault()}
                    >
                      <SpriteIcon name="help" classes={['o-svg-icon']} />
                    </a>
                  </Tippy>
                )}
              </p>
            </div>
            <div className="c-filters__container c-accordion-container" ref={this.containerRef}>
              <RefinementList
                attribute="availabilities"
                limit={100}
                transformItems={(items: any[]) => items.sort(compareAvailabilities)}
              />
            </div>
          </div>
        </>
      );
    }
  }
);

export default AvailabilityFilter;
