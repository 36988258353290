import * as React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { z } from 'zod';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import classNames from 'classnames';
import { useQueryClient } from 'react-query';
import { subject } from '@casl/ability';
import { useFormContext, useWatch } from 'react-hook-form';
import { useMount } from 'react-use';

import { ProjectStatus, ProjectVersionStatusUpdate } from '__generated-api__';
import api from 'api';
import { useAbility, useAuthenticatedUser } from 'auth';
import { Can } from 'auth/components/Can';
import { setLocationSearchParams } from 'utils/router';
import { useMutation } from 'hooks/query';
import Icon from 'components/icon';
import Form, { FormProvider, useForm } from 'components/Form/Form';
import { Button } from 'components/Button';
import { AutoSubmit } from 'components/Form/AutoSubmit';
import CheckboxField, { CheckboxWithCountField } from 'components/Form/CheckboxField';
import { FiltersAccordion } from 'components/Accordion';
import { useToast } from 'my-account/toast';
import { useCopyProject, useDownloadDocumentButton } from 'my-account/hooks/project';
import { useErrorHandler } from 'my-account/utils/error-handler';
import { MainHero } from 'my-account/components/MainHero';
import { DataTableHero } from 'my-account/components/DataTable/Hero';
import { DataTableClearFilterForm, DataTableListing } from 'my-account/components/DataTable/Listing';
import { SelectSalesforceCustomer } from 'my-account/components/SelectSalesforceCustomer';
import { SelectSalesforceOpportunity } from 'my-account/components/SelectSalesforceOpportunity';
import { Modal } from 'my-account/components/Modal';
import { SelectUser } from 'my-account/components/SelectUser';
import { getUserName } from 'my-account/utils/user';
import useDocumentTitle from 'hooks/useDocumentTitle';

const specificationPackagesFiltersSchema = z.object({
  status_published: z.boolean(),
  status_draft: z.boolean(),
  status_disabled: z.boolean(),
  customer: z.object({ id: z.number(), name: z.string() }).nullable().optional(),
  opportunity: z.object({ id: z.number(), name: z.string() }).nullable().optional(),
  user: z.object({ id: z.number(), first_name: z.string(), last_name: z.string() }).nullable().optional(),
});

const SelectSalesforceOpportunityWithCustomer: React.VFC = () => {
  const { setValue } = useFormContext<z.infer<typeof specificationPackagesFiltersSchema>>();
  const customer = useWatch<z.infer<typeof specificationPackagesFiltersSchema>, 'customer'>({ name: 'customer' });

  return (
    <SelectSalesforceOpportunity
      name="opportunity"
      label="Opportunity"
      small
      queryParams={customer ? { salesforceCustomerId: customer.id } : undefined}
      selectProps={{
        isClearable: true,
        onChange: (value) => {
          if (value && value.customer) {
            setValue('customer', value.customer);
          }
        },
      }}
    />
  );
};

const SpecificationPackagesFilters: React.VFC<{ counts?: { published: number; draft: number; disabled: number } }> = ({
  counts,
}) => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = React.useMemo(() => new URLSearchParams(location.search), [location.search]);

  const initialValueParse = specificationPackagesFiltersSchema.safeParse({
    status_published: searchParams.getAll('status')?.includes('published') ?? false,
    status_draft: searchParams.getAll('status')?.includes('draft') ?? false,
    status_disabled: searchParams.getAll('status')?.includes('disabled') ?? false,
    customer: searchParams.has('customer_id')
      ? { id: Number(searchParams.get('customer_id')!), name: 'Loading...' }
      : null,
    opportunity: searchParams.has('opportunity_id')
      ? { id: Number(searchParams.get('opportunity_id')!), name: 'Loading...' }
      : null,
    user: searchParams.has('user_id')
      ? { id: Number(searchParams.get('user_id')!), first_name: 'Loading...', last_name: '' }
      : null,
  });

  const { context, formProps } = useForm({
    schema: specificationPackagesFiltersSchema,
    initialValues: initialValueParse.success ? initialValueParse.data : {},
    onSubmit: async (values) => {
      const status: Array<'published' | 'draft' | 'disabled'> = [];
      if (values.status_published) {
        status.push('published');
      }

      if (values.status_draft) {
        status.push('draft');
      }

      if (values.status_disabled) {
        status.push('disabled');
      }

      history.replace(
        setLocationSearchParams(location, {
          status,
          customer_id: values.customer?.id ? String(values.customer.id) : undefined,
          opportunity_id: values.opportunity?.id ? String(values.opportunity.id) : undefined,
          user_id: values.user?.id ? String(values.user.id) : undefined,
          page: undefined,
          'list-page': undefined,
        })
      );
    },
    isFiltersForm: true,
    stopSubmitPropagation: true,
  });

  useMount(() => {
    let isCanceled = false;
    if (searchParams.has('customer_id')) {
      api.customers
        .getSalesforceCustomer({ id: Number(searchParams.get('customer_id')) })
        .then((customer) => {
          if (!isCanceled && !context.getFieldState('customer').isDirty) {
            context.setValue('customer', { ...customer.data });
          }
        })
        .catch((e) => {
          if (!isCanceled && !context.getFieldState('customer').isDirty) {
            context.setValue('customer', null);
          }
          console.error(e);
        });
    }

    if (searchParams.has('opportunity_id')) {
      api.opportunities
        .getSalesforceOpportunity({ id: Number(searchParams.get('opportunity_id')) })
        .then((opportunity) => {
          if (!isCanceled && !context.getFieldState('opportunity').isDirty) {
            context.setValue('opportunity', { ...opportunity.data });
          }
        })
        .catch((e) => {
          if (!isCanceled && !context.getFieldState('opportunity').isDirty) {
            context.setValue('opportunity', null);
          }
          console.error(e);
        });
    }

    if (searchParams.has('user_id')) {
      api.user
        .getUser({ userId: Number(searchParams.get('user_id')) })
        .then((user) => {
          if (!isCanceled && !context.getFieldState('user').isDirty) {
            context.setValue('user', {
              id: user.data.id,
              first_name: user.data.first_name ?? '',
              last_name: user.data.last_name ?? '',
            });
          }
        })
        .catch((e) => {
          if (!isCanceled && !context.getFieldState('user').isDirty) {
            context.setValue('user', null);
          }
          console.error(e);
        });
    }

    return () => {
      isCanceled = true;
    };
  });

  return (
    <FormProvider {...context}>
      <form {...formProps}>
        <DataTableClearFilterForm />
        <AutoSubmit />

        <SelectSalesforceCustomer
          name="customer"
          label="Account"
          placeholder="Select Account"
          selectProps={{ isClearable: true }}
          small
        />
        <SelectSalesforceOpportunityWithCustomer />

        <SelectUser
          name="user"
          label="Project Owner"
          placeholder="Select User"
          selectProps={{ isClearable: true }}
          queryParams={{ withProjects: true }}
          small
        />

        <FiltersAccordion title="Project Status">
          <div className="ais-RefinementList">
            <ul className="ais-RefinementList-list">
              <CheckboxWithCountField label="Published" name="status_published" count={counts?.published} />
              <CheckboxWithCountField label="Draft" name="status_draft" count={counts?.draft} />
              <CheckboxWithCountField label="Disabled" name="status_disabled" count={counts?.disabled} />
            </ul>
          </div>
        </FiltersAccordion>
      </form>
    </FormProvider>
  );
};

const specificationPackagesActionsSchema = z.object({
  mine: z.boolean().optional(),
});

const SpecificationPackagesActions: React.VFC = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = React.useMemo(() => new URLSearchParams(location.search), [location.search]);

  const mine = searchParams.get('mine') === '1' ? true : false;

  return (
    <Form
      schema={specificationPackagesActionsSchema}
      initialValues={{ mine }}
      onSubmit={async (values) => {
        history.replace(
          setLocationSearchParams(location, {
            mine: values.mine ? '1' : undefined,
            page: undefined,
            'list-page': undefined,
          })
        );
      }}
      className="u-mb-0"
      stopSubmitPropagation
    >
      <AutoSubmit />
      <CheckboxField
        name="mine"
        label="Show only my projects"
        checkboxStyle="toggle"
        small
        className="u-my-spacer-base-small"
      />
    </Form>
  );
};

const ProjectStatusTitle = {
  [ProjectStatus.Draft]: 'Draft',
  [ProjectStatus.Publishing]: 'Publishing',
  [ProjectStatus.Published]: 'Published',
  [ProjectStatus.PublishFailed]: 'Publishing Error',
  [ProjectStatus.Disabled]: 'Disabled',
} as const;

export default function SpecificationPackagesPage() {
  useDocumentTitle('Specification Packages');
  const user = useAuthenticatedUser();
  const ability = useAbility();
  const history = useHistory();
  const toast = useToast();
  const queryClient = useQueryClient();
  const canCreateProject = ability.can('create', 'Project');
  const [deleteProject, deleteProjectState] = useMutation(api.project.deleteProject, {
    onSettled: (data, error, variables) => {
      return Promise.all([
        queryClient
          .cancelQueries(api.project.listProjects.getQueryKey()[0])
          .then(() => queryClient.invalidateQueries(api.project.listProjects.getQueryKey()[0])),
        queryClient
          .cancelQueries(api.project.getProject.getQueryKey({ id: variables[0].id })[0])
          .then(() => queryClient.invalidateQueries(api.project.getProject.getQueryKey({ id: variables[0].id })[0])),
      ]);
    },
  });
  const [updateProject, updateProjectState] = useMutation(api.project.updateProject, {
    onSettled: async (data, error, variables) => {
      const id = data ? data.data.id : variables[0].id;

      return Promise.all([
        queryClient
          .cancelQueries(api.project.listProjects.getQueryKey()[0])
          .then(() => queryClient.invalidateQueries(api.project.listProjects.getQueryKey()[0])),
        queryClient
          .cancelQueries(api.project.getProject.getQueryKey({ id })[0])
          .then(() => queryClient.invalidateQueries(api.project.getProject.getQueryKey({ id })[0])),
      ]);
    },
  });
  const [copyProject] = useCopyProject();
  const errorHandler = useErrorHandler();
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(0);
  const [activateModalOpen, setActivateModalOpen] = React.useState(0);
  const [downloadDocument, downloadDocumentState] = useDownloadDocumentButton();

  return (
    <>
      <MainHero />

      <DataTableHero
        title="Specification Packages"
        actions={<SpecificationPackagesActions />}
        buttons={
          canCreateProject ? (
            <>
              <Link
                to="/specification-packages/add"
                className="c-button c-button--primary c-button--small u-my-spacer-base-small"
              >
                <Icon name="add" className="o-svg-icon o-svg-right" />
                <span>New project</span>
              </Link>
            </>
          ) : undefined
        }
      />

      <section className="c-block c-block--spacing-b-extra-small c-block--spacing-b@md">
        <div className="o-container-fluid">
          <DataTableListing
            label="specification packages"
            availableSortOptions={{
              created_at: 'Latest',
              updated_at: 'Recently Updated',
            }}
            defaultSort="updated_at"
            defaultDirection="desc"
            queryFn={api.project.listProjects}
            queryFnParams={(filters, searchParams) => {
              const status: ProjectStatus[] | undefined = searchParams.has('status')
                ? searchParams
                    .getAll('status')
                    .filter((val): val is ProjectStatus =>
                      [
                        ProjectStatus.Disabled,
                        ProjectStatus.Draft,
                        ProjectStatus.Published,
                        ProjectStatus.Publishing,
                        ProjectStatus.PublishFailed,
                      ].includes(val as unknown as ProjectStatus)
                    )
                    .flatMap((val) => {
                      if (val === ProjectStatus.Published) {
                        return [ProjectStatus.Published, ProjectStatus.Publishing, ProjectStatus.PublishFailed];
                      }

                      return val;
                    })
                : undefined;

              const customerId = searchParams.has('customer_id') ? Number(searchParams.get('customer_id')) : undefined;

              const opportunityId = searchParams.has('opportunity_id')
                ? Number(searchParams.get('opportunity_id'))
                : undefined;

              const userId =
                searchParams.get('mine') === '1'
                  ? user.id
                  : searchParams.has('user_id')
                  ? Number(searchParams.get('user_id'))
                  : undefined;

              return {
                ...filters,
                status: Array.isArray(status) && status.length ? status : undefined,
                customerId,
                opportunityId,
                userId,
              };
            }}
            filters={(query) => <SpecificationPackagesFilters counts={query[0]?.data?.filters?.status} />}
          >
            {(data) => (
              <>
                {data.map((project) => (
                  <Link key={project.id} to={`/specification-packages/${project.id}`} className="c-data-card">
                    <div className="c-data-card__column c-data-card__column--first">
                      {project.status && (
                        <p
                          className={classNames('c-data-card__subtitle', 'c-data-card__subtitle--status', {
                            'c-data-card__subtitle--draft': project.status === ProjectStatus.Draft,
                            'c-data-card__subtitle--warning': project.status === ProjectStatus.Publishing,
                            'c-data-card__subtitle--published': project.status === ProjectStatus.Published,
                            'c-data-card__subtitle--error':
                              project.status === ProjectStatus.Disabled ||
                              project.status === ProjectStatus.PublishFailed,
                          })}
                        >
                          {ProjectStatusTitle[project.status]}
                          {project.latest && <span> | v{project.latest.serial}</span>}
                        </p>
                      )}
                      <p className="c-data-card__title">{project.name}</p>
                      <p className="c-data-card__label">Project Name</p>
                    </div>

                    <div className="c-data-card__column">
                      <p>
                        {project.updated_at ? (
                          format(parseISO(project.updated_at), 'MMM d y')
                        ) : project.created_at ? (
                          format(parseISO(project.created_at), 'MMM d y')
                        ) : (
                          <>&mdash;</>
                        )}
                      </p>
                      <p className="c-data-card__label">Last update</p>
                    </div>
                    <div className="c-data-card__column">
                      <p>{project.user ? getUserName(project.user).name : <>&mdash;</>}</p>
                      <p className="c-data-card__label">Project Owner</p>
                    </div>

                    <div className="c-data-card__column c-data-card__column--last">
                      <button className="c-link-cta-basic c-link-cta--small" type="button">
                        <span>Project Details</span>
                        <Icon name="arrow" className="o-svg-icon o-svg-right" />
                      </button>
                    </div>

                    <div className="c-data-card-toolbar">
                      <Can I="read" this={subject('Project', project)}>
                        {project.status === ProjectStatus.Published && (
                          <>
                            <button type="button" title="Share Project">
                              <Icon name="share" className="c-data-card-toolbar__icon" />
                            </button>

                            <button
                              type="button"
                              title="Download Document"
                              disabled={downloadDocumentState.isDownloading}
                              onClick={(event) => {
                                event.preventDefault();
                                downloadDocument({
                                  id: project.id,
                                  name: project.name,
                                  versionId: project.latest_version_id,
                                });
                              }}
                            >
                              <Icon name="download" className="c-data-card-toolbar__icon" />
                            </button>
                          </>
                        )}
                      </Can>
                      <Can I="create" this={subject('Project', project)}>
                        <button
                          type="button"
                          title="Copy Project"
                          onClick={async (event) => {
                            event.preventDefault();
                            errorHandler.clearErrors();
                            const loadingToastId = toast.notify({
                              type: 'info',
                              title: 'Info',
                              message: `Copying "${project.name}" project...`,
                              autoClose: false,
                            });

                            try {
                              api.project
                                .getProject({
                                  id: Number(project.id),
                                })
                                .then(async (projectRes) => {
                                  const res = await copyProject(projectRes.data, projectRes.data.latest);
                                  toast.close(loadingToastId);
                                  history.push(`/specification-packages/${res.data.id}`);
                                  toast.notify({
                                    title: 'Success',
                                    type: 'success',
                                    message: <p>The "{project.name}" specification package was copied successfully.</p>,
                                  });
                                })
                                .catch((error) => {
                                  toast.close(loadingToastId);
                                  errorHandler.handleError(error);
                                });
                            } catch (error) {
                              toast.close(loadingToastId);
                              errorHandler.handleError(error);
                            }
                          }}
                        >
                          <Icon name="copy" className="c-data-card-toolbar__icon" />
                        </button>
                      </Can>
                      <Can I="delete" this={subject('Project', project)}>
                        {project.status !== ProjectStatus.Disabled ? (
                          <>
                            <button
                              type="button"
                              title="Disable Project"
                              onClick={(event) => {
                                event.preventDefault();
                                setDeleteModalOpen(project.id);
                              }}
                            >
                              <Icon name="trash" className="c-data-card-toolbar__icon" />
                            </button>

                            <Modal
                              isOpen={deleteModalOpen === project.id}
                              onRequestClose={() => {
                                setDeleteModalOpen(0);
                              }}
                            >
                              <div className="c-modal">
                                {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */}
                                <a
                                  href="#"
                                  className="c-modal-close"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    setDeleteModalOpen(0);
                                  }}
                                />

                                <div className="c-modal__main">
                                  <div className="c-block c-block--spacing-t-small c-block--spacing-b">
                                    <div className="o-container-fluid">
                                      <div className="o-row">
                                        <div className="o-col-12">
                                          <h4>Disable specification package</h4>
                                          <p>{`Are you sure that you really want to disable "${project.name}" specification package?`}</p>
                                          <Button
                                            className="c-button--md"
                                            disabled={deleteProjectState.isLoading}
                                            isLoading={deleteProjectState.isLoading}
                                            onClick={async (event) => {
                                              event.preventDefault();
                                              await deleteProject([{ id: project.id }]);
                                              toast.notify({
                                                type: 'success',
                                                title: 'Success',
                                                message: `The "${project.name}" specification package was disabled successfully.`,
                                              });
                                              setDeleteModalOpen(0);
                                            }}
                                          >
                                            <span>Disable</span>
                                          </Button>{' '}
                                          <Button
                                            className="c-button--md"
                                            variant="white"
                                            disabled={deleteProjectState.isLoading}
                                            isLoading={deleteProjectState.isLoading}
                                            onClick={(event) => {
                                              event.preventDefault();
                                              setDeleteModalOpen(0);
                                            }}
                                          >
                                            <span>Cancel</span>
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Modal>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              title="Activate Project"
                              onClick={(event) => {
                                event.preventDefault();
                                setActivateModalOpen(project.id);
                              }}
                            >
                              <Icon name="export" className="c-data-card-toolbar__icon" />
                            </button>

                            <Modal
                              isOpen={activateModalOpen === project.id}
                              onRequestClose={() => {
                                setActivateModalOpen(0);
                              }}
                            >
                              <div className="c-modal">
                                {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */}
                                <a
                                  href="#"
                                  className="c-modal-close"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    setActivateModalOpen(0);
                                  }}
                                />

                                <div className="c-modal__main">
                                  <div className="c-block c-block--spacing-t-small c-block--spacing-b">
                                    <div className="o-container-fluid">
                                      <div className="o-row">
                                        <div className="o-col-12">
                                          <h4>Activate specification package</h4>
                                          <p>{`Are you sure that you really want to activate "${project.name}" specification package.?`}</p>
                                          <Button
                                            className="c-button--md"
                                            disabled={updateProjectState.isLoading}
                                            isLoading={updateProjectState.isLoading}
                                            onClick={async (event) => {
                                              event.preventDefault();
                                              await updateProject([
                                                {
                                                  id: project.id,
                                                  projectUpdate: {
                                                    name: project.name,
                                                    latest: {
                                                      ...project.latest,
                                                      description: project.latest.description
                                                        ? project.latest.description
                                                        : undefined,
                                                      number: project.latest.number ? project.latest.number : undefined,
                                                      customer_id: project.latest.customer?.id ?? null,
                                                      opportunity_id: project.latest.opportunity?.id ?? null,
                                                      status: ProjectVersionStatusUpdate.Draft,
                                                    },
                                                  },
                                                },
                                              ]);
                                              toast.notify({
                                                type: 'success',
                                                title: 'Success',
                                                message: `The "${project.name}" specification package was activated successfully.`,
                                              });
                                              setActivateModalOpen(0);
                                            }}
                                          >
                                            <span>Activate</span>
                                          </Button>{' '}
                                          <Button
                                            className="c-button--md"
                                            variant="white"
                                            disabled={updateProjectState.isLoading}
                                            isLoading={updateProjectState.isLoading}
                                            onClick={(event) => {
                                              event.preventDefault();
                                              setActivateModalOpen(0);
                                            }}
                                          >
                                            <span>Cancel</span>
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Modal>
                          </>
                        )}
                      </Can>
                    </div>
                  </Link>
                ))}
              </>
            )}
          </DataTableListing>
        </div>
      </section>
    </>
  );
}
