import { subject } from '@casl/ability';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { z } from 'zod';

import { RegisteredUser, ReportType } from '__generated-api__';
import api from 'api';
import { AuthStatus, useAbility, useAuth, useAuthenticatedUser } from 'auth';
import { Can } from 'auth/components/Can';
import { AutoSubmit } from 'components/Form/AutoSubmit';
import Form from 'components/Form/Form';
import SelectField from 'components/Form/SelectField';
import Icon from 'components/icon';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { DataTableHero } from 'my-account/components/DataTable/Hero';
import { DataTableClearFilterForm, DataTableListing } from 'my-account/components/DataTable/Listing';
import { MainHero } from 'my-account/components/MainHero';
import { NewProjectQuoteModal } from 'my-account/components/NewProjectQuoteModal';
import { SelectCompany } from 'my-account/components/SelectCompany';
import { SelectLocation } from 'my-account/components/SelectLocation';
import { getObjectKeys } from 'my-account/utils/object';
import { caProvinces, usStates } from 'my-account/utils/states';
import { setLocationSearchParams } from 'utils/router';

const statesAndProvincesZodEnum = z.nativeEnum(usStates).or(z.nativeEnum(caProvinces)).or(z.literal(''));
const positiveNumberZod = z.number().positive();
const quotesFiltersSchema = z.object({
  company: z
    .object({
      id: z.number().positive(),
      name: z.string().nullable(),
    })
    .nullable()
    .optional(),
  location: z
    .object({
      id: z.number().positive(),
      name: z.string().nullable(),
    })
    .nullable()
    .optional(),
  state: statesAndProvincesZodEnum,
  direct_buyer: z.boolean().optional(),
});

const LocationFilter: React.VFC = () => {
  const { watch } = useFormContext<z.infer<typeof quotesFiltersSchema>>();
  const company = watch('company');

  return (
    <SelectLocation
      elStyle="fill"
      name="location"
      label="Location"
      queryParams={company ? { companyId: company.id } : undefined}
      selectProps={{ autoComplete: 'off', isClearable: true }}
      small
    />
  );
};

const QuotesFilters: React.VFC = () => {
  const user = useAuthenticatedUser();
  const history = useHistory();
  const location = useLocation();
  const searchParams = React.useMemo(() => new URLSearchParams(location.search), [location.search]);

  let initialCompanyValue: number | undefined;
  if (searchParams.has('company')) {
    const companyValidation = positiveNumberZod.safeParse(Number(searchParams.get('company')!));

    if (companyValidation.success) {
      initialCompanyValue = companyValidation.data;
    }
  }

  let initialLocationValue: number | undefined;
  if (searchParams.has('location')) {
    const locationValidation = positiveNumberZod.safeParse(Number(searchParams.get('location')!));

    if (locationValidation.success) {
      initialLocationValue = locationValidation.data;
    }
  }

  let initialStateValue: z.infer<typeof statesAndProvincesZodEnum> = '';
  if (searchParams.has('state')) {
    const stateValidation = statesAndProvincesZodEnum.safeParse(searchParams.get('state')!);

    if (stateValidation.success) {
      initialStateValue = stateValidation.data;
    }
  }

  return (
    <>
      <Form
        schema={quotesFiltersSchema}
        initialValues={{
          company: initialCompanyValue ? { id: initialCompanyValue, name: 'Loading...' } : undefined,
          location: initialLocationValue ? { id: initialLocationValue, name: 'Loading...' } : undefined,
          state: initialStateValue,
          direct_buyer: true,
        }}
        onSubmit={async (values) => {
          history.replace(
            setLocationSearchParams(location, {
              company: values.company ? String(values.company.id) : undefined,
              location: values.location ? String(values.location.id) : undefined,
              state: values.state ? values.state : undefined,
              direct_buyer: String(true),
              page: undefined,
              'list-page': undefined,
            })
          );
        }}
        isFiltersForm
      >
        <DataTableClearFilterForm />
        <AutoSubmit />
        <Can I="read" this={subject('Quote', { company_id: 0 })}>
          <SelectCompany
            elStyle="fill"
            name="company"
            label="Company"
            selectProps={{ autoComplete: 'off', isClearable: true }}
            small
          />
        </Can>
        <Can I="read" this={subject('Quote', { company_id: user.company_id, location_id: 0 })}>
          <LocationFilter />
        </Can>
        <SelectField
          name="state"
          label="State/Province"
          elStyle="fill"
          allowEmpty
          emptyOptionLabel="Any State/Province"
          small
        >
          <optgroup label="United States">
            {getObjectKeys(usStates).map((state) => (
              <option key={usStates[state]} value={usStates[state]}>
                {state}
              </option>
            ))}
          </optgroup>
          <optgroup label="Canada">
            {getObjectKeys(caProvinces).map((state) => (
              <option key={caProvinces[state]} value={caProvinces[state]}>
                {state}
              </option>
            ))}
          </optgroup>
        </SelectField>
      </Form>
    </>
  );
};

export default function PendingOrdersPage() {
  const auth = useAuth();
  let user: RegisteredUser | undefined;
  if (auth.status === AuthStatus.LoggedIn) {
    user = auth.currentUser;
  }
  useDocumentTitle('Pending Orders');
  const ability = useAbility();
  const canCreateQuote = ability.can('create', 'Quote');
  const [isCreateModalOpen, setIsCreateModalOpen] = React.useState(false);

  return (
    <>
      <MainHero />

      <DataTableHero
        title={'Pending Orders'}
        buttons={
          canCreateQuote ? (
            <>
              <a
                href="#new-project-quote"
                className="c-button c-button--primary c-button--small u-my-spacer-base-small"
                onClick={(event) => {
                  event.preventDefault();
                  setIsCreateModalOpen(true);
                }}
              >
                <Icon name="add" className="o-svg-icon o-svg-right" />
                <span>New order</span>
              </a>
            </>
          ) : undefined
        }
      />

      <section className="c-block c-block--spacing-b-extra-small c-block--spacing-b@md">
        <div className="o-container-fluid">
          <DataTableListing
            label="quotes"
            availableSortOptions={{ created_at: 'Latest', name: 'Alphabetical' }}
            defaultSort="created_at"
            queryFn={api.quote.listQuotes}
            queryFnParams={(filters, searchParams) => {
              let companyId: number | undefined = Number(searchParams.get('company'));
              if (Number.isNaN(companyId) || companyId < 1) {
                companyId = undefined;
              }

              let locationId: number | undefined = Number(searchParams.get('location'));
              if (Number.isNaN(locationId) || locationId < 1) {
                locationId = undefined;
              }

              let state: string | undefined = searchParams.get('state') ?? '';
              if (!state.trim()) {
                state = undefined;
              }
              return { ...filters, directBuyer: true, companyId, locationId, state };
            }}
            filters={() => <QuotesFilters />}
            reportType={ReportType.Quote}
          >
            {(data) => (
              <>
                {data.map((quote) => (
                  <Link key={quote.id} to={`/quotes/${quote.id}`} className="c-data-card">
                    <div className="c-data-card__column c-data-card__column--first">
                      <p className="c-data-card__subtitle">{quote.id}</p>
                      <p className="c-data-card__title">{quote.project_name}</p>
                      <p className="c-data-card__label">Project Name</p>
                    </div>

                    <div className="c-data-card__column">
                      <p>{quote.created_at ? format(parseISO(quote.created_at), 'MMM d y') : <>&mdash;</>}</p>
                      <p className="c-data-card__label">Submitted Date</p>
                    </div>

                    <div className="c-data-card__column">
                      <p>{quote.company?.name ?? <>&mdash;</>}</p>
                      <p className="c-data-card__label">Company</p>
                    </div>

                    <div className="c-data-card__column c-data-card__column--last">
                      <button className="c-link-cta-basic c-link-cta--small" type="button">
                        <span>{user?.direct_buyer ? 'Order Details' : 'Quote Details'}</span>
                        <Icon name="arrow" className="o-svg-icon o-svg-right" />
                      </button>
                    </div>
                  </Link>
                ))}
              </>
            )}
          </DataTableListing>
        </div>
      </section>

      <NewProjectQuoteModal
        isOpen={isCreateModalOpen}
        onRequestClose={() => {
          setIsCreateModalOpen(false);
        }}
      />
    </>
  );
}
