import * as React from 'react';
import classNames from 'classnames';
import { useController, useFormContext } from 'react-hook-form';
import { useMergeRefs } from 'hooks/useMergeRefs';

import { useCustomFormState } from 'components/Form/Form';

interface InputFieldSharedProps {
  name: string;
  icon?: React.ReactNode;
  label?: string;
  placeholder?: string;
  helpText?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  elStyle?: 'fill';
  small?: boolean;
  defaultValue?: string;
}

interface InputHtmlInputFieldProps extends InputFieldSharedProps {
  type?: 'text' | 'email' | 'password' | 'file' | 'number';
  inputProps?: Omit<JSX.IntrinsicElements['input'], 'name' | 'type' | 'disabled' | 'className' | 'placeholder'>;
}

interface InputHtmlTextareaFieldProps extends InputFieldSharedProps {
  type?: 'textarea';
  inputProps?: Omit<
    JSX.IntrinsicElements['textarea'],
    'name' | 'type' | 'disabled' | 'className' | 'placeholder' | 'children'
  >;
}

type InputFieldProps = InputHtmlInputFieldProps | InputHtmlTextareaFieldProps;

export const InputField = React.forwardRef<HTMLInputElement | HTMLTextAreaElement, InputFieldProps>(
  (
    {
      name,
      type = 'text',
      label,
      placeholder,
      icon,
      helpText,
      disabled,
      className,
      inputProps,
      elStyle,
      small,
      defaultValue,
    },
    ref
  ) => {
    const { hasFloatingLabels, disableFieldsOnSubmitting, isFiltersForm } = useCustomFormState();
    const {
      formState: { isSubmitting },
    } = useFormContext();
    const wrapRef = React.useRef<HTMLDivElement>(null);
    const inputRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>(null);
    const fieldController = useController({ name, defaultValue });
    const { value, ...fieldProps } = fieldController.field;
    const inputElProps = {
      ...inputProps,
      ...fieldProps,
      value:
        type === 'file' || type === 'password'
          ? undefined
          : type === 'number'
          ? typeof value === 'number' && !Number.isNaN(value)
            ? value
            : ''
          : value || '',
      placeholder: typeof label !== 'undefined' ? label : placeholder,
      disabled: disabled || (disableFieldsOnSubmitting && isSubmitting),
      ref: useMergeRefs(fieldProps.ref, inputRef, ref),
      onChange: React.useCallback<React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>>(
        (event) => {
          if (event.target instanceof HTMLInputElement && event.target.type === 'file') {
            fieldProps.onChange(event.target.files);
          } else if (event.target instanceof HTMLInputElement && event.target.type === 'number') {
            fieldProps.onChange(event.target.valueAsNumber);
          } else {
            fieldProps.onChange(event.target.value);
          }

          (
            inputProps?.onChange as unknown as
              | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
              | undefined
          )?.(event);
        },
        [fieldProps, inputProps?.onChange]
      ),
      onInput: React.useCallback<React.FormEventHandler<HTMLInputElement | HTMLTextAreaElement>>(
        (event) => {
          if (wrapRef.current) {
            if (event.currentTarget.value.length) {
              wrapRef.current.classList.add('fl-is-active');
            } else {
              wrapRef.current.classList.remove('fl-is-active');
            }
          }

          (
            inputProps?.onInput as unknown as React.FormEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
          )?.(event);
        },
        [inputProps?.onInput, wrapRef]
      ),
      onBlur: React.useCallback<React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>>(
        (event) => {
          fieldController.field.onBlur();
          if (wrapRef.current) {
            wrapRef.current.classList.remove('fl-has-focus');
          }
          (
            inputProps?.onBlur as unknown as React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
          )?.(event);
        },
        [wrapRef, fieldController, inputProps?.onBlur]
      ),
      onFocus: React.useCallback<React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>>(
        (event) => {
          if (wrapRef.current) {
            wrapRef.current.classList.add('fl-has-focus');
          }
          (
            inputProps?.onFocus as unknown as
              | React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
              | undefined
          )?.(event);
        },
        [wrapRef, inputProps?.onFocus]
      ),
    };
    const error = fieldController.fieldState.error;

    React.useEffect(() => {
      if (!wrapRef.current || !inputRef.current) return;

      if (inputRef.current.value.length) {
        wrapRef.current.classList.add('fl-is-active');
      } else {
        wrapRef.current.classList.remove('fl-is-active');
      }
    }, [wrapRef, inputRef, hasFloatingLabels]);

    return (
      <div
        className={classNames(
          'c-form-element',
          {
            [`c-form-element--style-${elStyle}`]: elStyle,
            'c-form-element--small': small,
            'c-form-element--file': type === 'file',
            'c-form-element--error': typeof error !== 'undefined',
          },
          className
        )}
      >
        {typeof label !== 'undefined' ? (
          hasFloatingLabels ? (
            <div className="c-form-element__field">
              {typeof icon !== 'undefined' && <div className="c-form-element__icon">{icon}</div>}
              <div
                className={classNames('fl-wrap', type === 'textarea' ? 'fl-wrap-textarea' : 'fl-wrap-input')}
                ref={wrapRef}
              >
                <label htmlFor={name} className={classNames('c-form-label', { 'c-filters__title': isFiltersForm })}>
                  {label}
                </label>
                {type === 'textarea' ? (
                  <textarea {...(inputElProps as JSX.IntrinsicElements['textarea'])} className="fl-textarea" />
                ) : (
                  <input {...(inputElProps as JSX.IntrinsicElements['input'])} type={type} className="fl-input" />
                )}
              </div>
            </div>
          ) : (
            <>
              <label htmlFor={name} className={classNames('c-form-label', { 'c-filters__title': isFiltersForm })}>
                {label}
              </label>
              <div className="c-form-element__field">
                {typeof icon !== 'undefined' && <div className="c-form-element__icon">{icon}</div>}
                {type === 'textarea' ? (
                  <textarea {...(inputElProps as JSX.IntrinsicElements['textarea'])} />
                ) : (
                  <input {...(inputElProps as JSX.IntrinsicElements['input'])} type={type} />
                )}
              </div>
            </>
          )
        ) : (
          <div className="c-form-element__field">
            {typeof icon !== 'undefined' && <div className="c-form-element__icon">{icon}</div>}
            {type === 'textarea' ? (
              <textarea {...(inputElProps as JSX.IntrinsicElements['textarea'])} />
            ) : (
              <input {...(inputElProps as JSX.IntrinsicElements['input'])} type={type} />
            )}
          </div>
        )}
        {typeof error !== 'undefined' && (
          <ul className="c-form-element--error__list">
            <li>{error.message}</li>
          </ul>
        )}
        {typeof helpText !== 'undefined' && <p className="c-note">{helpText}</p>}
      </div>
    );
  }
);

export default InputField;
