import { Link, Redirect, useParams } from 'react-router-dom';
import { subject } from '@casl/ability';

import api from 'api';
import { useAbility, useAuthenticatedUser } from 'auth';
import { useQuery } from 'hooks/query';
import Icon from 'components/icon';
import { MainHero } from 'my-account/components/MainHero';
import { EditUserForm } from 'my-account/forms/User';
import { Block403Error } from 'my-account/pages/403page';
import { ErrorMessagesContent } from 'my-account/utils/error-handler';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { getUserName } from 'my-account/utils/user';

export default function UserEditPage() {
  const { id } = useParams<{ id?: string }>();
  const authUser = useAuthenticatedUser();
  const ability = useAbility();
  const [userRes, getUserState] = useQuery(api.user.getUser, { userId: Number(id) });
  const [representations, representationsState] = useQuery(api.user.represents, { userId: Number(id) });

  useDocumentTitle(typeof userRes !== 'undefined' ? `Edit ${getUserName(userRes.data).name} User` : 'Edit User');

  if (Number(id) === authUser.id) {
    return <Redirect to={{ pathname: '/profile' }} />;
  }

  return (
    <>
      <MainHero />

      <section className="c-block c-block--hero">
        <div className="o-container-fluid">
          <div className="o-row u-flex-grow u-items-end">
            <div className="o-col-12">
              <Link
                to="/users"
                className="c-link-cta-basic c-link-cta--small c-block__get-back u-mt-spacer-base-large u-mb-spacer-base-small"
              >
                <Icon name="arrow" className="o-svg-icon o-svg-left" />
                <span>Back to users</span>
              </Link>
              <h1 className="c-title--medium u-mb-0">Edit User</h1>
            </div>
          </div>
        </div>
        <div className="c-block__pattern c-block__pattern--light3 c-block__pattern--top3 c-block__overlay--opacity-20">
          <Icon name="pattern" className="o-svg-icon" />
        </div>
      </section>

      {(getUserState.isLoading || representationsState.isLoading) && (
        <div className="c-listing__none">
          <div className="c-listing__none-spinner"></div>
          <p className="c-listing__none-title">Loading...</p>
        </div>
      )}
      {(getUserState.isError || representationsState.isError) && (
        <div className="o-container-fluid u-pb-spacer-section">
          <div className="o-row u-flex-grow u-items-center">
            <div className="o-col-12">
              <div className="c-listing__none">
                <div className="c-listing__none-figure">
                  <Icon name="error" className="o-svg-icon" />
                </div>
                <p className="c-listing__none-title">Error</p>
                <ErrorMessagesContent error={getUserState.error || representationsState.error} />
              </div>
            </div>
          </div>
        </div>
      )}
      {typeof userRes !== 'undefined' &&
        getUserState.isSuccess &&
        typeof representations !== 'undefined' &&
        representationsState.isSuccess && (
          <>
            {ability.can('read', subject('User', userRes.data)) ? (
              <EditUserForm user={userRes.data} representations={representations.data} />
            ) : (
              <Block403Error />
            )}
          </>
        )}
    </>
  );
}
