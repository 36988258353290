import * as React from 'react';
import { observer } from 'mobx-react';
import Tippy from '@tippyjs/react';

import wordpressData from 'data';
import ProductSearchStore from 'search/stores/product-search-store';
import SpriteIcon from 'search/components/sprite-icon';
import ObsoleteRefinment from 'search/components/product-search/obsolete-refinment';

interface ObsoleteFilterProps {
  store: ProductSearchStore;
}

const ObsoleteFilter = observer(
  class ObsoleteFilter extends React.Component<ObsoleteFilterProps> {
    private containerRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
      const { store } = this.props;
      const { obsoleteFilterOpened: opened } = store;
      if (!opened && this.containerRef.current) {
        this.containerRef.current.style.display = 'none';
      }
    }

    render() {
      const { store } = this.props;
      const { obsoleteFilterOpened: opened } = store;
      const classes = ['c-filters__group', opened ? 'is-open' : ''];
      return (
        <>
          <div className={classes.join(' ')}>
            <div
              className="c-filters__header c-accordion-toggle"
              onClick={() => {
                store.setObsoleteFilterOpened(!opened);
                if (this.containerRef.current) {
                  jQuery(this.containerRef.current).stop(true).slideToggle();
                }
              }}
            >
              <p className="c-filters__title">
                Retired Products
                <Tippy content="Many products with retired SKUs are still available via a combination of active SKUs. Contact Symmons Customer Support for more information.">
                  <a href={wordpressData.productsUrl} className="c-tooltip" onClick={(event) => event.preventDefault()}>
                    <SpriteIcon name="help" classes={['o-svg-icon']} />
                  </a>
                </Tippy>
              </p>
            </div>
            <div className="c-filters__container c-accordion-container" ref={this.containerRef}>
              <ObsoleteRefinment attribute="is_obsolete" />
            </div>
          </div>
        </>
      );
    }
  }
);

export default ObsoleteFilter;
