import { z } from 'zod';

export const SalsifyDigitalAsset = z.object({
  salsify_id: z.string(),
  salsify_url: z.string(),
  salsify_source_url: z.string().optional(),
  salsify_name: z.string(),
  salsify_created_at: z.string(),
  salsify_updated_at: z.string(),
  salsify_status: z.string().optional(),
  salsify_asset_height: z.number().optional(),
  salsify_asset_width: z.number().optional(),
  salsify_asset_resource_type: z.string().optional(),
  salsify_filename: z.string().optional(),
  salsify_bytes: z.number(),
  salsify_format: z.string().optional(),
  salsify_etag: z.string().optional(),
  salsify_system_id: z.string().optional(),
});
