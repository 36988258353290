import * as React from 'react';
import { z } from 'zod';
import { ControllerRenderProps, useController, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import classNames from 'classnames';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useUnmount } from 'react-use';
import { useHistory, useLocation } from 'react-router-dom';
import axios, { CancelTokenSource } from 'axios';
import { useDropzone } from 'react-dropzone';
import { ConditionalKeys } from 'type-fest';
import omit from 'lodash/omit';
import pick from 'lodash/pick';

import { CurrencyCode, FileType, PaginatedProducts, Product, ProductBlockType, TaxonomyTerm } from '__generated-api__';
import api from 'api';
import { useMutation } from 'hooks/query';
import Icon from 'components/icon';
import { Accordion, AccordionWithHideToggle, FiltersAccordion } from 'components/Accordion';
import { Button } from 'components/Button';
import Form, { FormProvider, useForm } from 'components/Form/Form';
import SelectField from 'components/Form/SelectField';
import InputField from 'components/Form/InputField';
import { AutoSubmit } from 'components/Form/AutoSubmit';
import SubmitButton from 'components/Form/SubmitButton';
import CheckboxField, { CheckboxWithCountField } from 'components/Form/CheckboxField';
import {
  featuresStringsToObjects,
  getLocalBlockId,
  getLocalProductDocumentsId,
  getLocalProductFeatureId,
  getLocalProductFinishesId,
  ProductBlock,
  ProductBlockDefaultValues,
  ProductDocumentType,
} from 'my-account/validations/project';
import { getCounter } from 'my-account/utils/counter';
import { useImageUpload } from 'my-account/utils/image-upload';
import { Modal } from 'my-account/components/Modal';
import {
  DataTableClearFilterForm,
  DataTableListing,
  DataTableListingProps,
} from 'my-account/components/DataTable/Listing';
import { DisplayFieldErrors, DisplayReactQueryError } from 'my-account/components/DisplayFieldError';
import ProductImage from 'my-account/components/ProductImage';
import { UploadImageProgress } from 'my-account/components/SPB/ImageBlockField/modal-contents/upload-progress';
import { ChooseImagePlaceholder } from 'my-account/components/SPB/ImageBlockField/modal-contents/choose';
import MediaLibraryModalContent from 'my-account/components/SPB/ImageBlockField/modal-contents/library';
import BlockContentImage from 'my-account/components/SPB/BlockContentImage';
import EditImageModalContent from 'my-account/components/SPB/ImageBlockField/modal-contents/edit';
import { SalsifyDigitalAsset } from 'my-account/validations/salsify';
import { finishIcons } from 'my-account/utils/finishes';
import { getTransformedSalsifyImage } from 'my-account/utils/salsify';
import { DisplayPrice } from 'my-account/utils/currency';
import useDocumentSettings from 'my-account/hooks/useDocumentSettings';
import { truncate } from 'my-account/utils/text';

const ProductCategory = {
  ACC: 'Accessories',
  ACS: 'ActivSense',
  BSF: 'Bar Sink Faucet',
  BTF: 'Bathroom Faucet',
  BHS: 'Bathroom Hardware Set',
  CNT: 'Controllers',
  DVT: 'Diverter Trim',
  GRB: 'Grab Bar',
  HDH: 'Hair Dryer Holder',
  HDS: 'Hand Shower',
  HTT: 'Hand Shower and Tub Trim',
  HSP: 'Hand Shower Parts',
  HST: 'Hand Shower Trim',
  INF: 'Institutional Faucet',
  KTF: 'Kitchen Faucets',
  LMT: 'Laundry Mate',
  LAV: 'Lav Faucets',
  PAR: 'Repair Parts',
  RBH: 'Robe Hook',
  RTF: 'Roman Tub Faucet',
  SHS: 'Shower/Hand Shower Trim',
  SHD: 'Showerhead',
  SHP: 'Showerhead Parts',
  SOT: 'Shower Only Trim',
  SHR: 'Shower Rod',
  SHW: 'Showers',
  SSY: 'Shower System',
  SVT: 'Shower Valve Trim',
  SPD: 'Soap Dish',
  TPH: 'Toilet Paper Holder',
  TBH: 'Toothbrush Holder',
  TWB: 'Towel Bar',
  TWR: 'Towel Ring',
  TWS: 'Towel Shelf',
  TSH: 'Tub/Shower/Hand Shower Trim',
  TSS: 'Tub/Shower System',
  TST: 'Tub/Shower Trim',
  TBS: 'Tub Spout',
  VAL: 'Valve',
  WMV: 'Washing Machine Valve',
} as const;

const ProductFinish: Record<string, string> = {
  BLK: 'Black',
  BLU: 'Blue',
  BRS: 'Brass',
  BBZ: 'Brushed Bronze',
  CRM: 'Chrome',
  COP: 'Copper',
  GRY: 'Gray',
  GRN: 'Green',
  MTB: 'Matte Black',
  MUL: 'Multi',
  OWT: 'Off-White',
  OTH: 'Other',
  PCP: 'Polished Chrome',
  PLG: 'Polished Graphite',
  PNL: 'Polished Nickel',
  SNA: 'Satin Nickel',
  SBZ: 'Seasoned Bronze',
  SLV: 'Silver',
  STS: 'Stainless Steel',
  WHT: 'White',
};

type DocumentSettings = ReturnType<typeof useDocumentSettings>;

const prepareProductForInsertion = (product: Product): Product => {
  return {
    ...product,
    main_image: product.main_image
      ? { ...product.main_image, salsify_url: product.main_image.salsify_url.replace(/^http:/, 'https:') }
      : null,
  };
};

const getLocalProductBlockFieldId = getCounter(0, 'product_block_field_');

const productsLibraryFiltersSchema = z
  .object({
    tier_RDS: z.boolean().optional(),
    tier_LTS: z.boolean().optional(),
    tier_SPO: z.boolean().optional(),
    tier_CMS: z.boolean().optional(),
  })
  .extend(
    Object.keys(ProductCategory).reduce((obj, category_key) => {
      obj[`category_${category_key as keyof typeof ProductCategory}`] = z.boolean().optional();
      return obj;
    }, {} as Record<`category_${keyof typeof ProductCategory}`, z.ZodOptional<z.ZodBoolean>>)
  );

const ProductsLibraryFilters: React.VFC<{
  filters: z.infer<typeof productsLibraryFiltersSchema>;
  setFilters: React.Dispatch<React.SetStateAction<z.infer<typeof productsLibraryFiltersSchema>>>;
  counts: PaginatedProducts['filters'] | undefined;
}> = ({ filters, setFilters, counts }) => {
  const [categoriesExpanded, setCategoriesExpanded] = React.useState(false);
  const product_categories = Object.keys(ProductCategory).filter((category_key) => {
    return counts?.categories[category_key] && counts?.categories[category_key] > 0;
  });
  return (
    <>
      <Form
        schema={productsLibraryFiltersSchema}
        initialValues={filters}
        onSubmit={async (values) => {
          setFilters(values);
        }}
        stopSubmitPropagation
        isFiltersForm
      >
        <DataTableClearFilterForm />
        <AutoSubmit />
        <FiltersAccordion title="Availability">
          <div className="ais-RefinementList">
            <ul className="ais-RefinementList-list">
              <CheckboxWithCountField label="ReadyStock™" name="tier_RDS" count={counts?.tiers['RDS']} />
              <CheckboxWithCountField label="Standard Product" name="tier_LTS" count={counts?.tiers['LTS']} />
              <CheckboxWithCountField label="Special Order" name="tier_SPO" count={counts?.tiers['SPO']} />
              <CheckboxWithCountField label="Coming Soon" name="tier_CMS" count={counts?.tiers['CMS']} />
            </ul>
          </div>
        </FiltersAccordion>
        <FiltersAccordion title="Category">
          <div className="ais-RefinementList">
            <ul className="ais-RefinementList-list">
              {product_categories
                .slice(0, 10)

                .map((category_key) => (
                  <CheckboxWithCountField
                    key={category_key}
                    label={ProductCategory[category_key as keyof typeof ProductCategory]}
                    name={`category_${category_key}`}
                    count={counts?.categories[category_key]}
                  />
                ))}
              <div style={{ display: categoriesExpanded ? 'block' : 'none' }}>
                {product_categories
                  .slice(10)
                  .filter((category_key) => {
                    return counts?.categories[category_key] && counts?.categories[category_key] > 0;
                  })
                  .map((category_key) => (
                    <CheckboxWithCountField
                      key={category_key}
                      label={ProductCategory[category_key as keyof typeof ProductCategory]}
                      name={`category_${category_key}`}
                      count={counts?.categories[category_key]}
                    />
                  ))}
              </div>
              {categoriesExpanded ? (
                <button
                  className="ais-RefinementList-showMore"
                  type="button"
                  onClick={(event) => {
                    event.preventDefault();
                    setCategoriesExpanded(false);
                  }}
                >
                  <Icon name="chevron" className="o-svg-icon o-svg-up" />
                  Show less
                </button>
              ) : (
                <button
                  className="ais-RefinementList-showMore"
                  type="button"
                  onClick={(event) => {
                    event.preventDefault();
                    setCategoriesExpanded(true);
                  }}
                >
                  <Icon name="chevron" className="o-svg-icon o-svg-down" />
                  Show more
                </button>
              )}
            </ul>
          </div>
        </FiltersAccordion>
      </Form>
    </>
  );
};

const ProductsLibraryModalContent: React.VFC<{
  title: string;
  onInsert: (items: { product: Product; selected_finish: string | undefined }[] | null) => void;
  document_settings: DocumentSettings;
}> = ({ title, onInsert, document_settings }) => {
  const history = useHistory();
  const location = useLocation();
  const fieldId = React.useMemo(() => getLocalProductBlockFieldId(), []);
  const [selectedItems, setSelectedItems] = React.useState<Product[]>([]);
  const [filters, setFilters] = React.useState<z.infer<typeof productsLibraryFiltersSchema>>({});
  const [selectedFinishes, setSelectedFinishes] = React.useState<Record<number, string>>({});

  const availableSortOptions = {
    created_at: 'Latest',
    title: 'Alphabetical',
    updated_at: 'Recently Updated',
  } as const;

  const dataTableQueryFnParams = React.useCallback<
    DataTableListingProps<typeof api.product.listProducts, typeof availableSortOptions>['queryFnParams']
  >(
    (listingFilters) => {
      const tiers = Object.keys(filters)
        .filter((filter_key) => filter_key.startsWith('tier_') && filters[filter_key as keyof typeof filters])
        .map((filter_key) => filter_key.replace(/^tier_/, ''));

      const categories = Object.keys(filters)
        .filter((filter_key) => filter_key.startsWith('category_') && filters[filter_key as keyof typeof filters])
        .map((filter_key) => filter_key.replace(/^category_/, ''));

      return {
        ...listingFilters,
        tiers: tiers.length ? tiers : undefined,
        categories: categories.length ? categories : undefined,
      };
    },
    [filters]
  );

  // TODO: remove this cleanup of url params after we add ability to provide custom state to DataTableListing
  useUnmount(() => {
    history.replace({ ...location, search: undefined });
  });

  return (
    <div className="c-block c-block--spacing-t-small c-block--spacing-b">
      <div className="o-container-fluid">
        <div className="o-row">
          <div className="o-col-12">
            <div className="c-block__header c-block__header--hor">
              <div className="c-block__header-content u-items-center u-block u-flex@sm">
                <div>
                  <h6 className="c-headline u-text-neutral-500">{title}</h6>
                  <h4 className="u-mr-spacer-base-small">Add Product</h4>
                </div>

                <div className="u-ml-auto@sm">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    href="#"
                    className="c-link-cta"
                    onClick={(event) => {
                      event.preventDefault();
                      onInsert(null);
                    }}
                  >
                    <Icon name="add-thin" className="o-svg-icon" />
                    <span>Add custom product</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DataTableListing
          label="products"
          availableSortOptions={availableSortOptions}
          defaultSort="title"
          defaultDirection="asc"
          defaultPerPage={48}
          queryFn={api.product.listProducts}
          queryFnParams={dataTableQueryFnParams}
          filters={(query) => (
            <ProductsLibraryFilters filters={filters} setFilters={setFilters} counts={query[0]?.data.filters} />
          )}
        >
          {(data) => (
            <ul className="o-stack-2@sm o-stack-3@md c-card__row--products">
              {data.map((product) => {
                const isSelected = typeof selectedItems.find((item) => item.id === product.id) !== 'undefined';

                return (
                  <li key={product.id}>
                    <article
                      className={classNames('c-card c-card--product c-card--link', { 'c-card--active': isSelected })}
                    >
                      <div className="c-card__body">
                        <div className="c-card__select-btn">
                          <div className="c-form-element c-form-element--style-line c-form-element--checkbox">
                            <div className="c-form-element__field">
                              <input
                                type="checkbox"
                                id={`${fieldId}_${product.id}`}
                                checked={isSelected}
                                onChange={(event) => {
                                  setSelectedItems((items) => {
                                    if (!event.target.checked) {
                                      return items.filter((item) => item.id !== product.id);
                                    }

                                    return [...items, { ...product }];
                                  });
                                }}
                              />
                              <label htmlFor={`${fieldId}_${product.id}`} />
                            </div>
                          </div>
                          <span
                            className="c-button c-button--full c-button--square c-button--primary u-mb-0"
                            onClick={(event) => {
                              event.preventDefault();
                              if (selectedItems.length === 0) {
                                onInsert([
                                  {
                                    product: prepareProductForInsertion(product),
                                    selected_finish: selectedFinishes[product.id],
                                  },
                                ]);
                              } else if (isSelected) {
                                onInsert(
                                  selectedItems.map((item) => ({
                                    product: prepareProductForInsertion(item),
                                    selected_finish: selectedFinishes[item.id],
                                  }))
                                );
                              } else {
                                setSelectedItems((items) => {
                                  return [...items, { ...product }];
                                });
                              }
                            }}
                          >
                            <Icon name="add" className="o-svg-icon" />
                            Insert
                          </span>
                        </div>
                        <figure className="c-card__figure">
                          <ProductImage product={product} />
                          {/* <div className="c-card__tags">
                          <span className="c-tag c-tag--small c-tag--dark">CUSTOM</span>
                        </div> */}
                        </figure>
                        <header className="c-card__header">
                          <p className="c-headline">
                            {(product.categories ?? [])
                              .map((item) => ProductCategory[item.term as keyof typeof ProductCategory])
                              .filter(Boolean)
                              .join(', ')}
                          </p>
                          <h4 className="c-card__title">{product.title}</h4>
                          <p className="c-card__subtitle">Model {product.sku}</p>
                        </header>
                        <footer className="c-card__footer c-card__footer-row">
                          <div className="o-col">
                            <ul className="c-finishes c-finishes--small c-finishes--hor">
                              {(product.finishes ?? [])
                                .filter((term) => ProductFinish[term.term] && finishIcons[ProductFinish[term.term]])
                                .map((term) => ({
                                  ...term,
                                  title: ProductFinish[term.term],
                                  icon: finishIcons[ProductFinish[term.term]],
                                }))
                                .map((finish, index) => (
                                  <li
                                    key={finish.id}
                                    className={classNames({
                                      'is-selected':
                                        selectedFinishes[product.id] === finish.term ||
                                        (typeof selectedFinishes[product.id] === 'undefined' && index === 0),
                                    })}
                                  >
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <a
                                      href="#"
                                      title={finish.title}
                                      onClick={(event) => {
                                        event.preventDefault();
                                        setSelectedFinishes((value) => {
                                          return {
                                            ...value,
                                            [product.id]: finish.term,
                                          };
                                        });
                                      }}
                                    >
                                      <span
                                        className="c-finishes__preview"
                                        style={{ backgroundImage: `url(${finish.icon})` }}
                                      />
                                    </a>
                                  </li>
                                ))}
                            </ul>
                          </div>
                          <div className="o-col c-card__col--end">
                            <div className="c-price">
                              <p className="c-price__amount">
                                <span className="c-price__small c-price__sup">$</span>
                                <span className="c-price__number">
                                  <DisplayPrice
                                    value={
                                      document_settings.currency_type === CurrencyCode.Ca
                                        ? product.ca_price && !Number.isNaN(Number(product.ca_price))
                                          ? Number(product.ca_price)
                                          : 0
                                        : product.price ?? 0
                                    }
                                    currency={document_settings.currency_type === CurrencyCode.Ca ? 'CAD' : 'USD'}
                                    hideCurrencySign
                                  />
                                </span>
                                <span className="c-price__small">
                                  {document_settings.currency_type === CurrencyCode.Ca ? 'CAD' : 'USD'}
                                </span>
                              </p>
                              {(product.tiers ?? []).find((term) => term.term === 'RDS') && (
                                <div className="c-price__note">
                                  <p>
                                    <Icon name="tier-1--multi" className="o-svg-icon o-svg-larger" />
                                    <span>ReadyStock™</span>
                                  </p>
                                </div>
                              )}
                              {(product.tiers ?? []).find((term) => term.term === 'CMS') && (
                                <div className="c-price__note">
                                  <p>
                                    <Icon name="tier-4--multi" className="o-svg-icon o-svg-larger" />
                                    <span>Coming Soon</span>
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        </footer>
                      </div>
                    </article>
                  </li>
                );
              })}
            </ul>
          )}
        </DataTableListing>
        <div className="u-text-right">
          <Button
            disabled={selectedItems.length === 0}
            onClick={(event) => {
              event.preventDefault();
              onInsert(
                selectedItems.map((item) => ({
                  product: prepareProductForInsertion(item),
                  selected_finish: selectedFinishes[item.id],
                }))
              );
            }}
          >
            Insert {selectedItems.length > 1 ? `${selectedItems.length} items` : 'item'}
          </Button>
        </div>
      </div>
    </div>
  );
};

interface ProductBlockFieldProps {
  name: string;
  title: string;
  buttonProps?: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
  onInsert: (items: { product: Product; settings: ProductBlockDefaultValues['settings'] }[]) => void;
}

const ProductSelectedFinishField: React.VFC = () => {
  const finishes = useWatch<ProductBlockDefaultValues, 'settings.finishes'>({ name: 'settings.finishes' });
  if (!finishes) {
    return null;
  }
  const filteredFinishes = finishes.filter((finish) => finish.term.length > 0);

  if (!filteredFinishes.length) {
    return null;
  }

  return (
    <SelectField name="settings.selected_finish" label="Selected Finish" elStyle="fill">
      {filteredFinishes.map((field) => (
        <option key={field.id} value={field.term}>
          {ProductFinish[field.term]}
        </option>
      ))}
    </SelectField>
  );
};

const ProductFinishesField: React.VFC = () => {
  const { fields, append, remove } = useFieldArray<ProductBlockDefaultValues, 'settings.finishes'>({
    name: 'settings.finishes',
  });

  React.useEffect(() => {
    if (!fields.length) {
      append({
        id: getLocalProductFinishesId(),
        term: '',
        type: TaxonomyTerm.Finish,
      });
    }
  }, [append, fields]);

  return (
    <div>
      <div className="u-pb-spacer-base">
        {fields.map((field, index) => {
          return (
            <div key={field.id} className="o-row o-row--small-gutters u-items-center u-mb-spacer-base u-mb-0@md">
              <div className="o-col-10 o-col-11@md">
                <SelectField
                  key={field.id}
                  name={`settings.finishes.${index}.term`}
                  label={`Finish ${index + 1}`}
                  defaultValue={field.term}
                  elStyle="fill"
                  className="u-mb-0"
                >
                  {Object.keys(ProductFinish)
                    .filter(
                      (finish_key) => finish_key === field.term || !fields.find(({ term }) => term === finish_key)
                    )
                    .map((finish_key) => (
                      <option key={finish_key} value={finish_key}>
                        {ProductFinish[finish_key]}
                      </option>
                    ))}
                </SelectField>
              </div>
              <div className="o-col">
                <button
                  type="button"
                  style={{ lineHeight: '1' }}
                  onClick={(event) => {
                    event.preventDefault();
                    remove(index);
                  }}
                >
                  <Icon name="trash" className="o-svg-icon" />
                </button>
              </div>
            </div>
          );
        })}

        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          href="#"
          className="c-link-cta u-mt-spacer-base@md"
          onClick={(event) => {
            event.preventDefault();
            append({
              id: getLocalProductFinishesId(),
              term: '',
              type: TaxonomyTerm.Finish,
            });
          }}
        >
          <span>Add finish</span>
        </a>
      </div>
      <ProductSelectedFinishField />
    </div>
  );
};

const ProductDocumentsOptions = {
  [ProductDocumentType.ProductSheet]: 'Product sheet',
  [ProductDocumentType.CustomerService]: 'Customer service',
  [ProductDocumentType.Warranty]: 'Warranty',
  [ProductDocumentType.InstallationGuide]: 'Installation instructions',
  [ProductDocumentType.SpecSheet]: 'Specification submittal',
};

const ProductDocumentsField: React.VFC = () => {
  const { fields, append, remove } = useFieldArray<ProductBlockDefaultValues, 'settings.documents'>({
    name: 'settings.documents',
  });
  const [uploadFile, uploadFileState] = useMutation(api.file.upload);
  const cancelTokenRef = React.useRef<CancelTokenSource | undefined>(undefined);
  const { getInputProps, open } = useDropzone({
    multiple: false,
    preventDropOnDocument: true,
    noClick: true,
    onDropAccepted: ([image]) => {
      cancelTokenRef.current = axios.CancelToken.source();
      uploadFile([{ image, type: FileType.File }, { cancelToken: cancelTokenRef.current.token }]).then((file) => {
        append({
          id: getLocalProductDocumentsId(),
          file: file.data,
          source: 'settings',
        });
      });
    },
  });

  return (
    <div className="u-pb-spacer-base">
      <input {...getInputProps()} />
      {fields.map((field, index) => {
        return (
          <div key={field.id} className="o-row o-row--small-gutters u-items-center u-mb-spacer-base u-mb-0@md">
            <div className="o-col-10 o-col-11@md">
              <div className="o-row o-row--small-gutters">
                <div className="o-col-6@md u-pr-0@md">
                  <SelectField
                    name={`settings.documents.${index}.type`}
                    elStyle="fill"
                    label="Document Type"
                    className="u-mb-0"
                  >
                    {Object.keys(ProductDocumentsOptions)
                      .filter((key) => {
                        return !fields.find(
                          (f, i) => i !== index && f.type === (key as unknown as keyof typeof ProductDocumentsOptions)
                        );
                      })
                      .map((key) => (
                        <option key={key} value={key}>
                          {ProductDocumentsOptions[key as unknown as keyof typeof ProductDocumentsOptions]}
                        </option>
                      ))}
                  </SelectField>
                </div>
                <div className="o-col-6@md u-pr-0@md">
                  <InputField
                    name={
                      field.source === 'product'
                        ? `settings.documents.${index}.file.salsify_name`
                        : `settings.documents.${index}.file.title`
                    }
                    label="Document Name"
                    elStyle="fill"
                    className="u-mb-0"
                  />
                </div>
              </div>
            </div>
            <div className="o-col">
              <button
                type="button"
                style={{ lineHeight: '1' }}
                onClick={(event) => {
                  event.preventDefault();
                  remove(index);
                }}
              >
                <Icon name="trash" className="o-svg-icon" />
              </button>
            </div>
          </div>
        );
      })}
      {fields.length < Object.keys(ProductDocumentsOptions).length && !uploadFileState.isLoading && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          href="#"
          className="c-link-cta u-mt-spacer-base@md"
          onClick={(event) => {
            event.preventDefault();
            open();
          }}
        >
          <span>Add file</span>
        </a>
      )}
      {uploadFileState.isLoading && (
        <Button isLoading variant="white" className="u-mt-spacer-base@md" style={{ backgroundColor: 'transparent' }} />
      )}
    </div>
  );
};

const ProductFeaturesField: React.VFC = () => {
  const { fields, append, remove } = useFieldArray<ProductBlockDefaultValues, 'settings.features'>({
    name: 'settings.features',
  });

  return (
    <div className="u-pb-spacer-base">
      {fields.map((field, index) => {
        return (
          <div key={field.id} className="o-row o-row--small-gutters u-items-center u-mb-spacer-base u-mb-0@md">
            <div className="o-col-10 o-col-11@md">
              <InputField
                name={`settings.features.${index}.content`}
                placeholder={`Feature ${index + 1}`}
                defaultValue={field.content}
                elStyle="fill"
                className="u-mb-0"
              />
            </div>
            <div className="o-col">
              <button
                type="button"
                style={{ lineHeight: '1' }}
                onClick={(event) => {
                  event.preventDefault();
                  remove(index);
                }}
              >
                <Icon name="trash" className="o-svg-icon" />
              </button>
            </div>
          </div>
        );
      })}

      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        href="#"
        className="c-link-cta u-mt-spacer-base@md"
        onClick={(event) => {
          event.preventDefault();
          append({
            id: getLocalProductFeatureId(),
            content: '',
          });
        }}
      >
        <span>Add feature</span>
      </a>
    </div>
  );
};

const getProductFormInitialValues = (
  value: ProductBlockDefaultValues,
  document_settings: DocumentSettings
): ProductBlockDefaultValues => {
  const productDocuments: Exclude<ProductBlockDefaultValues['settings'], undefined>['documents'] = [];

  const items: {
    productModelKey: ConditionalKeys<
      Exclude<ProductBlockDefaultValues['content'], null | undefined>,
      z.infer<typeof SalsifyDigitalAsset> | null | undefined
    >;
    type: ProductDocumentType;
  }[] = [
    {
      productModelKey: 'product_sheet',
      type: ProductDocumentType.ProductSheet,
    },
    { productModelKey: 'warranty', type: ProductDocumentType.Warranty },
    {
      productModelKey: 'installation_guide',
      type: ProductDocumentType.InstallationGuide,
    },
    { productModelKey: 'spec_sheet', type: ProductDocumentType.SpecSheet },
  ];

  items.forEach((item) => {
    const productValue = item.productModelKey && value.content ? value.content[item.productModelKey] : undefined;

    if (productValue) {
      productDocuments.push({
        id: getLocalProductDocumentsId(),
        type: item.type,
        file: productValue,
        source: 'product',
      });
    }
  });

  const finishes = value.settings?.finishes || value.content?.finishes || [];
  const selected_finish = value.settings?.selected_finish;
  const documents = value.settings?.documents ?? productDocuments;

  const show_no_of_units = value.settings?.show_no_of_units ?? document_settings.show_default_number_units ?? true;
  const no_of_units = Math.floor(value.settings?.no_of_units ?? document_settings.default_number_units ?? 10);
  const list_price =
    Math.floor(
      100 *
        (value.settings?.list_price ??
          (document_settings.currency_type === CurrencyCode.Ca
            ? value.content?.ca_price && !Number.isNaN(Number(value.content.ca_price))
              ? Number(value.content.ca_price)
              : 0.0
            : value.content?.price ?? 0.0))
    ) / 100;
  const multiplier =
    Math.floor(10000 * (value.settings?.multiplier ?? document_settings.default_multiplier ?? 0.7)) / 10000;
  const net_price = Math.floor(100 * (value.settings?.net_price ?? (list_price ?? 0) * multiplier)) / 100;
  const ext_price = Math.floor(100 * (value.settings?.ext_price ?? net_price * no_of_units)) / 100;

  return {
    ...value,
    settings: {
      ...value.settings,
      title: value.settings?.title || value.content?.title || '',
      sku: value.settings?.sku || value.content?.sku || '',
      show_finishes: value.settings?.show_finishes ?? finishes.length > 0,
      finishes,
      selected_finish:
        typeof selected_finish !== 'undefined' ? selected_finish : finishes.length > 0 ? finishes[0].term : undefined,
      show_features: value.settings?.show_features ?? true,
      features: Array.isArray(value.settings?.features)
        ? value.settings!.features
        : value.content
        ? featuresStringsToObjects(Array.isArray(value.content.features) ? (value.content.features as string[]) : [])
        : [],
      show_documents: value.settings?.show_documents ?? documents.length > 0,
      documents,
      show_pricing: value.settings?.show_pricing ?? true,
      show_no_of_units,
      no_of_units,
      show_list_price: value.settings?.show_list_price ?? document_settings.show_list_price ?? true,
      list_price,
      multiplier,
      show_net_price: value.settings?.show_net_price ?? document_settings.show_discount_price_label ?? false,
      net_price_label:
        value.settings?.net_price_label ?? document_settings.discount_price_label ?? 'Distributor Net Price',
      net_price,
      show_ext_price: value.settings?.show_ext_price ?? document_settings.show_extended_price ?? true,
      ext_price,
      show_info: value.settings?.show_info ?? false,
      show_special_attributes: value.settings?.show_special_attributes ?? true,
    },
  };
};

const ProductImageField: React.VFC<{
  openImageLibrary: () => void;
  openImageEdit: () => void;
  isAccordionOpen: boolean;
  setIsAccordionOpen: (value: boolean) => void;
}> = ({ openImageLibrary, openImageEdit, isAccordionOpen, setIsAccordionOpen }) => {
  const {
    setValue,
    formState: { errors },
  } = useFormContext<ProductBlockDefaultValues>();

  const contentImage = useWatch<ProductBlockDefaultValues, 'content.main_image'>({ name: 'content.main_image' });
  const settingsImage = useWatch<ProductBlockDefaultValues, 'settings.main_image'>({
    name: 'settings.main_image',
  });
  const settingsImageSettings = useWatch<ProductBlockDefaultValues, 'settings.image_settings'>({
    name: 'settings.image_settings',
  });
  const { getRootProps, getInputProps, isDragActive, open, uploadImageState, uploadProgress, cancelUpload } =
    useImageUpload({
      onChange: (data) => {
        setValue('settings.main_image', data);
      },
    });

  return (
    <>
      <input {...getInputProps()} />
      <Accordion
        title="Image"
        type="framed"
        isInvalid={
          typeof errors.settings?.main_image !== 'undefined' || typeof errors.settings?.image_settings !== 'undefined'
        }
        initialValue={isAccordionOpen}
        onChange={setIsAccordionOpen}
      >
        {uploadImageState.isLoading ? (
          <UploadImageProgress title="" uploadProgress={uploadProgress} cancelUpload={cancelUpload} />
        ) : (
          <div {...getRootProps()}>
            {isDragActive ? (
              <div className="c-add-media c-add-media--active u-mb-spacer-base">
                <div>
                  <Icon name="upload" className="o-svg-lg" />
                </div>

                <div>
                  <button className="c-link-cta-basic c-link-cta--small" type="button">
                    <span>Drop file here...</span>
                  </button>
                </div>
              </div>
            ) : settingsImage ? (
              <BlockContentImage
                image={settingsImage}
                image_settings={settingsImageSettings}
                openFileUpload={open}
                openImageLibrary={openImageLibrary}
                revertToOriginal={() => {
                  setValue('settings.main_image', undefined);
                  setValue('settings.image_settings', undefined);
                }}
                openImageEdit={openImageEdit}
              />
            ) : contentImage && settingsImage !== null ? (
              <BlockContentImage
                image={{
                  name: contentImage!.salsify_name,
                  size: contentImage!.salsify_bytes,
                  image: getTransformedSalsifyImage(contentImage!, ['w_512', 'h_512', 'c_fit']),
                  width:
                    contentImage!.salsify_asset_width! >= contentImage!.salsify_asset_height!
                      ? 512
                      : (512 * contentImage!.salsify_asset_width!) / contentImage!.salsify_asset_height!,
                  height:
                    contentImage!.salsify_asset_height! >= contentImage!.salsify_asset_width!
                      ? 512
                      : (512 * contentImage!.salsify_asset_height!) / contentImage!.salsify_asset_width!,
                }}
                image_settings={settingsImageSettings}
                openFileUpload={open}
                openImageLibrary={openImageLibrary}
                clearImage={() => {
                  setValue('settings.main_image', null);
                  setValue('settings.image_settings', undefined);
                }}
                openImageEdit={openImageEdit}
              />
            ) : (
              <ChooseImagePlaceholder openLibrary={openImageLibrary} open={open} />
            )}
            {uploadImageState.isError && uploadImageState.error && !axios.isCancel(uploadImageState.error) && (
              <DisplayReactQueryError error={uploadImageState.error} />
            )}
            <DisplayFieldErrors errors={pick(errors.settings, 'main_image', 'image_settings')} hideKeyLabels />
          </div>
        )}
      </Accordion>
      {!isAccordionOpen && (
        <DisplayFieldErrors
          errors={pick(errors.settings, 'main_image', 'image_settings')}
          hideKeyLabels
          style={{ marginTop: '-15px', marginBottom: '20px' }}
        />
      )}
    </>
  );
};

type ProductModalStateValue = 'closed' | 'choose' | 'details' | 'library' | 'image-library' | 'image-edit';

const ProductLibraryModalStateContent: React.VFC<{
  title: string;
  setModalState: React.Dispatch<React.SetStateAction<ProductModalStateValue>>;
  onInsert: (items: { product: Product; selected_finish: string | undefined }[]) => void;
  document_settings: DocumentSettings;
}> = ({ title, setModalState, onInsert, document_settings }) => {
  const { setValue } = useFormContext<ProductBlockDefaultValues>();

  return (
    <ProductsLibraryModalContent
      title={title}
      onInsert={(value) => {
        if (value === null) {
          setValue('content', null);
          setValue('settings', {});
          setModalState('details');
        } else if (value.length === 1) {
          setValue('content', { ...value[0].product });
          setValue(
            'settings',
            getProductFormInitialValues(
              {
                id: '',
                type: ProductBlockType.Product,
                content: { ...value[0].product },
                settings: { selected_finish: value[0].selected_finish },
              },
              document_settings
            ).settings
          );

          setModalState('details');
        } else {
          onInsert(value);
        }
      }}
      document_settings={document_settings}
    />
  );
};

const ProductMediaLibraryModalStateContent: React.VFC<{
  title: string;
  setModalState: React.Dispatch<React.SetStateAction<ProductModalStateValue>>;
}> = ({ title, setModalState }) => {
  const { setValue } = useFormContext<ProductBlockDefaultValues>();

  return (
    <MediaLibraryModalContent
      title={title}
      onInsert={(image) => {
        setValue('settings.main_image', image);
        setValue('settings.image_settings', undefined);
        setModalState('details');
      }}
    />
  );
};

const ProductImageEditModalStateContent: React.VFC<{
  title: string;
  setModalState: React.Dispatch<React.SetStateAction<ProductModalStateValue>>;
}> = ({ title, setModalState }) => {
  const { setValue } = useFormContext<ProductBlockDefaultValues>();

  const contentImage = useWatch<ProductBlockDefaultValues, 'content.main_image'>({ name: 'content.main_image' });
  const settingsImage = useWatch<ProductBlockDefaultValues, 'settings.main_image'>({
    name: 'settings.main_image',
  });
  const settingsImageSettings = useWatch<ProductBlockDefaultValues, 'settings.image_settings'>({
    name: 'settings.image_settings',
  });

  React.useEffect(() => {
    // It should not happen that we are in the image-edit modal state without image in the content
    // or in the settings, but anyway adding this just in case
    if (settingsImage === null || (!settingsImage && !contentImage)) {
      setModalState('details');
    }
  }, [contentImage, setModalState, settingsImage]);

  if (settingsImage !== null && (settingsImage || contentImage)) {
    return (
      <EditImageModalContent
        title={title}
        image={
          settingsImage || {
            name: contentImage!.salsify_name,
            image: getTransformedSalsifyImage(contentImage!, ['w_512', 'h_512', 'c_fit']),
            width:
              contentImage!.salsify_asset_width! >= contentImage!.salsify_asset_height!
                ? 512
                : (512 * contentImage!.salsify_asset_width!) / contentImage!.salsify_asset_height!,
            height:
              contentImage!.salsify_asset_height! >= contentImage!.salsify_asset_width!
                ? 512
                : (512 * contentImage!.salsify_asset_height!) / contentImage!.salsify_asset_width!,
          }
        }
        settings={settingsImageSettings}
        onChange={(settings) => {
          setValue('settings.image_settings', settings);
          setModalState('details');
        }}
        onCancel={() => {
          setModalState('details');
        }}
      />
    );
  }

  return null;
};

const ProductPricingFields: React.VFC = () => {
  const { setValue } = useFormContext<ProductBlockDefaultValues>();
  const no_of_units = useWatch<ProductBlockDefaultValues, 'settings.no_of_units'>({
    name: 'settings.no_of_units',
  });
  const list_price = useWatch<ProductBlockDefaultValues, 'settings.list_price'>({
    name: 'settings.list_price',
  });
  const net_price = useWatch<ProductBlockDefaultValues, 'settings.net_price'>({
    name: 'settings.net_price',
  });
  const multiplier = useWatch<ProductBlockDefaultValues, 'settings.multiplier'>({
    name: 'settings.multiplier',
  });

  React.useEffect(() => {
    setValue('settings.ext_price', Math.floor(100 * (no_of_units ?? 10) * (net_price ?? 0)) / 100);
  }, [net_price, no_of_units, setValue]);

  return (
    <div>
      <CheckboxField checkboxStyle="toggle" name="settings.show_no_of_units" label="Show Number of Units" />
      <InputField
        name="settings.no_of_units"
        label="Number of Units"
        type="number"
        elStyle="fill"
        inputProps={{
          min: 0,
        }}
      />
      <CheckboxField checkboxStyle="toggle" name="settings.show_list_price" label="Show List Price" />
      <InputField
        name="settings.list_price"
        label="List Price"
        type="number"
        elStyle="fill"
        inputProps={{
          step: 0.01,
          min: 0,
          onInput: (event) => {
            const new_list_price = (event.target as HTMLInputElement).valueAsNumber ?? 0;
            setValue('settings.net_price', Math.floor(100 * new_list_price * (multiplier ?? 1)) / 100);
          },
        }}
      />
      <CheckboxField checkboxStyle="toggle" name="settings.show_net_price" label="Show Net Price" />
      <SelectField name="settings.net_price_label" label="Net Price Label" elStyle="fill">
        <option value="Distributor Net Price">Distributor Net Price</option>
        <option value="Budget Price">Budget Price</option>
      </SelectField>
      <InputField
        name="settings.net_price"
        label="Net Price"
        type="number"
        elStyle="fill"
        inputProps={{
          step: 0.01,
          min: 0,
          onInput: (event) => {
            const new_net_price = (event.target as HTMLInputElement).valueAsNumber ?? 0;
            setValue(
              'settings.multiplier',
              new_net_price && list_price ? Math.floor(10000 * (new_net_price / list_price)) / 10000 : 1
            );
          },
        }}
      />
      <InputField
        name="settings.multiplier"
        label="Multiplier"
        type="number"
        elStyle="fill"
        inputProps={{
          step: 0.0001,
          min: 0,
          onInput: (event) => {
            const new_multiplier = (event.target as HTMLInputElement).valueAsNumber ?? 0;
            setValue('settings.net_price', Math.floor(100 * (list_price ?? 0) * new_multiplier) / 100);
          },
        }}
      />
      <CheckboxField checkboxStyle="toggle" name="settings.show_ext_price" label="Show Extended Price" />
      <fieldset disabled>
        <InputField
          name="settings.ext_price"
          label="Extended Price"
          type="number"
          elStyle="fill"
          inputProps={{
            step: 0.01,
            min: 0,
            readOnly: true,
          }}
        />
      </fieldset>
    </div>
  );
};

const ProductBlockModalContent: React.VFC<{
  title: string;
  field: ControllerRenderProps<{ block: ProductBlockDefaultValues }, 'block'>;
  onChange: (product: ProductBlockDefaultValues) => void;
  modalState: ProductModalStateValue;
  setModalState: React.Dispatch<React.SetStateAction<ProductModalStateValue>>;
  onInsert: (items: { product: Product; settings: ProductBlockDefaultValues['settings'] }[]) => void;
  setIsFormDirty: (value: boolean) => void;
  document_settings: DocumentSettings;
}> = ({ title, field, onChange, modalState, setModalState, onInsert, setIsFormDirty, document_settings }) => {
  const { context, formProps } = useForm({
    initialValues: getProductFormInitialValues(field.value, document_settings),
    onSubmit: async (values) => {
      onChange(values);
      setModalState('closed');
    },
    schema: ProductBlock,
    disableFieldsOnSubmitting: true,
    hasFloatingLabels: true,
    stopSubmitPropagation: true,
  });

  React.useEffect(() => {
    setIsFormDirty(context.formState.isDirty);
  }, [context.formState.isDirty, setIsFormDirty]);

  const [isImageAccordionOpen, setIsImageAccordionOpen] = React.useState(false);
  const [isGeneralOpen, setIsGeneralOpen] = React.useState(false);
  const [isFeaturesOpen, setIsFeaturesOpen] = React.useState(false);
  const [isFinishesOpen, setIsFinishesOpen] = React.useState(false);
  const [isDocumentsOpen, setIsDocumentsOpen] = React.useState(false);
  const [isPricingOpen, setIsPricingOpen] = React.useState(false);
  const [isMiscellaneousOpen, setIsMiscellaneousOpen] = React.useState(false);
  // const [isSpecialAttrsOpen, setIsSpecialAttrsOpen] = React.useState(false);

  return (
    <FormProvider {...context}>
      {modalState === 'details' && (
        <div className="c-block c-block--spacing-t-small c-block--spacing-b-small">
          <div className="o-container-fluid">
            <div className="o-col-12">
              <div className="c-block__header c-block__header--hor">
                <div className="c-block__header-content u-items-center u-block u-flex@sm">
                  <div>
                    <h6 className="c-headline u-text-neutral-500">{title}</h6>
                    <h4>Product Details</h4>
                  </div>
                </div>
              </div>
              <div className="u-mb-spacer-base">
                <button
                  className="c-button c-button--light-grey c-button--option"
                  type="button"
                  onClick={(event) => {
                    event.preventDefault();
                    setModalState('library');
                  }}
                >
                  <Icon name="refresh" className="o-svg-icon o-svg-large" />
                  <span>Change Product</span>
                </button>{' '}
                <button
                  className="c-button c-button--light-grey c-button--option"
                  type="button"
                  onClick={(event) => {
                    event.preventDefault();
                    onChange({
                      id: getLocalBlockId(),
                      type: ProductBlockType.Product,
                      content: undefined,
                      settings: undefined,
                    });
                    setModalState('closed');
                  }}
                >
                  <Icon name="close" className="o-svg-icon" />
                  <span>Clear Product</span>
                </button>
              </div>
            </div>
            <div className="o-row">
              <div className="o-col-12">
                <form {...formProps}>
                  <DisplayFieldErrors errors={context.formState.errors.content} />
                  <DisplayFieldErrors
                    errors={omit(
                      context.formState.errors.settings,
                      'main_image',
                      'image_settings',
                      'title',
                      'sku',
                      'features',
                      'show_features',
                      'finishes',
                      'show_finishes',
                      'selected_finish',
                      'documents',
                      'show_documents',
                      'show_pricing',
                      'show_no_of_units',
                      'no_of_units',
                      'show_list_price',
                      'list_price',
                      'show_net_price',
                      'net_price',
                      'net_price_label',
                      'multiplier',
                      'show_ext_price',
                      'ext_price',
                      'show_info',
                      'heading',
                      'notes'
                    )}
                  />
                  <ProductImageField
                    openImageLibrary={() => {
                      setModalState('image-library');
                    }}
                    openImageEdit={() => {
                      setModalState('image-edit');
                    }}
                    isAccordionOpen={isImageAccordionOpen}
                    setIsAccordionOpen={setIsImageAccordionOpen}
                  />
                  <Accordion
                    title="General"
                    type="framed"
                    isInvalid={
                      !isGeneralOpen &&
                      (typeof context.formState.errors.settings?.title !== 'undefined' ||
                        typeof context.formState.errors.settings?.sku !== 'undefined')
                    }
                    initialValue={isGeneralOpen}
                    onChange={setIsGeneralOpen}
                  >
                    <InputField name="settings.title" label="Product Name" elStyle="fill" />
                    <InputField name="settings.sku" label="Model Name" elStyle="fill" />
                  </Accordion>
                  {!isGeneralOpen && (
                    <DisplayFieldErrors
                      errors={pick(context.formState.errors.settings, 'title', 'sku')}
                      hideKeyLabels
                      style={{ marginTop: '-15px', marginBottom: '20px' }}
                    />
                  )}
                  <AccordionWithHideToggle
                    title="Features"
                    type="framed"
                    name="settings.show_features"
                    isInvalid={
                      typeof context.formState.errors.settings?.features !== 'undefined' ||
                      typeof context.formState.errors.settings?.show_features !== 'undefined'
                    }
                    initialValue={isFeaturesOpen}
                    onChange={setIsFeaturesOpen}
                  >
                    <ProductFeaturesField />
                  </AccordionWithHideToggle>
                  {!isFeaturesOpen && (
                    <DisplayFieldErrors
                      errors={pick(context.formState.errors.settings, 'features', 'show_features')}
                      hideKeyLabels
                      style={{ marginTop: '-15px', marginBottom: '20px' }}
                    />
                  )}
                  <AccordionWithHideToggle
                    title="Finishes"
                    type="framed"
                    name="settings.show_finishes"
                    isInvalid={
                      typeof context.formState.errors.settings?.finishes !== 'undefined' ||
                      typeof context.formState.errors.settings?.show_finishes !== 'undefined' ||
                      typeof context.formState.errors.settings?.selected_finish !== 'undefined'
                    }
                    initialValue={isFinishesOpen}
                    onChange={setIsFinishesOpen}
                  >
                    <ProductFinishesField />
                  </AccordionWithHideToggle>
                  {!isFinishesOpen && (
                    <DisplayFieldErrors
                      errors={pick(context.formState.errors.settings, 'finishes', 'show_finishes', 'selected_finish')}
                      hideKeyLabels
                      style={{ marginTop: '-15px', marginBottom: '20px' }}
                    />
                  )}
                  <AccordionWithHideToggle
                    title="Documents"
                    type="framed"
                    name="settings.show_documents"
                    isInvalid={
                      typeof context.formState.errors.settings?.documents !== 'undefined' ||
                      typeof context.formState.errors.settings?.show_documents !== 'undefined'
                    }
                    initialValue={isDocumentsOpen}
                    onChange={setIsDocumentsOpen}
                  >
                    <ProductDocumentsField />
                  </AccordionWithHideToggle>
                  {!isDocumentsOpen && (
                    <DisplayFieldErrors
                      errors={pick(context.formState.errors.settings, 'documents', 'show_documents')}
                      hideKeyLabels
                      style={{ marginTop: '-15px', marginBottom: '20px' }}
                    />
                  )}
                  <AccordionWithHideToggle
                    title="Pricing"
                    type="framed"
                    name="settings.show_pricing"
                    isInvalid={
                      typeof context.formState.errors.settings?.show_pricing !== 'undefined' ||
                      typeof context.formState.errors.settings?.show_no_of_units !== 'undefined' ||
                      typeof context.formState.errors.settings?.no_of_units !== 'undefined' ||
                      typeof context.formState.errors.settings?.show_list_price !== 'undefined' ||
                      typeof context.formState.errors.settings?.list_price !== 'undefined' ||
                      typeof context.formState.errors.settings?.show_net_price !== 'undefined' ||
                      typeof context.formState.errors.settings?.net_price !== 'undefined' ||
                      typeof context.formState.errors.settings?.net_price_label !== 'undefined' ||
                      typeof context.formState.errors.settings?.multiplier !== 'undefined' ||
                      typeof context.formState.errors.settings?.show_ext_price !== 'undefined' ||
                      typeof context.formState.errors.settings?.ext_price !== 'undefined'
                    }
                    initialValue={isPricingOpen}
                    onChange={setIsPricingOpen}
                  >
                    <ProductPricingFields />
                  </AccordionWithHideToggle>
                  {!isPricingOpen && (
                    <DisplayFieldErrors
                      errors={pick(
                        context.formState.errors.settings,
                        'show_pricing',
                        'show_no_of_units',
                        'no_of_units',
                        'show_list_price',
                        'list_price',
                        'show_net_price',
                        'net_price',
                        'net_price_label',
                        'multiplier',
                        'show_ext_price',
                        'ext_price'
                      )}
                      hideKeyLabels
                      style={{ marginTop: '-15px', marginBottom: '20px' }}
                    />
                  )}
                  <AccordionWithHideToggle
                    title="Miscellaneous"
                    type="framed"
                    name="settings.show_info"
                    isInvalid={
                      typeof context.formState.errors.settings?.show_info !== 'undefined' ||
                      typeof context.formState.errors.settings?.heading !== 'undefined' ||
                      typeof context.formState.errors.settings?.notes !== 'undefined'
                    }
                    initialValue={isMiscellaneousOpen}
                    onChange={setIsMiscellaneousOpen}
                  >
                    <InputField name="settings.heading" label="Heading" elStyle="fill" />
                    <InputField name="settings.notes" label="Content" type="textarea" elStyle="fill" />
                  </AccordionWithHideToggle>
                  {!isDocumentsOpen && (
                    <DisplayFieldErrors
                      errors={pick(context.formState.errors.settings, 'show_info', 'heading', 'notes')}
                      hideKeyLabels
                      style={{ marginTop: '-15px', marginBottom: '20px' }}
                    />
                  )}
                  <AccordionWithHideToggle
                    title="Special Attributes"
                    type="framed"
                    name="settings.show_special_attributes"
                    isInvalid={typeof context.formState.errors.settings?.show_special_attributes !== 'undefined'}
                    // initialValue={isSpecialAttrsOpen}
                    // onChange={setIsSpecialAttrsOpen}
                  />
                  {/* {!isSpecialAttrsOpen && (
                    <DisplayFieldErrors
                      errors={pick(context.formState.errors.settings, 'show_special_attributes')}
                      hideKeyLabels
                      style={{ marginTop: '-15px', marginBottom: '20px' }}
                    />
                  )} */}
                  <SubmitButton className="c-button--md">Save</SubmitButton>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      {(modalState === 'library' || modalState === 'choose') && (
        <ProductLibraryModalStateContent
          title={title}
          setModalState={setModalState}
          onInsert={(value) => {
            const [updateProduct, ...otherProducts] = value;

            onChange({
              ...field.value,
              content: { ...updateProduct.product },
              settings: getProductFormInitialValues(
                {
                  id: '',
                  type: ProductBlockType.Product,
                  content: { ...updateProduct.product },
                  settings: { selected_finish: updateProduct.selected_finish },
                },
                document_settings
              ).settings,
            });

            onInsert(
              otherProducts.map((item) => ({
                product: item.product,
                settings: getProductFormInitialValues(
                  {
                    id: '',
                    type: ProductBlockType.Product,
                    content: { ...item.product },
                    settings: { selected_finish: item.selected_finish },
                  },
                  document_settings
                ).settings,
              }))
            );
            setModalState('closed');
          }}
          document_settings={document_settings}
        />
      )}
      {modalState === 'image-library' && (
        <ProductMediaLibraryModalStateContent title={title} setModalState={setModalState} />
      )}
      {modalState === 'image-edit' && <ProductImageEditModalStateContent title={title} setModalState={setModalState} />}
    </FormProvider>
  );
};

const ProductBlockField: React.VFC<ProductBlockFieldProps> = ({ name, title, buttonProps, onInsert }) => {
  const document_settings = useDocumentSettings();

  const { field, fieldState } = useController<{ block: ProductBlockDefaultValues }, 'block'>({
    name: name as 'block',
  });
  const [modalState, setModalState] = React.useState<ProductModalStateValue>('closed');
  const [isFormDirty, setIsFormDirty] = React.useState(false);
  const onChange = (product: ProductBlockDefaultValues) => {
    return field.onChange(product);
  };

  const onClose = () => {
    if (modalState !== 'details' && modalState !== 'choose') {
      setModalState('details');
    } else if (isFormDirty) {
      if (window.confirm('Do you really want to leave? You have unsaved changes!')) {
        setModalState('closed');
        field.onBlur();
      }
    } else {
      setModalState('closed');
      field.onBlur();
    }
  };

  return (
    <>
      <button
        {...buttonProps}
        className={classNames(
          'c-button c-button--white c-button--option c-button--full c-button--option-drag u-mb-spacer',
          {
            'is-invalid': typeof fieldState.error !== 'undefined',
          }
        )}
        onClick={(event) => {
          event.preventDefault();
          setModalState(typeof field.value?.content !== 'undefined' ? 'details' : 'choose');
        }}
        type="button"
      >
        <span>
          <small>{title}</small>
          {field.value && typeof field.value.content !== 'undefined' ? (
            truncate(field.value.settings?.title || field.value.content?.title || '', 20)
          ) : (
            <>
              Add <Icon name="add-thin" className="o-svg-icon" />
            </>
          )}
        </span>
        <Icon name="pencil" className="o-svg-icon o-svg-large u-ml-auto" />
      </button>
      <DisplayFieldErrors errors={fieldState.error} />
      <Modal
        isOpen={modalState !== 'closed'}
        onRequestClose={onClose}
        style={{
          content: {
            maxWidth:
              modalState === 'choose' || modalState === 'library' || modalState === 'image-library'
                ? '80rem'
                : '41.25rem',
          },
        }}
      >
        <div
          className={classNames('c-modal', { 'c-modal--dark c-color--invert': modalState === 'image-edit' })}
          style={{ width: '100%' }}
        >
          {/*eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */}
          <a
            href="#"
            className="c-modal-close"
            onClick={(event) => {
              event.preventDefault();
              onClose();
            }}
          />

          <div className="c-modal__main">
            {modalState !== 'closed' && (
              <ProductBlockModalContent
                field={field}
                modalState={modalState}
                onChange={onChange}
                onInsert={onInsert}
                setModalState={setModalState}
                title={title}
                setIsFormDirty={setIsFormDirty}
                document_settings={document_settings}
              />
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export const SortableProductBlockField: React.VFC<ProductBlockFieldProps & { id: string }> = ({ id, ...props }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <ProductBlockField
      {...props}
      buttonProps={{ ref: setNodeRef, style, ...attributes, ...listeners, ...props.buttonProps }}
    />
  );
};

export default ProductBlockField;
