interface WordPressData {
  ajaxUrl: string;
  stylesheetDirectoryUri: string;
  themeAssetsUrl: string;
  productsUrl: string;
  myAccountUrl: string;
  siteUrl: string;
  isSearch: boolean;
  finishIcons: Record<string, string>;
  visitorCountryCode: 'US' | 'CA';
  algolia: {
    appId: string;
    productsIndex: string;
    documentsIndex: string;
    searchOnlyApiKey: string;
  };
  productSearch: {
    availabilityTooltip: string;
    businessSegmentsTooltip: string;
  };
  productConfigurator: {
    showerPage: string;
  };
  googleMapsApiKey: string;
}

interface WindowWithWordPressData extends Window {
  symmons: WordPressData;
}

const wordpressData = (window as unknown as WindowWithWordPressData).symmons;

export default wordpressData;
