import * as React from 'react';
import { observer } from 'mobx-react';
import { RefinementList } from 'react-instantsearch-dom';

import DocumentSearchStore from 'search/stores/document-search-store';

interface DocumentTypeFilterProps {
  store: DocumentSearchStore;
}

const DocumentTypeFilter = observer(
  class DocumentTypeFilter extends React.Component<DocumentTypeFilterProps> {
    private containerRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
      const { store } = this.props;
      const { documentTypeFilterOpened: opened } = store;
      if (!opened && this.containerRef.current) {
        this.containerRef.current.style.display = 'none';
      }
    }

    render() {
      const { store } = this.props;
      const { documentTypeFilterOpened: opened } = store;
      const classes = ['c-filters__group', opened ? 'is-open' : ''];
      return (
        <>
          <div className={classes.join(' ')}>
            <div
              className="c-filters__header c-accordion-toggle"
              onClick={() => {
                store.setDocumentTypeFilterOpened(!opened);
                if (this.containerRef.current) {
                  jQuery(this.containerRef.current).stop(true).slideToggle();
                }
              }}
            >
              <p className="c-filters__title">Document Type</p>
            </div>
            <div className="c-filters__container c-accordion-container" ref={this.containerRef}>
              <RefinementList attribute="types" limit={100} />
            </div>
          </div>
        </>
      );
    }
  }
);

export default DocumentTypeFilter;
