import * as React from 'react';
import { useQueryClient } from 'react-query';

import { RegisteredUser, SentQuoteStatusEnum } from '__generated-api__';
import api from 'api';
import { AuthStatus, useAuth } from 'auth';
import Form from 'components/Form/Form';
import InputField from 'components/Form/InputField';
import SelectField from 'components/Form/SelectField';
import SubmitButton from 'components/Form/SubmitButton';
import {
  PersistQuotePersistValues,
  cleanPendingQuotePersistedValues,
  getPendingQuotePersistedValues,
} from 'my-account/components/PendingQuote/PersistValues';
import { usePendingQuote } from 'my-account/components/PendingQuote/context';
import { IProjectQuoteRequestSchema, ProjectQuoteRequestSchema } from 'my-account/components/PendingQuote/schema';
import { useToast } from 'my-account/toast';
import { getObjectKeys } from 'my-account/utils/object';
import { caProvinces, usStates } from 'my-account/utils/states';

export const RequestProjectQuoteReviewProducts: React.VFC = () => {
  const { quote, openModal, canAddToQuote } = usePendingQuote();
  const auth = useAuth();
  let user: RegisteredUser | undefined;
  if (auth.status === AuthStatus.LoggedIn) {
    user = auth.currentUser;
  }
  if (!canAddToQuote) {
    return null;
  }

  const productsCount =
    typeof quote !== 'undefined' && typeof quote.products !== 'undefined' && quote.products.length > 0
      ? quote.products.reduce((total, product) => (total += product.pivot.quantity), 0)
      : 0;

  if (
    typeof quote === 'undefined' ||
    quote.status === SentQuoteStatusEnum.Sent ||
    typeof quote.products === 'undefined' ||
    !productsCount
  ) {
    return null;
  }

  return (
    <p>
      {user?.direct_buyer ? (
        <>
          You have {productsCount} product{productsCount !== 1 && 's'} pending in your order.
        </>
      ) : (
        <>
          You have {productsCount} product{productsCount !== 1 && 's'} pending in your project quote.
        </>
      )}
      <br />
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        href="#"
        onClick={(event) => {
          event.preventDefault();
          openModal({ hasConfirmation: false });
        }}
      >
        Review them
      </a>{' '}
      or continue by filling this form before sending.
    </p>
  );
};

export interface RequestProjectQuoteFormProps {
  className?: string;
  onSubmit?: () => void;
  renderFooter?: (submitButton: React.ReactNode) => React.ReactNode;
  products?: IProjectQuoteRequestSchema['products'];
}

export const RequestProjectQuoteForm: React.VFC<RequestProjectQuoteFormProps> = ({
  className,
  onSubmit,
  renderFooter,
  products,
}) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { requestQuote, isLoading, canCreateQuote } = usePendingQuote();
  const auth = useAuth();
  let user: RegisteredUser | undefined;
  if (auth.status === AuthStatus.LoggedIn) {
    user = auth.currentUser;
  }
  if (!canCreateQuote) {
    return null;
  }

  return (
    <Form
      schema={ProjectQuoteRequestSchema}
      initialValues={Object.assign({ state: '' }, getPendingQuotePersistedValues())}
      onSubmit={async (data, ctx) => {
        await requestQuote(typeof products !== 'undefined' ? { ...data, products } : data);
        toast.notify({
          title: user?.direct_buyer ? 'Order Sent' : 'Quote Requested!',
          type: 'success',
          message: user?.direct_buyer
            ? "We've received your order and we'll contact you via email soon! (Be sure to check your junk/spam folder just in case!)"
            : "We've received your quote and we'll contact you via email soon! (Be sure to check your junk/spam folder just in case!)",
        });
        onSubmit && onSubmit();
        await Promise.all([
          queryClient
            .cancelQueries(api.quote.listQuotes.getQueryKey()[0])
            .then(() => queryClient.invalidateQueries(api.quote.listQuotes.getQueryKey()[0])),
          queryClient
            .cancelQueries(api.quote.getCurrentQuote.getQueryKey()[0])
            .then(() => queryClient.invalidateQueries(api.quote.getCurrentQuote.getQueryKey()[0])),
        ]);
        cleanPendingQuotePersistedValues();
        ctx.reset();
      }}
      className={className}
      hasFloatingLabels
    >
      <PersistQuotePersistValues />
      <div className="o-row o-row--small-gutters">
        <div className="o-col-12">
          <InputField name="project_name" label="Project Name" elStyle="fill" />
        </div>
        <div className="o-col-6@sm">
          <InputField name="city" label="City" elStyle="fill" />
        </div>
        <div className="o-col-6@sm">
          <SelectField name="state" label="State/Province" elStyle="fill">
            <optgroup label="United States">
              {getObjectKeys(usStates).map((state) => (
                <option key={usStates[state]} value={usStates[state]}>
                  {state}
                </option>
              ))}
            </optgroup>
            <optgroup label="Canada">
              {getObjectKeys(caProvinces).map((state) => (
                <option key={caProvinces[state]} value={caProvinces[state]}>
                  {state}
                </option>
              ))}
            </optgroup>
          </SelectField>
        </div>
        <div className="o-col-12">
          <InputField name="message" label="Message" type="textarea" elStyle="fill" />
        </div>
        <div className="o-col-12 u-mb-spacer-base-small">
          <InputField name="files" type="file" elStyle="fill" inputProps={{ multiple: true }} />
        </div>
        <div className="o-col-12">
          {typeof renderFooter === 'undefined' ? (
            <SubmitButton variant="secondary" isLoading={isLoading} isFull>
              {user?.direct_buyer ? 'Submit Order' : 'Send request'}
            </SubmitButton>
          ) : (
            renderFooter(
              <SubmitButton variant="secondary" isLoading={isLoading} isFull>
                {user?.direct_buyer ? 'Submit Order' : 'Send request'}
              </SubmitButton>
            )
          )}
        </div>
      </div>
    </Form>
  );
};
