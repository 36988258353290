import * as React from 'react';
import { Text, View, Link } from '@react-pdf/renderer';

export interface FooterProps {
  client: {
    name: string;
    address?: string;
  };
  effective_date: string;
  prepared_by?: {
    name: string;
    email: string;
    phone?: string;
    mobile?: string;
  };
  page_number?: number;
  quote_number?: string;
  pricing_footer_note?: string;
}

const PricingFooterNote: React.VFC<{ pricing_footer_note: string }> = ({ pricing_footer_note }) => {
  const [note, label] = pricing_footer_note.split(':', 2).reverse();

  if (!note && !label) {
    return null;
  }

  return (
    <>
      <View style={{ flexShrink: 0, marginTop: 2 }}>
        <Text
          style={{
            fontFamily: 'Gotham Rounded',
            fontSize: 5,
            color: '#666666',
            opacity: 0.6,
            textTransform: 'uppercase',
            flexShrink: 0,
            paddingHorizontal: 9,
          }}
        >
          |
        </Text>
      </View>
      <View style={{ display: 'flex', flexDirection: 'row', flexShrink: 0, marginTop: 2 }}>
        {Boolean(label) && (
          <Text
            style={{
              fontFamily: 'Gotham Rounded',
              fontSize: 5,
              color: '#666666',
              opacity: 0.6,
              textTransform: 'uppercase',
              flexShrink: 0,
            }}
          >
            {label}:
          </Text>
        )}
        {Boolean(note) && (
          <Text
            style={{
              fontFamily: 'Gotham Rounded',
              fontWeight: 600,
              fontSize: 5,
              color: '#666666',
              opacity: 0.6,
              textTransform: 'uppercase',
              marginLeft: 9,
              flexShrink: 0,
            }}
          >
            {note}
          </Text>
        )}
      </View>
    </>
  );
};

const Footer: React.VFC<FooterProps> = ({
  client,
  effective_date,
  prepared_by,
  page_number,
  quote_number,
  pricing_footer_note,
}) => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: 80,
        flexGrow: 0,
        flexShrink: 0,
      }}
      fixed
    >
      <View style={{ width: 30, flexGrow: 0, flexShrink: 0 }}>
        <View style={{ width: '100%', height: 50, backgroundColor: '#F4F4F4' }} />
        <Text
          render={(props) => `${typeof page_number !== 'undefined' ? page_number : props.pageNumber}`}
          style={{
            fontFamily: 'Gotham Rounded',
            fontSize: 8,
            fontWeight: 600,
            color: '#666666',
            opacity: 0.6,
            width: '100%',
            textAlign: 'right',
            marginTop: 6,
          }}
        />
      </View>
      <View style={{ paddingHorizontal: 15, paddingVertical: 8, flexGrow: 1 }}>
        <Text
          style={{
            fontFamily: 'Gotham Rounded',
            fontWeight: 600,
            fontSize: 14,
            color: '#000000',
            opacity: 0.8,
            flexShrink: 0,
            lineHeight: 1,
            paddingBottom: 5,
          }}
        >
          {client.name}
        </Text>
        {typeof client.address !== 'undefined' && Boolean(client.address) && (
          <Text style={{ fontFamily: 'Gotham Rounded', fontSize: 7, color: '#666666', lineHeight: 1.28571429 }}>
            {client.address.replace(/\r\n/g, '\n')}
          </Text>
        )}
        <View style={{ display: 'flex', flexDirection: 'row', flexShrink: 0, flexWrap: 'wrap', marginTop: 5 }}>
          <View style={{ display: 'flex', flexDirection: 'row', marginTop: 2 }}>
            <Text
              style={{
                fontFamily: 'Gotham Rounded',
                fontSize: 5,
                color: '#666666',
                opacity: 0.6,
                textTransform: 'uppercase',
                flexShrink: 0,
              }}
            >
              Effective date:
            </Text>
            <Text
              style={{
                fontFamily: 'Gotham Rounded',
                fontWeight: 600,
                fontSize: 5,
                color: '#666666',
                opacity: 0.6,
                textTransform: 'uppercase',
                paddingLeft: 9,
                flexShrink: 0,
              }}
            >
              {effective_date}
            </Text>
          </View>
          {typeof quote_number !== 'undefined' && Boolean(quote_number) && (
            <>
              <View style={{ flexShrink: 0, marginTop: 2 }}>
                <Text
                  style={{
                    fontFamily: 'Gotham Rounded',
                    fontSize: 5,
                    color: '#666666',
                    opacity: 0.6,
                    textTransform: 'uppercase',
                    flexShrink: 0,
                    paddingHorizontal: 9,
                  }}
                >
                  |
                </Text>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row', flexShrink: 0, marginTop: 2 }}>
                <Text
                  style={{
                    fontFamily: 'Gotham Rounded',
                    fontSize: 5,
                    color: '#666666',
                    opacity: 0.6,
                    textTransform: 'uppercase',
                    flexShrink: 0,
                  }}
                >
                  Quote #:
                </Text>
                <Text
                  style={{
                    fontFamily: 'Gotham Rounded',
                    fontWeight: 600,
                    fontSize: 5,
                    color: '#666666',
                    opacity: 0.6,
                    textTransform: 'uppercase',
                    paddingLeft: 9,
                    flexShrink: 0,
                  }}
                >
                  {quote_number}
                </Text>
              </View>
            </>
          )}
          {typeof pricing_footer_note !== 'undefined' && (
            <PricingFooterNote pricing_footer_note={pricing_footer_note} />
          )}
        </View>
      </View>
      {typeof prepared_by !== 'undefined' && (
        <View
          style={{
            width: 190,
            height: '100%',
            backgroundColor: '#F4F4F4',
            marginLeft: 'auto',
            paddingHorizontal: 15,
            paddingVertical: 17,
            flexShrink: 0,
          }}
        >
          <Text
            style={{
              fontFamily: 'Gotham Rounded',
              fontSize: 5,
              color: '#666666',
              opacity: 0.6,
              textTransform: 'uppercase',
            }}
          >
            Prepared by:
          </Text>
          <Text
            style={{
              fontFamily: 'Gotham Rounded',
              fontWeight: 600,
              fontSize: 8,
              color: '#000000',
              opacity: 0.8,
              marginTop: 4,
            }}
          >
            {prepared_by.name}
          </Text>
          <Text style={{ fontFamily: 'Gotham Rounded', fontSize: 7, color: '#666666', lineHeight: 1.28571429 }}>
            {prepared_by.email}
          </Text>
          {Boolean(prepared_by.phone) && (
            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Text
                style={{
                  fontFamily: 'Gotham Rounded',
                  fontSize: 5,
                  color: '#666666',
                  opacity: 0.6,
                }}
              >
                P:
              </Text>
              <Link
                src={`tel:${prepared_by.phone}`}
                style={{
                  fontFamily: 'Gotham Rounded',
                  fontSize: 7,
                  color: '#666666',
                  marginLeft: 2,
                  textDecoration: 'none',
                }}
              >
                {prepared_by.phone}
              </Link>
            </View>
          )}
          {Boolean(prepared_by.mobile) && (
            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Text
                style={{
                  fontFamily: 'Gotham Rounded',
                  fontSize: 5,
                  color: '#666666',
                  opacity: 0.6,
                }}
              >
                M:
              </Text>
              <Link
                src={`tel:${prepared_by.mobile}`}
                style={{
                  fontFamily: 'Gotham Rounded',
                  fontSize: 7,
                  color: '#666666',
                  marginLeft: 2,
                  textDecoration: 'none',
                }}
              >
                {prepared_by.mobile}
              </Link>
            </View>
          )}
        </View>
      )}
    </View>
  );
};

export default Footer;
