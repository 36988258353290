import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';

import api from 'api';
import { useQuery } from 'hooks/query';
import Icon from 'components/icon';
import { MainHero } from 'my-account/components/MainHero';
import { EditProjectForm } from 'my-account/forms/Project';
import useDocumentTitle from 'hooks/useDocumentTitle';

export default function SpecificationPackageCreatePage() {
  useDocumentTitle('Add Project');
  const location = useLocation();
  const searchParams = React.useMemo(() => new URLSearchParams(location.search), [location.search]);
  const [customer, customerQuery] = useQuery(
    api.customers.getSalesforceCustomer,
    {
      id: searchParams.has('customer_id') ? Number(searchParams.get('customer_id')) : 0,
    },
    { enabled: searchParams.has('customer_id') }
  );
  const [opportunity, opportunityQuery] = useQuery(
    api.opportunities.getSalesforceOpportunity,
    {
      id: searchParams.has('opportunity_id') ? Number(searchParams.get('opportunity_id')) : 0,
    },
    { enabled: searchParams.has('opportunity_id') }
  );

  return (
    <>
      <MainHero />

      <section className="c-block c-block--hero">
        <div className="o-container-fluid">
          <div className="o-row u-flex-grow u-items-end">
            <div className="o-col-12">
              <Link
                to="/specification-packages"
                className="c-link-cta-basic c-link-cta--small c-block__get-back u-mt-spacer-base-large u-mb-spacer-base-small"
              >
                <Icon name="arrow" className="o-svg-icon o-svg-left" />
                <span>Back to specification packages</span>
              </Link>
              <h1 className="c-title--medium u-mb-0">Add Project</h1>
            </div>
          </div>
        </div>
        <div className="c-block__pattern c-block__pattern--light3 c-block__pattern--top3 c-block__overlay--opacity-20">
          <Icon name="pattern" className="o-svg-icon" />
        </div>
      </section>

      {customerQuery.isLoading || opportunityQuery.isLoading ? (
        <div className="c-listing__none">
          <div className="c-listing__none-spinner"></div>
          <p className="c-listing__none-title">Loading...</p>
        </div>
      ) : (
        <EditProjectForm
          project={undefined}
          projectVersion={undefined}
          defaultValues={{
            customer: customerQuery.isSuccess && typeof customer !== 'undefined' ? { ...customer.data } : undefined,
            opportunity:
              opportunityQuery.isSuccess && typeof opportunity !== 'undefined' ? { ...opportunity.data } : undefined,
          }}
        />
      )}
    </>
  );
}
