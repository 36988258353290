import * as React from 'react';
import { connectPagination } from 'react-instantsearch-dom';
import _range from 'lodash/range';

import SpriteIcon from 'search/components/sprite-icon';

interface CustomPaginationProps {
  /**
   * This is not zero based, it is 1 both when there are no results and when there are results.
   */
  currentRefinement: number;

  nbPages: number;
  refine: (value: number) => void;
  createURL: (value: number) => string;

  scrollToSelector: string;
}

class CustomPagination extends React.Component<CustomPaginationProps> {
  constructor(props: CustomPaginationProps) {
    super(props);
    this.scrollToTop = this.scrollToTop.bind(this);
  }

  scrollToTop() {
    const { scrollToSelector } = this.props;
    const offset = jQuery(scrollToSelector).offset() as JQueryCoordinates;
    if (jQuery(window).scrollTop() ?? 0 > offset.top) {
      // only scroll if the top of product search is not visible
      jQuery('html, body').animate({ scrollTop: offset.top }, 500);
    }
  }

  render() {
    const { currentRefinement, nbPages, refine, createURL } = this.props;

    // console.log('CustomPagination::render::currentRefinement=', currentRefinement);
    // console.log('CustomPagination::render::nbPages=', nbPages);

    const pagesInMiddle = _range(
      currentRefinement - 3 >= 1 ? currentRefinement - 3 : 1,
      (currentRefinement + 3 <= nbPages ? currentRefinement + 3 : nbPages || 1) + 1
    );

    // console.log('CustomPagination::render::pagesInMiddle=', pagesInMiddle);

    // console.log( 'CustomPagination::render::store=' );
    // console.log( store );

    let items: (number | string)[] = [];

    if (pagesInMiddle[0] !== 1) {
      items.push(1);
    }

    if (pagesInMiddle[0] > 2) {
      items.push('...');
    }

    items = [...items, ...pagesInMiddle];

    if (pagesInMiddle[pagesInMiddle.length - 1] < (nbPages || 1) - 1) {
      items.push('...');
    }

    if (pagesInMiddle[pagesInMiddle.length - 1] !== (nbPages || 1)) {
      items.push(nbPages);
    }

    return (
      <>
        {nbPages > 1 && (
          <div className="ais-Pagination">
            <ul className="ais-Pagination-list">
              <li
                className={[
                  'ais-Pagination-item',
                  'ais-Pagination-item--previousPage',
                  currentRefinement === 1 ? 'ais-Pagination-item--disabled' : '',
                ].join(' ')}
              >
                {currentRefinement === 1 ? (
                  <span aria-label="Previous" className="ais-Pagination-link">
                    <SpriteIcon name="chevron" classes={['o-svg-icon', 'o-svg-left']} />
                    Previous
                  </span>
                ) : (
                  <a
                    aria-label="Previous"
                    className="ais-Pagination-link"
                    href={createURL(currentRefinement - 1)}
                    onClick={(event) => {
                      event.preventDefault();
                      refine(currentRefinement - 1);
                      this.scrollToTop();
                    }}
                  >
                    <SpriteIcon name="chevron" classes={['o-svg-icon', 'o-svg-left']} />
                    Previous
                  </a>
                )}
              </li>

              {items.map((item, index) => {
                const classes = [
                  'ais-Pagination-item',
                  item === currentRefinement ? 'ais-Pagination-item--selected' : '',
                ];

                return (
                  <li key={index} className={classes.join(' ')}>
                    {typeof item === 'number' ? (
                      <a
                        href={createURL(item)}
                        className="ais-Pagination-link"
                        onClick={(event) => {
                          event.preventDefault();
                          refine(item);
                          this.scrollToTop();
                        }}
                      >
                        {' '}
                        {item}{' '}
                      </a>
                    ) : (
                      <span>...</span>
                    )}
                  </li>
                );
              })}

              <li
                className={[
                  'ais-Pagination-item',
                  'ais-Pagination-item--nextPage',
                  currentRefinement === (nbPages || 1) ? 'ais-Pagination-item--disabled' : '',
                ].join(' ')}
              >
                {currentRefinement === (nbPages || 1) ? (
                  <span aria-label="Previous" className="ais-Pagination-link">
                    Next
                    <SpriteIcon name="chevron" classes={['o-svg-icon', 'o-svg-right']} />
                  </span>
                ) : (
                  <a
                    aria-label="Previous"
                    className="ais-Pagination-link"
                    href={createURL(currentRefinement + 1)}
                    onClick={(event) => {
                      event.preventDefault();
                      refine(currentRefinement + 1);
                      this.scrollToTop();
                    }}
                  >
                    Next
                    <SpriteIcon name="chevron" classes={['o-svg-icon', 'o-svg-right']} />
                  </a>
                )}
              </li>
            </ul>
          </div>
        )}
      </>
    );
  }
}

const Pagination = connectPagination(CustomPagination);

export default Pagination;
