import classNames from 'classnames';
import * as React from 'react';

import Icon from 'components/icon';

export enum OffcanvasStaticKeys {
  Filters = 'filters',
}

export interface OffcanvasContextValue {
  activeOffcanvas: string | OffcanvasStaticKeys | undefined;
  open(offcanvasKey: string): void;
  close(offcanvasKey: string): void;
  toggle(offcanvasKey: string): void;
  closeAll(): void;
}

const offcanvasContext = React.createContext<OffcanvasContextValue>({
  activeOffcanvas: undefined,
  open() {},
  close() {},
  toggle() {},
  closeAll() {},
});

export const OffcanvasProvider: React.FC = ({ children }) => {
  const Provider = offcanvasContext.Provider;
  const [activeOffcanvas, setActiveOffcanvas] = React.useState<OffcanvasContextValue['activeOffcanvas']>(undefined);

  const open = React.useCallback((offcanvasKey: string) => {
    setActiveOffcanvas(offcanvasKey);
  }, []);

  const close = React.useCallback((offcanvasKey: string) => {
    setActiveOffcanvas((current) => {
      if (current === offcanvasKey) {
        return undefined;
      }

      return current;
    });
  }, []);

  const closeAll = React.useCallback(() => {
    setActiveOffcanvas(undefined);
  }, []);

  const toggle = React.useCallback((offcanvasKey: string) => {
    setActiveOffcanvas((current) => {
      if (current === offcanvasKey) {
        return undefined;
      }

      return offcanvasKey;
    });
  }, []);

  React.useEffect(() => {
    if (activeOffcanvas && activeOffcanvas !== OffcanvasStaticKeys.Filters) {
      jQuery('body').addClass('ra-freeze');
    } else {
      jQuery('body').removeClass('ra-freeze');
    }
  }, [activeOffcanvas]);

  return <Provider value={{ activeOffcanvas, open, close, toggle, closeAll }}>{children}</Provider>;
};

export const useOffcanvas = () => {
  return React.useContext(offcanvasContext);
};

export interface OffcanvasProps {
  offcanvasKey: Exclude<OffcanvasContextValue['activeOffcanvas'], undefined>;
  title: string;
}

export const Offcanvas: React.FC<OffcanvasProps> = ({ offcanvasKey, title, children }) => {
  const { activeOffcanvas, toggle } = useOffcanvas();

  return (
    <>
      <div
        className={classNames('c-offcanvas', 'c-offcanvas--popup', 'c-offcanvas--site-nav', {
          'is-active': activeOffcanvas === offcanvasKey,
        })}
      >
        <div className="c-offcanvas__inner">
          <span
            className={classNames('c-offcanvas__toggle', { 'is-active': activeOffcanvas === offcanvasKey })}
            onClick={(event) => {
              event.preventDefault();

              toggle(offcanvasKey);
            }}
          >
            <Icon name="close-thin" className="o-svg-icon" />
          </span>
          <div className="c-offcanvas__header c-offcanvas__header--sm">
            <span className="c-offcanvas__title">{title}</span>
          </div>
          <div className="c-offcanvas__body">{children}</div>
        </div>
      </div>
      <div
        className={classNames('c-offcanvas__overlay', 'is-transparent', {
          'is-active': activeOffcanvas === offcanvasKey,
        })}
        onClick={(event) => {
          event.preventDefault();

          toggle(offcanvasKey);
        }}
      ></div>
    </>
  );
};
