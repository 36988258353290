import { FileModelImage, ImageBlockSettings } from '__generated-api__';

type PartialAndNullable<T> = {
  [P in keyof T]?: T[P] | null;
};

export interface UseCroppedImageProps {
  image: PartialAndNullable<Pick<FileModelImage, 'name' | 'image' | 'width' | 'height'>>;
  settings?: ImageBlockSettings;
}

export class CroppedImageCancelled extends Error {
  name = 'CroppedImageCancelled';
  constructor() {
    super('CroppedImageCancelled');
  }
}

export const isCroppedImageCancelled = (val: unknown): val is CroppedImageCancelled => {
  return val instanceof Error && val.name === 'CroppedImageCancelled';
};

export type GetCroppedImage = (
  props: UseCroppedImageProps
) => Promise<string | { data: Buffer; format: 'png' | 'jpg' }> & { cancel: () => void };

export type GetCroppedImageSize = (props: UseCroppedImageProps) => { width: number; height: number };

export const getCroppedImageSize: GetCroppedImageSize = ({ image, settings }) => {
  if (
    !settings ||
    (settings.left === 0 &&
      settings.top === 0 &&
      settings.width === image.width &&
      settings.height === image.height &&
      settings.rotate === 0 &&
      settings.flip === 0)
  ) {
    return { width: image.width ?? 0, height: image.height ?? 0 };
  }

  if (settings.rotate === 0 || settings.rotate === 180) {
    return { width: settings.width, height: settings.height };
  }

  return { width: settings.height, height: settings.width };
};
