import * as React from 'react';
import { useFieldArray } from 'react-hook-form';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers';

import { ImageBlockType } from '__generated-api__';
import { getCounter } from 'my-account/utils/counter';
import { CoverPageDefaultValue } from 'my-account/validations/project';
import { SortableImageBlockField } from 'my-account/components/SPB/ImageBlockField';

const getLocalBlockId = getCounter(0, 'cover_page_block_');

const CoverPageFields: React.VFC<{ currentPagePreview: number }> = ({ currentPagePreview }) => {
  const {
    fields: blocks,
    append,
    move,
  } = useFieldArray<CoverPageDefaultValue, 'blocks'>({
    name: `latest.pages.${currentPagePreview}.blocks` as unknown as 'blocks',
  });

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
      onActivation: ({ event }) => {
        event.preventDefault();
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const activeItemIndex = blocks.findIndex((block) => block.id === active.id);
      const overItemIndex = blocks.findIndex((block) => block.id === over.id);
      move(activeItemIndex, overItemIndex);
    }
  }

  React.useEffect(() => {
    if (blocks.length < 3) {
      for (let i = 0; i < 3 - blocks.length; i++) {
        append({
          id: getLocalBlockId(),
          type: ImageBlockType.Image,
        });
      }
    }
  }, [append, blocks.length]);

  return (
    <div className="u-mb-spacer-base-large">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      >
        <SortableContext items={blocks} strategy={verticalListSortingStrategy}>
          {blocks.map((block, index) => (
            <SortableImageBlockField
              key={block.id}
              id={block.id}
              name={`latest.pages.${currentPagePreview}.blocks.${index}`}
              title={`Image ${index + 1}`}
            />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default CoverPageFields;
