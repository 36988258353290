import * as React from 'react';
import wordpressData from 'data';
import { ProductHit, ProductHitInterface } from 'search/components/product-search/product-hit';
import { Hit } from 'react-instantsearch-core';
import axios from 'axios';
import { VariantAffectingFilters } from 'search/stores/product-search-store';

interface RelatedProductsProps {
  title: string;
  relation: 'recommended_valves';
  sku?: string;
  onSelect?: (sku: string, hit: Hit<ProductHitInterface>) => any;
  onHitLoad?: (hit: Hit<ProductHitInterface>) => any;
  variantAffectingFilters?: VariantAffectingFilters;
}

const RelatedProducts: React.FC<RelatedProductsProps> = ({
  title,
  relation,
  sku,
  variantAffectingFilters,
  onSelect,
  onHitLoad,
}) => {
  const [hits, setHits] = React.useState<Hit<ProductHitInterface>[]>([]);
  React.useEffect(() => {
    axios
      .get<{ [key: string]: Hit<ProductHitInterface> }>(wordpressData.ajaxUrl, {
        params: { relation, sku, action: 'symmons_related_product_hits_form_wordpress_by_sku' },
      })
      .then((response) => {
        const loaded_hits = Object.values(response.data) as Hit<ProductHitInterface>[];
        if (typeof onHitLoad !== 'undefined') {
          loaded_hits.forEach(onHitLoad);
        }
        setHits(loaded_hits);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sku]);

  return hits?.length ? (
    <section className="c-block c-block--spacing-t-small c-block--spacing-t@md c-block--spacing-b-small c-block--spacing-b@md">
      <div className="o-container o-container-fluid">
        <div className="o-row">
          <div className="o-col-10@md o-col-9@lg @xlo-col-8@xl u-mx-auto@md">
            <h5 className="u-mb-spacer-base-large u-text-center">{title}</h5>
            <ul className="o-stack-1 c-card__row--products">
              {hits.map((hit) => (
                <li key={hit.objectID}>
                  <article className="c-card c-card--product c-card--product-hor@md c-card--link">
                    <ProductHit
                      hit={hit as Hit<ProductHitInterface>}
                      selectProduct={onSelect}
                      variantAffectingFilters={
                        variantAffectingFilters || {
                          query: undefined,
                          availabilities: [],
                          finishes: [],
                          special_attributes: [],
                          is_obsolete: [],
                          business_segments: [],
                        }
                      }
                    />
                  </article>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  ) : (
    <></>
  );
};

export default RelatedProducts;
