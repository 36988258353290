import { z } from 'zod';

import { Countries, caProvinces, usStates } from 'my-account/utils/states';

export const ProjectQuoteRequestSchema = z.object({
  project_name: z.string().nonempty(),
  city: z.string().nonempty(),
  state: z
    .nativeEnum(usStates)
    .or(z.nativeEnum(caProvinces))
    .or(z.literal(''))
    .refine((val) => val !== '', 'Required'),
  message: z.string().nonempty(),
  files: z.instanceof(window.FileList).optional(),
  products: z
    .array(
      z.union([z.object({ id: z.number(), quantity: z.number() }), z.object({ sku: z.string(), quantity: z.number() })])
    )
    .optional(),
});

export type IProjectQuoteRequestSchema = z.infer<typeof ProjectQuoteRequestSchema>;

export const ProjectOrderRequestSchema = z.object({
  project_name: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  location_name: z.string().optional(),
  address: z.string(),
  address2: z.string().optional(),
  city: z.string(),
  state: z
    .nativeEnum(usStates)
    .or(z.nativeEnum(caProvinces))
    .or(z.literal(''))
    .refine((val) => val !== '', 'Required'),
  postal_code: z.string(),
  phone: z.string().optional(),
  country: z
    .nativeEnum(Countries)
    .or(z.literal(''))
    .refine((val) => val !== '', 'Required'),
  message: z.string().min(1),
  files: z.instanceof(window.FileList).optional(),
  products: z
    .array(
      z.union([z.object({ id: z.number(), quantity: z.number() }), z.object({ sku: z.string(), quantity: z.number() })])
    )
    .optional(),
});

export type IProjectOrderRequestSchema = z.infer<typeof ProjectOrderRequestSchema>;
