import { observer } from 'mobx-react';
import * as React from 'react';
import { RefinementList } from 'react-instantsearch-dom';

import ProductSearchStore from 'search/stores/product-search-store';
import SpriteIcon from '../sprite-icon';

interface AccessoriesFilterProps {
  store: ProductSearchStore;
}

const AccessoriesFilter = observer(
  class AccessoriesFilter extends React.Component<AccessoriesFilterProps> {
    private containerRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
      const { store } = this.props;
      const { accessoriesFilterOpened: opened } = store;
      if (!opened && this.containerRef.current) {
        this.containerRef.current.style.display = 'none';
      }
    }

    render() {
      const { store } = this.props;
      const { accessoriesFilterOpened: opened } = store;
      const classes = ['c-filters__group', opened ? 'is-open' : ''];
      return (
        <>
          <div className={classes.join(' ')}>
            <div
              className="c-filters__header c-accordion-toggle"
              onClick={() => {
                store.setAccessoriesFilterOpened(!opened);
                if (this.containerRef.current) {
                  jQuery(this.containerRef.current).stop(true).slideToggle();
                }
              }}
            >
              <p className="c-filters__title">Accessories</p>
            </div>
            <div className="c-filters__container c-accordion-container" ref={this.containerRef}>
              <RefinementList
                attribute="accessories"
                limit={5}
                showMore={true}
                showMoreLimit={100}
                translations={{
                  showMore(expanded: boolean) {
                    if (expanded) {
                      return (
                        <>
                          <SpriteIcon name="chevron" classes={['o-svg-icon', 'o-svg-up']} />
                          Show less
                        </>
                      );
                    } else {
                      return (
                        <>
                          <SpriteIcon name="chevron" classes={['o-svg-icon', 'o-svg-down']} />
                          Show more
                        </>
                      );
                    }
                  },
                }}
              />
            </div>
          </div>
        </>
      );
    }
  }
);
export default AccessoriesFilter;
