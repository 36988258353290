import * as React from 'react';
import { z } from 'zod';
import { useController } from 'react-hook-form';

import { ImageBlockType, TextBlockType } from '__generated-api__';
import { EmptyBlock, getLocalBlockId, ImageBlock, TextBlock } from 'my-account/validations/project';
import ImageBlockField from 'my-account/components/SPB/ImageBlockField';
import InputField from 'components/Form/InputField';

const DividerPageFields: React.VFC<{ currentPagePreview: number }> = ({ currentPagePreview }) => {
  const image = useController<{ block: z.infer<typeof ImageBlock> | z.infer<typeof EmptyBlock> | undefined }, 'block'>({
    name: `latest.pages.${currentPagePreview}.blocks.0` as unknown as 'block',
  });
  const text = useController<{ block: z.infer<typeof TextBlock> | z.infer<typeof EmptyBlock> | undefined }, 'block'>({
    name: `latest.pages.${currentPagePreview}.blocks.1` as unknown as 'block',
  });

  React.useEffect(() => {
    if (!image.field.value) {
      image.field.onChange({
        id: getLocalBlockId(),
        type: ImageBlockType.Image,
      });
    }
  }, [image.field]);

  React.useEffect(() => {
    if (!text.field.value) {
      text.field.onChange({
        id: getLocalBlockId(),
        type: TextBlockType.Text,
        content: {
          content: '',
        },
      });
    }
  }, [text.field]);

  if (!image.field.value || !text.field.value) {
    return null;
  }

  return (
    <div className="u-mb-spacer-base-large">
      <div className="u-mb-spacer-base-small">
        <ImageBlockField title="Image" name={`latest.pages.${currentPagePreview}.blocks.0`} />
      </div>
      <InputField label="Title" name={`latest.pages.${currentPagePreview}.blocks.1.content.content`} elStyle="fill" />
    </div>
  );
};

export default DividerPageFields;
