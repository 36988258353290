import { Project, ProjectVersion, ProjectVersionStatus } from '__generated-api__';

export const getProjectVersion = (project?: Project, version?: string) => {
  const versionNumber: 'latest' | number =
    !version || typeof project === 'undefined'
      ? 'latest'
      : project.versions
          .filter((item) => item.id !== project.latest_version_id)
          .find((item) => String(item.id) === version)?.id ?? 'latest';
  const projectVersion =
    typeof project === 'undefined'
      ? undefined
      : versionNumber === 'latest'
      ? project.latest
      : project.versions.find((item) => item.id === versionNumber);

  return { versionNumber, projectVersion };
};

export const isPublishedVersion = (
  projectVersion?: ProjectVersion
): projectVersion is ProjectVersion & {
  status: typeof ProjectVersionStatus.Published | typeof ProjectVersionStatus.Complete;
} =>
  projectVersion?.status === ProjectVersionStatus.Published || projectVersion?.status === ProjectVersionStatus.Complete;
