import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor, restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  horizontalListSortingStrategy,
  sortableKeyboardCoordinates,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import classNames from 'classnames';
import * as React from 'react';
import { useWatch } from 'react-hook-form';
import { Navigation, Swiper as SwiperCore } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Project, UserRelation } from '__generated-api__';
import Icon from 'components/icon';
import {
  ProjectDefaultValuesType,
  ProjectVersionPageDefaultValue,
  ProjectVersionPageEmptyValue,
} from 'my-account/validations/project';
import DocumentPreviewCanvas from './DocumentPreviewCanvas';

const SortablePageNavSlide: React.VFC<{
  pages: ProjectDefaultValuesType['latest']['pages'];
  index: number;
  isSelected: boolean;
  setCurrentPagePreview: React.Dispatch<React.SetStateAction<number>>;
  pagesLength: number;
  removePage: (index: number) => void;
  user?: UserRelation;
  effectiveDate: string;
  isEditable: boolean;
}> = ({
  pages,
  index,
  isSelected,
  setCurrentPagePreview,
  pagesLength,
  removePage,
  user,
  effectiveDate,
  isEditable,
}) => {
  const page = pages[index];
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: String(page.id),
    disabled: !isEditable,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <SwiperSlide className="c-page-menu__item" style={style}>
      <div
        className={classNames('c-page-menu__page', {
          'c-page-menu__page--selected': isSelected,
        })}
        onClick={(event) => {
          event.preventDefault();
          setCurrentPagePreview(index);
        }}
      >
        <DocumentPreviewCanvas
          className="c-page-menu__page-cover"
          pages={pages}
          pageIndex={index}
          user={user}
          effectiveDate={effectiveDate}
          isThumbnail
        />
        <div className="c-page-menu__page-toolbar">
          {pagesLength > 1 && isEditable && (
            <button
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
              type="button"
              {...attributes}
              {...listeners}
              ref={setNodeRef}
            >
              <Icon name="move" />
            </button>
          )}
          {(pagesLength > 1 || index > 0) && isEditable && (
            <button
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();

                if (window.confirm('Are you sure you want to delete this page?')) {
                  removePage(index);
                }
              }}
              type="button"
            >
              <Icon name="trash" />
            </button>
          )}
        </div>
      </div>
      <p className="c-page-menu__page-title">
        {page.name ? page.name : `Page ${index + 1}`}
        <strong>{page.type}</strong>
      </p>
    </SwiperSlide>
  );
};

SortablePageNavSlide.displayName = 'SwiperSlide';

const filterEmptyPage = (
  page: ProjectVersionPageDefaultValue | ProjectVersionPageEmptyValue
): page is ProjectVersionPageDefaultValue => typeof page.type !== 'undefined';

const PagesNav: React.VFC<{
  currentPagePreview: number;
  setCurrentPagePreview: React.Dispatch<React.SetStateAction<number>>;
  user?: Project['user'];
  removePage: (index: number) => void;
  addNewPage: () => void;
  movePage: (from: number, to: number) => void;
  effectiveDate: string;
  isEditable: boolean;
}> = ({
  currentPagePreview,
  setCurrentPagePreview,
  user,
  removePage,
  addNewPage,
  movePage,
  effectiveDate,
  isEditable,
}) => {
  const pages = useWatch<ProjectDefaultValuesType, 'latest.pages'>({ name: 'latest.pages' });
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
      onActivation: ({ event }) => {
        event.preventDefault();
        event.stopPropagation();
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [swiper, setSwiper] = React.useState<SwiperCore | undefined>();
  const prevSliderButtonRef = React.useRef<HTMLButtonElement>(null);
  const nextSliderButtonRef = React.useRef<HTMLButtonElement>(null);

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (typeof pages !== 'undefined' && pages.length && over && active.id !== over.id) {
      const pagesWithStringIds = pages.map((page) => {
        if (typeof page === 'undefined') return undefined;
        return { ...page, id: String(page.id) };
      });
      const activeItemIndex = pagesWithStringIds.findIndex(
        (page) => typeof page !== 'undefined' && page.id === active.id
      );
      const overItemIndex = pagesWithStringIds.findIndex((page) => typeof page !== 'undefined' && page.id === over.id);

      movePage(activeItemIndex, overItemIndex);
    }
  }

  React.useEffect(() => {
    if (swiper && swiper.params.navigation && typeof swiper.params.navigation !== 'boolean') {
      swiper.params.navigation.prevEl = prevSliderButtonRef.current;
      swiper.params.navigation.nextEl = nextSliderButtonRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swiper, prevSliderButtonRef.current, nextSliderButtonRef.current]);

  React.useEffect(() => {
    if (swiper) {
      swiper.slideTo(currentPagePreview);
    }
  }, [swiper, currentPagePreview]);

  return (
    <section className="c-block c-block--spacing-t-extra-small c-block--spacing-b-extra-small c-block--bg-light3">
      <div className="u-mx-spacer-base">
        <div className="o-row">
          <div className="o-col-12 u-px-spacer-section-small@lg">
            <div className="c-page-menu">
              <button className="c-slider__arrow c-slider__arrow--next" type="button" ref={nextSliderButtonRef}>
                <Icon name="chevron" className="c-slider__arrow-thick o-svg-icon" />
              </button>
              <button className="c-slider__arrow c-slider__arrow--prev" type="button" ref={prevSliderButtonRef}>
                <Icon name="chevron" className="c-slider__arrow-thick o-svg-icon" />
              </button>
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToHorizontalAxis, restrictToFirstScrollableAncestor]}
              >
                <SortableContext
                  items={
                    typeof pages !== 'undefined' && pages.length
                      ? pages.filter(filterEmptyPage).map((page) => ({ ...page, id: String(page.id) }))
                      : []
                  }
                  strategy={horizontalListSortingStrategy}
                >
                  <Swiper
                    className="c-page-menu__slider"
                    modules={[Navigation]}
                    navigation={{
                      nextEl: nextSliderButtonRef.current,
                      prevEl: prevSliderButtonRef.current,
                    }}
                    slidesPerView={'auto'}
                    onSwiper={setSwiper}
                    allowTouchMove={false}
                    initialSlide={currentPagePreview}
                  >
                    {typeof pages !== 'undefined' &&
                      pages.length &&
                      pages.map((page, index, pages) => (
                        <SortablePageNavSlide
                          key={page.id}
                          pages={pages}
                          index={index}
                          isSelected={currentPagePreview === index}
                          pagesLength={pages.length}
                          removePage={removePage}
                          setCurrentPagePreview={setCurrentPagePreview}
                          user={user ?? undefined}
                          effectiveDate={effectiveDate}
                          isEditable={isEditable}
                        />
                      ))}
                    {isEditable && (
                      <SwiperSlide className="c-page-menu__item">
                        <div
                          className="c-page-menu__add-page"
                          onClick={(event) => {
                            event.preventDefault();
                            addNewPage();
                          }}
                        >
                          <Icon name="add-thin" />
                        </div>
                      </SwiperSlide>
                    )}
                  </Swiper>
                </SortableContext>
              </DndContext>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PagesNav;
