import * as React from 'react';
import { useFieldArray } from 'react-hook-form';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import chunk from 'lodash/chunk';
import { SetOptional } from 'type-fest';

import { ProductPageType, ProductBlockType } from '__generated-api__';
import { getCounter } from 'my-account/utils/counter';
import { ProductPageDefaultValue, ProjectDefaultValuesType } from 'my-account/validations/project';
import { SortableProductBlockField } from '../ProductBlockField';

const getLocalBlockId = getCounter(0, 'product_page_block_');

const ProductPageFields: React.VFC<{
  currentPagePreview: number;
  addNewPage: (
    page?:
      | SetOptional<ProjectDefaultValuesType['latest']['pages'][0], 'id'>
      | Array<SetOptional<ProjectDefaultValuesType['latest']['pages'][0], 'id'>>,
    index?: number
  ) => void;
}> = ({ currentPagePreview, addNewPage }) => {
  const {
    fields: blocks,
    append,
    move,
    update,
  } = useFieldArray<ProductPageDefaultValue, 'blocks'>({
    name: `latest.pages.${currentPagePreview}.blocks` as unknown as 'blocks',
  });

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
      onActivation: ({ event }) => {
        event.preventDefault();
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const activeItemIndex = blocks.findIndex((block) => block.id === active.id);
      const overItemIndex = blocks.findIndex((block) => block.id === over.id);
      move(activeItemIndex, overItemIndex);
    }
  }

  React.useEffect(() => {
    if (blocks.length < 5) {
      for (let i = 0; i < 5 - blocks.length; i++) {
        append({
          id: getLocalBlockId(),
          type: ProductBlockType.Product,
        });
      }
    }
  }, [append, blocks.length]);

  return (
    <div className="u-mb-spacer-base-large">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      >
        <SortableContext items={blocks} strategy={verticalListSortingStrategy}>
          {blocks.map((block, index) => (
            <SortableProductBlockField
              key={block.id}
              id={block.id}
              name={`latest.pages.${currentPagePreview}.blocks.${index}`}
              title={`Product ${index + 1}`}
              onInsert={(products) => {
                const emptyBlocks = blocks.filter(
                  (emptyBlock) => emptyBlock.id !== block.id && typeof emptyBlock.content === 'undefined'
                );

                if (emptyBlocks.length) {
                  products.slice(0, emptyBlocks.length).forEach((item, index) => {
                    update(
                      blocks.findIndex((item) => item.id === emptyBlocks[index].id),
                      { ...emptyBlocks[index], content: { ...item.product }, settings: { ...item.settings } }
                    );
                  });
                }

                if (products.length > emptyBlocks.length) {
                  addNewPage(
                    chunk(products.slice(emptyBlocks.length), 5).map((pageProducts) => ({
                      type: ProductPageType.Product,
                      blocks: pageProducts.map((item) => {
                        return {
                          id: getLocalBlockId(),
                          type: ProductBlockType.Product,
                          content: { ...item.product },
                          settings: { ...item.settings },
                        };
                      }),
                    })),
                    currentPagePreview + 1
                  );
                }
              }}
            />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default ProductPageFields;
