import { format, lastDayOfYear, parseISO } from 'date-fns/esm';
import omit from 'lodash/omit';
import { ConditionalKeys, SetOptional } from 'type-fest';
import { z } from 'zod';

import {
  CoverPageType,
  CoverPageUpdate,
  CurrencyCode,
  DividerPageType,
  DividerPageUpdate,
  EmptyPageType,
  FileRelation,
  ImageBlock as ImageBlockModel,
  ImageBlockType,
  IntroductionBlockType,
  IntroductionPageType,
  IntroductionPageUpdate,
  NotesPageType,
  NotesPageUpdate,
  PageLayouts,
  ProductBlock as ProductBlockModel,
  // PdfPageType,
  ProductBlockType,
  Product as ProductModel,
  ProductPageType,
  ProductPageUpdate,
  Project,
  ProjectUpdate,
  ProjectVersionStatus,
  ProjectVersionStatusUpdate,
  PropertyVisibility,
  SalsifyDigitalAsset as SalsifyDigitalAssetModel,
  SummaryBlockType,
  SummaryPageType,
  SummaryPageUpdate,
  TaxonomyTerm,
  TextBlock as TextBlockModel,
  TextBlockType,
  TocPageType,
  TocPageUpdate,
} from '__generated-api__';
import { getCounter } from 'my-account/utils/counter';
import {
  filterEmptyImageBlock,
  filterEmptyImageOrTextBlock,
  filterEmptyIntroductionBlock,
  filterEmptyProductBlock,
  filterEmptySummaryBlock,
} from 'spb-pdf/generator';
import { FileImageRelationUpdateSchema, FileRelationUpdateSchema, FileUpdateImageSchema, ImageSettings } from './file';
import { IntroductionModelUpdate } from './introduction';
import { SalsifyDigitalAsset } from './salsify';

export const getLocalPageId = getCounter(0, 'page_');
export const getLocalProductFeatureId = getCounter(0, 'product_feature_');
export const getLocalProductFinishesId = getCounter(0, 'product_finish_');
export const getLocalProductDocumentsId = getCounter(0, 'product_document_');
export const getLocalBlockId = getCounter(0, 'block_');

export const EmptyBlock = z.object({
  id: z.string(),
  type: z
    .literal(ImageBlockType.Image)
    .or(z.literal(ProductBlockType.Product))
    .or(z.literal(IntroductionBlockType.Introduction))
    .or(z.literal(TextBlockType.Text)),
});

export const ImageBlock = z.object({
  id: z.number().or(z.string()),
  type: z.literal(ImageBlockType.Image),
  content: FileUpdateImageSchema.optional(),
  settings: ImageSettings.optional(),
});

export type ImageBlockFieldType = z.infer<typeof ImageBlock>;

export type ImageBlockDefaultValues = DefaultBlockValue<ImageBlockFieldType>;

const TaxonomyTermRelation = z.object({
  id: z.number(),
  term: z.string(),
  type: z.nativeEnum(TaxonomyTerm),
});

const TaxonomyTermRelationConnect = z.object({
  id: z.number().or(z.string()),
  term: z.string(),
  type: z.nativeEnum(TaxonomyTerm),
});

export const Product = z.object({
  id: z.number(),
  sku: z.string(),
  title: z.string(),
  price: z.number().optional().nullable(),
  main_image: SalsifyDigitalAsset.optional().nullable(),
  installation_guide: SalsifyDigitalAsset.optional().nullable(),
  parts_diagram: SalsifyDigitalAsset.optional().nullable(),
  spec_sheet: SalsifyDigitalAsset.optional().nullable(),
  non_featured_images: SalsifyDigitalAsset.optional().nullable(),
  a_and_d: SalsifyDigitalAsset.optional().nullable(),
  '3d_cad_file_rfa': SalsifyDigitalAsset.optional().nullable(),
  product_sheet: SalsifyDigitalAsset.optional().nullable(),
  product_brochure: SalsifyDigitalAsset.optional().nullable(),
  warranty: SalsifyDigitalAsset.optional().nullable(),
  features: z.array(z.string()).optional().nullable(),
  categories: z.array(TaxonomyTermRelation).optional().nullable(),
  finishes: z.array(TaxonomyTermRelation).optional().nullable(),
  tiers: z.array(TaxonomyTermRelation).optional().nullable(),
  types: z.array(TaxonomyTermRelation).optional().nullable(),
  obsolete: z.boolean().optional().nullable(),
  modified: z.boolean().optional().nullable(),
  ready_for_website: z.boolean().optional().nullable(),
  assist_products: z.boolean().optional().nullable(),
  ada_compliant: z.boolean().optional().nullable(),
  buy_american_act: z.boolean().optional().nullable(),
  watersense_certified: z.boolean().optional().nullable(),
  lead_free: z.boolean().optional().nullable(),
  csa_cert: z.boolean().optional().nullable(),
  upc_cert: z.boolean().optional().nullable(),
  us_price: z.string().optional().nullable(),
  ca_price: z.string().optional().nullable(),
  created_at: z.string().optional().nullable(),
  updated_at: z.string().optional().nullable(),
});

export enum ProductDocumentType {
  ProductSheet = 'product_sheet',
  CustomerService = 'customer_service',
  Warranty = 'warranty',
  InstallationGuide = 'installation_guide',
  SpecSheet = 'spec_sheet',
  PartsDiagram = 'parts_diagram',
}

const ProductBlockSettingsDocument = z.discriminatedUnion('source', [
  z.object({
    id: z.string(),
    type: z.nativeEnum(ProductDocumentType),
    source: z.literal('settings'),
    file: FileRelationUpdateSchema,
  }),
  z.object({
    id: z.string(),
    type: z.nativeEnum(ProductDocumentType),
    source: z.literal('product'),
    file: SalsifyDigitalAsset,
  }),
]);

export const ProductBlockSettings = z
  .object({
    main_image: FileImageRelationUpdateSchema.nullable().optional(),
    image_settings: ImageSettings.optional(),
    title: z.string().optional(),
    sku: z.string().optional(),
    show_features: z.boolean(),
    features: z
      .array(
        z.object({
          id: z.string(),
          content: z.string().min(3).max(960).or(z.literal('')),
        })
      )
      .optional(),
    show_finishes: z.boolean(),
    finishes: z.array(TaxonomyTermRelationConnect),
    selected_finish: z.string().optional().nullable(),
    show_documents: z.boolean(),
    documents: z.array(ProductBlockSettingsDocument),
    show_pricing: z.boolean(),
    show_no_of_units: z.boolean(),
    no_of_units: z.number(),
    show_list_price: z.boolean(),
    list_price: z.number(),
    show_net_price: z.boolean(),
    net_price: z.number(),
    net_price_label: z.string(),
    multiplier: z.number(),
    show_ext_price: z.boolean(),
    ext_price: z.number(),
    show_info: z.boolean(),
    heading: z.string().optional(),
    notes: z.string().optional(),
    show_special_attributes: z.boolean(),
  })
  .refine(
    (obj) => {
      if (!obj.show_info) {
        return true;
      }

      return Boolean(obj.heading?.length);
    },
    {
      message: 'Heading is required.',
      path: ['heading'],
    }
  )
  .refine(
    (obj) => {
      if (!obj.show_info) {
        return true;
      }

      return Boolean(obj.notes?.length);
    },
    {
      message: 'Notes is required.',
      path: ['notes'],
    }
  );

export const ProductBlock = z
  .object({
    id: z.number().or(z.string()),
    type: z.literal(ProductBlockType.Product),
    content: Product.nullable(),
    settings: ProductBlockSettings,
  })
  .refine(
    (obj) => {
      if (obj.content) {
        return true;
      }

      return Boolean(obj.settings.title?.length);
    },
    {
      message: 'Title is required.',
      path: ['settings.title'],
    }
  )
  .refine(
    (obj) => {
      if (obj.content) {
        return true;
      }

      return Boolean(obj.settings.sku?.length);
    },
    {
      message: 'SKU is required.',
      path: ['settings.sku'],
    }
  );

export type ProductBlockFieldType = z.infer<typeof ProductBlock>;

export type ProductBlockDefaultValues = Omit<DefaultBlockValue<ProductBlockFieldType>, 'settings'> & {
  settings?: Partial<Omit<ProductBlockFieldType['settings'], 'main_image'>> & {
    main_image?: ProductBlockFieldType['settings']['main_image'] | null;
    documents?: Array<SetOptional<ProductBlockFieldType['settings']['documents'][0], 'type'>>;
  };
};

export const IntroductionBlockSettings = z
  .object({
    name: z.string().max(60).optional(),
    content: z.string().max(960).optional(),
    image: FileImageRelationUpdateSchema.optional(),
    image_settings: ImageSettings.optional(),
    link: z.literal('').or(z.string().url()).nullable().optional(),
    show_link: z.boolean().optional(),
  })
  .refine(
    (val) => {
      if (val.show_link === true && !val.link) {
        return false;
      }

      return true;
    },
    {
      message: 'Required',
      path: ['link'],
    }
  );

export const IntroductionBlock = z
  .object({
    id: z.number().or(z.string()).nullable(),
    type: z.literal(IntroductionBlockType.Introduction),
    content: IntroductionModelUpdate.optional(),
    settings: IntroductionBlockSettings,
  })
  .refine(
    (val) => {
      if (typeof val.content !== 'undefined' && typeof val.content.id === 'number') {
        return typeof val.settings.name !== 'undefined';
      }

      return true;
    },
    {
      message: 'Required',
      path: ['settings.name'],
    }
  )
  .refine(
    (val) => {
      if (typeof val.content !== 'undefined' && typeof val.content.id === 'number') {
        return val.settings.name && val.settings.name.length > 2;
      }

      return true;
    },
    {
      message: `String must contain at least 3 characters`,
      path: ['settings.name'],
    }
  )
  .refine(
    (val) => {
      if (typeof val.content !== 'undefined' && typeof val.content.id === 'number') {
        return typeof val.settings.content !== 'undefined';
      }

      return true;
    },
    {
      message: 'Required',
      path: ['settings.content'],
    }
  )
  .refine(
    (val) => {
      if (typeof val.content !== 'undefined' && typeof val.content.id === 'number') {
        return val.settings.content && val.settings.content.length > 2;
      }

      return true;
    },
    {
      message: `String must contain at least 3 characters`,
      path: ['settings.content'],
    }
  );

export type IntroductionBlockFieldType = z.infer<typeof IntroductionBlock>;

export type IntroductionBlockDefaultValues = DefaultBlockValue<IntroductionBlockFieldType>;

export const TextBlock = z.object({
  id: z.number().nullable(),
  type: z.literal(TextBlockType.Text),
  content: z
    .object({
      content: z.string().min(3).max(5000).or(z.literal('')).nullable().optional(),
    })
    .optional(),
});

export type TextBlockFieldType = z.infer<typeof TextBlock>;

export type TextBlockDefaultValues = DefaultBlockValue<TextBlockFieldType>;

export const TitleBlock = z.object({
  id: z.number().or(z.string()).nullable(),
  type: z.literal(TextBlockType.Text),
  content: z
    .object({
      content: z.string().min(3).max(60).or(z.literal('')).nullable(),
    })
    .optional(),
});

export type TitleBlockFieldType = z.infer<typeof TitleBlock>;

export type TitleBlockDefaultValues = DefaultBlockValue<TitleBlockFieldType>;

export const SummaryBlock = z.object({
  id: z.number().or(z.string()).nullable(),
  type: z.literal(SummaryBlockType.Summary),
  content: z
    .object({
      content: z.string().min(3).max(60).or(z.literal('')),
    })
    .optional(),
  settings: z
    .object({
      show_totals: z.boolean(),
      show_no_of_units: z.boolean(),
      show_list_price: z.boolean(),
      show_net_price: z.boolean(),
      show_ext_price: z.boolean(),
    })
    .optional(),
});

export type SummaryBlockFieldType = z.infer<typeof SummaryBlock>;

export type SummaryBlockDefaultValues = DefaultBlockValue<SummaryBlockFieldType>;

export type DefaultBlockValue<T extends { id?: any; type?: any }> =
  | (Pick<T, 'id' | 'type'> & Partial<Omit<T, 'id' | 'type'>>)
  | T;

export const EmptyPage = z.object({
  id: z.number().or(z.string()),
  name: z.string().optional(),
  type: z.literal(''),
  blocks: z.array(z.any()).optional().nullable(),
});

export type EmptyPageSchemaType = z.infer<typeof EmptyPage>;
export type EmptyPageDefaultValue = EmptyPageSchemaType;

export const TocPage = z.object({
  id: z.number().or(z.string()),
  name: z.string().nonempty(),
  type: z.literal(TocPageType.Toc),
  blocks: z.array(TitleBlock.or(EmptyBlock)).max(1).optional().nullable(),
});

export type TocPageSchemaType = z.infer<typeof TocPage>;
export type TocPageDefaultValue = Pick<TocPageSchemaType, 'id' | 'type'> &
  Partial<Omit<TocPageSchemaType, 'id' | 'type' | 'blocks'>> & {
    blocks?: TitleBlockDefaultValues[] | null;
  };

export const SummaryPage = z.object({
  id: z.number().or(z.string()),
  name: z.string().nonempty(),
  type: z.literal(SummaryPageType.Summary),
  blocks: z.array(SummaryBlock.or(EmptyBlock)).max(1).optional().nullable(),
});

export type SummaryPageSchemaType = z.infer<typeof SummaryPage>;
export type SummaryPageDefaultValue = Pick<SummaryPageSchemaType, 'id' | 'type'> &
  Partial<Omit<SummaryPageSchemaType, 'id' | 'type' | 'blocks'>> & {
    blocks?: SummaryBlockDefaultValues[] | null;
  };

// export const PdfPage = z.object({
//   id: z.number().or(z.string()),
//   name: z.string().nonempty(),
//   type: z.literal(PdfPageType.Pdf),
// });

// export type PdfPageSchemaType = z.infer<typeof PdfPage>;
// export type PdfPageDefaultValue = Pick<PdfPageSchemaType, 'id' | 'type'> &
//   Partial<Omit<PdfPageSchemaType, 'id' | 'type'>>;

export const CoverPage = z.object({
  id: z.number().or(z.string()),
  name: z.string().nonempty(),
  type: z.literal(CoverPageType.Cover),
  blocks: z.array(ImageBlock.or(EmptyBlock)).max(3).optional().nullable(),
  layout: z.nativeEnum(PageLayouts).nullable().optional(),
});

export type CoverPageSchemaType = z.infer<typeof CoverPage>;
export type CoverPageDefaultValue = Pick<CoverPageSchemaType, 'id' | 'type'> &
  Partial<Omit<CoverPageSchemaType, 'id' | 'type' | 'blocks'>> & {
    blocks?: ImageBlockDefaultValues[] | null;
  };

export const IntroductionPage = z.object({
  id: z.number().or(z.string()),
  name: z.string().nonempty(),
  type: z.literal(IntroductionPageType.Introduction),
  blocks: z.array(IntroductionBlock.or(EmptyBlock)).max(4).optional().nullable(),
});

export type IntroductionPageSchemaType = z.infer<typeof IntroductionPage>;
export type IntroductionPageDefaultValue = Pick<IntroductionPageSchemaType, 'id' | 'type'> &
  Partial<Omit<IntroductionPageSchemaType, 'id' | 'type' | 'blocks'>> & {
    blocks?: IntroductionBlockDefaultValues[] | null;
  };

export const DividerPage = z.object({
  id: z.number().or(z.string()),
  name: z.string().nonempty(),
  type: z.literal(DividerPageType.Divider),
  blocks: z.array(ImageBlock.or(TitleBlock).or(EmptyBlock)).max(2).optional().nullable(),
  layout: z.nativeEnum(PageLayouts).nullable().optional(),
});

export type DividerPageSchemaType = z.infer<typeof DividerPage>;
export type DividerPageDefaultValue = Pick<DividerPageSchemaType, 'id' | 'type'> &
  Partial<Omit<DividerPageSchemaType, 'id' | 'type' | 'blocks'>> & {
    blocks?: [ImageBlockDefaultValues, TitleBlockDefaultValues] | null;
  };

export const ProductPage = z.object({
  id: z.number().or(z.string()),
  name: z.string().nonempty(),
  type: z.literal(ProductPageType.Product),
  blocks: z.array(ProductBlock.or(EmptyBlock)).max(5).optional().nullable(),
});

export type ProductPageSchemaType = z.infer<typeof ProductPage>;
export type ProductPageDefaultValue = Pick<ProductPageSchemaType, 'id' | 'type'> &
  Partial<Omit<ProductPageSchemaType, 'id' | 'type' | 'blocks'>> & {
    blocks?: ProductBlockDefaultValues[] | null;
  };

export const NotesPage = z.object({
  id: z.number().or(z.string()),
  name: z.string().nonempty(),
  type: z.literal(NotesPageType.Notes),
  blocks: z.array(TextBlock.or(ImageBlock).or(EmptyBlock)).max(5).optional().nullable(),
  layout: z.nativeEnum(PageLayouts).nullable().optional(),
});

export type NotesPageSchemaType = z.infer<typeof NotesPage>;
export type NotesBlockDefaultValues = TextBlockDefaultValues | ImageBlockDefaultValues;
export type NotesPageDefaultValue = Pick<NotesPageSchemaType, 'id' | 'type'> &
  Partial<Omit<NotesPageSchemaType, 'id' | 'type' | 'blocks'>> & {
    blocks?: NotesBlockDefaultValues[] | null;
    layout: 'alternate' | null;
  };

export const ProjectPdfPage = z.discriminatedUnion(
  'type',
  [EmptyPage, CoverPage, TocPage, IntroductionPage, DividerPage, ProductPage, SummaryPage, NotesPage /*, PdfPage */],
  {
    errorMap(issue, ctx) {
      if (issue.code === z.ZodIssueCode.invalid_union_discriminator) {
        return { ...issue, message: 'Page type is required. Please select one below.' };
      }

      return { message: ctx.defaultError };
    },
  }
);

export type ProjectPdfPageType =
  | EmptyPageSchemaType
  | CoverPageSchemaType
  | TocPageSchemaType
  | IntroductionPageSchemaType
  | DividerPageSchemaType
  | ProductPageSchemaType
  | SummaryPageSchemaType
  | NotesPageSchemaType;
// | z.infer<typeof PdfPage>;

export const ProjectClient = z
  .object({
    id: z.number().nullable(),
    name: z.string().min(3).max(60).or(z.literal('')),
    image: FileImageRelationUpdateSchema.nullable(),
  })
  .refine(
    (val) => {
      if (!val.image && !val.name) {
        return false;
      }

      return true;
    },
    {
      message: 'Required.',
      path: ['name'],
    }
  );

export const ProjectClients = z.array(ProjectClient).max(4);

export const SalesforceCustomer = z.object({
  id: z.number(),
  salesforce_id: z.string(),
  name: z.string(),
  address: z.string().nullable(),
  city: z.string().nullable(),
  state: z.string().nullable(),
  postal_code: z.string().nullable(),
  country: z.string().nullable(),
  latitude: z.string().nullable(),
  longitude: z.string().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
});

export const SalesforceOpportunity = z.object({
  id: z.number(),
  salesforce_customer_id: z.number().optional(),
  salesforce_id: z.string(),
  name: z.string(),
  account_id: z.string(),
  address: z.string().nullable(),
  city: z.string().nullable(),
  state: z.string().nullable(),
  postal_code: z.string().nullable(),
  country: z.string().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
});

export const ProjectVersionSchema = z.object({
  id: z.number().nullable(),
  name: z.string().max(60),
  number: z.string().min(3).max(460).or(z.literal('')).optional(),
  status: z
    .nativeEnum(ProjectVersionStatus)
    .or(z.nativeEnum(ProjectVersionStatusUpdate))
    .transform((val) =>
      val === ProjectVersionStatus.Draft
        ? ProjectVersionStatusUpdate.Draft
        : val === ProjectVersionStatus.Published || val === ProjectVersionStatus.Publishing
        ? ProjectVersionStatusUpdate.Published
        : ProjectVersionStatusUpdate.Draft
    ),
  description: z.string().optional(),
  customer: SalesforceCustomer.nullable(),
  opportunity: SalesforceOpportunity.nullable(),
  show_name: z.boolean(),
  show_address: z.boolean(),
  show_rep_info: z.boolean(),
  currency_type: z.nativeEnum(CurrencyCode),
  logo_text: z.string().min(3).max(60),
  logo: FileImageRelationUpdateSchema.optional(),
  logo_settings: ImageSettings.optional(),
  clients: ProjectClients,
  pages: z.array(ProjectPdfPage),
  show_default_number_units: z.boolean(),
  default_number_units: z.number().default(10),
  show_list_price: z.boolean(),
  show_discount_price_label: z.boolean(),
  discount_price_label: z.string().min(3).max(60),
  default_multiplier: z.number(),
  show_extended_price: z.boolean(),
  pricing_footer_note: z.string().max(60),
});

export const ProjectUpdateSchema = z.object({
  submit_button: z.literal('draft').or(z.literal('publish')).optional(),
  name: z.string().min(3).max(60),
  latest: ProjectVersionSchema,
});

export type ProjectVersionPageDefaultValue =
  | EmptyPageDefaultValue
  | CoverPageDefaultValue
  | TocPageDefaultValue
  | IntroductionPageDefaultValue
  | DividerPageDefaultValue
  | ProductPageDefaultValue
  | SummaryPageDefaultValue
  | NotesPageDefaultValue;
export type ProjectVersionPageEmptyValue = {
  id: string | number;
  type?: '' | undefined | null;
  name?: string | undefined;
  blocks?: any[] | null | undefined;
  layout?: undefined | null;
};

export type ProjectDefaultValuesType = Omit<z.infer<typeof ProjectUpdateSchema>, 'latest'> & {
  latest: Partial<Omit<z.infer<typeof ProjectVersionSchema>, 'pages'>> & {
    pages: (ProjectVersionPageDefaultValue | ProjectVersionPageEmptyValue)[];
  };
};

export const filterEmptyClient = <T extends unknown>(value: T): value is NonNullable<T> =>
  value !== null && typeof value !== 'undefined';

export const propertyVisibilityToBoolean = (value: PropertyVisibility) => value === PropertyVisibility.Show;
export const booleanToPropertyVisibility = (value: boolean) =>
  value ? PropertyVisibility.Show : PropertyVisibility.Hide;

export const featuresStringsToObjects = (features: string[]) =>
  features.map((content) => ({
    id: getLocalProductFeatureId(),
    content,
  }));

const _projectToFormValue = (
  project: Project | undefined,
  version: 'latest' | number,
  defaultValues?: {
    customer?: ProjectDefaultValuesType['latest']['customer'];
    opportunity?: ProjectDefaultValuesType['latest']['opportunity'];
  }
): ProjectDefaultValuesType => {
  if (typeof project !== 'undefined') {
    const projectVersion =
      version === 'latest' || version === project.latest_version_id
        ? project.latest
        : project.versions.find((item) => item.id === version) ?? project.latest;

    return {
      name: project.name,
      latest: {
        ...projectVersion,
        clients: projectVersion.clients.map((client) => ({ ...client, name: client.name ?? '' })),
        logo: projectVersion.logo ? projectVersion.logo : undefined,
        logo_settings: projectVersion.logo_settings,
        status:
          projectVersion.status === ProjectVersionStatus.Draft
            ? ProjectVersionStatusUpdate.Draft
            : projectVersion.status === ProjectVersionStatus.Published
            ? ProjectVersionStatusUpdate.Published
            : ProjectVersionStatusUpdate.Draft,
        show_name: propertyVisibilityToBoolean(projectVersion.show_name),
        show_address: propertyVisibilityToBoolean(projectVersion.show_address),
        show_rep_info: propertyVisibilityToBoolean(projectVersion.show_rep_info),
        show_default_number_units: propertyVisibilityToBoolean(projectVersion.show_default_number_units),
        show_list_price: propertyVisibilityToBoolean(projectVersion.show_list_price),
        show_discount_price_label: propertyVisibilityToBoolean(projectVersion.show_discount_price_label),
        show_extended_price: propertyVisibilityToBoolean(projectVersion.show_extended_price),
        pricing_footer_note:
          projectVersion.pricing_footer_note ??
          'Pricing Effective Until: ' +
            format(
              lastDayOfYear(projectVersion.updated_at ? parseISO(projectVersion.updated_at) : new Date()),
              'MMM d y'
            ),
        pages: projectVersion.pages.map((page) => {
          if (page.type === CoverPageType.Cover) {
            return {
              id: page.id,
              name: page.name,
              type: CoverPageType.Cover,
              blocks: page.blocks,
              layout: page.layout,
            } as CoverPageDefaultValue;
          }

          if (page.type === IntroductionPageType.Introduction) {
            return {
              id: page.id,
              name: page.name,
              type: IntroductionPageType.Introduction,
              blocks: page.blocks.map((block) => {
                return {
                  ...block,
                  settings: {
                    ...block.settings,
                    name: block.settings?.name || block.content.name,
                    content: block.settings?.content || block.content.content,
                  },
                };
              }),
            } as IntroductionPageDefaultValue;
          }

          if (page.type === DividerPageType.Divider) {
            const imageBlock = page.blocks.find(
              (block): block is ImageBlockModel => block.type === ImageBlockType.Image
            );
            const textBlock = page.blocks.find((block): block is TextBlockModel => block.type === TextBlockType.Text);

            return {
              id: page.id,
              name: page.name,
              type: DividerPageType.Divider,
              blocks: [
                imageBlock
                  ? imageBlock
                  : {
                      id: getLocalBlockId(),
                      type: ImageBlockType.Image,
                    },
                textBlock
                  ? textBlock
                  : {
                      id: getLocalBlockId(),
                      type: TextBlockType.Text,
                      content: {
                        content: '',
                      },
                    },
              ],
              layout: page.layout,
            } as DividerPageDefaultValue;
          }

          if (page.type === ProductPageType.Product) {
            return {
              id: page.id,
              name: page.name,
              type: ProductPageType.Product,
              blocks: page.blocks.map((block) => {
                const documents: z.infer<typeof ProductBlockSettings>['documents'] = [];

                const items: {
                  settingsKey: ConditionalKeys<ProductBlockModel['settings'], FileRelation | null | undefined>;
                  productModelKey?: ConditionalKeys<ProductModel, SalsifyDigitalAssetModel | null | undefined>;
                  type: ProductDocumentType;
                }[] = [
                  {
                    settingsKey: 'product_sheet',
                    productModelKey: 'product_sheet',
                    type: ProductDocumentType.ProductSheet,
                  },
                  { settingsKey: 'customer_service', type: ProductDocumentType.CustomerService },
                  { settingsKey: 'warranty', productModelKey: 'warranty', type: ProductDocumentType.Warranty },
                  {
                    settingsKey: 'installation_guide',
                    productModelKey: 'installation_guide',
                    type: ProductDocumentType.InstallationGuide,
                  },
                  { settingsKey: 'spec_sheet', productModelKey: 'spec_sheet', type: ProductDocumentType.SpecSheet },
                  {
                    settingsKey: 'parts_diagram',
                    productModelKey: 'parts_diagram',
                    type: ProductDocumentType.PartsDiagram,
                  },
                ];

                items.forEach((item) => {
                  const settingsValue = block.settings[item.settingsKey];
                  const productValue =
                    item.productModelKey && block.content ? block.content[item.productModelKey] : undefined;

                  if (settingsValue) {
                    documents.push({
                      id: getLocalProductDocumentsId(),
                      type: item.type,
                      file: settingsValue,
                      source: 'settings',
                    });
                  } else if (settingsValue !== null && productValue) {
                    documents.push({
                      id: getLocalProductDocumentsId(),
                      type: item.type,
                      file: productValue,
                      source: 'product',
                    });
                  }
                });

                return {
                  ...block,
                  content: block.content
                    ? {
                        ...block.content,
                        main_image: block.content.main_image
                          ? {
                              ...block.content.main_image,
                              salsify_url: block.content.main_image.salsify_url.replace(/^http:/, 'https:'),
                            }
                          : null,
                      }
                    : null,
                  settings: {
                    ...omit(block.settings, ...items.map((item) => item.settingsKey)),
                    show_documents: propertyVisibilityToBoolean(block.settings.show_documents),
                    show_ext_price: propertyVisibilityToBoolean(block.settings.show_ext_price),
                    show_features: propertyVisibilityToBoolean(block.settings.show_features),
                    show_finishes: propertyVisibilityToBoolean(block.settings.show_finishes),
                    show_info: propertyVisibilityToBoolean(block.settings.show_info),
                    show_list_price: propertyVisibilityToBoolean(block.settings.show_list_price),
                    show_net_price: propertyVisibilityToBoolean(block.settings.show_net_price),
                    show_no_of_units: propertyVisibilityToBoolean(block.settings.show_no_of_units),
                    show_pricing: propertyVisibilityToBoolean(block.settings.show_pricing),
                    show_special_attributes: propertyVisibilityToBoolean(
                      block.settings.show_special_attributes ?? PropertyVisibility.Show
                    ),
                    features: featuresStringsToObjects(block.settings.features ?? block.content?.features ?? []),
                    finishes: block.settings.finishes.map((finish) => ({
                      ...finish,
                      id: typeof finish.id === 'number' ? finish.id : getLocalProductFinishesId(),
                    })),
                    documents,
                  },
                };
              }),
            } as ProductPageDefaultValue;
          }

          if (page.type === SummaryPageType.Summary) {
            return {
              id: page.id,
              name: page.name,
              type: SummaryPageType.Summary,
              blocks: [
                {
                  ...page.blocks[0],
                  content: {
                    content: page.blocks[0]?.content?.content || '',
                  },
                  settings: {
                    show_totals: propertyVisibilityToBoolean(page.blocks[0].settings.show_totals),
                    show_no_of_units: propertyVisibilityToBoolean(page.blocks[0].settings.show_no_of_units),
                    show_list_price: propertyVisibilityToBoolean(page.blocks[0].settings.show_list_price),
                    show_net_price: propertyVisibilityToBoolean(page.blocks[0].settings.show_net_price),
                    show_ext_price: propertyVisibilityToBoolean(page.blocks[0].settings.show_ext_price),
                  },
                },
              ],
            } as SummaryPageDefaultValue;
          }

          if (page.type === NotesPageType.Notes) {
            return {
              id: page.id,
              name: page.name,
              type: NotesPageType.Notes,
              blocks: page.blocks,
              layout: page.layout,
            } as NotesPageDefaultValue;
          }

          // if (page.type === PdfPageType.Pdf) {
          //   return {
          //     id: page.id,
          //     name: page.name,
          //     type: PdfPageType.Pdf,
          //   } as z.infer<typeof PdfPage>;
          // }

          if (page.type === TocPageType.Toc) {
            return {
              id: page.id,
              name: page.name,
              type: page.type,
              blocks: page.blocks,
            } as TocPageDefaultValue;
          }

          return {
            id: page.id,
            name: page.name,
            type: page.type,
          };
        }),
      },
    };
  }

  return {
    name: '',
    latest: {
      id: null,
      name: '',
      description: '',
      logo_text: 'Prepared for:',
      logo: undefined,
      logo_settings: undefined,
      clients: [],
      customer: defaultValues?.customer ?? null,
      opportunity: defaultValues?.opportunity ?? null,
      pages: [
        {
          id: getLocalPageId(),
          type: '',
          name: 'Page 1',
          blocks: [],
        },
      ],
      number: '',
      status: ProjectVersionStatusUpdate.Draft,
      currency_type: CurrencyCode.Us,
      default_number_units: 10,
      show_name: true,
      show_address: true,
      show_rep_info: true,
      show_list_price: true,
      show_extended_price: true,
      show_default_number_units: true,
      show_discount_price_label: false,
      discount_price_label: 'Distributor Net Price',
      default_multiplier: 0.7,
      pricing_footer_note: 'Pricing Effective Until: ' + format(lastDayOfYear(new Date()), 'MMM d y'),
    },
  };
};

export const projectToFormValue = (
  project: Project | undefined,
  version: 'latest' | number,
  defaultValues?: {
    customer?: ProjectDefaultValuesType['latest']['customer'];
    opportunity?: ProjectDefaultValuesType['latest']['opportunity'];
  }
): ProjectDefaultValuesType => {
  const output = _projectToFormValue(project, version, defaultValues);
  return output;
};

export type ProjectUpdateSchemaType = z.infer<typeof ProjectUpdateSchema>;

const coverPageFormValueToUpdateValue = (page: CoverPageSchemaType): CoverPageUpdate => ({
  ...page,
  id: typeof page.id === 'number' ? page.id : null,
  name: typeof page.name === 'string' ? page.name : '',
  layout: typeof page.layout === 'undefined' ? null : page.layout,
  blocks: (page.blocks ?? []).filter(filterEmptyImageBlock).map((block) => ({
    ...block,
    id: typeof block.id === 'number' ? block.id : null,
  })),
});

const introductionPageFormValueToUpdateValue = (page: IntroductionPageSchemaType): IntroductionPageUpdate => ({
  ...page,
  id: typeof page.id === 'number' ? page.id : null,
  name: typeof page.name === 'string' ? page.name : '',
  blocks: (page.blocks ?? []).filter(filterEmptyIntroductionBlock).map((block) => {
    const { show_link, link, ...otherSettings } = block.settings;

    return {
      ...block,
      id: typeof block.id === 'number' ? block.id : null,
      settings: {
        ...otherSettings,
        ...(show_link === true ? { show_link: true, link } : { show_link: false }),
      },
    };
  }),
});

const dividerPageFormValueToUpdateValue = (page: DividerPageSchemaType): DividerPageUpdate => {
  const imageBlock = (page.blocks ?? []).find((block) => block.type === ImageBlockType.Image) as
    | ImageBlockFieldType
    | undefined;
  const titleBlock = (page.blocks ?? []).find((block) => block.type === TextBlockType.Text) as
    | TitleBlockFieldType
    | undefined;

  const pageBlocks: DividerPageUpdate['blocks'] = [];

  if (imageBlock && filterEmptyImageBlock(imageBlock)) {
    pageBlocks.push({ ...imageBlock, id: typeof imageBlock.id === 'number' ? imageBlock.id : null });
  }

  if (titleBlock) {
    pageBlocks.push({
      ...titleBlock,
      id: typeof titleBlock.id === 'number' ? titleBlock.id : null,
      content: { content: titleBlock.content?.content ?? '' },
    });
  }

  return {
    ...page,
    id: typeof page.id === 'number' ? page.id : null,
    name: typeof page.name === 'string' ? page.name : '',
    layout: typeof page.layout === 'undefined' ? null : page.layout,
    blocks: pageBlocks,
  };
};

const summaryPageFormValueToUpdateValue = (page: SummaryPageSchemaType): SummaryPageUpdate => ({
  ...page,
  id: typeof page.id === 'number' ? page.id : null,
  name: typeof page.name === 'string' ? page.name : '',
  blocks: (page.blocks ?? []).filter(filterEmptySummaryBlock).map((block) => ({
    ...block,
    id: typeof block.id === 'number' ? block.id : null,
    content: {
      ...block.content,
      content: block.content?.content || '',
    },
    settings: {
      show_totals: booleanToPropertyVisibility(block.settings?.show_totals ?? true),
      show_no_of_units: booleanToPropertyVisibility(block.settings?.show_no_of_units ?? true),
      show_list_price: booleanToPropertyVisibility(block.settings?.show_list_price ?? true),
      show_net_price: booleanToPropertyVisibility(block.settings?.show_net_price ?? true),
      show_ext_price: booleanToPropertyVisibility(block.settings?.show_ext_price ?? true),
    },
  })),
});

const tocPageFormValueToUpdateValue = (page: TocPageSchemaType): TocPageUpdate => ({
  ...page,
  id: typeof page.id === 'number' ? page.id : null,
  name: typeof page.name === 'string' ? page.name : '',
  blocks: (page.blocks ?? [])
    .filter((block): block is TitleBlockFieldType => block.type === 'text')
    .map((block) => ({
      ...block,
      id: typeof block.id === 'number' ? block.id : null,
      content: {
        content: block.content?.content ? block.content.content : null,
      },
    })),
});

const notesPageFormValueToUpdateValue = (page: NotesPageSchemaType): NotesPageUpdate => {
  const pageBlocks: NotesPageUpdate['blocks'] = (page.blocks ?? [])
    .filter(filterEmptyImageOrTextBlock)
    ?.map((block) => {
      if (block.type === ImageBlockType.Image) {
        let image = block as ImageBlockFieldType;
        return {
          ...image,
          id: typeof block.id === 'number' ? block.id : null,
        };
      }
      if (block.type === TextBlockType.Text) {
        let text = block as unknown as TextBlockFieldType;
        return {
          ...block,
          id: typeof block.id === 'number' ? block.id : null,
          content: {
            content: text.content?.content ? text.content.content : null,
          },
        };
      }
      return undefined;
    })
    .filter((block) => block !== undefined) as NotesPageUpdate['blocks'];
  return {
    ...page,
    id: typeof page.id === 'number' ? page.id : null,
    name: typeof page.name === 'string' ? page.name : '',
    blocks: pageBlocks,
  };
};

const productPageFormValueToUpdateValue = (page: ProductPageSchemaType): ProductPageUpdate => ({
  ...page,
  id: typeof page.id === 'number' ? page.id : null,
  name: typeof page.name === 'string' ? page.name : '',
  blocks: (page.blocks ?? []).filter(filterEmptyProductBlock).map((block) => {
    const { documents, ...settings } = block.settings;

    const product_sheet = documents.find((document) => document.type === ProductDocumentType.ProductSheet);
    const customer_service = documents.find((document) => document.type === ProductDocumentType.CustomerService);
    const warranty = documents.find((document) => document.type === ProductDocumentType.Warranty);
    const installation_guide = documents.find((document) => document.type === ProductDocumentType.InstallationGuide);
    const spec_sheet = documents.find((document) => document.type === ProductDocumentType.SpecSheet);
    const parts_diagram = documents.find((document) => document.type === ProductDocumentType.PartsDiagram);

    return {
      ...block,
      id: typeof block.id === 'number' ? block.id : null,
      settings: {
        ...settings,
        show_documents: booleanToPropertyVisibility(block.settings.show_documents),
        show_ext_price: booleanToPropertyVisibility(block.settings.show_ext_price),
        show_features: booleanToPropertyVisibility(block.settings.show_features),
        show_finishes: booleanToPropertyVisibility(block.settings.show_finishes),
        show_info: booleanToPropertyVisibility(block.settings.show_info),
        show_list_price: booleanToPropertyVisibility(block.settings.show_list_price),
        show_net_price: booleanToPropertyVisibility(block.settings.show_net_price),
        show_no_of_units: booleanToPropertyVisibility(block.settings.show_no_of_units),
        show_pricing: booleanToPropertyVisibility(block.settings.show_pricing),
        show_special_attributes: booleanToPropertyVisibility(block.settings.show_special_attributes),
        features:
          typeof block.settings.features !== 'undefined' &&
          block.settings.features.filter(({ content }) => content.length)
            ? block.settings.features.filter(({ content }) => content.length).map(({ content }) => content)
            : [],
        finishes: block.settings.finishes
          .filter((finish) => finish.term.length)
          .map((finish) => ({
            ...finish,
            id: typeof finish.id === 'number' ? finish.id : null,
          })),
        product_sheet: product_sheet ? (product_sheet.source === 'product' ? undefined : product_sheet.file) : null,
        customer_service: customer_service
          ? customer_service.source === 'product'
            ? undefined
            : customer_service.file
          : null,
        warranty: warranty ? (warranty.source === 'product' ? undefined : warranty.file) : null,
        installation_guide: installation_guide
          ? installation_guide.source === 'product'
            ? undefined
            : installation_guide.file
          : null,
        spec_sheet: spec_sheet ? (spec_sheet.source === 'product' ? undefined : spec_sheet.file) : null,
        parts_diagram: parts_diagram ? (parts_diagram.source === 'product' ? undefined : parts_diagram.file) : null,
      },
    };
  }),
});

export const formValueToProjectUpdate = (input: ProjectUpdateSchemaType, isDraft?: boolean): ProjectUpdate => {
  console.log('formValueToProjectUpdate input', input);
  return {
    ...input,
    latest: {
      ...input.latest,
      logo: input.latest.logo ? input.latest.logo : undefined,
      logo_settings: input.latest.logo_settings,
      clients: input.latest.clients.map((client) => ({ ...client, name: client.name ? client.name : null })),
      status: isDraft ? ProjectVersionStatusUpdate.Draft : ProjectVersionStatusUpdate.Published,
      number: input.latest.number ? input.latest.number : undefined,
      description: input.latest.description ? input.latest.description : undefined,
      customer_id: input.latest.customer?.id ?? null,
      opportunity_id: input.latest.opportunity?.id ?? null,
      show_name: booleanToPropertyVisibility(input.latest.show_name),
      show_address: booleanToPropertyVisibility(input.latest.show_address),
      show_rep_info: booleanToPropertyVisibility(input.latest.show_rep_info),
      show_default_number_units: booleanToPropertyVisibility(input.latest.show_default_number_units),
      show_list_price: booleanToPropertyVisibility(input.latest.show_list_price),
      show_discount_price_label: booleanToPropertyVisibility(input.latest.show_discount_price_label),
      show_extended_price: booleanToPropertyVisibility(input.latest.show_extended_price),
      pages: input.latest.pages.map((page) => {
        if (page.type === CoverPageType.Cover) {
          return coverPageFormValueToUpdateValue(page);
        }

        if (page.type === IntroductionPageType.Introduction) {
          return introductionPageFormValueToUpdateValue(page);
        }

        if (page.type === DividerPageType.Divider) {
          return dividerPageFormValueToUpdateValue(page);
        }

        if (page.type === SummaryPageType.Summary) {
          return summaryPageFormValueToUpdateValue(page);
        }

        if (page.type === TocPageType.Toc) {
          return tocPageFormValueToUpdateValue(page);
        }

        if (page.type === NotesPageType.Notes) {
          return notesPageFormValueToUpdateValue(page);
        }

        if (page.type === ProductPageType.Product) {
          return productPageFormValueToUpdateValue(page);
        }

        return {
          id: typeof page.id === 'number' ? page.id : null,
          name: typeof page.name === 'string' ? page.name : '',
          type: EmptyPageType.Empty,
        };
      }),
    },
  };
};
