export function getObjectKeys<ObjectType>(object: ObjectType) {
  // @ts-ignore
  return Object.keys(object) as unknown as (keyof ObjectType)[];
}

export function getObjectFromEntries<Key extends string, Value>(
  array: readonly (readonly [Key, Value])[]
): Record<Key, Value> {
  return Object.fromEntries(array) as unknown as Record<Key, Value>;
}
