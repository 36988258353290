import * as React from 'react';
import { useWatch } from 'react-hook-form';
import omit from 'lodash/omit';
import * as pdfViewer from 'react-pdf';
import reactPdf, { pdf } from '@react-pdf/renderer';
import cloneDeep from 'lodash/cloneDeep';
import { AxiosResponse } from 'axios';
import { useMeasure } from 'react-use';

import {
  ProductPageType,
  ProjectApiDownloadProjectVersionRequest,
  ProjectApiDownloadPublicProjectRequest,
  SummaryPageType,
  UserRelation,
  TocPageType,
} from '__generated-api__';
import api from 'api';
import { BaseApiFn, useQuery } from 'hooks/query';
import spbPdf from 'spb-pdf';
import { GenerateSPBPdfPage } from 'spb-pdf/generator';
import { getUserName } from 'my-account/utils/user';
import { filterEmptyClient, ProjectDefaultValuesType } from 'my-account/validations/project';
import { useDeepCompareMemoize } from 'hooks/useDeepCompareMemoize';
import { wait } from 'my-account/utils/promise';
import useDocumentSettings from 'my-account/hooks/useDocumentSettings';

pdfViewer.pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfViewer.pdfjs.version}/pdf.worker.js`;

const DocumentPreviewCanvas: React.VFC<{
  user?: UserRelation;
  pageIndex: number;
  pages: ProjectDefaultValuesType['latest']['pages'];
  className: string;
  effectiveDate: string;
  isThumbnail?: boolean;
}> = ({ user, pageIndex, pages, className, effectiveDate, isThumbnail }) => {
  const page = pages[pageIndex];
  const { show_address, show_rep_info, currency_type, pricing_footer_note } = useDocumentSettings();
  const watchedClients = useWatch<ProjectDefaultValuesType, 'latest.clients'>({
    name: 'latest.clients',
  });
  const headerLabel = useWatch<ProjectDefaultValuesType, 'latest.logo_text'>({ name: 'latest.logo_text' });
  const opportunity = useWatch<ProjectDefaultValuesType, 'latest.opportunity'>({ name: 'latest.opportunity' });
  const quoteNumber = useWatch<ProjectDefaultValuesType, 'latest.number'>({ name: 'latest.number' });
  const logo = useWatch<ProjectDefaultValuesType, 'latest.logo'>({ name: 'latest.logo' });
  const logo_settings = useWatch<ProjectDefaultValuesType, 'latest.logo_settings'>({ name: 'latest.logo_settings' });

  const clients = React.useMemo(() => cloneDeep(watchedClients), [watchedClients]);

  const dependencies = useDeepCompareMemoize([
    currency_type,
    pageIndex,
    headerLabel,
    show_address,
    show_rep_info,
    currency_type,
    pricing_footer_note,
    opportunity,
    quoteNumber,
    effectiveDate,
    clients,
    logo,
    logo_settings,
    cloneDeep(user),
    page.type === TocPageType.Toc ? cloneDeep(page) : omit(cloneDeep(page), ['name']),
  ]);
  const document = React.useMemo(
    () => {
      return (
        <reactPdf.Document>
          <reactPdf.Page size="A4" style={{ paddingVertical: page.type === SummaryPageType.Summary ? 110 : 90 }}>
            <spbPdf.component.Header
              label={headerLabel}
              logo={logo ?? undefined}
              logo_settings={logo_settings ?? undefined}
              clients={
                typeof clients !== 'undefined'
                  ? clients.filter(filterEmptyClient).map((client) => ({
                      name: client.name ?? '',
                      image:
                        client.image && client.image.image
                          ? client.image.width && client.image.height
                            ? { src: client.image.image, width: client.image.width, height: client.image.height }
                            : { src: client.image.image }
                          : undefined,
                    }))
                  : []
              }
            />
            <GenerateSPBPdfPage
              pages={pages}
              page_index={pageIndex}
              wrapWithPage={false}
              currency_type={currency_type}
            />
            <spbPdf.component.Footer
              client={{
                name: opportunity?.name ?? '',
                address: show_address
                  ? [
                      opportunity?.address,
                      [opportunity?.city, [opportunity?.state, opportunity?.postal_code].filter(Boolean).join(' ')]
                        .filter(Boolean)
                        .join(', '),
                    ]
                      .filter(Boolean)
                      .join('\n')
                  : undefined,
              }}
              effective_date={effectiveDate}
              prepared_by={
                show_rep_info
                  ? {
                      name: user ? getUserName(user).name : '',
                      email: user?.email ?? '',
                      phone: user?.phone ?? undefined,
                    }
                  : undefined
              }
              page_number={pageIndex + 1}
              quote_number={quoteNumber}
              pricing_footer_note={
                page.type === ProductPageType.Product || page.type === SummaryPageType.Summary
                  ? pricing_footer_note
                  : undefined
              }
            />
          </reactPdf.Page>
        </reactPdf.Document>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dependencies
  );

  const [documentRender, setDocumentRender] = React.useState<
    | { isLoading: true; isError: false; value?: undefined; error?: undefined }
    | { isLoading: false; isError: true; value?: undefined; error: unknown }
    | { isLoading: false; isError: false; error?: undefined; value: string | null }
  >({ isLoading: true, isError: false });

  React.useEffect(() => {
    if (!document) {
      setDocumentRender({ isLoading: false, isError: false, value: null });
      return;
    }

    let canceled = false;

    setDocumentRender({ isLoading: true, isError: false });

    wait(200)
      .then(() => {
        if (canceled) {
          throw new Error('canceled');
        }

        return pdf(document).toBlob();
      })
      .then((blob) => {
        if (canceled) {
          throw new Error('canceled');
        }

        setDocumentRender({ isLoading: false, isError: false, value: URL.createObjectURL(blob) });
      })
      .catch((error) => {
        if (error instanceof Error && error.message === 'canceled') {
          return;
        }

        setDocumentRender({ isLoading: false, isError: true, error });
      });

    return () => {
      canceled = true;
    };
  }, [document]);

  const [pageWrapperRef, pageWrapperMeasure] = useMeasure<HTMLDivElement>();

  return (
    <div
      className={className}
      ref={pageWrapperRef}
      style={{ position: 'relative', paddingTop: '141.42857143%', height: '0' }}
    >
      {documentRender.isLoading && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            paddingTop: '141.42857143%',
            backgroundColor: 'white',
            zIndex: 2,
          }}
        >
          <div
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)' + (isThumbnail ? ' scale(0.4)' : ''),
            }}
          >
            <div className="c-listing__none">
              <div className="c-listing__none-spinner"></div>
              <p className="c-listing__none-title">Loading...</p>
            </div>
          </div>
        </div>
      )}
      <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
        {documentRender.value && (
          <pdfViewer.Document
            file={documentRender.value}
            externalLinkTarget="_blank"
            loading={
              <div
                style={{
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%, -50%)' + (isThumbnail ? ' scale(0.4)' : ''),
                }}
              >
                <div className="c-listing__none">
                  <div className="c-listing__none-spinner"></div>
                  <p className="c-listing__none-title">Loading...</p>
                </div>
              </div>
            }
          >
            <pdfViewer.Page
              pageNumber={1}
              width={pageWrapperMeasure.width}
              renderAnnotationLayer={!isThumbnail}
              renderTextLayer={!isThumbnail}
              renderInteractiveForms={!isThumbnail}
              loading={
                <div
                  style={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)' + (isThumbnail ? ' scale(0.4)' : ''),
                  }}
                >
                  <div className="c-listing__none">
                    <div className="c-listing__none-spinner"></div>
                    <p className="c-listing__none-title">Loading...</p>
                  </div>
                </div>
              }
            />
          </pdfViewer.Document>
        )}
      </div>
    </div>
  );
};

export const PreviewDocumentPdf: React.VFC<
  {
    pageNumber: number;
    className: string;
    isThumbnail?: boolean;
    setTotalPages: (totalPages: number) => void;
  } & (
    | { publicToken: string; project?: undefined }
    | { publicToken?: undefined; project: { id: number; versionId: number } }
  )
> = ({ pageNumber, className, isThumbnail, publicToken, project, setTotalPages }) => {
  const [pageWrapperRef, pageWrapperMeasure] = useMeasure<HTMLDivElement>();
  const [isLoading, setIsLoading] = React.useState(true);
  const [publicDocumentBlob] = useQuery<
    BaseApiFn<[ProjectApiDownloadPublicProjectRequest], any>,
    unknown,
    AxiosResponse<Blob>
  >(
    api.project.downloadPublicProject,
    { publicToken: typeof publicToken === 'string' ? publicToken : '' },
    { axiosRequestConfig: { responseType: 'blob', timeout: 30000 }, enabled: typeof publicToken === 'string' }
  );
  const [versionDocumentBlob] = useQuery<
    BaseApiFn<[ProjectApiDownloadProjectVersionRequest], any>,
    unknown,
    AxiosResponse<Blob>
  >(
    api.project.downloadProjectVersion,
    { id: project?.id ?? 0, versionId: project?.versionId ?? 0 },
    { axiosRequestConfig: { responseType: 'blob', timeout: 30000 }, enabled: typeof project !== 'undefined' }
  );
  const [pdfDocument, setPdfDocument] = React.useState<string | null>(null);

  const documentBlob = typeof publicToken === 'string' ? publicDocumentBlob : versionDocumentBlob;

  React.useEffect(() => {
    if (documentBlob?.data) {
      const url = URL.createObjectURL(documentBlob.data);

      setPdfDocument(url);

      return () => {
        URL.revokeObjectURL(url);
      };
    }

    setPdfDocument(null);
  }, [documentBlob]);

  return (
    <div
      className={className}
      ref={pageWrapperRef}
      style={{ position: 'relative', paddingTop: '141.42857143%', height: '0' }}
    >
      {(isLoading || pdfDocument === null) && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            paddingTop: '141.42857143%',
            backgroundColor: 'white',
            zIndex: 2,
          }}
        >
          <div
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)' + (isThumbnail ? ' scale(0.4)' : ''),
            }}
          >
            <div className="c-listing__none">
              <div className="c-listing__none-spinner"></div>
              <p className="c-listing__none-title">Loading...</p>
            </div>
          </div>
        </div>
      )}
      <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
        <pdfViewer.Document
          file={pdfDocument}
          externalLinkTarget="_blank"
          loading={
            <div
              style={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)' + (isThumbnail ? ' scale(0.4)' : ''),
              }}
            >
              <div className="c-listing__none">
                <div className="c-listing__none-spinner"></div>
                <p className="c-listing__none-title">Loading...</p>
              </div>
            </div>
          }
          onLoadSuccess={(data) => {
            setIsLoading(false);
            setTotalPages(data.numPages);
          }}
          onLoadError={() => {
            setIsLoading(false);
          }}
          onLoadProgress={() => {
            setIsLoading(true);
          }}
        >
          <pdfViewer.Page
            pageNumber={pageNumber}
            width={pageWrapperMeasure.width}
            renderAnnotationLayer={!isThumbnail}
            renderTextLayer={!isThumbnail}
            renderInteractiveForms={!isThumbnail}
            loading={
              <div
                style={{
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%, -50%)' + (isThumbnail ? ' scale(0.4)' : ''),
                }}
              >
                <div className="c-listing__none">
                  <div className="c-listing__none-spinner"></div>
                  <p className="c-listing__none-title">Loading...</p>
                </div>
              </div>
            }
          />
        </pdfViewer.Document>
      </div>
    </div>
  );
};

export default DocumentPreviewCanvas;
