import * as React from 'react';
import { Text, View, Link } from '@react-pdf/renderer';

import { CurrencyCode } from '__generated-api__';
import ImageBlockImage from 'spb-pdf/components/ImageBlock';
import { getDisplayPrice } from 'my-account/utils/currency';
import { ProductProps } from 'spb-pdf/utils/product';

interface SummaryProps {
  title: string;
  products: ProductProps[];
  settings: {
    show_totals: boolean;
    show_no_of_units: boolean;
    show_list_price: boolean;
    show_net_price: boolean;
    show_ext_price: boolean;
  };
  currency_type: CurrencyCode | undefined;
}

const Summary: React.VFC<SummaryProps> = ({ title, products, settings, currency_type }) => {
  const itemColumnFlex =
    0.5 +
    (!settings.show_no_of_units ? 0.125 : 0) +
    (!settings.show_list_price ? 0.125 : 0) +
    (!settings.show_net_price ? 0.125 : 0) +
    (!settings.show_ext_price ? 0.125 : 0);

  return (
    <View style={{ paddingHorizontal: 45 }}>
      <View style={{ position: 'relative' }}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            flexShrink: 0,
          }}
          fixed
        >
          {Boolean(title) && (
            <Text
              style={{
                fontFamily: 'Gotham Rounded',
                fontWeight: 200,
                fontSize: 18,
                color: '#000000',
                opacity: 0.8,
                letterSpacing: -0.39,
              }}
            >
              {title}
            </Text>
          )}
          <View></View>
          <Text
            style={{
              fontFamily: 'Gotham Rounded',
              fontSize: 6,
              color: '#666666',
              paddingBottom: 6,
            }}
            render={({ subPageNumber, subPageTotalPages }) =>
              subPageTotalPages > 1 ? `Summary page ${subPageNumber} out of ${subPageTotalPages}` : ''
            }
          />
        </View>
        {Boolean(title) && (
          <View
            style={{
              width: '100%',
              height: 1,
              backgroundColor: '#333333',
              marginTop: 20,
              marginBottom: 18,
              flexShrink: 0,
            }}
            fixed
          />
        )}
        <View
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch', width: '100%', flexShrink: 0 }}
          fixed
        >
          <View style={{ flex: itemColumnFlex }}>
            <Text
              style={{ fontFamily: 'Gotham Rounded', fontWeight: 600, fontSize: 7, color: '#666666', opacity: 0.6 }}
            >
              Item
            </Text>
          </View>
          {settings.show_no_of_units && (
            <View style={{ flex: 0.125 }}>
              <Text
                style={{
                  fontFamily: 'Gotham Rounded',
                  fontWeight: 600,
                  fontSize: 7,
                  color: '#666666',
                  opacity: 0.6,
                  textAlign: 'right',
                }}
              >
                Qty
              </Text>
            </View>
          )}
          {settings.show_list_price && (
            <View style={{ flex: 0.125 }}>
              <Text
                style={{
                  fontFamily: 'Gotham Rounded',
                  fontWeight: 600,
                  fontSize: 7,
                  color: '#666666',
                  opacity: 0.6,
                  textAlign: 'right',
                }}
              >
                List Price
              </Text>
            </View>
          )}
          {settings.show_net_price && (
            <View style={{ flex: 0.125 }}>
              <Text
                style={{
                  fontFamily: 'Gotham Rounded',
                  fontWeight: 600,
                  fontSize: 7,
                  color: '#666666',
                  opacity: 0.6,
                  textAlign: 'right',
                }}
              >
                Budget
              </Text>
            </View>
          )}
          {settings.show_ext_price && (
            <View style={{ flex: 0.125 }}>
              <Text
                style={{
                  fontFamily: 'Gotham Rounded',
                  fontWeight: 600,
                  fontSize: 7,
                  color: '#666666',
                  opacity: 0.6,
                  textAlign: 'right',
                }}
              >
                Extended
              </Text>
            </View>
          )}
        </View>
        {products.map((product, index) => (
          <View key={index} wrap={false} style={{ flexShrink: 0 }}>
            {index === 0 && (
              <View style={{ width: '100%', height: 1, backgroundColor: '#000000', opacity: 0.1, marginTop: 6 }} />
            )}
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                marginTop: 5,
              }}
            >
              <View style={{ flex: itemColumnFlex }}>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <View>
                    {product.image && (
                      <ImageBlockImage
                        image={{ content: product.image, settings: product.image_settings }}
                        style={{ width: 40, height: 40, objectFit: 'contain' }}
                      />
                    )}
                  </View>
                  <View style={{ minHeight: 28, marginLeft: 10 }}>
                    <Text
                      style={{
                        fontFamily: 'Gotham Rounded',
                        fontWeight: 600,
                        fontSize: 9,
                        lineHeight: 1.4,
                        color: '#000000',
                        opacity: 0.8,
                      }}
                    >
                      {product.title}
                    </Text>
                    <Text
                      style={{
                        fontFamily: 'Gotham Rounded',
                        fontWeight: 600,
                        fontSize: 5,
                        color: '#000000',
                        opacity: 0.8,
                        flexShrink: 0,
                      }}
                    >
                      {product.subtitle}
                    </Text>
                    {(typeof product.installation_guide !== 'undefined' ||
                      typeof product.product_sheet !== 'undefined') && (
                      <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 2 }}>
                        {typeof product.installation_guide !== 'undefined' && (
                          <Link
                            src={product.installation_guide}
                            style={{
                              fontFamily: 'Gotham Rounded',
                              fontWeight: 600,
                              fontSize: 6,
                              color: '#57AE55',
                              textDecoration: 'none',
                            }}
                          >
                            Installation
                          </Link>
                        )}
                        {typeof product.installation_guide !== 'undefined' &&
                          typeof product.product_sheet !== 'undefined' && (
                            <View
                              style={{
                                width: 0.5,
                                height: 10,
                                backgroundColor: '#000000',
                                opacity: 0.1,
                                marginHorizontal: 7,
                              }}
                            />
                          )}
                        {typeof product.parts_diagram !== 'undefined' && (
                          <Link
                            src={product.parts_diagram}
                            style={{
                              fontFamily: 'Gotham Rounded',
                              fontWeight: 600,
                              fontSize: 6,
                              color: '#57AE55',
                              textDecoration: 'none',
                            }}
                          >
                            Parts Diagram
                          </Link>
                        )}
                        {typeof product.product_sheet !== 'undefined' && (
                          <Link
                            src={product.product_sheet}
                            style={{
                              fontFamily: 'Gotham Rounded',
                              fontWeight: 600,
                              fontSize: 6,
                              color: '#57AE55',
                              textDecoration: 'none',
                            }}
                          >
                            Submittal
                          </Link>
                        )}
                      </View>
                    )}
                  </View>
                </View>
              </View>
              {settings.show_no_of_units && (
                <View style={{ flex: 0.125 }}>
                  <Text
                    style={{
                      fontFamily: 'Gotham Rounded',
                      fontSize: 8,
                      lineHeight: 1.56,
                      color: '#000000',
                      opacity: 0.8,
                      textAlign: 'right',
                    }}
                  >
                    {product.no_of_units}
                  </Text>
                </View>
              )}
              {settings.show_list_price && (
                <View style={{ flex: 0.125 }}>
                  <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Text
                      style={{
                        fontFamily: 'Gotham Rounded',
                        fontSize: 5,
                        color: '#000000',
                        opacity: 0.8,
                        lineHeight: 1,
                        paddingRight: 1,
                      }}
                    >
                      $
                    </Text>
                    <Text
                      style={{
                        fontFamily: 'Gotham Rounded',
                        fontWeight: 600,
                        fontSize: 8,
                        color: '#000000',
                        opacity: 0.8,
                        lineHeight: 1,
                      }}
                    >
                      {getDisplayPrice({
                        value: product.list_price,
                        currency: currency_type === CurrencyCode.Ca ? 'CAD' : 'USD',
                        hideCurrencySign: true,
                      })}
                    </Text>
                    <Text
                      style={{
                        fontFamily: 'Gotham Rounded',
                        fontSize: 5,
                        color: '#000000',
                        opacity: 0.8,
                        alignSelf: 'flex-end',
                        lineHeight: 1,
                        paddingLeft: 1,
                        paddingBottom: 0.5,
                      }}
                    >
                      {currency_type === CurrencyCode.Ca ? 'CAD' : 'USD'}
                    </Text>
                  </View>
                </View>
              )}
              {settings.show_net_price && (
                <View style={{ flex: 0.125 }}>
                  <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Text
                      style={{
                        fontFamily: 'Gotham Rounded',
                        fontSize: 5,
                        color: '#000000',
                        opacity: 0.8,
                        lineHeight: 1,
                        paddingRight: 1,
                      }}
                    >
                      $
                    </Text>
                    <Text
                      style={{
                        fontFamily: 'Gotham Rounded',
                        fontWeight: 600,
                        fontSize: 8,
                        color: '#000000',
                        opacity: 0.8,
                        lineHeight: 1,
                      }}
                    >
                      {getDisplayPrice({
                        value: product.net_price,
                        currency: currency_type === CurrencyCode.Ca ? 'CAD' : 'USD',
                        hideCurrencySign: true,
                      })}
                    </Text>
                    <Text
                      style={{
                        fontFamily: 'Gotham Rounded',
                        fontSize: 5,
                        color: '#000000',
                        opacity: 0.8,
                        alignSelf: 'flex-end',
                        lineHeight: 1,
                        paddingLeft: 1,
                        paddingBottom: 0.5,
                      }}
                    >
                      {currency_type === CurrencyCode.Ca ? 'CAD' : 'USD'}
                    </Text>
                  </View>
                </View>
              )}
              {settings.show_ext_price && (
                <View style={{ flex: 0.125 }}>
                  <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Text
                      style={{
                        fontFamily: 'Gotham Rounded',
                        fontSize: 5,
                        color: '#000000',
                        opacity: 0.8,
                        lineHeight: 1,
                        paddingRight: 1,
                      }}
                    >
                      $
                    </Text>
                    <Text
                      style={{
                        fontFamily: 'Gotham Rounded',
                        fontWeight: 600,
                        fontSize: 8,
                        color: '#000000',
                        opacity: 0.8,
                        lineHeight: 1,
                      }}
                    >
                      {getDisplayPrice({
                        value: product.extended_price,
                        currency: currency_type === CurrencyCode.Ca ? 'CAD' : 'USD',
                        hideCurrencySign: true,
                      })}
                    </Text>
                    <Text
                      style={{
                        fontFamily: 'Gotham Rounded',
                        fontSize: 5,
                        color: '#000000',
                        opacity: 0.8,
                        alignSelf: 'flex-end',
                        lineHeight: 1,
                        paddingLeft: 1,
                        paddingBottom: 0.5,
                      }}
                    >
                      {currency_type === CurrencyCode.Ca ? 'CAD' : 'USD'}
                    </Text>
                  </View>
                </View>
              )}
            </View>
            <View style={{ width: '100%', height: 1, backgroundColor: '#000000', opacity: 0.1, marginTop: 5 }} />
          </View>
        ))}

        {settings.show_totals && (settings.show_list_price || settings.show_net_price || settings.show_ext_price) && (
          <View
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 14, flexShrink: 0 }}
            wrap={false}
          >
            <View>
              {settings.show_list_price && (
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                  }}
                >
                  <View style={{ paddingRight: 20 }}>
                    <Text
                      style={{
                        fontFamily: 'Gotham Rounded',
                        fontSize: 6,
                        lineHeight: 1.5,
                        color: '#666666',
                        textTransform: 'uppercase',
                      }}
                    >
                      Total List Price
                    </Text>
                  </View>
                  <View>
                    <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                      <Text
                        style={{
                          fontFamily: 'Gotham Rounded',
                          fontSize: 5,
                          color: '#000000',
                          opacity: 0.8,
                          lineHeight: 1,
                          paddingRight: 1,
                        }}
                      >
                        $
                      </Text>
                      <Text
                        style={{
                          fontFamily: 'Gotham Rounded',
                          fontWeight: 600,
                          fontSize: 8,
                          color: '#000000',
                          opacity: 0.8,
                          lineHeight: 1,
                        }}
                      >
                        {getDisplayPrice({
                          value: products.reduce((res, product) => res + product.list_price, 0),
                          currency: currency_type === CurrencyCode.Ca ? 'CAD' : 'USD',
                          hideCurrencySign: true,
                        })}
                      </Text>
                      <Text
                        style={{
                          fontFamily: 'Gotham Rounded',
                          fontSize: 5,
                          color: '#000000',
                          opacity: 0.8,
                          alignSelf: 'flex-end',
                          lineHeight: 1,
                          paddingLeft: 1,
                          paddingBottom: 1,
                        }}
                      >
                        {currency_type === CurrencyCode.Ca ? 'CAD' : 'USD'}
                      </Text>
                    </View>
                  </View>
                </View>
              )}
              {settings.show_net_price && (
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                  }}
                >
                  <View style={{ paddingRight: 20 }}>
                    <Text
                      style={{
                        fontFamily: 'Gotham Rounded',
                        fontSize: 6,
                        lineHeight: 1.5,
                        color: '#666666',
                        textTransform: 'uppercase',
                      }}
                    >
                      Total Distributor Net Price
                    </Text>
                  </View>
                  <View>
                    <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                      <Text
                        style={{
                          fontFamily: 'Gotham Rounded',
                          fontSize: 5,
                          color: '#000000',
                          opacity: 0.8,
                          lineHeight: 1,
                          paddingRight: 1,
                        }}
                      >
                        $
                      </Text>
                      <Text
                        style={{
                          fontFamily: 'Gotham Rounded',
                          fontWeight: 600,
                          fontSize: 8,
                          color: '#000000',
                          opacity: 0.8,
                          lineHeight: 1,
                        }}
                      >
                        {getDisplayPrice({
                          value: products.reduce((res, product) => res + product.net_price, 0),
                          currency: currency_type === CurrencyCode.Ca ? 'CAD' : 'USD',
                          hideCurrencySign: true,
                        })}
                      </Text>
                      <Text
                        style={{
                          fontFamily: 'Gotham Rounded',
                          fontSize: 5,
                          color: '#000000',
                          opacity: 0.8,
                          alignSelf: 'flex-end',
                          lineHeight: 1,
                          paddingLeft: 1,
                          paddingBottom: 1,
                        }}
                      >
                        {currency_type === CurrencyCode.Ca ? 'CAD' : 'USD'}
                      </Text>
                    </View>
                  </View>
                </View>
              )}
              {settings.show_ext_price && (
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                    marginTop: 2,
                  }}
                >
                  <View style={{ paddingRight: 20 }}>
                    <Text
                      style={{
                        fontFamily: 'Gotham Rounded',
                        fontSize: 6,
                        lineHeight: 1.5,
                        color: '#666666',
                        textTransform: 'uppercase',
                      }}
                    >
                      Total Extended Price
                    </Text>
                  </View>
                  <View>
                    <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                      <Text
                        style={{
                          fontFamily: 'Gotham Rounded',
                          fontSize: 5,
                          color: '#000000',
                          opacity: 0.8,
                          lineHeight: 1,
                          paddingRight: 1,
                        }}
                      >
                        $
                      </Text>
                      <Text
                        style={{
                          fontFamily: 'Gotham Rounded',
                          fontWeight: 700,
                          fontSize: 10,
                          color: '#000000',
                          opacity: 0.8,
                          lineHeight: 1,
                        }}
                      >
                        {getDisplayPrice({
                          value: products.reduce((res, product) => res + product.extended_price, 0),
                          currency: currency_type === CurrencyCode.Ca ? 'CAD' : 'USD',
                          hideCurrencySign: true,
                        })}
                      </Text>
                      <Text
                        style={{
                          fontFamily: 'Gotham Rounded',
                          fontSize: 5,
                          color: '#000000',
                          opacity: 0.8,
                          alignSelf: 'flex-end',
                          lineHeight: 1,
                          paddingLeft: 1,
                          paddingBottom: 1,
                        }}
                      >
                        {currency_type === CurrencyCode.Ca ? 'CAD' : 'USD'}
                      </Text>
                    </View>
                  </View>
                </View>
              )}
            </View>
          </View>
        )}

        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            position: 'absolute',
            width: '100%',
            top: '100%',
            right: 0,
          }}
          fixed
        >
          <View></View>
          <Text
            style={{
              fontFamily: 'Gotham Rounded',
              fontSize: 6,
              color: '#666666',
              textTransform: 'uppercase',
              paddingTop: 18,
            }}
            render={({ subPageNumber, subPageTotalPages }) =>
              subPageTotalPages > 1 && subPageNumber < subPageTotalPages ? 'The list continues on next page…' : ''
            }
          />
        </View>
      </View>
    </View>
  );
};

export default Summary;
