import * as React from 'react';
import prettyBytes from 'pretty-bytes';

import Icon from 'components/icon';
import EstimatedTimeLeft from 'my-account/components/EstimatedTimeLeft';
import { UploadProgressValue } from 'my-account/utils/image-upload';

export interface UploadImageProgressProps {
  title: string;
  uploadProgress: UploadProgressValue;
  cancelUpload: () => void;
}

export const UploadImageProgress: React.VFC<UploadImageProgressProps> = ({ uploadProgress, cancelUpload }) => {
  return (
    <>
      <div className="c-add-media c-add-media--status u-mb-spacer-base">
        <div className="c-add-media__upload-progress">
          <h6>Uploading...</h6>
          <p>
            <strong>{Math.floor((uploadProgress ? uploadProgress.loaded / uploadProgress.total : 0) * 100)}%</strong>
            {uploadProgress && (
              <>
                {' '}
                <small>
                  <EstimatedTimeLeft
                    progress={uploadProgress.loaded / uploadProgress.total}
                    startTime={uploadProgress.startTime}
                  />
                </small>
              </>
            )}
          </p>
        </div>
        <div>
          <div className="c-add-media__media-details">
            <p>
              <span>{uploadProgress?.filename ?? ''}</span> <small>{prettyBytes(uploadProgress?.total ?? 0)}</small>
            </p>
            <button
              className="c-button c-button--light-grey c-button--option"
              onClick={(event) => {
                event.preventDefault();
                cancelUpload();
              }}
              type="button"
            >
              <Icon name="close" className="o-svg-icon" />
              <span>Cancel</span>
            </button>
          </div>
          <div className="c-add-media__progress-bar">
            <span
              style={{
                width: `${Math.floor((uploadProgress ? uploadProgress.loaded / uploadProgress.total : 0) * 100)}%`,
              }}
            ></span>
          </div>
        </div>
      </div>
      <p className="c-note">The accepted media file formats are JPG, JPEG, PNG, GIF, TIF, and TIFF.</p>
    </>
  );
};

const UploadProgressModalContent: React.VFC<UploadImageProgressProps> = (props) => {
  return (
    <div className="c-block c-block--spacing-t-extra-small c-block--spacing-b-small">
      <div className="o-container-fluid">
        <div className="o-row">
          <div className="o-col-12">
            <div className="c-block__header c-block__header--hor">
              <div className="c-block__header-content u-items-center u-block u-flex@sm">
                <div>
                  <h6 className="c-headline u-text-neutral-500">{props.title}</h6>
                  <h4 className="u-mb-spacer-base-large">Image Details</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="o-row">
          <div className="o-col-12">
            <UploadImageProgress {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadProgressModalContent;
