import _range from 'lodash/range';
import SpriteIcon from 'search/components/sprite-icon';

const SelectPagination: React.VFC<{
  currentPage: number;
  pages: number;
  selectPage: (page: number) => void;
  scrollToSelector?: string;
}> = ({ currentPage, pages, selectPage, scrollToSelector }) => {
  const scrollToTop = () => {
    if (typeof scrollToSelector !== 'undefined') {
      const offset = jQuery(scrollToSelector).offset() as JQueryCoordinates;
      if (jQuery(window).scrollTop() ?? 0 > offset.top) {
        // only scroll if the top of product search is not visible
        jQuery('html, body').animate({ scrollTop: offset.top }, 500);
      }
    }
  };

  const pagesInMiddle = _range(
    currentPage - 3 >= 1 ? currentPage - 3 : 1,
    (currentPage + 3 <= pages ? currentPage + 3 : pages || 1) + 1
  );

  let items: (number | string)[] = [];

  if (pagesInMiddle[0] !== 1) {
    items.push(1);
  }

  if (pagesInMiddle[0] > 2) {
    items.push('...');
  }

  items = [...items, ...pagesInMiddle];

  if (pagesInMiddle[pagesInMiddle.length - 1] < (pages || 1) - 1) {
    items.push('...');
  }

  if (pagesInMiddle[pagesInMiddle.length - 1] !== (pages || 1)) {
    items.push(pages);
  }

  return (
    <>
      {pages > 1 && (
        <div className="ais-Pagination">
          <ul className="ais-Pagination-list">
            <li
              className={[
                'ais-Pagination-item',
                'ais-Pagination-item--previousPage',
                currentPage === 1 ? 'ais-Pagination-item--disabled' : '',
              ].join(' ')}
            >
              {currentPage === 1 ? (
                <span aria-label="Previous" className="ais-Pagination-link">
                  <SpriteIcon name="chevron" classes={['o-svg-icon', 'o-svg-left']} />
                  Previous
                </span>
              ) : (
                <>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    aria-label="Previous"
                    className="ais-Pagination-link"
                    onClick={(event) => {
                      event.preventDefault();
                      selectPage(currentPage - 1);
                      scrollToTop();
                    }}
                  >
                    <SpriteIcon name="chevron" classes={['o-svg-icon', 'o-svg-left']} />
                    Previous
                  </a>
                </>
              )}
            </li>

            {items.map((item, index) => {
              const classes = ['ais-Pagination-item', item === currentPage ? 'ais-Pagination-item--selected' : ''];

              return (
                <li key={index} className={classes.join(' ')}>
                  {typeof item === 'number' ? (
                    <>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        className="ais-Pagination-link"
                        onClick={(event) => {
                          event.preventDefault();
                          selectPage(item);
                          scrollToTop();
                        }}
                      >
                        {' '}
                        {item}{' '}
                      </a>
                    </>
                  ) : (
                    <span>...</span>
                  )}
                </li>
              );
            })}

            <li
              className={[
                'ais-Pagination-item',
                'ais-Pagination-item--nextPage',
                currentPage === (pages || 1) ? 'ais-Pagination-item--disabled' : '',
              ].join(' ')}
            >
              {currentPage === (pages || 1) ? (
                <span aria-label="Previous" className="ais-Pagination-link">
                  Next
                  <SpriteIcon name="chevron" classes={['o-svg-icon', 'o-svg-right']} />
                </span>
              ) : (
                <>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    aria-label="Previous"
                    className="ais-Pagination-link"
                    onClick={(event) => {
                      event.preventDefault();
                      selectPage(currentPage + 1);
                      scrollToTop();
                    }}
                  >
                    Next
                    <SpriteIcon name="chevron" classes={['o-svg-icon', 'o-svg-right']} />
                  </a>
                </>
              )}
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default SelectPagination;
