import classNames from 'classnames';
import Icon from 'components/icon';
import * as React from 'react';

import { ToastObject, ToastType } from 'my-account/toast/types';

export interface ToastProps extends ToastObject {
  onClose: () => void;
}

const toastIconNames: Record<ToastType, string> = {
  success: 'checkmark',
  info: 'info',
  warning: 'alert',
  error: 'alert',
};

export const Toast: React.VFC<ToastProps> = ({ id, type, title, message, autoClose = true, onClose }) => {
  React.useEffect(() => {
    if (autoClose) {
      const timeoutId = setTimeout(() => {
        onClose();
      }, 3000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [autoClose, onClose]);

  return (
    <div className={classNames(['c-toast', `c-toast--${type}`])}>
      <div className="c-toast__icon">
        <Icon name={toastIconNames[type] ?? 'info'} />
      </div>

      <div className="c-toast__main">
        <p className="c-toast__title">{typeof title === 'function' ? title({ id }) : title}</p>
        {typeof message === 'string' ? <p>{message}</p> : typeof message === 'function' ? message({ id }) : message}
      </div>

      <button
        className="c-toast__close"
        onClick={(event) => {
          event.preventDefault();
          onClose();
        }}
        type="button"
      >
        <Icon name="close-thin" />
      </button>
    </div>
  );
};
