import * as React from 'react';
import { z } from 'zod';
import { useHistory } from 'react-router-dom';
import { ForbiddenError, subject } from '@casl/ability';
import { useQueryClient } from 'react-query';
import axios from 'axios';
import classNames from 'classnames';
import merge from 'lodash/merge';
import pick from 'lodash/pick';

import { AdminUserRoleEnum, Introduction, IntroductionStatus, IntroductionVisibility } from '__generated-api__';
import api from 'api';
import { AuthStatus, useAbility, useAuth } from 'auth';
import { useMutation } from 'hooks/query';
import Form from 'components/Form/Form';
import InputField from 'components/Form/InputField';
import SubmitButton from 'components/Form/SubmitButton';
import Icon from 'components/icon';
import { useToast } from 'my-account/toast';
import CheckboxField from 'components/Form/CheckboxField';
import DisplayFieldErrors from 'components/Form/FieldError';
import { ChooseImagePlaceholder } from 'my-account/components/SPB/ImageBlockField/modal-contents/choose';
import { DisplayReactQueryError } from 'my-account/components/DisplayFieldError';
import { useFormContext, useWatch } from 'react-hook-form';
import { UploadImageProgress } from 'my-account/components/SPB/ImageBlockField/modal-contents/upload-progress';
import BlockContentImage from 'my-account/components/SPB/BlockContentImage';
import { useImageUpload } from 'my-account/utils/image-upload';
import MediaLibraryModalContent from 'my-account/components/SPB/ImageBlockField/modal-contents/library';
import EditImageModalContent from 'my-account/components/SPB/ImageBlockField/modal-contents/edit';
import { Modal } from 'my-account/components/Modal';
import { IntroductionModelBase, IntroductionModelLink } from 'my-account/validations/introduction';

const IntroductionUpdateSchema = IntroductionModelBase.omit({ id: true, status: true })
  .and(IntroductionModelLink)
  .and(
    z.object({
      visibility: z.boolean(),
    })
  );

type IntroductionDefaultValues = Partial<z.infer<typeof IntroductionUpdateSchema>>;

const getIntroductionInitialValues = (introduction: Introduction | undefined): IntroductionDefaultValues => {
  if (typeof introduction !== 'undefined') {
    return {
      name: introduction.name,
      content: introduction.content,
      link: introduction.link ? introduction.link : '',
      visibility: introduction.visibility === IntroductionVisibility.Public,
      image: introduction.image ?? undefined,
      image_settings: introduction.image_settings ?? null,
      show_link: introduction.show_link,
    };
  }

  return {
    name: '',
    content: '',
    link: '',
    visibility: false,
    image: undefined,
    image_settings: undefined,
  };
};

type IntroductionModalStateValue = 'closed' | 'image-library' | 'image-edit';

const IntroductionImageField: React.VFC<{
  openImageLibrary: () => void;
  openImageEdit: () => void;
}> = ({ openImageLibrary, openImageEdit }) => {
  const {
    setValue,
    formState: { errors },
  } = useFormContext<IntroductionDefaultValues>();

  const image = useWatch<IntroductionDefaultValues, 'image'>({ name: 'image' });
  const imageSettings = useWatch<IntroductionDefaultValues, 'image_settings'>({ name: 'image_settings' });

  const { getRootProps, getInputProps, isDragActive, open, uploadImageState, uploadProgress, cancelUpload } =
    useImageUpload({
      onChange: (data) => {
        setValue('image', data);
        setValue('image_settings', {
          left: 0,
          top: 0,
          rotate: 0,
          flip: 0,
          width: data.width,
          height: data.height,
        });
      },
    });

  return (
    <>
      <input {...getInputProps()} />

      {uploadImageState.isLoading ? (
        <UploadImageProgress title="" uploadProgress={uploadProgress} cancelUpload={cancelUpload} />
      ) : (
        <div {...getRootProps()}>
          {isDragActive ? (
            <div className="c-add-media c-add-media--active u-mb-spacer-base">
              <div>
                <Icon name="upload" className="o-svg-lg" />
              </div>

              <div>
                <button className="c-link-cta-basic c-link-cta--small" type="button">
                  <span>Drop file here...</span>
                </button>
              </div>
            </div>
          ) : typeof image !== 'undefined' ? (
            <BlockContentImage
              image={image}
              image_settings={imageSettings ?? undefined}
              openFileUpload={open}
              openImageLibrary={openImageLibrary}
              clearImage={() => {
                setValue('image', undefined);
                setValue('image_settings', undefined);
              }}
              openImageEdit={openImageEdit}
              hideImageDetails
            />
          ) : (
            <ChooseImagePlaceholder openLibrary={openImageLibrary} open={open} />
          )}
          {uploadImageState.isError && uploadImageState.error && !axios.isCancel(uploadImageState.error) && (
            <DisplayReactQueryError error={uploadImageState.error} />
          )}
          <DisplayFieldErrors error={pick(errors, 'image', 'image_settings')} hideKeyLabels />
        </div>
      )}

      <DisplayFieldErrors error={pick(errors, 'image', 'image_settings')} hideKeyLabels />
    </>
  );
};

const IntroductionMediaLibraryModalStateContent: React.VFC<{
  title: string;
  setModalState: (value: IntroductionModalStateValue) => void;
}> = ({ title, setModalState }) => {
  const { setValue } = useFormContext<IntroductionDefaultValues>();

  return (
    <MediaLibraryModalContent
      title={title}
      onInsert={(image) => {
        setValue('image', image);
        setValue('image_settings', {
          top: 0,
          left: 0,
          rotate: 0,
          flip: 0,
          width: image.width,
          height: image.height,
        });
        setModalState('closed');
      }}
    />
  );
};

const IntroductionImageEditModalStateContent: React.VFC<{
  title: string;
  setModalState: (value: IntroductionModalStateValue) => void;
}> = ({ title, setModalState }) => {
  const { setValue } = useFormContext<IntroductionDefaultValues>();

  const image = useWatch<IntroductionDefaultValues, 'image'>({ name: 'image' });
  const imageSettings = useWatch<IntroductionDefaultValues, 'image_settings'>({ name: 'image_settings' });

  React.useEffect(() => {
    // It should not happen that we are in the image-edit modal state without image in the content
    // or in the settings, but anyway adding this just in case
    if (!image) {
      setModalState('closed');
    }
  }, [setModalState, image]);

  if (image) {
    return (
      <EditImageModalContent
        title={title}
        image={image}
        settings={imageSettings ?? undefined}
        onChange={(settings) => {
          setValue('image_settings', settings);
          setModalState('closed');
        }}
        onCancel={() => {
          setModalState('closed');
        }}
      />
    );
  }

  return null;
};

export const EditIntroductionForm: React.VFC<{ introduction?: Introduction }> = ({ introduction }) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();
  const ability = useAbility();

  const [createIntroduction] = useMutation(api.introduction.createIntroduction);
  const [updateIntroduction] = useMutation(api.introduction.updateIntroduction);
  const [deleteIntroduction] = useMutation(api.introduction.deleteIntroduction);

  const [modalState, setModalState] = React.useState<IntroductionModalStateValue>('closed');

  const canSubmit = introduction
    ? ability.can('update', subject('Introduction', introduction))
    : ability.can('create', 'Introduction');
  const canRemove = introduction ? ability.can('delete', subject('Introduction', introduction)) : false;

  const auth = useAuth();
  if (auth.status !== AuthStatus.LoggedIn && auth.status !== AuthStatus.LoggingOut) {
    return null;
  }
  const user = auth.currentUser;

  const onClose = () => {
    setModalState('closed');
  };

  return (
    <Form
      schema={IntroductionUpdateSchema}
      onSubmit={async (values, ctx) => {
        ForbiddenError.from(ability).throwUnlessCan(
          introduction ? 'update' : 'create',
          subject('Introduction', merge({}, introduction, values))
        );

        const data = {
          ...values,
          image: values.image ?? null,
          image_settings: values.image_settings ?? null,
          visibility: values.visibility ? IntroductionVisibility.Public : IntroductionVisibility.Private,
          link: values.link ?? '',
        };

        if (typeof introduction === 'undefined') {
          const res = await createIntroduction([{ createIntroductionBody: data }]);
          ctx.reset(getIntroductionInitialValues(res.data));
          history.push(`/introductions/${res.data.id}`);

          toast.notify({
            type: 'success',
            title: 'Success',
            message: "You've successfully created a new Introduction.",
          });
        } else {
          const res = await updateIntroduction([{ id: introduction.id, updateIntroductionBody: data }]);
          ctx.reset(getIntroductionInitialValues(res.data));

          toast.notify({
            type: 'success',
            title: 'Success',
            message: "You've successfully updated the Introduction.",
          });
        }
      }}
      initialValues={getIntroductionInitialValues(introduction)}
      className="u-mb-0"
      hasFloatingLabels
      formProps={{ mode: 'onSubmit' }}
    >
      <section className="c-block c-block--spacing-t-extra-small c-block--spacing-b c-block--spacing-b-large@md">
        <div className="o-container-fluid">
          <div className="o-row">
            <div className="o-col-3@md">
              <p className="u-text-xs u-uppercase u-mb-spacer-base-small">Introduction</p>
              <p className="c-note">
                Create an introduction that can be reused across Specification Packages. You must supply a name,
                content, and we strongly recommend supplying an image.
              </p>
            </div>

            <div className="o-col-9@md">
              <IntroductionImageField
                openImageLibrary={() => {
                  setModalState('image-library');
                }}
                openImageEdit={() => {
                  setModalState('image-edit');
                }}
              />

              <Modal
                isOpen={modalState !== 'closed'}
                onRequestClose={onClose}
                style={{
                  content: {
                    maxWidth: modalState === 'image-library' ? '80rem' : '41.25rem',
                  },
                }}
              >
                <div
                  className={classNames('c-modal', { 'c-modal--dark c-color--invert': modalState === 'image-edit' })}
                  style={{ width: '100%' }}
                >
                  {/*eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */}
                  <a
                    href="#"
                    className="c-modal-close"
                    onClick={(event) => {
                      event.preventDefault();
                      onClose();
                    }}
                  />

                  <div className="c-modal__main">
                    {modalState === 'image-library' && (
                      <IntroductionMediaLibraryModalStateContent title="" setModalState={setModalState} />
                    )}
                    {modalState === 'image-edit' && (
                      <IntroductionImageEditModalStateContent title="" setModalState={setModalState} />
                    )}
                  </div>
                </div>
              </Modal>

              <InputField name="name" label="Name" elStyle="fill" />
              <InputField name="content" label="Content" type="textarea" elStyle="fill" />
              <CheckboxField name="show_link" label="Show Link" checkboxStyle="toggle" />
              <InputField name="link" label="Link" elStyle="fill" />
              {user.role === AdminUserRoleEnum.Admin && (
                <CheckboxField
                  name="visibility"
                  label="Make this introduction public so others can use it in their projects"
                  checkboxStyle="toggle"
                  className="u-mb-0"
                />
              )}
            </div>
          </div>

          <div className="o-row">
            <div className="o-col-12">
              <hr className="u-mt-spacer-base u-mt-spacer-base-large@sm u-mb-spacer-base-large u-mb-spacer-section-small@sm" />
            </div>
          </div>

          <div className="o-row u-items-center">
            <div className="o-col-4@md o-offset-3@md">
              <div className="c-form-footer">
                <SubmitButton variant="secondary" isFull disabled={!canSubmit}>
                  Save changes
                </SubmitButton>
              </div>
            </div>

            {canRemove && typeof introduction !== 'undefined' && (
              <div className="o-col-5@md u-text-right@md">
                {introduction.status === IntroductionStatus.Active ? (
                  /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
                  <a
                    href="#"
                    className="c-link-cta-basic c-link-cta--small u-mt-spacer-base u-mt-0@md u-mb-0"
                    onClick={async (event) => {
                      event.preventDefault();

                      if (
                        window.confirm(
                          `Are you sure that you really want to archive "${introduction.name}" introduction?`
                        )
                      ) {
                        await deleteIntroduction([{ id: introduction.id }]);

                        await Promise.all([
                          queryClient
                            .cancelQueries(api.introduction.listIntroductions.getQueryKey()[0])
                            .then(() =>
                              queryClient.invalidateQueries(api.introduction.listIntroductions.getQueryKey()[0])
                            ),
                          queryClient
                            .cancelQueries(api.introduction.getIntroduction.getQueryKey({ id: introduction.id })[0])
                            .then(() =>
                              queryClient.invalidateQueries(
                                api.introduction.getIntroduction.getQueryKey({ id: introduction.id })[0]
                              )
                            ),
                        ]);

                        history.push('/introductions');
                        toast.notify({
                          type: 'success',
                          title: 'Success',
                          message: `The "${introduction.name}" introduction was archived successfully.`,
                        });
                      }
                    }}
                  >
                    <Icon name="trash" className="o-svg-icon o-svg-larger" />
                    <span>Archive introduction</span>
                  </a>
                ) : (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a
                    href="#"
                    className="c-link-cta-basic c-link-cta--small u-mt-spacer-base u-mt-0@md u-mb-0"
                    onClick={async (event) => {
                      event.preventDefault();

                      if (
                        window.confirm(
                          `Are you sure that you really want to activate "${introduction.name}" introduction?`
                        )
                      ) {
                        await updateIntroduction([
                          {
                            id: introduction.id,
                            updateIntroductionBody: {
                              name: introduction.name,
                              status: IntroductionStatus.Active,
                            },
                          },
                        ]);
                        await Promise.all([
                          queryClient
                            .cancelQueries(api.introduction.listIntroductions.getQueryKey()[0])
                            .then(() =>
                              queryClient.invalidateQueries(api.introduction.listIntroductions.getQueryKey()[0])
                            ),
                          queryClient
                            .cancelQueries(api.introduction.getIntroduction.getQueryKey({ id: introduction.id })[0])
                            .then(() =>
                              queryClient.invalidateQueries(
                                api.introduction.getIntroduction.getQueryKey({ id: introduction.id })[0]
                              )
                            ),
                        ]);
                        toast.notify({
                          type: 'success',
                          title: 'Success',
                          message: `The "${introduction.name}" introduction was activated successfully.`,
                        });
                      }
                    }}
                  >
                    <Icon name="export" className="o-svg-icon o-svg-larger" />
                    <span>Activate introduction</span>
                  </a>
                )}
              </div>
            )}
          </div>
        </div>
      </section>
    </Form>
  );
};
