import { z } from 'zod';

export const CompanyRelationUpdateSchema = z.object({
  id: z.number(),
  name: z.string().optional().nullable(),
});

export const CompanyRelationSchema = z.object({
  id: z.number(),
  name: z.string(),
  addresses: z.array(
    z.object({
      bill_to_id: z.number(),
      name: z.string().optional().nullable(),
      address: z.string().optional().nullable(),
      city: z.string().optional().nullable(),
      state: z.string().optional().nullable(),
      zip_code: z.string().optional().nullable(),
      longitude: z.number().optional().nullable(),
      latitude: z.number().optional().nullable(),
      locations: z
        .array(
          z.object({
            id: z.number(),
            company_id: z.number(),
            bill_to_id: z.number(),
            ship_to_id: z.string(),
            name: z.string().optional().nullable(),
            address: z.string().optional().nullable(),
            city: z.string().optional().nullable(),
            state: z.string().optional().nullable(),
            zip_code: z.string().optional().nullable(),
          })
        )
        .optional(),
    })
  ),
  sales_reps: z.array(
    z.object({
      id: z.number(),
      first_name: z.string().optional().nullable(),
      last_name: z.string().optional().nullable(),
      email: z.string().optional().nullable(),
      phone: z.string().optional().nullable(),
    })
  ),
});
