import isString from 'lodash/isString';

interface BaseObjectWithAddress {
  address?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  zip_code?: string | null;
  phone?: string | null;
}

export const AddressText = <T extends BaseObjectWithAddress>({ address }: { address?: T | null }) => {
  if (!address) {
    return null;
  }

  const firstLine = address.address ? address.address.trim() : '';
  const secondLine = [address.city, address.state, address.zip_code]
    .filter(isString)
    .map((val) => val.trim())
    .filter((val) => val.length)
    .join(' ');

  if (!firstLine.length && !secondLine.length) {
    return null;
  }

  return (
    <>
      {firstLine}
      {firstLine.length > 0 && secondLine.length > 0 && <br />}
      {secondLine}
    </>
  );
};

export const getAddressText = <T extends BaseObjectWithAddress>({ address }: { address?: T | null }) => {
  if (!address) {
    return null;
  }

  const firstLine = address.address ? address.address.trim() : '';
  const secondLine = [address.city, address.state, address.zip_code]
    .filter(isString)
    .map((val) => val.trim())
    .filter((val) => val.length)
    .join(' ');

  if (!firstLine.length && !secondLine.length) {
    return null;
  }

  return (
    <>
      {firstLine}
      {firstLine.length > 0 && secondLine.length > 0 && <br />}
      {secondLine}
    </>
  );
};
