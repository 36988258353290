import { z } from 'zod';
import { FileModelAvatarTypeEnum, FileModelFileTypeEnum, FileModelImageTypeEnum, UserRole } from '__generated-api__';
import { CompanyRelationUpdateSchema } from './company';

const FileRelationUpdateSchemaShared = z.object({
  id: z.number(),
  name: z.string().optional().nullable(),
  mime: z.string().optional().nullable(),
  title: z.string().optional().nullable(),
  thumbnail: z.string().optional().nullable(),
  private_url: z.string().optional().nullable(),
  image: z.string().optional().nullable(),
  size: z.number().optional().nullable(),
});

export const FileFileRelationUpdateSchema = FileRelationUpdateSchemaShared.extend({
  type: z.literal(FileModelFileTypeEnum.File),
});

export const FileAvatarRelationUpdateSchema = FileRelationUpdateSchemaShared.extend({
  type: z.literal(FileModelAvatarTypeEnum.Avatar),
  width: z.number().optional().nullable(),
  height: z.number().optional().nullable(),
});

export const FileImageRelationUpdateSchema = FileRelationUpdateSchemaShared.extend({
  type: z.literal(FileModelImageTypeEnum.Image),
  width: z.number().optional().nullable(),
  height: z.number().optional().nullable(),
});

export const AnyFileImageRelationUpdateSchema = FileRelationUpdateSchemaShared.extend({
  type: z.literal(FileModelImageTypeEnum.Image).or(z.literal(FileModelAvatarTypeEnum.Avatar)),
  width: z.number().optional().nullable(),
  height: z.number().optional().nullable(),
});

export const FileRelationUpdateSchema = z.union([
  FileFileRelationUpdateSchema,
  FileAvatarRelationUpdateSchema,
  FileImageRelationUpdateSchema,
]);

const FileUpdateShared = z.object({
  id: z.number(),
  status: z.string().optional().nullable(),
  name: z.string().optional().nullable(),
  mime: z.string().optional().nullable(),
  size: z.number().optional().nullable(),
  title: z.string().optional().nullable(),
  thumbnail: z.string().optional().nullable(),
  image: z.string().optional().nullable(),
  private_url: z.string().optional().nullable(),
  user: z
    .object({
      id: z.number(),
      first_name: z.string().optional().nullable(),
      last_name: z.string().optional().nullable(),
      email: z.string().optional().nullable(),
      role: z.nativeEnum(UserRole).optional().nullable(),
      image: AnyFileImageRelationUpdateSchema.optional().nullable(),
      phone: z.string().optional().nullable(),
      company: CompanyRelationUpdateSchema.optional().nullable(),
    })
    .optional()
    .nullable(),
  created_at: z.string().nullable(),
  updated_at: z.string().nullable(),
});

export const FileUpdateImageSchema = FileUpdateShared.extend({
  type: z.nativeEnum(FileModelImageTypeEnum),
  width: z.number().optional().nullable(),
  height: z.number().optional().nullable(),
});

// export const FileUpdateAvatarSchema = FileUpdateShared.extend({
//   type: z.nativeEnum(FileModelAvatarTypeEnum),
//   width: z.number(),
//   height: z.number(),
// });

// export const FileUpdateFileSchema = FileUpdateShared.extend({ type: z.nativeEnum(FileModelFileTypeEnum) });

// export const FileUpdateSchema = FileUpdateFileSchema.or(FileUpdateImageSchema).or(FileUpdateAvatarSchema);

export const ImageSettings = z.object({
  top: z.number(),
  left: z.number(),
  width: z.number(),
  height: z.number(),
  rotate: z.number(),
  flip: z.number(),
});
