import * as React from 'react';
import { Image } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

import { getCroppedImage, getCroppedImageSize, UseCroppedImageProps } from 'spb-pdf/utils/image';

export interface ImageBlockImageProps {
  image: {
    content: UseCroppedImageProps['image'];
    settings?: UseCroppedImageProps['settings'];
  };
  style?: Style | Style[];
}

const ImageBlockImage: React.VFC<ImageBlockImageProps> = ({ image, style }) => {
  const source = getCroppedImage({ image: image.content, settings: image.settings });
  const size = getCroppedImageSize({ image: image.content, settings: image.settings });

  return (
    <Image
      source={source}
      style={[
        {
          width: size.width,
          height: size.height,
        },
        ...(typeof style !== 'undefined' ? (Array.isArray(style) ? style : [style]) : []),
      ]}
    />
  );
};

export default ImageBlockImage;
