import ContentLoader from 'react-content-loader';

const shades: Record<string, { backgroundColor: string; foregroundColor: string }> = {
  f: { backgroundColor: '#f3f3f3', foregroundColor: '#ecebeb' },
  e: { backgroundColor: '#ecebeb', foregroundColor: '#dcdbdb' },
  '3': { backgroundColor: '#3f3f3f', foregroundColor: '#4f4f4f' },
};

export const CountPlaceholder: React.VFC<{
  size: number | { width: number; height: number };
  shade?: keyof typeof shades;
}> = ({ size, shade = 'f' }) => {
  let width: number, height: number;

  if (typeof size === 'number') {
    width = height = size;
  } else {
    width = size.width;
    height = size.height;
  }

  return (
    <ContentLoader
      speed={2}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      style={{ verticalAlign: 'baseline', transform: 'translateY(4px)' }}
      {...shades[shade]}
    >
      <rect x="0" y="0" rx="4" ry="4" width={width} height={height} />
    </ContentLoader>
  );
};
