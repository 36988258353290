import { useHistory, useLocation } from 'react-router';
import { useMount } from 'react-use';
import { z } from 'zod';

import { useAuth } from 'auth';
import { useToast } from 'my-account/toast';
import { useErrorHandler } from 'my-account/utils/error-handler';
import useDocumentTitle from 'hooks/useDocumentTitle';

const salesforceUrlStateDataSchema = z.object({
  action: z.literal('create'),
  cid: z.number(),
  oid: z.number(),
});

export default function SalesforceSSOPage() {
  useDocumentTitle('Salesforce SSO');
  const toast = useToast();
  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();
  const { clearErrors, handleError } = useErrorHandler();

  useMount(() => {
    const searchParams = new URLSearchParams(location.search);
    const action = searchParams.get('action');
    const code = searchParams.get('code');
    const state = searchParams.get('state');

    clearErrors();

    if ('verify' === action) {
      if (!code) {
        toast.notify({
          type: 'error',
          title: 'Error',
          message: 'Invalid request.',
          autoClose: false,
        });

        history.replace('/auth/login');

        return;
      }

      auth
        .loginSalesforce(code)
        .then(() => {
          if (state) {
            const decodedState = atob(state);
            const validatedState = salesforceUrlStateDataSchema.safeParse(JSON.parse(decodedState));
            if (validatedState.success) {
              history.replace(
                `/specification-packages/add?customer_id=${validatedState.data.cid}&opportunity_id=${validatedState.data.oid}`
              );
              return;
            }
          }

          history.replace('/profile');
        })
        .catch((error) => {
          handleError(error);

          history.replace('/auth/login');

          throw error;
        });
    } else {
      window.location.href = process.env.REACT_APP_API_BASE_URL + '/auth/salesforce';
    }
  });

  return (
    <div className="o-container-fluid">
      <div className="o-row">
        <div className="o-col-12">
          <div className="c-listing__none-wrapper c-listing__none-wrapper--fixed">
            <div className="c-listing__none">
              <div className="c-listing__none-spinner"></div>
              <p className="c-listing__none-title">Loading...</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
