import * as React from 'react';
import wordpressData from 'data';

interface SpriteIconProps {
  name: string;
  classes?: string[];
}

class SpriteIcon extends React.Component<SpriteIconProps> {
  render() {
    const classes = ['o-icon', ...(this.props.classes || [])];
    const path = `${wordpressData.themeAssetsUrl}/sprite/icons.svg#icon-${this.props.name}`;
    return (
      <>
        <svg className={classes.join(' ')}>
          <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={path}></use>
        </svg>
      </>
    );
  }
}

export default SpriteIcon;
