import classNames from 'classnames';
import * as React from 'react';

import { RegisteredUser, SentQuoteStatusEnum } from '__generated-api__';
import { AuthStatus, useAuth } from 'auth';
import { Button } from 'components/Button';
import Icon from 'components/icon';
import { Modal } from 'my-account/components/Modal';
import { NewProjectQuoteModal } from 'my-account/components/NewProjectQuoteModal';
import { usePendingQuote } from 'my-account/components/PendingQuote/context';
import { RequestProjectOrderForm } from 'my-account/forms/RequestProjectOrder';
import { RequestProjectQuoteForm } from 'my-account/forms/RequestProjectQuote';
import { DisplayPrice } from 'my-account/utils/currency';
import { getTransformedSalsifyImage } from 'my-account/utils/salsify';

export enum PendingQuoteModalStep {
  Summary = 'summary',
  Shipping = 'shipping',
  Confirmation = 'confirmation',
}

export const PendingQuoteModal: React.VFC<{
  isOpen?: boolean;
  onRequestClose: () => void;
  hasConfirmation?: boolean;
}> = ({ isOpen = false, onRequestClose, hasConfirmation }) => {
  const auth = useAuth();
  let user: RegisteredUser | undefined;
  if (auth.status === AuthStatus.LoggedIn) {
    user = auth.currentUser;
  }
  const { quote, closeModal, updateProducts, isLoading, canAddToQuote, canCreateQuote } = usePendingQuote();
  const [step, setStep] = React.useState(
    quote && quote.status === SentQuoteStatusEnum.Sent && hasConfirmation
      ? PendingQuoteModalStep.Confirmation
      : PendingQuoteModalStep.Summary
  );
  const [total, setTotal] = React.useState(0);

  React.useEffect(() => {
    if (quote) {
      const calc =
        quote.products?.reduce(
          (result, product) => result + Number(product.pivot.quoted_price ?? product.price) * product.pivot.quantity,
          0
        ) ?? 0;
      setTotal(calc);
    }
  }, [quote]);

  if (!canCreateQuote) {
    return null;
  }

  if (!canAddToQuote) {
    return <NewProjectQuoteModal isOpen={isOpen} onRequestClose={onRequestClose} />;
  }

  if (!quote) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      id="quote-modal"
      style={{ content: { maxWidth: '59.375rem' }, overlay: { zIndex: 1002 } }}
    >
      <div className="c-modal c-modal--split-content">
        {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */}
        <a
          href="#"
          className="c-modal-close"
          onClick={(event) => {
            event.preventDefault();
            closeModal();
          }}
        ></a>

        <div className="c-modal__main">
          <div className="c-block c-block--bg-primary c-block--spacing-t-small c-block--spacing-b-small c-color--white">
            <div className="o-container-fluid">
              <div className="c-block__pattern-circle c-block__pattern--left c-block__pattern--light3 c-block__overlay--opacity-20">
                <Icon name="pattern-circle" className="o-svg-icon" />
              </div>

              <div className="c-block__header">
                <h5 className="c-title--large">
                  {user?.direct_buyer ? (
                    <>
                      <strong>New Order</strong>
                    </>
                  ) : (
                    <>
                      My <strong>Project Quote</strong> Request
                    </>
                  )}
                </h5>
                {user?.direct_buyer ? (
                  <p>
                    On the Order Summary, you can review the items and amounts for your order. You can also add or
                    reduce the item amounts or delete the item entirely. When you’re done, click the Confirmation button
                    to move to Step 2 to add the project details and attach a file, if needed.
                  </p>
                ) : (
                  <p>
                    On the Quote Summary, you can review the items and amounts for your quote request. You can also add
                    or reduce the item amounts or delete the item entirely. When you’re done, click the Confirmation
                    button to move to Step 2 to add the project details and attach a file, if needed.
                  </p>
                )}

                {hasConfirmation && (
                  <div className="u-text-center u-mt-spacer-base">
                    <ol className="c-steps-nav">
                      <li
                        className={classNames('c-steps-nav__item', {
                          'is-current': step === PendingQuoteModalStep.Summary,
                          'is-active': step !== PendingQuoteModalStep.Summary,
                        })}
                        onClick={() => {
                          setStep(PendingQuoteModalStep.Summary);
                        }}
                      >
                        <p className="c-steps-nav__label">Summary</p>
                      </li>
                      {user?.direct_buyer && (
                        <li
                          className={classNames('c-steps-nav__item', {
                            'is-current': step === PendingQuoteModalStep.Shipping,
                            'is-active': step !== PendingQuoteModalStep.Shipping,
                          })}
                          onClick={() => {
                            setStep(PendingQuoteModalStep.Shipping);
                          }}
                        >
                          <p className="c-steps-nav__label">Shipping</p>
                        </li>
                      )}
                      <li
                        className={classNames('c-steps-nav__item', {
                          'is-current': step === PendingQuoteModalStep.Confirmation,
                        })}
                        onClick={() => {
                          setStep(PendingQuoteModalStep.Confirmation);
                        }}
                      >
                        <p className="c-steps-nav__label">Confirmation</p>
                      </li>
                    </ol>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="c-block c-block--spacing-t-small c-block--spacing-b-small">
            {step === PendingQuoteModalStep.Summary && (
              <div className="o-container-fluid">
                <div className="c-block__header">
                  <h5 className="c-title--xxs">
                    {user?.direct_buyer ? (
                      <>
                        <strong>Order Summary</strong>
                      </>
                    ) : (
                      <>
                        <strong>Quote Summary</strong>
                      </>
                    )}
                  </h5>
                </div>

                <div className="c-order-summary-item-list">
                  {typeof quote.products !== 'undefined' && quote.products.length > 0 ? (
                    quote.products.map((product) => (
                      <div className="c-order-summary-item" key={product.sku}>
                        <div>
                          {product.main_image !== null && typeof product.main_image !== 'undefined' && (
                            <img
                              src={getTransformedSalsifyImage(product.main_image, ['w_160', 'h_160', 'c_pad'])}
                              className="c-order-summary-item__image"
                              alt={product.main_image.salsify_name}
                              width="160"
                              height="160"
                            />
                          )}
                        </div>

                        <div>
                          <p className="c-order-summary-item__title">{product.title}</p>

                          <ul className="c-order-summary-item__list">
                            {product.installation_guide && (
                              <li>
                                <a href={product.installation_guide.salsify_url} target="_blank" rel="noreferrer">
                                  Installation
                                </a>
                              </li>
                            )}

                            {product.spec_sheet && (
                              <li>
                                <a href={product.spec_sheet.salsify_url} target="_blank" rel="noreferrer">
                                  Submittal
                                </a>
                              </li>
                            )}
                          </ul>

                          <p className="c-order-summary-item__price">
                            <DisplayPrice value={Number(product.pivot.quoted_price ?? product.price)} />
                          </p>
                        </div>

                        <div className="c-number-input js-number-input u-ml-auto">
                          <div className="c-number-input__input">
                            <button
                              className="c-number-input__button c-number-input__button--remove"
                              type="button"
                              onClick={(event) => {
                                event.preventDefault();

                                updateProducts(
                                  quote.products!.map((item) => {
                                    if (item.sku === product.sku) {
                                      return { ...item, pivot: { ...item.pivot, quantity: item.pivot.quantity - 1 } };
                                    }
                                    return item;
                                  })
                                );
                              }}
                            >
                              <Icon name="remove" />
                            </button>

                            <input
                              type="number"
                              value={product.pivot.quantity}
                              min="1"
                              onChange={(event) => {
                                let quantity = event.target.valueAsNumber;

                                if (Number.isNaN(quantity) || quantity < 0) {
                                  quantity = 0;
                                }

                                updateProducts(
                                  quote.products!.map((item) => {
                                    if (item.sku === product.sku) {
                                      return { ...item, pivot: { ...item.pivot, quantity } };
                                    }
                                    return item;
                                  })
                                );
                              }}
                            />

                            <button
                              className="c-number-input__button c-number-input__button--add"
                              type="button"
                              onClick={(event) => {
                                event.preventDefault();

                                updateProducts(
                                  quote.products!.map((item) => {
                                    if (item.sku === product.sku) {
                                      return { ...item, pivot: { ...item.pivot, quantity: item.pivot.quantity + 1 } };
                                    }
                                    return item;
                                  })
                                );
                              }}
                            >
                              <Icon name="add" />
                            </button>
                          </div>
                        </div>

                        <button
                          className="c-order-summary-item__delete"
                          type="button"
                          onClick={(event) => {
                            event.preventDefault();

                            updateProducts(quote.products!.filter((item) => item.sku !== product.sku));
                          }}
                        >
                          <Icon name="trash" />
                        </button>
                      </div>
                    ))
                  ) : (
                    <p>No products added.</p>
                  )}
                  <div className="c-order-summary-item">
                    <div>
                      <p className="c-order-summary-item__title">Shipping Cost</p>
                    </div>
                    <div className="u-ml-auto">
                      <p className="c-order-summary-item__price ">
                        {total > 999.99 ? (
                          'Free Shipping'
                        ) : (
                          <>
                            <DisplayPrice
                              value={
                                quote.products?.reduce(
                                  (result, product) =>
                                    result + Number(product.shipping_weight) * product.pivot.quantity * 0.25,
                                  0
                                ) + 11.05 ?? 0
                              }
                            />
                            &nbsp;USD
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="o-row u-items-center">
                  <div className="o-col-5@sm">
                    {typeof quote.products !== 'undefined' && quote.products.length > 0 && (
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      <a
                        href="#"
                        className="c-link-cta-basic c-link-cta-basic--small u-mt-3 u-mb-spacer-base u-mb-0@sm"
                        onClick={(event) => {
                          event.preventDefault();

                          if (window.confirm('Are you sure that you want to remove all products from the quote?')) {
                            updateProducts([]);
                          }
                        }}
                      >
                        {user?.direct_buyer ? <span>Clear cart</span> : <span>Clear quote</span>}
                      </a>
                    )}
                  </div>
                  {hasConfirmation && (
                    <div className="o-col-7@sm u-text-right@sm">
                      <div className="c-button-w-label">
                        <Button
                          rightIcon="arrow"
                          isLoading={isLoading}
                          onClick={(event) => {
                            event.preventDefault();
                            if (user?.direct_buyer) {
                              setStep(PendingQuoteModalStep.Shipping);
                            } else {
                              setStep(PendingQuoteModalStep.Confirmation);
                            }
                          }}
                        >
                          {user?.direct_buyer ? 'Checkout' : 'Confirmation'}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {(step === PendingQuoteModalStep.Confirmation || step === PendingQuoteModalStep.Shipping) && (
              <div className="o-container-fluid">
                {user?.direct_buyer ? (
                  <RequestProjectOrderForm
                    className="u-mb-spacer-base-small"
                    step={step}
                    setStep={setStep}
                    onSubmit={() => {
                      closeModal();
                    }}
                    renderFooter={(submitButton) => (
                      <div className="o-row u-items-center">
                        <div className="o-col@sm">
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <a
                            href="#"
                            className="c-link-cta-basic c-link-cta-basic--small u-mb-spacer-base u-mb-0@sm"
                            onClick={(event) => {
                              event.preventDefault();
                              setStep(PendingQuoteModalStep.Summary);
                            }}
                          >
                            <Icon name="arrow" className="o-svg-icon o-svg-left" /> <span>Summary</span>
                          </a>
                        </div>
                        <div className="o-col@sm u-text-right@sm">{submitButton}</div>
                      </div>
                    )}
                  />
                ) : (
                  <>
                    <div className="c-block__header u-mb-spacer-base-small">
                      <h5 className="c-title--xxs">
                        <strong>Confirmation</strong>
                      </h5>
                    </div>
                    <RequestProjectQuoteForm
                      className="u-mb-spacer-base-small"
                      onSubmit={() => {
                        closeModal();
                      }}
                      renderFooter={(submitButton) => (
                        <div className="o-row u-items-center">
                          <div className="o-col@sm">
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a
                              href="#"
                              className="c-link-cta-basic c-link-cta-basic--small u-mb-spacer-base u-mb-0@sm"
                              onClick={(event) => {
                                event.preventDefault();
                                setStep(PendingQuoteModalStep.Summary);
                              }}
                            >
                              <Icon name="arrow" className="o-svg-icon o-svg-left" /> <span>Summary</span>
                            </a>
                          </div>
                          <div className="o-col@sm u-text-right@sm">{submitButton}</div>
                        </div>
                      )}
                    />
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
