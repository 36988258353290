import algoliasearchHelper from 'algoliasearch-helper';
import _pick from 'lodash/pick';
import { Hit } from 'react-instantsearch-core';
import { Price, ProductHitInterface } from 'search/components/product-search/product-hit';
import { VariantAffectingFilters } from 'search/stores/product-search-store';
import axios from 'axios';
import wordpressData from 'data';
import api from 'api';
import { ConfigurationMeta, CreateShowerConfigurationBodyProducts } from '__generated-api__';

// All possible configuration types
export type configuratorTypes = 'shower';
export interface configuratorStoreData {
  type: configuratorTypes;
  ada_compliant?: boolean;
  finish?: string;
  flow?: string;
  tier?: string;
  style?: string;
  meta: {
    shower_category?: string;
  };
  products: {
    shower?: string;
    shower_head?: string;
    tub_spout?: string;
    valve?: string;
  };
}

export type ConfiguratorSpecifications = Pick<
  configuratorStoreData,
  'ada_compliant' | 'finish' | 'flow' | 'tier' | 'style' | 'meta'
>;

const cached_product_hits: { [key: string]: Hit<ProductHitInterface> } = {};

const load_product_hits = (skus: string[] | string) => {
  return axios
    .get<typeof cached_product_hits>(wordpressData.ajaxUrl, {
      params: { sku: skus, action: 'symmons_product_hits_form_wordpress_by_sku' },
    })
    .then((response) => {
      const loaded: string[] = [];
      Object.values(response.data).forEach((hit) => {
        loaded.push(hit.sku);
        cached_product_hits[hit.sku] = hit;
        hit?.variants.forEach((variant_hit) => {
          cached_product_hits[variant_hit.sku] = hit;
          loaded.push(variant_hit.sku);
        });
      });
      return loaded;
    });
};

export default class ProductConfiguratorStore {
  configuration_key?: string;
  authorization_key?: string;
  main_selection = true;
  error?: { title: string; message: string };
  is_loaded = false;
  data: configuratorStoreData = {
    type: 'shower',
    meta: {},
    products: {},
  };

  refresh() {}

  constructor(type: configuratorTypes, refresh?: () => any) {
    if (refresh) {
      this.refresh = refresh;
    }
    this.data.type = type;
  }

  load_configuration(configuration_key?: string | null) {
    if (configuration_key) {
      // Load configuration from middleware api by key
      this.configuration_key = configuration_key;
      api.showerConfiguration
        .getShowerConfiguration({ configurationKey: configuration_key })
        .then((response) => {
          const configuration_data = response.data;
          this.data.ada_compliant = configuration_data.ada_compliant;
          this.data.finish = configuration_data.finish ? configuration_data.finish.term : undefined;
          this.data.flow = configuration_data.flow ? configuration_data.flow.term : undefined;
          this.data.tier = configuration_data.tier ? configuration_data.tier.term : undefined;
          this.data.style = configuration_data.style ? configuration_data.style.term : undefined;
          this.data.products = {};
          configuration_data.products.forEach((item) => {
            this.data.products[item.pivot.type as keyof configuratorStoreData['products']] = item.sku;
          });
          this.data.meta = {};
          configuration_data.meta.forEach((item) => {
            this.data.meta[item.key as keyof configuratorStoreData['meta']] = item.value;
          });

          this.main_selection = Boolean(this.data.products[this.type]);
          load_product_hits(Object.values(this.data.products))
            .then(() => {
              // Remove deleted products
              (Object.keys(this.data.products) as Array<keyof configuratorStoreData['products']>).forEach((key) => {
                if (typeof cached_product_hits[this.data.products[key] as string] === 'undefined') {
                  delete this.data.products[key];
                }
              });

              // Check for authorization keys to enable editing
              const authorization_keys = JSON.parse(
                window.localStorage.getItem('productConfiguratorAuthorizationKeys') || '{}'
              );

              // Use authorization kay for editing if it is stored localy for this configuration
              if (authorization_keys[configuration_key]) {
                this.authorization_key = authorization_keys[configuration_key];
              } else if (typeof this.data.products[this.type] === 'undefined') {
                // Products can be deleted from middleware db so if user can't edit and there is no main products selected set error message
                this.error = {
                  title: 'Empty configuration.',
                  message: 'Configuration has no products for now.',
                };
              }

              //If main product is not selected set main selection screen
              this.main_selection = !this.data.products[this.type];
              this.is_loaded = true;

              this.refresh();
            })
            .catch((error) => {
              this.error = {
                title: 'Something went wrong.',
                message: 'Could not load configuration products, please try again later.',
              };
              this.refresh();
            });
        })
        .catch((error) => {
          if (error.response?.status === 404) {
            this.error = {
              title: '404: Configuration not found.',
              message: this.type_label(this.type, true) + ' configuration does not exist.',
            };
          } else {
            this.error = {
              title: 'Something went wrong.',
              message: 'Something went wrong please try again.',
            };
          }
          console.log(error);
          this.refresh();
        });
    } else {
      // Load from local storage (configuration that was not saved in middleware)
      if (window.localStorage.getItem(this.data.type + 'ProductConfiguration')) {
        Object.assign(
          this.data,
          _pick(JSON.parse(window.localStorage.getItem(this.data.type + 'ProductConfiguration') || '{}'), [
            'ada_compliant',
            'finish',
            'flow',
            'tier',
            'style',
            'meta',
            'products',
          ])
        );

        load_product_hits(Object.values(this.data.products)).then(() => {
          // Remove deleted products
          (Object.keys(this.data.products) as Array<keyof configuratorStoreData['products']>).forEach((key) => {
            if (typeof cached_product_hits[this.data.products[key] as string] === 'undefined') {
              delete this.data.products[key];
            }
          });

          this.main_selection = !this.data.products[this.type];
          this.is_loaded = true;
          this.refresh();
        });
      } else {
        this.is_loaded = true;
        this.refresh();
      }
    }
  }

  get can_edit() {
    return Boolean(
      (this.configuration_key && this.authorization_key) || (!this.configuration_key && !this.authorization_key)
    );
  }

  delete_local_storage() {
    window.localStorage.removeItem(this.data.type + 'ProductConfiguration');
  }

  save_middleware_record() {
    const configuration_body = {
      ada_compliant: this.data.ada_compliant,
      finish: this.data.finish,
      tier: this.data.tier,
      flow: this.data.flow,
      style: this.data.style,
      products: [] as Array<CreateShowerConfigurationBodyProducts>,
      meta: [] as Array<ConfigurationMeta>,
    };
    (Object.keys(this.data.products) as Array<keyof configuratorStoreData['products']>).forEach((type) => {
      configuration_body.products.push({
        type: type,
        sku: this.data.products[type] as CreateShowerConfigurationBodyProducts['sku'],
      });
    });
    (Object.keys(this.data.meta) as Array<keyof configuratorStoreData['meta']>).forEach((key) => {
      configuration_body.meta.push({
        key: key,
        value: this.data.meta[key] as ConfigurationMeta['value'],
      });
    });

    if (!this.configuration_key && !this.authorization_key) {
      // Configuration is stored only localy so we will create record in middleware
      api.showerConfiguration
        .createShowerConfiguration({ createShowerConfigurationBody: configuration_body })
        .then((response) => {
          this.configuration_key = response.data.key;
          this.authorization_key = response.data.authorization_key;

          // Save authorization key locally to enable editing for guest users in the future
          const authorization_keys = JSON.parse(
            window.localStorage.getItem('productConfiguratorAuthorizationKeys') || '{}'
          );
          authorization_keys[this.configuration_key] = this.authorization_key;
          window.localStorage.setItem('productConfiguratorAuthorizationKeys', JSON.stringify(authorization_keys));

          // Replace history and change url to have configuration key parameter to enable sharing by link
          const configuration_url = new URL(window.location.href);
          configuration_url.searchParams.set('configuration', this.configuration_key);
          configuration_url.search = configuration_url.searchParams.toString();
          window.history.replaceState({}, 'Product configuration', configuration_url.toString());

          // Delete local storage configuration since it is now stored in middleware and we can load it with configuration key
          this.delete_local_storage();
          this.refresh();
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (this.configuration_key && this.authorization_key) {
      // Update the existing configuration record in middleware
      api.showerConfiguration
        .updateShowerConfiguration({
          configurationKey: this.configuration_key,
          createShowerConfigurationBody: configuration_body,
          authorizationKey: this.authorization_key,
        })
        .then((response) => {
          this.refresh();
        })
        .catch((error) => {
          if (error.response?.status === 403) {
            console.log(error.response?.status);
            this.error = {
              title: '403 Forbidden.',
              message: 'You are not authorized to edit this configuration.',
            };
            delete this.authorization_key;
            this.refresh();
          }
          console.log(error);
        });
    }
  }

  update_local_storage() {
    window.localStorage.setItem(this.data.type + 'ProductConfiguration', JSON.stringify(this.data));
    this.refresh();
  }

  should_load_product_hits() {
    return Boolean(
      Object.values(this.data.products).filter((sku) => typeof cached_product_hits[sku] === 'undefined').length
    );
  }

  type_label(type: keyof configuratorStoreData['products'] = this.data.type, uppercase = false) {
    let label = type.replace(/_/g, ' ');
    return uppercase ? label.charAt(0).toUpperCase() + label.slice(1) : label;
  }

  get type() {
    return this.data.type;
  }

  set specifications(specifications: ConfiguratorSpecifications) {
    Object.assign(this.data, _pick(specifications, ['ada_compliant', 'finish', 'flow', 'tier', 'style', 'meta']));
    this.data.products = {};
    this.main_selection = true;

    // Store specifications product filtering in local storage if configuration is not saved in middleware
    if (!this.configuration_key && !this.authorization_key) {
      this.update_local_storage();
    }
  }

  get specifications() {
    const specifications: ConfiguratorSpecifications = {
      ada_compliant: this.data.ada_compliant,
      finish: this.data.finish,
      flow: this.data.flow,
      tier: this.data.tier,
      style: this.data.style,
      meta: { ...this.data.meta },
    };
    return specifications;
  }

  getSearchParameters(type: keyof configuratorStoreData['products'] = this.type, specifications = this.specifications) {
    const parameters: algoliasearchHelper.PlainSearchParameters = {};
    const filters: string[] = ['(is_obsolete:"false")'];
    if (specifications.ada_compliant) {
      filters.push(`(special_attributes:"ADA Compliant")`);
    }
    if (specifications.finish) {
      parameters.disjunctiveFacets = ['finishes'];
      parameters.disjunctiveFacetsRefinements = { finishes: [specifications.finish] };
    }
    if (specifications.tier) {
      filters.push(`(availabilities:"${specifications.tier}")`);
    }
    if (specifications.flow) {
      filters.push(`(flow_rate_gpm:"${specifications.flow}")`);
    }

    if (specifications.style) {
      filters.push(`(style:"${specifications.style}")`);
    }

    // Specific filters for product type search.
    if (type === 'shower') {
      if (specifications.meta.shower_category) {
        filters.push(`(shower_category:"${specifications.meta.shower_category}")`);
      } else {
        filters.push(`(categories:"Showers")`);
      }
    } else if (type === 'shower_head') {
      filters.push(`(shower_category:"SHO")`);
    } else if (type === 'tub_spout') {
      filters.push(`(shower_category:"TSO")`);
    }

    return { ...parameters, filters: filters.join(' AND ') };
  }

  // This will return base product hit based on sku code (also works varian product skus)
  getConfigurationProductHit(type: keyof configuratorStoreData['products'] = this.data.type) {
    const sku = this.data.products[type];
    return sku ? cached_product_hits[sku] : undefined;
  }

  getConfigurationProductSku(type: keyof configuratorStoreData['products'] = this.data.type) {
    return this.data.products[type];
  }

  // Get price data from product hit data and its variants based on sku code
  getConfigurationProductPrice(type: keyof configuratorStoreData['products'] = this.data.type) {
    const sku = this.getConfigurationProductSku(type);
    if (sku) {
      const hit = this.getConfigurationProductHit(type);
      const price_key = wordpressData.visitorCountryCode === 'CA' ? 'ca_price' : 'us_price';
      const price_currency = wordpressData.visitorCountryCode === 'CA' ? 'CAD' : 'USD';
      if (hit && typeof hit[price_key] !== 'undefined') {
        if (hit.sku === sku) {
          return new Price(hit[price_key] as number, price_currency);
        } else {
          const variant = hit.variants.find((variant) => variant.sku === sku);
          if (variant) {
            return new Price(variant[price_key] as number, price_currency);
          }
        }
      }
    }

    return undefined;
  }

  cache_product_hit(hit: Hit<ProductHitInterface>) {
    cached_product_hits[hit.sku] = hit;
    hit.variants.forEach((variant_hit) => {
      cached_product_hits[variant_hit.sku] = hit;
    });
  }

  setConfigurationProduct(
    type: keyof configuratorStoreData['products'],
    sku: string | undefined,
    hit?: Hit<ProductHitInterface>
  ) {
    if (sku !== this.data.products[type] || !this.configuration_key) {
      if (sku) {
        this.data.products[type] = sku;
        if (hit) {
          this.cache_product_hit(hit);
        }
      } else {
        // If sku is undefined remove the product
        delete this.data.products[type];
      }

      // Save configuration every time when new product is selected
      this.save_middleware_record();
    }

    this.refresh();
  }

  validateSpecifications(specifications: ConfiguratorSpecifications = this.specifications) {
    let canSubmit = !!(
      typeof specifications.ada_compliant !== 'undefined' &&
      typeof specifications.finish !== 'undefined' &&
      typeof specifications.flow !== 'undefined'
    );

    // Add specific meta for shower type
    if (this.type === 'shower') {
      if (canSubmit) {
        canSubmit = typeof specifications.meta.shower_category !== 'undefined';
      }
    }
    return canSubmit;
  }

  get variantAffectingFilters() {
    const filters: VariantAffectingFilters = {
      query: undefined,
      availabilities: [],
      finishes: [],
      special_attributes: [],
      is_obsolete: ['false'],
      business_segments: [],
    };

    if (this.data.ada_compliant) {
      filters.special_attributes.push('ADA Compliant');
    }
    if (this.data.finish) {
      filters.finishes.push(this.data.finish);
    }
    if (this.data.tier) {
      filters.availabilities.push(this.data.tier);
    }

    // Addfilters for specific configuration type if needed
    // if (this.type === 'shower') {}

    return filters;
  }

  // Temporary solution to get some kind of text for products based on collection
  getProductDescriptionByHit(hit: Hit<ProductHitInterface>) {
    const collection_descriptions = {
      ActivSense:
        'Gone are the days of hands-on fixtures. Luckily, so are the auto-sensing units that are synonymous with airport bathrooms. Difficult to install, hard to maintain and just as hard to operate. These faucets and soap dispensers don’t work in the new normal. The future is ActivSense.',
      Allura:
        'Traditional yet transitional, the Symmons Allura Collection blends classic detailing with modern accents and finishes. The solid construction and style give a distinct presence in any traditional bathroom.',
      Ballina: '',
      Bramwell: '',
      Canterbury:
        'The Canterbury Collection from Symmons features elegant and stately lines reminiscent of the Gilded Age, yet its geometric shapes offer the versatility to be a transitional touch in any bath décor.',
      Carrington:
        'Created with historical elegance and sophistication in mind, the Symmons Carrington Collection highlights rich Victorian design in functional fixtures with modern amenities.',
      'Clear-Flo': '',
      Collage: '',
      Degas:
        'Inspired by the creations of French artist Degas, this collection blends classic lines and modern contours to create a truly transitional design. The subtle impressionistic details of the Degas Collection complement a wide range of bath design portfolios.',
      Dia: 'The Dia Collection from Symmons expresses Modern Industrial concepts in functional forms, with a cylindrical motif that brings Minimalism to any bath space.',
      Duro: 'The bold angular design of the Duro Collection reflects the concept of form and function as one ideal. The precise rectangular shapes present a clean, minimalist feel for any contemporary bathroom.',
      Elm: 'The sleek form and simple lines of the Symmons Elm Collection can be a subtle addition, or choose a bold finish to make it the focal point of any bathroom design.',
      'Free-Flo': '',
      Forza:
        'The Forza kitchen faucet combines sleek, sweeping lines with effortless functionality and renowned Symmons durability. The Forza features single-handle operation, a long-lasting ceramic cartridge, and a horizontal pull-out spray head with one-touch control.',
      Gallery: '',
      Hydapipe:
        'The reliability of Symmons pressure-balanced, anti-scald valves combined with the durability of 18-gauge stainless steel make HydaPipe shower units the trusted choice for correctional, educational, industrial, institutional, and public-use installations. Order standard HydaPipe configurations or custom-build a design to meet the specific needs of any construction project.',
      Identity:
        "The individuality of recent customer projects inspired the Symmons Identity Collection's balance of sleek curves and defined edges with stylish simplicity. Its distinct contours bring a modern touch with hints of retro Space Age design.",
      'Laundry-Mate':
        "The Laundry-Mate washing machine valve combines the ease of use with the protection of water pressure shock absorption that can damage or burst hoses and lead to costly damage. Laundry-Mate's all-metal valve has a unique triple-seal packing and features one-touch control lever. Laundry-Mate offers a variety of valve and drain configurations to fit various laundry room applications.",
      Lennar: '',
      Maxline:
        'Symmons recognizes that safety and reliability are important factors when selecting a Thermostatic Mixing Valve, and the MaxLine is dual certified to ASSE 1070/1017 standards. The MaxLine TMV valve has been improved to function at a lower flow range, with a smaller footprint, fewer parts, and a durable brass body. The new MaxLine retains its enduring quality and industry-leading scald protection that is synonymous with the Symmons name.',
      Moscato:
        'The Symmons Moscato® kitchen faucet showcases elegant curves reminiscent of a bottle of the favored dessert wine. The smooth swivel spout branches out to an elegant lever handle, and the integrated pull-out spray head varies the flow from spray to stream with a simple touch control.',
      Museo:
        "Inspired by iconic works of Modern Art, the Symmons Museo Collection's portrays a striking, contemporary design. The clean styling and dramatic finishes make a definitive statement in any bath décor.",
      Naru: '',
      Origins:
        'As a combination of three core Symmons products, the Origins Collection combines legendary quality with a fresh stylings for faucets and showers. Powered by Temptrol®, this collection offers easy installation and proven durability with an attractive new design.',
      Oxford:
        'The artful squared aesthetics define the Oxford Collection from Symmons. Its prominent traditional styling is intended to be the focal point of traditional or transitional bath spaces.',
      Safetymix:
        'Safetymix is the original Symmons pressure-balancing valve that started a plumbing revolution still outperforms all in its class today. The time-tested Symmons quality and safety features are second to none. Safetymix tub/shower systems are ideal for high-use commercial and institutional applications where durability is tested and safety cannot be compromised.',
      SCOT: 'The SCOT/Metering faucet delivers consistent metered wash cycles for commercial, healthcare, hospitality, and other demanding high-use applications. The all-metal body features a deep chrome finish, a self-cleaning cartridge, and the single-lever handle equals fewer parts, easier installation, and lower maintenance. With safety, compliance, and conservation in mind, the SCOT offers the flexibility to adjust both the wash cycle time and the water temperature. The SCOT is also fully compliant with ADA regulations for accessibility and operation.',
      Sereno:
        'The contemporary design of the Symmons Sereno Collection presents interesting curves and unique stylings to create a minimalist palette that blends with a wide variety of bathroom spaces.',
      Showeroff:
        'Symmons Showeroff® is a durable, piston-actuated metering shower valve that provides full shower flow for approximately 45 seconds to minimize water consumption and reduce energy costs. This showering solution is ideal for correctional facilities, public showers, or applications where metered control of water usage is necessary.',
      Symmetrix:
        'Symmons Symmetrix® faucets were designed with commercial and institutional applications in mind. Built to withstand heavy use and harsh conditions, the Symmetrix line relies on ceramic control cartridges, which are guaranteed to provide flawless performance under the broadest range of water conditions.',
      TempControl: '',
      Temptrol:
        'The legendary Temptrol pressure-balancing, anti-scald mixing valve is the heart of every Symmons tub and shower system, and it is the benchmark by which all others are judged. Each valve is constructed of solid brass, bronze, and stainless steel, and features an adjustable piston that instantly and continually balances the hot and cold water pressure as demand fluctuates in the plumbing system. Now Temptrol is even easier to install with the Rapid Install bracket system for virtually any rough-in situation, and factory-installed options for various water supply connection methods.',
      'Temptrol Commercial': '',
      'Ultra Sense':
        'Ultra-Sense faucets offer touch-free operation for fully hygienic hand-washing solutions in commercial, educational, healthcare, and other public washroom environments. Ultra-Sense faucets use Position Sensitive Detection (PSD) for reactive control and to reduce wasted water. Ultra-Sense faucets utilize various battery or AC power options to adapt to any application.',
      Unity:
        "The Unity Collection's transitional design showcases quality in a graceful yet functional form. From the bath to the kitchen, the slight curves of the handles and spouts display functional elegance.",
      Vella:
        "The Vella Kitchen faucet's sleek design is the perfect fit for virtually any kitchen setting or décor. The single-handle operation and touch-control spray head lets you easily transition from spray to stream with the press of a button.",
      'Visu-Temp':
        'Visu-Temp® Clear-Vue™ shower valves are the perfect choice for specialized care applications. The easy-to-read display and built-in thermometer clearly displays the output water temperature for safety and convenience. With a streamlined single-handle control and durable finish on the outside, the Visu-Temp relies on the Safetymix® pressure-balancing valve behind the wall, which shuts down water flow in the event of failure in hot or cold water supply.',
      'Water Dance': '',
      Winslet:
        "Evoking the charm of an English country estate, the Symmons Winslet Collection sets forth gentle touches of vintage décor. The arched curves and simple lines weave traditional appointments into today's modern bathroom.",
    };

    if (hit.collection.length && collection_descriptions[hit.collection[0] as keyof typeof collection_descriptions]) {
      return collection_descriptions[hit.collection[0] as keyof typeof collection_descriptions];
    }
    return '';
  }
}
