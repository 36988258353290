import * as React from 'react';
import { observer } from 'mobx-react';
import wordpressData from 'data';
import ProductSearchStore, { ViewMode } from 'search/stores/product-search-store';
import SpriteIcon from 'search/components/sprite-icon';

interface ToggleViewBtnProps {
  store: ProductSearchStore;
}

const ToggleViewBtn = observer(
  class ToggleViewBtn extends React.Component<ToggleViewBtnProps> {
    render() {
      const { store } = this.props;
      return (
        <>
          <div className={['c-listing__layout', 'u-hidden', 'u-flex@md', 'has-selected-' + store.viewMode].join(' ')}>
            <a
              href={wordpressData.productsUrl}
              data-layout="list"
              className={store.viewMode === ViewMode.List ? 'is-selected' : ''}
              onClick={(event) => {
                event.preventDefault();
                store.setViewMode(ViewMode.List);
              }}
            >
              <SpriteIcon name="list" classes={['o-svg-icon']} />
            </a>
            <a
              href={wordpressData.productsUrl}
              data-layout="grid"
              className={store.viewMode === ViewMode.Grid ? 'is-selected' : ''}
              onClick={(event) => {
                event.preventDefault();
                store.setViewMode(ViewMode.Grid);
              }}
            >
              <SpriteIcon name="grid" classes={['o-svg-icon']} />
            </a>
          </div>
        </>
      );
    }
  }
);

export default ToggleViewBtn;
