import * as React from 'react';
import axios from 'axios';

import Icon from 'components/icon';
import { DisplayReactQueryError } from 'my-account/components/DisplayFieldError';

interface ChooseImageModalContentProps {
  title: string;
  open: () => void;
  error: unknown;
  openLibrary: () => void;
}

export const ChooseImagePlaceholder: React.VFC<Pick<ChooseImageModalContentProps, 'open' | 'openLibrary'>> = ({
  open,
  openLibrary,
}) => {
  return (
    <>
      <div className="c-profile-preview u-mb-spacer-base">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          href="#"
          className="c-profile-preview__add u-mt-0"
          onClick={(event) => {
            event.preventDefault();
            openLibrary();
          }}
        >
          <div>
            <Icon name="refresh" className="u-text-lg" />
          </div>

          <div>
            <button
              className="c-link-cta-basic c-link-cta--small"
              type="button"
              onClick={(event) => {
                event.preventDefault();
              }}
            >
              <span>Add from library</span>
            </button>
          </div>
        </a>
      </div>
      <legend>OR</legend>
      <div className="c-profile-preview u-mb-spacer-base">
        <div
          className="c-profile-preview__add u-mt-0"
          onClick={(event) => {
            event.preventDefault();
            open();
          }}
        >
          <div>
            <Icon name="upload" className="u-text-lg" />
          </div>

          <div>
            <button
              className="c-link-cta-basic c-link-cta--small"
              type="button"
              onClick={(event) => {
                event.preventDefault();
              }}
            >
              <span>Drop file here or click to upload</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const ChooseImageModalContent: React.VFC<{
  title: string;
  open: () => void;
  error: unknown;
  openLibrary: () => void;
}> = ({ title, open, error, openLibrary }) => {
  return (
    <div className="c-block c-block--spacing-t-extra-small c-block--spacing-b-small">
      <div className="o-container-fluid">
        <div className="o-row">
          <div className="o-col-12">
            <div className="c-block__header c-block__header--hor">
              <div className="c-block__header-content u-items-center u-block u-flex@sm">
                <div>
                  <h6 className="c-headline u-text-neutral-500">{title}</h6>
                  <h4 className="u-mb-spacer-base-large">Image Details</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="o-row">
          <div className="o-col-12">
            <ChooseImagePlaceholder open={open} openLibrary={openLibrary} />
            <p className="c-note">The accepted media file formats are JPG, JPEG, PNG, GIF, TIF, and TIFF.</p>
            {error && !axios.isCancel(error) && <DisplayReactQueryError error={error} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseImageModalContent;
