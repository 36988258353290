import { useParams } from 'react-router-dom';

import api from 'api';
import { useQuery } from 'hooks/query';
import Icon from 'components/icon';
import { MainHero } from 'my-account/components/MainHero';
import { ErrorMessagesContent } from 'my-account/utils/error-handler';
import { EditProjectForm } from 'my-account/forms/Project';
import { getProjectVersion, isPublishedVersion } from 'my-account/utils/project';
import useDocumentTitle from 'hooks/useDocumentTitle';

export default function SpecificationPackagePublicPage() {
  const { token, version } = useParams<{ token?: string; version?: string }>();
  const [projectRes, getProjectState] = useQuery(api.project.getPublicProject, { token: String(token) });
  const { projectVersion } = getProjectVersion(projectRes?.data, version);
  useDocumentTitle(projectVersion?.name ? `${projectVersion.name} Project` : 'Project');

  return (
    <>
      <MainHero />

      <section className="c-block c-block--hero">
        <div className="o-container-fluid">
          <div className="o-row u-flex-grow u-items-end">
            <div className="o-col-12">
              <h1 className="c-title--medium u-mb-0">View Project</h1>
            </div>
          </div>
        </div>
        <div className="c-block__pattern c-block__pattern--light3 c-block__pattern--top3 c-block__overlay--opacity-20">
          <Icon name="pattern" className="o-svg-icon" />
        </div>
      </section>

      {getProjectState.isLoading && (
        <div className="c-listing__none">
          <div className="c-listing__none-spinner"></div>
          <p className="c-listing__none-title">Loading...</p>
        </div>
      )}
      {getProjectState.isError && (
        <div className="o-container-fluid u-pb-spacer-section">
          <div className="o-row u-flex-grow u-items-center">
            <div className="o-col-12">
              <div className="c-listing__none">
                <div className="c-listing__none-figure">
                  <Icon name="error" className="o-svg-icon" />
                </div>
                <p className="c-listing__none-title">Error</p>
                <ErrorMessagesContent error={getProjectState.error} />
              </div>
            </div>
          </div>
        </div>
      )}
      {getProjectState.isSuccess &&
        typeof projectRes !== 'undefined' &&
        typeof projectVersion !== 'undefined' &&
        isPublishedVersion(projectVersion) && (
          <EditProjectForm project={projectRes.data} projectVersion={projectVersion} isPublicView />
        )}
    </>
  );
}
