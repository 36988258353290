import * as React from 'react';
import { z } from 'zod';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import prettyBytes from 'pretty-bytes';

import Icon from 'components/icon';
import { Accordion } from 'components/Accordion';
import SubmitButton from 'components/Form/SubmitButton';
import { getUserName } from 'my-account/utils/user';
import { ImageBlock } from 'my-account/validations/project';
import { FileUpdateImageSchema } from 'my-account/validations/file';
import CroppedImage from 'my-account/components/CroppedImage';
import { DisplayFieldErrors } from 'my-account/components/DisplayFieldError';

type ImageDetailsModalContentProps = {
  value: z.infer<typeof FileUpdateImageSchema>;
  formProps?: JSX.IntrinsicElements['form'];
  errors?: Record<string, any>;
} & (
  | { isEditable?: false }
  | {
      settings: z.infer<typeof ImageBlock>['settings'];
      isEditable: true;
      openEdit: () => void;
      openLibrary: () => void;
      openUpload: () => void;
      clearImage: () => void;
    }
);

const ImageDetailsModalContent: React.VFC<ImageDetailsModalContentProps> = ({ value, formProps, errors, ...props }) => {
  return (
    <div className="c-block c-block--spacing-t-small c-block--spacing-b-small">
      <div className="o-container-fluid">
        <div className="o-row">
          <div className="o-col-12">
            <div className="c-block__header c-block__header--hor">
              <div className="c-block__header-content u-items-center u-block u-flex@sm">
                <div>
                  <h4 className="u-mb-spacer-base-large">Image Details</h4>
                </div>
              </div>
            </div>
            <form {...formProps}>
              {props.isEditable && (
                <div className="u-mb-spacer-base">
                  <button
                    className="c-button c-button--light-grey c-button--option"
                    type="button"
                    onClick={(event) => {
                      event.preventDefault();
                      props.openLibrary();
                    }}
                  >
                    <Icon name="refresh" className="o-svg-icon o-svg-large" />
                    <span>Change from Library</span>
                  </button>{' '}
                  <button
                    className="c-button c-button--light-grey c-button--option"
                    type="button"
                    onClick={(event) => {
                      event.preventDefault();
                      props.openUpload();
                    }}
                  >
                    <Icon name="upload" className="o-svg-icon o-svg-large" />
                    <span>Upload New Image</span>
                  </button>{' '}
                  <button
                    className="c-button c-button--light-grey c-button--option"
                    type="button"
                    onClick={(event) => {
                      event.preventDefault();
                      props.clearImage();
                    }}
                  >
                    <Icon name="close" className="o-svg-icon" />
                    <span>Clear Image</span>
                  </button>
                </div>
              )}
              <DisplayFieldErrors errors={errors} />
              {props.isEditable ? (
                <figure className="c-image-preview" style={{ minHeight: '4.5rem' }}>
                  <CroppedImage image={value} settings={props.settings} />
                  <button
                    className="c-button c-button--white c-button--option"
                    type="button"
                    onClick={(event) => {
                      event.preventDefault();
                      props.openEdit();
                    }}
                  >
                    <Icon name="pencil" className="o-svg-icon o-svg-large" />
                    <span>Edit image</span>
                  </button>
                </figure>
              ) : (
                <figure className="c-image-preview">
                  <img
                    src={value.image ?? ''}
                    alt={value.name ?? ''}
                    width={value.width ?? 0}
                    height={value.height ?? 0}
                  />
                </figure>
              )}
              <Accordion title="Info" type="framed">
                <div className="c-table-wrapper">
                  <table>
                    <tbody>
                      <tr>
                        <th>
                          <Icon name="link" />
                        </th>
                        <td>
                          <strong>{value.image}</strong> {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <a
                            href="#"
                            className="c-link-cta-basic c-link-cta--small"
                            onClick={(event) => {
                              event.preventDefault();
                              navigator.clipboard.writeText(value.image ?? '');
                            }}
                          >
                            <Icon name="copy" className="o-svg-icon o-svg-larger" />
                            <span>Copy link</span>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <Icon name="id" />
                        </th>
                        <td>
                          <strong>{value.name}</strong>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <Icon name="dimensions" />
                        </th>
                        <td>
                          <strong>
                            {value.width} x {value.height}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <Icon name="invoice" />
                        </th>
                        <td>
                          <strong>{prettyBytes(value.size ?? 0)}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <hr />
                <div className="c-table-wrapper">
                  <table>
                    <tbody>
                      {typeof value.mime === 'string' && (
                        <tr>
                          <th>
                            <small>File:</small>
                          </th>
                          <td>Image, {value.mime.replace('image/', '')}</td>
                        </tr>
                      )}
                      <tr>
                        <th>
                          <small>Uploaded by:</small>
                        </th>
                        <td>{value.user ? getUserName(value.user).name : ''}</td>
                      </tr>
                      {value.created_at !== null &&
                        typeof value.created_at === 'string' &&
                        value.created_at.length > 0 && (
                          <tr>
                            <th>
                              <small>Upload Date:</small>
                            </th>
                            <td>Oct 3 2021, 9:23 pm {format(parseISO(value.created_at), 'MMM d y, h:m aaa')}</td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                </div>
              </Accordion>
              <SubmitButton>Save</SubmitButton>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageDetailsModalContent;
