import * as React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { PaginateOptions } from 'components/Pagination';
import { setLocationSearchParams } from 'utils/router';

export const useSearchFilterValue = () => {
  const location = useLocation();
  const searchParams = React.useMemo(() => new URLSearchParams(location.search), [location.search]);

  let value = '';
  if (searchParams.has('search')) {
    value = searchParams.get('search') ?? '';
  }

  return value;
};

export const SearchFilter: React.VFC<{
  placeholder: string;
  navigateSearch?: string | ((search?: string) => void);
  navigatePagination?: PaginateOptions['navigate'];
  defaultValue?: string;
}> = ({ placeholder, navigateSearch = 'search', navigatePagination = 'list-page', ...props }) => {
  const history = useHistory();
  const location = useLocation();
  const urlSearchValue = useSearchFilterValue();
  const defaultValue = typeof props.defaultValue === 'undefined' ? urlSearchValue : props.defaultValue;
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [hasValue, setHasValue] = React.useState(defaultValue.length > 0);

  const updateSearch = React.useCallback<(searchValue: string | undefined) => void>(
    (searchValue) => {
      let value = searchValue?.trim();
      if (!value?.length) {
        value = undefined;
      }

      if (typeof navigateSearch === 'string' && typeof navigatePagination === 'string') {
        history.replace(
          setLocationSearchParams(location, { [navigateSearch]: value, [navigatePagination]: undefined })
        );
      } else if (typeof navigateSearch === 'string' && typeof navigatePagination !== 'string') {
        history.replace(setLocationSearchParams(location, { search: value }));
        navigatePagination(1);
      } else if (typeof navigateSearch !== 'string' && typeof navigatePagination === 'string') {
        history.replace(setLocationSearchParams(location, { [navigatePagination]: undefined }));
        navigateSearch(value);
      } else if (typeof navigateSearch !== 'string' && typeof navigatePagination !== 'string') {
        navigateSearch(value);
        navigatePagination(1);
      }
    },
    [history, location, navigatePagination, navigateSearch]
  );

  const submitHandler = React.useCallback(() => {
    if (inputRef.current) {
      updateSearch(inputRef.current.value);
    }
  }, [inputRef, updateSearch]);

  const clearHandler = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      event.preventDefault();
      updateSearch(undefined);

      if (inputRef.current) {
        inputRef.current.value = '';
        setHasValue(false);
      }
    },
    [updateSearch, inputRef, setHasValue]
  );

  return (
    <div className="ais-SearchBox">
      <div className="ais-SearchBox-form">
        <input
          className="ais-SearchBox-input"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          placeholder={placeholder}
          spellCheck="false"
          maxLength={512}
          type="search"
          name="search"
          defaultValue={defaultValue}
          onInput={(event) => {
            debounce(() => updateSearch(event.currentTarget.value), 100);
            setHasValue(event.currentTarget.value.length > 0);
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              submitHandler();
            }
          }}
          ref={inputRef}
        />
        <button
          className="ais-SearchBox-submit"
          type="button"
          title="Submit the search query."
          onClick={(event) => {
            event.preventDefault();
            submitHandler();
          }}
        >
          <svg
            className="ais-SearchBox-submitIcon"
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 40 40"
          >
            <path
              d="M26.804 29.01c-2.832 2.34-6.465 3.746-10.426 3.746C7.333 32.756 0 25.424 0 16.378 0 7.333 7.333 0 16.378 0c9.046 0 16.378 7.333 16.378 16.378 0 3.96-1.406 7.594-3.746 10.426l10.534 10.534c.607.607.61 1.59-.004 2.202-.61.61-1.597.61-2.202.004L26.804 29.01zm-10.426.627c7.323 0 13.26-5.936 13.26-13.26 0-7.32-5.937-13.257-13.26-13.257C9.056 3.12 3.12 9.056 3.12 16.378c0 7.323 5.936 13.26 13.258 13.26z"
              fillRule="evenodd"
            ></path>
          </svg>
        </button>
        <button
          className="ais-SearchBox-reset"
          type="reset"
          title="Clear the search query."
          onClick={clearHandler}
          hidden={!hasValue}
        >
          <svg
            className="ais-SearchBox-resetIcon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            width="10"
            height="10"
          >
            <path
              d="M8.114 10L.944 2.83 0 1.885 1.886 0l.943.943L10 8.113l7.17-7.17.944-.943L20 1.886l-.943.943-7.17 7.17 7.17 7.17.943.944L18.114 20l-.943-.943-7.17-7.17-7.17 7.17-.944.943L0 18.114l.943-.943L8.113 10z"
              fillRule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  );
};
