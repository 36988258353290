import { Link, Path, Svg, Text, View } from '@react-pdf/renderer';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import { Node } from 'unist';
import { visitParents } from 'unist-util-visit-parents';

import ImageBlock from 'spb-pdf/components/ImageBlock';
import { UseCroppedImageProps } from 'spb-pdf/utils/image';

interface IntroductionProps {
  title: string;
  content: string;
  image: UseCroppedImageProps['image'];
  image_settings: UseCroppedImageProps['settings'];
}

function rehypeWrapText() {
  return function wrapTextTransform(tree: Node) {
    visitParents(tree, 'text', (node, ancestors) => {
      // @ts-ignore
      if (ancestors[ancestors.length - 1].tagName !== 'text' && ancestors[ancestors.length - 1].tagName !== 'a') {
        // @ts-ignore
        node.type = 'element';
        // @ts-ignore
        node.tagName = 'text';
        // @ts-ignore
        node.children = [{ type: 'text', value: node.value }];
      }
    });
  };
}

const IntroductionTitleMarkdown: React.VFC<{ children: string; fontSize?: number }> = ({ children, fontSize = 24 }) => {
  return (
    <Text
      style={{
        fontFamily: 'Gotham Rounded',
        fontWeight: 200,
        fontSize,
        color: '#000000',
        opacity: 0.8,
        letterSpacing: -0.52,
      }}
    >
      <ReactMarkdown
        allowedElements={['em', 'strong', 'br', 'text']}
        rehypePlugins={[rehypeWrapText]}
        skipHtml={true}
        unwrapDisallowed={true}
        components={{
          em({ children }) {
            return <Text style={{ fontStyle: 'italic' }}>{children}</Text>;
          },
          strong({ children }) {
            return <Text style={{ fontWeight: 600 }}>{children}</Text>;
          },
          br() {
            return <Text>{'\n'}</Text>;
          },
          text({ children }) {
            return <Text>{children}</Text>;
          },
        }}
      >
        {children}
      </ReactMarkdown>
    </Text>
  );
};

const IntroductionContentMarkdown: React.VFC<{ children: string }> = ({ children }) => {
  return (
    <View>
      <ReactMarkdown
        allowedElements={['em', 'strong', 'br', 'p', 'a', 'text']}
        rehypePlugins={[rehypeWrapText]}
        skipHtml={true}
        unwrapDisallowed={true}
        components={{
          p(props) {
            return <View>{props.children}</View>;
          },
          em({ children }) {
            return (
              <Text style={{ fontStyle: 'italic', fontFamily: 'Gotham Rounded', fontSize: 9, color: '#666666' }}>
                {children}
              </Text>
            );
          },
          strong({ children }) {
            return (
              <Text style={{ fontWeight: 600, fontFamily: 'Gotham Rounded', fontSize: 9, color: '#666666' }}>
                {children}
              </Text>
            );
          },
          br() {
            return <Text style={{ fontFamily: 'Gotham Rounded', fontSize: 9, color: '#666666' }}>{'\n'}</Text>;
          },
          a({ href, children }) {
            return (
              <Link src={href ?? '#'} style={{ textDecoration: 'none', display: 'flex', flexDirection: 'row' }}>
                <Text
                  style={{
                    fontFamily: 'Gotham Rounded',
                    fontWeight: 700,
                    fontSize: 7,
                    color: '#000000',
                    textTransform: 'uppercase',
                    borderBottomStyle: 'solid',
                    borderBottomWidth: 1,
                    borderBottomColor: '#8DD0E0',
                    paddingTop: 0.5,
                  }}
                >
                  {children}
                </Text>
                <Svg width="6" height="6" style={{ marginLeft: 4 }}>
                  <Path
                    d="M5.982 2.993a.244.244 0 0 1-.052.077L3.77 5.23a.24.24 0 0 1-.34-.34l1.75-1.75H.24a.24.24 0 0 1 0-.48h4.94L3.43.91a.24.24 0 0 1 .34-.34l2.159 2.16a.244.244 0 0 1 .053.264Z"
                    fillRule="evenodd"
                    fill="#000000"
                  />
                </Svg>
              </Link>
            );
          },
          text(props) {
            return (
              <Text style={{ fontFamily: 'Gotham Rounded', fontSize: 9, color: '#666666' }}>{props.children}</Text>
            );
          },
        }}
        children={children}
      />
    </View>
  );
};

const Introduction: React.VFC<
  IntroductionProps & { size: 'lg' | 'md' | 'sm' | 'xs'; isEven?: boolean; hasHairline?: boolean }
> = ({ size, isEven, hasHairline, ...introduction }) => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: isEven ? 'row-reverse' : 'row',
        width: '100%',
        paddingTop: size === 'lg' ? 46 : 0,
        position: 'relative',
        flexShrink: 0,
        flexGrow: 1,
      }}
    >
      {hasHairline && (
        <View
          style={{
            position: 'absolute',
            left: 45,
            right: 45,
            top: 5,
            height: 0.5,
            backgroundColor: '#000000',
            opacity: 0.1,
          }}
        />
      )}
      <View
        style={[
          { flexGrow: 0, flexShrink: 0 },
          size === 'lg'
            ? { width: 560, paddingLeft: 20 }
            : size === 'md'
            ? { width: 340 }
            : size === 'sm'
            ? { width: 220 }
            : { width: 158 },
        ]}
      >
        {size === 'lg' && (
          <View style={{ paddingLeft: 45, paddingRight: 5 }}>
            <IntroductionTitleMarkdown>{introduction.title}</IntroductionTitleMarkdown>
          </View>
        )}
        <View
          style={[
            ...(size === 'lg' ? [{ marginTop: 58 }] : []),
            { display: 'flex', alignItems: isEven ? 'flex-start' : 'flex-end' },
          ]}
        >
          <ImageBlock
            image={{ content: introduction.image, settings: introduction.image_settings }}
            style={[
              { objectFit: 'cover' },
              size === 'lg'
                ? { width: 330, height: 330, borderRadius: 165 }
                : size === 'md'
                ? { width: 256, height: 256, borderRadius: 128 }
                : size === 'sm'
                ? { width: 170, height: 170, borderRadius: 85 }
                : { width: 128, height: 128, borderRadius: 64 },
            ]}
          />
        </View>
      </View>
      <View
        style={[
          size === 'lg'
            ? { paddingRight: 30 }
            : {
                paddingLeft: isEven ? 30 : 15,
                paddingRight: isEven ? 15 : 30,
                paddingTop: size === 'md' ? 54 : size === 'sm' ? 24 : 15,
              },
          { width: '100%', paddingBottom: size === 'xs' ? 9 : size === 'sm' ? 15 : 30, flexGrow: 1 },
        ]}
      >
        {size !== 'lg' && (
          <View style={{ marginBottom: size === 'xs' ? 9 : 14 }}>
            <IntroductionTitleMarkdown fontSize={18}>{introduction.title}</IntroductionTitleMarkdown>
          </View>
        )}
        <IntroductionContentMarkdown>{introduction.content}</IntroductionContentMarkdown>
      </View>
    </View>
  );
};

const Introductions: React.VFC<{
  introductions:
    | [IntroductionProps]
    | [IntroductionProps, IntroductionProps]
    | [IntroductionProps, IntroductionProps, IntroductionProps]
    | [IntroductionProps, IntroductionProps, IntroductionProps, IntroductionProps];
}> = ({ introductions }) => {
  return (
    <View style={{ width: '100%', height: '100%' }}>
      {introductions.map((introduction, index) => {
        return (
          <Introduction
            key={index}
            {...introduction}
            size={
              introductions.length === 1
                ? 'lg'
                : introductions.length === 2
                ? 'md'
                : introductions.length === 3
                ? 'sm'
                : 'xs'
            }
            isEven={(index + 1) % 2 === 0}
            hasHairline={index > 0}
          />
        );
      })}
    </View>
  );
};

export default Introductions;
