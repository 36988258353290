import * as React from 'react';
import wordpressData from 'data';
import SpriteIcon from 'search/components/sprite-icon';
import { ProductHit, ProductHitInterface } from 'search/components/product-search/product-hit';
import SelectDropdown from 'configurator/components/select-dropdown';
import SelectPagination from 'configurator/components/select-pagination';
import algoliasearchHelper, { SearchResults } from 'algoliasearch-helper';
import { VariantAffectingFilters } from 'search/stores/product-search-store';
import { Hit } from 'react-instantsearch-core';
import Portal from 'components/Portal';
import { getAlgoliaClient } from 'utils/algolia';

const defaultAttributesToRetrieve = [
  'objectID',
  'name',
  'sku',
  'slug',
  'categories',
  'featured_image',
  'us_price',
  'ca_price',
  'is_obsolete',
  'is_modified',
  'tier',
  'variants',
  // TODO remove this below if it ends up unnecessary
  'sku_dashless',
  'wordpress_id',
  'group_by_sku',
  'availabilities',
  'collection',
  'finishes',
  'special_attributes',
  'number_of_handles',
  'installation_mount_type',
  'flow_rate_gpm',
  '_highlightResult',
];

interface ProductSelectContentProps {
  showViewOptions: boolean;
  onSelect?: (sku: string, hit: Hit<ProductHitInterface>) => any;
  searchParameters?: algoliasearchHelper.PlainSearchParameters;
  variantFilters?: VariantAffectingFilters;
}

const ProductSelectContent: React.VFC<ProductSelectContentProps> = ({
  onSelect,
  showViewOptions,
  searchParameters,
  variantFilters,
}) => {
  const [data, setData] = React.useState<{
    hitsPerPage: number;
    index: string;
    results: undefined | SearchResults<ProductHitInterface>;
  }>({ hitsPerPage: 12, index: wordpressData.algolia.productsIndex, results: undefined });

  const listings_wrapper_id = 'product-listings_' + Math.random().toString(5).substr(2, 9);

  const searchParametersStirng = JSON.stringify(searchParameters);

  const productSearchHelper = algoliasearchHelper(getAlgoliaClient(), data.index, {
    ...searchParameters,
    attributesToRetrieve: defaultAttributesToRetrieve,
    hitsPerPage: data.hitsPerPage,
  });

  // productSearchHelper.on('search', (event) => {
  //   console.log('ProductSelectContent::productSearchHelper::onSearch', event);
  // });

  productSearchHelper.on('result', (event) => {
    console.log('ProductSelectContent::productSearchHelper::onResult', event);
    // Algolia has limit set to FIRST 1000 products for constant query filters with changing page number.
    if (event.results.nbPages * event.results.hitsPerPage > 1000) {
      event.results.nbPages = Math.floor(1000 / event.results.hitsPerPage);
    }

    setData({ index: productSearchHelper.getIndex(), hitsPerPage: event.results.hitsPerPage, results: event.results });
  });
  productSearchHelper.on('error', (event) => {
    console.log(event.error.message);
    setData({ index: productSearchHelper.getIndex(), hitsPerPage: data.hitsPerPage, results: undefined });
  });

  React.useEffect(() => {
    productSearchHelper.setPage(0);
    productSearchHelper.search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParametersStirng]);

  return (
    <section className="c-listing" id={listings_wrapper_id}>
      {showViewOptions && (
        <div className="c-listing__header u-justify-between@sm u-justify-start@lg">
          <div className="c-listing__header-views">
            <div className="c-listing__header-results">
              {data.results?.nbHits ? (
                <p>
                  Showing{' '}
                  <strong>
                    {data.results.hitsPerPage * data.results.page}-
                    {data.results.hitsPerPage * data.results.page + data.results.hits.length}
                  </strong>{' '}
                  of <strong>{data.results.nbHits}</strong> results
                </p>
              ) : (
                <p>No results</p>
              )}
            </div>
            <div className="c-listing__header-controls">
              <SelectDropdown
                label="Sort:"
                subtitle="Sort items by:"
                currentValue={data.index}
                items={[
                  { value: `${wordpressData.algolia.productsIndex}`, label: 'Most relevant' },
                  { value: `${wordpressData.algolia.productsIndex}_price_asc`, label: 'Lowest price' },
                  { value: `${wordpressData.algolia.productsIndex}_price_desc`, label: 'Highest Price' },
                ]}
                onChange={(value) => {
                  if (typeof value === 'string') {
                    productSearchHelper.setIndex(value);
                    productSearchHelper.search();
                  }
                }}
              />
              <SelectDropdown
                label="Results per page:"
                subtitle="Sort items by:"
                currentValue={data.hitsPerPage}
                items={[
                  { value: 6, label: '6' },
                  { value: 12, label: '12' },
                  { value: 24, label: '24' },
                  { value: 48, label: '48' },
                ]}
                onChange={(value) => {
                  if (typeof value === 'number') {
                    productSearchHelper.state.hitsPerPage = value;
                    productSearchHelper.setPage(0);
                    productSearchHelper.search();
                  }
                }}
              />
            </div>
          </div>
        </div>
      )}

      <div className="c-listing__row">
        <div className={['c-listing__content', data.results?.nbHits ? '' : 'c-listing__none-wrapper'].join(' ')}>
          {data.results?.nbHits ? (
            <>
              <ul className={['o-stack-2@sm', 'o-stack-3@md', 'c-card__row--products'].join(' ')}>
                {data.results.hits.map((hit) => (
                  <li key={hit.objectID}>
                    <article className={['c-card', 'c-card--product', 'c-card--link'].join(' ')}>
                      <ProductHit
                        hit={hit as Hit<ProductHitInterface>}
                        variantAffectingFilters={
                          variantFilters || {
                            query: undefined,
                            availabilities: [],
                            finishes: [],
                            special_attributes: [],
                            is_obsolete: [],
                            business_segments: [],
                          }
                        }
                        selectProduct={onSelect}
                      />
                    </article>
                  </li>
                ))}
              </ul>
              <SelectPagination
                // Algolia page indexing starts from 0
                currentPage={data.results.page + 1}
                pages={data.results.nbPages}
                selectPage={(page) => {
                  productSearchHelper.setPage(page - 1);
                  productSearchHelper.search();
                }}
                scrollToSelector={'#' + listings_wrapper_id}
              />
            </>
          ) : (
            <div className="c-listing__none">
              <div className="c-listing__none-figure">
                <SpriteIcon name="search-results" classes={['o-svg-icon']} />
              </div>
              <p className="c-listing__none-title">No results to show</p>
              <p>Try tweaking the filters or searching for something more general.</p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ProductSelectContent;

export const SupplementalProductSelect: React.VFC<
  ProductSelectContentProps & { productTypeLabel: string; image: string; selectedProduct?: Hit<ProductHitInterface> }
> = ({ onSelect, showViewOptions, searchParameters, variantFilters, productTypeLabel, image, selectedProduct }) => {
  const [data, setData] = React.useState<{
    openModal: boolean;
    hitsPerPage: number;
    index: string;
    results: undefined | SearchResults<ProductHitInterface>;
    hit?: Hit<ProductHitInterface>;
  }>({
    openModal: false,
    hitsPerPage: 12,
    index: wordpressData.algolia.productsIndex,
    results: undefined,
    hit: selectedProduct,
  });

  const productSearchHelper = algoliasearchHelper(getAlgoliaClient(), data.index, {
    ...searchParameters,
    ...{
      attributesToRetrieve: defaultAttributesToRetrieve,
      hitsPerPage: data.hitsPerPage,
    },
  });

  // productSearchHelper.on('search', (event) => {
  //   console.log('SupplementalProductSelect::productSearchHelper::onSearch', event);
  // });

  productSearchHelper.on('result', (event) => {
    console.log('SupplementalProductSelect::productSearchHelper::onResult', event);
    // Algolia has limit set to FIRST 1000 products for constant query filters with changing page number.
    if (event.results.nbPages * event.results.hitsPerPage > 1000) {
      event.results.nbPages = Math.floor(1000 / event.results.hitsPerPage);
    }

    setData({
      hit: data.hit,
      openModal: data.openModal,
      index: productSearchHelper.getIndex(),
      hitsPerPage: event.results.hitsPerPage,
      results: event.results,
    });
  });
  productSearchHelper.on('error', (event) => {
    console.log(event.error.message);
    setData({
      hit: data.hit,
      openModal: data.openModal,
      index: productSearchHelper.getIndex(),
      hitsPerPage: data.hitsPerPage,
      results: undefined,
    });
  });

  React.useEffect(() => {
    productSearchHelper.setPage(0);
    productSearchHelper.search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (data.openModal && onSelect) {
    return (
      <Portal
        onAppend={() => {
          document.documentElement.style.overflow = 'hidden';
        }}
        onRemove={() => {
          document.documentElement.style.overflow = 'visible';
        }}
      >
        <div className="mfp-bg mfp-ready"></div>
        <div className="mfp-wrap mfp-auto-cursor mfp-ready" tabIndex={-1} style={{ overflow: 'hidden auto' }}>
          <div className="mfp-container mfp-s-ready mfp-inline-holder">
            <div className="mfp-content">
              <div id="modal-product-search" className="c-modal__wrapper c-modal__wrapper--lg">
                <div className="c-modal">
                  <button onClick={() => setData({ ...data, openModal: false })} className="c-modal-close" />
                  <div className="c-modal__main">
                    <div className="c-block c-block--spacing-t-small c-block--spacing-b-small">
                      <div className="o-container-fluid">
                        <div className="o-row">
                          <div className="o-col-12">
                            <div className="u-flex u-items-center u-mb-spacer-base-small">
                              <SpriteIcon
                                name="plans"
                                classes={['o-svg-icon', 'u-mr-spacer-base-small', 'u-h2 u-text-secondary']}
                              />{' '}
                              <h6 className="c-headline u-mb-0 u-text-secondary">Product configurator</h6>
                            </div>
                            <h2 className="u-mb-spacer-base-large">Select a different {productTypeLabel}</h2>
                          </div>
                        </div>
                        <ProductSelectContent
                          onSelect={(sku, hit) => {
                            setData({ ...data, hit: hit, openModal: false });
                            onSelect(sku, hit);
                          }}
                          {...{ showViewOptions, variantFilters, searchParameters }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mfp-preloader">Loading...</div>
          </div>
        </div>
        <div className="mfp-bg mfp-ready"></div>
      </Portal>
    );
  } else if (data.hit) {
    return (
      <div className="o-col-6@md u-mb-spacer-base-large">
        <div className="u-flex u-justify-between">
          <p className="c-subtitle u-uppercase">Custom {productTypeLabel}</p>
          <span>
            {onSelect && (
              <button
                onClick={() => setData({ ...data, openModal: true })}
                className="c-link-cta-basic c-link-cta--small u-text-neutral-500"
              >
                <span>Change</span>
              </button>
            )}
          </span>
        </div>
        <article className="c-card c-card--product c-card--link">
          <ProductHit
            hit={data.hit as Hit<ProductHitInterface>}
            variantAffectingFilters={
              variantFilters || {
                query: undefined,
                availabilities: [],
                finishes: [],
                special_attributes: [],
                is_obsolete: [],
                business_segments: [],
              }
            }
          />
        </article>
      </div>
    );
  }

  return (
    <div className="o-col-6@md u-pt-spacer-base-large@md u-mb-spacer-base-large">
      <div className="c-addon">
        <div className="c-addon__icon u-text-secondary">
          <SpriteIcon name="add-thin" classes={['o-svg-icon']} />
        </div>
        <figure className="c-addon__pic">
          <img alt={`Different ${productTypeLabel}.`} src={image} />
        </figure>
        <div className="c-addon__content">
          <p className="c-headline">Bonus</p>
          <h6 className="c-addon__title">Do you want a different {productTypeLabel}?</h6>
          {onSelect && (
            <button
              onClick={() => setData({ ...data, openModal: true })}
              className="c-button c-button--sm c-button--white js-modal-base js-no-smooth-scroll"
            >
              Yes
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
