import * as React from 'react';
import { z } from 'zod';
import { useHistory } from 'react-router-dom';
import { ForbiddenError, subject } from '@casl/ability';
import { MutationStatus, useQueryClient } from 'react-query';
import merge from 'lodash/merge';

import {
  CompanyManagerUserRoleEnum,
  FileModel,
  FileModelImageTypeEnum,
  FileStatus,
  FileType,
  FileVisibility,
  LocationManagerUserRoleEnum,
  RegularUserRoleEnum,
  SalesRepUserRoleEnum,
} from '__generated-api__';
import api from 'api';
import { AuthStatus, useAbility, useAuth } from 'auth';
import { useMutation } from 'hooks/query';
import Form from 'components/Form/Form';
import InputField from 'components/Form/InputField';
import SubmitButton from 'components/Form/SubmitButton';
import Icon from 'components/icon';
import { useToast } from 'my-account/toast';
import { ProfileImageField } from 'my-account/components/ProfileImage';
import SelectField from 'components/Form/SelectField';

const MediaUpdateSchema = z.object({
  title: z.string().optional(),
  type: z.nativeEnum(FileModelImageTypeEnum),
  visibility: z.nativeEnum(FileVisibility),
  image: z
    .object({
      id: z.number().positive(),
      title: z.string(),
      thumbnail: z.string(),
    })
    .nullable()
    .optional(),
});

const getMediaInitialValues = (file: FileModel | undefined): z.infer<typeof MediaUpdateSchema> => {
  return {
    title: file?.title ?? '',
    type: FileModelImageTypeEnum.Image,
    visibility: file?.visibility ?? FileVisibility.Private,
    image: file ?? undefined,
  };
};

export const EditMediaForm: React.VFC<{ file?: FileModel }> = ({ file }) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();
  const ability = useAbility();
  const auth = useAuth();
  const [updateFile] = useMutation(api.file.updateFile);
  const [deleteFile] = useMutation(api.file.deleteFile);
  const [uploadImageStatus, setUploadImageStatus] = React.useState<MutationStatus>('idle');
  const [update, setUpdate] = React.useState(file);

  const canSubmit = file ? ability.can('update', subject('File', file)) : ability.can('create', 'File');
  const canRemove = file ? ability.can('delete', subject('File', file)) : false;
  let fileVisibilityEnum;
  if (auth.status === AuthStatus.LoggedIn) {
    if (auth.currentUser.role === RegularUserRoleEnum.User) {
      fileVisibilityEnum = z.enum([FileVisibility.Private, FileVisibility.Hidden]);
    } else if (auth.currentUser.role === LocationManagerUserRoleEnum.LocationManager) {
      fileVisibilityEnum = z.enum([FileVisibility.Location, FileVisibility.Private, FileVisibility.Hidden]);
    } else if (auth.currentUser.role === CompanyManagerUserRoleEnum.CompanyManager) {
      fileVisibilityEnum = z.enum([FileVisibility.Company, FileVisibility.Location, FileVisibility.Private]);
    } else if (auth.currentUser.role === SalesRepUserRoleEnum.SalesRep) {
      fileVisibilityEnum = z.enum([FileVisibility.Private]);
    } else {
      fileVisibilityEnum = z.enum([FileVisibility.Public, FileVisibility.Private]);
    }
  } else {
    fileVisibilityEnum = z.enum([FileVisibility.Private, FileVisibility.Hidden]);
  }

  return (
    <>
      <Form
        schema={MediaUpdateSchema}
        onSubmit={async (values, ctx) => {
          ForbiddenError.from(ability).throwUnlessCan(
            file ? 'update' : 'create',
            subject('File', merge({}, update, values))
          );

          const data = {
            ...values,
            visibility: values.visibility ? values.visibility : FileVisibility.Private,
          };

          if (typeof update !== 'undefined') {
            const res = await updateFile([{ id: update.id, fileModelMetadataRequestBody: data }]);
            ctx.reset(getMediaInitialValues(res.data));
            history.push(`/media-library/${res.data.id}`);
            toast.notify({
              type: 'success',
              title: 'Success',
              message: "You've successfully updated the file.",
            });
          }
        }}
        initialValues={getMediaInitialValues(update)}
        className="u-mb-0"
        hasFloatingLabels
        formProps={{ mode: 'onSubmit' }}
      >
        <section className="c-block c-block--spacing-t-extra-small c-block--spacing-b c-block--spacing-b-large@md">
          <div className="o-container-fluid">
            <div className="o-row">
              <div className="o-col-3@md">
                <p className="u-text-xs u-uppercase u-mb-spacer-base-small">Media</p>
                <p className="c-note">Create a file for use across specifications.</p>
              </div>

              <div className="o-col-9@md o-col-6@lg">
                <div className="o-row o-row--small-gutters">
                  <div className="o-col-12">
                    <InputField name="title" label="Title" elStyle="fill" />
                  </div>
                  <div className="o-col-12">
                    <SelectField name="visibility" label="Field Visibility" elStyle="fill">
                      {fileVisibilityEnum.options.map((item: string) => (
                        <option value={item} key={item}>
                          {item.charAt(0).toUpperCase() + item.slice(1)}
                        </option>
                      ))}
                    </SelectField>
                  </div>
                </div>
              </div>

              <div className="o-col-9 o-col-5@sm o-col-3@lg o-offset-3@md u-ml-0@lg">
                <ProfileImageField
                  name="image"
                  type={FileType.Image}
                  onUploadStatusChange={(status) => {
                    setUploadImageStatus(status);
                  }}
                  onFileUpload={(uploaded) => {
                    if (uploaded !== undefined) {
                      setUpdate(uploaded);
                    }
                  }}
                />
              </div>
            </div>

            <div className="o-row">
              <div className="o-col-12">
                <hr className="u-mt-spacer-base u-mt-spacer-base-large@sm u-mb-spacer-base-large u-mb-spacer-section-small@sm" />
              </div>
            </div>

            <div className="o-row u-items-center">
              <div className="o-col-4@md o-offset-3@md">
                <div className="c-form-footer">
                  <SubmitButton
                    variant="secondary"
                    isFull
                    isLoading={uploadImageStatus === 'loading'}
                    disabled={!canSubmit}
                  >
                    Save changes
                  </SubmitButton>
                </div>
              </div>

              {canRemove && update && (
                <div className="o-col-5@md u-text-right@md">
                  {update.status === FileStatus.Active ? (
                    /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
                    <a
                      href="#"
                      className="c-link-cta-basic c-link-cta--small u-mt-spacer-base u-mt-0@md u-mb-0"
                      onClick={async (event) => {
                        event.preventDefault();

                        if (window.confirm(`Are you sure that you really want to archive "${update.name}" media?`)) {
                          await deleteFile([{ id: update.id }]);

                          await Promise.all([
                            queryClient
                              .cancelQueries(api.file.listFiles.getQueryKey()[0])
                              .then(() => queryClient.invalidateQueries(api.file.listFiles.getQueryKey()[0])),
                            queryClient
                              .cancelQueries(api.file.getFile.getQueryKey({ id: update.id })[0])
                              .then(() =>
                                queryClient.invalidateQueries(api.file.getFile.getQueryKey({ id: update.id })[0])
                              ),
                          ]);

                          history.push('/media-library');
                          toast.notify({
                            type: 'success',
                            title: 'Success',
                            message: `The "${update.name}" file was archived successfully.`,
                          });
                        }
                      }}
                    >
                      <Icon name="trash" className="o-svg-icon o-svg-larger" />
                      <span>Archive File</span>
                    </a>
                  ) : (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                      href="#"
                      className="c-link-cta-basic c-link-cta--small u-mt-spacer-base u-mt-0@md u-mb-0"
                      onClick={async (event) => {
                        event.preventDefault();

                        if (window.confirm(`Are you sure that you really want to activate "${update.name}" media?`)) {
                          await updateFile([
                            {
                              id: update.id,
                              fileModelMetadataRequestBody: {
                                title: update.name,
                                status: FileStatus.Active,
                              },
                            },
                          ]);
                          await Promise.all([
                            queryClient
                              .cancelQueries(api.file.listFiles.getQueryKey()[0])
                              .then(() => queryClient.invalidateQueries(api.file.listFiles.getQueryKey()[0])),
                            queryClient
                              .cancelQueries(api.file.getFile.getQueryKey({ id: update.id })[0])
                              .then(() =>
                                queryClient.invalidateQueries(api.file.getFile.getQueryKey({ id: update.id })[0])
                              ),
                          ]);
                          toast.notify({
                            type: 'success',
                            title: 'Success',
                            message: `The "${update.name}" media was activated successfully.`,
                          });
                        }
                      }}
                    >
                      <Icon name="export" className="o-svg-icon o-svg-larger" />
                      <span>Activate media</span>
                    </a>
                  )}
                </div>
              )}
            </div>
          </div>
        </section>
      </Form>
    </>
  );
};
