import * as React from 'react';
import wordpressData from 'data';
import SpriteIcon from 'search/components/sprite-icon';
import ProductConfiguratorStore, { ConfiguratorSpecifications } from './stores/shower-configurator-store';
import ShowerBodySpraysImageSrc from 'assets/shower-body-sprays.svg';
import ShowerHeadTubWandImageSrc from 'assets/shower-head-tub-wand.svg';
import ShowerHeadTubImageSrc from 'assets/shower-head-tub.svg';
import ShowerHeadImageSrc from 'assets/shower-head.svg';
import algoliasearchHelper from 'algoliasearch-helper';
import { getAlgoliaClient } from 'utils/algolia';

interface ButtonFiltersSectionParams<T> {
  title: string;
  isOptional?: boolean;
  onChange: (value?: T) => void;
  selected?: T;
  choices: {
    label: string;
    value?: T;
  }[];
}

interface GraphicFiltersSectionParams<T> {
  title: string;
  isOptional?: boolean;
  onChange: (value?: T) => void;
  selected?: T;
  choices: {
    label: string;
    visual: JSX.IntrinsicElements['img'] | React.ReactElement<React.ComponentProps<typeof SpriteIcon>>;
    value: T;
  }[];
}

const ButtonFiltersSection: React.VFC<
  ButtonFiltersSectionParams<string> | ButtonFiltersSectionParams<number> | ButtonFiltersSectionParams<boolean>
> = ({ title, choices, onChange, selected, isOptional }) => {
  return (
    <>
      <p className="c-list-steps__title">{title}</p>
      <ul className="c-list-tags u-mb-spacer-base-large">
        {choices.map((item, index) => (
          <li className="u-mb-spacer-base-small" key={'item-' + String(index)}>
            <a
              href={'#' + item.value}
              className={'c-tag c-tag--large ' + (selected === item.value ? 'c-tag--dark' : 'c-tag--light')}
              onClick={(event) => {
                event.preventDefault();
                if (isOptional && selected === item.value) {
                  onChange(undefined);
                } else {
                  onChange(item.value as any);
                }
              }}
            >
              <span>{item.label}</span>
            </a>
          </li>
        ))}
      </ul>
    </>
  );
};

const GraphicFiltersSection: React.VFC<
  | GraphicFiltersSectionParams<string>
  | GraphicFiltersSectionParams<string>
  | GraphicFiltersSectionParams<number>
  | GraphicFiltersSectionParams<boolean>
> = ({ title, choices, onChange, selected, isOptional }) => {
  return (
    <>
      <p className="c-list-steps__title">{title}</p>
      <ul className="c-list-tags o-stack-2 o-stack-3@md o-stack-4@lg u-mb-spacer-base u-items-normal">
        {choices.map((item, index) => (
          <li className="u-mb-spacer-base" key={'item-' + String(index)}>
            <a
              href={'#' + item.value}
              onClick={(event) => {
                event.preventDefault();
                if (isOptional && selected === item.value) {
                  onChange(undefined);
                } else {
                  onChange(item.value as any);
                }
              }}
              className={'c-tag c-tag--stretched c-tag--light' + (selected === item.value ? ' c-tag--selected' : '')}
            >
              <span className="c-tag__pic">{item.visual}</span>
              <span>{item.label}</span>
            </a>
          </li>
        ))}
      </ul>
    </>
  );
};

const FinishFiltersSection: React.VFC<{
  title: string;
  isOptional?: boolean;
  onChange: (value?: string) => void;
  selected?: string;
  results?: Record<string, number>;
}> = ({ title, onChange, selected, isOptional, results }) => {
  return (
    <>
      <p className="c-list-steps__title">{title}</p>
      <ul className="c-finishes c-finishes--hor u-mb-spacer-base-large">
        {Object.keys(wordpressData.finishIcons).map((label, index) => {
          return (
            <li
              key={'item-' + String(index)}
              className={'u-mb-spacer-base-small' + (label === selected ? ' is-selected' : '')}
              style={typeof results !== 'undefined' && !results[label] ? { opacity: 0.1 } : undefined}
            >
              <a
                href={'#' + label}
                title={label}
                onClick={(event) => {
                  event.preventDefault();
                  return isOptional && selected === label ? onChange(undefined) : onChange(label);
                }}
              >
                <span
                  className="c-finishes__preview"
                  style={{
                    backgroundImage: wordpressData.finishIcons[label]
                      ? `url(${wordpressData.finishIcons[label]})`
                      : undefined,
                  }}
                ></span>
              </a>
            </li>
          );
        })}
      </ul>
    </>
  );
};

const FiltersConfigurator: React.VFC<{
  configurator: ProductConfiguratorStore;
  onSubmit: (specifications: ConfiguratorSpecifications) => any;
}> = ({ configurator, onSubmit }) => {
  const [specifications, setSpecifications] = React.useState(configurator.specifications);
  const [results, setResults] = React.useState<
    | undefined
    | {
        total?: number;
        finishes: Record<string, number>;
      }
  >(undefined);

  const sections = [
    <ButtonFiltersSection
      key="tier-filter"
      title={'What range of availability works for your project?'}
      onChange={(value) => {
        setSpecifications({ ...specifications, tier: value });
      }}
      selected={specifications.tier}
      choices={[
        {
          value: 'ReadyStock™',
          label: 'ASAP – need within 1-2 weeks',
        },
        {
          value: undefined,
          label: 'Flexible – 3+ weeks',
        },
      ]}
    />,
    <ButtonFiltersSection
      key="ada-filter"
      title={'Does your ' + configurator.type_label() + ' need to be ADA-compliant for accessibility?'}
      onChange={(value) => {
        setSpecifications({ ...specifications, ada_compliant: value });
      }}
      selected={specifications.ada_compliant}
      choices={[
        {
          value: true,
          label: 'Yes, it needs to be ADA-compliant',
        },
        {
          value: false,
          label: 'No',
        },
      ]}
    />,
    <ButtonFiltersSection
      key="flow-filter"
      title={'What’s the maximum flow rate needed? (Check state/local codes)'}
      onChange={(value) => {
        setSpecifications({ ...specifications, flow: value });
      }}
      selected={specifications.flow}
      choices={[
        {
          value: '1.5 GPM',
          label: '1.5 GPM',
        },
        {
          value: '1.75 GPM',
          label: '1.75 GPM',
        },
        {
          value: '2.0 GPM',
          label: '2.0 GPM',
        },
        // {
        //   value: '2.5 GPM',
        //   label: '2.5 GPM',
        // },
      ]}
    />,
    <FinishFiltersSection
      key="finish-filter"
      title={'Which color and finish would you like?'}
      onChange={(value) => {
        setSpecifications({ ...specifications, finish: value });
      }}
      selected={specifications.finish}
      results={results?.finishes}
    />,
  ];

  let canSubmit = !!(
    typeof specifications.ada_compliant !== 'undefined' &&
    typeof specifications.finish !== 'undefined' &&
    typeof specifications.flow !== 'undefined' &&
    typeof specifications.style !== 'undefined'
  );

  // Add specific meta for shower type
  if (configurator.type === 'shower') {
    if (canSubmit) {
      canSubmit = typeof specifications.meta.shower_category !== 'undefined';
    }

    sections.push(
      <GraphicFiltersSection
        key="shower-setup-filter"
        title={'Which shower functions would you like?'}
        onChange={(value) => {
          specifications.meta.shower_category = value;
          setSpecifications({ ...specifications });
        }}
        selected={specifications.meta.shower_category}
        choices={[
          {
            value: 'SHT',
            label: 'Showerhead Only',
            visual: <img src={ShowerHeadImageSrc} alt="Showerhead Only" />,
          },
          {
            value: 'SHS',
            label: 'Showerhead + Tub Spout',
            visual: <img src={ShowerHeadTubImageSrc} alt="Showerhead + Tub Spout" />,
          },
          {
            value: 'SWT',
            label: 'Showerhead + Tub Spout + Hand Shower',
            visual: <img src={ShowerHeadTubWandImageSrc} alt="Showerhead + Tub Spout + Hand Shower" />,
          },
          {
            value: 'SSB',
            label: 'Body Sprays',
            visual: <img src={ShowerBodySpraysImageSrc} alt="Showerhead + Shower Wand + Body Spray" />,
          },
        ]}
      />
    );
  }

  React.useEffect(
    () => {
      if (configurator.validateSpecifications(specifications)) {
        const productSearchHelper = algoliasearchHelper(getAlgoliaClient(), wordpressData.algolia.productsIndex, {
          ...configurator.getSearchParameters('shower', specifications),
          attributesToRetrieve: [],
        });

        productSearchHelper.on('search', (event) => {
          //console.log('ProductSelectContent::productSearchHelper::onSearch', event);
          //setResults(undefined);
        });

        productSearchHelper.on('result', (event) => {
          console.log('ProductSelectContent::productSearchHelper::onResult', event.results);
          const newResult = {
            total: event.results.nbHits,
            finishes: {},
          };
          if (event.results.disjunctiveFacets[0]?.name === 'finishes') {
            newResult.finishes = event.results.disjunctiveFacets[0].data;
          }
          setResults(newResult);
        });
        productSearchHelper.on('error', (event) => {
          console.log(event.error.message);
        });
        productSearchHelper.setPage(0);
        productSearchHelper.search();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [specifications]
  );

  return (
    <section>
      <ul className="c-list-steps">
        {sections.map((section, index) => {
          return (
            <li key={'filter-section-' + String(index)} className="u-mb-spacer-base-large">
              <span className="c-list-steps__value c-list-steps__value--automatic"></span>
              {section}
            </li>
          );
        })}
      </ul>
      <hr className="u-mb-spacer-base-large"></hr>
      <div className="u-flex u-justify-between">
        <button
          className="c-link-cta-basic c-link-cta--small u-pl-0"
          onClick={() => {
            setSpecifications({ meta: {} });
            setResults(undefined);
          }}
        >
          Reset
        </button>
        <button
          disabled={!(configurator.validateSpecifications(specifications) && results?.total)}
          className="c-button c-button--small c-button--secondary"
          onClick={() => onSubmit(specifications)}
        >
          <span>
            {!configurator.validateSpecifications(specifications)
              ? 'Answer all questions'
              : typeof results === 'undefined'
              ? 'Loading...'
              : results.total === 0
              ? 'No results'
              : `Show ${results?.total} result${results?.total === 1 ? '' : 's'}`}
          </span>
          <SpriteIcon name="arrow" classes={['o-svg-icon', 'o-svg-right']} />
        </button>
      </div>
    </section>
  );
};

export default FiltersConfigurator;
