import { Ability, AbilityBuilder, AbilityClass, CanParameters } from '@casl/ability';
import * as React from 'react';

import {
  AdminUserRoleEnum,
  AgencyRepUserRoleEnum,
  Company,
  CompanyManagerUserRoleEnum,
  FileModel,
  FileVisibility,
  Introduction,
  Location,
  LocationManagerUserRoleEnum,
  Order,
  PriceSheet,
  Project,
  Quote,
  RegisteredUser,
  RegularUserRoleEnum,
  Report,
  Resource,
  SalesRepUserRoleEnum,
  User,
} from '__generated-api__';
import { PartialDeep } from 'type-fest';

export type Actions = 'create' | 'read' | 'update' | 'delete' | 'list';
export type Subjects =
  | (PartialDeep<Order> & { __caslSubjectType__: 'Order' })
  | 'Order'
  | (PartialDeep<Quote> & { __caslSubjectType__: 'Quote' })
  | 'Quote'
  | (PartialDeep<User> & { __caslSubjectType__: 'User' })
  | 'User'
  | (PartialDeep<Company> & { __caslSubjectType__: 'Company' })
  | 'Company'
  | (PartialDeep<Company> & { __caslSubjectType__: 'Agency' })
  | 'Agency'
  | (PartialDeep<Location> & { __caslSubjectType__: 'Location' })
  | 'Location'
  | (PartialDeep<Report> & { __caslSubjectType__: 'Report' })
  | 'Report'
  | 'ProductPrice'
  | (PartialDeep<Project> & { __caslSubjectType__: 'Project' })
  | 'Project'
  | (PartialDeep<Introduction> & { __caslSubjectType__: 'Introduction' })
  | 'Introduction'
  | (PartialDeep<Resource> & { __caslSubjectType__: 'Resource' })
  | 'Resource'
  | (PartialDeep<FileModel> & { __caslSubjectType__: 'File' })
  | 'File'
  | (PartialDeep<PriceSheet> & { __caslSubjectType__: 'PriceSheet' })
  | 'PriceSheet';

export type TAppAbility = Ability<[Actions, Subjects]>;
export type TAppAbilityCanParams = CanParameters<[Actions, Subjects]>;
const AppAbility = Ability as AbilityClass<TAppAbility>;

export const defineAbilityFor = (user?: RegisteredUser) => {
  const abilityBuilder = new AbilityBuilder(AppAbility);

  if (user) {
    if (user.role === AdminUserRoleEnum.Admin) {
      abilityBuilder.can('list', 'Introduction');
      abilityBuilder.can('create', 'Introduction');
      abilityBuilder.can('read', 'Introduction');
      abilityBuilder.can('update', 'Introduction');
      abilityBuilder.can('delete', 'Introduction');

      abilityBuilder.can('list', 'Resource');
      abilityBuilder.can('create', 'Resource');
      abilityBuilder.can('read', 'Resource');
      abilityBuilder.can('update', 'Resource');
      abilityBuilder.can('delete', 'Resource');

      abilityBuilder.can('list', 'File');
      abilityBuilder.can('create', 'File');
      abilityBuilder.can('read', 'File');
      abilityBuilder.can('update', 'File');
      abilityBuilder.can('delete', 'File');

      abilityBuilder.can('list', 'Project');
      abilityBuilder.can('create', 'Project');
      abilityBuilder.can('read', 'Project');
      abilityBuilder.can('update', 'Project');
      abilityBuilder.can('delete', 'Project');

      abilityBuilder.can('list', 'Report');
      abilityBuilder.can('create', 'Report');
      abilityBuilder.can('read', 'Report');
      abilityBuilder.can('update', 'Report');
      abilityBuilder.can('delete', 'Report');

      abilityBuilder.can('list', 'Order');
      abilityBuilder.can('read', 'Order');
      abilityBuilder.can('update', 'Order');

      abilityBuilder.can('list', 'Quote');
      abilityBuilder.can('read', 'Quote');
      abilityBuilder.can('update', 'Quote');
      abilityBuilder.can('delete', 'Quote');

      abilityBuilder.can('list', 'User');
      abilityBuilder.can('create', 'User');
      abilityBuilder.can('read', 'User');
      abilityBuilder.can('update', 'User');
      abilityBuilder.can('delete', 'User');
      abilityBuilder.cannot('delete', 'User', { id: user.id });

      abilityBuilder.can('list', 'Company');
      abilityBuilder.can('create', 'Company');
      abilityBuilder.can('read', 'Company');
      abilityBuilder.can('update', 'Company');
      abilityBuilder.can('delete', 'Company');

      abilityBuilder.can('list', 'Agency');
      abilityBuilder.can('create', 'Agency');
      abilityBuilder.can('read', 'Agency');
      abilityBuilder.can('update', 'Agency');
      abilityBuilder.can('delete', 'Agency');

      abilityBuilder.can('list', 'Location');
      abilityBuilder.can('create', 'Location');
      abilityBuilder.can('read', 'Location');
      abilityBuilder.can('update', 'Location');
      abilityBuilder.can('delete', 'Location');
    } else if (user.role === SalesRepUserRoleEnum.SalesRep || user.role === AgencyRepUserRoleEnum.AgencyRep) {
      abilityBuilder.can('list', 'Introduction');
      abilityBuilder.can('create', 'Introduction');
      abilityBuilder.can('read', 'Introduction');
      abilityBuilder.can('update', 'Introduction', { user_id: user.id });

      abilityBuilder.can('list', 'Resource');
      abilityBuilder.can('create', 'Resource');
      abilityBuilder.can('read', 'Resource');
      abilityBuilder.can('update', 'Resource');
      abilityBuilder.can('delete', 'Resource');

      abilityBuilder.can('list', 'File');
      abilityBuilder.can('create', 'File');
      abilityBuilder.can('read', 'File', {
        user_id: { $eq: user.id },
        visibility: FileVisibility.Public,
      });
      abilityBuilder.can('update', 'File', { user_id: user.id });
      abilityBuilder.can('delete', 'File', { user_id: user.id });

      abilityBuilder.can('list', 'Project');
      abilityBuilder.can('create', 'Project');
      abilityBuilder.can('read', 'Project');
      abilityBuilder.can('update', 'Project', { user_id: user.id });
      abilityBuilder.can('delete', 'Project', { user_id: user.id });

      // abilityBuilder.can('list', 'Report');
      // abilityBuilder.can('create', 'Report');
      // abilityBuilder.can('read', 'Report');
      // abilityBuilder.can('update', 'Report');
      // abilityBuilder.can('delete', 'Report');

      abilityBuilder.can('list', 'Order');
      abilityBuilder.can('read', 'Order');
      abilityBuilder.can('update', 'Order');

      abilityBuilder.can('list', 'Quote');
      abilityBuilder.can('read', 'Quote');
      abilityBuilder.can('update', 'Quote');
      abilityBuilder.can('delete', 'Quote');

      // abilityBuilder.can('list', 'User');
      // abilityBuilder.can('create', 'User');
      // abilityBuilder.can('read', 'User');
      // abilityBuilder.can('update', 'User');
      // abilityBuilder.can('delete', 'User');
      // abilityBuilder.cannot('delete', 'User', { id: user.id });
      abilityBuilder.can('read', 'User', { id: user.id });

      abilityBuilder.can('list', 'Company');
      // abilityBuilder.can('create', 'Company');
      abilityBuilder.can('read', 'Company');
      // abilityBuilder.can('update', 'Company');
      // abilityBuilder.can('delete', 'Company');

      abilityBuilder.can('list', 'Location');
      // abilityBuilder.can('create', 'Location');
      abilityBuilder.can('read', 'Location');
      // abilityBuilder.can('update', 'Location');
      // abilityBuilder.can('delete', 'Location');
    } else if (user.role === CompanyManagerUserRoleEnum.CompanyManager) {
      abilityBuilder.can('list', 'Order');
      abilityBuilder.can('read', 'Order', { company_id: user.company_id });
      abilityBuilder.can('update', 'Order', { company_id: user.company_id });

      abilityBuilder.can('list', 'Resource');
      abilityBuilder.can('read', 'Resource');

      abilityBuilder.can('list', 'Quote');
      abilityBuilder.can('create', 'Quote', { company_id: user.company_id });
      abilityBuilder.can('read', 'Quote', { company_id: user.company_id });
      abilityBuilder.can('update', 'Quote', { company_id: user.company_id });
      abilityBuilder.can('delete', 'Quote', { company_id: user.company_id });

      abilityBuilder.can('list', 'User', {
        role: { $in: [LocationManagerUserRoleEnum.LocationManager, RegularUserRoleEnum.User] },
      });
      abilityBuilder.can('create', 'User', {
        role: { $in: [LocationManagerUserRoleEnum.LocationManager, RegularUserRoleEnum.User] },
        company_id: user.company_id,
      });
      abilityBuilder.can('read', 'User', {
        company_id: user.company_id,
        role: { $in: [LocationManagerUserRoleEnum.LocationManager, RegularUserRoleEnum.User] },
      });
      abilityBuilder.can('update', 'User', {
        company_id: { $eq: user.company_id },
        role: { $in: [LocationManagerUserRoleEnum.LocationManager, RegularUserRoleEnum.User] },
      });
      abilityBuilder.can('delete', 'User', {
        company_id: { $eq: user.company_id },
        role: { $in: [LocationManagerUserRoleEnum.LocationManager, RegularUserRoleEnum.User] },
      });
      abilityBuilder.cannot('delete', 'User', { id: user.id });

      abilityBuilder.can('read', 'Company', { id: user.company_id });

      abilityBuilder.can('list', 'Location');
      abilityBuilder.can('read', 'Location', { company_id: user.company_id });

      abilityBuilder.can('read', 'ProductPrice');

      abilityBuilder.can('create', 'PriceSheet');
    } else if (user.role === LocationManagerUserRoleEnum.LocationManager) {
      abilityBuilder.can('list', 'Quote');
      abilityBuilder.can('create', 'Quote', { company_id: user.company_id, location_id: user.location_id });
      abilityBuilder.can('read', 'Quote', { company_id: user.company_id, location_id: user.location_id });
      abilityBuilder.can('update', 'Quote', { company_id: user.company_id, location_id: user.location_id });
      abilityBuilder.can('delete', 'Quote', { company_id: user.company_id, location_id: user.location_id });

      abilityBuilder.can('list', 'Resource');
      abilityBuilder.can('read', 'Resource');

      abilityBuilder.can('list', 'User', {
        role: { $in: [RegularUserRoleEnum.User] },
      });
      abilityBuilder.can('create', 'User', {
        company_id: user.company_id,
        location_id: user.location_id,
        role: { $in: [RegularUserRoleEnum.User] },
      });
      abilityBuilder.can('read', 'User', {
        company_id: user.company_id,
        location_id: user.location_id,
        role: { $in: [RegularUserRoleEnum.User] },
      });
      abilityBuilder.can('update', 'User', {
        company_id: user.company_id,
        location_id: user.location_id,
        role: { $in: [RegularUserRoleEnum.User] },
      });
      abilityBuilder.can('delete', 'User', {
        company_id: user.company_id,
        location_id: user.location_id,
        role: { $in: [RegularUserRoleEnum.User] },
      });
      abilityBuilder.cannot('delete', 'User', { id: user.id });

      abilityBuilder.can('read', 'Company', { id: user.company_id });
      abilityBuilder.can('read', 'Location', { id: user.location_id });
    } else if (user.role === RegularUserRoleEnum.User) {
      abilityBuilder.can('list', 'Quote');
      abilityBuilder.can('create', 'Quote', { user_id: user.id });
      abilityBuilder.can('read', 'Quote', { user_id: user.id });
      abilityBuilder.can('update', 'Quote', { user_id: user.id });
      abilityBuilder.can('delete', 'Quote', { user_id: user.id });

      abilityBuilder.can('list', 'Resource');
      abilityBuilder.can('read', 'Resource');

      abilityBuilder.can('read', 'User', { id: user.id });
      abilityBuilder.can('read', 'Company', { id: user.company_id });
      abilityBuilder.can('read', 'Location', { id: user.location_id });
    }

    if (user.role === LocationManagerUserRoleEnum.LocationManager || user.role === RegularUserRoleEnum.User) {
      if (user.permissions.can_view_pricing) {
        abilityBuilder.can('read', 'ProductPrice');
        abilityBuilder.can('create', 'PriceSheet', { location_id: user.location_id });
      }

      // if (user.permissions.can_view_my_orders) {
      //   abilityBuilder.can('list', 'Order');
      //   abilityBuilder.can('read', 'Order', { user_id: user.id });
      // }

      if (user.permissions.can_view_location_orders) {
        abilityBuilder.can('list', 'Order');
        abilityBuilder.can('read', 'Order', { company_id: user.company_id, location_id: user.location_id });
      }

      if (user.permissions.can_view_company_orders) {
        abilityBuilder.can('list', 'Order');
        abilityBuilder.can('read', 'Order', { company_id: user.company_id });
      }
    }
  }

  return abilityBuilder.build();
};

export const AuthorizationContext = React.createContext<TAppAbility>(new AbilityBuilder(AppAbility).build());
