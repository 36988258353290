import * as React from 'react';

import Icon from 'components/icon';
import { MainHero } from 'my-account/components/MainHero';

export const Block403Error: React.VFC = () => {
  return (
    <div className="o-container-fluid u-pb-spacer-section">
      <div className="o-row u-flex-grow u-items-center">
        <div className="o-col-12">
          <div className="c-listing__none">
            <div className="c-listing__none-figure">
              <Icon name="error" className="o-svg-icon" />
            </div>
            <p className="c-listing__none-title">Unauthorized access</p>
            <p>You are not authorized to access this page.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function Page403Error() {
  return (
    <>
      <MainHero />

      <section className="c-block c-block--spacing-t-extra-small c-block--spacing-t-small@md c-block--spacing-b-extra-small c-block--spacing-b-small@md c-block--height-semifull@xs">
        <div className="o-container-fluid">
          <div className="o-row u-flex-grow u-items-center">
            <div className="o-col-12">
              <div className="c-listing__none">
                <div className="c-listing__none-figure">
                  <Icon name="error" className="o-svg-icon" />
                </div>
                <p className="c-listing__none-title">Unauthorized access</p>
                <p>You are not authorized to access this page.</p>
              </div>
            </div>
          </div>

          <div className="c-block__pattern c-block__pattern--light3 c-block__pattern--top3 c-block__overlay--opacity-20">
            <Icon name="pattern" className="o-svg-icon" />
          </div>
        </div>
      </section>
    </>
  );
}
