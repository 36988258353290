import { User } from '__generated-api__';

export const getUserName = <
  T extends {
    first_name?: User['first_name'] | null;
    last_name?: User['last_name'] | null;
    email?: User['email'] | null;
  }
>(
  user: T
) => {
  let name = '';
  let initials = '';

  if (user.first_name || user.last_name) {
    name = [user.first_name, user.last_name]
      .filter((item): item is string => typeof item === 'string')
      .map((item) => item.trim())
      .filter((item) => item.length)
      .join(' ');
  }

  if (name.length) {
    initials = name.includes(' ')
      ? name
          .split(' ')
          .filter(Boolean)
          .slice(0, 2)
          .map((item) => item.charAt(0))
          .join('')
      : [name.charAt(0), name.charAt(1)].filter(Boolean).join('');
  } else if (user.email && user.email.length) {
    name = user.email.split('@')[0];
    initials = name.includes('.')
      ? name
          .split('.')
          .filter(Boolean)
          .slice(0, 2)
          .map((item) => item.charAt(0))
          .join('')
      : [name.charAt(0), name.charAt(1)].filter(Boolean).join('');
  }

  return { name, initials };
};
