import * as React from 'react';

import api from 'api';
import { SelectListingItemProps, SelectListingItem } from 'my-account/components/SelectListingItem';

export interface SelectCompanyProps<IsMulti extends boolean = false>
  extends Omit<
    SelectListingItemProps<typeof api.company.listCompanies, IsMulti>,
    'queryFn' | 'getOptionLabel' | 'getOptionValue' | 'queryParams'
  > {
  queryParams?: SelectListingItemProps<typeof api.company.listCompanies, IsMulti>['queryParams'];
}

export const SelectCompany = <IsMulti extends boolean = false>({
  queryParams = {},
  ...props
}: SelectCompanyProps<IsMulti>) => {
  return (
    <SelectListingItem
      {...props}
      queryFn={api.company.listCompanies}
      queryParams={{ sort: 'name', direction: 'asc', ...queryParams }}
      getOptionLabel={(company) => company.name}
      getOptionValue={(company) => String(company.id)}
    />
  );
};
