import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { Button, ButtonProps } from 'components/Button';

interface SubmitButtonProps extends Omit<ButtonProps, 'type'> {}

const SubmitButton: React.FC<SubmitButtonProps> = ({ children, isLoading, ...buttonProps }) => {
  const {
    formState: { isSubmitting },
  } = useFormContext();

  return (
    <Button {...buttonProps} type="submit" isLoading={isLoading || isSubmitting}>
      {children}
    </Button>
  );
};

export default SubmitButton;
